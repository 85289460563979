import React, { useEffect, useRef, useState } from 'react';
import { Popover } from 'antd';
import { MoreIcon } from '@mirantis/mds-icon';
import ComponentDropdown from '@root/app/common/react/components/componentDropdown/ComponentDropdown';
import { StyledPermissionGroupControlPopover } from './StyledPermissionGroupControlPopover';
import AssignUsersToPermissionGroupModal from '@components/permissionGroups/assign-users-to-permission-group-modal/AssignUsersToPermissionGroupModal';
import CreateEditPermissionGroupModal from '@components/permissionGroups/create-edit-permission-group-modal/CreateEditPermissionGroupModal';
import { editPermissionGroup, fetchPermissionsGroup, setActivePermissionsGroupId, setPermissionGroupUsers, deletePermissionGroup, } from '@redux/dispatchers/permissions';
import { useSelector } from 'react-redux';
import RemovePermissionGroupPopover from './remove-permission-group/RemovePermissionGroupPopover';
import { getGroupIsEditableById, getGroupNameById } from './permissionGroupsUtils';
import { dropPaginationToDefault } from '@redux/dispatchers/pagination';
var PermissionGroupControlPopover = function (_a) {
    var config = _a.config, id = _a.id;
    var _b = useState(false), opened = _b[0], setOpened = _b[1];
    var _c = useState(false), isConfirmVisible = _c[0], toggleIsConfirmVisible = _c[1];
    var shouldHandleClickRef = useRef(true);
    var popoverRef = useRef(null);
    var assignUsersModalRef = useRef();
    var modifyPermissionsGroupModalRef = useRef();
    var allPermissionGroups = useSelector(function (store) { return store.permissions.allPermissionGroups.data; });
    var activeGroupId = useSelector(function (store) { return store.permissions.activeGroupId; });
    var isEditable = getGroupIsEditableById(allPermissionGroups, id);
    var name = getGroupNameById(allPermissionGroups, id);
    var handleDeletePermissionGroup = function () {
        dropPaginationToDefault();
        deletePermissionGroup(activeGroupId);
        toggleIsConfirmVisible(false);
    };
    useEffect(function () {
        if (!opened) {
            setActivePermissionsGroupId(null);
            toggleIsConfirmVisible(false);
        }
    }, [opened]);
    var handleSetRef = function (isOpened) {
        if (!isOpened && (assignUsersModalRef.current || modifyPermissionsGroupModalRef.current)) {
            return null;
        }
        setOpened(isOpened);
    };
    var onOpenChange = function (value) { return (shouldHandleClickRef.current = !value); };
    var handleSetPermissionGroupUsers = function (data) { return setPermissionGroupUsers({ data: data, config: config }); };
    useEffect(function () {
        shouldHandleClickRef.current = !isConfirmVisible;
        setOpened(isConfirmVisible);
    }, [isConfirmVisible]);
    var Raws = function () { return (React.createElement("div", { className: "permission-group-control-popover" },
        React.createElement(AssignUsersToPermissionGroupModal, { onOpen: setOpened, onSave: handleSetPermissionGroupUsers, setRef: function (ref) { return (assignUsersModalRef.current = ref); } },
            React.createElement("div", { className: "item" }, "Manage users in group")),
        React.createElement(CreateEditPermissionGroupModal, { title: "Edit Permission Group", onOpen: setOpened, onSaveApiCall: editPermissionGroup, initialGroupSchemaApiCall: fetchPermissionsGroup, setRef: function (ref) { return (modifyPermissionsGroupModalRef.current = ref); } },
            React.createElement("div", { className: "item" }, "Edit group")),
        isEditable && (React.createElement(Popover, { placement: "left", onOpenChange: onOpenChange, open: isConfirmVisible, content: React.createElement(RemovePermissionGroupPopover, { name: name, onOk: handleDeletePermissionGroup, onClose: function () { return toggleIsConfirmVisible(false); } }) },
            React.createElement("div", { className: "item", onClick: function () { return toggleIsConfirmVisible(true); } }, "Delete group"))))); };
    return (React.createElement(StyledPermissionGroupControlPopover, { ref: popoverRef },
        React.createElement(MoreIcon, { size: 22, onClick: function () {
                setActivePermissionsGroupId(id);
                setOpened(!opened);
            } }),
        React.createElement(ComponentDropdown, { show: opened, setShow: handleSetRef, relatedDropdownRefs: [popoverRef], shouldHandleClickRef: shouldHandleClickRef },
            React.createElement(Raws, null))));
};
export default PermissionGroupControlPopover;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { DateUtils } from '@utils/date/DateUtils';
import { DATE_RANGES } from '@utils/date/dateConstants';
export var getInitialState = function (dateRange, show, prevStartEndingDates) {
    var selected = dateRange.selected, _a = dateRange.calculatedDateRange, start = _a.start, end = _a.end;
    var isCustomRange = selected === DATE_RANGES.CUSTOM_RANGE;
    var startingDate = prevStartEndingDates.startingDate
        ? prevStartEndingDates.startingDate
        : moment(start.timeString);
    var endingDate = moment(startingDate);
    endingDate = DateUtils.recalculateDateByMonths(endingDate, 1, 'increase');
    return {
        show: isCustomRange || show,
        hoveredDate: '',
        startingDate: startingDate,
        endingDate: endingDate,
        selectedDateStart: isCustomRange ? start.timeString : '',
        selectedDateEnd: isCustomRange ? end.timeString : '',
    };
};
export var getHandleMonthSwitch = function (state, setState) { return function (action) {
    var startingDateClone = moment(state.startingDate);
    var endingDateClone = moment(state.endingDate);
    var startingDate = action === 'reduce'
        ? DateUtils.recalculateDateByMonths(startingDateClone, 2, 'reduce')
        : DateUtils.recalculateDateByMonths(startingDateClone, 2, 'increase');
    var endingDate = action === 'reduce'
        ? DateUtils.recalculateDateByMonths(endingDateClone, 2, 'reduce')
        : DateUtils.recalculateDateByMonths(endingDateClone, 2, 'increase');
    setState(__assign(__assign({}, state), { startingDate: startingDate, endingDate: endingDate }));
}; };
export var shouldChangeCustomDateRange = function (state, prevState) {
    return !isEmpty(state) &&
        !isEmpty(prevState) &&
        (prevState.selectedDateStart !== state.selectedDateStart || prevState.selectedDateEnd !== state.selectedDateEnd);
};

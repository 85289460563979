import { store } from '@redux/store';
import * as actionCreators from '@redux/action-creators';
export var changeGranularity = function (payload) {
    var action = actionCreators.multiFilter.changeGranularity(payload);
    store.dispatch(action);
};
export var changeTermV2 = function (payload) {
    var action = actionCreators.multiFilter.changeTermV2(payload);
    store.dispatch(action);
};
export var setFilterData = function (payload) {
    var action = actionCreators.multiFilter.setFilterData(payload);
    store.dispatch(action);
};

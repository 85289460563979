var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import uniq from 'lodash/uniq';
export var extractOptionsForMapFilter = function (customers) {
    var regionsOptions = [];
    var statusesOptions = [];
    var customersOptions = [];
    for (var _i = 0, customers_1 = customers; _i < customers_1.length; _i++) {
        var customer = customers_1[_i];
        if (customer.city && customer.city.region) {
            regionsOptions.push(customer.city.region);
            statusesOptions.push(customer.status);
            customersOptions.push("".concat(customer.name, " (...").concat(customer.id.substr(customer.id.length - 4), ")"));
        }
    }
    return {
        region: __spreadArray([], uniq(regionsOptions), true),
        status: __spreadArray([], uniq(statusesOptions), true),
        customer: __spreadArray([], customersOptions, true),
    };
};
export var extractOptionsByFilteringOptions = function (customers, filteringOptions) {
    var statusesOptions = [];
    var customersOptions = [];
    var region = filteringOptions.region, status = filteringOptions.status;
    for (var _i = 0, customers_2 = customers; _i < customers_2.length; _i++) {
        var customer = customers_2[_i];
        if (customer.city) {
            if (status &&
                (customer.status === status || status === 'All Statuses') &&
                (customer.city.region === region || region === 'All World')) {
                customersOptions.push("".concat(customer.name, " (...").concat(customer.id.substr(customer.id.length - 4), ")"));
            }
            if (region && !status && (customer.city.region === region || region === 'All World')) {
                statusesOptions.push(customer.status);
                customersOptions.push("".concat(customer.name, " (...").concat(customer.id.substr(customer.id.length - 4), ")"));
            }
        }
    }
    return {
        status: __spreadArray([], uniq(statusesOptions), true),
        customer: __spreadArray([], customersOptions, true),
    };
};

import * as Actions from '@redux/actions/permissions';
export var fetchPermissionsAllowedPagesIds = function () { return ({
    type: Actions.AsyncActions.FETCH_PERMISSIONS_ALLOWED_PAGES_IDS,
}); };
export var setPermissionsAllowedPagesLoading = function (payload) { return ({
    type: Actions.SyncActions.SET_PERMISSIONS_ALLOWED_PAGES_LOADING,
    payload: payload,
}); };
export var setPermissionsAllowedPagesTags = function (payload) { return ({
    type: Actions.SyncActions.SET_PERMISSIONS_ALLOWED_PAGES_TAGS,
    payload: payload,
}); };
export var setPermissionGroups = function (payload) { return ({
    type: Actions.SyncActions.SET_PERMISSION_GROUPS,
    payload: payload,
}); };
export var fetchPermissionGroups = function (payload) { return ({
    type: Actions.AsyncActions.FETCH_PERMISSION_GROUPS,
    payload: payload,
}); };
export var setActivePermissionsGroupId = function (payload) { return ({
    type: Actions.SyncActions.SET_ACTIVE_PERMISSIONS_GROUP_ID,
    payload: payload,
}); };
export var setPermissionGroupUsers = function (payload) { return ({
    type: Actions.SyncActions.SET_PERMISSION_GROUP_USERS,
    payload: payload,
}); };
export var createPermissionGroup = function (payload) { return ({
    type: Actions.AsyncActions.CREATE_PERMISSION_GROUP,
    payload: payload,
}); };
export var fetchPermissionsGroup = function (payload) { return ({
    type: Actions.AsyncActions.FETCH_PERMISSIONS_GROUP,
    payload: payload,
}); };
export var setPermissionsGroup = function (payload) { return ({
    type: Actions.SyncActions.SET_PERMISSIONS_GROUP,
    payload: payload,
}); };
export var editPermissionGroup = function (payload) { return ({
    type: Actions.AsyncActions.EDIT_PERMISSION_GROUP,
    payload: payload,
}); };
export var deletePermissionGroup = function (payload) { return ({
    type: Actions.AsyncActions.DELETE_PERMISSION_GROUP,
    payload: payload,
}); };
export var setActiveGroupAssignedUsers = function (payload) { return ({
    type: Actions.SyncActions.SET_ACTIVE_GROUP_ASSIGNED_USERS,
    payload: payload,
}); };
export var fetchPermissionGroupAssignedUsers = function (payload) { return ({
    type: Actions.AsyncActions.FETCH_PERMISSION_GROUP_USERS,
    payload: payload,
}); };
export var setPermissionsAllUsers = function (payload) { return ({
    type: Actions.SyncActions.SET_PERMISSIONS_ALL_USERS,
    payload: payload,
}); };
export var resetPermissionsAllUsers = function () { return ({
    type: Actions.SyncActions.RESET_PERMISSIONS_ALL_USERS,
}); };
export var fetchPermissionsAllUsers = function (payload) { return ({
    type: Actions.AsyncActions.FETCH_PERMISSION_ALL_USERS,
    payload: payload,
}); };
export var postPermissionsGroup = function (payload) { return ({
    type: Actions.AsyncActions.POST_PERMISSIONS_GROUP,
    payload: payload,
}); };
export var patchPermissionsGroup = function (payload) { return ({
    type: Actions.AsyncActions.PATCH_PERMISSIONS_GROUP,
    payload: payload,
}); };

import { INSTALLATIION_CHARTS, CHART_DASHBOARDS } from './installationChartConstants';
export var getInstallationsChartsConfigs = function (params) { return ({
    dashboard: CHART_DASHBOARDS.installations,
    charts: INSTALLATIION_CHARTS.map(function (_a) {
        var key = _a.key;
        return ({
            key: key,
            end: params.end,
            start: params.start,
            timezone: params.timezone,
            granularity: params.granularity,
            customerTypes: params.customerTypes.join(','),
        });
    }),
}); };
export var getNormalizedSuccesses = function (lineData) {
    if (lineData === void 0) { lineData = []; }
    return lineData.map(function (item) { return ({
        x: item.time,
        y: item.successes,
    }); });
};
export var getNormalizedBinaryExecutions = function (lineData) {
    if (lineData === void 0) { lineData = []; }
    return lineData.map(function (item) { return ({
        x: item.time,
        y: item.binary_executions,
    }); });
};
export var getNormalizedAttempts = function (lineData) {
    if (lineData === void 0) { lineData = []; }
    return lineData.map(function (item) { return ({
        x: item.time,
        y: item.attempts,
    }); });
};

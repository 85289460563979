import { store } from '@redux/store';
import * as actionCreators from '@redux/action-creators';
export var getSearchClusters = function (payload) {
    var action = actionCreators.clusters.getClusters(payload);
    store.dispatch(action);
};
export var patchClusterName = function (payload) {
    var action = actionCreators.clusters.patchClusterName(payload);
    store.dispatch(action);
};
export var updateClusterNameInRedux = function (payload) {
    var action = actionCreators.clusters.updateClusterNameInRedux(payload);
    store.dispatch(action);
};

import { connect } from 'react-redux';
import withUrlValidationHOC from '@HOC/withUrlValidationHOC/withUrlValidationHOC';
import ManageCustomers from './ManageCustomers';
import { getManageCustomersActiveTabFromUrl } from './manageCustomersUtils';
var mapState = function (store) { return ({
    customerMode: store.management.customerMode,
    singleCustomer: store.management.singleCustomer,
    activeTabFromUrl: getManageCustomersActiveTabFromUrl(),
}); };
var connector = connect(mapState);
export default withUrlValidationHOC(connector(ManageCustomers));

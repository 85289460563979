import { DateUtils } from '@utils/date/DateUtils';
import { PICKER_TO_REQUEST_VOCABULAR, RESPONSE_TO_STATE_VOCABULAR } from './trialReportConstants';
export var normalizers = {
    createdAt: function (value) {
        return value ? DateUtils.getDateStringWithDynamicTimezone(value) : value;
    },
    startedAt: function (value) {
        return value ? DateUtils.getDateStringWithDynamicTimezone(value) : value;
    },
    expiredAt: function (value) {
        return value ? DateUtils.getDateStringWithDynamicTimezone(value) : '';
    },
    licenseDownloadedAt: function (value) {
        return value ? DateUtils.getDateStringWithDynamicTimezone(value) : '';
    },
    status: function (value) {
        return value ? RESPONSE_TO_STATE_VOCABULAR[value] : '';
    },
    isMirantis: function (value) {
        return value ? 'Yes' : 'No';
    },
    isClusterCreated: function (value) {
        return value ? 'Yes' : 'No';
    },
};
export var translatePicker = function (options) {
    if (options === void 0) { options = []; }
    if (Array.isArray(options))
        return options.map(function (element) { return PICKER_TO_REQUEST_VOCABULAR[element]; });
    return PICKER_TO_REQUEST_VOCABULAR[options];
};
export var getCallApiParams = function (timezone, pagination, multiFilter, state, timeStart, timeEnd, statusesSelected, isMirantisSelected, clusterCreatedSelected) { return ({
    timezone: timezone,
    limit: pagination.limit,
    page: pagination.currentPage,
    search: multiFilter.filter.searchTerm,
    sortBy: state.sortBy,
    orderBy: state.orderBy,
    start: timeStart,
    end: timeEnd,
    statuses: translatePicker(statusesSelected).join(','),
    is_mirantis: translatePicker(isMirantisSelected).join(','),
    cluster_created: translatePicker(clusterCreatedSelected).join(','),
}); };
export var getNormalizedTrialReportStatisticsParams = function (trialReportParams) {
    return {
        cluster_created: (trialReportParams === null || trialReportParams === void 0 ? void 0 : trialReportParams.cluster_created) ? trialReportParams.cluster_created : null,
        is_mirantis: (trialReportParams === null || trialReportParams === void 0 ? void 0 : trialReportParams.is_mirantis) ? trialReportParams.is_mirantis : null,
        start: trialReportParams === null || trialReportParams === void 0 ? void 0 : trialReportParams.start,
        end: trialReportParams === null || trialReportParams === void 0 ? void 0 : trialReportParams.end,
        statuses: (trialReportParams === null || trialReportParams === void 0 ? void 0 : trialReportParams.statuses) ? trialReportParams.statuses : null,
    };
};

export var TITLES;
(function (TITLES) {
    TITLES["INITIAL"] = "Sign In to Mirantis \nCloud Native Console with";
    TITLES["SIGN_IN_STARTED"] = "Signing in...";
})(TITLES || (TITLES = {}));
export var BUTTON_LABELS;
(function (BUTTON_LABELS) {
    BUTTON_LABELS["GOOGLE"] = "Mirantis employee";
    BUTTON_LABELS["MCP"] = "Mirantis CloudCare Portal";
})(BUTTON_LABELS || (BUTTON_LABELS = {}));

export var SyncActions;
(function (SyncActions) {
    SyncActions["SET_USER_INFO"] = "SET_USER_INFO";
    SyncActions["SET_USER_PREFERENCES"] = "SET_USER_PREFERENCES";
    SyncActions["CHANGE_USER_PREFERENCE"] = "CHANGE_USER_PREFERENCE";
    SyncActions["APPLY_SETTINGS_SAVE"] = "APPLY_SETTINGS_SAVE";
    SyncActions["RESET_PREFERENCES"] = "RESET_PREFERENCES";
})(SyncActions || (SyncActions = {}));
export var AsyncActions;
(function (AsyncActions) {
    AsyncActions["HANDLE_PREFERENCES_SAVE"] = "HANDLE_PREFERENCES_SAVE";
})(AsyncActions || (AsyncActions = {}));

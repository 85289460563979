import { connect } from 'react-redux';
import { dropLastSyncDate } from '@actionCreators/mode/mode';
import { changeCurrentPage } from '@actionCreators/pagination/pagination';
import { getBootstrapInstallations } from '@actionCreators/bootstrapInstallations';
import InstallationsTable from './InstallationsTable';
var mapState = function (store) { return ({
    mode: store.mode.selected,
    filter: store.crossPageFilter,
    timezone: store.userInfo.settings.initial.timezone,
    bootstrapInstallations: store.bootstrapInstallations,
    pagination: store.pagination.bootstrapInstallations,
}); };
var mapDispatch = {
    changeCurrentPage: changeCurrentPage,
    getBootstrapInstallations: getBootstrapInstallations,
    dropLastSyncDate: dropLastSyncDate,
};
export default connect(mapState, mapDispatch)(InstallationsTable);

import * as Actions from '@redux/actions';
var r = {};
r[Actions.clusters.SyncActions.SET_CLUSTERS] = function (draft, payload) {
    var clusters = {
        loading: false,
        data: payload.data,
    };
    Object.assign(draft.searchClusters, clusters);
};
r[Actions.clusters.AsyncActions.GET_CLUSTERS] = function (draft) {
    draft.searchClusters.loading = true;
};
export { r as resolvers };

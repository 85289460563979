export var CUSTOMER_TYPES_PICKER = {
    name: 'Customer Type',
    pseudo: 'customerType',
    static: true,
};
export var LAST_SYNC_DATE_PICKER = {
    name: 'Hide inactive data',
    pseudo: 'lastSyncDate',
    static: true,
    singleSelect: true,
};
export var ALL = 'All';

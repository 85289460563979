import * as React from 'react';
import get from 'lodash/get';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getMKERecommendations } from '@redux/dispatchers/telemetry';
import { getNormalizedRecommendationData } from '../../../customer/recommendations/recommendationsUtils';
var Recommendations = function (_a) {
    var activeClusterId = _a.activeClusterId, clusterLicense = _a.clusterLicense;
    var recommendationsParams = {
        license: clusterLicense,
        cluster_id: activeClusterId,
        alert_types: 'Recommendation',
    };
    useEffect(function () {
        getMKERecommendations(recommendationsParams);
    }, []);
    var recommendations = useSelector(function (state) {
        return get(state.telemetry.customer.recommendations, "".concat(activeClusterId, ".data"), []);
    });
    var normalizedInfoItems = getNormalizedRecommendationData(recommendations);
    return (recommendations === null || recommendations === void 0 ? void 0 : recommendations.length) > 0 ? (React.createElement("table", { id: "recommendations-".concat(activeClusterId) },
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement("th", null, "#"),
                React.createElement("th", null, "Type"),
                React.createElement("th", null, "Description"),
                React.createElement("th", null, "Links"))),
        React.createElement("tbody", null, normalizedInfoItems.map(function (_a, index) {
            var type = _a.type, extraInfoItems = _a.extraInfoItems, description = _a.description;
            return (React.createElement("tr", { key: description + 1 },
                React.createElement("td", null, index + 1),
                React.createElement("td", null, type),
                React.createElement("td", null, description),
                React.createElement("td", null, extraInfoItems.map(function (_a, index) {
                    var value = _a.value, link = _a.link;
                    return (React.createElement("span", { key: value },
                        React.createElement("a", { target: "_blank", href: link, rel: "noreferrer" }, value),
                        index < extraInfoItems.length - 1 ? ', ' : '.'));
                }))));
        })))) : null;
};
export default Recommendations;

import React from 'react';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import CrossPageFilterContainer from '@commonComponents/cross-page-filter/CrossPageFilterContainer';
import CustomerTypesFilterContainer from '@commonComponents/customer-type-filter/CustomerTypesFilterContainer';
import FreemiumInstallationsCharts from './installation-charts/FreemiumInstallationsCharts';
import InstallationsTableContainer from './InstallationsTableContainer';
var PSEUDO_CC = DASHBOARDS_SCHEMA.REPORTS.PAGES.INSTALLATION_REPORT.PSEUDO_CC;
var InstallationReport = function () { return (React.createElement(React.Fragment, null,
    React.createElement("h2", null, "Installation Report"),
    React.createElement(CustomerTypesFilterContainer, null),
    React.createElement(FreemiumInstallationsCharts, null),
    React.createElement(CrossPageFilterContainer, { dashboardName: PSEUDO_CC, isGranularity: true }),
    React.createElement(InstallationsTableContainer, null))); };
export default InstallationReport;

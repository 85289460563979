var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import isEqual from 'lodash/isEqual';
import TableButton from '@commonComponents/editable-table/TableButton';
import EditableInput from '@commonComponents/editable-input/EditableInput';
import PureHTMLTooltip from '@commonComponents/pure-thml-tooltip/PureHTMLTooltip';
import { COLUMNS_KEYS } from './clusterHealthTableConstants';
import { isSFPermissionGroup } from '@commonUtils/commonUtils';
var ClusterHealthTr = function (_a) {
    var cluster = _a.cluster, columns = _a.columns, visibleColumns = _a.visibleColumns, handleSave = _a.handleSave;
    var _b = React.useState(cluster), initial = _b[0], setInitial = _b[1];
    var _c = React.useState(cluster), state = _c[0], setState = _c[1];
    var _d = React.useState(true), disabled = _d[0], setDisabled = _d[1];
    var equal = isEqual(initial, state);
    var prefix = !disabled && equal ? 'red' : 'blue';
    var handleChange = function (value, key) {
        var _a;
        setState(__assign(__assign({}, state), (_a = {}, _a[key] = value, _a)));
    };
    var handleBtnClick = function () {
        if (disabled) {
            setDisabled(false);
        }
        if (!disabled && equal) {
            setDisabled(true);
        }
        if (!disabled && !equal) {
            handleSave(state);
            setInitial(__assign({}, state));
            setDisabled(true);
        }
    };
    var tds = [];
    var isEditable = false;
    for (var _i = 0, columns_1 = columns; _i < columns_1.length; _i++) {
        var column = columns_1[_i];
        var key = column.key;
        if (visibleColumns.includes(key)) {
            if (column.isEditable) {
                isEditable = true;
            }
            var item = cluster[key];
            if (key === COLUMNS_KEYS.HEALTH_STATUS) {
                item = (React.createElement(PureHTMLTooltip, { text: "Alerts: ".concat(cluster.alertsTotal || '-') },
                    React.createElement("div", { className: "select healthStatus disabled ".concat(item) }, item)));
            }
            if (key === COLUMNS_KEYS.ANNOTATION) {
                item = (React.createElement(EditableInput, { value: state[key], disabled: disabled, onChange: function (value) { return handleChange(value, 'annotation'); } }));
            }
            tds.push(React.createElement("td", { key: key, className: "table__td" }, item));
        }
    }
    if (!isSFPermissionGroup() && isEditable) {
        tds.push(React.createElement("td", { key: cluster.clusterId, className: "tooltip-handler" },
            React.createElement(TableButton, { tooltip: "Edit annotation", prefix: prefix, disabled: disabled, handleBtnClick: handleBtnClick })));
    }
    return React.createElement(React.Fragment, null, tds);
};
export default ClusterHealthTr;

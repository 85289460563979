import isEmpty from 'lodash/isEmpty';
import Redirect from '@commonUtils/urlUtils/Redirect';
import { getAllQueryParams } from '@commonUtils/urlUtils/urlUtils';
import QUERY_PARAMS_VALIDATORS_MAP from './queryParamsValidatorsMap';
var QueryParamsValidationService = /** @class */ (function () {
    function QueryParamsValidationService() {
        this.validated = false;
    }
    QueryParamsValidationService.prototype.init = function () {
        if (this.validated === false) {
            QueryParamsValidationService.validateUrl();
            this.validated = true;
        }
    };
    QueryParamsValidationService.validateUrl = function () {
        var validatedSearch = this.getValidatedSearch();
        if (window.location.search !== validatedSearch) {
            Redirect.goTo("".concat(window.location.pathname).concat(validatedSearch));
        }
    };
    QueryParamsValidationService.getValidatedSearch = function () {
        var prevQueries = getAllQueryParams(window.location.search);
        var queries = this.getValidatedQueries(Object.entries(prevQueries));
        return !isEmpty(queries) ? "?".concat(queries.join('&')) : '';
    };
    QueryParamsValidationService.getValidatedQuery = function (key, value) {
        var validatedQuery = QUERY_PARAMS_VALIDATORS_MAP[key] ? QUERY_PARAMS_VALIDATORS_MAP[key](value) : null;
        return isEmpty(validatedQuery) ? null : "".concat(key, "=").concat(validatedQuery);
    };
    QueryParamsValidationService.getValidatedQueries = function (prevQueries) {
        var _this = this;
        var nextQueries = [];
        prevQueries.forEach(function (query) {
            var key = query[0], value = query[1];
            var finalValue = value.split(',');
            var validatedQuery = _this.getValidatedQuery(key, finalValue);
            if (!isEmpty(validatedQuery))
                nextQueries.push(validatedQuery);
        });
        return nextQueries;
    };
    return QueryParamsValidationService;
}());
export default QueryParamsValidationService;

import React, { useEffect } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Checkbox } from '@mirantis/mds-checkbox';
import { fetchPermissionGroupAssignedUsers } from '@redux/dispatchers/permissions';
import { CheckedType, changeElement, changeGroup } from './CreateEditPermissionGroupUtils';
import PermissionDashboardNotification from './PermissionDashboardNotification';
import { DASHBOARDS_LABElS } from '../permissionGroupsConstants';
var DashboardsCheckBoxes = function (props) {
    var newDashboardSchema = props.newDashboardSchema, setDashboardSchema = props.setDashboardSchema;
    var activeGroupId = useSelector(function (store) { return store.permissions.activeGroupId; });
    var activeGroupAssignedUsersIds = useSelector(function (store) { return store.permissions.activeGroupAssignedUsers; });
    var prevDashboards = useSelector(function (store) { return store.permissions.permissionGroup.dashboards; });
    var userId = useSelector(function (store) { return store.userInfo.userPreferences.id; });
    useEffect(function () {
        if (activeGroupId) {
            fetchPermissionGroupAssignedUsers({ groupId: activeGroupId });
        }
    }, [activeGroupId]);
    var handleOnchangeElement = function (id) {
        setDashboardSchema(changeElement(id, newDashboardSchema));
    };
    var handleOnchangeGroup = function (id) {
        setDashboardSchema(changeGroup(id, newDashboardSchema));
    };
    var oldPermissionPage = prevDashboards[1].items[2];
    var newPermissionPage = newDashboardSchema[1].items[2];
    var isPermissionPageEnabled = activeGroupAssignedUsersIds.map(function (_a) {
        var id = _a.id;
        return id;
    }).includes(userId) &&
        oldPermissionPage.isSelected &&
        !newPermissionPage.isSelected;
    return (React.createElement(React.Fragment, null, newDashboardSchema.map(function (element) {
        return (React.createElement("div", { key: element.section, style: { marginTop: '20px' } },
            element.items.length && element.section !== 'null' && element.section !== '' ? (React.createElement(Checkbox, { label: get(DASHBOARDS_LABElS, element.section, 'no-page'), value: CheckedType(element.items), onChange: function () { return handleOnchangeGroup(element.section); } })) : (React.createElement(React.Fragment, null)),
            element.items.map(function (page) { return (React.createElement("div", { key: page.id, style: { marginLeft: element.section ? '20px' : '', position: 'relative' } },
                React.createElement(Checkbox, { label: get(DASHBOARDS_LABElS, page.tag, 'no-page'), value: page.isSelected, onChange: function () { return handleOnchangeElement(page.id); } }),
                newPermissionPage.id === page.id && isPermissionPageEnabled && (React.createElement(PermissionDashboardNotification, null)))); })));
    })));
};
export default DashboardsCheckBoxes;

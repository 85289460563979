var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
var ObjectComponent = function (_a) {
    var image = _a.image;
    return (React.createElement("object", { data: image, type: "image/svg+xml", className: "svg", "data-testid": "object" }));
};
var ImgComponent = function (_a) {
    var image = _a.image, imageClassName = _a.imageClassName;
    return (React.createElement("img", { src: image, className: imageClassName, alt: imageClassName }));
};
var LazyImageFactory = /** @class */ (function (_super) {
    __extends(LazyImageFactory, _super);
    function LazyImageFactory(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            image: undefined,
        };
        _this.importer = function () { return import("@img/".concat(_this.imageName)); };
        _this.imageName = props.imageName;
        _this.type = props.type || 'img';
        _this.imageClassName = props.imageClassName || '';
        return _this;
    }
    LazyImageFactory.prototype.render = function () {
        var Resolver = LazyImageFactory.list()[this.type];
        return this.state.image ? React.createElement(Resolver, { image: this.state.image, imageClassName: this.imageClassName }) : null;
    };
    LazyImageFactory.prototype.shouldComponentUpdate = function (nextProps, nextState, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    nextContext) {
        return this.state.image !== nextState.image;
    };
    LazyImageFactory.prototype.componentDidMount = function () {
        var _this = this;
        // we added mounted flag to avoid state update on unmounted component
        this.mounted = true;
        this.importer().then(function (res) {
            if (_this.mounted) {
                _this.setState({
                    image: res.default,
                });
            }
        });
    };
    LazyImageFactory.prototype.componentWillUnmount = function () {
        this.mounted = false;
    };
    LazyImageFactory.list = function () { return ({
        object: ObjectComponent,
        img: ImgComponent,
    }); };
    return LazyImageFactory;
}(React.Component));
export default LazyImageFactory;

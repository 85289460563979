import React from 'react';
import { connect } from 'react-redux';
import { getMultiFilterPickers } from '@configs/multiFilterConfig';
import { changeTermV2 } from '@actionCreators/multiFilter/multiFilter';
import { changeCurrentPage } from '@actionCreators/pagination/pagination';
import FilterContainer from '@commonComponents/filter/FilterContainer';
import Search from '@commonComponents/search/Search';
import { StringUtils } from '@utils/string/StringUtils';
import { resetSearchValueInUrl } from '@commonComponents/search/searchUtils';
import { SEARCH_PLACEHOLDER } from '@commonConstants/commonConstants';
var mapState = function (store) { return ({
    mode: store.mode,
    multiFilter: store.multiFilter,
}); };
var mapDispatch = {
    changeTermV2: changeTermV2,
    changeCurrentPage: changeCurrentPage,
};
var connector = connect(mapState, mapDispatch);
var SearchClustersFilter = function (props) {
    var name = props.filterName;
    var params = {};
    var onSearchChange = function (value) {
        var length = StringUtils.countStringLengthWithoutSpaces(value);
        if (value === '' || length > 2) {
            props.changeTermV2({
                tableName: name,
                pseudo: 'searchTerm',
                value: value,
            });
        }
    };
    var resetAdditionalParams = function () {
        resetSearchValueInUrl();
        onSearchChange('');
    };
    return (React.createElement(FilterContainer, { name: name, params: params, currentMode: props.mode, multiFilter: props.multiFilter, isOptionsHidden: true, isPaginationOn: true, doNotUpdate: true, pickers: getMultiFilterPickers(name), resetAdditionalParams: resetAdditionalParams },
        React.createElement(Search, { label: "Clusters search", placeholder: SEARCH_PLACEHOLDER, withDelay: 1500, max: 64, onChange: onSearchChange })));
};
export default connector(SearchClustersFilter);

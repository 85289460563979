var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css, jsx } from '@emotion/react';
import { MQ } from '@emotionConstants';
var base = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    width: calc(100% - 435px);\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n    ", " {\n        width: calc(100% - 375px);\n    }\n\n    ", " {\n        width: 100%;\n        height: 100%;\n        align-items: flex-start;\n        justify-content: flex-start;\n    }\n"], ["\n    position: relative;\n    width: calc(100% - 435px);\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n    ", " {\n        width: calc(100% - 375px);\n    }\n\n    ", " {\n        width: 100%;\n        height: 100%;\n        align-items: flex-start;\n        justify-content: flex-start;\n    }\n"])), MQ['xxlg'], MQ['lg']);
var small = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: calc(100% - 730px);\n\n    ", " {\n        width: calc(100% - 550px);\n    }\n\n    ", " {\n        width: calc(100% - 450px);\n    }\n\n    ", " {\n        width: 100%;\n    }\n"], ["\n    width: calc(100% - 730px);\n\n    ", " {\n        width: calc(100% - 550px);\n    }\n\n    ", " {\n        width: calc(100% - 450px);\n    }\n\n    ", " {\n        width: 100%;\n    }\n"])), MQ['xxlg'], MQ['xlg'], MQ['lg']);
var NonSignedInContent = function (_a) {
    var children = _a.children, isSmall = _a.isSmall;
    return (
    // @ts-ignore
    jsx("div", { className: "content", css: [base, isSmall && small] }, children));
};
export default NonSignedInContent;
var templateObject_1, templateObject_2;

import moment from 'moment';
import last from 'lodash/last';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import toNumber from 'lodash/toNumber';
import queryString from 'query-string';
import { MONTHS } from '@commonConstants/commonConstants';
import { CHART_DASHBOARDS, COSTS_CHARTS_KEYS } from './costsConstants';
export var getCostsChartsConfigs = function (multiFilter, dateRange) {
    return {
        dashboard: CHART_DASHBOARDS.COSTS_CHARTS,
        charts: [
            {
                start: dateRange.start.timeStamp,
                end: dateRange.end.timeStamp,
                key: COSTS_CHARTS_KEYS.COSTS_CHARTS,
                accountIds: get(multiFilter, 'filter.selected.awsAccounts', [])
                    .map(function (id) { return id; })
                    .join(','),
            },
        ],
    };
};
export var getNormalizedCosts = function (value, groupedByMonth, groupByYear) {
    if (value === void 0) { value = []; }
    if (groupedByMonth === void 0) { groupedByMonth = ''; }
    if (groupByYear === void 0) { groupByYear = ''; }
    return value.map(function (account) {
        var normalizedData = {};
        get(account, 'resultsCosts', []).forEach(function (item) {
            if (groupedByMonth === 'month') {
                var year = moment(item.date).format('Y');
                var label = moment(item.date).format('Y MMM');
                if (groupByYear !== year) {
                    return;
                }
                normalizedData[label] = {
                    label: label,
                    key: label,
                    value: get(normalizedData, "".concat(label, ".value"), 0) + toNumber(item.amount),
                };
            }
            else {
                var year = moment(item.date).format('Y');
                var monthName = moment().month(moment(item.date).month()).format('MMMM');
                if (groupByYear !== year) {
                    return;
                }
                if (groupedByMonth === monthName) {
                    normalizedData[item.date] = {
                        key: moment(item.date).format('D'),
                        label: moment(item.date).format('D dd'),
                        value: get(normalizedData, "".concat(item.date, ".value"), 0) + toNumber(item.amount),
                    };
                }
            }
        });
        return orderBy(Object.values(normalizedData).map(function (_a) {
            var label = _a.label, value = _a.value, key = _a.key;
            return ({ x: label, y: value, key: key });
        }), function (_a) {
            var key = _a.key;
            return parseInt(key);
        });
    });
};
export var getNewMultiFilter = function (selectedYear) {
    var currentMonth = moment().month();
    var currentYear = moment().year().toString();
    var monthFromUrl = get(queryString.parse(window.location.search), 'month', null);
    var newMonths = [];
    var selectedMonth = '';
    if (selectedYear !== currentYear) {
        newMonths = MONTHS.slice(currentMonth);
        selectedMonth = newMonths.includes(monthFromUrl) ? monthFromUrl : newMonths[0];
    }
    else {
        newMonths = MONTHS.slice(0, currentMonth + 1);
        selectedMonth = newMonths.includes(monthFromUrl) ? monthFromUrl : last(newMonths);
    }
    return {
        newMonths: newMonths,
        selectedMonth: selectedMonth,
    };
};

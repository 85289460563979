var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { message } from 'antd';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getClusters as getClustersAPI, patchClusterName as getPatchClusterNameAPI, } from '@api/clusters-api/clustersApi';
import { setClusters, updateClusterNameInRedux } from '@actionCreators/clusters/clusters';
import * as Actions from '@redux/actions';
function patchClusterName(action) {
    var params, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                params = {
                    name: action.payload.name,
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 5, , 6]);
                return [4 /*yield*/, call(getPatchClusterNameAPI, action.payload.id, params)];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(updateClusterNameInRedux({
                        id: action.payload.id,
                        name: action.payload.name,
                        table: action.payload.table,
                        dashboard: action.payload.dashboard,
                    }))];
            case 3:
                _a.sent();
                return [4 /*yield*/, message.success('Cluster name edited successfully')];
            case 4:
                _a.sent();
                return [3 /*break*/, 6];
            case 5:
                e_1 = _a.sent();
                console.error('Failed to patch cluster name');
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function getClusters(_a) {
    var clusters, e_2;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(getClustersAPI, payload)];
            case 1:
                clusters = _b.sent();
                return [4 /*yield*/, put(setClusters(clusters))];
            case 2:
                _b.sent();
                return [3 /*break*/, 4];
            case 3:
                e_2 = _b.sent();
                console.error('Failed to get clusters', e_2);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
export default function clustersSaga() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeEvery(Actions.clusters.AsyncActions.GET_CLUSTERS, getClusters)];
            case 1: return [4 /*yield*/, _a.apply(void 0, [[_c.sent()]])];
            case 2:
                _c.sent();
                _b = all;
                return [4 /*yield*/, takeEvery(Actions.clusters.AsyncActions.PATCH_CLUSTER_NAME, patchClusterName)];
            case 3: return [4 /*yield*/, _b.apply(void 0, [[_c.sent()]])];
            case 4:
                _c.sent();
                return [2 /*return*/];
        }
    });
}

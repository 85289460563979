import get from 'lodash/get';
import queryString from 'query-string';
import Redirect from '@commonUtils/urlUtils/Redirect';
import { URL_QUERIES } from '@commonConstants/urlConstants';
var TRIAL_STATISTIC_ACTIVE_TAB = URL_QUERIES.TRIAL_STATISTIC.TRIAL_STATISTIC_ACTIVE_TAB;
export var setTrialStatisticTabToUrl = function (tabTitle) {
    return Redirect.toCurrentPageWithQueryParams(TRIAL_STATISTIC_ACTIVE_TAB, [tabTitle], '');
};
export var getTrialStatisticActiveTabFromUrl = function () {
    return get(queryString.parse(window.location.search), TRIAL_STATISTIC_ACTIVE_TAB, null);
};

import React from 'react';
import CustomerTypesFilterContainer from '@commonComponents/customer-type-filter/CustomerTypesFilterContainer';
import AlertsFilter from './AlertsFilter';
import AlertCharts from './alerts-charts/AlertsCharts';
var Alerts = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", null, "Alerts (Opscare)"),
        React.createElement(CustomerTypesFilterContainer, null),
        React.createElement(AlertsFilter, null),
        React.createElement(AlertCharts, null)));
};
export default Alerts;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import merge from 'lodash/merge';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import * as Actions from '@redux/actions/telemetry';
import * as ClustersActions from '@redux/actions/clusters';
import { initialState } from './reducer';
var r = {};
r[Actions.AsyncActions.GET_TELEMETRY_CUSTOMERS] = function (draft) {
    draft.customers.loading = true;
};
r[Actions.SyncActions.SET_CLUSTER_SPECS] = function (draft, payload) {
    draft.clusterSpecs = payload;
};
r[Actions.SyncActions.SET_TELEMETRY_CUSTOMERS] = function (draft, payload) {
    draft.customers.loading = false;
    draft.customers.data = payload.data;
};
r[Actions.AsyncActions.GET_TELEMETRY_CUSTOMER] = function (draft) {
    draft.customer.loading = true;
};
r[Actions.SyncActions.SET_TELEMETRY_CUSTOMER] = function (draft, payload) {
    merge(draft.customer, __assign({ loading: false }, payload));
    draft.customer.overview = payload.overview;
    draft.customer.mccOverview = payload.mccOverview;
    draft.customer.mkeOverview = payload.mkeOverview;
};
r[Actions.SyncActions.RESET_MKE_TELEMETRY_CUSTOMER] = function (draft) {
    draft.customer = initialState.customer;
};
r[Actions.AsyncActions.GET_MKE_ALERTS] = function (draft) {
    draft.customer.alerts.loading = true;
};
r[Actions.SyncActions.SET_MKE_ALERTS] = function (draft, payload) {
    set(draft.customer.alerts, "".concat(payload.clusterId, ".loading"), false);
    set(draft.customer.alerts, "".concat(payload.clusterId, ".data"), payload.data);
};
r[Actions.SyncActions.DROP_MKE_ALERTS_TO_DEFAULT] = function (draft) {
    draft.customer.alerts = cloneDeep(initialState.customer.alerts);
};
r[Actions.AsyncActions.GET_MKE_RECOMMENDATIONS] = function (draft) {
    draft.customer.recommendations.loading = true;
};
r[Actions.SyncActions.SET_MKE_RECOMMENDATIONS] = function (draft, payload) {
    set(draft.customer.recommendations, "".concat(payload.clusterId, ".loading"), false);
    set(draft.customer.recommendations, "".concat(payload.clusterId, ".data"), payload.data);
};
r[Actions.SyncActions.DROP_MKE_RECOMMENDATIONS_TO_DEFAULT] = function (draft) {
    draft.customer.recommendations = cloneDeep(initialState.customer.recommendations);
};
r[Actions.SyncActions.SET_TELEMETRY_CUSTOMER_ERROR] = function (draft, payload) {
    draft.customer.error = payload;
};
r[ClustersActions.SyncActions.UPDATE_CLUSTER_NAME_IN_REDUX] = function (draft, payload) {
    if (payload.dashboard === 'telemetry') {
        draft.customer[payload.table].clusters[payload.id].clusterName = payload.name;
    }
};
export { r as resolvers };

import { store } from '@redux/store';
export var getAllowedPagesTags = function () { return store.getState().permissions.allowedPagesTags.data; };
export var isAllPagePermissionGranted = function (ids, allowedPagesTags) {
    if (allowedPagesTags === void 0) { allowedPagesTags = getAllowedPagesTags(); }
    return ids.every(function (pageId) { return allowedPagesTags.includes(pageId); }) || ids.includes(location.pathname);
};
export var isSomePagePermissionGranted = function (ids, allowedPagesTags) {
    if (allowedPagesTags === void 0) { allowedPagesTags = getAllowedPagesTags(); }
    return ids.some(function (pageId) { return allowedPagesTags.includes(pageId); });
};
export var isPagePermissionGrantedWrapper = function (allowedPagesTags) { return function (ids) {
    return isAllPagePermissionGranted(ids, allowedPagesTags);
}; };

import { camelToSnakeCase } from '@commonUtils/commonUtils';
export var NO_OPENSTACK_USAGE_CLUSTERS_FOUND = 'No Openstack Usage found';
export var COLUMNS_KEYS = {
    CLUSTER_ID: 'clusterId',
    CLUSTER_NAME: 'clusterName',
    CLUSTER_VERSION: 'clusterVersion',
    CLUSTER_UPDATING: 'clusterUpdating',
    INSTANCE_ID: 'instanceId',
    INSTANCE_NAME: 'instanceName',
    CUSTOMER_ID: 'customerId',
    CUSTOMER_NAME: 'customerName',
    MANAGEMENT_ID: 'managementId',
    REGION_ID: 'regionId',
    PROVIDER: 'provider',
    CPU: 'cpu',
    CPU_PERCENTAGE: 'cpuPercentage',
    MEMORY: 'memory',
    MEMORY_PERCENTAGE: 'memoryPercentage',
    DISK: 'disk',
    DISK_PERCENTAGE: 'diskPercentage',
    TENANTS: 'tenants',
    NODES: 'nodes',
    VMS: 'vms',
    VOLUMES: 'volumes',
    IMAGES: 'images',
    NETWORKS: 'networks',
    LOAD_BALANCERS: 'loadbalancers',
    MASTER_NODES: 'masterNodes',
    WORKER_NODES: 'workerNodes',
    TOTAL_NODES: 'totalNodes',
    LAST_SYNC_DATE: 'lastSyncDate',
    CLUSTER_LAST_SYNC_DATE: 'clusterLastSyncDate',
    MCC_VERSION: 'mccVersion',
};
export var COLUMNS = [
    {
        name: 'Customer',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.CUSTOMER_NAME),
        key: COLUMNS_KEYS.CUSTOMER_NAME,
        isVisibleByDefault: true,
    },
    {
        name: 'OpenStack Child Cluster',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.CLUSTER_ID),
        key: COLUMNS_KEYS.CLUSTER_ID,
        isVisibleByDefault: true,
    },
    {
        name: 'Cluster Name',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.CLUSTER_NAME),
        key: COLUMNS_KEYS.CLUSTER_NAME,
    },
    {
        name: 'MOSK',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.CLUSTER_VERSION),
        key: COLUMNS_KEYS.CLUSTER_VERSION,
        isVisibleByDefault: true,
    },
    {
        name: 'Total Nodes',
        key: COLUMNS_KEYS.TOTAL_NODES,
        isVisibleByDefault: true,
    },
    {
        name: 'Compute Nodes',
        key: COLUMNS_KEYS.NODES,
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.NODES),
        isVisibleByDefault: true,
    },
    {
        name: 'VMs',
        key: COLUMNS_KEYS.VMS,
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.VMS),
        isVisibleByDefault: true,
    },
    {
        name: 'CPU Usage %',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.CPU_PERCENTAGE),
        key: COLUMNS_KEYS.CPU_PERCENTAGE,
        isVisibleByDefault: true,
    },
    {
        name: 'RAM Usage %',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.MEMORY_PERCENTAGE),
        key: COLUMNS_KEYS.MEMORY_PERCENTAGE,
        isVisibleByDefault: true,
    },
    {
        name: 'Cluster Updating',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.CLUSTER_UPDATING),
        key: COLUMNS_KEYS.CLUSTER_UPDATING,
    },
    {
        name: 'Instance ID',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.INSTANCE_ID),
        key: COLUMNS_KEYS.INSTANCE_ID,
    },
    {
        name: 'Customer ID',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.CUSTOMER_ID),
        key: COLUMNS_KEYS.CUSTOMER_ID,
    },
    {
        name: 'Instance Name',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.INSTANCE_NAME),
        key: COLUMNS_KEYS.INSTANCE_NAME,
    },
    {
        name: 'Management ID',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.MANAGEMENT_ID),
        key: COLUMNS_KEYS.MANAGEMENT_ID,
    },
    {
        name: 'Region ID',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.REGION_ID),
        key: COLUMNS_KEYS.REGION_ID,
    },
    {
        name: 'Provider',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.PROVIDER),
        key: COLUMNS_KEYS.PROVIDER,
    },
    {
        name: 'CPU',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.CPU),
        key: COLUMNS_KEYS.CPU,
    },
    {
        name: 'Memory',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.MEMORY),
        key: COLUMNS_KEYS.MEMORY,
    },
    {
        name: 'Disk',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.DISK),
        key: COLUMNS_KEYS.DISK,
    },
    {
        name: 'Disk Usage %',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.DISK_PERCENTAGE),
        key: COLUMNS_KEYS.DISK_PERCENTAGE,
    },
    {
        name: 'Tenants',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.TENANTS),
        key: COLUMNS_KEYS.TENANTS,
    },
    {
        name: 'Volumes',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.VOLUMES),
        key: COLUMNS_KEYS.VOLUMES,
    },
    {
        name: 'Images',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.IMAGES),
        key: COLUMNS_KEYS.IMAGES,
    },
    {
        name: 'Networks',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.NETWORKS),
        key: COLUMNS_KEYS.NETWORKS,
    },
    {
        name: 'Load Balancers',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.LOAD_BALANCERS),
        key: COLUMNS_KEYS.LOAD_BALANCERS,
    },
    {
        name: 'Last Sync Date',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.LAST_SYNC_DATE),
        key: COLUMNS_KEYS.LAST_SYNC_DATE,
    },
    {
        name: 'Cluster Last Sync Date',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.CLUSTER_LAST_SYNC_DATE),
        key: COLUMNS_KEYS.CLUSTER_LAST_SYNC_DATE,
    },
    {
        name: 'MCC Version',
        sortByKey: camelToSnakeCase(COLUMNS_KEYS.MCC_VERSION),
        key: COLUMNS_KEYS.MCC_VERSION,
    },
];

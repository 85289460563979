export var getFilterResult = function (res, selected) {
    var _a, _b;
    return Object.assign(res, ((_a = selected.customer) === null || _a === void 0 ? void 0 : _a.length) > 0 && {
        customers_count: selected.customer.length,
    }, ((_b = selected.cluster) === null || _b === void 0 ? void 0 : _b.length) > 0
        ? {
            clusters_count: selected.cluster.length,
        }
        : {
            clusters_count: res.clusters_count,
        }, { nodes_count: res.nodes });
};

import * as Actions from '@redux/actions';
export var getTopCustomers = function (payload) { return ({
    type: Actions.home.AsyncActions.GET_TOP_CUSTOMERS,
    payload: payload,
}); };
export var setTopCustomers = function (payload) { return ({
    type: Actions.home.SyncActions.SET_TOP_CUSTOMERS,
    payload: payload,
}); };
export var filterCustomers = function (payload) { return ({
    type: Actions.home.SyncActions.FILTER_CUSTOMERS,
    payload: payload,
}); };
export var setFilteredCustomers = function (payload) { return ({
    type: Actions.home.SyncActions.SET_FILTEREd_CUSTOMERS,
    payload: payload,
}); };
export var resetFilter = function () { return ({
    type: Actions.home.SyncActions.RESET_FILTER,
}); };
export var setMapFilterOptions = function (payload) { return ({
    type: Actions.home.SyncActions.SET_MAP_FILTER_OPTIONS,
    payload: payload,
}); };
export var changeOption = function (payload) { return ({
    type: Actions.home.SyncActions.CHANGE_OPTION,
    payload: payload,
}); };
export var getCustomersForMap = function (payload) { return ({
    type: Actions.home.AsyncActions.GET_CUSTOMERS_FOR_MAP,
    payload: payload,
}); };

export var TITLE_STYLE = {
    FIRST: { marginTop: 0, marginBottom: 20 },
    GENERAL: { marginTop: 20, marginBottom: 20 },
};
export var MKE_TABLE_TITLE = 'MKE Cluster Overview';
export var NO_CLUSTERS_FOUND = 'No MKE Clusters found';
export var MKE_CLUSTERS_TABLE_SUBTITLES = [
    {
        name: 'Last poll',
        key: 'lastSyncDate',
        parentColumnKey: 'overallInfo',
    },
    {
        name: 'MCR',
        key: 'mcrVersion',
        parentColumnKey: 'versions',
    },
    {
        name: 'MKE',
        key: 'mkeVersion',
        parentColumnKey: 'versions',
    },
    {
        name: 'MSR',
        key: 'msrVersion',
        parentColumnKey: 'versions',
    },
    {
        name: 'Status',
        key: 'utilizationStatus',
        parentColumnKey: 'utilizationAverages',
        tag: true,
    },
    {
        name: 'CPU',
        key: 'cpu',
        parentColumnKey: 'utilizationAverages',
    },
    {
        name: 'Memory',
        key: 'memory',
        parentColumnKey: 'utilizationAverages',
    },
    {
        name: 'Nodes',
        key: 'nodeCount',
        parentColumnKey: 'utilizationAverages',
    },
    {
        name: 'Status',
        key: 'healthStatus',
        tag: true,
        parentColumnKey: 'health',
    },
    {
        name: 'Critical',
        key: 'healthCriticalCount',
        parentColumnKey: 'health',
    },
    {
        name: 'Warning',
        key: 'healthWarningCount',
        parentColumnKey: 'health',
    },
    {
        name: 'Info',
        key: 'healthInfoCount',
        parentColumnKey: 'health',
    },
];
export var MKE_CUSTOM_CLUSTERS_TABLE_SUBTITLES = [
    {
        name: 'Last poll',
        key: 'lastSyncDate',
        parentColumnKey: 'overallInfo',
    },
    {
        name: 'Status',
        key: 'utilizationStatus',
        parentColumnKey: 'utilizationAverages',
        tag: true,
    },
    {
        name: 'CPU',
        key: 'cpu',
        parentColumnKey: 'utilizationAverages',
    },
    {
        name: 'Memory',
        key: 'memory',
        parentColumnKey: 'utilizationAverages',
    },
    {
        name: 'Nodes',
        key: 'nodeCount',
        parentColumnKey: 'utilizationAverages',
    },
];
export var MKE_CLUSTERS_TABLE_COLUMNS = [
    {
        name: '',
        key: 'overallInfo',
        subtitles: [MKE_CLUSTERS_TABLE_SUBTITLES[0]],
    },
    {
        name: 'Versions',
        key: 'versions',
        subtitles: [MKE_CLUSTERS_TABLE_SUBTITLES[1], MKE_CLUSTERS_TABLE_SUBTITLES[2], MKE_CLUSTERS_TABLE_SUBTITLES[3]],
    },
    {
        name: 'Utilization',
        key: 'utilizationAverages',
        subtitles: [
            MKE_CLUSTERS_TABLE_SUBTITLES[4],
            MKE_CLUSTERS_TABLE_SUBTITLES[5],
            MKE_CLUSTERS_TABLE_SUBTITLES[6],
            MKE_CLUSTERS_TABLE_SUBTITLES[7],
        ],
    },
    {
        name: 'Health',
        key: 'health',
        subtitles: [
            MKE_CLUSTERS_TABLE_SUBTITLES[8],
            MKE_CLUSTERS_TABLE_SUBTITLES[9],
            MKE_CLUSTERS_TABLE_SUBTITLES[10],
            MKE_CLUSTERS_TABLE_SUBTITLES[11],
        ],
    },
];
export var MKE_CUSTOM_CLUSTERS_TABLE_COLUMNS = [
    {
        name: '',
        key: 'overallInfo',
        subtitles: [MKE_CLUSTERS_TABLE_SUBTITLES[0]],
    },
    {
        name: 'Utilization',
        key: 'utilizationAverages',
        subtitles: [
            MKE_CLUSTERS_TABLE_SUBTITLES[4],
            MKE_CLUSTERS_TABLE_SUBTITLES[5],
            MKE_CLUSTERS_TABLE_SUBTITLES[6],
            MKE_CLUSTERS_TABLE_SUBTITLES[7],
        ],
    },
];

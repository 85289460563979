var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import partition from 'lodash/partition';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import get from 'lodash/get';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import { LAST_SYNC_DATE_OPTIONS } from '@configs/modeSwitcher';
import { isEveryItemInArrayObject } from '@commonUtils/commonUtils';
var _a = URL_QUERIES.COMMON, HAS_ACTIVE_MKE_LICENSE = _a.HAS_ACTIVE_MKE_LICENSE, IS_MCC_CUSTOMER = _a.IS_MCC_CUSTOMER, SEARCH_VALUE = _a.SEARCH_VALUE, HIDE_INACTIVE_CUSTOMERS = _a.HIDE_INACTIVE_CUSTOMERS;
var _b = URL_QUERIES.PICKERS, EXTERNAL_PICKER = _b.EXTERNAL_PICKER, STACKED_PICKER = _b.STACKED_PICKER, CLUSTER_PICKER = _b.CLUSTER_PICKER, CUSTOMER_PICKER = _b.CUSTOMER_PICKER, INSTANCE_PICKER = _b.INSTANCE_PICKER, PERMISSIONS_PICKER = _b.PERMISSIONS_PICKER, MANAGE_USERS_STATUS_PICKER = _b.MANAGE_USERS_STATUS_PICKER;
export var getMergeFilterParamsConditions = function (queries) {
    var shouldSetInactiveHoursThreshold = !isNil(queries.lastSyncDate);
    return {
        shouldSetInactiveHoursThreshold: shouldSetInactiveHoursThreshold,
        shouldSetCustomerTypes: !isNil(queries.customerType),
        shouldSetCustomerIds: !isNil(queries.customer),
        shouldSetInstanceIds: !isNil(queries.instance),
        shouldSetClusterIds: !isNil(queries.cluster),
        shouldSetPermissionsIds: !isNil(queries.permissions),
    };
};
export var mergeFilterSelected = function (filter, queries) {
    for (var query in queries) {
        if (Object.prototype.hasOwnProperty.call(queries, query)) {
            if (query in filter.selected) {
                var finalValue = void 0;
                if (isArray(queries[query])) {
                    finalValue = queries[query];
                }
                else {
                    finalValue = queries[query] === '' ? [] : [queries[query]];
                }
                if ([HAS_ACTIVE_MKE_LICENSE, IS_MCC_CUSTOMER, HIDE_INACTIVE_CUSTOMERS].includes(query)) {
                    finalValue = [queries[query] === 'true'];
                }
                filter.selected[query] = finalValue;
            }
            if (!isNil(filter.searchTerm)) {
                filter.searchTerm = queries[SEARCH_VALUE];
            }
            if (!isNil(queries[EXTERNAL_PICKER.picker])) {
                filter.selected[EXTERNAL_PICKER.picker] = [queries[EXTERNAL_PICKER.picker] === 'true'];
            }
            if (!isNil(queries[STACKED_PICKER.picker])) {
                filter.selected[STACKED_PICKER.picker] = [queries[STACKED_PICKER.picker] === 'true'];
            }
        }
    }
};
export var mergePrevAndCurrentFilter = function (prevFilter, currentFilter) {
    var _a;
    if (!prevFilter.configuration)
        return currentFilter;
    // eslint-disable-next-line guard-for-in
    for (var value in currentFilter.selected) {
        currentFilter.selected[value] =
            prevFilter.selected[value] && ((_a = prevFilter.selected[value]) === null || _a === void 0 ? void 0 : _a.length)
                ? prevFilter.selected[value]
                : currentFilter.selected[value];
    }
};
export var getCommonParams = function (params, queries) {
    var customerType = queries.customerType, cluster = queries.cluster, lastSyncDate = queries.lastSyncDate, customer = queries.customer, instance = queries.instance, permissions = queries.permissions;
    var conditions = getMergeFilterParamsConditions(queries);
    var commonParams = cloneDeep(params);
    if (conditions.shouldSetCustomerTypes) {
        var customerTypes = Array.isArray(customerType) ? customerType.join(',') : customerType;
        commonParams.customer_types = queries.customerType.includes('All') ? '' : customerTypes;
    }
    if (conditions.shouldSetInactiveHoursThreshold) {
        commonParams.inactive_hours_threshold = find(LAST_SYNC_DATE_OPTIONS, {
            label: lastSyncDate,
        }).value;
    }
    if (conditions.shouldSetCustomerIds) {
        commonParams.customer_ids = Array.isArray(customer) ? customer.join(',') : customer;
    }
    if (conditions.shouldSetInstanceIds) {
        commonParams.instance_ids = Array.isArray(instance) ? instance.join(',') : instance;
    }
    if (conditions.shouldSetClusterIds) {
        commonParams.cluster_ids = Array.isArray(cluster) ? cluster.join(',') : cluster;
    }
    if (conditions.shouldSetPermissionsIds) {
        commonParams.permissions = Array.isArray(permissions) ? permissions.join(',') : permissions;
    }
    return commonParams;
};
export var getPickerParams = function (picker, commonParams) {
    var newCommonParams = cloneDeep(commonParams);
    return __assign(__assign({}, newCommonParams), { limit: picker[1] ? parseInt(picker[1]) : newCommonParams.limit, currentPage: picker[0] ? parseInt(picker[0]) : undefined, maxPages: picker[2] ? parseInt(picker[2]) : undefined });
};
export var getFinalParams = function (params, queries) {
    var commonParams = getCommonParams(params, queries);
    var customerPicker = get(queries, CUSTOMER_PICKER.value, []);
    var clusterPicker = get(queries, CLUSTER_PICKER.value, []);
    var instancePicker = get(queries, INSTANCE_PICKER.value, []);
    var permissionsPicker = get(queries, PERMISSIONS_PICKER.value, []);
    var statusPicker = get(queries, MANAGE_USERS_STATUS_PICKER.value, []);
    return {
        stageParams: commonParams,
        providerParams: commonParams,
        statusParams: statusPicker,
        permissionParams: getPickerParams(permissionsPicker, commonParams),
        clusterParams: __assign(__assign({}, getPickerParams(clusterPicker, commonParams)), { without_total_count: true }),
        customerParams: __assign(__assign({}, getPickerParams(customerPicker, commonParams)), { without_total_count: true }),
        instanceParams: __assign(__assign({}, getPickerParams(instancePicker, commonParams)), { without_total_count: true }),
    };
};
export var mergePickerPagination = function (configuration, params, pickerName, pickerParamName) {
    if (configuration[pickerName]) {
        configuration[pickerName].currentPage = get(params, "".concat(pickerParamName, ".currentPage"), configuration[pickerName].currentPage);
        configuration[pickerName].maxPages = get(params, "".concat(pickerParamName, ".maxPages"), configuration[pickerName].maxPages);
    }
};
export var mergePickersPagination = function (configuration, params) {
    mergePickerPagination(configuration, params, 'customer', 'customerParams');
    mergePickerPagination(configuration, params, 'instance', 'instanceParams');
    mergePickerPagination(configuration, params, 'cluster', 'clusterParams');
    mergePickerPagination(configuration, params, 'stage', 'stageParams');
    mergePickerPagination(configuration, params, 'provider', 'providerParams');
    mergePickerPagination(configuration, params, 'permissions', 'permissionParams');
};
export var mergePickerSelected = function (filter, configuration) {
    var _loop_1 = function (responseOption) {
        var configData = configuration[responseOption].data;
        if (!isEmpty(configData)) {
            if (isEveryItemInArrayObject(configData)) {
                filter.selected[responseOption] = partition(configData, function (option) {
                    return get(filter, "selected.".concat(responseOption), []).includes(option.id.toString());
                })[0];
            }
            else {
                filter.selected[responseOption] = configData.filter(function (name) {
                    return get(filter, "selected.".concat(responseOption), []).includes(name);
                });
            }
        }
    };
    // eslint-disable-next-line guard-for-in
    for (var responseOption in configuration) {
        _loop_1(responseOption);
    }
};
export var mergeResponseConfig = function (filter, res, params) {
    var newConfiguration = cloneDeep(res);
    mergePickerSelected(filter, newConfiguration);
    mergePickersPagination(newConfiguration, params);
    merge(filter, {
        configuration: newConfiguration,
    });
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Spin } from 'antd';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import EmptyComponent from '@commonComponents/skeletons/empty/EmptyComponent';
import TableSkeleton from '@commonComponents/skeletons/tableSkeleton/TableSkeleton';
export var StyledTableLoadingStates = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    cursor: default;\n\n    .table-loading-states-spinner {\n        position: absolute;\n        left: 0;\n        right: 0;\n        top: 50%;\n        width: 100px;\n        margin-left: auto;\n        margin-right: auto;\n        transform: translateY(-50%);\n    }\n\n    &::before {\n        position: absolute;\n        content: '';\n        width: 100%;\n        height: 100%;\n        z-index: -1;\n        transition: background 0.2s;\n    }\n\n    &.loading {\n        &::before {\n            z-index: 1;\n            background-image: radial-gradient(circle, rgba(255, 255, 255, 0.4) 92%, transparent 100%);\n        }\n    }\n"], ["\n    position: relative;\n    cursor: default;\n\n    .table-loading-states-spinner {\n        position: absolute;\n        left: 0;\n        right: 0;\n        top: 50%;\n        width: 100px;\n        margin-left: auto;\n        margin-right: auto;\n        transform: translateY(-50%);\n    }\n\n    &::before {\n        position: absolute;\n        content: '';\n        width: 100%;\n        height: 100%;\n        z-index: -1;\n        transition: background 0.2s;\n    }\n\n    &.loading {\n        &::before {\n            z-index: 1;\n            background-image: radial-gradient(circle, rgba(255, 255, 255, 0.4) 92%, transparent 100%);\n        }\n    }\n"])));
var defaultConfigs = {
    height: 20,
    empty: {},
    skeleton: {
        paddingTop: 20,
        paddingBottom: 20,
    },
};
var TableLoadingStates = function (props) {
    var loading = props.loading, count = props.count, skeleton = props.skeleton, empty = props.empty, children = props.children, className = props.className;
    var normalizedData = Object.values(props.data);
    if (loading && normalizedData.length === 0) {
        return (React.createElement(TableSkeleton, { className: className, count: count, height: defaultConfigs.height, paddingBottom: (skeleton === null || skeleton === void 0 ? void 0 : skeleton.paddingBottom) || defaultConfigs.skeleton.paddingBottom, paddingTop: (skeleton === null || skeleton === void 0 ? void 0 : skeleton.paddingTop) || defaultConfigs.skeleton.paddingTop }));
    }
    if (normalizedData.length === 0) {
        return React.createElement(EmptyComponent, { paddingTop: empty === null || empty === void 0 ? void 0 : empty.paddingTop, description: empty === null || empty === void 0 ? void 0 : empty.description, className: className });
    }
    return (React.createElement(StyledTableLoadingStates, { className: classNames(className, { loading: loading }) },
        loading && (React.createElement(Spin, { className: "table-loading-states-spinner", indicator: React.createElement(LoadingOutlined, { style: { fontSize: 28 }, spin: true }) })),
        children));
};
export default TableLoadingStates;
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import LazyImageFactory from '@services/lazy-image-factory/LazyImageFactory';
import { MQ } from '@emotionConstants';
var AsideIllustrationContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-top: auto;\n    overflow: hidden;\n\n    ", " {\n        display: none;\n    }\n"], ["\n    margin-top: auto;\n    overflow: hidden;\n\n    ", " {\n        display: none;\n    }\n"])), MQ['lg']);
var AsideIllustration = function () { return (React.createElement(AsideIllustrationContainer, null,
    React.createElement(LazyImageFactory, { imageName: "illustration.svg", type: "object" }))); };
export default AsideIllustration;
var templateObject_1;

export var CLASS_NAMES;
(function (CLASS_NAMES) {
    CLASS_NAMES["ACTIVE"] = "active";
    CLASS_NAMES["DISABLED"] = "disabled";
})(CLASS_NAMES || (CLASS_NAMES = {}));
export var GROPED_TABLE_DOME_ATTRIBUTES;
(function (GROPED_TABLE_DOME_ATTRIBUTES) {
    GROPED_TABLE_DOME_ATTRIBUTES["DATA_CLICKABLE_CELL_ID"] = "data-clickable-cell-id";
    GROPED_TABLE_DOME_ATTRIBUTES["DATA_CLICKABLE_CLUSTER_ID"] = "data-clickable-cluster-id";
})(GROPED_TABLE_DOME_ATTRIBUTES || (GROPED_TABLE_DOME_ATTRIBUTES = {}));
export var CLUSTER_OVERVIEW_COLUMN_ID = 'cluster_overview';

import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { GAPageView } from '@app/tracking';
import Redirect from '@commonUtils/urlUtils/Redirect';
import { AuthContext } from '@app/OAuthProvider/OAuthProvider';
import { fetchPermissionsAllowedPagesIds, setPermissionsAllowedPagesTags } from '@redux/dispatchers/permissions';
import { ENVIRONMENTS } from '@commonConstants/environments';
import { WarningBanner } from '@components/warning-banner/WarningBanner';
import ApiErrorBannersWrapper from '@commonComponents/api-error-banner/ApiErrorBannersWrapper';
import AppLoader from '@commonComponents/app-loader/appLoader';
var RequiredAuth = function (props) {
    var useAuth = useContext(AuthContext);
    var authenticated = useAuth.isSignedIn();
    var _a = useSelector(function (store) { return store.permissions.allowedPagesTags; }), allowedPagesTags = _a.data, dirty = _a.dirty;
    var permissionGroups = useSelector(function (store) { var _a, _b; return (_b = (_a = store.userInfo) === null || _a === void 0 ? void 0 : _a.activeUser) === null || _b === void 0 ? void 0 : _b.permissionGroups; });
    useEffect(function () {
        if (!authenticated) {
            Redirect.toSignIn();
        }
        else {
            if (authenticated && process.env.ENVIRONMENT === ENVIRONMENTS.PROD) {
                GAPageView();
            }
            var shouldFetchAllowedTags = !isEmpty(permissionGroups) && authenticated && isEmpty(allowedPagesTags) && !dirty;
            if (shouldFetchAllowedTags) {
                fetchPermissionsAllowedPagesIds();
            }
            else if (!dirty) {
                // we fire this action to toggle loading  store.permissions.allowedPagesTags.loading to be false
                setPermissionsAllowedPagesTags([]);
            }
        }
    }, [authenticated, permissionGroups]);
    return authenticated && dirty ? (React.createElement("div", null,
        React.createElement(WarningBanner, null),
        React.createElement(ApiErrorBannersWrapper, null),
        props.component)) : (React.createElement(AppLoader, null));
};
export default RequiredAuth;

var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import get from 'lodash/get';
import queryString from 'query-string';
import cloneDeep from 'lodash/cloneDeep';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { OptionsAPI, PICKERS_API } from '@api/pickers-api/pickersApi';
import history from '@history/history';
import { getFilterName } from '@redux/selectors/filter';
import { getMultiFilterInitialState } from '@configs/multiFilterConfig';
import { setFilterData, updatePickerOptions, setUpdatedPickersData, pushNewPaginationOptions, } from '@actionCreators/multiFilter/multiFilter';
import * as Actions from '@redux/actions';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import { dropPaginationToDefault } from '@redux/dispatchers/pagination';
import { getFinalParams, mergeFilterSelected, mergeResponseConfig } from './multiFilterSagaUtils';
var SEARCH_VALUE = URL_QUERIES.COMMON.SEARCH_VALUE;
export function initFilter(_a) {
    var currentFilterName, initialConfig, filter, queries, newParams, res, e_1;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, getFilterName()];
            case 1:
                currentFilterName = _b.sent();
                if (payload.name === currentFilterName) {
                    return [2 /*return*/];
                }
                _b.label = 2;
            case 2:
                _b.trys.push([2, 6, , 7]);
                initialConfig = getMultiFilterInitialState(payload.name);
                filter = cloneDeep(initialConfig.config);
                queries = queryString.parse(history.location.search, { arrayFormat: 'comma' });
                newParams = getFinalParams(cloneDeep(payload.params), queries);
                mergeFilterSelected(filter, queries);
                if (!initialConfig.isApiRequired) return [3 /*break*/, 4];
                return [4 /*yield*/, PICKERS_API[payload.name](newParams)];
            case 3:
                res = _b.sent();
                mergeResponseConfig(filter, res, newParams);
                _b.label = 4;
            case 4:
                filter.searchTerm = get(queryString.parse(window.location.search), SEARCH_VALUE, '');
                return [4 /*yield*/, put(setFilterData({
                        filter: filter,
                        name: payload.name,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 7];
            case 6:
                e_1 = _b.sent();
                console.error('Failed to initialize filter', e_1);
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function updateFilterPickers(_a) {
    var hasToBeUpdated, params, apis, res;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                hasToBeUpdated = payload.hasToBeUpdated, params = payload.params;
                apis = hasToBeUpdated.map(function (option) {
                    // TODO: consider to remove this if
                    if (!OptionsAPI[option]) {
                        return;
                    }
                    var f = OptionsAPI[option];
                    return f.call(f, params);
                });
                return [4 /*yield*/, Promise.all(apis)];
            case 1:
                res = _b.sent();
                return [4 /*yield*/, put(setUpdatedPickersData({
                        data: res,
                        hasToBeUpdated: hasToBeUpdated,
                    }))];
            case 2:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
function changeFilterOptionCurrentPage(action) {
    var api, res, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                api = OptionsAPI[action.payload.optionsKey];
                return [4 /*yield*/, api(action.payload.params)];
            case 1:
                res = _a.sent();
                return [4 /*yield*/, put(pushNewPaginationOptions({
                        optionsKey: action.payload.optionsKey,
                        data: res,
                    }))];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                e_2 = _a.sent();
                console.error('Failed to get new options for picker');
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
function setSearchTerm(action) {
    var api, res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                api = OptionsAPI[action.payload.optionsKey];
                return [4 /*yield*/, api(action.payload.params)];
            case 1:
                res = _a.sent();
                return [4 /*yield*/, put(updatePickerOptions({
                        optionsKey: action.payload.optionsKey,
                        termPseudo: action.payload.termPseudo,
                        term: action.payload.params[action.payload.termPseudo.termPseudoUN],
                        data: res,
                    }))];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function handleChangeTermv2() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(dropPaginationToDefault)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export default function filterV2Saga() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeEvery(Actions.multiFilter.AsyncActions.INIT_FILTER, initFilter)];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeEvery(Actions.multiFilter.AsyncActions.CHANGE_FILTER_OPTION_CURRENT_PAGE, changeFilterOptionCurrentPage)];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.multiFilter.AsyncActions.UPDATE_FILTER_PICKERS, updateFilterPickers)];
            case 3:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.multiFilter.AsyncActions.SET_SEARCH_TERM, setSearchTerm)];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.multiFilter.SyncActions.CHANGE_TERMV2, handleChangeTermv2)];
            case 5:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.multiFilter.SyncActions.SET_SELECTED_PICKER_OPTIONS, handleChangeTermv2)];
            case 6: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 7:
                _c.sent();
                return [2 /*return*/];
        }
    });
}

import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedEffect } from '@app/hooks';
import Line from '@commonComponents/chart/Line/Line';
import { fetchCharts, dropChartsToDefault } from '@redux/dispatchers/charts';
import { getChartOptions, getNormalizedABSYValue, getNormalizedABSTooltipValue } from '@commonUtils/charts/chartUtils';
import { CHART_MESSAGES } from '@commonConstants/chartsConstants';
import { CHART_DASHBOARDS, ACTIVE_INSTANCES_CHARTS_KEYS } from './activeInstancesChartConstants';
import { getActiveInstancesChartsConfigs, getNormalizedInstances, getNormalizedRegionalClusters, getNormalizedChildClusters, } from './activeInstancesChartsUtils';
import { StyledActiveInstancesCharts } from './StyledActiveInstancesCharts';
import { StyledChartsWrapperNew } from '@app/common/react/styled/StyledChartsWrapperNew';
var ActiveInstancesCharts = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var _o = useSelector(function (store) { return ({
        timezone: store.userInfo.userPreferences.timezone,
        charts: store.charts.activeInstances,
        customerTypes: store.mode.selected,
        crossPageFilter: store.crossPageFilter,
    }); }), charts = _o.charts, customerTypes = _o.customerTypes, crossPageFilter = _o.crossPageFilter, timezone = _o.timezone;
    var params = {
        customerTypes: customerTypes,
        granularity: (_b = (_a = crossPageFilter.granularity) === null || _a === void 0 ? void 0 : _a.selected) === null || _b === void 0 ? void 0 : _b.value,
        end: (_d = (_c = crossPageFilter.dateRange.calculatedDateRange) === null || _c === void 0 ? void 0 : _c.end) === null || _d === void 0 ? void 0 : _d.timeStamp,
        start: (_f = (_e = crossPageFilter.dateRange.calculatedDateRange) === null || _e === void 0 ? void 0 : _e.start) === null || _f === void 0 ? void 0 : _f.timeStamp,
        timezone: timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    var loading = (_g = charts[ACTIVE_INSTANCES_CHARTS_KEYS.ACTIVE_INSTANCES_CHARTS]) === null || _g === void 0 ? void 0 : _g.loading;
    useDebouncedEffect(function () {
        if (!loading && params.granularity) {
            fetchCharts(getActiveInstancesChartsConfigs(params));
        }
        return function () {
            dropChartsToDefault(CHART_DASHBOARDS.activeInstancesCharts);
        };
    }, [
        props.activeClusterId,
        crossPageFilter.dateRange.calculatedDateRange,
        (_j = (_h = crossPageFilter.granularity) === null || _h === void 0 ? void 0 : _h.selected) === null || _j === void 0 ? void 0 : _j.value,
        customerTypes,
        timezone,
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledActiveInstancesCharts, null,
            React.createElement(StyledChartsWrapperNew, { className: "styled_charts_wrapper one" },
                React.createElement(Line, { loadingOut: loading, title: "Active Instances Timeline", datasetsTitles: ['Instances', 'Regional Clusters', 'Child Clusters'], datasets: [
                        getNormalizedInstances((_k = charts[ACTIVE_INSTANCES_CHARTS_KEYS.ACTIVE_INSTANCES_CHARTS]) === null || _k === void 0 ? void 0 : _k.data),
                        getNormalizedRegionalClusters((_l = charts[ACTIVE_INSTANCES_CHARTS_KEYS.ACTIVE_INSTANCES_CHARTS]) === null || _l === void 0 ? void 0 : _l.data),
                        getNormalizedChildClusters((_m = charts[ACTIVE_INSTANCES_CHARTS_KEYS.ACTIVE_INSTANCES_CHARTS]) === null || _m === void 0 ? void 0 : _m.data),
                    ], message: CHART_MESSAGES.ACTIVE_INSTANCES_CHART_MESSAGE, chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedABSYValue,
                        getNormalizedTooltipValue: getNormalizedABSTooltipValue,
                    }) })))));
};
export default memo(ActiveInstancesCharts);

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { Scrollbars } from 'react-custom-scrollbars';
var StyledCustomThumb = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 4px;\n    height: 10px;\n    border-radius: 6px;\n    background: ", ";\n    margin: 5px 0;\n"], ["\n    width: 4px;\n    height: 10px;\n    border-radius: 6px;\n    background: ", ";\n    margin: 5px 0;\n"])), function (props) { return props.theme.color.action.primary.normal.disabled.border; });
var Scrollbar = function (_a) {
    var css = _a.css, children = _a.children;
    return (React.createElement(Scrollbars, { className: "scrollbars", renderThumbVertical: function () { return React.createElement(StyledCustomThumb, { className: "custom_thumb" }); }, style: css }, children));
};
export default Scrollbar;
var templateObject_1;

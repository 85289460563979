var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var StyledTelemetryAlertsFilter = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    font-size: 16px;\n"], ["\n    display: flex;\n    font-size: 16px;\n"])));
export var StyledRow = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 10px;\n    display: flex;\n    flex-direction: column;\n"], ["\n    margin: 10px;\n    display: flex;\n    flex-direction: column;\n"])));
export var StyledItem = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    height: 45px;\n    display: flex;\n    padding: 10px;\n"], ["\n    height: 45px;\n    display: flex;\n    padding: 10px;\n"])));
export var StyledLabel = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-weight: 600;\n    padding-right: 5px;\n"], ["\n    font-weight: 600;\n    padding-right: 5px;\n"])));
export var StyledToggle = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    position: absolute;\n    right: 0;\n    top: 15px;\n"], ["\n    position: absolute;\n    right: 0;\n    top: 15px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

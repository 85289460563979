export var SyncActions;
(function (SyncActions) {
    SyncActions["SET_FILTER_DATA"] = "SET_FILTER_DATA";
    SyncActions["SET_SELECTED_PICKER_OPTIONS"] = "SET_SELECTED_PICKER_OPTIONS";
    SyncActions["SET_UPDATED_PICKERS_DATA"] = "SET_UPDATED_PICKERS_DATA";
    SyncActions["CHANGE_GRANULARITY"] = "CHANGE_GRANULARITY";
    SyncActions["PUSH_NEW_PAGINATION_OPTIONS"] = "PUSH_NEW_PAGINATION_OPTIONS";
    SyncActions["CHANGE_SINGLE_SELECT_PICKER_OPTION"] = "CHANGE_SINGLE_SELECT_PICKER_OPTION";
    SyncActions["UPDATE_PICKER_OPTIONS"] = "UPDATE_PICKER_OPTIONS";
    SyncActions["UNMOUNT_FILTER"] = "UNMOUNT_FILTER";
    SyncActions["RESET_FILTER_PICKERS"] = "RESET_FILTER_PICKERS";
    SyncActions["CHANGE_TERMV2"] = "CHANGE_TERMV2";
    SyncActions["DROP_FILTER_TO_DEFAULT"] = "DROP_FILTER_TO_DEFAULT";
})(SyncActions || (SyncActions = {}));
export var AsyncActions;
(function (AsyncActions) {
    AsyncActions["INIT_FILTER"] = "INIT_FILTER";
    AsyncActions["CHANGE_FILTER_OPTION_CURRENT_PAGE"] = "CHANGE_FILTER_OPTION_CURRENT_PAGE";
    AsyncActions["UPDATE_FILTER_PICKERS"] = "UPDATE_FILTER_PICKERS";
    AsyncActions["SET_SEARCH_TERM"] = "SET_SEARCH_TERM";
})(AsyncActions || (AsyncActions = {}));

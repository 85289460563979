import React from 'react';
import { connect } from 'react-redux';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { SEARCH_TERM } from '@commonConstants/searchTermPseudosConstants';
import { CROSS_PAGE_FILTER_CONFIG } from '@configs/crossPageFilterInitialState';
import FilterContainer from '@commonComponents/filter/FilterContainer';
import CrossPageFilterContainer from '@commonComponents/cross-page-filter/CrossPageFilterContainer';
import { DateUtils } from '@utils/date/DateUtils';
import { changeCrossPageFilterOption } from '@actionCreators/crossPageFilter';
import { getFilterDateChangeConfig } from '@commonComponents/cross-page-filter/crossPageFilterUtils';
var DATE_RANGE_KEY = URL_QUERIES.COMMON.DATE_RANGE;
var BOOTSTRAP = DASHBOARDS_SCHEMA.DASHBOARDS.PAGES.BOOTSTRAP;
var pickers = [
    {
        name: 'Customer',
        pseudo: 'customer',
        termPseudo: SEARCH_TERM,
        effectsOn: ['instance'],
    },
    {
        name: 'Instance',
        pseudo: 'instance',
        termPseudo: SEARCH_TERM,
    },
    {
        name: 'Stage',
        pseudo: 'stage',
        static: true,
    },
    {
        name: 'Provider',
        pseudo: 'provider',
        static: true,
    },
    {
        name: 'Status',
        pseudo: 'status',
        static: true,
    },
];
export var BootstrapFilter = function (_a) {
    var mode = _a.mode, multiFilter = _a.multiFilter, history = _a.history, changeCrossPageFilterOptionAction = _a.changeCrossPageFilterOptionAction;
    var name = BOOTSTRAP.PSEUDO_CC;
    var selected = multiFilter.filter.selected;
    var params = {
        limit: 20,
        source: 'BOOTSTRAP',
        customer_types: mode.selected[0] === 'All' ? '' : mode.selected.toString(),
        inactive_hours_threshold: mode.lastSyncDate.selected.value,
    };
    if (!multiFilter.loading && multiFilter.name === name) {
        var extendedParams = {
            customer_ids: selected.customer.map(function (o) { return o.id; }).toString(),
            instance_ids: selected.instance.map(function (o) { return o.id; }).toString(),
        };
        params = Object.assign(params, extendedParams);
    }
    var defaultSelected = CROSS_PAGE_FILTER_CONFIG[BOOTSTRAP.PSEUDO_CC].dateRange.selected;
    var resetAdditionalParams = function () {
        changeCrossPageFilterOptionAction(getFilterDateChangeConfig(DATE_RANGE_KEY, defaultSelected, DateUtils.calculateDateRangeByOption(defaultSelected)));
    };
    return (React.createElement(FilterContainer, { name: name, pickers: pickers, params: params, currentMode: mode, multiFilter: multiFilter, isOptionsHidden: false, isPaginationOn: true, resetAdditionalParams: resetAdditionalParams },
        React.createElement(CrossPageFilterContainer, { isEmbedded: true, history: history, dashboardName: BOOTSTRAP.PSEUDO_CC, className: "bootstrap-date-range-picker" })));
};
var mapStateToProps = function (store) { return ({
    mode: store.mode,
    multiFilter: store.multiFilter,
}); };
var mapDispatch = {
    changeCrossPageFilterOptionAction: changeCrossPageFilterOption,
};
export default connect(mapStateToProps, mapDispatch)(BootstrapFilter);

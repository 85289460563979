export var STATS = [
    {
        name: 'Customers',
        pseudo: 'customers_count',
    },
    {
        name: 'Management Clusters',
        pseudo: 'management_clusters_count',
    },
    {
        name: 'Child Clusters',
        pseudo: 'clusters_count',
    },
    {
        name: 'All Nodes',
        pseudo: 'nodes_count',
    },
];
export var FILTER_STATS = [
    {
        name: 'Customers',
        pseudo: 'customers_count',
    },
    {
        name: 'Management Clusters',
        pseudo: 'management_clusters_count',
    },
    {
        name: 'Child Clusters',
        pseudo: 'clusters_count',
    },
    {
        name: 'All Nodes',
        pseudo: 'nodes_count',
    },
    {
        name: 'MCC Nodes',
        pseudo: 'cc_nodes',
    },
];

import React, { useState } from 'react';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import OpenstackClusterUsageFilter from './OpenstackClusterUsageFilter';
import OpenstackClusterUsageTable from './openstack-cluster-usage-table/OpenstackClusterUsageTable';
import { StyledOpenstackClusterUsageFilter, StyledOpenstackClusterUsageTopControls, } from './StyledOpenstackClusterUsage';
import DownloadCsv from './download-csv/DownloadCsv';
var OPENSTACK_CLUSTERS_USAGE = DASHBOARDS_SCHEMA.DASHBOARDS.PAGES.OPENSTACK_CLUSTERS_USAGE;
var OpenstackClusterUsage = function () {
    var _a = useState({
        sortBy: 'cluster_name',
        orderBy: 'asc',
    }), sortState = _a[0], setSortState = _a[1];
    var _b = useState([]), visibleColumns = _b[0], setVisibleColumns = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", null, "Openstack Clusters Usage"),
        React.createElement(StyledOpenstackClusterUsageTopControls, null,
            React.createElement(StyledOpenstackClusterUsageFilter, null,
                React.createElement(OpenstackClusterUsageFilter, null)),
            React.createElement(DownloadCsv, { sortState: sortState, visibleColumns: visibleColumns })),
        React.createElement(OpenstackClusterUsageTable, { visibleColumnsKeysCallback: function (value) { return setVisibleColumns(value); }, name: OPENSTACK_CLUSTERS_USAGE.PSEUDO_CC, onSortStateChange: setSortState, summary: true })));
};
export default OpenstackClusterUsage;

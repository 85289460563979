import { connect } from 'react-redux';
import withUrlValidationHOC from '@HOC/withUrlValidationHOC/withUrlValidationHOC';
import withDisablingWhileFetchingHOC from '@HOC/withDisablingWhileFetchingHOC/withDisablingWhileFetchingHOC';
import { getCustomerTypes, setSelectedOptions } from '@actionCreators/mode/mode';
import CustomerTypesFilter from './CustomerTypesFilter';
import { getCustomerTypesFromUrl, getLastSyncDateFromUrlFromUrl, isLastSyncDisabled } from './customerTypesFilterUtils';
var mapState = function (store) { return ({
    mode: store.mode,
    location: window.location,
    dashboards: store.dashboards,
    customerTypesFromUrl: getCustomerTypesFromUrl(),
    lastSyncDateFromUrl: getLastSyncDateFromUrlFromUrl(),
    isLastSyncDisabled: isLastSyncDisabled(),
}); };
var mapDispatch = {
    getCustomerTypes: getCustomerTypes,
    setSelectedOptions: setSelectedOptions,
};
var connector = connect(mapState, mapDispatch);
export default withUrlValidationHOC(withDisablingWhileFetchingHOC(connector(CustomerTypesFilter)));

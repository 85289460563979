var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useDebouncedEffect } from '@app/hooks';
import Line from '@commonComponents/chart/Line/Line';
import { fetchCharts, dropChartsToDefault } from '@redux/dispatchers/charts';
import { getChartOptions, getNormalizedABSYValue, getNormalizedABSTooltipValue } from '@commonUtils/charts/chartUtils';
import { CHART_DASHBOARDS, COSTS_CHARTS_KEYS } from './signUpsConstants';
import { getNormalizedSignUps, getSignUpsChartsConfigs } from './signUpsChartUtils';
import { StyledChartsWrapperNew } from '@app/common/react/styled/StyledChartsWrapperNew';
var TrialSignUpsCharts = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var _o = useSelector(function (store) { return ({
        multiFilter: store.multiFilter,
        crossPageFilter: store.crossPageFilter,
        timezone: store.userInfo.userPreferences.timezone,
        trialReportsSignUps: store.charts.trialReportsSignUps,
    }); }), trialReportsSignUps = _o.trialReportsSignUps, crossPageFilter = _o.crossPageFilter, timezone = _o.timezone, multiFilter = _o.multiFilter;
    var loading = (_a = trialReportsSignUps[COSTS_CHARTS_KEYS.SIGN_UPS_CHARTS]) === null || _a === void 0 ? void 0 : _a.loading;
    var isParams = crossPageFilter.dateRange.calculatedDateRange && ((_c = (_b = crossPageFilter === null || crossPageFilter === void 0 ? void 0 : crossPageFilter.granularity) === null || _b === void 0 ? void 0 : _b.selected) === null || _c === void 0 ? void 0 : _c.value);
    var isMonth = ((_e = (_d = crossPageFilter === null || crossPageFilter === void 0 ? void 0 : crossPageFilter.granularity) === null || _d === void 0 ? void 0 : _d.selected) === null || _e === void 0 ? void 0 : _e.value) === '30 days';
    useDebouncedEffect(function () {
        if (!loading && isParams) {
            fetchCharts(getSignUpsChartsConfigs(crossPageFilter, timezone, multiFilter));
        }
        return function () {
            dropChartsToDefault(CHART_DASHBOARDS.TRIAL_REPORTS_SIGNUPS);
        };
    }, [
        (_g = (_f = multiFilter === null || multiFilter === void 0 ? void 0 : multiFilter.filter) === null || _f === void 0 ? void 0 : _f.selected) === null || _g === void 0 ? void 0 : _g.external,
        crossPageFilter.dateRange.calculatedDateRange,
        (_j = (_h = crossPageFilter.granularity) === null || _h === void 0 ? void 0 : _h.selected) === null || _j === void 0 ? void 0 : _j.value,
        timezone,
    ]);
    return (React.createElement(StyledChartsWrapperNew, { className: "styled_charts_wrapper one" },
        React.createElement(Line, { loadingOut: loading, title: "Sign Ups", datasetsTitles: ['value'], datasets: [
                // @ts-ignore
                getNormalizedSignUps((_k = trialReportsSignUps[COSTS_CHARTS_KEYS.SIGN_UPS_CHARTS]) === null || _k === void 0 ? void 0 : _k.data, (_m = (_l = crossPageFilter === null || crossPageFilter === void 0 ? void 0 : crossPageFilter.granularity) === null || _l === void 0 ? void 0 : _l.selected) === null || _m === void 0 ? void 0 : _m.value),
            ], chartOptions: __assign(__assign({}, getChartOptions({
                getNormalizedYValue: getNormalizedABSYValue,
                getNormalizedTooltipValue: getNormalizedABSTooltipValue,
                maxXAxisTicksLimit: 10,
            })), { xFormatFunction: function (value) { return moment(value).format(isMonth ? 'MMM Y' : 'MMM D, Y'); } }) })));
};
export default memo(TrialSignUpsCharts);

var _a, _b, _c;
export var GRANULARITIES;
(function (GRANULARITIES) {
    GRANULARITIES["ONE_MONTH"] = "1 month";
    GRANULARITIES["ONE_WEEK"] = "1 week";
    GRANULARITIES["ONE_DAY"] = "1 day";
    GRANULARITIES["TWELVE_HOURS"] = "12 hours";
    GRANULARITIES["SIX_HOURS"] = "6 hours";
    GRANULARITIES["THREE_HOURS"] = "3 hours";
    GRANULARITIES["ONE_HOUR"] = "1 hour";
    GRANULARITIES["FIFTEEN_MINUTES"] = "15 minutes";
})(GRANULARITIES || (GRANULARITIES = {}));
export var MCC_GRANULARITY_RANGES_EQUIVALENTS = (_a = {},
    _a[GRANULARITIES.ONE_MONTH] = [90, 360],
    _a[GRANULARITIES.ONE_WEEK] = [32, 89],
    _a[GRANULARITIES.ONE_DAY] = [2, 31],
    _a[GRANULARITIES.TWELVE_HOURS] = [0, 7],
    _a[GRANULARITIES.SIX_HOURS] = [0, 3],
    _a[GRANULARITIES.THREE_HOURS] = [0, 1],
    _a[GRANULARITIES.ONE_HOUR] = [-1, -1],
    _a[GRANULARITIES.FIFTEEN_MINUTES] = [-1, -1],
    _a);
export var STATIC_GRANULARITY_RANGES_EQUIVALENTS = (_b = {},
    _b[GRANULARITIES.ONE_MONTH] = [90, 360],
    _b[GRANULARITIES.ONE_WEEK] = [32, 89],
    _b[GRANULARITIES.ONE_DAY] = [0, 31],
    _b);
export var COMMON_GRANULARITY_MINUTES_EQUIVALENTS = (_c = {},
    _c[GRANULARITIES.ONE_MONTH] = 43200,
    _c[GRANULARITIES.ONE_WEEK] = 10080,
    _c[GRANULARITIES.ONE_DAY] = 1440,
    _c[GRANULARITIES.TWELVE_HOURS] = 720,
    _c[GRANULARITIES.SIX_HOURS] = 360,
    _c[GRANULARITIES.THREE_HOURS] = 180,
    _c[GRANULARITIES.ONE_HOUR] = 60,
    _c[GRANULARITIES.FIFTEEN_MINUTES] = 15,
    _c);

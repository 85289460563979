var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as Actions from '@redux/actions';
import { prev } from './reducer';
import { extractOptionsByFilteringOptions } from '@utils/extracting/customers';
var r = {};
r[Actions.home.AsyncActions.GET_TOP_CUSTOMERS] = function (draft) {
    draft.topCustomers.loading = true;
};
r[Actions.home.SyncActions.SET_TOP_CUSTOMERS] = function (draft, payload) {
    draft.topCustomers.loading = false;
    draft.topCustomers.data = payload.data;
};
r[Actions.home.SyncActions.SET_FILTEREd_CUSTOMERS] = function (draft, payload) {
    var filtration = draft.map.filtration;
    draft.map.filteredCustomers = payload;
    draft.map.acceptDisabled = true;
    filtration.prev.region = filtration.current.region.active;
    filtration.prev.status = filtration.current.status.active;
    filtration.prev.customer = filtration.current.customer.active;
};
r[Actions.home.SyncActions.RESET_FILTER] = function (draft) {
    draft.map.acceptDisabled = true;
    draft.map.filtration.current = draft.map.filtration.initial;
    draft.map.filteredCustomers = draft.map.customersList;
    draft.map.filtration.prev = prev;
};
r[Actions.home.SyncActions.SET_MAP_FILTER_OPTIONS] = function (draft, _a) {
    var options = _a.options, customersList = _a.customersList;
    var regions = __spreadArray(['All World'], options.region, true);
    var statuses = __spreadArray(['All Statuses'], options.status, true);
    var customers = __spreadArray(['All Customers'], options.customer, true);
    draft.map.filtration.initial.region.options = regions;
    draft.map.filtration.current.region.options = regions;
    draft.map.filtration.initial.status.options = statuses;
    draft.map.filtration.current.status.options = statuses;
    draft.map.filtration.initial.customer.options = customers;
    draft.map.filtration.current.customer.options = customers;
    draft.map.filteredCustomers = customersList;
    draft.map.customersList = customersList;
};
r[Actions.home.SyncActions.CHANGE_OPTION] = function (draft, payload) {
    var filtration = draft.map.filtration;
    if (payload.key === 'region') {
        var filter = {
            region: payload.value,
        };
        var options = extractOptionsByFilteringOptions(draft.map.customersList, filter);
        var statuses = __spreadArray(['All Statuses'], options.status, true);
        var customers = __spreadArray(['All Customers'], options.customer, true);
        draft.map.filtration.current.status.active = 'All Statuses';
        draft.map.filtration.current.status.options = statuses;
        draft.map.filtration.current.customer.active = 'All Customers';
        draft.map.filtration.current.customer.options = customers;
    }
    if (payload.key === 'status') {
        var filter = {
            region: draft.map.filtration.current.region.active,
            status: payload.value,
        };
        var options = extractOptionsByFilteringOptions(draft.map.customersList, filter);
        var customers = __spreadArray(['All Customers'], options.customer, true);
        draft.map.filtration.current.customer.active = 'All Customers';
        draft.map.filtration.current.customer.options = customers;
    }
    filtration.current[payload.key].active = payload.value;
    draft.map.acceptDisabled = !(filtration.prev.region !== filtration.current.region.active ||
        filtration.prev.status !== filtration.current.status.active ||
        filtration.prev.customer !== filtration.current.customer.active);
};
export { r as resolvers };

import moment from 'moment';
import head from 'lodash/head';
import { DateUtils } from '@utils/date/DateUtils';
import { CHART_DASHBOARDS, COSTS_CHARTS_KEYS } from './signUpsConstants';
export var getSignUpsChartsConfigs = function (crossPageFilter, timezone, multiFilter) {
    var _a, _b, _c, _d, _e, _f;
    return {
        dashboard: CHART_DASHBOARDS.TRIAL_REPORTS_SIGNUPS,
        charts: [
            {
                isExternal: head((_b = (_a = multiFilter === null || multiFilter === void 0 ? void 0 : multiFilter.filter) === null || _a === void 0 ? void 0 : _a.selected) === null || _b === void 0 ? void 0 : _b.external),
                timezone: timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
                granularity: crossPageFilter.granularity.selected.value,
                key: COSTS_CHARTS_KEYS.SIGN_UPS_CHARTS,
                end: (_d = (_c = crossPageFilter.dateRange.calculatedDateRange) === null || _c === void 0 ? void 0 : _c.end) === null || _d === void 0 ? void 0 : _d.timeStamp,
                start: (_f = (_e = crossPageFilter.dateRange.calculatedDateRange) === null || _e === void 0 ? void 0 : _e.start) === null || _f === void 0 ? void 0 : _f.timeStamp,
            },
        ],
    };
};
export var getNormalizedSignUps = function (value, granularity) {
    if (value === void 0) { value = []; }
    if (granularity === void 0) { granularity = ''; }
    var dataset = {};
    if (granularity === '1 day') {
        var points = value.map(function (_a) {
            var date = _a.date;
            return moment(date).valueOf();
        });
        var max = Math.max.apply(Math, points);
        var min = Math.min.apply(Math, points);
        dataset = DateUtils.enumerateDaysBetweenDates(min, max).reduce(function (result, date) {
            result[moment(date).format('Y/M/D')] = {
                x: moment(date).format('Y/M/D'),
                y: '0',
            };
            return result;
        }, {});
    }
    value.forEach(function (_a) {
        var date = _a.date, count = _a.count;
        dataset[moment(date).format('Y/M/D')] = {
            x: date,
            y: count,
        };
    });
    return Object.values(dataset);
};

import React, { useEffect } from 'react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { StyledTabs } from './styled';
var Tabs = function (props) {
    var shouldHideBorder = props.shouldHideBorder, active = props.active, tabs = props.tabs, activeTabFromUrl = props.activeTabFromUrl, handleChange = props.handleChange;
    useEffect(function () {
        if (!isNil(activeTabFromUrl))
            handleChange(activeTabFromUrl);
    }, [activeTabFromUrl]);
    var finalTabs = tabs.map(function (tab, idx) {
        var className = classNames({
            tabs__tab: true,
            active: active === tab,
        });
        return (React.createElement("h5", { key: idx, className: className, onClick: function () { return handleChange(tab); } }, tab));
    });
    return (React.createElement(StyledTabs, { className: "tabs", shouldHideBorder: shouldHideBorder },
        React.createElement("div", { className: "tabs__wrapper" }, finalTabs)));
};
export default Tabs;

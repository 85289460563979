var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { usePrevious } from '@app/hooks';
import APIFiltering from '@api/banners-api/dynamicStatsBannerApi';
import { headCustomersCount } from '@api/customers-api/customersApi';
import { headClusterCount, headManagementClusterCount } from '@api/clusters-api/clustersApi';
import { headNodesCount } from '@api/nodes';
import withDisablingWhileFetchingHOC from '@HOC/withDisablingWhileFetchingHOC/withDisablingWhileFetchingHOC';
import { isFilterEmpty } from '@utils/filtering/isFilterEmpty';
import GreySection from '@commonComponents/GreySection';
import StyledOverallStatistic from '@app/common/react/styled/StyledOverallStatistic';
import { getFilterResult } from './statisStatsBannerUtils';
import { STATS, FILTER_STATS } from './constants';
var Item = function (_a) {
    var value = _a.value, name = _a.name;
    return (React.createElement("div", { className: "overall-statistic__item" },
        React.createElement("div", { className: "overall-statistic__item__value" }, value),
        React.createElement("div", { className: "overall-statistic__item__name" }, name)));
};
var StaticStatsBanner = function () {
    var _a, _b, _c;
    var _d = useSelector(function (store) { return ({
        mode: store.mode,
        lastSyncDate: store.mode.lastSyncDate.selected,
        multiFilter: store.multiFilter,
    }); }), mode = _d.mode, lastSyncDate = _d.lastSyncDate, multiFilter = _d.multiFilter;
    var selected = multiFilter.filter.selected;
    var _e = React.useState({
        customers_count: '-',
        management_clusters_count: '-',
        clusters_count: '-',
        nodes_count: '-',
        cc_nodes: '-',
    }), state = _e[0], setState = _e[1];
    var params = {
        customer_types: mode.selected[0] === 'All' ? null : mode.selected.toString(),
        inactive_hours_threshold: lastSyncDate.value,
        source: 'CONTAINER_CLOUD',
    };
    var prevParams = usePrevious(params);
    var filteringParams = {
        customer_types: mode.selected[0] === 'All' ? '' : mode.selected.toString(),
        inactive_hours_threshold: mode.lastSyncDate.selected.value,
        source: 'CONTAINER_CLOUD',
        customer_ids: ((_a = selected === null || selected === void 0 ? void 0 : selected.customer) === null || _a === void 0 ? void 0 : _a.length) > 0 ? selected.customer.map(function (o) { return o.id; }).toString() : null,
        instance_ids: ((_b = selected === null || selected === void 0 ? void 0 : selected.instance) === null || _b === void 0 ? void 0 : _b.length) > 0 ? selected.instance.map(function (o) { return o.id; }).toString() : null,
        cluster_ids: ((_c = selected === null || selected === void 0 ? void 0 : selected.cluster) === null || _c === void 0 ? void 0 : _c.length) > 0 ? selected.cluster.map(function (o) { return o.id; }).toString() : null,
    };
    var prevFilteringParams = usePrevious(filteringParams);
    var callApi = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, customers_count, management_clusters_count, clusters_count, nodes_count;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, Promise.all([
                        headCustomersCount(params),
                        headManagementClusterCount(params),
                        headClusterCount(params),
                        headNodesCount(params),
                    ])];
                case 1:
                    _a = _b.sent(), customers_count = _a[0], management_clusters_count = _a[1], clusters_count = _a[2], nodes_count = _a[3];
                    return [2 /*return*/, {
                            customers_count: customers_count || '-',
                            management_clusters_count: management_clusters_count || '-',
                            clusters_count: clusters_count || '-',
                            nodes_count: nodes_count || '-',
                            cc_nodes: '-',
                        }];
            }
        });
    }); };
    var callAPIFiltering = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, APIFiltering(filteringParams)];
                case 1:
                    res = _a.sent();
                    setState(getFilterResult(res, selected));
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error('Failed to fetch Dynamic stats');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (isFilterEmpty(selected) &&
            (!isEqual(prevParams, params) || !isEqual(prevFilteringParams, filteringParams))) {
            callApi()
                .then(function (res) { return setState(res); })
                .catch(function () { return console.error('Failed to get static stats banner data'); });
        }
    }, [mode.selected, lastSyncDate, selected]);
    useEffect(function () {
        if (isFilterEmpty(selected)) {
            return;
        }
        callAPIFiltering();
    }, [mode, selected]);
    var items = isFilterEmpty(selected)
        ? STATS.map(function (item) { return React.createElement(Item, { key: item.pseudo, value: state[item.pseudo], name: item.name }); })
        : FILTER_STATS.map(function (item) { return React.createElement(Item, { key: item.pseudo, value: state[item.pseudo], name: item.name }); });
    return (React.createElement(GreySection, null,
        React.createElement("h2", null, "Container Cloud statistics"),
        React.createElement(StyledOverallStatistic, { className: "overall-statistic" }, items)));
};
export default withDisablingWhileFetchingHOC(memo(StaticStatsBanner), { spinner: true });

import React from 'react';
import { Spin, Empty } from 'antd';
import classNames from 'classnames';
import { StyledChartsLoader } from './StyledChartsLoader';
import { LoadingOutlined } from '@ant-design/icons';
var ChartsLoaderEmptyState = function (_a) {
    var isEmpty = _a.isEmpty, loading = _a.loading, children = _a.children;
    return (React.createElement(StyledChartsLoader, { className: "chart-loader" },
        React.createElement("div", { "data-testid": "spin", className: classNames('charts-loader', { loading: loading }) },
            React.createElement(Spin, { className: "spinner", indicator: React.createElement(LoadingOutlined, { style: { fontSize: 28 }, spin: true }) })),
        React.createElement("div", { className: classNames('empty', { isEmpty: isEmpty }) },
            React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: 'No data available' })),
        children));
};
export default ChartsLoaderEmptyState;

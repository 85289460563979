var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef } from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { useSize } from '@app/hooks';
import { StyledDisableWhileFetchContainer } from './styled';
var DEFAULT_REQUEST_IDS_TO_IGNORE = ['/statuses/components', '/statuses/application', '/instances/'];
/* eslint-disable react/display-name */
var withDisablingWhileFetchingHOC = function (WrappedComponent, config) {
    if (config === void 0) { config = { spinner: false, externalSpinner: false }; }
    return function (props) {
        var childRef = useRef(null);
        var _a = props.requestIdsToIgnore, requestIdsToIgnore = _a === void 0 ? DEFAULT_REQUEST_IDS_TO_IGNORE : _a, className = props.className;
        var size = useSize(childRef);
        var requestsInPendingStateIds = useSelector(function (store) { return store.requestsTracking.requestsInPendingStateIds; });
        var disabled = requestsInPendingStateIds.filter(function (value) { return !requestIdsToIgnore.some(function (item) { return value.includes(item); }); }).length >
            0;
        var hocClassNames = classNames('disable-while-fetching-hoc-container', className, {
            disabled: disabled,
            'spinner-external': config.externalSpinner,
        });
        return (React.createElement(StyledDisableWhileFetchContainer, { className: hocClassNames },
            (config.spinner || config.externalSpinner) && disabled && size.width > 0 && (React.createElement(Spin, { className: "disable-while-fetching-spinner", indicator: React.createElement(LoadingOutlined, { style: { fontSize: 28 }, spin: true }) })),
            React.createElement("div", { ref: childRef },
                React.createElement(WrappedComponent, __assign({}, props)))));
    };
};
export default withDisablingWhileFetchingHOC;

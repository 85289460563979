var FieldLevelValidation = /** @class */ (function () {
    function FieldLevelValidation() {
    }
    FieldLevelValidation.required = function (value) { return !value; };
    FieldLevelValidation.minLength = function (min) { return function (value) { return value && value.length < min; }; };
    FieldLevelValidation.maxLength = function (max) { return function (value) { return value && value.length > max; }; };
    FieldLevelValidation.email = function (value) {
        return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
    };
    FieldLevelValidation.checkbox = function (value) { return !value; };
    FieldLevelValidation.noSpecSymbols = function (value) {
        return value && !/^[a-zA-Z0-9][^^~`!@&#$%*+[\]{}()\\/:;?><=\t\n]{2,63}$/.test(value);
    };
    return FieldLevelValidation;
}());
export { FieldLevelValidation };

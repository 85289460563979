export var ARROW_ICON_SIZE = 10;
export var ASC_TYPE = 'asc';
export var DESC_TYPE = 'desc';
export var SEARCH_PLACEHOLDER = 'Enter at least 3 characters';
export var SECOND_SEARCH_PLACEHOLDER = 'Enter at least 1 characters';
export var TABLE_SEQUENTIAL_NUMBER = 'sequentialNumber';
export var MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

import { navBarRoutes, routes as config } from '@configs/routes';
import { pages } from '../pages';
import { dashboards } from './dashboards';
import { ROUTES_IDS } from '@commonConstants/routesConstants';
var dashboardRoutes = [
    {
        exact: true,
        id: ROUTES_IDS.HOME,
        component: dashboards.Home,
        path: navBarRoutes.HOME.path,
    },
    {
        exact: false,
        id: ROUTES_IDS.CONTAINER_CLOUD_USAGE,
        component: dashboards.UsageOverview,
        path: navBarRoutes.CONTAINER_CLOUD_USAGE.path,
    },
    {
        exact: false,
        id: ROUTES_IDS.OPENSTACK_USAGE,
        component: dashboards.OpenstackUsage,
        path: navBarRoutes.OPENSTACK_OVERVIEW.path,
    },
    {
        exact: false,
        id: ROUTES_IDS.OPENSTACK_CLUSTERS_USAGE,
        component: dashboards.OpenstackClusterUsage,
        path: navBarRoutes.OPENSTACK_CLUSTERS_USAGE.path,
    },
    {
        exact: false,
        id: ROUTES_IDS.ALERTS,
        component: dashboards.Alerts,
        path: navBarRoutes.ALERTS.path,
    },
    {
        exact: false,
        id: ROUTES_IDS.CLUSTER_HEALTH,
        component: dashboards.ClusterHealth,
        path: navBarRoutes.CLUSTER_HEALTH.path,
    },
    {
        exact: false,
        id: ROUTES_IDS.MANAGE_USERS,
        component: dashboards.ManageUsers,
        path: navBarRoutes.MANAGE_USERS.path,
        forAdmin: true,
    },
    {
        exact: false,
        id: ROUTES_IDS.PERMISSION_GROUP,
        component: dashboards.PermissionGroups,
        path: navBarRoutes.PERMISSION_GROUPS.path,
        forAdmin: true,
    },
    {
        exact: false,
        id: ROUTES_IDS.MANAGE_CUSTOMERS_CONTAINER,
        component: dashboards.ManageCustomersContainer,
        path: navBarRoutes.MANAGE_CUSTOMERS.path,
        forAdmin: true,
    },
    {
        exact: false,
        id: ROUTES_IDS.BOOTSTRAP,
        component: dashboards.Bootstrap,
        path: navBarRoutes.BOOTSTRAP.path,
    },
    {
        exact: false,
        id: ROUTES_IDS.INSTALLATION_REPORT,
        component: dashboards.InstallationReport,
        path: navBarRoutes.INSTALLATION_REPORT.path,
    },
    {
        exact: false,
        id: ROUTES_IDS.NEW_SIGNUPS,
        component: dashboards.NewSignUps,
        path: navBarRoutes.NEW_SIGN_UPS_REPORT.path,
    },
    {
        exact: false,
        id: ROUTES_IDS.ACTIVE_INSTANCES,
        component: dashboards.ActiveInstances,
        path: navBarRoutes.ACTIVE_INSTANCES.path,
    },
    {
        exact: false,
        id: ROUTES_IDS.TRIAL_REPORT,
        component: dashboards.TrialReport,
        path: navBarRoutes.TRIAL_REPORT.path,
    },
    {
        exact: false,
        id: ROUTES_IDS.TRIAL_COSTS,
        component: dashboards.TrialStatistic,
        path: navBarRoutes.COSTS.path,
    },
    {
        exact: false,
        id: ROUTES_IDS.MCC_DASHBOARDS,
        component: dashboards.telemetryContainerNew,
        path: navBarRoutes.TELEMETRY_DASHBOARD.path,
        forSFUser: true,
    },
    {
        exact: false,
        id: ROUTES_IDS.SEARCH_CLUSTERS,
        component: dashboards.SearchClusters,
        path: navBarRoutes.SEARCH_CLUSTERS.path,
        forSFUser: false,
    },
];
export var generalRouters = [
    {
        exact: false,
        component: pages.Main,
        nestedRoutes: dashboardRoutes,
        path: config.MAIN.path,
        forAuthorized: true,
    },
    {
        exact: true,
        component: pages.SignIn,
        path: config.SIGN_IN.path,
    },
    {
        exact: true,
        component: pages.ForbiddenAccess,
        path: config.FORBIDDEN_ACCESS.path,
    },
    {
        exact: false,
        component: pages.NotFound,
        path: config.NOT_FOUND.path,
    },
];

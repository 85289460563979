var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import head from 'lodash/head';
import { fetchCharts } from '@redux/dispatchers/charts';
import { usePrevious } from '@app/hooks';
import BarChart from '@commonComponents/chart/bar/Bar';
import { afterTitleMoney, getNormalizedMoneyTooltipValue, getNormalizedMoneyYValue, getChartOptions as getBarChartOptions, } from '@commonUtils/charts/chartUtils';
import { DateUtils } from '@utils/date/DateUtils';
import { StyledChartsWrapperNew } from '@app/common/react/styled/StyledChartsWrapperNew';
import { STATIC_REPORTS_DATE_RANGES } from '@utils/date/dateConstants';
import { COSTS_CHARTS_KEYS } from './costsConstants';
import { getNormalizedCosts, getCostsChartsConfigs } from './costChartUtils';
import { StyledChart } from './style';
var CostsCharts = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var stacked = _a.stacked;
    var _q = useSelector(function (store) { return ({
        multiFilter: store.multiFilter,
        crossPageFilter: store.crossPageFilter,
        trialReportsCosts: store.charts.trialReportsCosts,
        trialReportsOccupiedSlots: store.charts.trialReportsOccupiedSlots,
    }); }), trialReportsCosts = _q.trialReportsCosts, multiFilter = _q.multiFilter;
    var loading = (_b = trialReportsCosts[COSTS_CHARTS_KEYS.COSTS_CHARTS]) === null || _b === void 0 ? void 0 : _b.loading;
    var prevAwsAccounts = usePrevious((_d = (_c = multiFilter === null || multiFilter === void 0 ? void 0 : multiFilter.filter) === null || _c === void 0 ? void 0 : _c.selected) === null || _d === void 0 ? void 0 : _d.awsAccounts);
    useEffect(function () {
        var _a, _b;
        if (!loading && !isEqual(prevAwsAccounts, (_b = (_a = multiFilter === null || multiFilter === void 0 ? void 0 : multiFilter.filter) === null || _a === void 0 ? void 0 : _a.selected) === null || _b === void 0 ? void 0 : _b.awsAccounts)) {
            fetchCharts(getCostsChartsConfigs(multiFilter, DateUtils.calculateDateRangeByOption(STATIC_REPORTS_DATE_RANGES.PREVIOUS_YEAR)));
        }
    }, [(_f = (_e = multiFilter === null || multiFilter === void 0 ? void 0 : multiFilter.filter) === null || _e === void 0 ? void 0 : _e.selected) === null || _f === void 0 ? void 0 : _f.awsAccounts]);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledChartsWrapperNew, { className: "styled_charts_wrapper one" },
            React.createElement(BarChart, { loadingOut: loading, title: "AWS cost by Month", maxXAxisTicksLimit: 12, datasetsTitles: get(trialReportsCosts[COSTS_CHARTS_KEYS.COSTS_CHARTS], 'data', []).map(function (_a) {
                    var account = _a.account;
                    return account;
                }), 
                // @ts-ignore
                datasets: getNormalizedCosts((_g = trialReportsCosts[COSTS_CHARTS_KEYS.COSTS_CHARTS]) === null || _g === void 0 ? void 0 : _g.data, 'month', head((_j = (_h = multiFilter === null || multiFilter === void 0 ? void 0 : multiFilter.filter) === null || _h === void 0 ? void 0 : _h.selected) === null || _j === void 0 ? void 0 : _j.year)), chartOptions: __assign(__assign({}, getBarChartOptions({
                    getNormalizedYValue: getNormalizedMoneyYValue,
                    getNormalizedTooltipValue: getNormalizedMoneyTooltipValue,
                    stackedY: stacked,
                    stackedX: stacked,
                    afterBody: afterTitleMoney,
                })), { xFormatFunction: function (value) { return value; } }) })),
        React.createElement(StyledChart, null,
            React.createElement(StyledChartsWrapperNew, { className: "styled_charts_wrapper one" },
                React.createElement(BarChart, { loadingOut: loading, title: "AWS cost by Day", maxXAxisTicksLimit: 31, datasetsTitles: get(trialReportsCosts[COSTS_CHARTS_KEYS.COSTS_CHARTS], 'data', []).map(function (_a) {
                        var account = _a.account;
                        return account;
                    }), 
                    // @ts-ignore
                    datasets: getNormalizedCosts((_k = trialReportsCosts[COSTS_CHARTS_KEYS.COSTS_CHARTS]) === null || _k === void 0 ? void 0 : _k.data, head((_m = (_l = multiFilter === null || multiFilter === void 0 ? void 0 : multiFilter.filter) === null || _l === void 0 ? void 0 : _l.selected) === null || _m === void 0 ? void 0 : _m.month), head((_p = (_o = multiFilter === null || multiFilter === void 0 ? void 0 : multiFilter.filter) === null || _o === void 0 ? void 0 : _o.selected) === null || _p === void 0 ? void 0 : _p.year)), chartOptions: __assign(__assign({}, getBarChartOptions({
                        getNormalizedYValue: getNormalizedMoneyYValue,
                        getNormalizedTooltipValue: getNormalizedMoneyTooltipValue,
                        stackedY: stacked,
                        stackedX: stacked,
                        afterBody: afterTitleMoney,
                    })), { xFormatFunction: function (value) { return value; } }) })))));
};
export default memo(CostsCharts);

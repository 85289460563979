var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useRef, useState } from 'react';
import remove from 'lodash/remove';
import { Checkbox, checkValues } from '@mirantis/mds-checkbox';
import { SettingsIcon, variants } from '@mirantis/mds-icon';
import ComponentDropdown from '@root/app/common/react/components/componentDropdown/ComponentDropdown';
import { StyledClearfix } from '@app/common/react/styled/StyledClearfix';
import { TABLE_SEQUENTIAL_NUMBER } from '@commonConstants/commonConstants';
import { StyledTableController } from './styled';
var ColumnController = function (_a) {
    var visibleColumnsKeys = _a.visibleColumnsKeys, columns = _a.columns, setVisibleColumns = _a.setVisibleColumns;
    var _b = useState(false), opened = _b[0], setOpened = _b[1];
    var ref = useRef(null);
    var Raw = function () {
        var onChange = function (val) {
            var arr = __spreadArray([], visibleColumnsKeys.filter(function (value) { return value !== TABLE_SEQUENTIAL_NUMBER; }), true);
            if (arr.includes(val)) {
                if (arr.length <= 2) {
                    return;
                }
                remove(arr, function (string) { return string === val; });
            }
            else {
                arr.push(val);
            }
            setVisibleColumns(arr);
        };
        var checks = columns
            .filter(function (_a) {
            var isStatic = _a.isStatic;
            return !isStatic;
        })
            .map(function (column) { return (React.createElement(Checkbox, { key: column.key, label: column.name || ' ', value: visibleColumnsKeys.includes(column.key) ? checkValues.MIXED : checkValues.UNCHECKED, onChange: function () { return onChange(column.key); }, "data-testid": "checkbox_".concat(column.key) })); });
        return (React.createElement("div", { className: "table__controller__dropdown" },
            React.createElement("div", { className: "table__controller__dropdown__title" }, "Columns"),
            React.createElement("div", null, checks)));
    };
    return (React.createElement(StyledClearfix, null,
        React.createElement(StyledTableController, { className: "table__controller", "data-testid": "columns-controller" },
            React.createElement("div", { className: "table__controller__icon", "data-testid": "toggle_controller", onClick: function () { return setOpened(!opened); }, ref: ref },
                React.createElement(SettingsIcon, { size: 20, variant: opened ? variants.INFO : variants.NORMAL, "data-testid": "svgClickable" })),
            React.createElement(ComponentDropdown, { show: opened, setShow: setOpened, relatedDropdownRefs: [ref] },
                React.createElement(Raw, null)))));
};
export default ColumnController;

import React from 'react';
import classnames from 'classnames';
var TableTR = function (_a) {
    var columns = _a.columns, rows = _a.rows, normalizers = _a.normalizers, customizeValue = _a.customizeValue, extraCustomColumns = _a.extraCustomColumns, summaryNormalizers = _a.summaryNormalizers, summary = _a.summary, summaryRows = _a.summaryRows;
    var tdsSummary = summary
        ? columns.map(function (column) {
            var tdValueSummary = column.key in summaryNormalizers
                ? summaryNormalizers[column.key](summaryRows[column.key], rows, summaryRows)
                : '';
            return (React.createElement("td", { key: column.key, "data-testid": "td_".concat(column.key), className: classnames('table__td', column.key) }, customizeValue(column.key, tdValueSummary)));
        })
        : '';
    return (React.createElement(React.Fragment, null,
        rows.map(function (row) {
            var tds = columns.map(function (column) {
                var tdValue = column.key in row && column.key in normalizers
                    ? normalizers[column.key](row[column.key], row)
                    : row[column.key];
                return (React.createElement("td", { key: column.key, "data-testid": "td_".concat(column.key), className: classnames('table__td', column.key) }, customizeValue(column.key, tdValue)));
            });
            return (React.createElement("tr", { key: row.sequentialNumber, id: row === null || row === void 0 ? void 0 : row.id, className: "table__tr" },
                tds,
                extraCustomColumns.map(function (customColumn) { return (React.createElement("td", { "data-testid": "td_".concat(customColumn.key), key: "".concat(row.sequentialNumber, " ").concat(customColumn.key), className: classnames('table__td', customColumn.key) }, customColumn.getComponent(row.id))); })));
        }),
        summary && (React.createElement("tr", { key: summaryRows.sequentialNumber, id: summaryRows === null || summaryRows === void 0 ? void 0 : summaryRows.id, className: "table__tr table__tr-summary" },
            tdsSummary,
            extraCustomColumns.map(function (customColumn) { return (React.createElement("td", { "data-testid": "td_".concat(customColumn.key), key: "".concat(summaryRows.sequentialNumber, " ").concat(customColumn.key), className: classnames('table__td', customColumn.key) }, customColumn.getComponent(summaryRows.id))); })))));
};
export default TableTR;

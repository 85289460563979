var _a;
import { URL_QUERIES } from '@commonConstants/urlConstants';
import { validateStatus, validateTimezone, validateDateRange, validateSourceType, validatePagination, validateGranularity, validateCustomerType, validateAlertsStatus, validateAlertPriority, validateManageClusterList, validateHasActiveMkeLicense, validateTelemetryAlertsFilter, validateManageCustomersActiveTab, } from './queryParamsValidators';
var COMMON = URL_QUERIES.COMMON, TELEMETRY = URL_QUERIES.TELEMETRY, MANAGE = URL_QUERIES.MANAGE, DASHBOARDS = URL_QUERIES.DASHBOARDS, PICKERS = URL_QUERIES.PICKERS, TRIAL_STATISTIC = URL_QUERIES.TRIAL_STATISTIC;
var QUERY_PARAMS_VALIDATORS_MAP = (_a = {},
    _a[COMMON.TYPE] = function (value) { return value; },
    _a[COMMON.STAGE] = function (value) { return value; },
    _a[COMMON.CLUSTER] = function (value) { return value; },
    _a[COMMON.PROVIDER] = function (value) { return value; },
    _a[COMMON.INSTANCE] = function (value) { return value; },
    _a[COMMON.CUSTOMER] = function (value) { return value; },
    _a[COMMON.ACCOUNT_ID] = function (value) { return value; },
    _a[COMMON.MKE_VERSION] = function (value) { return value; },
    _a[COMMON.PERMISSIONS] = function (value) { return value; },
    _a[COMMON.SEARCH_VALUE] = function (value) { return value; },
    _a[COMMON.HEALTH_STATUS] = function (value) { return value; },
    _a[COMMON.LAST_SYNC_DATE] = function (value) { return value; },
    _a[COMMON.IS_MCC_CUSTOMER] = function (value) { return value; },
    _a[DASHBOARDS.CHECKED_NODES] = function (value) { return value; },
    _a[COMMON.HIDE_INACTIVE_CUSTOMERS] = function (value) { return value; },
    _a[DASHBOARDS.IS_ACTIVE_CLUSTERS] = function (value) { return value; },
    _a[TELEMETRY.TELEMETRY_MCC_NAME] = function (value) { return value; },
    _a[TELEMETRY.TELEMETRY_ACTIVE_CLUSTER_ID] = function (value) { return value; },
    _a[COMMON.CLUSTER_HEALTH_STATUS] = function (value) { return value; },
    _a[PICKERS.CLUSTER_PICKER.value] = function (value) { return value; },
    _a[PICKERS.EXTERNAL_PICKER.picker] = function (value) { return value; },
    _a[PICKERS.STACKED_PICKER.picker] = function (value) { return value; },
    _a[PICKERS.PERMISSIONS_PICKER.value] = function (value) { return value; },
    _a[PICKERS.CUSTOMER_PICKER.value] = function (value) { return value; },
    _a[PICKERS.INSTANCE_PICKER.value] = function (value) { return value; },
    _a[PICKERS.TRIAL_REPORT_STATUS_PICKER.value] = function (value) { return value; },
    _a[PICKERS.TRIAL_REPORT_IS_MIRANTIS_PICKER.value] = function (value) { return value; },
    _a[PICKERS.TRIAL_REPORT_CLUSTER_CREATED_PICKER.value] = function (value) { return value; },
    _a[PICKERS.TRIAL_REPORT_CLUSTER_CREATED_PICKER.value] = function (value) { return value; },
    _a[PICKERS.TRIAL_STATISTIC_YEAR_PICKER.value] = function (value) { return value; },
    _a[PICKERS.TRIAL_STATISTIC_MONTH_PICKER.value] = function (value) { return value; },
    _a[PICKERS.AWS_ACCOUNTS_PICKER.value] = function (value) { return value; },
    _a[TELEMETRY.INACTIVE_HOURS_THRESHOLD] = function (value) { return value; },
    _a[TRIAL_STATISTIC.TRIAL_STATISTIC_ACTIVE_TAB] = function (value) { return value; },
    _a[COMMON.STATUS] = validateStatus,
    _a[COMMON.TIME_ZONE] = validateTimezone,
    _a[COMMON.DATE_RANGE] = validateDateRange,
    _a[COMMON.PAGINATION] = validatePagination,
    _a[COMMON.SOURCE_TYPE] = validateSourceType,
    _a[COMMON.GRANULARITY] = validateGranularity,
    _a[COMMON.CUSTOMER_TYPE] = validateCustomerType,
    _a[COMMON.ALERT_PRIORITY] = validateAlertPriority,
    _a[COMMON.HAS_ACTIVE_MKE_LICENSE] = validateHasActiveMkeLicense,
    _a[TELEMETRY.TELEMETRY_ALERTS_FILTER] = validateTelemetryAlertsFilter,
    _a[TELEMETRY.TELEMETRY_MANAGE_CLUSTER_LIST] = validateManageClusterList,
    _a[MANAGE.MANAGE_CUSTOMERS_ACTIVE_TAB] = validateManageCustomersActiveTab,
    _a[DASHBOARDS.ALERT_STATUS] = validateAlertsStatus,
    _a);
export default QUERY_PARAMS_VALIDATORS_MAP;

import get from 'lodash/get';
import moment from 'moment';
export var getNormalizedToggleClustersStatus = function (clusters) {
    return clusters.map(function (_a) {
        var data = _a.data;
        return ({
            type: data.type,
            userId: data.user_id,
            message: data.message,
            clusterId: data.cluster_id,
        });
    });
};
export var getLastSyncDate = function (cluster) {
    return moment(cluster.last_sync_date).valueOf() < 0 ? cluster.cluster_last_sync_date : cluster.last_sync_date;
};
export var getNormalizedMKEOverview = function (response) {
    var clusters = get(response, 'clusters', []).map(function (cluster) { return ({
        clusterId: cluster.cluster_id,
        mcrVersion: cluster.mcr_version,
        mkeVersion: cluster.mke_version,
        msrVersion: cluster.msr_version,
        healthStatus: cluster.health_status,
        utilizationStatus: cluster.utilization_status,
        cpu: cluster.cpu,
        nodeCount: cluster.node_count,
        healthInfoCount: cluster.health_info_count,
        healthWarningCount: cluster.health_warning_count,
        healthCriticalCount: cluster.health_critical_count,
        license: cluster.license,
        isHidden: cluster.is_hidden,
        lastSyncDate: getLastSyncDate(cluster),
        types: ['MKE'],
        memory: +cluster.memory.toFixed(),
    }); });
    return {
        clusters: clusters,
        allClustersCount: response.all_clusters_count,
        globalHealthScore: response.global_health_score,
        globalHealthInfoCount: response.global_health_info_count,
        globalHealthWarningCount: response.global_health_warning_count,
        globalHealthCriticalCount: response.global_health_critical_count,
        globalHealthStatus: response.global_health_status,
        globalUtilizationScore: response.global_utilization_score,
        globalUtilizationInfoCount: response.global_utilization_info_count,
        globalUtilizationWarningCount: response.global_utilization_warning_count,
        globalUtilizationCriticalCount: response.global_utilization_critical_count,
        globalUtilizationStatus: response.global_utilization_status,
    };
};
export var getNormalizedMCCOverview = function (response) {
    var clusters = get(response, 'clusters', []).map(function (cluster) { return ({
        clusterId: cluster.cluster_id,
        clusterUpdating: cluster.cluster_updating,
        clusterName: cluster.cluster_name || cluster.cluster_id,
        crVersion: cluster.cluster_release,
        isHidden: cluster.is_hidden,
        k8sApiAvailability: cluster.k8s_api_availability.toFixed(),
        k8sVersion: cluster.k8s_version,
        lastSyncDate: getLastSyncDate(cluster),
        managementId: cluster.management_id,
        mccVersion: cluster.mcc_version,
        mkeApiAvailability: cluster.mke_api_availability.toFixed(),
        mkeVersion: cluster.mke_version,
        nodeCount: cluster.node_count,
        controlPlaneNodes: cluster.control_plane_nodes,
        computeNodes: cluster.compute_nodes,
        osdNodes: cluster.osd_nodes,
        provider: cluster.provider,
        regionId: cluster.region_id,
        types: cluster.type,
        disk: +cluster.disk.toFixed(),
        utilizationStatus: cluster.utilization_status,
        cpuAbs: +cluster.cpu_capacity.toFixed(),
        cpuPercentage: (+cluster.cpu_percentage * 100).toFixed(),
        memoryAbs: +cluster.ram_capacity.toFixed(),
        memoryPercentage: (+cluster.memory_percentage * 100).toFixed(),
        instanceId: cluster.instance_id,
        instanceName: cluster.instance_name,
    }); });
    return {
        clusters: clusters,
        allClustersInformationMccMke: get(response, 'all_clusters_information_mcc_mke', 0),
        allClustersInformationMccMos: get(response, 'all_clusters_information_mcc_mos', 0),
    };
};
export var getNormalizedMKEAlerts = function (response) {
    var _a;
    var data = (_a = response.data) === null || _a === void 0 ? void 0 : _a.map(function (dataItem) { return ({
        type: dataItem.type,
        action: dataItem.action,
        description: dataItem.description,
        status: dataItem.status,
    }); });
    return {
        data: data,
        maxPages: response.max_pages,
        currentPage: response.current_page,
    };
};
export var getNormalizedMKERecommendations = function (response) {
    var data = response.data.map(function (dataItem) { return ({
        type: dataItem.type,
        description: dataItem.description,
        extraInfo: dataItem.extra_info,
        link: dataItem.link,
        effort: dataItem.effort,
    }); });
    return {
        data: data,
        maxPages: response.max_pages,
        currentPage: response.current_page,
    };
};

import { store } from '@redux/store';
import * as actionCreators from '@redux/action-creators';
export var fetchCharts = function (payload) {
    var action = actionCreators.charts.fetchCharts(payload);
    store.dispatch(action);
};
export var setChartsData = function (payload) {
    var action = actionCreators.charts.setChartsData(payload);
    store.dispatch(action);
};
export var setChartsCanvasLoaded = function (payload) {
    var action = actionCreators.charts.setChartsCanvasLoaded(payload);
    store.dispatch(action);
};
export var dropChartsToDefault = function (payload) {
    var action = actionCreators.charts.dropChartsToDefault(payload);
    store.dispatch(action);
};

export var getNormalizedBootstrapStats = function (response) { return ({
    customerCount: response.customer_count,
    instanceCount: response.instance_count,
    attemptCount: response.attempt_count,
    successCount: response.success_count,
    successRatio: response.success_ratio,
}); };
export var getNormalizedBootstrapEvents = function (response) {
    var data = response.data.map(function (dataItem) { return ({
        eventTime: dataItem.event_time,
        customerId: dataItem.customer_id,
        customerName: dataItem.customer_name,
        instanceId: dataItem.instance_id,
        instanceName: dataItem.instance_name,
        stage: dataItem.stage,
        provider: dataItem.provider,
        status: dataItem.status,
        message: dataItem.message,
        binVersion: dataItem.bin_version,
    }); });
    return {
        data: data,
        maxPages: response.max_pages,
        currentPage: response.current_page,
    };
};
export var getNormalizedBootstrapInstallations = function (response) {
    var data = response.data.map(function (dataItem) { return ({
        date: dataItem.date,
        attempts: dataItem.attempts,
        successes: dataItem.successes,
        binaryExecutions: dataItem.binary_executions,
    }); });
    return {
        data: data,
        maxPages: response.max_pages,
        currentPage: response.current_page,
    };
};

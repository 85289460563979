var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef, useState } from 'react';
import isNil from 'lodash/isNil';
import styled from '@emotion/styled';
import Redirect from '@commonUtils/urlUtils/Redirect';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import ComponentDropdown from '@root/app/common/react/components/componentDropdown/ComponentDropdown';
import ButtonLogout from '@commonComponents/buttons/ButtonLogout';
import ButtonSettings from '@commonComponents/buttons/ButtonSettings';
import Profile from './profile/Profile';
import SettingsModalContainer from './settingsModal/SettingsModalContainer';
var TIME_ZONE = URL_QUERIES.COMMON.TIME_ZONE;
var ActiveUserDropdown = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    left: 0;\n    width: 240px;\n    bottom: 128px;\n    z-index: 10000;\n    padding: 7.5px 0;\n    position: absolute;\n    box-shadow: ", ";\n    background: ", ";\n\n    & > div {\n        padding: 7.5px 19px;\n\n        &:hover {\n            background: ", ";\n        }\n    }\n"], ["\n    left: 0;\n    width: 240px;\n    bottom: 128px;\n    z-index: 10000;\n    padding: 7.5px 0;\n    position: absolute;\n    box-shadow: ", ";\n    background: ", ";\n\n    & > div {\n        padding: 7.5px 19px;\n\n        &:hover {\n            background: ", ";\n        }\n    }\n"])), function (props) { return props.theme.shadow.box[0].css; }, function (props) { return props.theme.color.background.layer3; }, function (props) { return props.theme.color.background.layer2; });
var ActiveUser = function (_a) {
    var user = _a.user, location = _a.location, timeZoneFromUrl = _a.timeZoneFromUrl, isSFUser = _a.isSFUser, handlePreferencesSave = _a.handlePreferencesSave, changeUserPreference = _a.changeUserPreference, collapsed = _a.collapsed;
    var _b = useState(false), showDropdown = _b[0], setShowDropdown = _b[1];
    var _c = useState(false), showModal = _c[0], setShowModal = _c[1];
    var ref = useRef(null);
    var userTimezone = user === null || user === void 0 ? void 0 : user.timezone;
    var dismiss = function () {
        setShowModal(false);
    };
    useEffect(function () {
        if (isSFUser)
            return;
        var timezone = userTimezone === 'UTC' ? 'UTC' : 'Local';
        Redirect.toCurrentPageWithQueryParams(TIME_ZONE, [timezone], '');
    }, [userTimezone, location.pathname]);
    useEffect(function () {
        if (isSFUser)
            return;
        if (!isNil(timeZoneFromUrl) && timeZoneFromUrl !== userTimezone) {
            changeUserPreference({
                key: 'timezone',
                value: timeZoneFromUrl,
            });
            handlePreferencesSave(__assign(__assign({}, user), { timezone: timeZoneFromUrl }));
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { "data-testid": "active-user", className: "active-user__profile-wrapper", ref: ref, onClick: function () { return setShowDropdown(!showDropdown); } },
            React.createElement(Profile, { collapsed: collapsed })),
        React.createElement(ComponentDropdown, { show: showDropdown, setShow: setShowDropdown, relatedDropdownRefs: [ref] },
            React.createElement(ActiveUserDropdown, { className: "active-user__dropdown" },
                React.createElement(ButtonSettings, { setShowModal: setShowModal, disabled: isSFUser }),
                React.createElement(ButtonLogout, null))),
        showModal && React.createElement(SettingsModalContainer, { dismiss: dismiss })));
};
export default ActiveUser;
var templateObject_1;

export var getNormalizedTrial = function (response) {
    var data = response.data.map(function (dataItem) { return ({
        id: "id_".concat(dataItem.email).concat(dataItem.created_at),
        email: dataItem.email,
        name: dataItem.name,
        status: dataItem.status,
        createdAt: dataItem.created_at,
        startedAt: dataItem.started_at,
        expiredAt: dataItem.expired_at,
        isClusterCreated: dataItem.cluster_created,
        isMirantis: dataItem.is_mirantis,
        licenseDownloadedAt: dataItem.license_downloaded_at,
    }); });
    return {
        data: data,
        maxPages: response.max_pages,
        currentPage: response.current_page,
    };
};
export var getNormalizedTrialStatistics = function (response) {
    return {
        fullCount: response.full_count,
        mirantisCount: response.mirantis_count,
        clusterCreatedCount: response.cluster_created_count,
    };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import * as chart from 'chart.js';
import { Bar } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartsLoaderEmptyState from '../ChartsLoader';
import Tooltip from '@commonComponents/tooltip/Tooltip';
import { getOptions, isChartDataEmpty } from './barUtils';
import { StyledBar } from './StyledBar';
import { BACKGROUND_COLOR } from '../chartConstants';
var plugins = [];
chart.Chart.register(ChartDataLabels);
chart.Chart.register(chart.Filler);
chart.Chart.register(zoomPlugin);
chart.Chart.register(chart.CategoryScale, chart.CategoryScale, chart.LinearScale, chart.BarElement, chart.Title, chart.Tooltip, chart.Legend);
var BarChart = function (_a) {
    var title = _a.title, leftLabel = _a.leftLabel, message = _a.message, _b = _a.datasets, datasets = _b === void 0 ? [] : _b, _c = _a.datasetsTitles, datasetsTitles = _c === void 0 ? [''] : _c, chartOptions = _a.chartOptions, isDataLabels = _a.isDataLabels, maxXAxisTicksLimit = _a.maxXAxisTicksLimit, maxXRotation = _a.maxXRotation, minXRotation = _a.minXRotation, loadingOut = _a.loadingOut;
    var _d = useState(false), isDerty = _d[0], setDerty = _d[1];
    var _e = useState(true), isLoading = _e[0], setLoading = _e[1];
    useEffect(function () {
        if (loadingOut) {
            setLoading(true);
            setDerty(true);
        }
        else if (isChartDataEmpty(datasets)) {
            setLoading(false);
        }
    }, [loadingOut]);
    var isEmpty = isDerty && !isLoading && isChartDataEmpty(datasets);
    return (React.createElement(ChartsLoaderEmptyState, { loading: isLoading, isEmpty: isEmpty },
        React.createElement("div", { className: "chart" },
            React.createElement(StyledBar, null,
                React.createElement(Tooltip, { message: message, place: "top", className: "chart-title-tooltip" },
                    React.createElement("div", { className: "title" }, title)),
                React.createElement("div", { className: "left-side-label" },
                    React.createElement("span", { className: "label" }, leftLabel)),
                React.createElement(Bar, { data: {
                        labels: datasets.length > 0 ? datasets[0].map(function (e) { return chartOptions.xFormatFunction(e.x); }) : [],
                        datasets: datasets.map(function (dataset, index) { return ({
                            label: datasetsTitles[index],
                            data: dataset.map(function (e) { return e.y; }),
                            backgroundColor: BACKGROUND_COLOR[index],
                            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                            hoverBorderColor: 'rgba(255,99,132,1)',
                            pointStyle: 'bar',
                            borderWidth: 1, // line thickness
                        }); }),
                    }, type: "bar", 
                    // @ts-ignore
                    plugins: plugins, 
                    // @ts-ignore
                    options: getOptions(__assign(__assign({}, chartOptions), { isLegend: datasets.length > 1, isDataLabels: isDataLabels, maxXAxisTicksLimit: maxXAxisTicksLimit, maxXRotation: maxXRotation, minXRotation: minXRotation, setLoading: setLoading, isChartDataEmpty: isChartDataEmpty(datasets), isLoadingOut: loadingOut })) })))));
};
export default BarChart;

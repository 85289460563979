import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { getUrlWithParams } from '@commonUtils/urlUtils/urlUtils';
import { isSFPermissionGroup } from '@commonUtils/commonUtils';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import ManageClustersLinkStyled from './ManageClustersLinkStyled';
var TELEMETRY_MANAGE_CLUSTER_LIST = URL_QUERIES.TELEMETRY.TELEMETRY_MANAGE_CLUSTER_LIST;
var ManageClustersLink = function (_a) {
    var _b;
    var type = _a.type;
    var isSfUser = isSFPermissionGroup();
    var allClustersCount = useSelector(function (store) {
        return get(store, "telemetry.customer.".concat(type.toLowerCase(), "Overview.allClustersCount"), 0);
    });
    return allClustersCount > 0 && isSfUser ? (React.createElement(ManageClustersLinkStyled, null,
        React.createElement(Link, { className: "manage-clusters-list", to: getUrlWithParams((_b = {}, _b[TELEMETRY_MANAGE_CLUSTER_LIST] = type, _b)) }, "Manage Cluster List"))) : (React.createElement("span", null));
};
export default ManageClustersLink;

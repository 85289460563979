import cloneDeep from 'lodash/cloneDeep';
import { store } from '@redux/store';
var getState = function () {
    return cloneDeep(store.getState());
};
export var userPreferencesFromStore = function (additionalParams) {
    if (additionalParams === void 0) { additionalParams = {}; }
    var userInfo = getState().userInfo;
    var timezone = userInfo.settings.current.timezone;
    var resolvedTimezone = timezone === 'UTC' ? timezone : 'Local';
    return Object.assign(additionalParams, {
        timezone: resolvedTimezone,
    });
};

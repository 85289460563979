export var SyncActions;
(function (SyncActions) {
    SyncActions["RESET_SIGN_IN"] = "RESET_SIGN_IN";
    SyncActions["REDIRECTING_STARTED"] = "REDIRECTING_STARTED";
})(SyncActions || (SyncActions = {}));
export var AsyncActions;
(function (AsyncActions) {
    AsyncActions["SIGN_IN_STARTED"] = "SIGN_IN_STARTED";
    AsyncActions["CHANGE_SIGN_IN_STEP"] = "CHANGE_SIGN_IN_STEP";
})(AsyncActions || (AsyncActions = {}));

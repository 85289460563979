import * as Actions from '@redux/actions/signIn';
export var signInStarted = function () { return ({
    type: Actions.AsyncActions.SIGN_IN_STARTED,
}); };
export var changeSignInStep = function () { return ({
    type: Actions.AsyncActions.CHANGE_SIGN_IN_STEP,
}); };
export var redirectingStarted = function () { return ({
    type: Actions.SyncActions.REDIRECTING_STARTED,
}); };
export var resetSignIn = function () { return ({
    type: Actions.SyncActions.RESET_SIGN_IN,
}); };

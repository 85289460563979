var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import { DateUtils } from '@utils/date/DateUtils';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import DatePickerView from './DatePickerView';
import { getInitialState, getHandleMonthSwitch } from './datePickerUtils';
import { MAX_NUM_OF_DAYS, TIMES } from './datePickerConstants';
var PSEUDO_CC = DASHBOARDS_SCHEMA.MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.PSEUDO_CC;
// TODO: consider to get rid of prevStartEndingDates
var prevStartEndingDates = {};
var DatePicker = function (_a) {
    var show = _a.show, prevShow = _a.prevShow, dateRange = _a.dateRange, changeCustomDateRange = _a.changeCustomDateRange;
    var crossPageFilter = useSelector(function (store) { return store.crossPageFilter; });
    var _b = useState(getInitialState(dateRange, show, prevStartEndingDates)), state = _b[0], setState = _b[1];
    useEffect(function () {
        if (!prevShow) {
            prevStartEndingDates = {};
        }
    }, [prevShow]);
    var handleMonthSwitch = getHandleMonthSwitch(state, setState);
    var updatesState = function (newState) {
        setState(__assign(__assign({}, state), newState));
    };
    var handleApply = function () {
        var end = new Date(state.selectedDateEnd.split('-').join('/'));
        var start = new Date(state.selectedDateStart.split('-').join('/'));
        //@ts-ignore
        var diffTime = Math.abs(end - start);
        var numberOfSelectedDays = Math.ceil(diffTime / (TIMES.MILISEC * TIMES.SEC * TIMES.MIN * TIMES.HOURS));
        if (crossPageFilter.dashboardName === PSEUDO_CC && numberOfSelectedDays > MAX_NUM_OF_DAYS) {
            message.error('Max step is 12 month', 10);
        }
        else {
            changeCustomDateRange({
                start: DateUtils.formatCustomDateRange(state.selectedDateStart, 'start'),
                end: DateUtils.formatCustomDateRange(state.selectedDateEnd, 'end'),
            });
            // we save prev startingDate & endingDate dates
            prevStartEndingDates = {
                startingDate: state.startingDate,
                endingDate: state.endingDate,
            };
        }
    };
    return (React.createElement(DatePickerView, { show: show, handleApply: handleApply, hoveredDate: state.hoveredDate, startingDate: state.startingDate, endingDate: state.endingDate, selectedDateEnd: state.selectedDateEnd, selectedDateStart: state.selectedDateStart, handleMonthSwitch: handleMonthSwitch, updatesState: updatesState }));
};
export default DatePicker;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getCustomersOverview, getCustomersWithLocations } from '@api/customers-api/customersApi';
import { setTopCustomers, setFilteredCustomers, setMapFilterOptions } from '@redux/action-creators/home/home';
import { filterCustomersByOptions } from '@utils/filtering/customers';
import { extractOptionsForMapFilter } from '@utils/extracting/customers';
import * as Actions from '@redux/actions';
export function getTopCustomers(_a) {
    var customerOverview, e_1;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(getCustomersOverview, payload)];
            case 1:
                customerOverview = _b.sent();
                return [4 /*yield*/, put(setTopCustomers(customerOverview))];
            case 2:
                _b.sent();
                return [3 /*break*/, 4];
            case 3:
                e_1 = _b.sent();
                console.error('Failed to get top customers');
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
export function getCustomersForMap(action) {
    var res, page, customersList, params, options, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                res = null;
                page = 1;
                customersList = [];
                _a.label = 1;
            case 1:
                params = __assign({}, action.payload);
                params.page = page;
                return [4 /*yield*/, call(getCustomersWithLocations, params)];
            case 2:
                res = _a.sent();
                customersList.push.apply(customersList, res.data);
                page++;
                _a.label = 3;
            case 3:
                if (res.currentPage < res.maxPages) return [3 /*break*/, 1];
                _a.label = 4;
            case 4:
                options = extractOptionsForMapFilter(customersList);
                return [4 /*yield*/, put(setMapFilterOptions({
                        options: options,
                        customersList: customersList,
                    }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 7];
            case 6:
                e_2 = _a.sent();
                console.error('Failed to get customers fro map');
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function filterCustomers(action) {
    var customers;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                customers = filterCustomersByOptions(action.payload);
                return [4 /*yield*/, put(setFilteredCustomers(customers))];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export default function homeSaga() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeEvery(Actions.home.AsyncActions.GET_CUSTOMERS_FOR_MAP, getCustomersForMap)];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeLatest(Actions.home.AsyncActions.GET_TOP_CUSTOMERS, getTopCustomers)];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(Actions.home.SyncActions.FILTER_CUSTOMERS, filterCustomers)];
            case 3: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 4:
                _c.sent();
                return [2 /*return*/];
        }
    });
}

import { routes, navBarRoutes } from '@configs/routes';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { ROUTES_IDS } from '@commonConstants/routesConstants';
import { isAllPagePermissionGranted, isSomePagePermissionGranted } from './navBarUtils';
var HOME = DASHBOARDS_SCHEMA.HOME, ACCOUNT_MANAGEMENT = DASHBOARDS_SCHEMA.ACCOUNT_MANAGEMENT, DASHBOARDS = DASHBOARDS_SCHEMA.DASHBOARDS, REPORTS = DASHBOARDS_SCHEMA.REPORTS, SUPPORT = DASHBOARDS_SCHEMA.SUPPORT, MKE_DASHBOARDS = DASHBOARDS_SCHEMA.MKE_DASHBOARDS;
var ALERTS = ROUTES_IDS.ALERTS, TRIAL_COSTS = ROUTES_IDS.TRIAL_COSTS, BOOTSTRAP = ROUTES_IDS.BOOTSTRAP, NEW_SIGNUPS = ROUTES_IDS.NEW_SIGNUPS, MANAGE_USERS = ROUTES_IDS.MANAGE_USERS, PERMISSION_GROUP = ROUTES_IDS.PERMISSION_GROUP, TRIAL_REPORT = ROUTES_IDS.TRIAL_REPORT, CONTAINER_CLOUD_USAGE = ROUTES_IDS.CONTAINER_CLOUD_USAGE, CLUSTER_HEALTH = ROUTES_IDS.CLUSTER_HEALTH, ACTIVE_INSTANCES = ROUTES_IDS.ACTIVE_INSTANCES, INSTALLATION_REPORT = ROUTES_IDS.INSTALLATION_REPORT, MCC_DASHBOARDS = ROUTES_IDS.MCC_DASHBOARDS, OPENSTACK_USAGE = ROUTES_IDS.OPENSTACK_USAGE, OPENSTACK_CLUSTERS_USAGE = ROUTES_IDS.OPENSTACK_CLUSTERS_USAGE, MANAGE_CUSTOMERS_CONTAINER = ROUTES_IDS.MANAGE_CUSTOMERS_CONTAINER, SEARCH_CLUSTERS = ROUTES_IDS.SEARCH_CLUSTERS;
export var navBar = [
    {
        name: 'Home',
        isVisible: function () { return isAllPagePermissionGranted([ROUTES_IDS.HOME]); },
        pseudo: HOME.PSEUDO_CC,
        path: routes.MAIN.path,
        disabled: false,
        children: null,
    },
    {
        name: 'Account Management',
        isVisible: function () { return isSomePagePermissionGranted([MANAGE_CUSTOMERS_CONTAINER, MANAGE_USERS, PERMISSION_GROUP]); },
        pseudo: ACCOUNT_MANAGEMENT.PSEUDO_CC,
        path: "".concat(routes.MAIN.path, "/account-management"),
        disabled: false,
        forAdmin: true,
        children: [
            {
                name: 'Manage Users',
                isVisible: function () { return isAllPagePermissionGranted([MANAGE_USERS]); },
                pseudo: ACCOUNT_MANAGEMENT.PAGES.MANAGE_USERS.PSEUDO_CC,
                disabled: false,
                path: navBarRoutes.MANAGE_USERS.path,
                children: null,
            },
            {
                name: 'Manage Customers',
                isVisible: function () { return isAllPagePermissionGranted([MANAGE_CUSTOMERS_CONTAINER]); },
                pseudo: ACCOUNT_MANAGEMENT.PAGES.MANAGE_CUSTOMERS.PSEUDO_CC,
                disabled: false,
                path: navBarRoutes.MANAGE_CUSTOMERS.path,
                children: null,
            },
            {
                name: 'Permission Groups',
                isVisible: function () { return isAllPagePermissionGranted([PERMISSION_GROUP]); },
                pseudo: ACCOUNT_MANAGEMENT.PAGES.PERMISSION_GROUPS.PSEUDO_CC,
                disabled: false,
                path: navBarRoutes.PERMISSION_GROUPS.path,
                children: null,
            },
        ],
    },
    {
        name: 'Dashboards',
        isVisible: function () { return isSomePagePermissionGranted([OPENSTACK_USAGE, CONTAINER_CLOUD_USAGE, BOOTSTRAP]); },
        pseudo: DASHBOARDS.PSEUDO_CC,
        path: "".concat(routes.MAIN.path, "/dashboards"),
        disabled: false,
        children: [
            {
                name: 'Container Cloud Usage',
                isVisible: function () { return isAllPagePermissionGranted([CONTAINER_CLOUD_USAGE]); },
                pseudo: DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE.PSEUDO_CC,
                disabled: false,
                path: navBarRoutes.CONTAINER_CLOUD_USAGE.path,
                children: null,
            },
            {
                name: 'OpenStack Usage',
                isVisible: function () { return isAllPagePermissionGranted([OPENSTACK_USAGE]); },
                pseudo: DASHBOARDS.PAGES.OPENSTACK_USAGE.PSEUDO_CC,
                disabled: false,
                path: navBarRoutes.OPENSTACK_OVERVIEW.path,
                children: null,
            },
            {
                name: 'OpenStack Clusters Usage',
                isVisible: function () { return isAllPagePermissionGranted([OPENSTACK_CLUSTERS_USAGE]); },
                pseudo: DASHBOARDS.PAGES.OPENSTACK_CLUSTERS_USAGE.PSEUDO_CC,
                disabled: false,
                path: navBarRoutes.OPENSTACK_CLUSTERS_USAGE.path,
                children: null,
            },
            {
                name: 'Bootstrap Dashboard',
                isVisible: function () { return isAllPagePermissionGranted([BOOTSTRAP]); },
                pseudo: DASHBOARDS.PAGES.BOOTSTRAP.PSEUDO_CC,
                disabled: false,
                path: navBarRoutes.BOOTSTRAP.path,
                children: null,
            },
        ],
    },
    {
        name: 'Reports',
        isVisible: function () {
            return isSomePagePermissionGranted([NEW_SIGNUPS, TRIAL_REPORT, ACTIVE_INSTANCES, INSTALLATION_REPORT]);
        },
        pseudo: REPORTS.PSEUDO_CC,
        path: "".concat(routes.MAIN.path, "/reports"),
        disabled: false,
        children: [
            {
                name: 'Installation Report',
                isVisible: function () { return isAllPagePermissionGranted([INSTALLATION_REPORT]); },
                pseudo: REPORTS.PAGES.INSTALLATION_REPORT.PSEUDO_CC,
                disabled: false,
                path: navBarRoutes.INSTALLATION_REPORT.path,
                children: null,
            },
            {
                name: 'New Sign-Ups',
                isVisible: function () { return isAllPagePermissionGranted([NEW_SIGNUPS]); },
                pseudo: REPORTS.PAGES.NEW_SIGN_UPS_REPORT.PSEUDO_CC,
                disabled: false,
                path: navBarRoutes.NEW_SIGN_UPS_REPORT.path,
                children: null,
            },
            {
                name: 'Active Instances',
                isVisible: function () { return isAllPagePermissionGranted([ACTIVE_INSTANCES]); },
                pseudo: REPORTS.PAGES.ACTIVE_INSTANCES.PSEUDO_CC,
                disabled: false,
                path: navBarRoutes.ACTIVE_INSTANCES.path,
                children: null,
            },
            {
                name: 'Trial Report',
                isVisible: function () { return isAllPagePermissionGranted([TRIAL_REPORT]); },
                pseudo: REPORTS.PAGES.TRIAL_REPORT.PSEUDO_CC,
                disabled: false,
                path: navBarRoutes.TRIAL_REPORT.path,
                children: null,
            },
            {
                name: 'Trial Statistic',
                isVisible: function () { return isAllPagePermissionGranted([TRIAL_COSTS]); },
                pseudo: REPORTS.PAGES.TRIAL_STATISTIC.PSEUDO_CC,
                disabled: false,
                path: navBarRoutes.COSTS.path,
                children: null,
            },
        ],
    },
    {
        name: 'Support',
        isVisible: function () { return isSomePagePermissionGranted([SEARCH_CLUSTERS, CLUSTER_HEALTH, ALERTS]); },
        pseudo: SUPPORT.PSEUDO_CC,
        path: "".concat(routes.MAIN.path, "/support"),
        disabled: false,
        children: [
            {
                name: 'Cluster Health',
                isVisible: function () { return isAllPagePermissionGranted([CLUSTER_HEALTH]); },
                pseudo: SUPPORT.PAGES.CLUSTER_HEALTH.PSEUDO_CC,
                disabled: false,
                path: navBarRoutes.CLUSTER_HEALTH.path,
                children: null,
            },
            {
                name: 'Alerts (Opscare)',
                isVisible: function () { return isAllPagePermissionGranted([ALERTS]); },
                pseudo: SUPPORT.PAGES.ALERTS.PSEUDO_CC,
                disabled: false,
                path: navBarRoutes.ALERTS.path,
                children: null,
            },
            {
                name: 'Search Clusters',
                isVisible: function () { return isAllPagePermissionGranted([SEARCH_CLUSTERS]); },
                pseudo: SUPPORT.PAGES.SEARCH_CLUSTERS.PSEUDO_CC,
                disabled: false,
                path: navBarRoutes.SEARCH_CLUSTERS.path,
                children: null,
            },
        ],
    },
    {
        name: 'Telemetry Dashboards',
        isVisible: function () { return isSomePagePermissionGranted([MCC_DASHBOARDS]); },
        pseudo: MKE_DASHBOARDS.PSEUDO_CC,
        path: "".concat(routes.MAIN.path, "/mke-dashboards"),
        disabled: false,
        forSFUser: true,
        children: [
            {
                name: 'Telemetry Dashboard',
                isVisible: function () { return isAllPagePermissionGranted([MCC_DASHBOARDS]); },
                pseudo: MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.PSEUDO_CC,
                disabled: false,
                path: navBarRoutes.TELEMETRY_DASHBOARD.path,
                children: null,
            },
        ],
    },
];

import React, { useEffect, useState } from 'react';
import isNil from 'lodash/isNil';
import { Aside } from '@commonComponents/aside/Aside';
import NonSignedInContent from '@commonComponents/non-signed-in-content/NonSignedInContent';
import AsideInfo from './aside-info/AsideInfo';
import SignInContent from './sign-in-content/SignInContent';
import AsideIllustration from './aside-illustration/AsideIllustration';
import TestSignIn from './test-sign-in/TestSignIn';
import { AuthContext } from '@app/OAuthProvider/OAuthProvider';
import Redirect from '@commonUtils/urlUtils/Redirect';
import { isTestEnvMode } from '@commonUtils/commonUtils';
export var SignIn = function () {
    var _a;
    var useAuth = React.useContext(AuthContext);
    var _b = useState(false), authenticated = _b[0], setAuthenticated = _b[1];
    var _c = useState(false), shouldDisplayTestSignIn = _c[0], setShouldDisplayTestSignIn = _c[1];
    useEffect(function () {
        setAuthenticated(useAuth.isSignedIn());
        if (authenticated) {
            Redirect.toMain();
        }
    }, [authenticated]);
    var TestSignInPage = !isNil((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_TEST_AUTH_TOKEN) ? React.createElement(TestSignIn, null) : React.createElement("div", null);
    var getContent = function () {
        return shouldDisplayTestSignIn ? (TestSignInPage) : (React.createElement(React.Fragment, null,
            React.createElement(Aside, { type: "large" },
                React.createElement(React.Fragment, null,
                    React.createElement(AsideInfo, null),
                    React.createElement(AsideIllustration, null))),
            React.createElement(NonSignedInContent, { isSmall: true },
                React.createElement(SignInContent, { onSignIn: function () { return setShouldDisplayTestSignIn(isTestEnvMode()); } }))));
    };
    return authenticated ? React.createElement("div", null) : getContent();
};

import React, { useState, useEffect } from 'react';
import { getBootstrapEvents } from '@redux/dispatchers/bootstrap';
import { changeCurrentPage } from '@redux/dispatchers/pagination';
import isEqual from 'lodash/isEqual';
import { usePrevious } from '@app/hooks';
import Table from '@commonComponents/table/Table';
import PaginationContainer from '@commonComponents/pagination/PaginationContainer';
import TableLoadingStates from '@commonComponents/table-loading-states/TableLoadingStates';
import { getBootstrapEventsParams } from '@components/bootstrap-events-table/BootstrapEventsTableUtils';
import { PAGINATION_NAMES } from '@configs/DASHBOARD_PSEUDOS';
import { COLUMNS, TABLE_TITLE, NO_BOOTSTRAP_EVENTS_FOUND } from './bootstrapEventsTableConstants';
var BOOTSTRAP = PAGINATION_NAMES.BOOTSTRAP;
var BootstrapEventsTable = function (_a) {
    var mode = _a.mode, multiFilter = _a.multiFilter, pagination = _a.pagination, bootstrap = _a.bootstrap, timezone = _a.timezone, crossPageFilter = _a.crossPageFilter;
    var _b = useState({
        sortBy: 'event_time',
        orderBy: 'desc',
    }), state = _b[0], setState = _b[1];
    var params = getBootstrapEventsParams({
        limit: pagination.limit,
        page: pagination.currentPage,
        sortBy: state.sortBy,
        orderBy: state.orderBy,
        timezone: timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    var prevParams = usePrevious(params);
    var callAPI = function () {
        getBootstrapEvents(params);
    };
    useEffect(function () {
        if (!isEqual(prevParams, params)) {
            callAPI();
        }
    }, [
        state,
        multiFilter.filter,
        pagination.currentPage,
        pagination.limit,
        mode,
        timezone,
        crossPageFilter.dateRange.calculatedDateRange,
    ]);
    var data = {
        columns: COLUMNS,
        rows: bootstrap.data,
    };
    var onPaginationChange = function (page) {
        changeCurrentPage({
            paginationName: BOOTSTRAP,
            currentPage: page,
        });
    };
    var onLimitChange = function (limit) {
        changeCurrentPage({
            limit: limit,
            paginationName: BOOTSTRAP,
            currentPage: 1,
        });
    };
    var setSortableState = function (nextState) {
        setState(nextState);
    };
    return (React.createElement("div", { className: "table bootstrap-events" },
        React.createElement(TableLoadingStates, { count: pagination.limit, loading: bootstrap.loading, data: (bootstrap === null || bootstrap === void 0 ? void 0 : bootstrap.data) || [], empty: {
                description: NO_BOOTSTRAP_EVENTS_FOUND,
            }, skeleton: {
                paddingBottom: 20,
                paddingTop: 20,
            } },
            React.createElement(React.Fragment, null,
                React.createElement(Table, { title: TABLE_TITLE, sortable: true, data: data, orderBy: state.orderBy, sortBy: state.sortBy, setSortableState: setSortableState, pagination: pagination, columnControl: true }),
                React.createElement(PaginationContainer, { withLimits: true, onLimitChange: onLimitChange, pagination: pagination, onChange: onPaginationChange })))));
};
export default BootstrapEventsTable;

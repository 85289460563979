import React from 'react';
import classNames from 'classnames';
import { BackwardIcon, ForwardIcon, variants } from '@mirantis/mds-icon';
import JobsModal from '@components/warning-banner/jobs-details/JobsDetails';
import ActiveUserContainer from './active-user/ActiveUserContainer';
import { StyledAsideNav, StyledAsideNavActiveUser, StyledVersion, StyledExpandController } from './styled';
var AsideNav = function (props) {
    var version = process.env.REACT_APP_VERSION;
    var CollapseExpandIcon = props.collapsed ? ForwardIcon : BackwardIcon;
    return (React.createElement(StyledAsideNav, { className: classNames('aside-nav', { collapsed: props.collapsed }) },
        props.children,
        React.createElement("div", null,
            version && (React.createElement(StyledVersion, { className: "aside-nav__version" },
                ' ',
                props.collapsed ? (React.createElement(React.Fragment, null, "V: ".concat(version),
                    " ",
                    React.createElement(JobsModal, null))) : (React.createElement(React.Fragment, null, "Version: ".concat(version),
                    " ",
                    React.createElement(JobsModal, null))))),
            React.createElement(StyledAsideNavActiveUser, { className: "aside-nav__active-user" },
                React.createElement(ActiveUserContainer, { collapsed: props.collapsed })),
            React.createElement(StyledExpandController, { onClick: function () { return props.toggleCollapsed(!props.collapsed); } },
                React.createElement(CollapseExpandIcon, { size: 20, variant: variants.INFO })))));
};
export default AsideNav;

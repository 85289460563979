import produce from 'immer';
import { STEPS } from '@components/new-sign-ups/constants';
import { resolvers } from './resolvers';
export var initialState = {
    isSignInStarted: false,
    step: 0,
    description: STEPS[0],
};
export var signIn = function (state, action) {
    if (state === void 0) { state = initialState; }
    if (action === void 0) { action = {}; }
    var type = action.type, payload = action.payload;
    if (!(type in resolvers)) {
        return state;
    }
    var resolver = function (draft) { return resolvers[type](draft, payload); };
    return produce(state, resolver);
};

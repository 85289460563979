import isNil from 'lodash/isNil';
export var getOptions = function (_a) {
    var setLoading = _a.setLoading, yFormatFunction = _a.yFormatFunction, tooltipsCallback = _a.tooltipsCallback, _b = _a.isLegend, isLegend = _b === void 0 ? false : _b, _c = _a.isDataLabels, isDataLabels = _c === void 0 ? false : _c, _d = _a.maxXAxisTicksLimit, maxXAxisTicksLimit = _d === void 0 ? 5 : _d, legendClick = _a.legendClick, _e = _a.stackedY, stackedY = _e === void 0 ? false : _e, yValue = _a.yValue, isChartDataEmpty = _a.isChartDataEmpty, isLoadingOut = _a.isLoadingOut;
    return ({
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            datalabels: {
                display: isDataLabels,
                color: 'black',
                anchor: 'end',
                offset: -20,
                align: 'start',
            },
            title: {
                display: false,
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',
                },
                limits: {
                    x: { min: 0 },
                    y: { min: 0 },
                },
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    mode: 'x',
                },
            },
            legend: {
                display: isLegend,
                labels: {
                    usePointStyle: true, // used only when pointStyle exists in Line.tsx
                },
                onClick: function (e, legendItem, legend) {
                    legendClick(legendItem);
                    var index = legendItem.datasetIndex;
                    var ci = legend.chart;
                    if (legendItem.hidden) {
                        ci.show(index);
                    }
                    else {
                        ci.hide(index);
                    }
                    legendItem.hidden = !legendItem.hidden;
                    ci.update();
                },
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: tooltipsCallback,
            },
        },
        animation: {
            onComplete: function (context) {
                if (context.initial) {
                    setLoading(true);
                }
                else if (!isChartDataEmpty && !isLoadingOut) {
                    setLoading(false);
                }
            },
        },
        scales: {
            y: {
                min: (yValue === null || yValue === void 0 ? void 0 : yValue.min) ? yValue.min : null,
                max: (yValue === null || yValue === void 0 ? void 0 : yValue.max) ? yValue.max : null,
                stacked: stackedY,
                beginAtZero: true,
                grace: '5%',
                ticks: {
                    callback: yFormatFunction,
                    maxTicksLimit: 5,
                },
            },
            x: {
                ticks: {
                    maxTicksLimit: maxXAxisTicksLimit,
                    maxRotation: 0,
                    minRotation: 0,
                },
            },
        },
        hover: {
            mode: 'nearest',
            intersect: true,
        },
    });
};
export var getFinalLeftLabel = function (dynamicLabelFormatter, datasets, activeDataSets, leftLabel) {
    return datasets.some(function (dataset, index) { return activeDataSets.includes(index) && dataset.length > 0; }) && dynamicLabelFormatter
        ? dynamicLabelFormatter(datasets
            .map(function (dataset, index) { return dataset.map(function (e) { return (activeDataSets.includes(index) ? e.y : null); }); })
            .flat()
            .filter(Boolean))
        : leftLabel;
};
export var isChartDataEmpty = function (datasets) { return datasets.every(function (dataset) { return dataset.every(function (item) { return isNil(item.y); }); }); };

import isNil from 'lodash/isNil';
export var getOptions = function (_a) {
    var setLoading = _a.setLoading, yFormatFunction = _a.yFormatFunction, tooltipsCallback = _a.tooltipsCallback, _b = _a.isLegend, isLegend = _b === void 0 ? false : _b, _c = _a.isDataLabels, isDataLabels = _c === void 0 ? false : _c, _d = _a.maxXAxisTicksLimit, maxXAxisTicksLimit = _d === void 0 ? 5 : _d, _e = _a.maxXRotation, maxXRotation = _e === void 0 ? 0 : _e, _f = _a.minXRotation, minXRotation = _f === void 0 ? 0 : _f, _g = _a.stackedY, stackedY = _g === void 0 ? false : _g, _h = _a.stackedX, stackedX = _h === void 0 ? false : _h, isChartDataEmpty = _a.isChartDataEmpty, isLoadingOut = _a.isLoadingOut;
    return ({
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            datalabels: {
                display: isDataLabels,
                color: 'black',
                anchor: 'end',
                offset: -20,
                align: 'start',
            },
            title: {
                display: false,
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',
                },
                limits: {
                    x: { min: 0 },
                    y: { min: 0 },
                },
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    pan: {
                        enabled: true,
                        mode: 'xy',
                    },
                    mode: 'xy',
                },
            },
            legend: {
                display: isLegend,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: tooltipsCallback,
            },
        },
        animation: {
            onComplete: function (context) {
                if (context.initial) {
                    setLoading(false);
                }
                else if (!isChartDataEmpty && !isLoadingOut) {
                    setLoading(false);
                }
            },
        },
        scales: {
            y: {
                stacked: stackedY,
                beginAtZero: true,
                grace: '5%',
                ticks: {
                    callback: yFormatFunction,
                    maxTicksLimit: 5,
                },
            },
            x: {
                stacked: stackedX,
                ticks: {
                    maxTicksLimit: maxXAxisTicksLimit,
                    maxRotation: maxXRotation,
                    minRotation: minXRotation,
                },
            },
        },
        hover: {
            mode: 'nearest',
            intersect: true,
        },
    });
};
export var isChartDataEmpty = function (datasets) { return datasets.every(function (dataset) { return dataset.every(function (item) { return isNil(item.y); }); }); };

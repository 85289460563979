import React from 'react';
import classNames from 'classnames';
import Redirect from '@commonUtils/urlUtils/Redirect';
import Dots from '@commonComponents/pagination/Dots';
import { StyledItem } from '@commonComponents/pagination/styled';
import { PAGINATION_DEFAULT_LIMITS } from './paginationConstants';
export var handleSetPaginationPreferencesFromUrlToStore = function (prevLimit, prevPage, onPageChange, onLimitChange, paginationPreferences) {
    var limit = paginationPreferences.limit, page = paginationPreferences.page;
    if (limit && limit !== prevLimit.toString()) {
        onLimitChange(limit);
    }
    if (page && page !== prevPage.toString()) {
        onPageChange(page);
    }
};
export var handleSetPaginationPreferencesToUrl = function (limit, currentPage, paginationQuery, paginationPreferences) {
    var limitFromUrl = paginationPreferences.limit, pageFromUrl = paginationPreferences.page;
    if (pageFromUrl === currentPage && limitFromUrl === limit) {
        return;
    }
    var options = [currentPage.toString(), limit.toString()];
    Redirect.toCurrentPageWithQueryParams(paginationQuery, options, '');
};
export var getCorrectNumberOfRaws = function (limit, maxPages, limits) {
    var allRowsCount = limit * maxPages;
    var finalLimits = limits ? limits : PAGINATION_DEFAULT_LIMITS;
    var result = [];
    var stop = false;
    finalLimits.forEach(function (currentLimit) {
        if (!stop) {
            if (allRowsCount / currentLimit > 1) {
                result.push(currentLimit);
            }
            else {
                result.push(currentLimit);
                stop = true;
            }
        }
    });
    return result;
};
export var handleChange = function (item, onChange, pagination, value) {
    if (onChange && !pagination.loading) {
        if (item === 'prev') {
            onChange(pagination.currentPage - 1);
        }
        if (item === 'next') {
            onChange(pagination.currentPage + 1);
        }
        if (item === 'item') {
            onChange(value);
        }
    }
};
var renderDefault = function (_a) {
    var onChange = _a.onChange, pagination = _a.pagination;
    var maxPages = pagination.maxPages, currentPage = pagination.currentPage;
    var items = [];
    var _loop_1 = function (index) {
        var className = classNames({
            pagination__item: true,
            active: currentPage === index,
        });
        items.push(React.createElement(StyledItem, { key: index, "data-testid": "page_".concat(index), className: className, onClick: function () { return handleChange('item', onChange, pagination, index); } }, index));
    };
    for (var index = 1; index <= maxPages; index++) {
        _loop_1(index);
    }
    return items;
};
var renderDots = function (_a) {
    var pagination = _a.pagination, onChange = _a.onChange;
    var maxPages = pagination.maxPages, currentPage = pagination.currentPage;
    var items = [];
    var k = maxPages > 8 ? 3 : 2;
    var push = function (index) {
        var className = classNames({
            pagination__item: true,
            active: currentPage === index,
        });
        items.push(React.createElement(StyledItem, { key: index, className: className, onClick: function () { return handleChange('item', onChange, pagination, index); } }, index));
    };
    var pushDot = function (key) {
        items.push(React.createElement(Dots, { key: key, maxPages: maxPages, onChange: onChange }));
    };
    for (var index = 1; index <= maxPages; index++) {
        if (index === 1) {
            push(index);
            if (currentPage - index > k) {
                pushDot('start');
            }
            continue;
        }
        if (index === maxPages) {
            if (maxPages - currentPage > k) {
                pushDot('end');
            }
            push(index);
            continue;
        }
        if (currentPage - index < k && index - currentPage < k) {
            push(index);
        }
    }
    return items;
};
export var renderItems = function (props) { return (props.pagination.maxPages > 5 ? renderDots(props) : renderDefault(props)); };

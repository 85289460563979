import * as Actions from '@redux/actions';
export var getCustomerTypes = function () { return ({
    type: Actions.mode.AsyncActions.GET_CUSTOMER_TYPES,
}); };
export var setCustomerTypes = function (payload) { return ({
    type: Actions.mode.SyncActions.SET_CUSTOMER_TYPES,
    payload: payload,
}); };
export var setSelectedOptions = function (payload) { return ({
    type: Actions.mode.SyncActions.SET_SELECTED_OPTIONS,
    payload: payload,
}); };
export var dropLastSyncDate = function () { return ({
    type: Actions.mode.SyncActions.DROP_LAST_SYNC_DATE,
}); };

export var CHART_DASHBOARDS = {
    ALERTS: 'alerts',
};
export var ALERTS_CHARTS_KEYS = {
    ALERTS_BY_TIME: 'alerts_by_time',
    ALERTS_BY_SERVICE: 'alerts_by_service',
};
export var MCC_CHARTS = [
    {
        key: ALERTS_CHARTS_KEYS.ALERTS_BY_TIME,
        chartType: 'time',
    },
    {
        key: ALERTS_CHARTS_KEYS.ALERTS_BY_SERVICE,
        chartType: 'service',
    },
];

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import classNames from 'classnames';
import styled from '@emotion/styled';
export var StyledDescription = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    max-height: 0;\n    overflow: hidden;\n    transition: all 0.2s;\n\n    &.expanded {\n        max-height: 500px;\n    }\n\n    .left {\n        padding-right: 10px;\n    }\n"], ["\n    display: flex;\n    max-height: 0;\n    overflow: hidden;\n    transition: all 0.2s;\n\n    &.expanded {\n        max-height: 500px;\n    }\n\n    .left {\n        padding-right: 10px;\n    }\n"])));
var BannerDescription = function (_a) {
    var detailInfo = _a.detailInfo, expanded = _a.expanded;
    return (React.createElement(StyledDescription, { className: classNames('description', { expanded: expanded }) },
        React.createElement("div", { className: "left" },
            detailInfo.method ? (React.createElement("div", null,
                React.createElement("b", null, "Method: "),
                detailInfo.method)) : (''),
            detailInfo.status ? (React.createElement("div", null,
                React.createElement("b", null, "Status: "),
                detailInfo.status)) : (''),
            detailInfo.statusText ? (React.createElement("div", null,
                React.createElement("b", null, "Status Text: "),
                detailInfo.statusText)) : ('')),
        React.createElement("div", { className: "right" },
            detailInfo.url ? (React.createElement("div", null,
                React.createElement("b", null, "Url: "),
                detailInfo.url)) : (''),
            detailInfo.message ? (React.createElement("div", null,
                React.createElement("b", null, "Message: "),
                detailInfo.message)) : (''))));
};
export default BannerDescription;
var templateObject_1;

import isNil from 'lodash/isNil';
import React, { useRef } from 'react';
//Component that shows and hides child ReactNode depending on props
var ComponentDropdown = function (_a) {
    var children = _a.children, show = _a.show, setShow = _a.setShow, _b = _a.relatedDropdownRefs, relatedDropdownRefs = _b === void 0 ? [] : _b, _c = _a.shouldHandleClickRef, shouldHandleClickRef = _c === void 0 ? { current: true } : _c;
    var ref = useRef(null);
    var handleClickOnDocument = function (evt) {
        if ((shouldHandleClickRef === null || shouldHandleClickRef === void 0 ? void 0 : shouldHandleClickRef.current) && ref && ref.current) {
            if (!ref.current.contains(evt.target) &&
                !relatedDropdownRefs
                    .filter(function (_a) {
                    var current = _a.current;
                    return !isNil(current);
                })
                    .find(function (_a) {
                    var current = _a.current;
                    return current.contains(evt.target);
                })) {
                setShow(false);
            }
        }
    };
    React.useEffect(function () {
        window.addEventListener('mousedown', handleClickOnDocument);
        return function () {
            window.removeEventListener('mousedown', handleClickOnDocument);
        };
    }, []);
    return show ? React.createElement("div", { ref: ref }, children) : null;
};
export default ComponentDropdown;

import * as Actions from '@redux/actions';
export var getClusterHealth = function (payload) { return ({
    type: Actions.clusterHealth.AsyncActions.GET_CLUSTER_HEALTH,
    payload: payload,
}); };
export var setClusterHealth = function (payload) { return ({
    type: Actions.clusterHealth.SyncActions.SET_CLUSTER_HEALTH,
    payload: payload,
}); };
export var patchAnnotation = function (payload) { return ({
    type: Actions.clusterHealth.AsyncActions.PATCH_ANNOTATION,
    payload: payload,
}); };

import ReactGA from 'react-ga';
import { TRACKING_ID } from '@configs/google-analytics';
export var GAInit = function () {
    ReactGA.initialize(TRACKING_ID);
};
export var GAPageView = function () {
    ReactGA.pageview(window.location.pathname + window.location.search);
};
export var GAEvent = function (category, action, label) {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
};
export var EventUserAgent = function () {
    GAEvent('User', 'userAgent', navigator.userAgent);
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { Button } from '@mirantis/mds-button';
import { AuthContext } from '@app/OAuthProvider/OAuthProvider';
import SignInProgressBar from '@app/pages/sign-in/sign-in-progress-bar/SignInProgressBar';
import { MQ } from '@emotionConstants';
import { Auth0Connections } from '@commonConstants/auth0Connections';
import { isTestEnvMode } from '@commonUtils/commonUtils';
import { TITLES, BUTTON_LABELS } from './signInContextConstants';
var mapState = function (store) { return ({
    signIn: store.signIn,
}); };
var connector = connect(mapState);
var SignInContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    max-width: 550px;\n    width: 100%;\n    ", " {\n        width: calc(100% - 50px);\n        margin: 0 25px 50px;\n    }\n    ", " {\n        margin: 0 50px 50px;\n    }\n\n    .sign-in__buttons {\n        display: flex;\n\n        & .sign-in__btn:first-of-type {\n            margin-right: 15px;\n        }\n    }\n"], ["\n    max-width: 550px;\n    width: 100%;\n    ", " {\n        width: calc(100% - 50px);\n        margin: 0 25px 50px;\n    }\n    ", " {\n        margin: 0 50px 50px;\n    }\n\n    .sign-in__buttons {\n        display: flex;\n\n        & .sign-in__btn:first-of-type {\n            margin-right: 15px;\n        }\n    }\n"])), MQ['md'], MQ['lg']);
var SignInTitle = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-bottom: 25px;\n    white-space: pre-wrap;\n"], ["\n    margin-bottom: 25px;\n    white-space: pre-wrap;\n"])));
var SignInContent = function (_a) {
    var signIn = _a.signIn, onSignIn = _a.onSignIn;
    var login = useContext(AuthContext).login;
    var GOOGLE = Auth0Connections.GOOGLE, SF = Auth0Connections.SF;
    var title = signIn.isSignInStarted ? TITLES.SIGN_IN_STARTED : TITLES.INITIAL;
    var handleLogin = function (key) {
        if (isTestEnvMode()) {
            onSignIn(key);
        }
        else {
            login(key);
        }
    };
    return (React.createElement(SignInContainer, { className: "sign-in" },
        React.createElement(SignInTitle, { className: "sign-in__title" }, title),
        signIn.isSignInStarted ? (React.createElement(SignInProgressBar, { signIn: signIn })) : (React.createElement("div", { className: "sign-in__buttons" },
            React.createElement(Button, { className: "sign-in__btn", label: BUTTON_LABELS.MCP, onClick: function () { return handleLogin(SF); } }),
            React.createElement(Button, { className: "sign-in__btn", label: BUTTON_LABELS.GOOGLE, onClick: function () { return handleLogin(GOOGLE); } })))));
};
export default connector(SignInContent);
var templateObject_1, templateObject_2;

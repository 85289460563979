var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import isEmpty from 'lodash/isEmpty';
import { DATE_RANGES, STATIC_REPORTS_DATE_RANGES } from '@utils/date/dateConstants';
import { STANDARDS } from './queryParamsValidationConstants';
import getDefaultDateRangeSelected from './dateRangeDefaultValue';
import getDefaultGranularitySelected from './granularityDefaultValue';
export var getValidatedParams = function (params, standard, defaultParams, customValidateCallback) {
    if (defaultParams === void 0) { defaultParams = null; }
    if (customValidateCallback === void 0) { customValidateCallback = null; }
    var validatedParams = params.filter(function (param) { return standard.includes(param); });
    if (customValidateCallback) {
        validatedParams = customValidateCallback(validatedParams);
    }
    return isEmpty(validatedParams) && defaultParams ? defaultParams : validatedParams;
};
export var validateTelemetryAlertsFilter = function (value) {
    var type = value[0], status = value[1];
    var validatedType = getValidatedParams([type], STANDARDS.TELEMETRY_ALERTS_FILTER_TYPE.STANDARD, STANDARDS.TELEMETRY_ALERTS_FILTER_TYPE.DEFAULT);
    var validatedStatus = getValidatedParams([status], STANDARDS.TELEMETRY_ALERTS_FILTER_STATUS.STANDARD, STANDARDS.TELEMETRY_ALERTS_FILTER_STATUS.DEFAULT);
    return [validatedType, validatedStatus].join(',');
};
export var validateSourceType = function (value) { return getValidatedParams(value, STANDARDS.SOURCE_TYPE.STANDARD); };
export var validateStatus = function (value) { return getValidatedParams(value, STANDARDS.STATUS.STANDARD); };
export var validateAlertsStatus = function (value) { return getValidatedParams(value, STANDARDS.ALERT_STATUS.STANDARD); };
export var validateAlertPriority = function (value) { return getValidatedParams(value, STANDARDS.ALERT_PRIORITY.STANDARD); };
export var validateManageClusterList = function (value) {
    return getValidatedParams(value, STANDARDS.TELEMETRY_MANAGE_CLUSTER_LIST.STANDARD, STANDARDS.TELEMETRY_MANAGE_CLUSTER_LIST.DEFAULT);
};
export var validateCustomerType = function (value) {
    return getValidatedParams(value, STANDARDS.CUSTOMER_TYPE.STANDARD, STANDARDS.CUSTOMER_TYPE.DEFAULT);
};
export var validateHasMkeAlerts = function (value) {
    return STANDARDS.HAS_ACTIVE_MKE_ALERTS.STANDARD.includes(value.toString())
        ? value
        : STANDARDS.HAS_ACTIVE_MKE_ALERTS.DEFAULT;
};
export var validateTimezone = function (value) {
    return getValidatedParams(value, STANDARDS.TIMEZONE.STANDARD, STANDARDS.TIMEZONE.DEFAULT);
};
export var validateHasActiveMkeLicense = function (value) {
    return STANDARDS.ACTIVE_MKE_LICENCE.STANDARD.includes(value.toString()) ? value : STANDARDS.ACTIVE_MKE_LICENCE.DEFAULT;
};
export var validateManageCustomersActiveTab = function (value) {
    return getValidatedParams(value, STANDARDS.MANAGE_CUSTOMERS_ACTIVE_TAB.STANDARD, STANDARDS.MANAGE_CUSTOMERS_ACTIVE_TAB.DEFAULT);
};
export var validatePagination = function (value) {
    var page = value[0], rows = value[1];
    var validatedRows = getValidatedParams([rows], STANDARDS.PAGINATION.STANDARD.ROWS, STANDARDS.PAGINATION.DEFAULT.ROWS);
    var validatedPage = STANDARDS.PAGINATION.STANDARD.PAGE.test(page) ? page : STANDARDS.PAGINATION.DEFAULT.PAGE;
    return "".concat(validatedPage, ",").concat(validatedRows);
};
export var validateGranularity = function (value) {
    var defaultParams = getDefaultGranularitySelected();
    var granularityValidateCallback = function (validatedValue) {
        return validatedValue.length === 2 ? validatedValue : defaultParams;
    };
    return getValidatedParams(value, STANDARDS.GRANULARITY_STANDARD.STANDARD, defaultParams, granularityValidateCallback);
};
export var validateDateRange = function (value) {
    var defaultValue = getDefaultDateRangeSelected();
    var allDateRanges = __assign(__assign({}, STATIC_REPORTS_DATE_RANGES), DATE_RANGES);
    var isValid = value.length === 3 &&
        Object.values(allDateRanges).includes(value[0]) &&
        STANDARDS.DATE_RANGE.STANDARD.test(value[1]) &&
        STANDARDS.DATE_RANGE.STANDARD.test(value[2]);
    return isValid ? value : defaultValue;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as Actions from '@redux/actions/permissions';
import { getNormalizedUserKey } from '@root/app/api/permissions-api/permissionsApiUtils';
var r = {};
r[Actions.SyncActions.SET_PERMISSIONS_ALLOWED_PAGES_LOADING] = function (draft, payload) {
    draft.allowedPagesTags.loading = payload;
    draft.allowedPagesTags.dirty = true;
};
r[Actions.SyncActions.SET_PERMISSIONS_ALLOWED_PAGES_TAGS] = function (draft, payload) {
    draft.allowedPagesTags.data = payload;
    draft.allowedPagesTags.loading = false;
    draft.allowedPagesTags.dirty = true;
};
r[Actions.AsyncActions.FETCH_PERMISSIONS_ALLOWED_PAGES_IDS] = function (draft) {
    draft.allowedPagesTags.loading = true;
};
r[Actions.SyncActions.SET_ACTIVE_GROUP_ASSIGNED_USERS] = function (draft, payload) {
    draft.activeGroupAssignedUsers = payload;
    payload.forEach(function (user) { return (draft.allUsersStatic[getNormalizedUserKey(user.id)] = user); });
};
r[Actions.SyncActions.SET_ACTIVE_PERMISSIONS_GROUP_ID] = function (draft, payload) {
    draft.activeGroupId = payload;
};
r[Actions.SyncActions.SET_PERMISSIONS_GROUP] = function (draft, payload) {
    draft.permissionGroup.name = payload.name;
    draft.permissionGroup.dashboards = payload.dashboards;
};
r[Actions.SyncActions.SET_PERMISSION_GROUPS] = function (draft, payload) {
    draft.allPermissionGroups = payload;
    draft.allPermissionGroups.loading = false;
};
r[Actions.AsyncActions.FETCH_PERMISSION_GROUPS] = function (draft) {
    draft.allPermissionGroups.loading = true;
};
r[Actions.SyncActions.RESET_PERMISSIONS_ALL_USERS] = function (draft) {
    draft.allUsers.data = {};
    draft.allUsers.maxPages = 0;
    draft.allUsers.currentPage = 1;
};
r[Actions.AsyncActions.FETCH_PERMISSION_ALL_USERS] = function (draft) {
    draft.allUsers.loading = true;
};
r[Actions.SyncActions.SET_PERMISSIONS_ALL_USERS] = function (draft, payload) {
    draft.allUsers.data = __assign(__assign({}, draft.allUsers.data), payload.data);
    draft.allUsers.maxPages = payload.maxPages;
    draft.allUsers.currentPage = payload.currentPage;
    draft.allUsers.loading = false;
    draft.allUsersStatic = __assign(__assign({}, draft.allUsersStatic), payload.data);
};
export { r as resolvers };

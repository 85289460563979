var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { AVAILABILITY_LABELS, TELEMETRY_USAGE_FIELDS, OPENSTACK_USAGE_FIELDS, } from '@commonConstants/availabilitychartsConttants';
import { isMOSCluster } from '../../../../commonTelemetryDashboardUtils';
import { getFormattedData, getDates, getNormalizedData } from './pdfGenerationUtils';
var AvailabilityPdfPage = function (_a) {
    var cluster = _a.cluster;
    var _b = useSelector(function (store) {
        var _a, _b, _c, _d, _e, _f;
        return ({
            mccData: getFormattedData(get((_c = (_b = (_a = store.charts) === null || _a === void 0 ? void 0 : _a.mccCluster) === null || _b === void 0 ? void 0 : _b.mccClusterCharts) === null || _c === void 0 ? void 0 : _c.data, "".concat(cluster.clusterId), []), 'metric_time'),
            availabilityData: getFormattedData(get((_f = (_e = (_d = store.charts) === null || _d === void 0 ? void 0 : _d.mccClusterAvailabilityCharts) === null || _e === void 0 ? void 0 : _e.availabilityCharts) === null || _f === void 0 ? void 0 : _f.data, "".concat(cluster.clusterId), []), 'time'),
        });
    }), mccData = _b.mccData, availabilityData = _b.availabilityData;
    var finalFieldsKeys = isMOSCluster(cluster)
        ? __spreadArray(__spreadArray([], TELEMETRY_USAGE_FIELDS, true), OPENSTACK_USAGE_FIELDS, true) : TELEMETRY_USAGE_FIELDS;
    var dates = getDates(mccData, availabilityData);
    var normalizedData = getNormalizedData(dates, mccData, availabilityData, finalFieldsKeys);
    return isEmpty(normalizedData) ? null : (React.createElement(React.Fragment, null,
        React.createElement("table", { id: "availability-table-".concat(cluster.clusterId) },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Date"),
                    finalFieldsKeys.map(function (valueKey) {
                        return normalizedData[0][valueKey] ? (React.createElement("th", { key: 'head' + valueKey }, AVAILABILITY_LABELS[valueKey])) : ('');
                    }))),
            React.createElement("tbody", null, normalizedData.map(function (availability) { return (React.createElement("tr", { key: 'tr' + availability.date },
                React.createElement(React.Fragment, null,
                    React.createElement("td", null, availability.date),
                    finalFieldsKeys.map(function (valueKey) {
                        return availability[valueKey] ? (React.createElement("td", { key: 'td' + valueKey }, availability[valueKey])) : ('');
                    })))); })))));
};
export default AvailabilityPdfPage;

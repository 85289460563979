import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { signIn } from './sign-in/reducer';
import { openstackClusterUsage } from './openstack-cluster-usage/reducer';
import { userInfo } from './user-info/reducer';
import { filtration } from './filtration/reducer';
import { alerts } from './alerts/reducer';
import { management } from './management/reducer';
import { clusters } from './clusters-search/reducer';
import { home } from './home/reducer';
import { clusterHealth } from './cluster-health/reducer';
import { pagination } from './pagination/reducer';
import { mode } from './mode/reducer';
import { multiFilter } from './multiFilter/reducer';
import { bootstrap } from './bootstrap/reducer';
import { crossPageFilter } from './crosspage-filter/reducer';
import { bootstrapInstallations } from './bootstrap-installations/reducer';
import { newSignUps } from './new-sign-ups/reducer';
import { activeInstances } from './activeInstances/reducer';
import { trialReport } from './trial-report/reducer';
import { telemetry } from './telemetry/reducer';
import requestsTracking from './request-tracking/requestTrackingReducer';
import { errorHandling } from './error-handling/errorHandlingReducer';
import { permissions } from './permissions/reducer';
import { charts } from './charts/reducer';
import { warningBanner } from './warning-banner/reducer';
export var rootReducer = combineReducers({
    home: home,
    mode: mode,
    signIn: signIn,
    charts: charts,
    alerts: alerts,
    userInfo: userInfo,
    clusters: clusters,
    bootstrap: bootstrap,
    telemetry: telemetry,
    management: management,
    filtration: filtration,
    pagination: pagination,
    newSignUps: newSignUps,
    multiFilter: multiFilter,
    trialReport: trialReport,
    permissions: permissions,
    warningBanner: warningBanner,
    clusterHealth: clusterHealth,
    errorHandling: errorHandling,
    crossPageFilter: crossPageFilter,
    activeInstances: activeInstances,
    requestsTracking: requestsTracking,
    form: formReducer,
    bootstrapInstallations: bootstrapInstallations,
    openstackClusterUsage: openstackClusterUsage,
});

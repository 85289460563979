import React from 'react';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import ActiveInstancesChart from './activeInstancesCharts/ActiveInstancesChart';
import ActiveInstanceTable from './ActiveInstanceTable';
import ActiveInstanceStatsBanner from '@components/activeInstances/ActiveInstanceStatsBanner';
import CrossPageFilterContainer from '@app/common/react/components/cross-page-filter/CrossPageFilterContainer';
import CustomerTypesFilterContainer from '@commonComponents/customer-type-filter/CustomerTypesFilterContainer';
var ActiveInstance = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", null, "Active Instances"),
        React.createElement(CustomerTypesFilterContainer, null),
        React.createElement(ActiveInstanceStatsBanner, null),
        React.createElement(ActiveInstancesChart, null),
        React.createElement(CrossPageFilterContainer, { dashboardName: DASHBOARDS_SCHEMA.REPORTS.PAGES.ACTIVE_INSTANCES.PSEUDO_CC, history: props.history, isGranularity: true }),
        React.createElement(ActiveInstanceTable, null)));
};
export default ActiveInstance;

import axios from 'axios';
import axiosRetry from 'axios-retry';
import { Localstorage } from '@utils/localstorage/Localstorage';
import { BASE_URL as base_url } from '@configs/api';
import { interceptRequests } from '@api/axios-instance/axiosInstanceInterceptors';
export var getAxiosInstance = function () {
    var token = Localstorage.get('token');
    var instance = axios.create({
        baseURL: base_url,
        responseType: 'json',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: "Bearer ".concat(token),
        },
    });
    axiosRetry(instance, { retries: 5, retryDelay: axiosRetry.exponentialDelay });
    interceptRequests(instance);
    return instance;
};

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import without from 'lodash/without';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { message } from 'antd';
import { resetPermissionsAllUsers, fetchPermissionsAllUsers, fetchPermissionGroupAssignedUsers, } from '@redux/dispatchers/permissions';
import { usePrevious, useDebouncedEffect } from '@app/hooks';
import AssignUsersToPermissionGroupView from './AssignUsersToPermissionGroupView';
import { getFetchUsersParams } from './assignUsersToPermissionGroupUtils';
import { ASSIGNED_USER_EXCLUDED_NOTIFICATION_TEXT } from './assignUsersToPermissionGroupConstants';
var AssignUsersToPermissionGroup = function (_a) {
    var users = _a.users, groupId = _a.groupId, groupName = _a.groupName, allUsersStatic = _a.allUsersStatic, allUsersMaxPages = _a.allUsersMaxPages, assignedUsersIds = _a.assignedUsersIds, setAssignedUsersIds = _a.setAssignedUsersIds, assignedUsersSearchTerm = _a.assignedUsersSearchTerm, staticAssignedUsersIds = _a.staticAssignedUsersIds, setStaticAssignedUsersIds = _a.setStaticAssignedUsersIds, setAssignedUsersSearchTerm = _a.setAssignedUsersSearchTerm;
    var _b = useState(''), unassignedUsersSearchTerm = _b[0], setUnassignedUsersSearchTerm = _b[1];
    var _c = useState([]), unassignedTemporarySelectedUsersIds = _c[0], setUnassignedTemporarySelectedUsersIds = _c[1];
    var _d = useState([]), assignedTemporarySelectedUsersIds = _d[0], setAssignedTemporarySelectedUsersIds = _d[1];
    var _e = useState(false), isUserAssignedDesc = _e[0], toggleIsUserAssignedDesc = _e[1];
    var _f = useState(false), isUserUnassignedDesc = _f[0], toggleIsUserUnassignedDesc = _f[1];
    var prevAsignedUserIds = usePrevious(assignedUsersIds) || assignedUsersIds;
    var activeUserId = useSelector(function (store) { var _a, _b; return (_b = (_a = store.userInfo) === null || _a === void 0 ? void 0 : _a.activeUser) === null || _b === void 0 ? void 0 : _b.id; });
    useEffect(function () {
        if (prevAsignedUserIds.includes(activeUserId) && !assignedUsersIds.includes(activeUserId)) {
            message.warning(ASSIGNED_USER_EXCLUDED_NOTIFICATION_TEXT);
        }
    }, [assignedUsersIds]);
    useDebouncedEffect(function () {
        if (groupId) {
            fetchPermissionGroupAssignedUsers(getFetchUsersParams({ groupId: groupId, isDesc: isUserAssignedDesc, limit: Number.MAX_SAFE_INTEGER }));
        }
    }, [groupId, isUserAssignedDesc], 500);
    useDebouncedEffect(function () {
        resetPermissionsAllUsers();
        fetchPermissionsAllUsers(getFetchUsersParams({ groupId: groupId, searchTerm: unassignedUsersSearchTerm, isDesc: isUserUnassignedDesc }));
    }, [unassignedUsersSearchTerm, isUserUnassignedDesc], 500);
    var handleUnassignedUsersOnRequestPage = function (newPage) {
        fetchPermissionsAllUsers(getFetchUsersParams({
            groupId: groupId,
            searchTerm: unassignedUsersSearchTerm,
            isDesc: isUserUnassignedDesc,
            page: newPage + 1,
        }));
    };
    var handleUnassignedSelectUser = function (userId) {
        var newSelectedUsersIds = unassignedTemporarySelectedUsersIds.includes(userId)
            ? without(unassignedTemporarySelectedUsersIds, userId)
            : __spreadArray(__spreadArray([], unassignedTemporarySelectedUsersIds, true), [userId], false);
        setAssignedTemporarySelectedUsersIds([]);
        setUnassignedTemporarySelectedUsersIds(newSelectedUsersIds);
    };
    var handleAssignedSelectUser = function (userId) {
        var newSelectedUsersIds = assignedTemporarySelectedUsersIds.includes(userId)
            ? without(unassignedTemporarySelectedUsersIds, userId)
            : __spreadArray(__spreadArray([], assignedTemporarySelectedUsersIds, true), [userId], false);
        setUnassignedTemporarySelectedUsersIds([]);
        setAssignedTemporarySelectedUsersIds(newSelectedUsersIds);
    };
    var moveToAssignedUsersSection = function () {
        if (isEmpty(unassignedTemporarySelectedUsersIds)) {
            return;
        }
        setAssignedUsersIds(__spreadArray(__spreadArray([], assignedUsersIds, true), unassignedTemporarySelectedUsersIds, true));
        setUnassignedTemporarySelectedUsersIds([]);
    };
    var moveToUnAssignedUsersSection = function () {
        var newSelectedUsersIds = without.apply(void 0, __spreadArray([assignedUsersIds], assignedTemporarySelectedUsersIds, false));
        setAssignedUsersIds(newSelectedUsersIds);
        setAssignedTemporarySelectedUsersIds([]);
        setStaticAssignedUsersIds(newSelectedUsersIds);
    };
    var allUsers = Object.values(users).filter(function (_a) {
        var id = _a.id;
        return !staticAssignedUsersIds.includes(id);
    });
    return (React.createElement(AssignUsersToPermissionGroupView, { users: users, allUsers: allUsers, groupName: groupName, allUsersStatic: allUsersStatic, allUsersMaxPages: allUsersMaxPages, assignedUsersIds: assignedUsersIds, isUserAssignedDesc: isUserAssignedDesc, isUserUnassignedDesc: isUserUnassignedDesc, assignedUsersSearchTerm: assignedUsersSearchTerm, toggleIsUserAssignedDesc: toggleIsUserAssignedDesc, unassignedUsersSearchTerm: unassignedUsersSearchTerm, setAssignedUsersSearchTerm: setAssignedUsersSearchTerm, toggleIsUserUnassignedDesc: toggleIsUserUnassignedDesc, setUnassignedUsersSearchTerm: setUnassignedUsersSearchTerm, unassignedTemporarySelectedUsersIds: unassignedTemporarySelectedUsersIds, assignedTemporarySelectedUsersIds: assignedTemporarySelectedUsersIds, handleAssignedSelectUser: handleAssignedSelectUser, handleUnassignedSelectUser: handleUnassignedSelectUser, moveToAssignedUsersSection: moveToAssignedUsersSection, moveToUnAssignedUsersSection: moveToUnAssignedUsersSection, handleUnassignedUsersOnRequestPage: handleUnassignedUsersOnRequestPage }));
};
export default AssignUsersToPermissionGroup;

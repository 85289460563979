import { store } from '@redux/store';
import * as actionCreators from '@redux/action-creators';
export var getClusterHealth = function (payload) {
    var action = actionCreators.clusterHealth.getClusterHealth(payload);
    store.dispatch(action);
};
export var patchAnnotation = function (payload) {
    var action = actionCreators.clusterHealth.patchAnnotation(payload);
    store.dispatch(action);
};

import produce from 'immer';
import { resolvers } from './resolvers';
export var initialState = {
    customers: {
        loading: true,
        data: null,
    },
    customer: {
        loading: true,
        error: null,
        sfAccount: null,
        licenseKey: null,
        overview: null,
        mccOverview: null,
        mkeOverview: null,
        alerts: {
            loading: true,
            data: [],
        },
        recommendations: {
            loading: true,
            data: [],
        },
    },
    clusterSpecs: null,
};
export var telemetry = function (state, action) {
    if (state === void 0) { state = initialState; }
    if (action === void 0) { action = {}; }
    var type = action.type, payload = action.payload;
    if (!(type in resolvers)) {
        return state;
    }
    var resolver = function (draft) { return resolvers[type](draft, payload); };
    return produce(state, resolver);
};

import set from 'lodash/set';
import * as Actions from '@redux/actions';
var r = {};
r[Actions.charts.SyncActions.SET_CHARTS_DATA] = function (draft, payload) {
    set(draft[payload.dashboard], "".concat(payload.key, ".loading"), false);
    set(draft[payload.dashboard], "".concat(payload.key, ".data"), payload.data);
};
r[Actions.charts.SyncActions.SET_CHARTS_CANVAS_LOADED] = function (draft, payload) {
    set(draft[payload.dashboard], "loadedCanvas.".concat(payload.clusterId, ".").concat(payload.key), true);
};
r[Actions.charts.SyncActions.DROP_CHARTS_TO_DEFAULT] = function (draft, payload) {
    draft[payload] = {};
};
r[Actions.charts.AsyncActions.FETCH_CHARTS] = function (draft, payload) {
    payload.charts.forEach(function (_a) {
        var key = _a.key;
        set(draft[payload.dashboard], "".concat(key, ".loading"), true);
    });
};
export { r as resolvers };

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import uuid from 'react-uuid';
import { connect } from 'react-redux';
import classNames from 'classnames';
import clone from 'lodash/clone';
import size from 'lodash/size';
import { Table as AntdTable } from 'antd';
import { DASHBOARDS_SCHEMA, PAGINATION_NAMES } from '@configs/DASHBOARD_PSEUDOS';
import { useDebouncedEffect } from '@app/hooks';
import { changeCurrentPage } from '@actionCreators/pagination/pagination';
import { getActiveInstances } from '@actionCreators/activeInstances';
import Table from '@commonComponents/table/Table';
import ExpandableTableCell from '@commonComponents/expandable-table-cell/ExpandableTableCell';
import PaginationContainer from '@commonComponents/pagination/PaginationContainer';
import TableLoadingStates from '@commonComponents/table-loading-states/TableLoadingStates';
import { ACTIVE_INSTANCE_COLUMNS, NO_ACTIVE_INSTANCES_FOUND, ACTIVE_INSTANCE_COLUMNS_KEYS, ACTIVE_INSTANCE_EXPANDED_TALE_CELL_HEIGHT, ACTIVE_INSTANCE_DETAIL_COLUMNS, } from './activeInstanceConstants';
import { getDetailItem, getNormalizedData, reorderDetailItems, getDetailValue } from './activeInstanceTableUtils';
import StyledActiveInstanceTable from './activeInstanceTableStyled';
var MIN_HEIGHT = ACTIVE_INSTANCE_EXPANDED_TALE_CELL_HEIGHT.MIN_HEIGHT;
var mapState = function (store) { return ({
    mode: store.mode.selected,
    filter: store.crossPageFilter,
    timezone: store.userInfo.settings.initial.timezone,
    pagination: store.pagination.activeInstances,
    activeInstances: store.activeInstances,
}); };
var mapDispatch = {
    getActiveInstances: getActiveInstances,
    changeCurrentPage: changeCurrentPage,
};
var connector = connect(mapState, mapDispatch);
var ActiveInstanceTable = function (props) {
    var _a, _b;
    var mode = props.mode, filter = props.filter, timezone = props.timezone, activeInstances = props.activeInstances, pagination = props.pagination;
    var _c = useState({
        sortBy: ACTIVE_INSTANCE_COLUMNS_KEYS.NAME,
        orderBy: 'asc',
    }), state = _c[0], setState = _c[1];
    var callAPI = function () {
        var params = {
            customer_types: mode[0] === 'All' ? null : mode.toString(),
            timezone: timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
            page: pagination.currentPage,
            limit: pagination.limit,
            sortBy: state.sortBy,
            orderBy: state.orderBy,
        };
        props.getActiveInstances(params);
    };
    useDebouncedEffect(function () {
        if (filter.dashboardName !== DASHBOARDS_SCHEMA.REPORTS.PAGES.ACTIVE_INSTANCES.PSEUDO_CC) {
            return;
        }
        callAPI();
    }, [
        state,
        mode,
        timezone,
        filter.dateRange.calculatedDateRange,
        (_b = (_a = filter.granularity) === null || _a === void 0 ? void 0 : _a.selected) === null || _b === void 0 ? void 0 : _b.value,
        pagination.currentPage,
        pagination.limit,
    ]);
    var data = {
        columns: ACTIVE_INSTANCE_COLUMNS,
        rows: getNormalizedData((activeInstances === null || activeInstances === void 0 ? void 0 : activeInstances.data) || []),
    };
    var className = classNames({
        'instances-heartbeat-table': true,
        loading: activeInstances.loading,
    });
    var onPaginationChange = function (page) {
        props.changeCurrentPage({
            paginationName: PAGINATION_NAMES.ACTIVE_INSTANCES,
            currentPage: page,
        });
    };
    var onLimitChange = function (limit) {
        props.changeCurrentPage({
            paginationName: PAGINATION_NAMES.ACTIVE_INSTANCES,
            currentPage: 1,
            limit: limit,
        });
    };
    var getNormalizedValues = function (values) {
        return __spreadArray([], values, true).sort(function (obj1, obj2) {
            return reorderDetailItems(obj1, obj2, 'man');
        })
            .map(function (value) {
            return getDetailValue(value);
        });
    };
    var customizeValue = function (key, value) {
        if (key === ACTIVE_INSTANCE_COLUMNS_KEYS.DETAILS) {
            var normalizedValues = getNormalizedValues(value);
            return (React.createElement(ExpandableTableCell, { key: key, minHeight: MIN_HEIGHT, itemsLength: size(clone(value)) }, [
                React.createElement("div", { key: key, className: "details-wrapper" }, __spreadArray([], value, true).sort(function (obj1, obj2) {
                    return reorderDetailItems(obj1, obj2, 'man');
                })
                    .map(function (item) { return (React.createElement("div", { key: item.id, className: classNames('detail-item', { isClickable: true }) }, getDetailItem(item))); })),
                React.createElement("div", { key: key, className: "detail-popup-wrapper" },
                    React.createElement(AntdTable, { columns: ACTIVE_INSTANCE_DETAIL_COLUMNS, pagination: false, dataSource: normalizedValues, rowKey: function () { return uuid(); } })),
            ]));
        }
        return value;
    };
    return (React.createElement("div", { className: className },
        React.createElement(TableLoadingStates, { data: (activeInstances === null || activeInstances === void 0 ? void 0 : activeInstances.data) || [], count: pagination.limit, loading: activeInstances.loading, empty: {
                description: NO_ACTIVE_INSTANCES_FOUND,
            }, skeleton: {
                paddingBottom: 20,
                paddingTop: 20,
            } },
            React.createElement(React.Fragment, null,
                React.createElement(StyledActiveInstanceTable, null,
                    React.createElement(Table, { sortable: true, data: data, orderBy: state.orderBy, sortBy: state.sortBy, setSortableState: function (s) { return setState(s); }, customizeValue: customizeValue, pagination: pagination, columnControl: true })),
                React.createElement(PaginationContainer, { withLimits: true, onLimitChange: onLimitChange, pagination: pagination, onChange: onPaginationChange })))));
};
export default connector(ActiveInstanceTable);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import pull from 'lodash/pull';
import React from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import range from 'lodash/range';
import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';
import cloneDeep from 'lodash/cloneDeep';
import { SuccessIcon, variants } from '@mirantis/mds-icon';
import { getDataAttributes } from '@commonComponents/gropped-column-table/gropedTableUtils';
import Tooltip from '@commonComponents/tooltip/Tooltip';
import { DateUtils } from '@utils/date/DateUtils';
import { PROVIDER_ICONS_MAP } from './telemetryCustomerConstants';
export var getClustersLevelParents = function (clusters) {
    var managementLevel = [];
    var regionLevel = [];
    clusters.forEach(function (cluster) {
        var isManagementParent = cluster.clusterId === cluster.managementId && cluster.clusterId === cluster.regionId;
        var isRegionParent = cluster.clusterId !== cluster.managementId && cluster.clusterId === cluster.regionId;
        if (isManagementParent) {
            managementLevel.push(cluster);
        }
        if (isRegionParent) {
            regionLevel.push(cluster);
        }
    });
    return { managementLevel: managementLevel, regionLevel: regionLevel };
};
export var getNormalizedClusters = function (data) {
    var prevData = cloneDeep(data);
    var finalResult = [];
    var dependentClusterIds = [];
    var _a = getClustersLevelParents(data), managementLevel = _a.managementLevel, regionLevel = _a.regionLevel;
    // it compares by the links - do not use deepClone
    var clusters = without.apply(void 0, __spreadArray([data], __spreadArray(__spreadArray([], managementLevel, true), regionLevel, true), false));
    var regionLevelClusters = regionLevel.map(function (item) {
        var childClusters = clusters.filter(function (child) { return child.regionId === item.clusterId; });
        clusters = pull.apply(void 0, __spreadArray([clusters], childClusters, false));
        return __assign(__assign({}, item), { childClusters: childClusters });
    });
    var managementLevelClusters = managementLevel.map(function (item) { return (__assign(__assign({}, item), { childClusters: __spreadArray(__spreadArray([], clusters, true), regionLevelClusters, true).filter(function (child) { return child.managementId === item.clusterId; }) })); });
    managementLevelClusters.forEach(function (item) {
        finalResult.push(item);
        dependentClusterIds.push(item.clusterId);
        get(item, 'childClusters', []).forEach(function (managementChildCluster) {
            set(managementChildCluster, 'child', true);
            dependentClusterIds.push(managementChildCluster.clusterId);
            finalResult.push(managementChildCluster);
            get(managementChildCluster, 'childClusters', []).forEach(function (regionChildCluster) {
                set(managementChildCluster, 'child', true);
                dependentClusterIds.push(regionChildCluster.clusterId);
                finalResult.push(regionChildCluster);
            });
        });
    });
    var independentClusters = prevData.filter(function (item) { return !dependentClusterIds.includes(item.clusterId); });
    finalResult.push.apply(finalResult, independentClusters);
    return finalResult;
};
export var resolveHiddenParamValue = function (isManageClusterListPage, isSfUser) {
    return !isManageClusterListPage && isSfUser ? false : null;
};
var getPercentageColorClassname = function (value) {
    if (range(0, 80).includes(+value)) {
        return 'green';
    }
    if (range(80, 90).includes(+value)) {
        return 'yellow';
    }
    if (range(90, 101).includes(+value)) {
        return 'red';
    }
};
export var normalizers = {
    lastSyncDate: function (dateStr) { return DateUtils.getDateLocalStringAutoTimezoneHandler(dateStr); },
    clusterUpdating: function (value) {
        return value ? (React.createElement("div", { className: "is_updating" },
            React.createElement(SuccessIcon, { size: 16, variant: variants.SUCCESS, className: "is-updating", "data-testid": "is_updating" }))) : (React.createElement("div", { className: "not_updating" }));
    },
    mkeVersion: function (value) { return (value === 'null' ? '' : value); },
    msrVersion: function (value) { return (value === 'null' ? '' : value); },
    mcrVersion: function (value) { return (value === 'null' ? '' : value); },
    // eslint-disable-next-line react/display-name
    memory: function (value, cluster) { return (React.createElement(Tooltip, { disable: false, place: "top", message: "Node memory total in cluster" },
        React.createElement("div", __assign({}, getDataAttributes(cluster.clusterId, null)), "".concat(parseInt(value), " GB")))); },
    // eslint-disable-next-line react/display-name
    cpu: function (value, cluster) { return (React.createElement(Tooltip, { disable: false, place: "top", message: "Number of cores in cluster" },
        React.createElement("div", __assign({}, getDataAttributes(cluster.clusterId, null)), "".concat(value, " cores")))); },
    // eslint-disable-next-line react/display-name
    disk: function (value, cluster) { return (React.createElement(Tooltip, { disable: false, place: "top", message: "Persistent volume claims total in cluster" },
        React.createElement("div", __assign({}, getDataAttributes(cluster.clusterId, null)), "".concat(value, " GB")))); },
    mkeApi: function (value) { return "".concat(value, "%"); },
    mkeApiAvailability: function (value) { return "".concat(value, "%"); },
    k8sApiAvailability: function (value) { return "".concat(value, "%"); },
    provider: function (value, cluster, isCLear) {
        if (isCLear === void 0) { isCLear = false; }
        return isCLear ? value.toLowerCase() : PROVIDER_ICONS_MAP[value.toLowerCase()];
    },
    // eslint-disable-next-line react/display-name
    cpuPercentage: function (value, cluster) { return (React.createElement("div", null,
        React.createElement(Tooltip, { disable: false, place: "top", message: "Number of cores in cluster" },
            React.createElement("span", __assign({}, getDataAttributes(cluster.clusterId, null), { className: 'Number_of_cores' }), "".concat(cluster.cpuAbs, " cores "))),
        React.createElement(Tooltip, { disable: false, place: "top", message: "Core utilization in cluster" },
            React.createElement("span", __assign({}, getDataAttributes(cluster.clusterId, null), { className: "".concat(getPercentageColorClassname(value), " Core utilization") }), "(".concat(value, "%)"))))); },
    // eslint-disable-next-line react/display-name
    memoryPercentage: function (value, cluster) { return (React.createElement("div", null,
        React.createElement(Tooltip, { disable: false, place: "top", message: "Node memory total in cluster" },
            React.createElement("span", __assign({}, getDataAttributes(cluster.clusterId, null), { className: 'Node_memory_total' }), "".concat(cluster.memoryAbs, " GB "))),
        React.createElement(Tooltip, { disable: false, place: "top", message: "Memory utilization in cluster" },
            React.createElement("span", __assign({}, getDataAttributes(cluster.clusterId, null), { className: "".concat(getPercentageColorClassname(value), " Memory utilization") }), "(".concat(value, "%)"))))); },
};
export var getClusterLicense = function (clusters, clusterId) { return get(clusters[clusterId], 'license'); };
export var isMOSCluster = function (cluster) { return get(cluster, 'types', []).includes('MOS'); };
export var getToggleAllClustersDefaultChecked = function (visibleClustersIds, allClustersIds) {
    var isAllHidden = isEmpty(visibleClustersIds);
    var isAllVisible = !isAllHidden && allClustersIds.length === visibleClustersIds.length;
    if (isAllHidden)
        return false;
    if (isAllVisible)
        return true;
    return true;
};

import React from 'react';
import HomeStaticStatsBanner from '@commonComponents/static-stats-banner/HomeStaticStatsBanner';
import CustomerTypesFilterContainer from '@commonComponents/customer-type-filter/CustomerTypesFilterContainer';
// import LeafletMap from './leaflet-map/LeafletMap';
// import LandingMapFilter from './LandingMapFilter';
import TopCustomers from './top-customers/TopCustomers';
var Home = function () { return (React.createElement(React.Fragment, null,
    React.createElement("h2", null, "Home"),
    React.createElement(CustomerTypesFilterContainer, null),
    React.createElement(HomeStaticStatsBanner, null),
    React.createElement(TopCustomers, null))); };
export default Home;

import React from 'react';
import { connect } from 'react-redux';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { CROSS_PAGE_FILTER_CONFIG } from '@configs/crossPageFilterInitialState';
import { getMultiFilterPickers } from '@configs/multiFilterConfig';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import FilterContainer from '@commonComponents/filter/FilterContainer';
import CrossPageFilterContainer from '@commonComponents/cross-page-filter/CrossPageFilterContainer';
import { getFilterDateChangeConfig } from '@commonComponents/cross-page-filter/crossPageFilterUtils';
import { changeCrossPageFilterOption } from '@actionCreators/crossPageFilter';
import { DateUtils } from '@utils/date/DateUtils';
var DATE_RANGE_KEY = URL_QUERIES.COMMON.DATE_RANGE;
var PSEUDO_CC = DASHBOARDS_SCHEMA.SUPPORT.PAGES.ALERTS.PSEUDO_CC;
var mapState = function (store) { return ({
    mode: store.mode,
    multiFilter: store.multiFilter,
}); };
var mapDispatch = {
    changeCrossPageFilterOptionAction: changeCrossPageFilterOption,
};
var connector = connect(mapState, mapDispatch);
export var AlertsFilter = function (_a) {
    var mode = _a.mode, multiFilter = _a.multiFilter, changeCrossPageFilterOptionAction = _a.changeCrossPageFilterOptionAction;
    var selected = multiFilter.filter.selected;
    var params = {
        limit: 20,
        source: 'SF_ALERTS',
        customer_types: mode.selected[0] === 'All' ? null : mode.selected.toString(),
        inactive_hours_threshold: mode.lastSyncDate.selected.value,
    };
    if (!multiFilter.loading && multiFilter.name === PSEUDO_CC) {
        var extendedParams = {
            customer_ids: selected.customer.length > 0 ? selected.customer.map(function (o) { return o.id; }).toString() : null,
            cluster_ids: selected.cluster.length > 0 ? selected.cluster.map(function (o) { return o.id; }).toString() : null,
        };
        params = Object.assign(params, extendedParams);
    }
    var defaultSelected = CROSS_PAGE_FILTER_CONFIG[PSEUDO_CC].dateRange.selected;
    var resetAdditionalParams = function () {
        changeCrossPageFilterOptionAction(getFilterDateChangeConfig(DATE_RANGE_KEY, defaultSelected, DateUtils.calculateDateRangeByOption(defaultSelected)));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FilterContainer, { name: PSEUDO_CC, params: params, currentMode: mode, multiFilter: multiFilter, isOptionsHidden: true, resetAdditionalParams: resetAdditionalParams, pickers: getMultiFilterPickers(PSEUDO_CC) },
            React.createElement(CrossPageFilterContainer, { isEmbedded: true, dashboardName: PSEUDO_CC, className: "alerts-date-range-picker" }))));
};
export default connector(AlertsFilter);

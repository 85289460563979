import { FETCH_USERS_LIMIT } from './assignUsersToPermissionGroupConstants';
export var getFetchUsersParams = function (_a) {
    var groupId = _a.groupId, _b = _a.searchTerm, searchTerm = _b === void 0 ? '' : _b, isDesc = _a.isDesc, _c = _a.page, page = _c === void 0 ? undefined : _c, _d = _a.limit, limit = _d === void 0 ? FETCH_USERS_LIMIT : _d;
    return ({
        groupId: groupId,
        page: page,
        limit: searchTerm.length > 0 ? undefined : limit,
        searchTerm: searchTerm.length > 0 ? searchTerm : undefined,
        sortBy: 'full_name',
        orderBy: isDesc ? 'desc' : 'asc',
    });
};
export var shouldShowUser = function (assignedUsersSearchTerm, email, fullName) {
    return assignedUsersSearchTerm.length === 0 ||
        "".concat(email.toLowerCase()).concat(fullName.toLowerCase()).includes(assignedUsersSearchTerm.toLowerCase());
};

import React from 'react';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { HomeIcon, TeamIcon, DashboardIcon, ContainerIcon, MetricIcon } from '@mirantis/mds-icon';
var resolveIcon = function (pseudo) {
    switch (pseudo) {
        case DASHBOARDS_SCHEMA.HOME.PSEUDO_CC:
            // TODO: replace with the new icon when it is added to MDS (PRODX-37689)
            return HomeIcon;
        case DASHBOARDS_SCHEMA.ACCOUNT_MANAGEMENT.PSEUDO_CC:
            return TeamIcon;
        case DASHBOARDS_SCHEMA.DASHBOARDS.PSEUDO_CC:
            return DashboardIcon;
        case DASHBOARDS_SCHEMA.REPORTS.PSEUDO_CC:
            // TODO: replace with the new ReportsIcon when it is added to MDS (PRODX-37689)
            return ContainerIcon;
        case DASHBOARDS_SCHEMA.SUPPORT.PSEUDO_CC:
            // TODO: replace with the new SupportIcon when it is added to MDS (PRODX-37689)
            return null;
        case DASHBOARDS_SCHEMA.MKE_DASHBOARDS.PSEUDO_CC:
            // TODO: replace with the new TelemetryIcon when it is added to MDS (PRODX-37689)
            return MetricIcon;
        default:
            return null;
    }
};
var Raw = function (_a) {
    var needRenderIcon = _a.needRenderIcon, pseudo = _a.pseudo;
    if (needRenderIcon) {
        var Icon_1 = resolveIcon(pseudo);
        return React.createElement("div", { className: "nav__link__img" }, Icon_1 && React.createElement(Icon_1, { size: 20 }));
    }
    return null;
};
export var Icon = React.memo(Raw);

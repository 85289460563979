var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef } from 'react';
import classNames from 'classnames';
import { Input } from '@mirantis/mds-input';
import { fieldStates } from '@mirantis/mds-theme';
import { ExpandIcon } from '@mirantis/mds-icon';
import { DATE_RANGES } from '@utils/date/dateConstants';
import ComponentDropdown from '@root/app/common/react/components/componentDropdown/ComponentDropdown';
import DatePicker from '@commonComponents/datePicker/DatePicker';
import { StyledDateRange, StyledDateRangeDropdown } from '@commonComponents/cross-page-filter/StyledDateRange';
import { usePrevious } from '@app/hooks';
var getIsCustomMode = function (mode) { return mode === DATE_RANGES.CUSTOM_RANGE; };
var DateRange = function (_a) {
    var dateRange = _a.dateRange, onChange = _a.onChange, changeCustomDateRange = _a.changeCustomDateRange, isEmbedded = _a.isEmbedded;
    var ref = useRef(null);
    var _b = React.useState(false), show = _b[0], setShow = _b[1];
    var _c = React.useState(getIsCustomMode(dateRange.selected)), isCustomMode = _c[0], setIsCustomMode = _c[1];
    var prevShow = usePrevious(show);
    var handleClickOnOption = function (value) {
        if (getIsCustomMode(value)) {
            setIsCustomMode(true);
            changeCustomDateRange(dateRange.calculatedDateRange);
            return;
        }
        onChange(value);
        setShow(false);
        setIsCustomMode(false);
    };
    var DateRanges = function () {
        var options = dateRange.options.map(function (option) {
            var cls = classNames({
                date__range__option: true,
                active: option === dateRange.selected,
            });
            return (React.createElement("div", { key: option, onClick: function () { return handleClickOnOption(option); }, className: cls }, option));
        });
        var className = classNames({
            filter__select__child: true,
            dp_opened: isCustomMode,
        });
        return (React.createElement(StyledDateRangeDropdown, { className: className },
            React.createElement("div", { className: "filter__select__child__part" }, options),
            React.createElement(DatePicker, { show: isCustomMode, prevShow: prevShow, dateRange: dateRange, changeCustomDateRange: changeCustomDateRange })));
    };
    var handleClickOnFilter = function () {
        setShow(!show);
    };
    var selectedOption = dateRange.selected === 'Custom Range'
        ? "".concat(dateRange.calculatedDateRange.start.timeString, "  -  ").concat(dateRange.calculatedDateRange.end.timeString)
        : dateRange.selected;
    return (React.createElement(StyledDateRange, { className: "select", width: isEmbedded ? '100%' : 'calc((100% - -98px) / 6);' },
        React.createElement("div", { onClick: handleClickOnFilter, ref: ref },
            React.createElement(Input, { showHelp: false, label: "Date Range", value: selectedOption, state: fieldStates.READONLY, fieldAfter: {
                    Icon: {
                        Icon: function (props) { return React.createElement(ExpandIcon, __assign({}, props, { size: 11 })); },
                    },
                } })),
        React.createElement(ComponentDropdown, { show: show, setShow: setShow },
            React.createElement(DateRanges, null))));
};
export default DateRange;

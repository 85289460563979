import uudi from 'react-uuid';
import remove from 'lodash/remove';
import * as Actions from '@redux/actions';
import { getPageFromPathname } from '@commonUtils/commonUtils';
import { PAGES_UNDERSCORE_CAMEL_CASE_MAP } from '@configs/DASHBOARD_PSEUDOS';
var resolvers = {};
resolvers[Actions.errorHandling.SyncActions.SET_ERROR] = function (draft, payload) {
    var page = getPageFromPathname(window.location.pathname);
    var normalizedPage = PAGES_UNDERSCORE_CAMEL_CASE_MAP[page];
    var errorId = uudi();
    if (draft.pages[normalizedPage]) {
        draft.pages[normalizedPage].push({
            page: normalizedPage,
            id: errorId,
            errorObject: payload,
        });
    }
    draft.errors.push({ page: normalizedPage, id: errorId });
};
resolvers[Actions.errorHandling.SyncActions.CLEAR_ERROR] = function (draft, payload) {
    var page = draft.errors.find(function (_a) {
        var id = _a.id;
        return id === payload;
    }).page;
    remove(draft.pages[page], { id: payload });
    remove(draft.errors, { id: payload });
};
export default resolvers;

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import without from 'lodash/without';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { signOut } from '@root/index';
export var getNormalizedCreatePermissionGroupParams = function (payload) {
    var dashboards = payload.newDashboardSchema.reduce(function (result, _a) {
        var items = _a.items;
        var availableDashboards = items
            .filter(function (_a) {
            var isSelected = _a.isSelected;
            return isSelected;
        })
            .map(function (_a) {
            var id = _a.id, parentId = _a.parentId;
            return ({
                id: +id,
                parent_id: parentId,
                is_active: true,
            });
        });
        result.push.apply(result, availableDashboards);
        return result;
    }, []);
    return {
        name: payload.groupName,
        dashboards: dashboards,
    };
};
export var getNormalizedSetPermissionGroupUsersParams = function (payload, activeGroupAssignedUsers) {
    var turnedOff = without.apply(void 0, __spreadArray([activeGroupAssignedUsers], payload.newAssignedUsers, false));
    var turnedOn = without.apply(void 0, __spreadArray([payload.newAssignedUsers], activeGroupAssignedUsers, false));
    return {
        id: payload.groupId,
        users: __spreadArray(__spreadArray([], turnedOff.map(function (id) { return ({ id: id, is_active: false }); }), true), turnedOn.map(function (id) { return ({ id: id, is_active: true }); }), true),
    };
};
export var getNormalizedEditPermissionGroupParams = function (payload, oldDashboards, oldName) {
    var groupId = payload.groupId, groupName = payload.groupName;
    var changedDashboards = [];
    var oldNormalizedDashboards = cloneDeep(oldDashboards).reduce(function (result, _a) {
        var items = _a.items;
        result.push.apply(result, items);
        return result;
    }, []);
    var newNormalizedDashboards = payload.newDashboardSchema.reduce(function (result, _a) {
        var items = _a.items;
        result.push.apply(result, items);
        return result;
    }, []);
    oldNormalizedDashboards.forEach(function (_a) {
        var id = _a.id, isSelected = _a.isSelected;
        changedDashboards.push.apply(changedDashboards, newNormalizedDashboards.filter(function (newDashboard) { return newDashboard.id === id && newDashboard.isSelected !== isSelected; }));
    });
    return {
        id: groupId,
        name: groupName === oldName ? undefined : groupName,
        dashboards: changedDashboards.map(function (_a) {
            var id = _a.id, isSelected = _a.isSelected, parentId = _a.parentId;
            return ({
                id: id,
                parent_id: parentId,
                is_active: isSelected,
            });
        }),
    };
};
export var handleValidateTokenUpdate = function (groupsIds, groupsIdsFromToken) {
    if (!isEqual(groupsIds.sort(), groupsIdsFromToken.sort())) {
        signOut();
    }
};

export var NO_ACTIVE_INSTANCES_FOUND = 'No Active Instances found';
export var ACTIVE_INSTANCE_COLUMNS_KEYS = {
    ID: 'id',
    NAME: 'name',
    CUSTOMER_NAME: 'customerName',
    RC_STATE: 'releaseControllerState',
    MCC_VERSION: 'mccVersion',
    MCC_VERSION_SYNC_TIME: 'mccVersionSyncTime',
    DETAILS: 'details',
    LAST_HEARTBEAT: 'lastHeartbeat',
};
export var ACTIVE_INSTANCE_STATS_BANNER_DATA = [
    {
        name: 'Instances',
        pseudo: 'instanceCount',
    },
    {
        name: 'Regional clusters',
        pseudo: 'regionClusterCount',
    },
    {
        name: 'Child Clusters',
        pseudo: 'childClusterCount',
    },
];
export var ACTIVE_INSTANCE_COLUMNS = [
    {
        name: 'Customer Name',
        key: ACTIVE_INSTANCE_COLUMNS_KEYS.CUSTOMER_NAME,
        sortByKey: 'customer_name',
        isVisibleByDefault: true,
    },
    {
        name: 'Instance Name',
        key: ACTIVE_INSTANCE_COLUMNS_KEYS.NAME,
        sortByKey: 'name',
        isVisibleByDefault: true,
    },
    {
        name: 'RC State',
        key: ACTIVE_INSTANCE_COLUMNS_KEYS.RC_STATE,
        isVisibleByDefault: true,
    },
    {
        name: 'MCC Version',
        key: ACTIVE_INSTANCE_COLUMNS_KEYS.MCC_VERSION,
        sortByKey: 'mcc_version',
        isVisibleByDefault: true,
    },
    {
        name: 'Version Sync Time',
        key: ACTIVE_INSTANCE_COLUMNS_KEYS.MCC_VERSION_SYNC_TIME,
        sortByKey: 'mcc_version_sync_time',
        isVisibleByDefault: true,
    },
    {
        name: 'Details',
        key: ACTIVE_INSTANCE_COLUMNS_KEYS.DETAILS,
        isVisibleByDefault: true,
    },
    {
        name: 'Last Heartbeat',
        key: ACTIVE_INSTANCE_COLUMNS_KEYS.LAST_HEARTBEAT,
        sortByKey: 'last_heartbeat',
        isVisibleByDefault: true,
    },
];
export var ACTIVE_INSTANCE_DETAIL_COLUMNS = [
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Provider',
        dataIndex: 'provider',
        key: 'provider',
    },
    {
        title: 'Version',
        dataIndex: 'version',
        key: 'version',
    },
    {
        title: 'Master Node Count',
        dataIndex: 'masterNodeCount',
        key: 'masterNodeCount',
    },
    {
        title: 'Worker Node Count',
        dataIndex: 'workerNodeCount',
        key: 'workerNodeCount',
    },
    {
        title: 'Release Controller State',
        dataIndex: 'releaseControllerState',
        key: 'releaseControllerState',
    },
];
export var ACTIVE_INSTANCE_CHART;
(function (ACTIVE_INSTANCE_CHART) {
    ACTIVE_INSTANCE_CHART["uid"] = "DuJK5lPMk";
    ACTIVE_INSTANCE_CHART["chart"] = "active-instances-timeline";
})(ACTIVE_INSTANCE_CHART || (ACTIVE_INSTANCE_CHART = {}));
export var ACTIVE_INSTANCE_EXPANDED_TALE_CELL_HEIGHT = {
    MIN_HEIGHT: '18px',
};
export var KEYS = ['type', 'provider', 'version', 'masterNodeCount', 'workerNodeCount', 'releaseControllerState'];

export var SyncActions;
(function (SyncActions) {
    SyncActions["SET_CUSTOMER_TYPES"] = "SET_CUSTOMER_TYPES";
    SyncActions["SET_SELECTED_OPTIONS"] = "SET_SELECTED_OPTIONS";
    SyncActions["DROP_LAST_SYNC_DATE"] = "DROP_LAST_SYNC_DATE";
})(SyncActions || (SyncActions = {}));
export var AsyncActions;
(function (AsyncActions) {
    AsyncActions["GET_CUSTOMER_TYPES"] = "GET_CUSTOMER_TYPES";
})(AsyncActions || (AsyncActions = {}));

import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Toggle as MDSToggle } from '@mirantis/mds-toggle';
import { StyledToggleandTabsWrapper } from './styled';
var OverallOpenstackUsage = lazy(function () { return import('./overall-openstack-usage/OverallOpenstackUsage'); });
import { setCheckedNodesToUrl, getCheckedNodesFromUrl, deleteCheckedNodesInUrl } from './openstackUsageUtils';
var OpenstackUsage = function () {
    var _a = useState(false), checkedNodes = _a[0], toggleCheckedNodes = _a[1];
    useEffect(function () {
        var checkedNodesFromUrl = getCheckedNodesFromUrl();
        if (checkedNodesFromUrl === 'true') {
            toggleCheckedNodes(true);
        }
    }, []);
    useEffect(function () {
        if (checkedNodes) {
            setCheckedNodesToUrl('true');
        }
        else {
            deleteCheckedNodesInUrl();
        }
    }, [checkedNodes]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Suspense, { fallback: null },
            React.createElement(StyledToggleandTabsWrapper, null,
                React.createElement("h2", null, "OpenStack Usage"),
                React.createElement(MDSToggle, { value: checkedNodes, label: checkedNodes ? 'All nodes' : 'Enabled nodes', onClick: function () { return toggleCheckedNodes(!checkedNodes); } })),
            React.createElement(OverallOpenstackUsage, { checkedNodes: checkedNodes }))));
};
export default OpenstackUsage;

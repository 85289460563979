import get from 'lodash/get';
import moment from 'moment';
import head from 'lodash/head';
import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import { DateUtils } from '@utils/date/DateUtils';
import { CHART_DASHBOARDS, COSTS_CHARTS_KEYS } from './occupiedSlotsConstants';
export var getOccupidedSlotsChartsConfigs = function (multiFilter, crossPageFilter, timezone) {
    var _a, _b;
    return {
        dashboard: CHART_DASHBOARDS.TRIAL_REPORTS_OCCUPIED_SLOTS,
        charts: [
            {
                isExternal: head((_b = (_a = multiFilter === null || multiFilter === void 0 ? void 0 : multiFilter.filter) === null || _a === void 0 ? void 0 : _a.selected) === null || _b === void 0 ? void 0 : _b.external),
                timezone: timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
                granularity: crossPageFilter.granularity.selected.value,
                start: crossPageFilter.dateRange.calculatedDateRange.start.timeStamp,
                end: crossPageFilter.dateRange.calculatedDateRange.end.timeStamp,
                key: COSTS_CHARTS_KEYS.OCCUPIED_CHARTS,
            },
        ],
    };
};
export var getNormalizedOccupiedSlots = function (value, granularity) {
    if (value === void 0) { value = []; }
    if (granularity === void 0) { granularity = ''; }
    var points = flatten(value.map(function (account) { return get(account, 'Points', []); }));
    var dates = points.map(function (_a) {
        var date = _a.date;
        return moment(date).valueOf();
    });
    var max = Math.max.apply(Math, dates);
    var min = Math.min.apply(Math, dates);
    var sortedValue = orderBy(value, function (account) { return get(account, 'Points', []).length; }, ['desc']);
    var datasetSkeleton = {};
    get(head(sortedValue), 'Points', []).forEach(function (_a) {
        var date = _a.date;
        datasetSkeleton[moment(date).format('Y/M/D')] = {
            x: date,
            y: 0,
        };
    });
    return sortedValue.map(function (account) {
        var dataset = cloneDeep(datasetSkeleton);
        if (granularity === '1 day') {
            dataset = DateUtils.enumerateDaysBetweenDates(min, max).reduce(function (result, date) {
                result[moment(date).format('Y/M/D')] = {
                    x: date,
                    y: '0',
                };
                return result;
            }, {});
        }
        get(account, 'Points', []).forEach(function (_a) {
            var date = _a.date, count = _a.count;
            dataset[moment(date).format('Y/M/D')] = {
                x: date,
                y: count,
            };
        });
        return Object.values(dataset);
    });
};

export var NO_BOOTSTRAP_EVENTS_FOUND = 'No Bootstrap Events found';
export var TABLE_TITLE = 'Events';
export var COLUMNS = [
    {
        name: 'Timestamp',
        key: 'eventTime',
        sortByKey: 'event_time',
        isVisibleByDefault: true,
    },
    {
        name: 'Customer Name',
        key: 'customerName',
        sortByKey: 'customer_name',
        isVisibleByDefault: true,
    },
    {
        name: 'Instance Name',
        key: 'instanceName',
        sortByKey: 'instance_name',
        isVisibleByDefault: true,
    },
    {
        name: 'Stage',
        key: 'stage',
        sortByKey: 'stage',
        isVisibleByDefault: true,
    },
    {
        name: 'Provider',
        key: 'provider',
        sortByKey: 'provider',
        isVisibleByDefault: true,
    },
    {
        name: 'Status',
        key: 'status',
        sortByKey: 'status',
        isVisibleByDefault: true,
    },
    {
        name: 'Bin version',
        key: 'binVersion',
        sortByKey: 'bin_version',
        isVisibleByDefault: true,
    },
    {
        name: 'Message',
        key: 'message',
        sortByKey: 'message',
    },
];

export var SyncActions;
(function (SyncActions) {
    SyncActions["SET_CLUSTER_SPECS"] = "SET_CLUSTER_SPECS";
    SyncActions["SET_TELEMETRY_CUSTOMERS"] = "SET_TELEMETRY_CUSTOMERS";
    SyncActions["SET_TELEMETRY_CUSTOMER"] = "SET_TELEMETRY_CUSTOMER";
    SyncActions["RESET_MKE_TELEMETRY_CUSTOMER"] = "RESET_MKE_TELEMETRY_CUSTOMER";
    SyncActions["SET_MKE_ALERTS"] = "SET_MKE_ALERTS";
    SyncActions["SET_MKE_RECOMMENDATIONS"] = "SET_MKE_RECOMMENDATIONS";
    SyncActions["SET_TELEMETRY_CUSTOMER_ERROR"] = "SET_TELEMETRY_CUSTOMER_ERROR";
    SyncActions["DROP_MKE_RECOMMENDATIONS_TO_DEFAULT"] = "DROP_MKE_RECOMMENDATIONS_TO_DEFAULT";
    SyncActions["DROP_MKE_ALERTS_TO_DEFAULT"] = "DROP_MKE_ALERTS_TO_DEFAULT";
})(SyncActions || (SyncActions = {}));
export var AsyncActions;
(function (AsyncActions) {
    AsyncActions["GET_TELEMETRY_CUSTOMERS"] = "GET_TELEMETRY_CUSTOMERS";
    AsyncActions["GET_TELEMETRY_CUSTOMER"] = "GET_TELEMETRY_CUSTOMER";
    AsyncActions["GET_MKE_ALERTS"] = "GET_MKE_ALERTS";
    AsyncActions["GET_MKE_RECOMMENDATIONS"] = "GET_MKE_RECOMMENDATIONS";
    AsyncActions["TOGGLE_TELEMETRY_CLUSTER_STATUS"] = "TOGGLE_TELEMETRY_CLUSTER_STATUS";
    AsyncActions["GET_CLUSTER_SPECS"] = "GET_CLUSTER_SPECS";
})(AsyncActions || (AsyncActions = {}));

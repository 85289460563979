var Validation = /** @class */ (function () {
    function Validation() {
    }
    Validation.email = function (email) {
        return email.length > 0 && !!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    };
    Validation.password = function (password) { return password.length >= 4; };
    Validation.noSpecSymbols = function (s) { return /^[a-zA-Z0-9][^^~`!@&#$%*+[\]{}()\\/:;?><=\t\n]{2,63}$/.test(s); };
    Validation.noEmpty = function (value) { return /^(?=.{3,64}$).*$/.test(value); };
    return Validation;
}());
export { Validation };

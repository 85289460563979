var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import get from 'lodash/get';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import { DOMAIN, CLIENT_ID } from '@configs/auth0';
import { getAPI } from '@configs/api';
import { routes } from '@configs/routes';
import AppLoader from '@commonComponents/app-loader/appLoader';
import { getNormalizedUserInfo } from '@commonUtils/tokenUtils';
import { isSFPermissionGroup } from '@commonUtils/commonUtils';
import { TIMEOUTS } from '@commonConstants/timeoutConstants';
import { HTTP_CODES } from '@commonConstants/httpConstants';
import { LOCAL_STORAGE_KEYS } from '@commonConstants/localStorageConstancts';
import { tokenSignIn } from '@api/tokenSingIn';
import { signInStarted, changeSignInStep, resetSignIn, redirectingStarted } from '@redux/dispatchers/signIn';
import { setUserInfo, setUserPreferences } from '@redux/dispatchers/userInfo';
import Redirect from '@commonUtils/urlUtils/Redirect';
import { Localstorage } from '@utils/localstorage/Localstorage';
import { JWT } from '@utils/JWT/JWT';
import { message } from 'antd';
var OK = HTTP_CODES.OK;
export var AuthContext = React.createContext({});
var audience = getAPI();
var OAuthProvider = /** @class */ (function (_super) {
    __extends(OAuthProvider, _super);
    function OAuthProvider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.mocked_internal_token = null;
        _this.state = {
            auth0: null,
            isAuthenticated: false,
            internalSignIn: false,
        };
        _this.getAuth0Client = function () {
            return createAuth0Client({
                domain: DOMAIN,
                clientId: CLIENT_ID,
                authorizationParams: {
                    audience: audience,
                    redirect_uri: window.location.origin,
                },
            });
        };
        _this.login = function (connection) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.mocked_internal_token === null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.state.auth0.loginWithPopup({
                                authorizationParams: {
                                    redirect_uri: window.location.origin,
                                    prompt: 'login',
                                    connection: connection,
                                },
                            })];
                    case 1:
                        _a.sent();
                        signInStarted();
                        setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                            var token;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.state.auth0.getTokenSilently()];
                                    case 1:
                                        token = _a.sent();
                                        return [4 /*yield*/, this.internalLogIn(token)];
                                    case 2:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); }, TIMEOUTS.AUTHORIZATION_STEP);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.internalLogIn(this.mocked_internal_token)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.internalLogIn = function (id_token, endpoint) {
            if (endpoint === void 0) { endpoint = null; }
            return __awaiter(_this, void 0, void 0, function () {
                var token, internalResponse, user, normalizedUserInfo, preferences, isSfUser;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            changeSignInStep();
                            token = '';
                            if (!(this.mocked_internal_token === null)) return [3 /*break*/, 2];
                            return [4 /*yield*/, tokenSignIn(id_token, endpoint)];
                        case 1:
                            internalResponse = _a.sent();
                            if (get(internalResponse, 'status') !== OK) {
                                Redirect.forbiddenAccess();
                                return [2 /*return*/];
                            }
                            token = internalResponse.data.token;
                            return [3 /*break*/, 3];
                        case 2:
                            token = this.mocked_internal_token;
                            _a.label = 3;
                        case 3:
                            user = JWT.decode(token);
                            normalizedUserInfo = getNormalizedUserInfo(user);
                            preferences = {
                                id: normalizedUserInfo.id,
                                timezone: normalizedUserInfo.preferences.timezone,
                            };
                            setUserPreferences(preferences);
                            return [4 /*yield*/, Localstorage.set(LOCAL_STORAGE_KEYS.USER_PREFERENCES, JSON.stringify(preferences))];
                        case 4:
                            _a.sent();
                            isSfUser = isSFPermissionGroup(normalizedUserInfo.permissionGroups);
                            this.setState({
                                isAuthenticated: true,
                            });
                            return [4 /*yield*/, Localstorage.set(LOCAL_STORAGE_KEYS.TOKEN, token)];
                        case 5:
                            _a.sent();
                            setUserInfo(__assign(__assign({}, normalizedUserInfo), { firstLogin: true }));
                            redirectingStarted();
                            if (isSfUser) {
                                this.signInMKEUser(user);
                            }
                            else {
                                this.signInMCCUser(normalizedUserInfo);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        };
        _this.signInMCCUser = function (normalizedUserInfo) {
            setTimeout(function () {
                resetSignIn();
                Redirect.toMain();
                if (normalizedUserInfo.permissionGroups.some(function (group) { return group.tag === 'DEFAULT'; }) &&
                    normalizedUserInfo.permissionGroups.length === 1) {
                    message.info('If you would like to see more dashboards get in touch with mcnc-support@mirantis.com', 30);
                }
            }, TIMEOUTS.AUTHORIZATION_STEP);
        };
        _this.signInMKEUser = function (user) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                setTimeout(function () {
                    resetSignIn();
                    Redirect.toTelemetryDashboard(user.sign_in_id);
                }, TIMEOUTS.AUTHORIZATION_STEP);
                return [2 /*return*/];
            });
        }); };
        _this.logout = function () {
            _this.removeInternalToken();
            if (!_this.mocked_internal_token) {
                _this.state.auth0.logout({
                    logoutParams: {
                        returnTo: window.location.origin + routes.SIGN_IN.path,
                        client_id: CLIENT_ID,
                    },
                });
            }
            else {
                Redirect.toSignIn();
            }
        };
        _this.isSignedIn = function () { return _this.state.isAuthenticated && !!Localstorage.get(LOCAL_STORAGE_KEYS.TOKEN); };
        _this.getCurrentUser = function () { return _this.state.auth0.getUser(); };
        _this.removeInternalToken = function () {
            Localstorage.remove(LOCAL_STORAGE_KEYS.TOKEN);
            Localstorage.remove(LOCAL_STORAGE_KEYS.USER_PREFERENCES);
            Localstorage.remove(LOCAL_STORAGE_KEYS.MOCKED_INTERNAL_TOKEN);
        };
        return _this;
    }
    OAuthProvider.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var token, mockedInternalToken, isTestingMode, auth0, _a;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        token = Localstorage.get(LOCAL_STORAGE_KEYS.TOKEN);
                        mockedInternalToken = Localstorage.get(LOCAL_STORAGE_KEYS.MOCKED_INTERNAL_TOKEN);
                        isTestingMode = Localstorage.get(LOCAL_STORAGE_KEYS.IS_TESTING_MODE) === 'true';
                        if (!(mockedInternalToken === null && !isTestingMode)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getAuth0Client()];
                    case 1:
                        auth0 = _c.sent();
                        _a = this.setState;
                        _b = {
                            auth0: auth0
                        };
                        return [4 /*yield*/, auth0.isAuthenticated()];
                    case 2:
                        _a.apply(this, [(_b.isAuthenticated = _c.sent(),
                                _b)]);
                        return [3 /*break*/, 4];
                    case 3:
                        this.mocked_internal_token = mockedInternalToken || token;
                        this.setState({
                            auth0: true,
                            isAuthenticated: true,
                        });
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    OAuthProvider.prototype.render = function () {
        if (!this.state.auth0) {
            return React.createElement(AppLoader, null);
        }
        return (React.createElement(AuthContext.Provider, { value: {
                login: this.login,
                internalLogIn: this.internalLogIn,
                isSignedIn: this.isSignedIn,
                logout: this.logout,
                getCurrentUser: this.getCurrentUser,
            } }, this.props.children));
    };
    return OAuthProvider;
}(React.Component));
export default OAuthProvider;

import React, { useState } from 'react';
import classNames from 'classnames';
import { StyledPureHTMLTooltip } from './styled';
var PureHTMLTooltip = function (_a) {
    var children = _a.children, text = _a.text;
    var _b = useState(false), show = _b[0], setShow = _b[1];
    var tooltipClassName = classNames({
        'pure-html-tooltip__text': true,
        visible: show,
    });
    return (React.createElement(StyledPureHTMLTooltip, { className: "pure-html-tooltip" },
        React.createElement("div", { className: "pure-html-tooltip__child", onMouseOver: function () { return setShow(true); }, onMouseLeave: function () { return setShow(false); } }, children),
        React.createElement("span", { "data-testid": "pure-html-tooltip", className: tooltipClassName }, text)));
};
export default PureHTMLTooltip;

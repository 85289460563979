var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Link } from 'react-router-dom';
import { css, jsx } from '@emotion/react';
import classNames from 'classnames';
import LazyImageFactory from '@services/lazy-image-factory/LazyImageFactory';
import { isSFPermissionGroup } from '@commonUtils/commonUtils';
import { routes } from '@configs/routes';
import { MQ } from '@emotionConstants';
var small = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 240px;\n    height: 65px;\n    padding: 18px 18px 0 13px;\n\n    &.expanded {\n        padding-right: 18px;\n    }\n"], ["\n    width: 240px;\n    height: 65px;\n    padding: 18px 18px 0 13px;\n\n    &.expanded {\n        padding-right: 18px;\n    }\n"])));
var large = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin: 60px 70px 55px;\n\n    ", " {\n        margin: 40px 25px 25px;\n    }\n\n    ", " {\n        margin: 40px 50px 25px;\n    }\n\n    & .svg {\n        height: 35px;\n    }\n"], ["\n    margin: 60px 70px 55px;\n\n    ", " {\n        margin: 40px 25px 25px;\n    }\n\n    ", " {\n        margin: 40px 50px 25px;\n    }\n\n    & .svg {\n        height: 35px;\n    }\n"])), MQ['md'], MQ['lg']);
var AsideLogo = function (_a) {
    var isSmall = _a.isSmall, collapsed = _a.collapsed;
    var imageName = collapsed ? 'small-logo-mcnc.svg' : 'logo-mcnc.svg';
    var image = jsx(LazyImageFactory, { imageClassName: "svg", imageName: imageName, type: "img" });
    var logo = isSFPermissionGroup() ? image : jsx(Link, { to: routes.MAIN.path }, image);
    return (
    // @ts-ignore
    jsx("div", { key: imageName, className: classNames('aside__logo'), css: isSmall ? small : large }, logo));
};
export default AsideLogo;
var templateObject_1, templateObject_2;

export var AsyncActions;
(function (AsyncActions) {
    AsyncActions["FETCH_PERMISSIONS_ALLOWED_PAGES_IDS"] = "FETCH_PERMISSIONS_ALLOWED_PAGES_IDS";
    AsyncActions["FETCH_PERMISSION_GROUPS"] = "FETCH_PERMISSION_GROUPS";
    AsyncActions["POST_PERMISSIONS_GROUP"] = "POST_PERMISSIONS_GROUP";
    AsyncActions["PATCH_PERMISSIONS_GROUP"] = "PATCH_PERMISSIONS_GROUP";
    AsyncActions["FETCH_PERMISSIONS_GROUP"] = "FETCH_PERMISSIONS_GROUP";
    AsyncActions["CREATE_PERMISSION_GROUP"] = "CREATE_PERMISSION_GROUP";
    AsyncActions["EDIT_PERMISSION_GROUP"] = "EDIT_PERMISSION_GROUP";
    AsyncActions["DELETE_PERMISSION_GROUP"] = "DELETE_PERMISSION_GROUP";
    AsyncActions["FETCH_PERMISSION_GROUP_USERS"] = "FETCH_PERMISSION_GROUP_USERS";
    AsyncActions["FETCH_PERMISSION_ALL_USERS"] = "FETCH_PERMISSION_ALL_USERS";
})(AsyncActions || (AsyncActions = {}));
export var SyncActions;
(function (SyncActions) {
    SyncActions["SET_PERMISSION_GROUPS"] = "SET_PERMISSION_GROUPS";
    SyncActions["RESET_PERMISSIONS_ALL_USERS"] = "RESET_PERMISSIONS_ALL_USERS";
    SyncActions["SET_PERMISSIONS_ALLOWED_PAGES_LOADING"] = "SET_PERMISSIONS_ALLOWED_PAGES_LOADING";
    SyncActions["SET_PERMISSION_GROUP_USERS"] = "SET_PERMISSION_GROUP_USERS";
    SyncActions["SET_ACTIVE_PERMISSIONS_GROUP_ID"] = "SET_ACTIVE_PERMISSIONS_GROUP_ID";
    SyncActions["SET_PERMISSIONS_GROUP"] = "SET_PERMISSIONS_GROUP";
    SyncActions["SET_PERMISSIONS_ALLOWED_PAGES_TAGS"] = "SET_PERMISSIONS_ALLOWED_PAGES_TAGS";
    SyncActions["SET_PERMISSIONS_ALL_USERS"] = "SET_PERMISSIONS_ALL_USERS";
    SyncActions["SET_ACTIVE_GROUP_ASSIGNED_USERS"] = "SET_ACTIVE_GROUP_ASSIGNED_USERS";
})(SyncActions || (SyncActions = {}));

import produce from 'immer';
import { resolvers } from './resolvers';
var initialState = {
    searchClusters: {
        loading: true,
        data: [],
    },
};
export var clusters = function (state, action) {
    if (state === void 0) { state = initialState; }
    if (action === void 0) { action = {}; }
    var type = action.type, payload = action.payload;
    if (!(type in resolvers)) {
        return state;
    }
    var resolver = function (draft) { return resolvers[type](draft, payload); };
    return produce(state, resolver);
};

import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import Line from '@commonComponents/chart/Line/Line';
import { dropChartsToDefault, fetchCharts } from '@redux/dispatchers/charts';
import { getChartOptions, getDynamicValueUnit, getNormalizedABSYValue, getNormalizedABSTooltipValue, getNormalizedDynamicGbYAxisData, getNormalizedChartDataDynamicKey, getNormalizedDynamicGbTooltipValue, } from '@commonUtils/charts/chartUtils';
import { CHART_MESSAGES } from '@commonConstants/chartsConstants';
import { MKE_CHARTS_KEYS, CHART_DASHBOARDS } from './mkeClusterChartsConstants';
import { DateUtils } from '@utils/date/DateUtils';
import { STATIC_REPORTS_DATE_RANGES } from '@utils/date/dateConstants';
import { getChartsConfigs } from './mkeClusterChartsUtils';
import { StyledChartsWrapperNew } from '@app/common/react/styled/StyledChartsWrapperNew';
import { StyledMkeClusterCharts } from './StyledMkeClusterCharts';
var MEMORY = MKE_CHARTS_KEYS.MEMORY, CPUS = MKE_CHARTS_KEYS.CPUS, CONTAINER_COUNT = MKE_CHARTS_KEYS.CONTAINER_COUNT, CONTROLLER_COUNT = MKE_CHARTS_KEYS.CONTROLLER_COUNT, NODE_COUNT = MKE_CHARTS_KEYS.NODE_COUNT;
var MkeClusterCharts = function (props) {
    var _a = useSelector(function (store) {
        var _a, _b, _c, _d, _e, _f;
        return ({
            timezone: store.userInfo.userPreferences.timezone,
            data: get((_c = (_b = (_a = store.charts) === null || _a === void 0 ? void 0 : _a.mkeCluster) === null || _b === void 0 ? void 0 : _b.mkeClusterCharts) === null || _c === void 0 ? void 0 : _c.data, "".concat(props.activeClusterId), []),
            loading: (_f = (_e = (_d = store.charts) === null || _d === void 0 ? void 0 : _d.mkeCluster) === null || _e === void 0 ? void 0 : _e.mkeClusterCharts) === null || _f === void 0 ? void 0 : _f.loading,
            calculatedDateRange: store.crossPageFilter.dateRange.calculatedDateRange,
            crossPageFilter: store.crossPageFilter,
        });
    }), timezone = _a.timezone, data = _a.data, loading = _a.loading, calculatedDateRange = _a.calculatedDateRange, crossPageFilter = _a.crossPageFilter;
    var t = timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone;
    var _b = DateUtils.calculateDateRangeByOption(STATIC_REPORTS_DATE_RANGES.PREVIOUS_1_MONTHS), start = _b.start, end = _b.end;
    var params = {
        timezone: t,
        granularity: '1 day',
        activeClusterId: props.activeClusterId,
        license: props.clusterLicense,
        start: props.checkedActive || crossPageFilter.dashboardName === null
            ? start.timeStamp
            : calculatedDateRange === null || calculatedDateRange === void 0 ? void 0 : calculatedDateRange.start.timeStamp,
        end: props.checkedActive || crossPageFilter.dashboardName === null
            ? end.timeStamp
            : calculatedDateRange === null || calculatedDateRange === void 0 ? void 0 : calculatedDateRange.end.timeStamp,
    };
    useEffect(function () {
        if (props.shouldFetchCharts) {
            fetchCharts(getChartsConfigs(params));
        }
        return function () {
            dropChartsToDefault(CHART_DASHBOARDS.mkeCluster);
        };
    }, [props.activeClusterId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledMkeClusterCharts, null,
            React.createElement(StyledChartsWrapperNew, { className: props.rowChartsCount || 'two' },
                React.createElement(Line, { chartKeys: {
                        key: CONTAINER_COUNT,
                        clusterId: props.activeClusterId,
                        dashboard: CHART_DASHBOARDS.mkeCluster,
                    }, loadingOut: loading, leftLabel: "containers", title: "Containers Over Time", datasets: [getNormalizedChartDataDynamicKey(data, CONTAINER_COUNT)], message: CHART_MESSAGES.CPU_RATIO_CHART_MESSAGE, chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedABSYValue,
                        getNormalizedTooltipValue: getNormalizedABSTooltipValue,
                    }) }),
                React.createElement(Line, { chartKeys: {
                        key: CPUS,
                        clusterId: props.activeClusterId,
                        dashboard: CHART_DASHBOARDS.mkeCluster,
                    }, leftLabel: "cores", loadingOut: loading, title: "CPU Cores Over Time", datasets: [getNormalizedChartDataDynamicKey(data, CPUS)], message: CHART_MESSAGES.RAM_RATIO_CHART_MESSAGE, chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedABSYValue,
                        getNormalizedTooltipValue: getNormalizedABSTooltipValue,
                    }) }))),
        React.createElement(StyledMkeClusterCharts, null,
            React.createElement(StyledChartsWrapperNew, { className: props.rowChartsCount || 'three' },
                React.createElement(Line, { chartKeys: {
                        key: MEMORY,
                        clusterId: props.activeClusterId,
                        dashboard: CHART_DASHBOARDS.mkeCluster,
                    }, leftLabel: "bytes", loadingOut: loading, title: "Memory Over Time", dynamicLabelFormatter: getDynamicValueUnit, datasets: [getNormalizedChartDataDynamicKey(data, MEMORY)], message: CHART_MESSAGES.CPU_ABS_CHART_MESSAGE, chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedDynamicGbYAxisData,
                        getNormalizedTooltipValue: getNormalizedDynamicGbTooltipValue,
                    }) }),
                React.createElement(Line, { chartKeys: {
                        key: CONTROLLER_COUNT,
                        clusterId: props.activeClusterId,
                        dashboard: CHART_DASHBOARDS.mkeCluster,
                    }, leftLabel: "nodes", loadingOut: loading, title: "Manager Nodes Over Time", datasets: [getNormalizedChartDataDynamicKey(data, CONTROLLER_COUNT)], message: CHART_MESSAGES.RAM_ABS_CHART_MESSAGE, chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedABSYValue,
                        getNormalizedTooltipValue: getNormalizedABSTooltipValue,
                    }) }),
                React.createElement(Line, { chartKeys: {
                        key: NODE_COUNT,
                        clusterId: props.activeClusterId,
                        dashboard: CHART_DASHBOARDS.mkeCluster,
                    }, leftLabel: "nodes", loadingOut: loading, title: "Total Nodes Over Time", datasets: [getNormalizedChartDataDynamicKey(data, NODE_COUNT)], message: CHART_MESSAGES.DISK_ABS_CHART_MESSAGE, chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedABSYValue,
                        getNormalizedTooltipValue: getNormalizedABSTooltipValue,
                    }) })))));
};
export default memo(MkeClusterCharts);

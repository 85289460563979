var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import isNil from 'lodash/isNil';
var getNormalizedExtraInfoItems = function (arr) {
    var normalized = arr.filter(function (item) { return item.value && item.value !== 'null'; });
    normalized = __spreadArray([], normalized.map(function (item) {
        var value = item.value, link = item.link;
        var res = value[value.length - 1] === '.' ? value.substring(0, value.length - 1) : value;
        return {
            value: res,
            link: link,
        };
    }), true);
    return normalized;
};
var getNormalizedDescription = function (str, length) {
    var payloadStr = str.split(': ')[0];
    if (!length)
        return "".concat(str, ".").replace('..', '.');
    if (length === 1)
        payloadStr = "".concat(payloadStr, ".").replace('..', '.');
    if (length > 1)
        payloadStr = "".concat(payloadStr, ": ").concat(length);
    return payloadStr;
};
export var getNormalizedRecommendationData = function (data) {
    if (isNil(data))
        return [];
    return data.map(function (recommendation) {
        var noNormExtraInfoItems = recommendation.extraInfoItems, noNormalDescription = recommendation.description;
        var extraInfoItems = getNormalizedExtraInfoItems(noNormExtraInfoItems);
        var description = getNormalizedDescription(noNormalDescription, extraInfoItems.length);
        return __assign(__assign({}, recommendation), { extraInfoItems: extraInfoItems, description: description });
    });
};
export { getNormalizedExtraInfoItems, getNormalizedDescription };

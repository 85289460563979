import React, { useEffect, memo, useMemo } from 'react';
import { Button, actionTypes } from '@mirantis/mds-button';
import { useDebouncedEffect } from '@app/hooks';
import RowLimit from './RowLimit';
import { renderItems, handleChange, getCorrectNumberOfRaws, handleSetPaginationPreferencesToUrl, handleSetPaginationPreferencesFromUrlToStore, } from './paginationUtils';
import { StyledPagination, StyledPaginationHandler } from '@commonComponents/pagination/styled';
var Pagination = function (props) {
    var limits = props.limits, onChange = props.onChange, withLimits = props.withLimits, pagination = props.pagination, onLimitChange = props.onLimitChange, paginationQuery = props.paginationQuery, paginationPreferences = props.paginationPreferences;
    var currentPage = pagination.currentPage, maxPages = pagination.maxPages, limit = pagination.limit;
    var prevPrefix = currentPage === 1 ? 'disabled' : 'bordered';
    var nextPrefix = currentPage === maxPages ? 'disabled' : 'bordered';
    // Made to cache pagination values when clicking on them and not to substitute the default value
    var memoLimits = useMemo(function () { return getCorrectNumberOfRaws(pagination.limit, maxPages, limits); }, [maxPages, limit]);
    var renderRowLimits = function () {
        if (withLimits) {
            var handleOnLimitChange = function (nextLimit) {
                onLimitChange(nextLimit);
            };
            return (React.createElement("div", null,
                React.createElement(RowLimit, { limit: limit, maxPages: maxPages, limits: memoLimits, onLimitChange: handleOnLimitChange })));
        }
    };
    var renderPagination = function () {
        if (!maxPages || maxPages < 2) {
            return null;
        }
        return (React.createElement(StyledPagination, { className: "pagination" },
            React.createElement(Button, { label: "Prev", 
                //@ts-ignore
                actionType: actionTypes.SECONDARY, disabled: prevPrefix === 'disabled', onClick: function () { return handleChange('prev', onChange, pagination); } }),
            React.createElement(StyledPaginationHandler, { className: "pagination__handler", style: { display: 'inherit' } }, renderItems(props)),
            React.createElement(Button, { label: "Next", 
                //@ts-ignore
                actionType: actionTypes.SECONDARY, disabled: nextPrefix === 'disabled', onClick: function () { return handleChange('next', onChange, pagination); } })));
    };
    useEffect(function () {
        handleSetPaginationPreferencesFromUrlToStore(limit, currentPage, onChange, onLimitChange, paginationPreferences);
    }, []);
    useDebouncedEffect(function () {
        handleSetPaginationPreferencesToUrl(limit, currentPage, paginationQuery, paginationPreferences);
    }, [limit, currentPage]);
    return (React.createElement(StyledPaginationHandler, { className: "pagination-handler" },
        renderRowLimits(),
        renderPagination()));
};
export default memo(Pagination);

export var SyncActions;
(function (SyncActions) {
    SyncActions["SET_TOP_CUSTOMERS"] = "SET_TOP_CUSTOMERS";
    SyncActions["FILTER_CUSTOMERS"] = "FILTER_CUSTOMERS";
    SyncActions["SET_FILTEREd_CUSTOMERS"] = "SET_FILTEREd_CUSTOMERS";
    SyncActions["RESET_FILTER"] = "RESET_FILTER";
    SyncActions["SET_MAP_FILTER_OPTIONS"] = "SET_MAP_FILTER_OPTIONS";
    SyncActions["CHANGE_OPTION"] = "CHANGE_OPTION";
})(SyncActions || (SyncActions = {}));
export var AsyncActions;
(function (AsyncActions) {
    AsyncActions["GET_TOP_CUSTOMERS"] = "GET_TOP_CUSTOMERS";
    AsyncActions["GET_CUSTOMERS_FOR_MAP"] = "GET_CUSTOMERS_FOR_MAP";
})(AsyncActions || (AsyncActions = {}));

import React, { useState, useRef, useEffect, useCallback } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import history from '@history/history';
import get from 'lodash/get';
import queryString from 'query-string';
var DEFAULT_DEBOUNCE = 100;
export var usePrevious = function (value) {
    var ref = useRef();
    useEffect(function () {
        ref.current = value;
    });
    return ref.current;
};
export var useDebouncedEffect = function (effect, deps, delay) {
    if (delay === void 0) { delay = DEFAULT_DEBOUNCE; }
    var callback = useCallback(effect, deps);
    useEffect(function () {
        var handler = setTimeout(function () {
            callback();
        }, delay);
        return function () {
            clearTimeout(handler);
        };
    }, [callback, delay]);
};
export var useSize = function (target) {
    var _a = React.useState({ bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0, x: 0, y: 0 }), size = _a[0], setSize = _a[1];
    React.useLayoutEffect(function () {
        setSize(target.current.getBoundingClientRect());
    }, [target]);
    // @ts-ignore
    useResizeObserver(target, function (entry) { return setSize(entry.contentRect); });
    return size;
};
export var useLocalStorage = function (key, initialValue) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    var _a = useState(function () {
        if (typeof window === 'undefined') {
            return initialValue;
        }
        try {
            // Get from local storage by key
            var item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            // eslint-disable-next-line newline-before-return
            return item ? JSON.parse(item) : initialValue;
        }
        catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    }), storedValue = _a[0], setStoredValue = _a[1];
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    var setValue = function (value) {
        try {
            // Allow value to be a function so we have same API as useState
            var valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            if (typeof window !== 'undefined') {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        }
        catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };
    return [storedValue, setValue];
};
export var useHistory = function () {
    var _a = React.useState({
        action: history.action,
        location: history.location,
    }), historyLocal = _a[0], setHistory = _a[1];
    React.useLayoutEffect(function () { return history.listen(setHistory); }, [history]);
    return historyLocal;
};
export var useQuery = function (query) {
    var _a = React.useState({
        action: history.action,
        location: history.location,
    }), historyLocal = _a[0], setHistory = _a[1];
    React.useLayoutEffect(function () { return history.listen(setHistory); }, [history]);
    return get(queryString.parse(historyLocal.location.search), query, '');
};

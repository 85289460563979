import * as Actions from '@redux/actions/charts';
export var fetchCharts = function (payload) { return ({
    type: Actions.AsyncActions.FETCH_CHARTS,
    payload: payload,
}); };
export var setChartsData = function (payload) { return ({
    type: Actions.SyncActions.SET_CHARTS_DATA,
    payload: payload,
}); };
export var setChartsCanvasLoaded = function (payload) { return ({
    type: Actions.SyncActions.SET_CHARTS_CANVAS_LOADED,
    payload: payload,
}); };
export var dropChartsToDefault = function (payload) { return ({
    type: Actions.SyncActions.DROP_CHARTS_TO_DEFAULT,
    payload: payload,
}); };

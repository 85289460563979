import * as Actions from '@redux/actions/telemetry';
export var toggleTelemetryClusterStatus = function (payload) { return ({
    type: Actions.AsyncActions.TOGGLE_TELEMETRY_CLUSTER_STATUS,
    payload: payload,
}); };
export var getTelemetryCustomers = function (payload) { return ({
    type: Actions.AsyncActions.GET_TELEMETRY_CUSTOMERS,
    payload: payload,
}); };
export var setTelemetryCustomers = function (payload) { return ({
    type: Actions.SyncActions.SET_TELEMETRY_CUSTOMERS,
    payload: payload,
}); };
export var getTelemetryCustomer = function (payload) { return ({
    type: Actions.AsyncActions.GET_TELEMETRY_CUSTOMER,
    payload: payload,
}); };
export var setTelemetryCustomer = function (payload) { return ({
    type: Actions.SyncActions.SET_TELEMETRY_CUSTOMER,
    payload: payload,
}); };
export var resetTelemetryMkeCustomer = function () { return ({
    type: Actions.SyncActions.RESET_MKE_TELEMETRY_CUSTOMER,
}); };
export var getMKEAlerts = function (payload) { return ({
    type: Actions.AsyncActions.GET_MKE_ALERTS,
    payload: payload,
}); };
export var setMKEAlerts = function (payload) { return ({
    type: Actions.SyncActions.SET_MKE_ALERTS,
    payload: payload,
}); };
export var dropMKEAlertsToDefault = function () { return ({
    type: Actions.SyncActions.DROP_MKE_ALERTS_TO_DEFAULT,
}); };
export var getMKERecommendations = function (payload) { return ({
    type: Actions.AsyncActions.GET_MKE_RECOMMENDATIONS,
    payload: payload,
}); };
export var dropMKERecommendationsToDefault = function () { return ({
    type: Actions.SyncActions.DROP_MKE_RECOMMENDATIONS_TO_DEFAULT,
}); };
export var getClusterSpecs = function (payload) { return ({
    type: Actions.AsyncActions.GET_CLUSTER_SPECS,
    payload: payload,
}); };
export var setClusterSpecs = function (payload) { return ({
    type: Actions.SyncActions.SET_CLUSTER_SPECS,
    payload: payload,
}); };
export var setMKERecommendations = function (payload) { return ({
    type: Actions.SyncActions.SET_MKE_RECOMMENDATIONS,
    payload: payload,
}); };
export var setTelemetryCustomerError = function (payload) { return ({
    type: Actions.SyncActions.SET_TELEMETRY_CUSTOMER_ERROR,
    payload: payload,
}); };

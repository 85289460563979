import * as Actions from '@redux/actions/warningBanner';
export var getWarningBanner = function () { return ({
    type: Actions.AsyncActions.GET_WARNING_BANNER,
}); };
export var setWarningBanner = function (payload) { return ({
    type: Actions.SyncActions.SET_WARNING_BANNER,
    payload: payload,
}); };
export var getWarningBannerDetails = function () { return ({
    type: Actions.AsyncActions.GET_WARNING_BANNER_DETAILS,
}); };
export var setWarningBannerDetails = function (payload) { return ({
    type: Actions.SyncActions.SET_WARNING_BANNER_DETAILS,
    payload: payload,
}); };

import React from 'react';
import { Button } from '@mirantis/mds-button';
import { signOut } from '@root/index';
import Redirect from '@commonUtils/urlUtils/Redirect';
import { AuthContext } from '@app/OAuthProvider/OAuthProvider';
import { Aside } from '@commonComponents/aside/Aside';
import ButtonWrapper from '@commonComponents/button-wrapper/ButtonWrapper';
import ErrorBanner from '@commonComponents/error-baner/ErrorBanner';
import NonSignedInContent from '@commonComponents/non-signed-in-content/NonSignedInContent';
export var ForbiddenAccess = function () {
    var useAuth = React.useContext(AuthContext);
    var authenticated = useAuth.isSignedIn();
    if (authenticated) {
        Redirect.toMain();
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Aside, null),
        React.createElement(NonSignedInContent, null,
            React.createElement(ErrorBanner, { title: "Unsuccessful access", message: "You have no permissions to access Mirantis Native Cloud Console. Please contact your administrator." },
                React.createElement(ButtonWrapper, { justify: "center" },
                    React.createElement(Button, { label: "Logout", onClick: signOut }))))));
};

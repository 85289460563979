export var SELECTED_CLUSTER_MAX_LIMIT_THRESHOLD_MESSAGE = 'More then 20 clusters takes for a long time to generate pdf';
export var BLURED_COLUMNS_KEYS = [
    'mkeVersion',
    'mcrVersion',
    'msrVersion',
    'healthStatus',
    'healthCriticalCount',
    'healthWarningCount',
    'healthInfoCount',
];

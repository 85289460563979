var _a;
import { DASHBOARD_FAMILIES, DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { DateUtils } from '@utils/date/DateUtils';
import { DATE_RANGES, STATIC_REPORTS_DATE_RANGES } from '@utils/date/dateConstants';
import { GRANULARITIES } from '@commonConstants/granularityConstants';
var DASHBOARDS = DASHBOARDS_SCHEMA.DASHBOARDS, REPORTS = DASHBOARDS_SCHEMA.REPORTS, SUPPORT = DASHBOARDS_SCHEMA.SUPPORT, MKE_DASHBOARDS = DASHBOARDS_SCHEMA.MKE_DASHBOARDS;
export var defaultDateRanges = [
    DATE_RANGES.LAST_MONTH,
    DATE_RANGES.MONTH_TO_DATE,
    DATE_RANGES.LAST_WEEK,
    DATE_RANGES.THIS_WEEK,
    DATE_RANGES.YESTERDAY,
    DATE_RANGES.TODAY,
    DATE_RANGES.CUSTOM_RANGE,
];
export var staticReportsDateRanges = [
    STATIC_REPORTS_DATE_RANGES.PREVIOUS_YEAR,
    STATIC_REPORTS_DATE_RANGES.PREVIOUS_6_MONTHS,
    STATIC_REPORTS_DATE_RANGES.PREVIOUS_3_MONTHS,
    DATE_RANGES.LAST_MONTH,
    DATE_RANGES.MONTH_TO_DATE,
    DATE_RANGES.LAST_WEEK,
    DATE_RANGES.THIS_WEEK,
    DATE_RANGES.CUSTOM_RANGE,
];
export var staticReportsTelemetryDateRanges = [
    DATE_RANGES.PREVIOUS_TWO_MONTHS,
    DATE_RANGES.PREVIOUS_TWO_WEEKS,
    DATE_RANGES.CUSTOM_RANGE,
];
export var defaultGranularity = [
    {
        pseudo: GRANULARITIES.ONE_WEEK,
        value: '1 week',
    },
    {
        pseudo: GRANULARITIES.ONE_DAY,
        value: '1 day',
    },
    {
        pseudo: GRANULARITIES.TWELVE_HOURS,
        value: '12 hours',
    },
    {
        pseudo: GRANULARITIES.SIX_HOURS,
        value: '6 hours',
    },
    {
        pseudo: GRANULARITIES.THREE_HOURS,
        value: '3 hours',
    },
    {
        pseudo: GRANULARITIES.ONE_HOUR,
        value: '1 hour',
    },
    {
        pseudo: GRANULARITIES.FIFTEEN_MINUTES,
        value: '15 minutes',
    },
];
export var staticReportsGranularity = [
    {
        pseudo: GRANULARITIES.ONE_MONTH,
        value: '30 days',
    },
    {
        pseudo: GRANULARITIES.ONE_WEEK,
        value: '1 week',
    },
    {
        pseudo: GRANULARITIES.ONE_DAY,
        value: '1 day',
    },
];
export var CROSS_PAGE_FILTER_CONFIG = (_a = {},
    _a[DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE.PSEUDO_CC] = {
        dashboardName: DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE.PSEUDO_CC,
        dashboardFamily: DASHBOARD_FAMILIES.MCC_REPORTS,
        granularity: {
            selected: defaultGranularity[2],
            options: defaultGranularity,
        },
        dateRange: {
            selected: defaultDateRanges[3],
            options: defaultDateRanges,
            calculatedDateRange: DateUtils.calculateDateRangeByOption(defaultDateRanges[3]),
        },
    },
    _a[DASHBOARDS.PAGES.OPENSTACK_USAGE.TABS.OPENSTACK_OVERALL_USAGE.PSEUDO_CC] = {
        dashboardName: DASHBOARDS.PAGES.OPENSTACK_USAGE.TABS.OPENSTACK_OVERALL_USAGE.PSEUDO_CC,
        dashboardFamily: DASHBOARD_FAMILIES.MCC_REPORTS,
        granularity: {
            selected: defaultGranularity[2],
            options: defaultGranularity,
        },
        dateRange: {
            selected: defaultDateRanges[3],
            options: defaultDateRanges,
            calculatedDateRange: DateUtils.calculateDateRangeByOption(defaultDateRanges[3]),
        },
    },
    _a[DASHBOARDS.PAGES.BOOTSTRAP.PSEUDO_CC] = {
        dashboardName: DASHBOARDS.PAGES.BOOTSTRAP.PSEUDO_CC,
        dashboardFamily: DASHBOARD_FAMILIES.MCC_REPORTS,
        dateRange: {
            selected: defaultDateRanges[3],
            options: defaultDateRanges,
            calculatedDateRange: DateUtils.calculateDateRangeByOption(defaultDateRanges[3]),
        },
    },
    _a[SUPPORT.PAGES.ALERTS.PSEUDO_CC] = {
        dashboardName: SUPPORT.PAGES.ALERTS.PSEUDO_CC,
        dashboardFamily: DASHBOARD_FAMILIES.MCC_REPORTS,
        dateRange: {
            selected: defaultDateRanges[3],
            options: defaultDateRanges,
            calculatedDateRange: DateUtils.calculateDateRangeByOption(defaultDateRanges[3]),
        },
    },
    _a[REPORTS.PAGES.INSTALLATION_REPORT.PSEUDO_CC] = {
        dashboardName: REPORTS.PAGES.INSTALLATION_REPORT.PSEUDO_CC,
        dashboardFamily: DASHBOARD_FAMILIES.STATIC_REPORTS,
        granularity: {
            selected: staticReportsGranularity[0],
            options: staticReportsGranularity,
        },
        dateRange: {
            selected: staticReportsDateRanges[2],
            options: staticReportsDateRanges,
            calculatedDateRange: DateUtils.calculateDateRangeByOption(staticReportsDateRanges[2]),
        },
    },
    _a[REPORTS.PAGES.NEW_SIGN_UPS_REPORT.PSEUDO_CC] = {
        dashboardName: REPORTS.PAGES.NEW_SIGN_UPS_REPORT.PSEUDO_CC,
        dashboardFamily: DASHBOARD_FAMILIES.STATIC_REPORTS,
        granularity: {
            selected: staticReportsGranularity[0],
            options: staticReportsGranularity,
        },
        dateRange: {
            selected: staticReportsDateRanges[2],
            options: staticReportsDateRanges,
            calculatedDateRange: DateUtils.calculateDateRangeByOption(staticReportsDateRanges[2]),
        },
    },
    _a[MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.PSEUDO_CC] = {
        dashboardName: MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.PSEUDO_CC,
        dashboardFamily: DASHBOARD_FAMILIES.MCC_REPORTS,
        dateRange: {
            selected: staticReportsTelemetryDateRanges[2],
            options: staticReportsTelemetryDateRanges,
            calculatedDateRange: DateUtils.calculateDateRangeByOption(staticReportsTelemetryDateRanges[2]),
        },
    },
    _a[REPORTS.PAGES.ACTIVE_INSTANCES.PSEUDO_CC] = {
        dashboardName: REPORTS.PAGES.ACTIVE_INSTANCES.PSEUDO_CC,
        dashboardFamily: DASHBOARD_FAMILIES.STATIC_REPORTS,
        granularity: {
            selected: staticReportsGranularity[0],
            options: staticReportsGranularity,
        },
        dateRange: {
            selected: staticReportsDateRanges[2],
            options: staticReportsDateRanges,
            calculatedDateRange: DateUtils.calculateDateRangeByOption(staticReportsDateRanges[2]),
        },
    },
    _a[REPORTS.PAGES.TRIAL_REPORT.PSEUDO_CC] = {
        dashboardName: REPORTS.PAGES.TRIAL_REPORT.PSEUDO_CC,
        dashboardFamily: DASHBOARD_FAMILIES.STATIC_REPORTS,
        granularity: {
            selected: staticReportsGranularity[0],
            options: staticReportsGranularity,
        },
        dateRange: {
            selected: staticReportsDateRanges[2],
            options: staticReportsDateRanges,
            calculatedDateRange: DateUtils.calculateDateRangeByOption(staticReportsDateRanges[2]),
        },
    },
    _a[REPORTS.PAGES.TRIAL_STATISTIC.TABS.SIGN_UPS] = {
        dashboardName: REPORTS.PAGES.TRIAL_STATISTIC.TABS.SIGN_UPS,
        dashboardFamily: DASHBOARD_FAMILIES.STATIC_REPORTS,
        granularity: {
            selected: staticReportsGranularity[0],
            options: staticReportsGranularity,
        },
        dateRange: {
            selected: staticReportsDateRanges[2],
            options: staticReportsDateRanges,
            calculatedDateRange: DateUtils.calculateDateRangeByOption(staticReportsDateRanges[2]),
        },
    },
    _a[REPORTS.PAGES.TRIAL_STATISTIC.TABS.OCCUPIED_SLOTS] = {
        dashboardName: REPORTS.PAGES.TRIAL_STATISTIC.TABS.OCCUPIED_SLOTS,
        dashboardFamily: DASHBOARD_FAMILIES.STATIC_REPORTS,
        granularity: {
            selected: staticReportsGranularity[0],
            options: staticReportsGranularity,
        },
        dateRange: {
            selected: staticReportsDateRanges[2],
            options: staticReportsDateRanges,
            calculatedDateRange: DateUtils.calculateDateRangeByOption(staticReportsDateRanges[2]),
        },
    },
    _a);

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Input } from '@mirantis/mds-input';
import { fieldStates } from '@mirantis/mds-theme';
import { FieldLevelValidation } from '@utils/field-level-validation/FieldLevelValidation';
var renderField = function (_a) {
    var input = _a.input, meta = _a.meta, label = _a.label;
    return (React.createElement(Input, { showHelp: false, label: label, name: input.name, value: input.value, onChange: function (e) { return input.onChange(e.target.value); }, error: "Validation didn't passed", state: meta.valid || meta.pristine ? fieldStates.ENABLED : fieldStates.ERROR }));
};
var AddNewUserForm = function (props) {
    var handleSubmit = props.handleSubmit, valid = props.valid, submitting = props.submitting, pristine = props.pristine, setDisabled = props.setDisabled, disabled = props.disabled;
    var isDisabled = !valid || submitting || pristine;
    if (disabled !== isDisabled) {
        setTimeout(function () {
            setDisabled(isDisabled);
        }, 100);
    }
    return (React.createElement("form", { onSubmit: handleSubmit, className: "add-new-user__form form" },
        React.createElement(Field, { label: "Full Name", name: "full_name", validate: [FieldLevelValidation.required, FieldLevelValidation.noSpecSymbols], component: renderField }),
        React.createElement(Field, { label: "Email", name: "email", validate: [FieldLevelValidation.required, FieldLevelValidation.email], component: renderField })));
};
export default reduxForm({
    form: 'add-new-user',
})(AddNewUserForm);

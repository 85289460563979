var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classNames from 'classnames';
import { StyledRadioButton } from './styled';
var RadioButton = function (_a) {
    var checked = _a.checked, label = _a.label, pseudo = _a.pseudo, onChange = _a.onChange, _b = _a.prefix, prefix = _b === void 0 ? '' : _b, dataAttributes = _a.dataAttributes;
    var id = "radio-".concat(pseudo);
    var className = classNames({
        checked: checked,
    });
    return (React.createElement(StyledRadioButton, __assign({ className: "radio-button ".concat(prefix) }, dataAttributes),
        React.createElement("input", __assign({ id: id, "data-testid": "radio", type: "radio", name: "radio", defaultChecked: checked, className: className, onClick: function () { return onChange(pseudo); } }, dataAttributes)),
        React.createElement("label", __assign({ htmlFor: "radio" }, dataAttributes), label)));
};
export default RadioButton;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { getClusterSpecs } from '@root/app/redux/dispatchers/telemetry';
import { Button } from '@mirantis/mds-button';
import styled from '@emotion/styled';
import { getFormattedJSON } from './downloadClusterSpecsUtils';
import EmptyComponent from '@commonComponents/skeletons/empty/EmptyComponent';
import { downloadFile } from '@commonUtils/commonUtils';
export var StyledClusterSpecs = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    z-index: 1;\n    background-color: #fff;\n    position: relative;\n\n    button {\n        right: 0;\n        position: absolute;\n    }\n"], ["\n    z-index: 1;\n    background-color: #fff;\n    position: relative;\n\n    button {\n        right: 0;\n        position: absolute;\n    }\n"])));
var ClusterSpecs = function (_a) {
    var clusterId = _a.clusterId;
    var data = useSelector(function (store) { return store.telemetry.clusterSpecs; });
    useEffect(function () {
        getClusterSpecs({ clusterIds: clusterId });
    }, []);
    if (isEmpty(data)) {
        return (React.createElement(StyledClusterSpecs, null,
            React.createElement(EmptyComponent, { paddingTop: 10, description: 'No Cluster Specs' })));
    }
    var formattedClusterSpecsJSON = getFormattedJSON(data);
    function downloadSpecJSON() {
        downloadFile(formattedClusterSpecsJSON, 'specs_cluster_id_' + clusterId + '.json', 'text/json');
    }
    return (React.createElement(StyledClusterSpecs, { className: "cluster-specs" },
        React.createElement(Button, { className: "download-cluster-specs", label: "Download", onClick: downloadSpecJSON }),
        React.createElement("pre", { style: { overflowY: 'hidden' } }, formattedClusterSpecsJSON)));
};
export default ClusterSpecs;
var templateObject_1;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect, memo } from 'react';
import DrawerComponent from '@app/common/react/components/drawer/Drawer';
import GroupTableWrapper from './GroupTableWrapper';
import { StyledTableWithDetailView } from './TelemetryClustersTableWithDetailViewStyled';
import { TELEMETRY_CLUSTERS_PLACEMENT } from './telemetryClustersTableWithDetailViewConstants';
var TelemetryClustersTableWithDetailView = function (props) {
    var _a = useState(false), visible = _a[0], setVisibleDrawer = _a[1];
    var showDrawer = function () {
        setVisibleDrawer(true);
    };
    var onCloseDrawer = function () {
        setVisibleDrawer(false);
        props.setActiveClusterId(null);
    };
    useEffect(function () {
        if (props.expanded) {
            showDrawer();
        }
    }, [props.expanded]);
    useEffect(function () {
        if (props.expanded) {
            showDrawer();
        }
    }, [props.expanded]);
    return (React.createElement(StyledTableWithDetailView, null,
        React.createElement(GroupTableWrapper, __assign({}, props)),
        React.createElement(DrawerComponent, { currentPlacement: TELEMETRY_CLUSTERS_PLACEMENT, onClose: onCloseDrawer, visible: visible }, props.children)));
};
TelemetryClustersTableWithDetailView.defaultProps = {
    cellOnCLick: function () { return null; },
    setActiveClusterId: function () { return null; },
};
export default memo(TelemetryClustersTableWithDetailView);

import React from 'react';
import CustomerTypesFilterContainer from '@commonComponents/customer-type-filter/CustomerTypesFilterContainer';
import ClusterHealthFilter from './ClusterHealthFilter';
import ClusterHealthTableContainer from './cluster-health-table/ClusterHealthTableContainer';
var ClusterHealth = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", null, "Cluster Health"),
        React.createElement(CustomerTypesFilterContainer, null),
        React.createElement(ClusterHealthFilter, null),
        React.createElement(ClusterHealthTableContainer, null)));
};
export default ClusterHealth;

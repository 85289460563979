export var getNormalizedUserInfo = function (user) {
    var _a;
    return ({
        id: user.id,
        email: user.email,
        exp: user.exp,
        fullName: user.full_name,
        lastSignInTime: user.last_sign_in_time,
        preferences: {
            timezone: (_a = user.preferences) === null || _a === void 0 ? void 0 : _a.timezone,
        },
        permissionGroups: user.groups,
        signInId: user.sign_in_id,
        firstLogin: false,
    });
};

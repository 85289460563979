import React, { useEffect, useState } from 'react';
import { Modal, sizes } from '@mirantis/mds-modal';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { setPermissionsGroup } from '@redux/dispatchers/permissions';
import { dropPaginationToDefault } from '@redux/dispatchers/pagination';
import CreateEditPermissionGroup from './CreateEditPermissionGroup';
import { isEnabled } from './CreateEditPermissionGroupUtils';
import { getGroupIsEditableById } from '../permissionGroupsUtils';
var CreateEditPermissionGroupModal = function (props) {
    var title = props.title, children = props.children, setRef = props.setRef, onSaveApiCall = props.onSaveApiCall, initialGroupSchemaApiCall = props.initialGroupSchemaApiCall, _a = props.onOpen, onOpen = _a === void 0 ? function () { return null; } : _a;
    var _b = useState(false), opened = _b[0], setOpened = _b[1];
    var _c = useState([]), newDashboardSchema = _c[0], setDashboardSchema = _c[1];
    var _d = useState(''), groupName = _d[0], setGroupName = _d[1];
    var defaultDashboardSchema = useSelector(function (store) { return store.permissions.permissionGroup.dashboards; });
    var defaultGroupName = useSelector(function (store) { return store.permissions.permissionGroup.name; });
    var activeGroupId = useSelector(function (store) { return store.permissions.activeGroupId; });
    var allPermissionGroups = useSelector(function (store) { return store.permissions.allPermissionGroups.data; });
    var isEditable = getGroupIsEditableById(allPermissionGroups, activeGroupId);
    useEffect(function () {
        setDashboardSchema(cloneDeep(defaultDashboardSchema));
    }, [defaultDashboardSchema]);
    useEffect(function () {
        setGroupName(defaultGroupName);
    }, [defaultGroupName]);
    var handleOnSave = function () {
        dropPaginationToDefault();
        setGroupName('');
        onSaveApiCall({ groupName: groupName, newDashboardSchema: newDashboardSchema, groupId: activeGroupId });
        setOpened(false);
        onOpen(false);
    };
    var handleOnClose = function () {
        setPermissionsGroup([]);
        setGroupName('');
        setDashboardSchema([]);
        setOpened(false);
        onOpen(false);
    };
    // isEditable === undefined when we create new group
    var enabled = (isEditable === undefined || isEditable === true) &&
        isEnabled(groupName) &&
        (!isEqual(groupName, defaultGroupName) || !isEqual(newDashboardSchema, defaultDashboardSchema));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "children", onClick: function () { return setOpened(true); } }, children),
        opened && (React.createElement("div", { className: "create-edit-permission-group" },
            React.createElement(Modal, { id: "create-edit-permission-group-modal", ref: setRef, zIndex: 1000, size: sizes.X_LARGE, onClose: handleOnClose, title: title, className: "manage-permission-group-modal", callAction: {
                    label: 'Save Group',
                    onClick: handleOnSave,
                    enabled: enabled,
                }, cancelAction: {
                    onClick: handleOnClose,
                } },
                React.createElement(CreateEditPermissionGroup, { groupName: groupName, groupId: activeGroupId, setGroupName: setGroupName, newDashboardSchema: newDashboardSchema, setDashboardSchema: setDashboardSchema, initialGroupSchemaApiCall: initialGroupSchemaApiCall }))))));
};
export default CreateEditPermissionGroupModal;

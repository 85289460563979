import Home from '@components/home';
import UsageOverview from '@components/usage-overview';
import OpenstackUsage from '@components/openstack-usage/OpenstackUsage';
import OpenstackClusterUsage from '@components/openstack-cluster-usage';
import Alerts from '@components/alerts';
import { ManageUsers } from '@components/manage-users';
import ManageCustomersContainer from '@components/manage-customers/ManageCustomersContainer';
import ClusterHealth from '@components/cluster-health';
import Bootstrap from '@components/bootstrap';
import InstallationReport from '@components/instalation-report';
import NewSignUps from '@components/new-sign-ups';
import ActiveInstances from '@components/activeInstances';
import TrialReport from '@components/trialReport/TrialReport';
import TrialStatistic from '@components/trialStatistic/TrialStatistic';
import PermissionGroups from '@components/permissionGroups';
import telemetryContainerNew from '@components/telemetry-dashboard/telemetryMccMke/telemetryContainer';
import SearchClusters from '@root/app/components/search-clusters/SearchClusters';
export var dashboards = {
    Home: Home,
    UsageOverview: UsageOverview,
    OpenstackUsage: OpenstackUsage,
    OpenstackClusterUsage: OpenstackClusterUsage,
    Alerts: Alerts,
    ManageUsers: ManageUsers,
    ManageCustomersContainer: ManageCustomersContainer,
    ClusterHealth: ClusterHealth,
    Bootstrap: Bootstrap,
    InstallationReport: InstallationReport,
    NewSignUps: NewSignUps,
    ActiveInstances: ActiveInstances,
    TrialReport: TrialReport,
    TrialStatistic: TrialStatistic,
    telemetryContainerNew: telemetryContainerNew,
    PermissionGroups: PermissionGroups,
    SearchClusters: SearchClusters,
};

import get from 'lodash/get';
import { X_AXIS_VALUE_FORMAT, MEMORY_UNITS } from '@commonConstants/chartsConstants';
import { DateUtils } from '@utils/date/DateUtils';
export function formatGB(x) {
    var l = 0, n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
        n = n / 1024;
    }
    return {
        value: n.toFixed(n < 10 && l > 0 ? 1 : 0),
        unit: MEMORY_UNITS[l],
    };
}
export var getNormalizedABSTooltipValue = function (_a) {
    var formattedValue = _a.formattedValue, label = _a.dataset.label;
    var formattedValueString = formattedValue.toString();
    var commaIndex = formattedValueString.indexOf(',');
    return "".concat(label, " ").concat(formattedValueString.substring(0, commaIndex > 0 ? commaIndex + 3 : Number.MAX_SAFE_INTEGER));
};
export var getNormalizedPercentageTooltipValue = function (args) {
    return "".concat(getNormalizedABSTooltipValue(args), "%");
};
export var getDynamicValueUnit = function (dataset) {
    var normalizedValue = Math.max.apply(Math, dataset);
    var unit = formatGB(normalizedValue).unit;
    return unit;
};
export var getNormalizedABSYValue = function (value) { return value.toLocaleString(); };
export var getNormalizedMoneyYValue = function (value) { return "$ ".concat(value.toFixed(2)); };
function convertNumber(num, locale) {
    if (locale === void 0) { locale = 'en-US'; }
    var format = new Intl.NumberFormat(locale).format;
    var _a = /^0(.)1$/.exec(format(0.1)), decimalSign = _a[1];
    return +num.replace(new RegExp("[^".concat(decimalSign, "\\d]"), 'g'), '').replace(decimalSign, '.');
}
export var getNormalizedMoneyTooltipValue = function (_a) {
    var formattedValue = _a.formattedValue, label = _a.dataset.label;
    return "".concat(label, ": $ ").concat(convertNumber(formattedValue).toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }));
};
export var getNormalizedPercentageValue = function (toFixed) {
    if (toFixed === void 0) { toFixed = 1; }
    return function (value) {
        return "".concat(value.toFixed(toFixed), "%");
    };
};
export var getNormalizedConvertedInPercentageValue = function (toFixed) {
    if (toFixed === void 0) { toFixed = 1; }
    return function (value) {
        var VALUE = value.toFixed(toFixed);
        return "".concat(VALUE > 100 ? 100 : VALUE, "%");
    };
};
export var getNormalizedChartData = function (value) {
    if (value === void 0) { value = []; }
    return value.map(function (_a) {
        var MetricTime = _a.MetricTime, Value = _a.Value;
        return ({
            x: MetricTime,
            y: Value || null,
        });
    });
};
export var getNormalizedChartDataDynamicKey = function (value, key) {
    if (value === void 0) { value = []; }
    return Object.values(value).map(function (item) { return ({
        x: item.metric_time,
        y: get(item, key, null) || null,
    }); });
};
export var getNormalizedChartDataInPercentageConverted = function (value, key, xKey) {
    if (value === void 0) { value = []; }
    if (xKey === void 0) { xKey = 'metric_time'; }
    return Object.values(value).map(function (item) { return ({
        x: item[xKey],
        y: get(item, key, null) * 100 || null,
    }); });
};
export var getNormalizedTimeBarChartData = function (value) {
    if (value === void 0) { value = []; }
    return value.map(function (_a) {
        var MetricTime = _a.MetricTime, Value = _a.Value;
        return ({
            x: MetricTime,
            y: Value || null,
        });
    });
};
export var getNormalizedServiceBarChartData = function (value) {
    if (value === void 0) { value = []; }
    return value.map(function (_a) {
        var Service = _a.Service, Value = _a.Value;
        return ({
            x: Service,
            y: Value || null,
        });
    });
};
export var getNormalizedDynamicGbYAxisData = function (value) { return "".concat(formatGB(value).value); };
export var getNormalizedDynamicGbTooltipValue = function (_a) {
    var formattedValue = _a.formattedValue;
    var normalizedValue = formattedValue
        .toString()
        .split('')
        .filter(function (symb) { return symb !== ','; })
        .join('');
    var _b = formatGB(normalizedValue), value = _b.value, unit = _b.unit;
    return "".concat(value, " ").concat(unit);
};
export var getChartOptions = function (_a) {
    var getMin = _a.getMin, getMax = _a.getMax, getData = _a.getData, stackedY = _a.stackedY, stackedX = _a.stackedX, getNormalizedYValue = _a.getNormalizedYValue, maxXAxisTicksLimit = _a.maxXAxisTicksLimit, afterTitle = _a.afterTitle, afterBody = _a.afterBody, getNormalizedTooltipValue = _a.getNormalizedTooltipValue, yValue = _a.yValue;
    return ({
        getMin: getMin,
        getMax: getMax,
        getData: getData,
        stackedY: stackedY,
        stackedX: stackedX,
        afterTitle: afterTitle,
        afterBody: afterBody,
        maxXAxisTicksLimit: maxXAxisTicksLimit,
        xFormatFunction: function (value) { return DateUtils.getDateLocalStringAutoTimezoneHandler(value, X_AXIS_VALUE_FORMAT); },
        yFormatFunction: function (value) { return getNormalizedYValue(value); },
        yValue: yValue,
        tooltipsCallback: {
            afterTitle: afterTitle,
            afterBody: afterBody,
            title: function (title) { return title[0].label; },
            label: getNormalizedTooltipValue,
        },
    });
};
export var afterTitleMoney = function (context) {
    var sumAmount = context.reduce(function (result, _a) {
        var raw = _a.raw;
        result += raw;
        return result;
    }, 0);
    return "Sum: $ ".concat(sumAmount.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }));
};

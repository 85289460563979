import { store } from '@redux/store';
import * as actionCreators from '@redux/action-creators/userInfo';
export var setUserInfo = function (payload) {
    var action = actionCreators.setUserInfo(payload);
    store.dispatch(action);
};
export var setUserPreferences = function (payload) {
    var action = actionCreators.setUserPreferences(payload);
    store.dispatch(action);
};

export var getNormalizedClusters = function (response) {
    var data = response.data.map(function (cluster) { return ({
        id: cluster.id,
        name: cluster.name,
        instanceId: cluster.instance_id,
        namespace: cluster.namespace,
        customerName: cluster.customer_name,
        prefix: cluster.prefix,
        version: cluster.version,
        instanceName: cluster.instance_name,
        clusterRelease: cluster.cluster_release,
        clusterType: cluster.cluster_type,
        customerId: cluster.customer_id,
        k8sVersion: cluster.k8s_version,
        managementId: cluster.management_id,
        mccVersion: cluster.mcc_version,
        mkeVersion: cluster.mke_version,
        provider: cluster.provider,
        regionId: cluster.region_id,
        clusterLastSyncDate: cluster.cluster_last_sync_date,
        instanceLastSyncDate: cluster.instance_last_sync_date,
    }); });
    return {
        data: data,
        currentPage: response.current_page,
        maxPages: response.max_pages,
    };
};
export var getNormalizedPatchClusters = function (response) {
    return {
        annotation: response.annotation,
        id: response.id,
    };
};
export var getNormalizedPatchClusterName = function (response) {
    return {
        id: response.id,
        name: response.name,
    };
};
export var getNormalizedClusterHealth = function (response) {
    var data = response.data.map(function (dataItem) { return ({
        healthStatus: dataItem.health_status,
        clusterId: dataItem.cluster_id,
        managementId: dataItem.management_id,
        regionId: dataItem.region_id,
        prefix: dataItem.prefix,
        clusterName: dataItem.cluster_name,
        customerName: dataItem.customer_name,
        annotation: dataItem.annotation,
        lastSync: dataItem.last_sync,
        alertsTotal: dataItem.alerts_total,
    }); });
    return {
        data: data,
        maxPages: response.max_pages,
        currentPage: response.current_page,
    };
};

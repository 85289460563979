var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import get from 'lodash/get';
import without from 'lodash/without';
import queryString from 'query-string';
import { STATES } from '@commonComponents/generate-pdf-button/generatePdfConstants';
import { generatePdfWrapper } from '@components/telemetry-dashboard/telemetryMccMke/customer/telemetry-customer/pdf-generation/pdfGeneration';
import Redirect from '@root/app/common/utils/urlUtils/Redirect';
import { URL_QUERIES } from '@root/app/common/constants/urlConstants';
var IS_ACTIVE_CLUSTERS = URL_QUERIES.DASHBOARDS.IS_ACTIVE_CLUSTERS;
export var handlePdfGeneratingModeChanged = function (pdfGeneratingMode, generatePdfClusterIdsRef, setPdfGeneratingMode, setGeneratePdfClusterIds, setTableKeys) {
    if (pdfGeneratingMode === STATES.GENERATING_IN_PROGRESS) {
        generatePdfWrapper(generatePdfClusterIdsRef.current, setPdfGeneratingMode);
        generatePdfClusterIdsRef.current = [];
        setGeneratePdfClusterIds([]);
    }
    else if (pdfGeneratingMode === STATES.START_SELECTING) {
        generatePdfClusterIdsRef.current = [];
        setGeneratePdfClusterIds([]);
        setTableKeys(["mcc_table".concat(Math.random()), "mke_table".concat(Math.random())]);
    }
};
export var handlePdfGeneratingClusterOnchange = function (id, generatePdfClusterIdsRef, setGeneratePdfClusterIds) {
    if (generatePdfClusterIdsRef.current.includes(id)) {
        generatePdfClusterIdsRef.current = without(generatePdfClusterIdsRef.current, id);
        setGeneratePdfClusterIds(generatePdfClusterIdsRef.current);
    }
    else {
        generatePdfClusterIdsRef.current = __spreadArray(__spreadArray([], generatePdfClusterIdsRef.current, true), [id], false);
        setGeneratePdfClusterIds(generatePdfClusterIdsRef.current);
    }
};
export var setCheckedIsActiveClustersToUrl = function (value) {
    return Redirect.toCurrentPageWithQueryParams(IS_ACTIVE_CLUSTERS, [value], '');
};
export var deleteCheckedIsActiveClustersInUrl = function () { return Redirect.toCurrentPageWithoutQueryParams([IS_ACTIVE_CLUSTERS]); };
export var getCheckedIsActiveClustersFromUrl = function () {
    return get(queryString.parse(window.location.search), IS_ACTIVE_CLUSTERS, null);
};

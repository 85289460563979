import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import queryString from 'query-string';
import history from '@history/history';
import { useHistory, usePrevious } from '@app/hooks';
import { resetTelemetryMkeCustomer } from '@redux/dispatchers/telemetry';
import { isSFPermissionGroup } from '@commonUtils/commonUtils';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import TelemetryCustomerWrapper from './customer/telemetry-customer/TelemetryCustomerWrapper';
import TelemetryCustomers from './customers/TelemetryCustomers';
var ACCOUNT_ID = URL_QUERIES.COMMON.ACCOUNT_ID;
var TELEMETRY_MANAGE_CLUSTER_LIST = URL_QUERIES.TELEMETRY.TELEMETRY_MANAGE_CLUSTER_LIST;
export var TelemetryDashboard = function (props) {
    var customer = props.customer;
    var localHistory = useHistory();
    var accountId = get(queryString.parse(localHistory.location.search), ACCOUNT_ID);
    var manageClusterListPage = get(queryString.parse(localHistory.location.search), TELEMETRY_MANAGE_CLUSTER_LIST);
    var isSFUser = isSFPermissionGroup();
    var prevAccountId = usePrevious(accountId);
    var prevPathname = useRef(null);
    var moveToPreviousPageWrapper = function () { return history.push(prevPathname.current); };
    useEffect(function () {
        if (!isSFUser)
            return;
        prevPathname.current = history.location.pathname + history.location.search;
        window.addEventListener('popstate', moveToPreviousPageWrapper);
        return function () {
            window.removeEventListener('popstate', moveToPreviousPageWrapper, true);
        };
    }, []);
    useEffect(function () {
        if (!accountId) {
            resetTelemetryMkeCustomer();
        }
    }, [accountId]);
    return accountId ? (React.createElement(TelemetryCustomerWrapper, { customer: customer, accountId: accountId, manageClusterListPage: manageClusterListPage })) : (React.createElement(TelemetryCustomers, { prevAccountId: prevAccountId }));
};
var mapState = function (state) { return ({
    mode: state.mode,
    customer: state.telemetry.customer,
}); };
var connector = connect(mapState, null);
export default connector(TelemetryDashboard);

export var NO_ACTIVE_USER_GROUPS_FOUND = 'No Active User Groups found';
export var SEARCH_LABEL = 'User groups search';
export var USER_GROUPS_TITLE = 'Permission Groups';
export var SEARCH_DELAY = 1500;
var enumSort = "desc";
export var INITIAL_STATE = {
    sortBy: 'created_at',
    orderBy: enumSort,
};
export var COLUMNS_KEYS_USERS_GROUP = {
    ID: 'id',
    SEQUENTIAL_NUMBER: 'sequentialNumber',
    NAME: 'name',
    USERS_COUNT: 'usersCount',
    CREATED_AT: 'createdAt',
};
export var COLUMNS_USERS_GROUP = [
    {
        name: 'Group name',
        key: COLUMNS_KEYS_USERS_GROUP.NAME,
        sortByKey: 'name',
        isVisibleByDefault: true,
    },
    {
        name: 'Users count',
        key: COLUMNS_KEYS_USERS_GROUP.USERS_COUNT,
        sortByKey: 'users_count',
        isVisibleByDefault: true,
    },
    {
        name: 'Created At',
        key: COLUMNS_KEYS_USERS_GROUP.CREATED_AT,
        sortByKey: 'created_at',
        isVisibleByDefault: true,
    },
];
export var DASHBOARDS_LABElS = {
    home: 'Home',
    account_management: 'Account Management',
    manage_users: 'Manage Users',
    manage_customers: 'Manage Customers',
    permission_group: 'Permission groups',
    container_cloud_usage: 'Container Cloud Usage',
    openstack_usage: 'Open Stack Usage',
    openstack_cluster_usage: 'Openstack Cluster Usage',
    bootstrap_dashboard: 'Bootstrap Dashboard',
    container_cloud_reports: 'Reports',
    container_cloud_dashboards: 'Dashboards',
    installation_report: 'Installation Report',
    new_sign_ups: 'New Sign-Ups',
    active_instances: 'Active Instances',
    trial_report: 'Trial Report',
    trial_costs: 'Trial Statistic',
    support: 'Support',
    alerts: 'Alerts',
    cluster_health: 'Cluster Health',
    general_search_dashboard: 'Search Clusters',
    telemetry_dashboard: 'Telemetry Dashboard',
    mcc_dashboards: 'Telemetry Dashboard',
};

import * as Actions from '@redux/actions';
export var initFilter = function (payload) { return ({
    type: Actions.multiFilter.AsyncActions.INIT_FILTER,
    payload: payload,
}); };
export var updateFilterPickers = function (payload) { return ({
    type: Actions.multiFilter.AsyncActions.UPDATE_FILTER_PICKERS,
    payload: payload,
}); };
export var setFilterData = function (payload) { return ({
    type: Actions.multiFilter.SyncActions.SET_FILTER_DATA,
    payload: payload,
}); };
export var unmountFilter = function () { return ({
    type: Actions.multiFilter.SyncActions.UNMOUNT_FILTER,
}); };
export var setSelectedPickerOptions = function (payload) { return ({
    type: Actions.multiFilter.SyncActions.SET_SELECTED_PICKER_OPTIONS,
    payload: payload,
}); };
export var setUpdatedPickersData = function (payload) { return ({
    type: Actions.multiFilter.SyncActions.SET_UPDATED_PICKERS_DATA,
    payload: payload,
}); };
export var setSearchTerm = function (payload) { return ({
    type: Actions.multiFilter.AsyncActions.SET_SEARCH_TERM,
    payload: payload,
}); };
export var changeFilterOptionsCurrentPage = function (payload) { return ({
    type: Actions.multiFilter.AsyncActions.CHANGE_FILTER_OPTION_CURRENT_PAGE,
    payload: payload,
}); };
export var updatePickerOptions = function (payload) { return ({
    type: Actions.multiFilter.SyncActions.UPDATE_PICKER_OPTIONS,
    payload: payload,
}); };
export var resetFilterPickers = function (payload) { return ({
    type: Actions.multiFilter.SyncActions.RESET_FILTER_PICKERS,
    payload: payload,
}); };
export var changeGranularity = function (payload) { return ({
    type: Actions.multiFilter.SyncActions.CHANGE_GRANULARITY,
    payload: payload,
}); };
export var changeTermV2 = function (payload) { return ({
    type: Actions.multiFilter.SyncActions.CHANGE_TERMV2,
    payload: payload,
}); };
export var pushNewPaginationOptions = function (payload) { return ({
    type: Actions.multiFilter.SyncActions.PUSH_NEW_PAGINATION_OPTIONS,
    payload: payload,
}); };
export var changeSingleSelectPickerOption = function (payload) { return ({
    type: Actions.multiFilter.SyncActions.CHANGE_SINGLE_SELECT_PICKER_OPTION,
    payload: payload,
}); };
export var dropFilterToDefault = function () { return ({
    type: Actions.multiFilter.SyncActions.DROP_FILTER_TO_DEFAULT,
}); };

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { JWT } from '@utils/JWT/JWT';
import { AuthContext } from '@app/OAuthProvider/OAuthProvider';
import { Localstorage } from '@utils/localstorage/Localstorage';
import { LOCAL_STORAGE_KEYS } from '@commonConstants/localStorageConstancts';
var StyledProfile = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex: 2;\n    display: flex;\n    align-items: center;\n    padding: 0 19px;\n    position: relative;\n\n    .profile__name {\n        font-size: 12px;\n\n        span {\n            margin-left: 10px;\n            margin-right: 10px;\n            white-space: nowrap;\n            display: block;\n            overflow: hidden;\n            text-overflow: ellipsis;\n            max-width: 135px;\n        }\n"], ["\n    flex: 2;\n    display: flex;\n    align-items: center;\n    padding: 0 19px;\n    position: relative;\n\n    .profile__name {\n        font-size: 12px;\n\n        span {\n            margin-left: 10px;\n            margin-right: 10px;\n            white-space: nowrap;\n            display: block;\n            overflow: hidden;\n            text-overflow: ellipsis;\n            max-width: 135px;\n        }\n"])));
var StyledProfileAvatar = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 35px;\n    height: 35px;\n    border-radius: 50%;\n"], ["\n    width: 35px;\n    height: 35px;\n    border-radius: 50%;\n"])));
var StyledProfileName = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size: 12px;\n\n    span {\n        margin-left: 10px;\n        margin-right: 10px;\n        white-space: nowrap;\n    }\n"], ["\n    font-size: 12px;\n\n    span {\n        margin-left: 10px;\n        margin-right: 10px;\n        white-space: nowrap;\n    }\n"])));
var Profile = function (_a) {
    var collapsed = _a.collapsed;
    var mocked_internal_token = Localstorage.get(LOCAL_STORAGE_KEYS.MOCKED_INTERNAL_TOKEN);
    var token = Localstorage.get(LOCAL_STORAGE_KEYS.TOKEN);
    var isTestingMode = Localstorage.get(LOCAL_STORAGE_KEYS.IS_TESTING_MODE) === 'true';
    var _b = React.useState(''), name = _b[0], setName = _b[1];
    var _c = React.useState(null), image = _c[0], setImage = _c[1];
    var useAuth = React.useContext(AuthContext);
    React.useEffect(function () {
        if (mocked_internal_token || isTestingMode) {
            var mockedUser = JWT.decode(mocked_internal_token || token);
            setName(mockedUser.full_name);
            setImage('https://lh3.googleusercontent.com/a/AATXAJzWVCyW1QRC46_I9bocLUz_OmTVOJKHVqc7rBxa=s96-c');
        }
        else {
            useAuth.getCurrentUser().then(function (user) {
                setName(user.name);
                setImage(user.picture);
            });
        }
    }, []);
    return (React.createElement(StyledProfile, { className: "profile" },
        image ? React.createElement(StyledProfileAvatar, { className: "profile__avatar", alt: "avatar", src: image }) : null,
        !collapsed && React.createElement(StyledProfileName, { className: "profile__name" }, React.createElement("span", null, name))));
};
export default Profile;
var templateObject_1, templateObject_2, templateObject_3;

import * as Actions from '@redux/actions';
export var getUsers = function (payload) { return ({
    type: Actions.management.AsyncActions.GET_USERS,
    payload: payload,
}); };
export var setUsers = function (payload) { return ({
    type: Actions.management.SyncActions.SET_USERS,
    payload: payload,
}); };
export var getCities = function (payload) { return ({
    type: Actions.management.AsyncActions.GET_CITIES,
    payload: payload,
}); };
export var setCities = function (payload) { return ({
    type: Actions.management.SyncActions.SET_CITIES,
    payload: payload,
}); };
export var resetCitiesLoading = function () { return ({
    type: Actions.management.SyncActions.RESET_CITIES_LOADING,
}); };
export var patchUser = function (payload) { return ({
    type: Actions.management.AsyncActions.PATCH_USER,
    payload: payload,
}); };
export var patchCustomer = function (payload) { return ({
    type: Actions.management.AsyncActions.PATCH_CUSTOMER,
    payload: payload,
}); };
export var patchInstance = function (payload) { return ({
    type: Actions.management.AsyncActions.PATCH_INSTANCE,
    payload: payload,
}); };
export var updateUser = function (payload) { return ({
    type: Actions.management.SyncActions.UPDATE_USER,
    payload: payload,
}); };
export var addNewUser = function (payload) { return ({
    type: Actions.management.AsyncActions.ADD_NEW_USER,
    payload: payload,
}); };
export var pushNewUser = function (payload) { return ({
    type: Actions.management.SyncActions.PUSH_NEW_USER,
    payload: payload,
}); };
export var getInstances = function (payload) { return ({
    type: Actions.management.AsyncActions.GET_INSTANCES,
    payload: payload,
}); };
export var setInstances = function (payload) { return ({
    type: Actions.management.SyncActions.SET_INSTANCES,
    payload: payload,
}); };
export var getCustomers = function (payload) { return ({
    type: Actions.management.AsyncActions.GET_CUSTOMERS,
    payload: payload,
}); };
export var setCustomers = function (payload) { return ({
    type: Actions.management.SyncActions.SET_CUSTOMERS,
    payload: payload,
}); };
export var updateCustomer = function (payload) { return ({
    type: Actions.management.SyncActions.UPDATE_CUSTOMER,
    payload: payload,
}); };
export var updateInstance = function (payload) { return ({
    type: Actions.management.SyncActions.UPDATE_INSTANCE,
    payload: payload,
}); };
export var setSingleCustomerMode = function (payload) { return ({
    type: Actions.management.SyncActions.SET_SINGLE_CUSTOMER_MODE,
    payload: payload,
}); };
export var getSingleCustomerInstances = function (payload) { return ({
    type: Actions.management.AsyncActions.GET_SINGLE_CUSTOMER_INSTANCES,
    payload: payload,
}); };
export var setSingleCustomerInstances = function (payload) { return ({
    type: Actions.management.SyncActions.SET_SINGLE_CUSTOMER_INSTANCES,
    payload: payload,
}); };
export var resetSingleCustomerMode = function () { return ({
    type: Actions.management.SyncActions.RESET_SINGLE_CUSTOMER_MODE,
}); };

export var CHARTS_TYPES = {
    RAM: 'ram',
    CPU: 'cpu',
    DISK: 'disk',
    NODE: 'node',
    CPUS: 'cpus',
    MEMORY: 'memory',
    NODE_COUNT: 'node_count',
    CONTAINER_COUNT: 'container_count',
    K8S_AVAILABILITY: 'k8s_availability',
    MKE_AVAILABILITY: 'mke_availability',
    CONTROLLER_COUNT: 'controller_count',
};
export var CLUSTER_AVAILABILITY_THRESHOLD = 99.9;
export var X_AXIS_VALUE_FORMAT = 'MM[/]DD HH:mm';
export var X_AXIS_VALUE_FORMAT_WITHOUT_HOER = 'MM[/]DD';
export var MEMORY_UNITS = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export var CHART_MESSAGES = {
    CPU_RATIO_CHART_MESSAGE: 'Ratio between summarised CPU time usage of all containers in the cluster ("CPU Usage Abs") and summarised CPU resources of all nodes in the cluster that are available for scheduling.',
    CPU_ABS_CHART_MESSAGE: 'Summarised CPU time usage of all containers in the cluster (a container CPU usage calculated as a rate() over 5m of container total CPU time).',
    RAM_ABS_CHART_MESSAGE: 'Summarised RAM usage of all containers in the cluster (a container RAM usage is a container working set size).',
    RAM_RATIO_CHART_MESSAGE: 'Ratio between summarised RAM usage of all containers in the cluster and summarised memory resources of all nodes in the cluster that are available for scheduling.',
    DISK_ABS_CHART_MESSAGE: 'Summarised capacity of storage requested by all persistent volume claims in the cluster.',
    CPU_USAGE_AND_CAPACITY: 'Summarised CPU time usage and CPU capacity of all containers in the cluster (a container CPU usage calculated as a rate() over 5m of container total CPU time)..',
    RAM_USAGE_AND_CAPACITY: 'Summarised RAM usage and capacity of all containers in the cluster (a container RAM usage is a container working set size).',
    PVS_USAGE_AND_CAPACITY: 'Summarised capacity of storage requested by all persistent volume claims in the cluster.',
    NODE_USAGE: 'Summarised nodes usage per cluster.',
    ACTIVE_INSTANCES_CHART_MESSAGE: 'Timeline of number of instances and clusters activity',
    INSTALLATION_CHART_MESSAGE: 'Binary executions - License-check event count Attempts - Bootstrap-start event count Successes - Bootstrap-end event with the ok status count',
};

import React from 'react';
import { connect } from 'react-redux';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { getMultiFilterPickers } from '@configs/multiFilterConfig';
import { StringUtils } from '@utils/string/StringUtils';
import { resetSearchValueInUrl } from '@commonComponents/search/searchUtils';
import FilterContainer from '@commonComponents/filter/FilterContainer';
import Search from '@commonComponents/search/Search';
import { changeTermV2 } from '@redux/dispatchers/multiFilter';
import { SEARCH_PLACEHOLDER } from '@commonConstants/commonConstants';
export var ClusterHealthFilter = function (_a) {
    var mode = _a.mode, multiFilter = _a.multiFilter;
    var name = DASHBOARDS_SCHEMA.SUPPORT.PAGES.CLUSTER_HEALTH.PSEUDO_CC;
    var selected = multiFilter.filter.selected;
    var params = {
        limit: 20,
        customer_types: mode.selected[0] === 'All' ? null : mode.selected.toString(),
        inactive_hours_threshold: mode.lastSyncDate.selected.value,
    };
    if (!multiFilter.loading && multiFilter.name === name) {
        var extendedParams = {
            customer_ids: selected.customer.length > 0 ? selected.customer.map(function (o) { return o.id; }).toString() : null,
        };
        params = Object.assign(params, extendedParams);
    }
    var onSearchChange = function (value) {
        var length = StringUtils.countStringLengthWithoutSpaces(value);
        if (value === '' || length > 2) {
            changeTermV2({
                tableName: name,
                pseudo: 'searchTerm',
                value: value,
            });
        }
    };
    var resetAdditionalParams = function () {
        resetSearchValueInUrl();
        onSearchChange('');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FilterContainer, { name: name, params: params, currentMode: mode, multiFilter: multiFilter, isOptionsHidden: false, pickers: getMultiFilterPickers(name), resetAdditionalParams: resetAdditionalParams },
            React.createElement(Search, { label: "Search", placeholder: SEARCH_PLACEHOLDER, withDelay: 1500, max: 64, onChange: onSearchChange }))));
};
var mapStateToProps = function (store) { return ({
    mode: store.mode,
    multiFilter: store.multiFilter,
}); };
export default connect(mapStateToProps)(ClusterHealthFilter);

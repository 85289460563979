var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { navBar } from '@configs/navbar';
import { routes } from '@configs/routes';
export var getRoutesWithResetOpenAndMatchFields = function (state) {
    return Object.keys(state).reduce(function (newState, route) {
        if ('opened' in newState[route]) {
            newState[route].opened = false;
        }
        return newState;
    }, __assign({}, state));
};
export var getNavItems = function (location, collapsed) {
    if (collapsed === void 0) { collapsed = false; }
    var newState = {};
    navBar.forEach(function (topRoute) {
        var match = topRoute.children
            ? location.pathname.search(topRoute.path) !== -1 && topRoute.path !== routes.MAIN.path
            : location.pathname === topRoute.path;
        newState[topRoute.pseudo] = {
            match: match,
        };
        if (!!topRoute.children && !match) {
            newState[topRoute.pseudo].opened = false;
        }
        else if (!!topRoute.children && match && !collapsed) {
            newState[topRoute.pseudo].opened = true;
        }
    });
    return newState;
};

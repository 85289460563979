import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useDebouncedEffect } from '@app/hooks';
import { getNewSignUps } from '@actionCreators/newSignUps';
import { changeCurrentPage } from '@actionCreators/pagination/pagination';
import Table from '@commonComponents/table/Table';
import PaginationContainer from '@commonComponents/pagination/PaginationContainer';
import TableLoadingStates from '@commonComponents/table-loading-states/TableLoadingStates';
import { DASHBOARDS_SCHEMA, PAGINATION_NAMES } from '@configs/DASHBOARD_PSEUDOS';
import { DateUtils } from '@utils/date/DateUtils';
import { CUSTOMER_TYPES } from '@commonConstants/customeTypeConstants';
import { COLUMNS, NO_NEW_SIGN_UPS_FOUND } from './newSignUpsConstants';
var PSEUDO_CC = DASHBOARDS_SCHEMA.REPORTS.PAGES.NEW_SIGN_UPS_REPORT.PSEUDO_CC;
var mapState = function (store) { return ({
    mode: store.mode.selected,
    filter: store.crossPageFilter,
    timezone: store.userInfo.settings.initial.timezone,
    pagination: store.pagination.newSignUps,
    newSignUps: store.newSignUps,
}); };
var mapDispatch = {
    getNewSignUps: getNewSignUps,
    changeCurrentPage: changeCurrentPage,
};
var connector = connect(mapState, mapDispatch);
var NewSignUpsTable = function (props) {
    var _a, _b;
    var mode = props.mode, filter = props.filter, timezone = props.timezone, newSignUps = props.newSignUps, pagination = props.pagination;
    var _c = useState({
        sortBy: 'created_at',
        orderBy: 'desc',
    }), state = _c[0], setState = _c[1];
    var callAPI = function () {
        var params = {
            customer_types: CUSTOMER_TYPES.FREEMIUM,
            start: filter.dateRange.calculatedDateRange.start.timeStamp,
            end: filter.dateRange.calculatedDateRange.end.timeStamp,
            timezone: timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
            page: pagination.currentPage,
            limit: pagination.limit,
            sortBy: state.sortBy,
            orderBy: state.orderBy,
        };
        props.getNewSignUps(params);
    };
    useDebouncedEffect(function () {
        if (filter.dashboardName !== PSEUDO_CC) {
            return;
        }
        callAPI();
    }, [
        state,
        mode,
        timezone,
        (_b = (_a = filter.granularity) === null || _a === void 0 ? void 0 : _a.selected) === null || _b === void 0 ? void 0 : _b.value,
        filter.dateRange.calculatedDateRange,
        pagination.currentPage,
        pagination.limit,
    ]);
    var data = {
        columns: COLUMNS,
        rows: newSignUps.data,
    };
    var className = classNames({
        'new-sign-ups-table': true,
        loading: newSignUps.loading,
    });
    var onPaginationChange = function (page) {
        props.changeCurrentPage({
            paginationName: PAGINATION_NAMES.NEW_SIGN_UPS,
            currentPage: page,
        });
    };
    var onLimitChange = function (limit) {
        props.changeCurrentPage({
            paginationName: PAGINATION_NAMES.NEW_SIGN_UPS,
            currentPage: 1,
            limit: limit,
        });
    };
    var normalizers = {
        createdAt: function (s) { return DateUtils.getDateLocalStringAutoTimezoneHandler(s); },
    };
    return (React.createElement("div", { className: className },
        React.createElement(TableLoadingStates, { loading: newSignUps.loading, data: (newSignUps === null || newSignUps === void 0 ? void 0 : newSignUps.data) || [], count: pagination.limit, empty: {
                description: NO_NEW_SIGN_UPS_FOUND,
            }, skeleton: {
                paddingBottom: 20,
                paddingTop: 20,
            } },
            React.createElement(React.Fragment, null,
                React.createElement(Table, { sortable: true, data: data, orderBy: state.orderBy, sortBy: state.sortBy, setSortableState: function (s) { return setState(s); }, normalizers: normalizers, pagination: pagination, columnControl: true }),
                React.createElement(PaginationContainer, { withLimits: true, onLimitChange: onLimitChange, pagination: pagination, onChange: onPaginationChange })))));
};
export default connector(NewSignUpsTable);

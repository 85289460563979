import * as Actions from '@redux/actions/signIn';
import { STEPS } from '@components/new-sign-ups/constants';
var r = {};
r[Actions.AsyncActions.SIGN_IN_STARTED] = function (draft) {
    draft.isSignInStarted = true;
};
r[Actions.AsyncActions.CHANGE_SIGN_IN_STEP] = function (draft) {
    var nextStep = draft.step + 1;
    draft.step = nextStep;
    draft.description = STEPS[nextStep];
};
r[Actions.SyncActions.REDIRECTING_STARTED] = function (draft) {
    draft.description = 'Redirecting...';
};
r[Actions.SyncActions.RESET_SIGN_IN] = function (draft) {
    draft.isSignInStarted = false;
    draft.step = 0;
    draft.description = STEPS[0];
};
export { r as resolvers };

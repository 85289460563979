var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, sizes } from '@mirantis/mds-modal';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import { setPermissionsGroup } from '@redux/dispatchers/permissions';
import { dropPaginationToDefault } from '@redux/dispatchers/pagination';
import { getGroupNameById, getGroupIsAssignableById } from '../permissionGroupsUtils';
import AssignUsersToPermissionGroup from '@components/permissionGroups/assign-users-to-permission-group-modal/AssignUsersToPermissionGroup';
var AssignUsersToPermissionGroupModal = function (_a) {
    var children = _a.children, onSave = _a.onSave, onOpen = _a.onOpen, setRef = _a.setRef;
    var _b = useState(false), opened = _b[0], setOpened = _b[1];
    var _c = useState([]), assignedUsersIds = _c[0], setAssignedUsersIds = _c[1];
    var _d = useState([]), staticAssignedUsersIds = _d[0], setStaticAssignedUsersIds = _d[1];
    var _e = useSelector(function (store) { return store.permissions.allUsers; }), allUsersMaxPages = _e.maxPages, allUsers = _e.data;
    var activeGroupId = useSelector(function (store) { return store.permissions.activeGroupId; });
    var allUsersStatic = useSelector(function (store) { return store.permissions.allUsersStatic; });
    var allPermissionGroups = useSelector(function (store) { return store.permissions.allPermissionGroups.data; });
    var activeGroupAssignedUsers = useSelector(function (store) { return store.permissions.activeGroupAssignedUsers; });
    var _f = useState(''), assignedUsersSearchTerm = _f[0], setAssignedUsersSearchTerm = _f[1];
    var groupName = getGroupNameById(allPermissionGroups, activeGroupId);
    var isAssignable = getGroupIsAssignableById(allPermissionGroups, activeGroupId);
    var handleOnSave = function () {
        dropPaginationToDefault();
        onSave({ newAssignedUsers: assignedUsersIds, groupId: activeGroupId });
        setOpened(false);
        onOpen(false);
    };
    var handleOnClose = function () {
        setOpened(false);
        onOpen(false);
        setAssignedUsersIds([]);
        setPermissionsGroup([]);
    };
    useEffect(function () {
        if (assignedUsersSearchTerm === '') {
            setAssignedUsersIds(activeGroupAssignedUsers.map(function (_a) {
                var id = _a.id;
                return id;
            }));
            setStaticAssignedUsersIds(activeGroupAssignedUsers.map(function (_a) {
                var id = _a.id;
                return id;
            }));
        }
        else {
            setAssignedUsersIds(activeGroupAssignedUsers.map(function (_a) {
                var id = _a.id;
                return id;
            }));
        }
    }, [activeGroupAssignedUsers]);
    useEffect(function () {
        setStaticAssignedUsersIds(uniq(__spreadArray(__spreadArray([], staticAssignedUsersIds, true), assignedUsersIds, true)));
    }, [assignedUsersIds]);
    var enabled = isAssignable &&
        !isEqual(activeGroupAssignedUsers.map(function (_a) {
            var id = _a.id;
            return id;
        }), assignedUsersIds);
    return (React.createElement("div", null,
        React.createElement("div", { className: "children", onClick: function () { return setOpened(true); } }, children),
        React.createElement("div", { className: "assign-users-to-permission-group" }, opened && (React.createElement(Modal, { id: "assign-users-modal", ref: setRef, title: "Manage permission group", size: sizes.X_LARGE, zIndex: 1000, className: "manage-permission-group-modal", onClose: handleOnClose, callAction: {
                enabled: enabled,
                label: 'Save Group',
                onClick: handleOnSave,
            }, cancelAction: {
                onClick: handleOnClose,
            } },
            React.createElement(AssignUsersToPermissionGroup, { users: allUsers, allUsersMaxPages: allUsersMaxPages, groupId: activeGroupId, groupName: groupName, allUsersStatic: allUsersStatic, assignedUsersIds: assignedUsersIds, setAssignedUsersIds: setAssignedUsersIds, assignedUsersSearchTerm: assignedUsersSearchTerm, setAssignedUsersSearchTerm: setAssignedUsersSearchTerm, staticAssignedUsersIds: staticAssignedUsersIds, setStaticAssignedUsersIds: setStaticAssignedUsersIds }))))));
};
export default AssignUsersToPermissionGroupModal;

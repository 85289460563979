import { connect } from 'react-redux';
import { initFilter, unmountFilter, setSearchTerm, resetFilterPickers, dropFilterToDefault, updateFilterPickers, setSelectedPickerOptions, changeFilterOptionsCurrentPage, changeSingleSelectPickerOption, } from '@actionCreators/multiFilter/multiFilter';
import { changeCrossPageFilterOption } from '@actionCreators/crossPageFilter';
import withDisablingWhileFetchingHOC from '@HOC/withDisablingWhileFetchingHOC/withDisablingWhileFetchingHOC';
import Filter from './Filter';
var mapDispatch = {
    initFilter: initFilter,
    unmountFilter: unmountFilter,
    setSelectedPickerOptions: setSelectedPickerOptions,
    updateFilterPickers: updateFilterPickers,
    resetFilterPickers: resetFilterPickers,
    setSearchTerm: setSearchTerm,
    changeFilterOptionsCurrentPage: changeFilterOptionsCurrentPage,
    changeSingleSelectPickerOption: changeSingleSelectPickerOption,
    dropFilterToDefault: dropFilterToDefault,
    changeCrossPageFilterOption: changeCrossPageFilterOption,
};
export default withDisablingWhileFetchingHOC(connect(null, mapDispatch)(Filter));

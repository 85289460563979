import get from 'lodash/get';
import moment from 'moment';
import { HTTP_CODES } from '@commonConstants/httpConstants';
import { signOut } from '@root/index';
import { setError } from '@redux/dispatchers/errorHandling';
import errorHandlingMessagesSwitch from './errorHandlingMessagesSwitch';
export var checkResStatus = function (response) {
    var status = get(response, 'status');
    if ([HTTP_CODES.UNAUTHORIZED].includes(status)) {
        signOut();
    }
};
export var getMessage = function (response, errorMessage) {
    var _a;
    var method = (_a = response === null || response === void 0 ? void 0 : response.config) === null || _a === void 0 ? void 0 : _a.method;
    var message = errorHandlingMessagesSwitch(response, errorMessage);
    return message ? message : "Failed to ".concat(method, " at ").concat(get(response, 'config.url', '').split('/')[1]);
};
export var getDetailInfo = function (response) {
    var _a, _b;
    return {
        method: (_a = response === null || response === void 0 ? void 0 : response.config) === null || _a === void 0 ? void 0 : _a.method,
        url: (_b = response === null || response === void 0 ? void 0 : response.config) === null || _b === void 0 ? void 0 : _b.url,
        status: response === null || response === void 0 ? void 0 : response.status,
        statusText: response === null || response === void 0 ? void 0 : response.statusText,
        message: get(response, 'data.message', null),
    };
};
export var handleErrorResponse = function (error) {
    var _a = error.response, response = _a === void 0 ? {} : _a;
    var errorObject = {
        response: response,
        name: error.name,
        timestamp: moment().valueOf(),
        message: getMessage(response, error.message),
        detailInfo: getDetailInfo(response),
    };
    setError(errorObject);
    checkResStatus(response);
};

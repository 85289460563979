import React, { useEffect } from 'react';
import { DateUtils } from '@utils/date/DateUtils';
import { getFilterDateChangeConfig, handleSetFiltersDatesToUrl, handleSetInitialFiltersDates, } from './crossPageFilterUtils';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { CROSS_PAGE_FILTER_CONFIG } from '@configs/crossPageFilterInitialState';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import { DATE_RANGES } from '@utils/date/dateConstants';
import StyledFilter from '@app/common/react/styled/StyledFilter';
import DateRange from './DateRange';
import Granularity from './Granularity';
var _a = URL_QUERIES.COMMON, DATE_RANGE = _a.DATE_RANGE, GRANULARITY = _a.GRANULARITY;
var CUSTOM_RANGE = DATE_RANGES.CUSTOM_RANGE;
var CrossPageFilter = function (props) {
    var isGranularity = props.isGranularity, isEmbedded = props.isEmbedded, dashboardName = props.dashboardName, crossPageFilter = props.crossPageFilter, userPreferences = props.userPreferences, initCrossPageFilterState = props.initCrossPageFilterState, resetCrossPageFilterState = props.resetCrossPageFilterState, changeCrossPageFilterOption = props.changeCrossPageFilterOption;
    useEffect(function () {
        var config = CROSS_PAGE_FILTER_CONFIG[dashboardName];
        initCrossPageFilterState(config);
        handleSetInitialFiltersDates(userPreferences, isGranularity, changeCrossPageFilterOption);
        return function () {
            if (dashboardName === DASHBOARDS_SCHEMA.MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.PSEUDO_CC) {
                resetCrossPageFilterState(config);
            }
        };
    }, []);
    useEffect(function () {
        handleSetFiltersDatesToUrl(crossPageFilter, isGranularity);
    }, [crossPageFilter]);
    if (crossPageFilter.dashboardName !== props.dashboardName) {
        return null;
    }
    var onGranularityChange = function (granularity) {
        return changeCrossPageFilterOption(getFilterDateChangeConfig(GRANULARITY, granularity));
    };
    var onDateRangeChange = function (value) {
        return changeCrossPageFilterOption(getFilterDateChangeConfig(DATE_RANGE, value, DateUtils.calculateDateRangeByOption(value), isGranularity));
    };
    var changeCustomDateRange = function (calculatedDateRange) {
        return changeCrossPageFilterOption(getFilterDateChangeConfig(DATE_RANGE, CUSTOM_RANGE, calculatedDateRange, isGranularity));
    };
    var content = (React.createElement(React.Fragment, null,
        isGranularity ? (React.createElement(Granularity, { selected: crossPageFilter.granularity.selected, options: crossPageFilter.granularity.options, onChange: onGranularityChange })) : null,
        React.createElement(DateRange, { dateRange: crossPageFilter.dateRange, onChange: onDateRangeChange, changeCustomDateRange: changeCustomDateRange, isEmbedded: isEmbedded })));
    return React.createElement("div", { className: "cross-page-filter" }, isEmbedded ? content : React.createElement(StyledFilter, null, content));
};
export default CrossPageFilter;

import React from 'react';
import { Drawer } from 'antd';
import { DRAWER_SETTINGS } from './drawerConstants';
var DrawerComponent = function (_a) {
    var onClose = _a.onClose, visible = _a.visible, children = _a.children, _b = _a.currentPlacement, currentPlacement = _b === void 0 ? DRAWER_SETTINGS.CURRENT_PLACEMENT : _b, _c = _a.width, width = _c === void 0 ? DRAWER_SETTINGS.WIDTH : _c;
    return (React.createElement(Drawer, { onClose: onClose, open: visible, 
        // @ts-ignore
        placement: currentPlacement, width: width }, children));
};
export default DrawerComponent;

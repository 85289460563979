import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { CROSS_PAGE_FILTER_CONFIG } from '@configs/crossPageFilterInitialState';
import { getPageFromPathname } from '@commonUtils/commonUtils';
var DASHBOARDS = DASHBOARDS_SCHEMA.DASHBOARDS, REPORTS = DASHBOARDS_SCHEMA.REPORTS;
var getFormedGranularitySelected = function (dashBoard) {
    var _a = CROSS_PAGE_FILTER_CONFIG[dashBoard].granularity.selected, value = _a.value, pseudo = _a.pseudo;
    // order makes sense
    return [value, pseudo];
};
var granularityDefaultValue = function (pathname) {
    if (pathname === void 0) { pathname = window.location.pathname; }
    var page = getPageFromPathname(pathname);
    switch (page) {
        case DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE.PSEUDO_CC:
            return getFormedGranularitySelected(DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE.PSEUDO_CC);
        case REPORTS.PAGES.NEW_SIGN_UPS_REPORT.PSEUDO_CC:
            return getFormedGranularitySelected(REPORTS.PAGES.NEW_SIGN_UPS_REPORT.PSEUDO_CC);
        case REPORTS.PAGES.INSTALLATION_REPORT.PSEUDO_CC: {
            return getFormedGranularitySelected(REPORTS.PAGES.INSTALLATION_REPORT.PSEUDO_CC);
        }
        case REPORTS.PAGES.ACTIVE_INSTANCES.PSEUDO_CC:
            return getFormedGranularitySelected(REPORTS.PAGES.ACTIVE_INSTANCES.PSEUDO_CC);
        case REPORTS.PAGES.TRIAL_REPORT.PSEUDO_CC:
            return getFormedGranularitySelected(REPORTS.PAGES.TRIAL_REPORT.PSEUDO_CC);
        case DASHBOARDS.PAGES.OPENSTACK_USAGE.PSEUDO_CC: {
            return getFormedGranularitySelected(DASHBOARDS.PAGES.OPENSTACK_USAGE.TABS.OPENSTACK_OVERALL_USAGE.PSEUDO_CC);
        }
        default:
            return '';
    }
};
export default granularityDefaultValue;

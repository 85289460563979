import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { changeCurrentPage } from '@actionCreators/pagination/pagination';
import PaginationContainer from '@commonComponents/pagination/PaginationContainer';
import { DASHBOARDS_SCHEMA, PAGINATION_NAMES } from '@configs/DASHBOARD_PSEUDOS';
import { StyledTable } from '@commonComponents/table/styled';
import { COLUMNS_CLUSTERS, NO_CLUSTERS_FOUND } from './SearchClustersTableConstants';
import { getClusters } from '@actionCreators/clusters/clusters';
import Table from '@commonComponents/table/Table';
import TableLoadingStates from '@commonComponents/table-loading-states/TableLoadingStates';
import { normalizers } from './searchClustersTableUtils';
var filterName = DASHBOARDS_SCHEMA.SUPPORT.PAGES.SEARCH_CLUSTERS.PSEUDO_CC;
var mapState = function (store) { return ({
    mode: store.mode.selected,
    multiFilter: store.multiFilter,
    pagination: store.pagination.generalSearchDashboard,
    clusters: store.clusters.searchClusters,
}); };
var mapDispatch = {
    changeCurrentPage: changeCurrentPage,
    getClusters: getClusters,
};
var connector = connect(mapState, mapDispatch);
var SearchClustersTable = function (props) {
    var mode = props.mode, multiFilter = props.multiFilter, pagination = props.pagination, clusters = props.clusters;
    var _a = useState({
        sortBy: 'id',
        orderBy: 'asc',
    }), state = _a[0], setState = _a[1];
    var getApiParams = function () {
        var _a = multiFilter.filter.searchTerm, searchTerm = _a === void 0 ? '' : _a;
        return {
            search_term: searchTerm.length > 0 ? searchTerm : null,
            page: pagination.currentPage,
            limit: pagination.limit,
            sortBy: state.sortBy,
            orderBy: state.orderBy,
            with_cluster_versions: true,
        };
    };
    var callAPI = function () {
        props.getClusters(getApiParams());
    };
    useEffect(function () {
        if (multiFilter.name === filterName && multiFilter.filter.searchTerm !== undefined) {
            callAPI();
        }
    }, [multiFilter, pagination.currentPage, pagination.limit, mode, state]);
    var onPaginationChange = function (page) {
        props.changeCurrentPage({
            paginationName: PAGINATION_NAMES.SEARCH_CLUSTERS,
            currentPage: page,
        });
    };
    var onLimitChange = function (limit) {
        props.changeCurrentPage({
            limit: limit,
            paginationName: PAGINATION_NAMES.SEARCH_CLUSTERS,
            currentPage: 1,
        });
    };
    var data = {
        columns: COLUMNS_CLUSTERS,
        rows: clusters.data,
    };
    return (React.createElement(TableLoadingStates, { data: clusters.data, count: pagination.limit, loading: clusters.loading, empty: {
            paddingTop: 10,
            description: NO_CLUSTERS_FOUND,
        } },
        React.createElement(StyledTable, { className: "table" },
            React.createElement(Table, { sortable: true, data: data, normalizers: normalizers, orderBy: state.orderBy, sortBy: state.sortBy, setSortableState: function (s) { return setState(s); }, pagination: pagination, withSequenceNumbering: true, columnControl: true }),
            React.createElement(PaginationContainer, { pagination: pagination, onLimitChange: onLimitChange, onChange: onPaginationChange, withLimits: true }))));
};
export default connector(SearchClustersTable);

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as Actions from '@redux/actions/requestsTracking';
var resolvers = {};
resolvers[Actions.SyncActions.TOGGLE_REQUEST_PENDING_STATE_IDS] = function (draft, _a) {
    var shouldHandleRequestIds = _a.shouldHandleRequestIds, pending = _a.pending;
    if (pending) {
        var newRequestsInPendingStateIds = new Set(__spreadArray(__spreadArray([], draft.requestsInPendingStateIds, true), shouldHandleRequestIds, true));
        draft.requestsInPendingStateIds = Array.from(newRequestsInPendingStateIds);
    }
    else {
        draft.requestsInPendingStateIds = draft.requestsInPendingStateIds.filter(function (value) { return !shouldHandleRequestIds.includes(value); });
    }
};
export default resolvers;

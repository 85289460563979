var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import find from 'lodash/find';
import { DateUtils } from '@utils/date/DateUtils';
import { MCC_GRANULARITY_RANGES_EQUIVALENTS, STATIC_GRANULARITY_RANGES_EQUIVALENTS, } from '@commonConstants/granularityConstants';
import { DASHBOARD_FAMILIES, DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { defaultGranularity, staticReportsGranularity } from '@configs/crossPageFilterInitialState';
import { DATE_RANGES, DATE_RANGES_DAYS_QTY_EQUIVALENTS, MCC_DATE_RANGE_GRANULARITY_DEFAULTS, STATIC_REPORTS_DATE_RANGE_GRANULARITY_DEFAULTS, } from '@utils/date/dateConstants';
export var resolveDaysEquivalents = function (dashboardFamily) {
    return dashboardFamily === DASHBOARD_FAMILIES.MCC_REPORTS
        ? MCC_GRANULARITY_RANGES_EQUIVALENTS
        : STATIC_GRANULARITY_RANGES_EQUIVALENTS;
};
export var filterOptionsByDaysEquivalent = function (dashboardFamily, daysEquivalent, granularityOptions) {
    var daysEquivalentsSource = resolveDaysEquivalents(dashboardFamily);
    return granularityOptions.filter(function (granularity) {
        var _a = daysEquivalentsSource[granularity.pseudo], start = _a[0], end = _a[1];
        return (daysEquivalent >= start && daysEquivalent <= end) || (daysEquivalent >= start && daysEquivalent >= end);
    });
};
export var filterGranularityOptionsBySelectedDateRange = function (dashboardFamily, dateRange, granularityOptions) {
    var daysEquivalent = dateRange.selected === DATE_RANGES.CUSTOM_RANGE
        ? DateUtils.calculateNumberOfDaysBetweenDates(dateRange.calculatedDateRange.start.timeString, dateRange.calculatedDateRange.end.timeString)
        : DATE_RANGES_DAYS_QTY_EQUIVALENTS[dateRange.selected];
    return filterOptionsByDaysEquivalent(dashboardFamily, daysEquivalent, granularityOptions);
};
export var isGranularityHigherThanDateRange = function (dashboardFamily, granularity, dateRange) {
    var daysEquivalentsSource = resolveDaysEquivalents(dashboardFamily);
    var rangeEquivalent = daysEquivalentsSource[granularity.pseudo];
    var daysEquivalent = dateRange.selected === DATE_RANGES.CUSTOM_RANGE
        ? DateUtils.calculateNumberOfDaysBetweenDates(dateRange.calculatedDateRange.start.timeString, dateRange.calculatedDateRange.end.timeString)
        : DATE_RANGES_DAYS_QTY_EQUIVALENTS[dateRange.selected];
    if (rangeEquivalent[1] < 0)
        return true;
    return daysEquivalent >= rangeEquivalent[0] && daysEquivalent <= rangeEquivalent[1];
};
export var resolveEquivalentGranularity = function (dashboardFamily, dateRange, granularityOptions) {
    var daysEquivalentsSource = resolveDaysEquivalents(dashboardFamily);
    var daysEquivalent = dateRange.selected === DATE_RANGES.CUSTOM_RANGE
        ? DateUtils.calculateNumberOfDaysBetweenDates(dateRange.calculatedDateRange.start.timeString, dateRange.calculatedDateRange.end.timeString)
        : DATE_RANGES_DAYS_QTY_EQUIVALENTS[dateRange.selected];
    var equivalentGranularity = find(dateRange.selected === DATE_RANGES.CUSTOM_RANGE ? __spreadArray([], granularityOptions, true).reverse() : granularityOptions, function (granularity) {
        var equivalent = daysEquivalentsSource[granularity.pseudo];
        return daysEquivalent >= equivalent[0] && daysEquivalent <= equivalent[1];
    });
    if (!equivalentGranularity)
        return granularityOptions[0];
    return equivalentGranularity;
};
export var resolveDefaultGranularity = function (dashboardFamily, dateRange) {
    var dateRangesDefaults = dashboardFamily === DASHBOARD_FAMILIES.MCC_REPORTS
        ? MCC_DATE_RANGE_GRANULARITY_DEFAULTS
        : STATIC_REPORTS_DATE_RANGE_GRANULARITY_DEFAULTS;
    var defaultGranularityPseudo = dateRangesDefaults[dateRange.selected];
    var dateRangesGranularitySource = dashboardFamily === DASHBOARD_FAMILIES.MCC_REPORTS ? defaultGranularity : staticReportsGranularity;
    return find(dateRangesGranularitySource, ['pseudo', defaultGranularityPseudo]);
};
export var mergeDateRangeFromPayload = function (state, dateRange, selectedDateRange, dashboardName) {
    //Checking if dateRange is selected in picker and this option exist in list to decide if dateRange should be preserved
    if (selectedDateRange && dateRange.options.includes(selectedDateRange)) {
        state.dateRange.options = dateRange.options;
        // do not share state from other pages to telemetry
        if (dashboardName === DASHBOARDS_SCHEMA.MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.PSEUDO_CC) {
            state.dateRange.calculatedDateRange = dateRange.calculatedDateRange;
        }
    }
    else {
        Object.assign(state.dateRange, dateRange);
    }
};
export var changeGranularityRegardingToDateRange = function (draft, payload) {
    var filteredGranularityOptions = filterGranularityOptionsBySelectedDateRange(draft.dashboardFamily, payload.payload, draft.granularity.defaultOptions);
    //add "|| payload.payload.selected === DATE_RANGES.LAST_MONTH" if need 1 week default
    var granularity = payload.payload.selected === DATE_RANGES.CUSTOM_RANGE
        ? resolveEquivalentGranularity(draft.dashboardFamily, payload.payload, filteredGranularityOptions)
        : resolveDefaultGranularity(draft.dashboardFamily, payload.payload);
    Object.assign(draft.dateRange, payload.payload);
    //change granularity regarding to date range
    draft.granularity.options = filteredGranularityOptions;
    draft.granularity.selected = granularity;
};

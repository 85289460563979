var _a;
export var CHART_DASHBOARDS = {
    AVAILABILITY_CHARTS: 'mccClusterAvailabilityCharts',
};
export var AVAILABILITY_CHARTS_KEYS = {
    NOVA: 'nova',
    KEYSTONE: 'keystone',
    GLANCE: 'glance',
    NEUTRON: 'neutron',
    CINDERV2: 'cinderv2',
    CINDERV3: 'cinderv3',
    SWIFT: 'swift',
    KPI_PROVISIONING: 'kpi_provisioning',
};
export var AVAILABILITY_CHARTS_LABELS = (_a = {},
    _a[AVAILABILITY_CHARTS_KEYS.NOVA] = 'Nova',
    _a[AVAILABILITY_CHARTS_KEYS.KEYSTONE] = 'Keystone',
    _a[AVAILABILITY_CHARTS_KEYS.GLANCE] = 'Glance',
    _a[AVAILABILITY_CHARTS_KEYS.NEUTRON] = 'Neutron',
    _a[AVAILABILITY_CHARTS_KEYS.CINDERV2] = 'Cinder v2',
    _a[AVAILABILITY_CHARTS_KEYS.CINDERV3] = 'Cinder v3',
    _a[AVAILABILITY_CHARTS_KEYS.SWIFT] = 'Swift',
    _a[AVAILABILITY_CHARTS_KEYS.KPI_PROVISIONING] = 'KPI provisioning',
    _a);

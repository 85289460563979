import React from 'react';
import uudi from 'react-uuid';
import get from 'lodash/get';
import withDisablingWhileFetchingHOC from '@HOC/withDisablingWhileFetchingHOC/withDisablingWhileFetchingHOC';
import { StyledStatsWithTags } from './styled';
var StatsBarWithTagsContainer = function (props) { return (React.createElement(StyledStatsWithTags, { className: "stats-tagged" },
    React.createElement("div", { className: "stats-tagged__title" }, "Clusters Info"),
    React.createElement("div", { className: "stats-tagged__items flex" }, [
        {
            label: 'MCC - MKE',
            key: 'allClustersInformationMccMke',
        },
        {
            label: 'MCC - MOS',
            key: 'allClustersInformationMccMos',
        },
        {
            label: 'MKE',
            key: 'allClustersInformationMke',
        },
    ].map(function (_a) {
        var label = _a.label, key = _a.key;
        return (React.createElement("div", { key: uudi(), className: "stats-tagged__items__item" },
            React.createElement("div", { className: "stats-tagged__items__item__description" }, label),
            React.createElement("div", { className: "stats-tagged__items__item__value" }, get(props.mccOverview, key, 0))));
    })))); };
export default withDisablingWhileFetchingHOC(StatsBarWithTagsContainer);

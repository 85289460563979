import get from 'lodash/get';
import { MCC_CHARTS, CHART_DASHBOARDS } from './alertsChartsConstants';
export var getAlertsChartsConfigs = function (params) { return ({
    dashboard: CHART_DASHBOARDS.ALERTS,
    charts: MCC_CHARTS.map(function (_a) {
        var key = _a.key, chartType = _a.chartType;
        return ({
            key: key,
            chartType: chartType,
            timezone: params.timezone,
            inactiveHoursThreshold: params.lastSyncDate,
            end: params.calculatedDateRange.end.timeStamp,
            start: params.calculatedDateRange.start.timeStamp,
            statuses: get(params, 'alertStatus', []).join(','),
            priorities: get(params, 'alertPriority', []).join(','),
            customerTypes: get(params, 'customerTypes', []).join(','),
            customerIds: get(params, 'customer', [])
                .map(function (_a) {
                var id = _a.id;
                return id;
            })
                .join(','),
            clusterIds: get(params, 'cluster', [])
                .map(function (_a) {
                var id = _a.id;
                return id;
            })
                .join(','),
        });
    }),
}); };

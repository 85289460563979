import { ENVIRONMENTS } from '@commonConstants/environments';
var host = window.location.host;
var splitedHost = host.split('.')[0].split('-');
var env = splitedHost[2];
export var getAPI = function () {
    if (process.env.MCNC_BACKEND_URL) {
        return process.env.MCNC_BACKEND_URL;
    }
    var DEV = 'https://mcnc-reports-dev.azurewebsites.net';
    var STAGE = 'https://mcnc-reports-stage.azurewebsites.net';
    var PROD = 'https://mcc-reports-prod.azurewebsites.net';
    var TESTS = 'https://mcnc-reports-master.azurewebsites.net';
    var TESTS_SECOND = 'https://mcnc-reports-tests.azurewebsites.net';
    var DEF = "https://mcnc-reports".concat(env ? '-' + env : '', ".azurewebsites.net");
    switch (process.env.ENVIRONMENT) {
        case ENVIRONMENTS.DEV:
            return DEV;
        case ENVIRONMENTS.STAGE:
            return STAGE;
        case ENVIRONMENTS.PROD:
            return PROD;
        case ENVIRONMENTS.TESTS:
            return TESTS;
        default:
            return DEF;
    }
};
export var BASE_URL = "".concat(getAPI(), "/api/v1/");
export var ENDPOINTS = {
    AUTH0_TOKEN_SIGN_IN: '/auth0tokensignin',
    OPENSTACK_CLUSTERS_USAGE: '/openstack-clusters-usage',
    USERS: '/users',
    CITIES: '/cities',
    INSTANCES: '/instances',
    ACTIVE_INSTANCES: '/active-instances',
    ACTIVE_INSTANCES_STATISTICS: '/active-instances/statistics',
    CLUSTERS: '/clusters',
    NODES: '/nodes',
    AWS_ACCOUNTS: '/trials/reports/aws/accounts',
    OS_NETWORKS: '/openstack-networks',
    OS_IMAGES: '/openstack-images',
    OS_VOLUMES: '/openstack-volumes',
    OS_VMS: '/openstack-vms',
    OS_LOAD_BALANCERS: '/openstack-loadbalancers',
    OS_TENANTS: '/openstack-tenants',
    OS_COMPUTE_NODES: '/openstack-compute-nodes',
    CUSTOMERS_LIST: '/customers',
    CUSTOMERS_LIST_WITH_LOCATION: '/customers/cities',
    CUSTOMERS_OVERVIEW: '/customers-overview',
    CUSTOMER_SIGN_UPS: '/customer-sign-ups',
    CPU_USAGE: '/reports/usage/cpu',
    MEMORY_USAGE: '/reports/usage/memory',
    DISK_USAGE: '/reports/usage/disk',
    ALERTS_BY_DATE: '/reports/alerts/by-date',
    ALERTS_BY_SERVICE: '/reports/alerts/by-service',
    ALERTS_CUSTOMERS: '/alert-customers',
    ALERTS_STATUSES: '/alert-statuses',
    ALERTS_PRIORITIES: '/alert-priorities',
    CLUSTER_HEALTH: '/clusters-health',
    CLUSTER_HEALTH_STATUSES: '/cluster-health-statuses',
    CUSTOMER_TYPES: '/customer-types',
    BOOTSTRAP_STAGES: '/bootstrap-stages',
    BOOTSTRAP_PROVIDERS: '/bootstrap-providers',
    BOOTSTRAP_EVENTS: '/bootstrap-events',
    BOOTSTRAP_INSTALLATIONS: '/bootstrap-installations',
    BOOTSTRAP_STATS: '/bootstrap-stats',
    SF_ACCOUNTS: '/sf-accounts',
    MKE_VERSIONS: '/customer-ratings/mke-versions',
    TRIAL: '/trials/reports/users',
    PERMISSIONS_GROUPS: '/permission-groups',
    WARNING_BANNER: '/statuses/application',
    WARNING_DETAILS: '/statuses/components',
    CHARTS: function (dashboard) { return "/charts/".concat(dashboard); },
    TRIALS_CHARTS: function (dashboard) { return "/trials/".concat(dashboard); },
    CLUSTER_NAME: function (cluster_id) { return "/clusters/".concat(cluster_id); },
    CLUSTER_SPECS: '/clusters/specs',
    AVAILABLE_PERMISSIONS_GROUPS: function (userId) { return "/permission-groups/".concat(userId, "/dashboards"); },
    TRIAL_STATISTIC: '/trials/reports/users/stats',
    DASHBOARDS: '/dashboards',
    PERMISSION_GROUPS: function (groupId) {
        if (groupId === void 0) { groupId = null; }
        return "/permission-groups".concat(groupId ? "/".concat(groupId) : '');
    },
    PERMISSION_GROUPS_USERS: function (groupId) { return "/permission-groups/".concat(groupId, "/users"); },
    SF_ACCOUNT: function (accountId) { return "/sf-accounts/".concat(accountId); },
    MKE_ALERTS: function (license, clusterId) { return "/mke-licenses/".concat(license, "/mke-clusters/").concat(clusterId, "/mke-alerts"); },
    MKE_RECOMMENDATIONS: function (license, clusterId) {
        return "/mke-licenses/".concat(license, "/mke-clusters/").concat(clusterId, "/mke-recommendations");
    },
    MKE_OVERVIEW: function (accountId) { return "/sf-accounts/".concat(accountId, "/mke-clusters-overview"); },
    MCC_OVERVIEW: function (accountId) { return "/sf-accounts/".concat(accountId, "/mcc-clusters-overview"); },
    TOGGLE_CLUSTER_STATUS: function (clusterId, type) { return "/hidden-clusters/".concat(clusterId, "?type=").concat(type); },
    USERS_PATCH: function (userId) { return "/users/".concat(userId); },
    INSTANCES_PATCH: function (instanceId) { return "/instances/".concat(instanceId); },
    CUSTOMERS_PATCH: function (customerId) { return "/customers/".concat(customerId); },
    PATCH_ANNOTATION: function (clusterId) { return "/clusters/".concat(clusterId, "/annotations"); },
    USER_PREFERENCES: function (userId) { return "/users/".concat(userId, "/preferences"); },
    POST_PERMISSIONS_GROUP: "/api/v1/permission-groups",
};

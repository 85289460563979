import produce from 'immer';
import { resolvers } from './resolvers';
export var initialState = {
    activeGroupId: null,
    allUsersStatic: {},
    permissionGroup: { name: '', dashboards: [] },
    allUsers: {
        data: {},
        loading: false,
        maxPages: 0,
        currentPage: 0,
    },
    allowedPagesTags: {
        loading: true,
        dirty: false,
        data: [],
    },
    activeGroupAssignedUsers: [],
    allPermissionGroups: {
        data: [],
        maxPages: 0,
        currentPage: 0,
        loading: false,
    },
};
export var permissions = function (state, action) {
    if (state === void 0) { state = initialState; }
    if (action === void 0) { action = {}; }
    var type = action.type, payload = action.payload;
    if (!(type in resolvers)) {
        return state;
    }
    var resolver = function (draft) { return resolvers[type](draft, payload); };
    return produce(state, resolver);
};

import produce from 'immer';
import get from 'lodash/get';
import queryString from 'query-string';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import { r } from './resolvers';
var SEARCH_VALUE = URL_QUERIES.COMMON.SEARCH_VALUE;
var initialState = {
    loading: true,
    name: '',
    filter: {
        searchTerm: get(queryString.parse(window.location.search), SEARCH_VALUE, ''),
        configuration: null,
        hasToBeUpdated: null,
    },
};
export var multiFilter = function (state, action) {
    if (state === void 0) { state = initialState; }
    if (action === void 0) { action = {}; }
    var type = action.type, payload = action.payload;
    if (!(type in r)) {
        return state;
    }
    var resolver = function (draft) { return r[type](draft, payload); };
    return produce(state, resolver);
};

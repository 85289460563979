import * as Actions from '@redux/actions/userInfo';
export var setUserInfo = function (payload) { return ({
    type: Actions.SyncActions.SET_USER_INFO,
    payload: payload,
}); };
export var setUserPreferences = function (payload) { return ({
    type: Actions.SyncActions.SET_USER_PREFERENCES,
    payload: payload,
}); };
export var changeUserPreference = function (payload) { return ({
    type: Actions.SyncActions.CHANGE_USER_PREFERENCE,
    payload: payload,
}); };
export var handlePreferencesSave = function (payload) { return ({
    type: Actions.AsyncActions.HANDLE_PREFERENCES_SAVE,
    payload: payload,
}); };
export var applySettingsSave = function () { return ({
    type: Actions.SyncActions.APPLY_SETTINGS_SAVE,
}); };
export var resetPreferences = function () { return ({
    type: Actions.SyncActions.RESET_PREFERENCES,
}); };

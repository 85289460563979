import { connect } from 'react-redux';
import ActiveUser from './ActiveUser';
import * as actionCreators from '@actionCreators/userInfo';
import { isSFPermissionGroup } from '@commonUtils/commonUtils';
import { getTimezoneFromUrl } from '@commonUtils/urlUtils/urlUtils';
import withUrlValidationHOC from '@HOC/withUrlValidationHOC/withUrlValidationHOC';
var mapState = function (store) { return ({
    user: store.userInfo.userPreferences,
    location: window.location,
    timeZoneFromUrl: getTimezoneFromUrl(window.location),
    isSFUser: isSFPermissionGroup(),
}); };
var mapDispatch = {
    changeUserPreference: actionCreators.changeUserPreference,
    handlePreferencesSave: actionCreators.handlePreferencesSave,
};
var connector = connect(mapState, mapDispatch);
export default withUrlValidationHOC(connector(ActiveUser));

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { PAGINATION_NAMES } from '@configs/DASHBOARD_PSEUDOS';
import { getTopCustomers } from '@actionCreators/home/home';
import { changeCurrentPage } from '@actionCreators/pagination/pagination';
import TableLoadingStates from '@commonComponents/table-loading-states/TableLoadingStates';
import Table from '@commonComponents/table/Table';
import PaginationContainer from '@commonComponents/pagination/PaginationContainer';
import { DateUtils } from '@utils/date/DateUtils';
import { COLUMNS, TABLE_TITLE, NO_CUSTOMERS_FOUND } from './topCustomersConstants';
var mapState = function (store) { return ({
    timezone: store.userInfo.userPreferences.timezone,
    mode: store.mode.selected,
    lastSyncDate: store.mode.lastSyncDate.selected,
    customers: store.home.topCustomers,
    pagination: store.pagination.customerOverview,
}); };
var mapDispatch = {
    getTopCustomers: getTopCustomers,
    changeCurrentPage: changeCurrentPage,
};
var connector = connect(mapState, mapDispatch);
var TopCustomers = function (props) {
    var _a = useState({
        sortBy: 'managed_cluster_count',
        orderBy: 'asc',
    }), state = _a[0], setState = _a[1];
    var customers = props.customers, pagination = props.pagination, mode = props.mode, lastSyncDate = props.lastSyncDate, timezone = props.timezone;
    var callAPI = function () {
        var params = {
            customer_types: mode[0] === 'All' ? null : mode.toString(),
            inactive_hours_threshold: lastSyncDate.value,
            page: pagination.currentPage,
            limit: pagination.limit,
            sortBy: state.sortBy,
            orderBy: state.orderBy,
            timezone: timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        props.getTopCustomers(params);
    };
    useEffect(function () {
        callAPI();
    }, [state, pagination.currentPage, pagination.limit, mode, lastSyncDate, timezone]);
    var data = {
        columns: COLUMNS,
        rows: customers.data,
    };
    var className = classNames({
        'top-customers-table': true,
        loading: customers.loading,
    });
    var onPaginationChange = function (page) {
        props.changeCurrentPage({
            paginationName: PAGINATION_NAMES.CUSTOMER_OVERVIEW,
            currentPage: page,
        });
    };
    var onLimitChange = function (limit) {
        props.changeCurrentPage({
            paginationName: PAGINATION_NAMES.CUSTOMER_OVERVIEW,
            currentPage: 1,
            limit: limit,
        });
    };
    var setSortableState = function (nextState) {
        setState(nextState);
    };
    var normalizers = {
        createdAt: function (s) { return (isNil(s) ? '' : DateUtils.getDateLocalStringAutoTimezoneHandler(s)); },
    };
    return (React.createElement("div", { className: className },
        React.createElement(TableLoadingStates, { className: className, data: (customers === null || customers === void 0 ? void 0 : customers.data) || [], loading: customers.loading, count: pagination.limit, skeleton: {
                paddingBottom: 20,
                paddingTop: 20,
            }, empty: {
                description: NO_CUSTOMERS_FOUND,
            } },
            React.createElement(Table, { title: TABLE_TITLE, sortable: true, data: data, orderBy: state.orderBy, sortBy: state.sortBy, setSortableState: setSortableState, normalizers: normalizers, pagination: pagination, columnControl: true })),
        React.createElement(PaginationContainer, { withLimits: true, limits: [5, 10, 15], onLimitChange: onLimitChange, pagination: pagination, onChange: onPaginationChange })));
};
export default connector(TopCustomers);

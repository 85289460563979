var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import head from 'lodash/head';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import Line from '@commonComponents/chart/Line/Line';
import { StyledChartsWrapperNew } from '@app/common/react/styled/StyledChartsWrapperNew';
import { fetchCharts, dropChartsToDefault } from '@redux/dispatchers/charts';
import { getNormalizedABSYValue, getNormalizedABSTooltipValue, getChartOptions } from '@commonUtils/charts/chartUtils';
import { getNormalizedOccupiedSlots, getOccupidedSlotsChartsConfigs } from './occupiedSlotsChartUtils';
import { CHART_DASHBOARDS, COSTS_CHARTS_KEYS } from './occupiedSlotsConstants';
import { useDebouncedEffect } from '@app/hooks';
var OccupiedSlotsCharts = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    var _u = useSelector(function (store) { return ({
        multiFilter: store.multiFilter,
        crossPageFilter: store.crossPageFilter,
        timezone: store.userInfo.userPreferences.timezone,
        trialReportsOccupiedSlots: store.charts.trialReportsOccupiedSlots,
    }); }), trialReportsOccupiedSlots = _u.trialReportsOccupiedSlots, multiFilter = _u.multiFilter, crossPageFilter = _u.crossPageFilter, timezone = _u.timezone;
    var isMonth = ((_b = (_a = crossPageFilter === null || crossPageFilter === void 0 ? void 0 : crossPageFilter.granularity) === null || _a === void 0 ? void 0 : _a.selected) === null || _b === void 0 ? void 0 : _b.value) === '30 days';
    var loading = (_c = trialReportsOccupiedSlots[COSTS_CHARTS_KEYS.OCCUPIED_CHARTS]) === null || _c === void 0 ? void 0 : _c.loading;
    var isParams = crossPageFilter.dateRange.calculatedDateRange &&
        ((_e = (_d = crossPageFilter === null || crossPageFilter === void 0 ? void 0 : crossPageFilter.granularity) === null || _d === void 0 ? void 0 : _d.selected) === null || _e === void 0 ? void 0 : _e.value) &&
        ((_g = (_f = multiFilter === null || multiFilter === void 0 ? void 0 : multiFilter.filter) === null || _f === void 0 ? void 0 : _f.selected) === null || _g === void 0 ? void 0 : _g.external);
    useDebouncedEffect(function () {
        if (!loading && isParams) {
            fetchCharts(getOccupidedSlotsChartsConfigs(multiFilter, crossPageFilter, timezone));
        }
        return function () {
            dropChartsToDefault(CHART_DASHBOARDS.TRIAL_REPORTS_OCCUPIED_SLOTS);
        };
    }, [
        (_j = (_h = multiFilter === null || multiFilter === void 0 ? void 0 : multiFilter.filter) === null || _h === void 0 ? void 0 : _h.selected) === null || _j === void 0 ? void 0 : _j.awsAccounts,
        (_l = (_k = multiFilter === null || multiFilter === void 0 ? void 0 : multiFilter.filter) === null || _k === void 0 ? void 0 : _k.selected) === null || _l === void 0 ? void 0 : _l.external,
        crossPageFilter.dateRange.calculatedDateRange,
        (_o = (_m = crossPageFilter.granularity) === null || _m === void 0 ? void 0 : _m.selected) === null || _o === void 0 ? void 0 : _o.value,
        timezone,
    ]);
    var data = get(trialReportsOccupiedSlots[COSTS_CHARTS_KEYS.OCCUPIED_CHARTS], 'data', []);
    var datasetsTitles = orderBy(data, function (account) { return get(account, 'Points', []).length; }, ['desc']).map(function (_a) {
        var provider = _a.provider;
        return provider;
    });
    return (React.createElement(StyledChartsWrapperNew, { className: "styled_charts_wrapper one" },
        React.createElement(Line, { loadingOut: loading, title: "Occupied Slots", datasetsTitles: datasetsTitles, 
            // @ts-ignore
            datasets: getNormalizedOccupiedSlots((_p = trialReportsOccupiedSlots[COSTS_CHARTS_KEYS.OCCUPIED_CHARTS]) === null || _p === void 0 ? void 0 : _p.data, (_r = (_q = crossPageFilter === null || crossPageFilter === void 0 ? void 0 : crossPageFilter.granularity) === null || _q === void 0 ? void 0 : _q.selected) === null || _r === void 0 ? void 0 : _r.value), chartOptions: __assign(__assign({}, getChartOptions({
                stackedY: head((_t = (_s = multiFilter === null || multiFilter === void 0 ? void 0 : multiFilter.filter) === null || _s === void 0 ? void 0 : _s.selected) === null || _t === void 0 ? void 0 : _t.stacked),
                maxXAxisTicksLimit: 10,
                getNormalizedYValue: getNormalizedABSYValue,
                getNormalizedTooltipValue: getNormalizedABSTooltipValue,
            })), { xFormatFunction: function (value) { return moment(value).format(isMonth ? 'MMM Y' : 'MMM D, Y'); } }) })));
};
export default memo(OccupiedSlotsCharts);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { StyledSkeletonWrapper } from './StyledSkeleton';
var TableSkeleton = function (_a) {
    var height = _a.height, count = _a.count, rest = __rest(_a, ["height", "count"]);
    return (React.createElement(StyledSkeletonWrapper, __assign({}, rest),
        React.createElement(SkeletonTheme, null,
            React.createElement(Skeleton, { height: height, count: count, className: "loader-skeleton" }))));
};
export default TableSkeleton;

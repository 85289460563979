import React from 'react';
import { connect } from 'react-redux';
import { getMultiFilterPickers } from '@configs/multiFilterConfig';
import { changeTermV2 } from '@actionCreators/multiFilter/multiFilter';
import { StringUtils } from '@utils/string/StringUtils';
import { resetSearchValueInUrl } from '@commonComponents/search/searchUtils';
import FilterContainer from '@commonComponents/filter/FilterContainer';
import Search from '@commonComponents/search/Search';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { SEARCH_PLACEHOLDER } from '@commonConstants/commonConstants';
var mapState = function (store) { return ({
    multiFilter: store.multiFilter,
    mode: store.mode,
}); };
var mapDispatch = {
    changeTermV2: changeTermV2,
};
var connector = connect(mapState, mapDispatch);
var ManageUsersFilter = function (props) {
    var name = DASHBOARDS_SCHEMA.ACCOUNT_MANAGEMENT.PAGES.MANAGE_USERS.PSEUDO_CC;
    var params = {};
    var onSearchChange = function (value) {
        var length = StringUtils.countStringLengthWithoutSpaces(value);
        if (value === '' || length > 2) {
            props.changeTermV2({
                tableName: name,
                pseudo: 'searchTerm',
                value: value,
            });
        }
    };
    var resetAdditionalParams = function () {
        resetSearchValueInUrl();
        onSearchChange('');
    };
    return (React.createElement(FilterContainer, { name: name, params: params, multiFilter: props.multiFilter, isOptionsHidden: true, isPaginationOn: true, doNotUpdate: true, currentMode: props.mode, pickers: getMultiFilterPickers(name), resetAdditionalParams: resetAdditionalParams },
        React.createElement(Search, { label: "Search users", placeholder: SEARCH_PLACEHOLDER, withDelay: 1000, onChange: onSearchChange, max: 64 })));
};
export default connector(ManageUsersFilter);

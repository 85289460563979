import cloneDeep from 'lodash/cloneDeep';
import { store } from '@redux/store';
var getState = function () {
    return cloneDeep(store.getState());
};
var getGlobalFilteringParams = function (additionalParams, isCustomerTypesInUse, isInactiveHoursInUse) {
    if (additionalParams === void 0) { additionalParams = {}; }
    if (isCustomerTypesInUse === void 0) { isCustomerTypesInUse = true; }
    if (isInactiveHoursInUse === void 0) { isInactiveHoursInUse = true; }
    var mode = getState().mode;
    return Object.assign(additionalParams, {
        customer_types: mode.selected.length === 0 || !isCustomerTypesInUse ? null : mode.selected.toString(),
        inactive_hours_threshold: isInactiveHoursInUse ? mode.lastSyncDate.selected.value : null,
    });
};
export var getClusterHealthParams = function (additionalParams) {
    if (additionalParams === void 0) { additionalParams = {}; }
    var state = getState();
    var filter = state.multiFilter.filter;
    var globalFilteringParams = getGlobalFilteringParams(additionalParams);
    return Object.assign(globalFilteringParams, additionalParams, {
        customer_ids: filter.selected.customer.length > 0 ? filter.selected.customer.map(function (o) { return o.id; }).toString() : null,
        health_statuses: filter.selected.healthStatus.length > 0 ? filter.selected.healthStatus.map(function (h) { return h.id; }).toString() : null,
    });
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import get from 'lodash/get';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { DateUtils } from '@utils/date/DateUtils';
import StatsBarWithTags from '@commonComponents/stats-bar-with-tags/StatsBarWithTags';
import { StyledDescription } from '@components/telemetry-dashboard/telemetryMccMke/customer/telemetry-customer/styled';
import { STATIC_REPORTS_DATE_RANGES } from '@utils/date/dateConstants';
export var StyledGeneralInfoPdfPage = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: rgb(247, 247, 248);\n    padding: 20px 50px;\n    min-height: 1428px;\n    max-height: 1428px;\n    min-width: 1000px;\n    max-width: 1000px;\n    z-index: 1;\n\n    .stats-tagged {\n        margin: 20px 0;\n    }\n"], ["\n    background-color: rgb(247, 247, 248);\n    padding: 20px 50px;\n    min-height: 1428px;\n    max-height: 1428px;\n    min-width: 1000px;\n    max-width: 1000px;\n    z-index: 1;\n\n    .stats-tagged {\n        margin: 20px 0;\n    }\n"])));
export var StyledTop = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    align-items: end;\n    justify-content: space-between;\n"], ["\n    display: flex;\n    align-items: end;\n    justify-content: space-between;\n"])));
export var StyledGeneralTables = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    justify-content: space-between;\n\n    table {\n        width: 440px;\n        border-right: unset;\n    }\n\n    thead .ant-table-cell {\n        background-color: rgb(74, 160, 242);\n        font-weight: 600;\n        color: white;\n    }\n\n    tbody .ant-table-row {\n        td {\n            padding: 7px 15px;\n            border-right: 1px solid rgb(229, 224, 224);\n        }\n\n        &:nth-of-type(odd) {\n            background-color: rgb(215, 232, 252);\n        }\n\n        &:nth-of-type(even) {\n            background-color: white;\n        }\n    }\n"], ["\n    display: flex;\n    justify-content: space-between;\n\n    table {\n        width: 440px;\n        border-right: unset;\n    }\n\n    thead .ant-table-cell {\n        background-color: rgb(74, 160, 242);\n        font-weight: 600;\n        color: white;\n    }\n\n    tbody .ant-table-row {\n        td {\n            padding: 7px 15px;\n            border-right: 1px solid rgb(229, 224, 224);\n        }\n\n        &:nth-of-type(odd) {\n            background-color: rgb(215, 232, 252);\n        }\n\n        &:nth-of-type(even) {\n            background-color: white;\n        }\n    }\n"])));
export var StyledTable = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    h5 {\n        font-weight: 600;\n        padding: 10px 0;\n    }\n"], ["\n    h5 {\n        font-weight: 600;\n        padding: 10px 0;\n    }\n"])));
var COLUMNS = [
    {
        title: '#',
        dataIndex: 'index',
        key: 'index',
    },
    {
        title: 'Cluster Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Cluster Id',
        dataIndex: 'clusterId',
        key: 'clusterId',
    },
];
var DATE_FORMAT = 'Y MMM D';
var getDateRange = function (start, end) {
    return DateUtils.getDateString(start, DATE_FORMAT) + ' - ' + DateUtils.getDateString(end, DATE_FORMAT);
};
var GeneralInfoPdfPage = function (_a) {
    var calculatedDateRange = _a.calculatedDateRange, mkeClusters = _a.mkeClusters, mccClusters = _a.mccClusters, isActiveToggleOn = _a.isActiveToggleOn;
    var _b = useSelector(function (store) { return get(store, 'telemetry.customer', {}); }), sfAccount = _b.sfAccount, mccOverview = _b.mccOverview;
    var MccClustersDataSource = mccClusters.map(function (_a, index) {
        var clusterId = _a.clusterId, clusterName = _a.clusterName;
        return ({
            clusterId: clusterId,
            name: clusterName || clusterId,
            index: index + 1,
        });
    });
    var MkeClustersDataSource = mkeClusters.map(function (_a, index) {
        var clusterId = _a.clusterId, clusterName = _a.clusterName;
        return ({
            clusterId: clusterId,
            name: clusterName || clusterId,
            index: index + 1,
        });
    });
    var dateRange = '';
    if (isActiveToggleOn) {
        var _c = DateUtils.calculateDateRangeByOption(STATIC_REPORTS_DATE_RANGES.PREVIOUS_3_MONTHS), start = _c.start, end = _c.end;
        dateRange = getDateRange(start.timeStamp, end.timeStamp);
    }
    else {
        dateRange = getDateRange(calculatedDateRange.start.timeStamp, calculatedDateRange.end.timeStamp);
    }
    return (React.createElement(StyledGeneralInfoPdfPage, { id: "general_telemetry_customer_page" },
        React.createElement(StyledTop, null,
            React.createElement("div", null,
                React.createElement("h3", null, sfAccount === null || sfAccount === void 0 ? void 0 : sfAccount.name),
                React.createElement(StyledDescription, { className: "description" },
                    "ID: ", sfAccount === null || sfAccount === void 0 ? void 0 :
                    sfAccount.id)),
            React.createElement("div", null,
                React.createElement("b", null, "Date Range: "),
                dateRange)),
        React.createElement("hr", null),
        React.createElement(StatsBarWithTags, { mccOverview: mccOverview }),
        React.createElement(StyledGeneralTables, null,
            MccClustersDataSource.length > 0 ? (React.createElement(StyledTable, null,
                React.createElement("h5", null, "MCC Clusters"),
                React.createElement(Table, { bordered: true, columns: COLUMNS, dataSource: MccClustersDataSource, pagination: false }))) : (''),
            MkeClustersDataSource.length > 0 ? (React.createElement(StyledTable, null,
                React.createElement("h5", null, "MKE Clusters"),
                React.createElement(Table, { bordered: true, columns: COLUMNS, dataSource: MkeClustersDataSource, pagination: false }))) : (''))));
};
export default GeneralInfoPdfPage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

import { Toggle as MDSToggle } from '@mirantis/mds-toggle';
import React, { useEffect, useState } from 'react';
var Toggle = function (_a) {
    var onChange = _a.onChange, value = _a.value, initialChecked = _a.initialChecked, className = _a.className, _b = _a.label, label = _b === void 0 ? ' ' : _b;
    var _c = useState(initialChecked), checked = _c[0], toggleChecked = _c[1];
    var onClick = function () {
        toggleChecked(!checked);
        onChange(value, !checked);
    };
    useEffect(function () {
        toggleChecked(initialChecked);
    }, [initialChecked]);
    return React.createElement(MDSToggle, { value: checked, className: className, label: label, onClick: onClick });
};
export default Toggle;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import isEqual from 'lodash/isEqual';
import { message } from 'antd';
import { Dropdown } from '@mirantis/mds-dropdown';
import { optionsManageUsers } from '@configs/user-management';
import NoMDSLabel from '@commonComponents/no-mds-label/NoMDSLabel';
import TableButton from '@commonComponents/editable-table/TableButton';
import EditableInput from '@commonComponents/editable-input/EditableInput';
import { StyledStatusSelect } from '@app/common/react/styled/StyledStatusSelect';
import { Validation } from '@utils/validation/Validation';
import { DateUtils } from '@utils/date/DateUtils';
import { COLUMNS_KEYS } from './manageUsersTableConstants';
import { isSFPermissionGroup } from '@commonUtils/commonUtils';
import ExpandableTableCell from '@root/app/common/react/components/expandable-table-cell/ExpandableTableCell';
import { ACTIVE_INSTANCE_EXPANDED_TALE_CELL_HEIGHT } from '../../activeInstances/activeInstanceConstants';
var ManageUsersTableRow = function (_a) {
    var user = _a.user, columns = _a.columns, visibleColumns = _a.visibleColumns, allPermissionGroupsMap = _a.allPermissionGroupsMap, handleSave = _a.handleSave;
    var _b = React.useState(user), initial = _b[0], setInitial = _b[1];
    var _c = React.useState(user), state = _c[0], setState = _c[1];
    var _d = React.useState(true), disabled = _d[0], setDisabled = _d[1];
    var equal = isEqual(initial, state);
    var prefix = !disabled && equal ? 'red' : 'blue';
    var handleChange = function (value, key) {
        var _a;
        setState(__assign(__assign({}, state), (_a = {}, _a[key] = value, _a)));
    };
    var handleBtnClick = function () {
        if (disabled) {
            setDisabled(false);
        }
        if (!disabled && equal) {
            setDisabled(true);
        }
        if (!disabled && !equal) {
            if (Validation.noSpecSymbols(state.fullName)) {
                handleSave(state);
                setInitial(__assign({}, state));
                setDisabled(true);
            }
            else {
                return message.warning("The user name mustn't contain special symbols and should have from 3 to 64 characters");
            }
        }
    };
    var tds = [];
    var isEditable = false;
    var _loop_1 = function (column) {
        var key = column.key;
        if (visibleColumns.includes(key)) {
            if (column.isEditable) {
                isEditable = true;
            }
            var item = user[key];
            if (key === COLUMNS_KEYS.FULL_NAME && !isSFPermissionGroup()) {
                item = (React.createElement(EditableInput, { value: state[key], onChange: function (value) { return handleChange(value, key); }, disabled: disabled }));
            }
            if (key === COLUMNS_KEYS.LAST_SIGN_IN && item) {
                item = DateUtils.getDateLocalStringAutoTimezoneHandler(item);
            }
            if (key === COLUMNS_KEYS.IS_ACTIVE) {
                if (disabled) {
                    item = (React.createElement(StyledStatusSelect, { className: state[key] ? 'active' : 'inactive' }, state[key] ? optionsManageUsers.isActive[0].value : optionsManageUsers.isActive[1].value));
                }
                else {
                    item = (React.createElement(NoMDSLabel, null,
                        React.createElement(Dropdown, { showHelp: false, label: "No label", placeholder: "Select the status:", showPlaceholder: true, value: state[key]
                                ? optionsManageUsers.isActive[0].value
                                : optionsManageUsers.isActive[1].value, items: optionsManageUsers[key], 
                            //@ts-ignore
                            onItemSelect: function (prop) { return handleChange(prop.item.value === 'Active', key); } })));
                }
            }
            if (key === COLUMNS_KEYS.PERMISSION_IDS) {
                item = (React.createElement(ExpandableTableCell, { itemsLength: user[key].length, minHeight: ACTIVE_INSTANCE_EXPANDED_TALE_CELL_HEIGHT.MIN_HEIGHT }, [
                    React.createElement("div", { key: key, style: { overflow: 'hidden' } }, user[key].map(function (permissionItem) {
                        var _a;
                        return (React.createElement("div", { key: permissionItem }, (_a = allPermissionGroupsMap.get(permissionItem)) === null || _a === void 0 ? void 0 : _a.name));
                    })),
                ]));
            }
            tds.push(React.createElement("td", { key: key, className: "table__td" }, item));
        }
    };
    for (var _i = 0, columns_1 = columns; _i < columns_1.length; _i++) {
        var column = columns_1[_i];
        _loop_1(column);
    }
    if (isEditable) {
        tds.push(React.createElement("td", { key: user.id, className: "tooltip-handler" },
            React.createElement(TableButton, { tooltip: "Edit name and status", prefix: prefix, disabled: disabled, handleBtnClick: handleBtnClick })));
    }
    return React.createElement(React.Fragment, null, tds);
};
export default ManageUsersTableRow;

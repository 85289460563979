import get from 'lodash/get';
import { connect } from 'react-redux';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import Pagination from './Pagination';
import { getPaginationUserPreferencesFromUrl } from '@commonUtils/urlUtils/urlUtils';
var PAGINATION = URL_QUERIES.COMMON.PAGINATION;
var mapState = function (state, ownProps) {
    // we can override paginationQuery by a prop
    var paginationQuery = get(ownProps, 'paginationQuery', PAGINATION);
    return {
        paginationQuery: paginationQuery,
        paginationPreferences: getPaginationUserPreferencesFromUrl(paginationQuery),
    };
};
export default connect(mapState, null)(Pagination);

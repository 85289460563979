import React, { useEffect, useRef } from 'react';
import { Alert } from 'antd';
import { useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import { fetchShouldShowWarningBanner } from '@redux/dispatchers/warningBanner';
import { BANNER_STATUSES, WARNING_TYPES, CHECK_WARNING_BANNER_TIMEOUT } from './warningBannerConstants';
export var WarningBanner = function () {
    var prevTime = useSelector(function (store) { return store.warningBanner.banner.timestamp; });
    var prevTimeRef = useRef(0);
    useEffect(function () {
        if (!prevTime) {
            fetchShouldShowWarningBanner();
        }
        prevTimeRef.current = prevTime;
    }, [prevTime]);
    var handleWindowClick = function () {
        var newTime = Date.now();
        if (newTime - prevTimeRef.current > CHECK_WARNING_BANNER_TIMEOUT) {
            fetchShouldShowWarningBanner();
        }
    };
    var bannerStatus = useSelector(function (store) { return store.warningBanner.banner.status; });
    var shouldShowWarningBanner = !isNil(bannerStatus) && bannerStatus !== BANNER_STATUSES.FINE;
    var message = useSelector(function (store) { return store.warningBanner.banner.message; });
    useEffect(function () {
        document.addEventListener('click', handleWindowClick);
        return function () {
            document.removeEventListener('click', function () { return handleWindowClick; });
        };
    }, []);
    var type = WARNING_TYPES[bannerStatus];
    return (shouldShowWarningBanner && (React.createElement(Alert, { banner: true, 
        // @ts-ignore
        type: type, message: type, description: message ? message : 'MCNC is under maintenance, during this time data may be incorrect' })));
};

import get from 'lodash/get';
import queryString from 'query-string';
import Redirect from '@commonUtils/urlUtils/Redirect';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import { TELEMETRY_ALERTS_FILTERS_CONFIGURATION } from '@components/telemetry-dashboard/telemetryMccMke/customer/telemetry-alerts-filters/telemetryAlertsFiltersConstants';
var TELEMETRY_ALERTS_FILTER = URL_QUERIES.TELEMETRY.TELEMETRY_ALERTS_FILTER;
export var alertsFilterFromUrl = function () {
    var _a;
    var _b = get(queryString.parse(window.location.search), TELEMETRY_ALERTS_FILTER, '').split(','), type = _b[0], status = _b[1];
    if (!(type && status)) {
        return null;
    }
    return _a = {},
        _a[TELEMETRY_ALERTS_FILTERS_CONFIGURATION.TYPE.KEY] = type,
        _a[TELEMETRY_ALERTS_FILTERS_CONFIGURATION.STATUS.KEY] = status,
        _a;
};
export var setTelemetryAlertsFilterToUrl = function (type, status) {
    return Redirect.toCurrentPageWithQueryParams(TELEMETRY_ALERTS_FILTER, [type, status], '');
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
// TODO: replace with the new icon when it is added to MDS (PRODX-37689)
import { NoAccessIcon, variants } from '@mirantis/mds-icon';
import styled from '@emotion/styled';
var StyledContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: auto;\n    width: 600px;\n    text-align: center;\n\n    h1 {\n        margin-top: 25px;\n    }\n\n    h3 {\n        margin-top: 15px;\n        margin-bottom: 25px;\n    }\n"], ["\n    margin: auto;\n    width: 600px;\n    text-align: center;\n\n    h1 {\n        margin-top: 25px;\n    }\n\n    h3 {\n        margin-top: 15px;\n        margin-bottom: 25px;\n    }\n"])));
var ErrorBanner = function (_a) {
    var title = _a.title, message = _a.message, children = _a.children;
    return (React.createElement(StyledContainer, { className: "error-banner" },
        React.createElement(NoAccessIcon, { size: 124, variant: variants.DANGER }),
        React.createElement("h1", null, title),
        message ? React.createElement("h3", null, message) : null,
        children));
};
export default ErrorBanner;
var templateObject_1;

export var NO_CLUSTERS_FOUND = 'No clusters found';
export var COLUMNS_CLUSTERS = [
    {
        name: 'Customer Name',
        key: 'customerName',
        sortByKey: 'customer_name',
        isVisibleByDefault: true,
    },
    {
        name: 'Customer Id',
        key: 'customerId',
        sortByKey: 'customer_id',
        isVisibleByDefault: false,
    },
    {
        name: 'Cluster Id',
        key: 'id',
        sortByKey: 'id',
        isVisibleByDefault: true,
    },
    {
        name: 'Cluster Name',
        key: 'name',
        sortByKey: 'name',
        isVisibleByDefault: true,
    },
    {
        name: 'Instance Id',
        key: 'instanceId',
        sortByKey: 'instance_id',
        isVisibleByDefault: true,
    },
    {
        name: 'Instance Name',
        key: 'instanceName',
        sortByKey: 'instance_name',
        isVisibleByDefault: false,
    },
    {
        name: 'Namespace',
        key: 'namespace',
        sortByKey: 'namespace',
        isVisibleByDefault: false,
    },
    {
        name: 'Prefix',
        key: 'prefix',
        sortByKey: 'prefix',
        isVisibleByDefault: false,
    },
    {
        name: 'Version',
        key: 'version',
        sortByKey: 'version',
        isVisibleByDefault: false,
    },
    {
        name: 'Cluster Release',
        key: 'clusterRelease',
        sortByKey: 'cluster_release',
        isVisibleByDefault: false,
    },
    {
        name: 'Cluster Type',
        key: 'clusterType',
        sortByKey: 'cluster_type',
        isVisibleByDefault: false,
    },
    {
        name: 'k8sVersion',
        key: 'k8sVersion',
        sortByKey: 'k8s_version',
        isVisibleByDefault: false,
    },
    {
        name: 'Management Id',
        key: 'managementId',
        sortByKey: 'management_id',
        isVisibleByDefault: false,
    },
    {
        name: 'Mcc Version',
        key: 'mccVersion',
        sortByKey: 'mcc_version',
        isVisibleByDefault: false,
    },
    {
        name: 'Mke Version',
        key: 'mkeVersion',
        sortByKey: 'mke_version',
        isVisibleByDefault: false,
    },
    {
        name: 'Provider',
        key: 'provider',
        sortByKey: 'provider',
        isVisibleByDefault: false,
    },
    {
        name: 'Region Id',
        key: 'regionId',
        sortByKey: 'region_id',
        isVisibleByDefault: false,
    },
    {
        name: 'Instance Last Sync Date',
        key: 'instanceLastSyncDate',
        sortByKey: 'instance_last_sync_date',
        isVisibleByDefault: false,
    },
    {
        name: 'Cluster Last Sync Dat',
        key: 'clusterLastSyncDate',
        sortByKey: 'cluster_last_sync_date',
        isVisibleByDefault: false,
    },
];

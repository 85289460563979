var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import get from 'lodash/get';
import memoize from 'lodash/memoize';
import React, { useEffect, useState, useRef } from 'react';
import history from '@history/history';
import { connect } from 'react-redux';
import { resolveTagModifier } from '@app/helpers/resolveTagModifier';
import { getMKEAlerts, dropMKEAlertsToDefault } from '@actionCreators/telemetry';
import Table from '@commonComponents/table/Table';
import Loader from '@commonComponents/Loader';
import { getFilteredAlerts } from './telemetryAlertsTableUtils';
import { scrollIntoView } from '@commonUtils/commonUtils';
import { MESSAGES } from '@commonConstants/messagesConstants';
import { NO_ALERTS_FOUND, TO_ALERTS_TABLE_HASH } from './telemetryAlertsTableConstants';
var mapState = function (store) { return ({
    alerts: store.telemetry.customer.alerts,
}); };
var mapDispatch = {
    getMKEAlertsAction: getMKEAlerts,
    dropMKEAlerts: dropMKEAlertsToDefault,
};
var connector = connect(mapState, mapDispatch);
var TelemetryAlertsTable = function (_a) {
    var _b, _c, _d;
    var getMKEAlertsAction = _a.getMKEAlertsAction, dropMKEAlerts = _a.dropMKEAlerts, params = _a.params, filter = _a.filter, alerts = _a.alerts;
    var _e = useState(getFilteredAlerts(get(alerts, "".concat(params.cluster_id, ".data"), []), filter)), alertsTableData = _e[0], setAlertsTableData = _e[1];
    var _f = useState('description'), sortBy = _f[0], setSortBy = _f[1];
    var _g = useState('asc'), orderBy = _g[0], setOrderBy = _g[1];
    var ref = useRef();
    var normalizers = {
        status: function (str) {
            return React.createElement("div", { className: "tag tag--".concat(resolveTagModifier(str)) }, str);
        },
    };
    // eslint-disable-next-line no-shadow
    var handleSortClick = function (_a) {
        var orderBy = _a.orderBy, sortBy = _a.sortBy;
        setSortBy(sortBy);
        setOrderBy(orderBy);
    };
    useEffect(function () {
        var augmentedParams = __assign(__assign({}, params), { orderBy: orderBy, sortBy: sortBy });
        getMKEAlertsAction(augmentedParams);
        return function () {
            dropMKEAlerts();
        };
    }, [params.cluster_id, orderBy, sortBy]);
    useEffect(function () {
        var getFilteredAlertsTableData = memoize(function () {
            return getFilteredAlerts(get(alerts, "".concat(params.cluster_id, ".data"), []), filter);
        });
        setAlertsTableData(getFilteredAlertsTableData);
    }, [filter, (_b = alerts[params.cluster_id]) === null || _b === void 0 ? void 0 : _b.data]);
    useEffect(function () {
        if (history.location.hash === TO_ALERTS_TABLE_HASH.HASH_VALUE) {
            scrollIntoView(ref);
        }
    }, [ref, history.location.hash]);
    if ((_c = alerts[params.cluster_id]) === null || _c === void 0 ? void 0 : _c.loading) {
        return React.createElement(Loader, { message: MESSAGES.LOADING });
    }
    if (((_d = alerts[params.cluster_id]) === null || _d === void 0 ? void 0 : _d.data.length) === 0) {
        return React.createElement(Loader, { message: NO_ALERTS_FOUND });
    }
    return (React.createElement("div", { ref: ref },
        React.createElement(Table, { id: "alerts-".concat(params.cluster_id), data: alertsTableData, normalizers: normalizers, fallbackMessage: MESSAGES.NO_ALERTS, withSequenceNumbering: true, setSortableState: handleSortClick, sortBy: sortBy, orderBy: orderBy })));
};
export default connector(TelemetryAlertsTable);

import React, { useState } from 'react';
import { BackwardIcon, ForwardIcon } from '@mirantis/mds-icon';
import MonthCalendar from '@commonComponents/month-calendar/MonthCalendar';
import { DateUtils } from '@utils/date/DateUtils';
import { DAYS_CONTRACTIONS } from '@utils/date/dateConstants';
import { StyledDatePicker } from './StyledDatePicker';
import { Button } from '@mirantis/mds-button';
var DatePickerView = function (_a) {
    var show = _a.show, handleApply = _a.handleApply, endingDate = _a.endingDate, hoveredDate = _a.hoveredDate, startingDate = _a.startingDate, selectedDateEnd = _a.selectedDateEnd, selectedDateStart = _a.selectedDateStart, updatesState = _a.updatesState, handleMonthSwitch = _a.handleMonthSwitch;
    var _b = useState(false), isDirty = _b[0], setIsDirty = _b[1];
    var handleUpdatesState = function (value) {
        setIsDirty(true);
        updatesState(value);
    };
    var renderLeftPart = function () {
        var className = 'date-picker__switch';
        var leftDate = DateUtils.getMonthAndYearByDate(startingDate);
        return (React.createElement("div", { className: className },
            React.createElement("div", { className: "".concat(className, "__angle left"), "data-testid": "go-to-prev-month-button", onClick: function () { return handleMonthSwitch('reduce'); } },
                React.createElement(BackwardIcon, { size: 14 })),
            React.createElement("div", { className: "".concat(className, "__month left") },
                leftDate.m,
                " ",
                leftDate.y)));
    };
    var renderRightPart = function () {
        var className = 'date-picker__switch';
        var rightDate = DateUtils.getMonthAndYearByDate(endingDate);
        return (React.createElement("div", { className: className },
            React.createElement("div", { className: "".concat(className, "__month right") },
                rightDate.m,
                " ",
                rightDate.y),
            React.createElement("div", { className: "".concat(className, "__angle right"), onClick: function () { return handleMonthSwitch('increase'); } },
                React.createElement(ForwardIcon, { size: 14 }))));
    };
    var renderWeeks = function () {
        return DAYS_CONTRACTIONS.map(function (day) { return (React.createElement("div", { key: day, className: 'date-picker__part__week__day' }, day)); });
    };
    return show ? (React.createElement("div", { className: "filter__select__child__part" },
        React.createElement(StyledDatePicker, { className: "date-picker" },
            React.createElement("div", { className: "date-picker__part" },
                renderLeftPart(),
                React.createElement("div", { className: "date-picker__part__week" }, renderWeeks()),
                React.createElement(MonthCalendar, { date: startingDate, selectedDateStart: selectedDateStart, hoveredDate: hoveredDate, selectedDateEnd: selectedDateEnd, updateState: handleUpdatesState })),
            React.createElement("div", { className: "date-picker__part" },
                renderRightPart(),
                React.createElement("div", { className: "date-picker__part__week" }, renderWeeks()),
                React.createElement(MonthCalendar, { date: endingDate, selectedDateStart: selectedDateStart, hoveredDate: hoveredDate, selectedDateEnd: selectedDateEnd, updateState: handleUpdatesState }))),
        React.createElement(Button, { label: "Apply", disabled: !isDirty, onClick: handleApply, actionType: "primary", className: "apply-button" }))) : null;
};
export default DatePickerView;

import React, { useEffect } from 'react';
import isArray from 'lodash/isArray';
import classNames from 'classnames';
import { dropPaginationToDefault } from '@redux/dispatchers/pagination';
import { Button } from '@mirantis/mds-button';
import { Dropdown } from '@mirantis/mds-dropdown';
import { Checkbox, checkValues } from '@mirantis/mds-checkbox';
import Picker from '@commonComponents/picker/Picker';
import Tooltip from '@commonComponents/tooltip/Tooltip';
import { handleSetPickersSelectedItemsToUrl, handleResetFilterPickers } from './filterUtils';
import StyledFilter from '@app/common/react/styled/StyledFilter';
var Filter = function (props) {
    var _a;
    var name = props.name, params = props.params, pickers = props.pickers, multiFilter = props.multiFilter, children = props.children, className = props.className, initFilter = props.initFilter, currentMode = props.currentMode, doNotUpdate = props.doNotUpdate, doNotUnmount = props.doNotUnmount, childrenOrder = props.childrenOrder, unmountFilter = props.unmountFilter, setSearchTerm = props.setSearchTerm, isPaginationOn = props.isPaginationOn, _b = props.isResetButton, isResetButton = _b === void 0 ? true : _b, pickersWithTooltip = props.pickersWithTooltip, resetFilterPickers = props.resetFilterPickers, dropFilterToDefault = props.dropFilterToDefault, updateFilterPickers = props.updateFilterPickers, resetAdditionalParams = props.resetAdditionalParams, setSelectedPickerOptions = props.setSelectedPickerOptions, changeFilterOptionsCurrentPage = props.changeFilterOptionsCurrentPage, changeSingleSelectPickerOption = props.changeSingleSelectPickerOption, shouldNotResetPickersOnModeChange = props.shouldNotResetPickersOnModeChange;
    var _c = multiFilter.filter, configuration = _c.configuration, selected = _c.selected, hasToBeUpdated = _c.hasToBeUpdated;
    useEffect(function () {
        initFilter({ name: name, params: params });
        return function () {
            if (!doNotUnmount) {
                unmountFilter();
            }
        };
    }, []);
    useEffect(function () {
        if (shouldNotResetPickersOnModeChange) {
            return;
        }
        handleResetFilterPickers(resetFilterPickers, pickers, multiFilter, name);
    }, [currentMode === null || currentMode === void 0 ? void 0 : currentMode.selected, (_a = currentMode === null || currentMode === void 0 ? void 0 : currentMode.lastSyncDate) === null || _a === void 0 ? void 0 : _a.selected]);
    useEffect(function () {
        if (hasToBeUpdated && !doNotUpdate) {
            updateFilterPickers({
                hasToBeUpdated: hasToBeUpdated,
                params: params,
            });
        }
    }, [hasToBeUpdated]);
    useEffect(function () {
        handleSetPickersSelectedItemsToUrl(multiFilter.filter, params);
    }, [multiFilter.filter]);
    if (multiFilter.name !== name) {
        return null;
    }
    var handleSingleSelectPickerChange = function (pseudo, value) {
        changeSingleSelectPickerOption({
            pseudo: pseudo,
            value: value,
        });
    };
    var handleCheckboxChange = function (pseudo, value) {
        changeSingleSelectPickerOption({
            pseudo: pseudo,
            value: value,
        });
        if (isPaginationOn) {
            dropPaginationToDefault();
        }
    };
    var handlePickerApply = function (picker, options) {
        if (isPaginationOn) {
            dropPaginationToDefault();
        }
        setSelectedPickerOptions({
            picker: picker,
            options: options,
        });
    };
    var handleSearchTerm = function (optionsKey, termPseudo, value) {
        var _a;
        var term = (_a = {},
            _a[termPseudo.termPseudoUN] = value,
            _a);
        setSearchTerm({
            optionsKey: optionsKey,
            termPseudo: termPseudo,
            params: Object.assign(params, term),
        });
    };
    var handleChangeCurrentPage = function (optionsKey, page) {
        var newParams = Object.assign(params, {
            page: page,
        });
        changeFilterOptionsCurrentPage({
            optionsKey: optionsKey,
            params: newParams,
        });
    };
    var render = function () {
        var pickersNodes = pickers.map(function (picker) {
            var dependsOn = picker.dependsOn;
            if (picker.singleSelect) {
                return (React.createElement(Dropdown, { showHelp: false, className: "select", style: { width: 'calc((100vw - 310px) / 6)' }, key: picker.pseudo, label: picker.name, placeholder: picker.placeholder, showPlaceholder: true, value: isArray(selected[picker.pseudo]) ? selected[picker.pseudo][0] : selected[picker.pseudo], items: configuration[picker.pseudo].data.map(function (value) { return ({ value: value }); }), 
                    //@ts-ignore
                    onItemSelect: function (_a) {
                        var item = _a.item;
                        return handleSingleSelectPickerChange(picker.pseudo, item.value);
                    } }));
            }
            if (picker.type === 'checkbox') {
                if ((pickersWithTooltip === null || pickersWithTooltip === void 0 ? void 0 : pickersWithTooltip.pseudo) === picker.pseudo) {
                    return (React.createElement(Tooltip, { key: picker.pseudo, message: pickersWithTooltip.massage, place: "top", className: "" },
                        React.createElement(Checkbox, { className: "multi-filter-checkbox ".concat(picker.pseudo), key: picker.pseudo, label: picker.name, value: selected[picker.pseudo][0] === true ? checkValues.MIXED : checkValues.UNCHECKED, onChange: function () { return handleCheckboxChange(picker.pseudo, !selected[picker.pseudo][0]); } })));
                }
                return (React.createElement(Checkbox, { className: "multi-filter-checkbox ".concat(picker.pseudo), key: picker.pseudo, label: picker.name, value: selected[picker.pseudo][0] === true ? checkValues.MIXED : checkValues.UNCHECKED, onChange: function () { return handleCheckboxChange(picker.pseudo, !selected[picker.pseudo][0]); } }));
            }
            return (React.createElement(Picker, { key: picker.pseudo, disabled: dependsOn && selected[dependsOn].length === 0, picker: picker, selected: selected[picker.pseudo], options: configuration[picker.pseudo], handlePickerApply: handlePickerApply, handleSearchTerm: handleSearchTerm, handleChangeCurrentPage: handleChangeCurrentPage }));
        });
        var reset = function () {
            dropFilterToDefault();
            if (resetAdditionalParams) {
                resetAdditionalParams();
            }
        };
        var nodes = [children, pickersNodes];
        return (React.createElement(React.Fragment, null,
            !childrenOrder || childrenOrder === 'start' ? nodes : nodes.reverse(),
            isResetButton && (children || pickers.length > 0) ? (React.createElement(Button, { "data-testid": "reset", className: "filter__button reset", label: "Reset", onClick: reset })) : null));
    };
    return (React.createElement(StyledFilter, { className: classNames('filter', className), "data-testid": "filter" }, render()));
};
export default Filter;

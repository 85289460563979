import React, { useState } from 'react';
import classNames from 'classnames';
import { ExpandIcon } from '@mirantis/mds-icon';
import { Popover } from '@mirantis/mds-popover';
import StyledExpandableTableCell from './expandableTableCellStyled';
var ExpandableTableCell = function (_a) {
    var children = _a.children, minHeight = _a.minHeight, itemsLength = _a.itemsLength;
    var _b = useState(false), expanded = _b[0], toggleExpanded = _b[1];
    var height = expanded ? undefined : minHeight;
    return (React.createElement(StyledExpandableTableCell, { height: height },
        React.createElement(Popover, { content: children[1], className: "popup-detail-info", "aria-label": "details-popover", placement: "TOP_LEFT", opened: children[1] ? undefined : false }, children[0]),
        itemsLength > 1 && (React.createElement(ExpandIcon, { onClick: function () { return toggleExpanded(!expanded); }, style: { cursor: 'pointer' }, size: 18, className: classNames('expanded-icon', { expanded: expanded }) }))));
};
export default ExpandableTableCell;

import React, { useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import set from 'lodash/set';
import get from 'lodash/get';
import { Checkbox, checkValues } from '@mirantis/mds-checkbox';
import queryString from 'query-string';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { usePrevious } from '@app/hooks';
import { setFilterData } from '@redux/dispatchers/multiFilter';
import { getMultiFilterPickers } from '@configs/multiFilterConfig';
import FilterContainer from '@commonComponents/filter/FilterContainer';
import Redirect from '@commonUtils/urlUtils/Redirect';
import TrialCostsChart from './TrialCostsChart';
import { StyledFilter, StyledStacked } from './style';
import { getNewMultiFilter } from './costChartUtils';
var name = DASHBOARDS_SCHEMA.REPORTS.PAGES.TRIAL_STATISTIC.TABS.AWS_COSTS;
var TrialCostsContainer = function () {
    var _a, _b, _c, _d;
    var _e = useState(false), stacked = _e[0], setStacked = _e[1];
    var _f = useSelector(function (store) { return ({
        mode: store.mode,
        multiFilter: store.multiFilter,
    }); }), multiFilter = _f.multiFilter, mode = _f.mode;
    var newFilter = cloneDeep(multiFilter);
    var resetAdditionalParams = function () { return setStacked(false); };
    var prevYear = usePrevious(last((_b = (_a = multiFilter === null || multiFilter === void 0 ? void 0 : multiFilter.filter) === null || _a === void 0 ? void 0 : _a.selected) === null || _b === void 0 ? void 0 : _b.year));
    useEffect(function () {
        var _a, _b;
        var selectedYear = last((_b = (_a = multiFilter === null || multiFilter === void 0 ? void 0 : multiFilter.filter) === null || _a === void 0 ? void 0 : _a.selected) === null || _b === void 0 ? void 0 : _b.year);
        if (!isEqual(prevYear, selectedYear)) {
            var _c = getNewMultiFilter(selectedYear), newMonths = _c.newMonths, selectedMonth = _c.selectedMonth;
            set(newFilter, 'filter.configuration.month.data', newMonths);
            set(newFilter, 'filter.selected.month', [selectedMonth]);
            setFilterData(newFilter);
        }
    }, [last((_d = (_c = multiFilter === null || multiFilter === void 0 ? void 0 : multiFilter.filter) === null || _c === void 0 ? void 0 : _c.selected) === null || _d === void 0 ? void 0 : _d.year)]);
    useEffect(function () {
        var stackedFromUrl = get(queryString.parse(window.location.search), 'stacked', false);
        setStacked(stackedFromUrl);
    }, []);
    useEffect(function () {
        if (stacked) {
            Redirect.toCurrentPageWithQueryParams('stacked', [stacked.toString()]);
        }
        else {
            Redirect.toCurrentPageWithoutQueryParams(['stacked']);
        }
    }, [stacked]);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledFilter, null,
            React.createElement(FilterContainer, { childrenOrder: "end", name: name, params: {}, currentMode: mode, doNotUpdate: true, isPaginationOn: true, isOptionsHidden: true, multiFilter: multiFilter, pickers: getMultiFilterPickers(name), resetAdditionalParams: resetAdditionalParams },
                React.createElement(StyledStacked, null,
                    React.createElement(Checkbox, { label: "Stacked", key: "stacked-charts-picker", value: stacked ? checkValues.CHECKED : checkValues.UNCHECKED, onChange: function () { return setStacked(!stacked); } })))),
        React.createElement(TrialCostsChart, { stacked: stacked })));
};
export default memo(TrialCostsContainer);

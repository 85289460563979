import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { usePrevious } from '@app/hooks';
import { getSignUpsChartsConfigs } from './newSignUpsUtils';
import { StyledChartsWrapperNew } from '@app/common/react/styled/StyledChartsWrapperNew';
import Line from '@commonComponents/chart/Line/Line';
import { getChartOptions, getNormalizedChartData, getNormalizedABSTooltipValue, getNormalizedABSYValue, } from '@commonUtils/charts/chartUtils';
import { fetchCharts } from '@redux/dispatchers/charts';
import { CHART_TEXT, SIGN_UPS_CHARTS_KEYS } from './newSignUpsConstants';
var PSEUDO_CC = DASHBOARDS_SCHEMA.REPORTS.PAGES.NEW_SIGN_UPS_REPORT.PSEUDO_CC;
var mapState = function (store) { return ({
    timezone: store.userInfo.settings.initial.timezone,
    mode: store.mode.selected,
    crossPageFilter: store.crossPageFilter,
    lastSyncDate: store.mode.lastSyncDate.selected.value,
    charts: store.charts.signUps,
}); };
var connector = connect(mapState);
var NewSignUpsCharts = function (props) {
    var _a, _b, _c, _d;
    var params = {
        mode: props.mode,
        lastSyncDate: props.lastSyncDate,
        crossPageFilter: props.crossPageFilter,
        timezone: props.timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    var NEW_SIGN_UPS_REPORT = DASHBOARDS_SCHEMA.REPORTS.PAGES.NEW_SIGN_UPS_REPORT;
    var prevParams = usePrevious(params);
    useEffect(function () {
        var _a;
        var shouldFetchCharts = (!isEmpty(props.crossPageFilter.dateRange.calculatedDateRange) &&
            isEmpty(props.charts) &&
            !((_a = props.charts[SIGN_UPS_CHARTS_KEYS.SIGN_UPS]) === null || _a === void 0 ? void 0 : _a.loading)) ||
            !isEqual(prevParams, params);
        if (shouldFetchCharts && props.crossPageFilter.dashboardName === PSEUDO_CC) {
            fetchCharts(getSignUpsChartsConfigs(params));
        }
    }, [
        props.mode,
        props.timezone,
        props.lastSyncDate,
        (_b = (_a = props.crossPageFilter.granularity) === null || _a === void 0 ? void 0 : _a.selected) === null || _b === void 0 ? void 0 : _b.value,
        props.crossPageFilter.dateRange.calculatedDateRange,
    ]);
    var shouldNotRenderCharts = isEmpty(props.crossPageFilter.granularity.selected) ||
        props.crossPageFilter.dashboardName !== NEW_SIGN_UPS_REPORT.PSEUDO_CC;
    if (isEmpty(props.charts) || shouldNotRenderCharts) {
        return React.createElement("div", null);
    }
    return (React.createElement(StyledChartsWrapperNew, { className: "charts one" },
        React.createElement(Line, { loadingOut: (_c = props.charts[SIGN_UPS_CHARTS_KEYS.SIGN_UPS]) === null || _c === void 0 ? void 0 : _c.loading, title: CHART_TEXT.TITLE, datasets: [getNormalizedChartData((_d = props.charts[SIGN_UPS_CHARTS_KEYS.SIGN_UPS]) === null || _d === void 0 ? void 0 : _d.data)], message: CHART_TEXT.MESSAGE, chartOptions: getChartOptions({
                getNormalizedYValue: getNormalizedABSYValue,
                getNormalizedTooltipValue: getNormalizedABSTooltipValue,
            }) })));
};
export default connector(NewSignUpsCharts);

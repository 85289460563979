import { store } from '@redux/store';
import * as actionCreators from '@redux/action-creators';
export var toggleTelemetryClusterStatus = function (payload) {
    var action = actionCreators.telemetry.toggleTelemetryClusterStatus(payload);
    store.dispatch(action);
};
export var getTelemetryCustomer = function (payload) {
    var action = actionCreators.telemetry.getTelemetryCustomer(payload);
    store.dispatch(action);
};
export var resetTelemetryMkeCustomer = function () {
    var action = actionCreators.telemetry.resetTelemetryMkeCustomer();
    store.dispatch(action);
};
export var getMKERecommendations = function (payload) {
    var action = actionCreators.telemetry.getMKERecommendations(payload);
    store.dispatch(action);
};
export var dropMKEAlertsToDefault = function () {
    var action = actionCreators.telemetry.dropMKEAlertsToDefault();
    store.dispatch(action);
};
export var dropMKERecommendationsToDefault = function () {
    var action = actionCreators.telemetry.dropMKERecommendationsToDefault();
    store.dispatch(action);
};
export var getClusterSpecs = function (payload) {
    var action = actionCreators.telemetry.getClusterSpecs(payload);
    store.dispatch(action);
};

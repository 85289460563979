import get from 'lodash/get';
import queryString from 'query-string';
import { Location } from '@utils/location/Location';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import { LOCAL_STORAGE_KEYS } from '@commonConstants/localStorageConstancts';
import { Localstorage } from '@utils/localstorage/Localstorage';
import { DEFAULT_LAST_SYNC_DATE } from '@root/configs/modeSwitcher';
import { ALL } from '@commonComponents/customer-type-filter/customerTypesFilterConstants';
var _a = URL_QUERIES.COMMON, CUSTOMER_TYPE = _a.CUSTOMER_TYPE, LAST_SYNC_DATE = _a.LAST_SYNC_DATE;
export var isLastSyncDisabled = function () { return Location.isStaticReportsPage(); };
export var getCustomerTypesFromUrl = function () {
    return get(queryString.parse(window.location.search), CUSTOMER_TYPE, '').split(',').filter(Boolean);
};
export var getLastSyncDateFromUrlFromUrl = function () { return get(queryString.parse(window.location.search), LAST_SYNC_DATE, null); };
export var setCustomerTypeToStoreAndLocalStorage = function (setSelectedOptions, optionsPayload) {
    var finalOptionsPayload = optionsPayload.includes(ALL) ? [] : optionsPayload;
    Localstorage.set(LOCAL_STORAGE_KEYS.MODE, finalOptionsPayload);
    setSelectedOptions({ mode: finalOptionsPayload });
};
export var setLastSyncDateToStoreAndLocalStorage = function (setSelectedOptions, lastSyncDate) {
    Localstorage.set(LOCAL_STORAGE_KEYS.LAST_SYNC_DATE, JSON.stringify(lastSyncDate));
    setSelectedOptions({ lastSyncDate: lastSyncDate });
};
export var resetCustomerTypeAndLastSyncDate = function (setSelectedOptions) {
    Localstorage.set(LOCAL_STORAGE_KEYS.MODE, '');
    Localstorage.set(LOCAL_STORAGE_KEYS.LAST_SYNC_DATE, JSON.stringify(DEFAULT_LAST_SYNC_DATE));
    setSelectedOptions({
        mode: [],
        lastSyncDate: DEFAULT_LAST_SYNC_DATE,
    });
};

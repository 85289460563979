var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { HelpIcon, ExpandIcon, CollapseIcon, variants } from '@mirantis/mds-icon';
import Loader from '@commonComponents/Loader';
import { MESSAGES } from '@commonConstants/messagesConstants';
import { NO_RECOMMENDATIONS_FOUND, LESS_INFO_BUTTON_TEXT, MORE_INFO_BUTTON_TEXT } from './recommendationsConstants';
import { StyledRecommendations } from './StyledRecommendations';
var renderExtraInfo = function (extraInfoItems, extraClassName) { return (React.createElement("div", { className: extraClassName }, extraInfoItems.map(function (_a, index) {
    var value = _a.value, link = _a.link;
    return (React.createElement("span", { key: value },
        React.createElement("a", { target: "_blank", href: link, rel: "noreferrer" }, value),
        extraInfoItems.length > 0 && index < extraInfoItems.length - 1 ? ', ' : '.'));
}))); };
var Recommendations = function (props) {
    var _a = useState([]), opened = _a[0], setOpened = _a[1];
    var _b = get(props.recommendations, props.params.cluster_id, { data: [], loading: false }), loading = _b.loading, data = _b.data;
    useEffect(function () {
        var params = props.params;
        props.getMKERecommendations(params);
        return function () {
            props.dropMKERecommendationsToDefault();
        };
    }, [props.params.cluster_id]);
    if (loading) {
        return React.createElement(Loader, { message: MESSAGES.LOADING });
    }
    if (data.length === 0) {
        return React.createElement(Loader, { message: NO_RECOMMENDATIONS_FOUND });
    }
    var handleOpen = function (idx) {
        var arr = __spreadArray([], opened, true);
        var index = arr.indexOf(idx);
        if (index > -1) {
            arr.splice(index, 1);
        }
        else {
            arr.push(idx);
        }
        setOpened(arr);
    };
    var recommendations = data.map(function (recommendation, idx) {
        var extraInfoItems = recommendation.extraInfoItems, type = recommendation.type, description = recommendation.description;
        var isOpened = opened.includes(idx);
        var descriptionClassName = classNames({
            recommendations__rec__more: true,
            opened: isOpened,
        });
        var extraClassName = classNames({
            recommendations__rec__extra: true,
            opened: isOpened,
        });
        var toggleText = isOpened ? LESS_INFO_BUTTON_TEXT : MORE_INFO_BUTTON_TEXT;
        var Icon = isOpened ? CollapseIcon : ExpandIcon;
        return (React.createElement("div", { key: uuid(), className: "recommendations__rec" },
            React.createElement("div", { className: "recommendations__rec__wrapper" },
                React.createElement("div", { className: "recommendations__rec__ico" },
                    React.createElement(HelpIcon, { size: 20, variant: variants.INFO })),
                React.createElement("div", { className: "recommendations__rec__descr" },
                    React.createElement("b", null, type),
                    " \u2014 ",
                    description),
                description && (React.createElement("div", { className: descriptionClassName, onClick: function () { return handleOpen(idx); } },
                    toggleText,
                    React.createElement("div", { className: "ico" },
                        React.createElement(Icon, { size: 18, variant: variants.INFO }))))),
            !isEmpty(extraInfoItems) && renderExtraInfo(extraInfoItems, extraClassName)));
    });
    return React.createElement(StyledRecommendations, { className: "recommendations" }, recommendations);
};
export default Recommendations;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import moment from 'moment/moment';
import toString from 'lodash/toString';
import { DateUtils } from '@utils/date/DateUtils';
import { STATIC_REPORTS_DATE_RANGES } from '@utils/date/dateConstants';
import { getFormattedNumberAfterDot } from '@commonUtils/commonUtils';
import { X_AXIS_VALUE_FORMAT_WITHOUT_HOER } from '@commonConstants/chartsConstants';
import { MCC_CHARTS_KEYS, CHART_DASHBOARDS as CHART_DASHBOARDS_MCC, } from '@components/telemetry-dashboard/charts/mccClustersCharts/mccClusterChartsConstants';
import { MKE_CHARTS_KEYS, CHART_DASHBOARDS as CHART_DASHBOARDS_MKE, } from '@components/telemetry-dashboard/charts/mkeClusterscharts/mkeClusterChartsConstants';
export var getAutoTableConfig = function () { return ({
    styles: { cellWidth: 'wrap', fontSize: 8 },
    headStyles: { fillColor: [74, 160, 242] },
    alternateRowStyles: { fillColor: [215, 232, 252] },
    tableLineColor: [247, 247, 248],
    tableLineWidth: 0.1,
    theme: 'grid',
    showHeader: 'everyPage',
    columnStyles: {
        1: {
            columnWidth: 25,
        },
    },
}); };
export var getFormattedData = function (data, timeKey) {
    return data.reduce(function (result, item) {
        var timestamp = moment(item[timeKey]).valueOf();
        result[timestamp] = __assign({ timestamp: timestamp }, item);
        return result;
    }, {});
};
export var getParams = function (timezone, clusterIds, licenseKeys, calculatedDateRange, isActiveToggleOn) {
    var t = timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone;
    var _a = DateUtils.calculateDateRangeByOption(STATIC_REPORTS_DATE_RANGES.PREVIOUS_3_MONTHS), start = _a.start, end = _a.end;
    return {
        timezone: t,
        granularity: '1 day',
        licenseKeys: licenseKeys.join(','),
        clusterIds: clusterIds.join(','),
        start: isActiveToggleOn ? start.timeStamp : calculatedDateRange === null || calculatedDateRange === void 0 ? void 0 : calculatedDateRange.start.timeStamp,
        end: isActiveToggleOn ? end.timeStamp : calculatedDateRange === null || calculatedDateRange === void 0 ? void 0 : calculatedDateRange.end.timeStamp,
    };
};
export var getDates = function (mccData, availabilityData) {
    return uniq(__spreadArray(__spreadArray([], Object.values(mccData), true), Object.values(availabilityData), true).map(function (_a) {
        var timestamp = _a.timestamp;
        return timestamp;
    })
        .sort(function (a, b) { return a - b; }));
};
export var getNormalizedData = function (dates, mccData, availabilityData, fieldKeys) {
    return dates.map(function (date) { return (__assign({ date: DateUtils.getDateLocalStringAutoTimezoneHandler(date, X_AXIS_VALUE_FORMAT_WITHOUT_HOER) }, Object.values(fieldKeys).reduce(function (result, key) {
        var finalValues = __assign(__assign({}, get(mccData, date, {})), get(availabilityData, date, {}));
        var value = toString(get(finalValues, key, ''));
        if (value.length === 0) {
            // @ts-ignore
            result[key] = '-';
            return result;
        }
        // @ts-ignore
        result[key] = getFormattedNumberAfterDot(value * 100) + ' %';
        return result;
    }, {}))); });
};
export var shouldGeneratePDf = function (charts, mccClusterIds, mkeClusterIds, mosClusterIds) {
    var isAllMccClusterLoaded = mccClusterIds.every(function (clusterId) {
        var _a;
        return ((_a = Object.values(get(charts, "".concat(CHART_DASHBOARDS_MCC.mccCluster, ".loadedCanvas.").concat(clusterId), {}))) === null || _a === void 0 ? void 0 : _a.length) ===
            Object.values(MCC_CHARTS_KEYS).length - (mosClusterIds.includes(clusterId) ? 4 : 5);
    });
    var isAllMkeClusterLoaded = mkeClusterIds.every(function (clusterId) {
        var _a;
        return ((_a = Object.values(get(charts, "".concat(CHART_DASHBOARDS_MKE.mkeCluster, ".loadedCanvas.").concat(clusterId), {}))) === null || _a === void 0 ? void 0 : _a.length) ===
            Object.values(MKE_CHARTS_KEYS).length;
    });
    return ((mccClusterIds.length === 0 || isAllMccClusterLoaded) && (mkeClusterIds.length === 0 || isAllMkeClusterLoaded));
};

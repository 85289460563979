import React from 'react';
import { EditIcon, CloseIcon, DoneIcon, variants } from '@mirantis/mds-icon';
import Tooltip from '@commonComponents/tooltip/Tooltip';
var TableButton = function (props) {
    var resolveIcon = function () {
        if (!props.disabled && props.prefix !== 'red')
            return React.createElement(DoneIcon, { variant: variants.SUCCESS, size: 13, className: "save" });
        if (props.prefix === 'red')
            return React.createElement(CloseIcon, { variant: variants.DANGER, size: 13 });
        return React.createElement(EditIcon, { variant: variants.INFO, size: 16 });
    };
    return (React.createElement(Tooltip, { message: props.disabled ? props.tooltip : '' },
        React.createElement("div", { className: "table__button", onClick: props.handleBtnClick, "data-testid": "table-button" }, resolveIcon())));
};
export default TableButton;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import React, { useEffect, useState, memo } from 'react';
import Slider from '@root/app/components/telemetry-dashboard/slider/Slider';
import { INITIAL_ALERTS_FILTER_STATE, TELEMETRY_ALERTS_FILTERS_CONFIGURATION } from './telemetryAlertsFiltersConstants';
import { setTelemetryAlertsFilterToUrl } from './telementryAlertsfilterUtils';
import { StyledTelemetryAlertsFilter } from './StyledTelemetryAlertsFilter';
var TYPE = TELEMETRY_ALERTS_FILTERS_CONFIGURATION.TYPE, STATUS = TELEMETRY_ALERTS_FILTERS_CONFIGURATION.STATUS;
export var AlertsFilterContext = React.createContext(INITIAL_ALERTS_FILTER_STATE);
var TelemetryAlertsFilters = function (_a) {
    var onChange = _a.onChange, alertsFilterFromUrl = _a.alertsFilterFromUrl;
    var _b = useState(INITIAL_ALERTS_FILTER_STATE), filter = _b[0], setFilters = _b[1];
    useEffect(function () {
        if (!isNil(alertsFilterFromUrl) && !isEqual(alertsFilterFromUrl, filter)) {
            setFilters(alertsFilterFromUrl);
        }
    }, []);
    useEffect(function () {
        setTelemetryAlertsFilterToUrl(filter[TYPE.KEY], filter[STATUS.KEY]);
        onChange(filter);
    }, [filter]);
    var onChangeType = function (value) {
        var _a;
        var newFilters = __assign(__assign({}, filter), (_a = {}, _a[TYPE.KEY] = value, _a));
        setFilters(newFilters);
    };
    var onChangeStatus = function (value) {
        var _a;
        var newFilters = __assign(__assign({}, filter), (_a = {}, _a[STATUS.KEY] = value, _a));
        setFilters(newFilters);
    };
    return (React.createElement(StyledTelemetryAlertsFilter, { className: "telemetry_alerts_filter" },
        React.createElement("div", { className: "filer" },
            React.createElement("span", { className: "title" }, TYPE.TITLE),
            React.createElement(Slider, { defaultActive: filter.type, items: TYPE.SWITCHERS, onChange: onChangeType })),
        React.createElement("div", { className: "filer" },
            React.createElement("span", { className: "title" }, STATUS.TITLE),
            React.createElement(Slider, { defaultActive: filter.status, items: STATUS.SWITCHERS, onChange: onChangeStatus }))));
};
export default memo(TelemetryAlertsFilters);

var BREAKPOINTS = {
    md: 767,
    lg: 991,
    xlg: 1199,
    xxlg: 1500,
    xxxlg: 1650,
    xxxxlg: 1850,
};
export var MQ = Object.keys(BREAKPOINTS)
    .map(function (key) { return [key, BREAKPOINTS[key]]; })
    .reduce(function (prev, _a) {
    var key = _a[0], breakpoint = _a[1];
    prev[key] = "@media (max-width: ".concat(breakpoint, "px)");
    return prev;
}, {});

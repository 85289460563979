export var SyncActions;
(function (SyncActions) {
    SyncActions["SET_CITIES"] = "SET_CITIES";
    SyncActions["SET_USERS"] = "SET_USERS";
    SyncActions["RESET_CITIES_LOADING"] = "RESET_CITIES_LOADING";
    SyncActions["UPDATE_USER"] = "UPDATE_USER";
    SyncActions["UPDATE_CUSTOMER"] = "UPDATE_CUSTOMER";
    SyncActions["UPDATE_INSTANCE"] = "UPDATE_INSTANCE";
    SyncActions["PUSH_NEW_USER"] = "PUSH_NEW_USER";
    SyncActions["SET_INSTANCES"] = "SET_INSTANCES";
    SyncActions["SET_CUSTOMERS"] = "SET_CUSTOMERS";
    SyncActions["SET_SINGLE_CUSTOMER_MODE"] = "SET_SINGLE_CUSTOMER_MODE";
    SyncActions["SET_SINGLE_CUSTOMER_INSTANCES"] = "SET_SINGLE_CUSTOMER_INSTANCES";
    SyncActions["RESET_SINGLE_CUSTOMER_MODE"] = "RESET_SINGLE_CUSTOMER_MODE";
})(SyncActions || (SyncActions = {}));
export var AsyncActions;
(function (AsyncActions) {
    AsyncActions["GET_CITIES"] = "GET_CITIES";
    AsyncActions["GET_USERS"] = "GET_USERS";
    AsyncActions["PATCH_CUSTOMER"] = "PATCH_CUSTOMER";
    AsyncActions["PATCH_USER"] = "PATCH_USER";
    AsyncActions["PATCH_INSTANCE"] = "PATCH_INSTANCE";
    AsyncActions["ADD_NEW_USER"] = "ADD_NEW_USER";
    AsyncActions["GET_INSTANCES"] = "GET_INSTANCES";
    AsyncActions["GET_CUSTOMERS"] = "GET_CUSTOMERS";
    AsyncActions["GET_SINGLE_CUSTOMER_INSTANCES"] = "GET_SINGLE_CUSTOMER_INSTANCES";
})(AsyncActions || (AsyncActions = {}));

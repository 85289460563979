var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Toggle from '@commonComponents/toggle/Toggle';
import { getToggleAllClustersDefaultChecked } from './commonTelemetryDashboardUtils';
import { HIDE_ALL, SHOW_ALL } from '@components/telemetry-dashboard/telemetryCustomerConstants';
export var StyledToggleAllClustersButton = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    font-size: 18px;\n    padding: 15px 0;\n\n    .mds-toggle-label {\n        font-size: 18px;\n        margin-top: 0;\n        cursor: pointer;\n    }\n"], ["\n    font-size: 18px;\n    padding: 15px 0;\n\n    .mds-toggle-label {\n        font-size: 18px;\n        margin-top: 0;\n        cursor: pointer;\n    }\n"])));
var ToggleAllClustersButton = function (_a) {
    var onChange = _a.onChange, visibleClustersIds = _a.visibleClustersIds, allClustersIds = _a.allClustersIds;
    var _b = useState(getToggleAllClustersDefaultChecked(visibleClustersIds, allClustersIds)), checked = _b[0], toggleChecked = _b[1];
    useEffect(function () {
        toggleChecked(getToggleAllClustersDefaultChecked(visibleClustersIds, allClustersIds));
    }, [visibleClustersIds]);
    var handleOnChange = function () {
        onChange();
    };
    var label = checked ? HIDE_ALL : SHOW_ALL;
    return (React.createElement(StyledToggleAllClustersButton, null,
        React.createElement(Toggle, { initialChecked: checked, className: "manage-cluster-toggle-all", onChange: handleOnChange, label: label })));
};
export default ToggleAllClustersButton;
var templateObject_1;

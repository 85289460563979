import React, { useState, useRef } from 'react';
import { StyledItem } from './styled';
var PageSelector = function (_a) {
    var maxPages = _a.maxPages, handleSubmit = _a.handleSubmit, hide = _a.hide;
    var _b = useState(''), value = _b[0], setValue = _b[1];
    var ref = useRef(null);
    var handleClick = function (evt) {
        if (ref && ref.current) {
            if (!ref.current.contains(evt.target)) {
                hide();
            }
        }
    };
    React.useEffect(function () {
        window.addEventListener('mousedown', handleClick);
        return function () {
            window.removeEventListener('mousedown', handleClick);
        };
    }, []);
    var handleChange = function (input) {
        if (input === '') {
            setValue(input);
        }
        if (!/^[0-9]+$/.test(input)) {
            return;
        }
        if (value === '' && input === 0) {
            return;
        }
        if (+input > maxPages) {
            return;
        }
        setValue(input);
    };
    var handleS = function (evt) {
        evt.preventDefault();
        if (value !== '') {
            handleSubmit(value);
        }
    };
    return (React.createElement("div", { className: "page-selector", ref: ref },
        React.createElement("div", { className: "page-selector__title" }, "GO"),
        React.createElement("div", { className: "page-selector__input" },
            React.createElement("form", { onSubmit: handleS },
                React.createElement("input", { type: "text", alt: "page_num", value: value, onChange: function (e) { return handleChange(e.target.value); }, autoFocus: true })))));
};
var Dots = function (_a) {
    var maxPages = _a.maxPages, onChange = _a.onChange;
    var _b = useState(false), active = _b[0], setActive = _b[1];
    var handleClick = function () {
        setActive(true);
    };
    var handleSubmit = function (value) {
        onChange(value);
        setActive(false);
    };
    var hide = function () {
        setActive(false);
    };
    return (React.createElement(StyledItem, { className: "pagination__item dots", onClick: handleClick },
        active ? React.createElement(PageSelector, { handleSubmit: handleSubmit, maxPages: maxPages, hide: hide }) : null,
        React.createElement("span", null, "...")));
};
export default Dots;

import { getUserStatusStandard, getSourceTypeStandard, getCustomerTypesStandard, getGranularityStandard, getTelemetryAlertsFilterTypeStandard, getTelemetryAlertsFilterStatusStandard, } from './queryParamsStandartsSelectors';
export var STANDARDS = {
    STATUS: {
        STANDARD: getUserStatusStandard(),
    },
    SOURCE_TYPE: {
        STANDARD: getSourceTypeStandard(),
    },
    GRANULARITY_STANDARD: {
        STANDARD: getGranularityStandard(),
    },
    CUSTOMER_TYPE: {
        STANDARD: getCustomerTypesStandard(),
        DEFAULT: ['All'],
    },
    TELEMETRY_ALERTS_FILTER_TYPE: {
        STANDARD: getTelemetryAlertsFilterTypeStandard(),
        DEFAULT: ['All'],
    },
    TELEMETRY_ALERTS_FILTER_STATUS: {
        STANDARD: getTelemetryAlertsFilterStatusStandard(),
        DEFAULT: ['All'],
    },
    TELEMETRY_MANAGE_CLUSTER_LIST: {
        STANDARD: ['MKE', 'MCC'],
        DEFAULT: ['mke'],
    },
    ACTIVE_MKE_LICENCE: {
        STANDARD: ['true', 'false'],
        DEFAULT: ['false'],
    },
    HAS_ACTIVE_MKE_ALERTS: {
        STANDARD: ['true', 'false'],
        DEFAULT: ['false'],
    },
    TIMEZONE: {
        STANDARD: ['UTC', 'Local'],
        DEFAULT: ['Local'],
    },
    DATE_RANGE: {
        STANDARD: /(\d{4})-(\d{2})-(\d{2})/,
    },
    MANAGE_CUSTOMERS_ACTIVE_TAB: {
        STANDARD: ['Customers', 'Instances'],
        DEFAULT: ['Customers'],
    },
    ALERT_STATUS: {
        STANDARD: ['Auto-Solved', 'In Progress', 'New'],
    },
    ALERT_PRIORITY: {
        STANDARD: ['070 Unknown', '080 Warning', '090 Critical'],
    },
    PAGINATION: {
        STANDARD: {
            ROWS: ['5', '10', '15', '20', '25', '50', '100'],
            PAGE: /^[0-9]+$/,
        },
        DEFAULT: {
            PAGE: 1,
            ROWS: 10,
        },
    },
};

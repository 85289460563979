import { store } from '@redux/store';
import * as actionCreators from '@actionCreators/index';
export var fetchPermissionsAllowedPagesIds = function () {
    var action = actionCreators.permissions.fetchPermissionsAllowedPagesIds();
    store.dispatch(action);
};
export var setActivePermissionsGroupId = function (payload) {
    var action = actionCreators.permissions.setActivePermissionsGroupId(payload);
    store.dispatch(action);
};
export var setPermissionGroupUsers = function (payload) {
    var action = actionCreators.permissions.setPermissionGroupUsers(payload);
    store.dispatch(action);
};
export var fetchPermissionGroups = function (payload) {
    var action = actionCreators.permissions.fetchPermissionGroups(payload);
    store.dispatch(action);
};
export var setPermissionsAllowedPagesTags = function (payload) {
    var action = actionCreators.permissions.setPermissionsAllowedPagesTags(payload);
    store.dispatch(action);
};
export var setPermissionsGroup = function (payload) {
    var action = actionCreators.permissions.setPermissionsGroup(payload);
    store.dispatch(action);
};
export var fetchPermissionGroupAssignedUsers = function (payload) {
    var action = actionCreators.permissions.fetchPermissionGroupAssignedUsers(payload);
    store.dispatch(action);
};
export var setPermissionsAllUsers = function (payload) {
    var action = actionCreators.permissions.setPermissionsAllUsers(payload);
    store.dispatch(action);
};
export var resetPermissionsAllUsers = function () {
    var action = actionCreators.permissions.resetPermissionsAllUsers();
    store.dispatch(action);
};
export var fetchPermissionsAllUsers = function (payload) {
    var action = actionCreators.permissions.fetchPermissionsAllUsers(payload);
    store.dispatch(action);
};
export var createPermissionGroup = function (payload) {
    var action = actionCreators.permissions.createPermissionGroup(payload);
    store.dispatch(action);
};
export var fetchPermissionsGroup = function (payload) {
    var action = actionCreators.permissions.fetchPermissionsGroup(payload);
    store.dispatch(action);
};
export var editPermissionGroup = function (payload) {
    var action = actionCreators.permissions.editPermissionGroup(payload);
    store.dispatch(action);
};
export var deletePermissionGroup = function (payload) {
    var action = actionCreators.permissions.deletePermissionGroup(payload);
    store.dispatch(action);
};

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import filter from 'lodash/filter';
import Loader from '@commonComponents/Loader';
import ColumnController from '@commonComponents/table/ColumnController';
import { MESSAGES } from '@commonConstants/messagesConstants';
import { getTableDataWithSequenceNumbering } from '@commonUtils/tableUtils';
import TableTH from './TableTH';
import TableTR from './TableTR';
import { StyledTable } from './styled';
var Table = function (props) {
    var id = props.id, data = props.data, title = props.title, summary = props.summary, columnControl = props.columnControl, pagination = props.pagination, summaryNormalizers = props.summaryNormalizers, _a = props.extraCustomColumns, extraCustomColumns = _a === void 0 ? [] : _a, _b = props.customizeValue, customizeValue = _b === void 0 ? function (key, value) { return value; } : _b, _c = props.visibleColumnsKeysCallback, visibleColumnsKeysCallback = _c === void 0 ? function (value) { return value; } : _c;
    var finalData = getTableDataWithSequenceNumbering(data, pagination);
    var columns = finalData.columns, rows = finalData.rows;
    var summaryRows = summary
        ? Object.fromEntries(Object.keys(rows[0]).map(function (property) { return [property, rows.reduce(function (s, o) { return s + o[property]; }, 0)]; }))
        : '';
    var normalizers = props.normalizers || {};
    var visibleColumnsByDefault = filter(columns, function (_a) {
        var isVisibleByDefault = _a.isVisibleByDefault, isStatic = _a.isStatic;
        return isVisibleByDefault || isStatic;
    });
    var shouldShowTable = rows.length > 0;
    var _d = useState(visibleColumnsByDefault), visibleColumns = _d[0], setVisibleColumns = _d[1];
    useEffect(function () {
        visibleColumnsKeysCallback(visibleColumns);
    }, [visibleColumns]);
    if (!shouldShowTable) {
        return React.createElement(Loader, { message: props.fallbackMessage || MESSAGES.NO_DATA_FOUND });
    }
    var visibleColumnsKeys = visibleColumns.map(function (_a) {
        var key = _a.key;
        return key;
    });
    var theadClass = classNames({
        table__thead: true,
        control: props.columnControl,
    });
    var handleSetVisibleColumns = function (newVisibleColumnsKeys) {
        var newVisibleColumns = filter(columns, function (_a) {
            var key = _a.key, isStatic = _a.isStatic;
            return newVisibleColumnsKeys.includes(key) || isStatic;
        });
        setVisibleColumns(newVisibleColumns);
    };
    var renderTable = function () {
        var table = (React.createElement("table", { id: id },
            React.createElement("thead", { className: theadClass },
                React.createElement("tr", { className: "table__tr" },
                    React.createElement(TableTH, { sortable: props.sortable, columns: visibleColumns, sortBy: props.sortBy, orderBy: props.orderBy, setSortableState: props.setSortableState }))),
            React.createElement("tbody", { className: "table__tbody" },
                React.createElement(TableTR, { rows: rows, summary: summary, summaryRows: summaryRows, columns: visibleColumns, normalizers: normalizers, customizeValue: customizeValue, extraCustomColumns: extraCustomColumns, summaryNormalizers: summaryNormalizers }))));
        return columnControl ? React.createElement("div", { className: "table__control-wrapper" }, table) : table;
    };
    return (React.createElement(StyledTable, { className: "table" },
        title ? React.createElement("h2", { className: "table__title" }, title) : null,
        columnControl && (React.createElement(ColumnController, { columns: columns, setVisibleColumns: handleSetVisibleColumns, visibleColumnsKeys: visibleColumnsKeys })),
        renderTable()));
};
export default Table;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { Input } from '@mirantis/mds-input';
import { DoneIcon, CloseIcon, EditIcon, variants } from '@mirantis/mds-icon';
export var StyledChildren = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n\n    .mds-input-label-part {\n        display: none;\n    }\n\n    .mds-input-field-body {\n        width: 250px;\n    }\n\n    span {\n        height: 28px;\n        padding-top: 0;\n    }\n\n    div {\n        height: 28px;\n        margin: 0;\n    }\n\n    svg {\n        margin: 5px;\n        cursor: pointer;\n\n        &.disabled {\n            pointer-events: none;\n            stroke: gray !important;\n        }\n    }\n\n    input {\n        font-size: 16px;\n    }\n"], ["\n    display: flex;\n    align-items: center;\n\n    .mds-input-label-part {\n        display: none;\n    }\n\n    .mds-input-field-body {\n        width: 250px;\n    }\n\n    span {\n        height: 28px;\n        padding-top: 0;\n    }\n\n    div {\n        height: 28px;\n        margin: 0;\n    }\n\n    svg {\n        margin: 5px;\n        cursor: pointer;\n\n        &.disabled {\n            pointer-events: none;\n            stroke: gray !important;\n        }\n    }\n\n    input {\n        font-size: 16px;\n    }\n"])));
var isValid = function (newValue, oldValue) { return newValue.length > 0 && oldValue !== newValue; };
var EditableValue = function (props) {
    var _a = useState(props.children), value = _a[0], setValue = _a[1];
    var _b = useState(false), isEditMode = _b[0], toggleEditMode = _b[1];
    var onSubmit = function () {
        toggleEditMode(false);
        props.onSubmit(value);
    };
    var onCancel = function () {
        toggleEditMode(false);
        setValue(props.children);
    };
    return isEditMode ? (React.createElement(StyledChildren, null,
        React.createElement(Input, { showHelp: false, label: "label", value: value, onChange: function (evt) { return setValue(evt.target.value); } }),
        React.createElement(CloseIcon, { role: "close-icon", size: 13, variant: variants.DANGER, onClick: onCancel }),
        React.createElement(DoneIcon, { role: "checkmark-icon", size: 13, onClick: onSubmit, variant: isValid(value, props.children) ? variants.SUCCESS : undefined, className: classNames('save', { disabled: !isValid(value, props.children) }) }))) : (React.createElement(StyledChildren, null,
        props.children,
        ' ',
        React.createElement(EditIcon, { variant: variants.INFO, role: "edit-icon", size: 13, onClick: function () { return toggleEditMode(true); } })));
};
export default EditableValue;
var templateObject_1;

import React from 'react';
import { BackwardIcon } from '@mirantis/mds-icon';
import { useTheme } from '@emotion/react';
import GoBackButton from '@commonComponents/go-back-button/GoBackButton';
import { getUrlWithoutParams } from '@commonUtils/urlUtils/urlUtils';
import { isSFPermissionGroup } from '@commonUtils/commonUtils';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import { StyledGoBackButton, StyledButtonPlaceholder } from '@app/common/react/styled/StyledGoBackButton';
var _a = URL_QUERIES.COMMON, ACCOUNT_ID = _a.ACCOUNT_ID, DATE_RANGE = _a.DATE_RANGE;
var _b = URL_QUERIES.TELEMETRY, TELEMETRY_ALERTS_FILTER = _b.TELEMETRY_ALERTS_FILTER, TELEMETRY_ACTIVE_CLUSTER_ID = _b.TELEMETRY_ACTIVE_CLUSTER_ID, INACTIVE_HOURS_THRESHOLD = _b.INACTIVE_HOURS_THRESHOLD, IS_ACTIVE_CLUSTERS = _b.IS_ACTIVE_CLUSTERS;
var TelemetryBackButton = function () {
    var theme = useTheme();
    return isSFPermissionGroup() ? (React.createElement(StyledButtonPlaceholder, null)) : (React.createElement(StyledGoBackButton, null,
        React.createElement(BackwardIcon, { size: 14, color: theme.v1.color.action.tertiary.normal.enabled.text }),
        React.createElement(GoBackButton, { text: "Back to List", className: "go-back-btn", to: getUrlWithoutParams([
                ACCOUNT_ID,
                TELEMETRY_ALERTS_FILTER,
                TELEMETRY_ACTIVE_CLUSTER_ID,
                INACTIVE_HOURS_THRESHOLD,
                DATE_RANGE,
                IS_ACTIVE_CLUSTERS,
            ]) })));
};
export default TelemetryBackButton;

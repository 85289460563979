export var LOCAL_STORAGE_KEYS;
(function (LOCAL_STORAGE_KEYS) {
    LOCAL_STORAGE_KEYS["MODE"] = "mode";
    LOCAL_STORAGE_KEYS["LAST_SYNC_DATE"] = "lastSyncDate";
    LOCAL_STORAGE_KEYS["IS_CLEAR"] = "isClear";
    LOCAL_STORAGE_KEYS["TOKEN"] = "token";
    LOCAL_STORAGE_KEYS["SHOULD_LOG_RENDERS"] = "should_log_renders";
    LOCAL_STORAGE_KEYS["MOCKED_INTERNAL_TOKEN"] = "mocked_internal_token";
    LOCAL_STORAGE_KEYS["USER_PREFERENCES"] = "user-preferences";
    LOCAL_STORAGE_KEYS["NAV_BAR_COLLAPSED"] = "nav-bar-collapsed";
    LOCAL_STORAGE_KEYS["IS_TESTING_MODE"] = "is_testing_mode";
    LOCAL_STORAGE_KEYS["TELEMETRY_MKE_MCC_SORTED"] = "telemetry_mke_mcc_sorted";
})(LOCAL_STORAGE_KEYS || (LOCAL_STORAGE_KEYS = {}));

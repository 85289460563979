import React, { useState } from 'react';
import get from 'lodash/get';
import { useDebouncedEffect } from '@app/hooks';
import Table from '@commonComponents/table/Table';
import PaginationContainer from '@commonComponents/pagination/PaginationContainer';
import { PAGINATION_NAMES } from '@configs/DASHBOARD_PSEUDOS';
import { COLUMNS, INITIAL_STATE } from './trialReportConstants';
import { getTrialReport } from '@redux/dispatchers/trialReport';
import { changeCurrentPage } from '@redux/dispatchers/pagination';
import TableLoadingStates from '@commonComponents/table-loading-states/TableLoadingStates';
import { normalizers, getCallApiParams } from './trialReportUtls';
var TrialReportTable = function (props) {
    var loading = props.loading, timezone = props.timezone, pagination = props.pagination, multiFilter = props.multiFilter, crossPageFilter = props.crossPageFilter, statusesSelected = props.statusesSelected, isMirantisSelected = props.isMirantisSelected, clusterCreatedSelected = props.clusterCreatedSelected;
    var _a = useState({
        sortBy: INITIAL_STATE.sortBy,
        orderBy: INITIAL_STATE.orderBy,
    }), state = _a[0], setState = _a[1];
    var timeStart = get(crossPageFilter, 'dateRange.calculatedDateRange.start.timeStamp', 0);
    var timeEnd = get(crossPageFilter, 'dateRange.calculatedDateRange.end.timeStamp', 0);
    var finalTimezone = timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone;
    var data = {
        columns: COLUMNS,
        rows: props.data || [],
    };
    var callAPI = function () {
        var params = getCallApiParams(finalTimezone, pagination, multiFilter, state, timeStart, timeEnd, statusesSelected, isMirantisSelected, clusterCreatedSelected);
        getTrialReport(params);
    };
    useDebouncedEffect(function () {
        callAPI();
    }, [
        state,
        timezone,
        pagination.limit,
        pagination.currentPage,
        multiFilter.filter.searchTerm,
        crossPageFilter.dateRange.calculatedDateRange,
        statusesSelected,
        isMirantisSelected,
        clusterCreatedSelected,
    ]);
    var onPaginationChange = function (page) {
        changeCurrentPage({
            paginationName: PAGINATION_NAMES.TRIAL_REPORT,
            currentPage: page,
        });
    };
    var onLimitChange = function (limit) {
        changeCurrentPage({
            paginationName: PAGINATION_NAMES.TRIAL_REPORT,
            currentPage: 1,
            limit: limit,
        });
    };
    return (React.createElement("div", { className: "trial-report-table" },
        React.createElement(TableLoadingStates, { data: (data === null || data === void 0 ? void 0 : data.rows) || [], count: pagination.limit, loading: loading, empty: {
                paddingTop: 10,
            } },
            React.createElement(React.Fragment, null,
                React.createElement(Table, { sortable: true, data: data, orderBy: state.orderBy, sortBy: state.sortBy, setSortableState: function (s) { return setState(s); }, normalizers: normalizers, pagination: pagination, withSequenceNumbering: true, columnControl: true }),
                React.createElement(PaginationContainer, { withLimits: true, onLimitChange: onLimitChange, pagination: pagination, onChange: onPaginationChange })))));
};
export default TrialReportTable;

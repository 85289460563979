var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/** @jsx jsx */
import React from 'react';
import classNames from 'classnames';
import { useTheme, css, jsx } from '@emotion/react';
import AsideLogo from '@commonComponents/aside-logo/AsideLogo';
import { MQ } from '@emotionConstants';
var getBase = function (theme) { return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 435px;\n    display: flex;\n    flex-direction: column;\n    background-color: ", ";\n    box-shadow: ", ";\n\n    ", " {\n        width: 375px;\n    }\n\n    ", " {\n        width: 100%;\n    }\n"], ["\n    width: 435px;\n    display: flex;\n    flex-direction: column;\n    background-color: ", ";\n    box-shadow: ", ";\n\n    ", " {\n        width: 375px;\n    }\n\n    ", " {\n        width: 100%;\n    }\n"])), theme.color.background.header1, theme.shadow.box[0].css, MQ['xxlg'], MQ['lg']); };
var large = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 730px;\n\n    ", " {\n        width: 550px;\n    }\n\n    ", " {\n        width: 450px;\n    }\n\n    ", " {\n        width: 100%;\n    }\n"], ["\n    width: 730px;\n\n    ", " {\n        width: 550px;\n    }\n\n    ", " {\n        width: 450px;\n    }\n\n    ", " {\n        width: 100%;\n    }\n"])), MQ['xxlg'], MQ['xlg'], MQ['lg']);
var contentCenter = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    height: 100%;\n    align-items: center;\n"], ["\n    display: flex;\n    height: 100%;\n    align-items: center;\n"])));
var Body = function (props) {
    var isLarge = props.type === 'large';
    var theme = useTheme();
    var className = classNames({
        aside: true,
        'aside--large': props.type === 'large',
    });
    var renderChildren = function () {
        return props.alignContentCenter ? (
        // @ts-ignore
        jsx("div", { className: "aside__content-center", css: contentCenter }, props.children)) : (props.children);
    };
    return (
    // @ts-ignore
    jsx("div", { className: className, css: [getBase(theme), isLarge && large] },
        jsx(AsideLogo, null),
        renderChildren()));
};
export var Aside = React.memo(Body);
var templateObject_1, templateObject_2, templateObject_3;

import get from 'lodash/get';
import queryString from 'query-string';
import Redirect from '@commonUtils/urlUtils/Redirect';
import { URL_QUERIES } from '@commonConstants/urlConstants';
var MANAGE_CUSTOMERS_ACTIVE_TAB = URL_QUERIES.MANAGE.MANAGE_CUSTOMERS_ACTIVE_TAB;
export var setActiveManageCustomersTabToUrl = function (tabTitle) {
    return Redirect.toCurrentPageWithQueryParams(MANAGE_CUSTOMERS_ACTIVE_TAB, [tabTitle], '');
};
export var getManageCustomersActiveTabFromUrl = function () {
    return get(queryString.parse(window.location.search), MANAGE_CUSTOMERS_ACTIVE_TAB, null);
};

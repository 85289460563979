import React from 'react';
import { connect } from 'react-redux';
import Redirect from '@commonUtils/urlUtils/Redirect';
import { getMultiFilterPickers } from '@configs/multiFilterConfig';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import FilterContainer from '@commonComponents/filter/FilterContainer';
import Search from '@commonComponents/search/Search';
import { SEARCH_PLACEHOLDER } from '@commonConstants/commonConstants';
import { StringUtils } from '@utils/string/StringUtils';
import { changeTermV2 } from '@redux/dispatchers/multiFilter';
import { setSelectedOptions } from '@redux/dispatchers/mode';
import CustomerTypesFilterContainer from '@commonComponents/customer-type-filter/CustomerTypesFilterContainer';
import { resetCustomerTypeAndLastSyncDate } from '@commonComponents/customer-type-filter/customerTypesFilterUtils';
import { URL_QUERIES } from '@commonConstants/urlConstants';
var SEARCH_VALUE = URL_QUERIES.COMMON.SEARCH_VALUE;
var OPENSTACK_CLUSTERS_USAGE = DASHBOARDS_SCHEMA.DASHBOARDS.PAGES.OPENSTACK_CLUSTERS_USAGE;
var mapState = function (store) { return ({
    mode: store.mode,
    multiFilter: store.multiFilter,
}); };
var connector = connect(mapState);
export var OpenstackClusterUsageFilter = function (_a) {
    var mode = _a.mode, multiFilter = _a.multiFilter;
    var name = OPENSTACK_CLUSTERS_USAGE.PSEUDO_CC;
    var params = {
        limit: 20,
        source: 'CONTAINER_CLOUD',
        inactive_hours_threshold: mode.lastSyncDate.selected.value,
        customer_types: mode.selected[0] === 'All' ? null : mode.selected.toString(),
    };
    var onSearchChange = function (value) {
        var length = StringUtils.countStringLengthWithoutSpaces(value);
        if (value === '' || length > 2) {
            changeTermV2({
                tableName: name,
                pseudo: 'searchTerm',
                value: value,
            });
        }
    };
    var resetAdditional = function () {
        Redirect.toCurrentPageWithoutQueryParams([SEARCH_VALUE]);
        changeTermV2({
            tableName: OPENSTACK_CLUSTERS_USAGE.PSEUDO_CC,
            pseudo: 'searchTerm',
            value: '',
        });
        resetCustomerTypeAndLastSyncDate(setSelectedOptions);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Search, { label: "Search", placeholder: SEARCH_PLACEHOLDER, withDelay: 1500, max: 64, onChange: onSearchChange }),
        React.createElement(FilterContainer, { name: name, childrenOrder: "start", params: params, currentMode: mode, multiFilter: multiFilter, resetAdditionalParams: resetAdditional, pickers: getMultiFilterPickers(name), shouldNotResetPickersOnModeChange: true },
            React.createElement(CustomerTypesFilterContainer, { isResetButton: false }))));
};
export default connector(OpenstackClusterUsageFilter);

var checkRegion = function (region, customer) {
    if (!customer.city) {
        return false;
    }
    return region === 'All World' || region === customer.city.region;
};
var checkStatus = function (status, customer) {
    return status === 'All Statuses' || status === customer.status;
};
var checkCustomer = function (name, customer) {
    return name === 'All Customers' || name === "".concat(customer.name, " (...").concat(customer.id.substr(customer.id.length - 4), ")");
};
export var filterCustomersByOptions = function (payload) {
    return payload.customers.filter(function (customer) {
        return checkRegion(payload.region, customer) &&
            checkStatus(payload.status, customer) &&
            checkCustomer(payload.customer, customer);
    });
};
export var filterCustomersByRegion = function (payload) {
    return payload.customers.filter(function (customer) { return checkRegion(payload.region, customer); });
};
export var extractCustomersOptionsByRegion = function (payload) {
    var customers = filterCustomersByRegion(payload);
    return customers.map(function (customer) { return customer.name; });
};

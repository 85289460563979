var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import get from 'lodash/get';
import has from 'lodash/has';
import uuid from 'react-uuid';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { Table as AntdTable } from 'antd';
import { Popover } from '@mirantis/mds-popover';
import Tooltip from '@commonComponents/tooltip/Tooltip';
import { resolveTagModifier } from '@app/helpers/resolveTagModifier';
import { getDataAttributes } from '@commonComponents/gropped-column-table/gropedTableUtils';
import { CLUSTER_OVERVIEW_COLUMN_ID } from '@commonComponents/gropped-column-table/GropedTableConstants';
import RowControls from './RowControls';
import { tableOnClick, getClassName } from './gropedTableUtils';
import { StyledTag } from '@app/common/react/styled/StyledTag';
export var NODES_DETAILS = {
    controlPlaneNodes: 'Control plane nodes',
    computeNodes: 'Compute nodes',
    osdNodes: 'Osd nodes',
};
var NODES_DETAILS_KEYS = Object.keys(NODES_DETAILS);
var StyledPopover = styled(Popover)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    .mds-popover-frame {\n        padding: 12px 12px 20px;\n        background-color: #fff;\n        border-radius: 8px;\n\n        table {\n            border-right: 0;\n        }\n    }\n"], ["\n    .mds-popover-frame {\n        padding: 12px 12px 20px;\n        background-color: #fff;\n        border-radius: 8px;\n\n        table {\n            border-right: 0;\n        }\n    }\n"])));
var GroupedTableBody = function (_a) {
    var data = _a.data, subtitles = _a.subtitles, normalizers = _a.normalizers, cellOnCLick = _a.cellOnCLick, toggleStatus = _a.toggleStatus, activeClusterId = _a.activeClusterId, extraHeadColumns = _a.extraHeadColumns, isClustersManaging = _a.isClustersManaging, setActiveClusterId = _a.setActiveClusterId, _b = _a.startRowNumber, startRowNumber = _b === void 0 ? 1 : _b, pdfGeneratingClusterOnchange = _a.pdfGeneratingClusterOnchange, bluredColumnsKeys = _a.bluredColumnsKeys;
    var tableBody = data.map(function (cluster, index) {
        var arr = [
            React.createElement("td", __assign({ key: uuid(), "parent-colomn-id": CLUSTER_OVERVIEW_COLUMN_ID }, getDataAttributes(cluster.clusterId, null)),
                React.createElement("div", __assign({ className: "groped-table__row-title" }, getDataAttributes(cluster.clusterId, null)),
                    React.createElement("div", { className: "left" },
                        React.createElement(RowControls, { clusterId: cluster.clusterId, activeClusterId: activeClusterId, isClustersManaging: isClustersManaging, toggleStatus: toggleStatus, onPdfGenerateSelect: function () { return pdfGeneratingClusterOnchange(cluster.clusterId); } }),
                        React.createElement("span", __assign({ className: "cluster-index" }, getDataAttributes(cluster.clusterId, null)), index + startRowNumber)),
                    React.createElement("div", { className: "right" },
                        React.createElement("div", { className: "types" },
                            (cluster === null || cluster === void 0 ? void 0 : cluster.child) && React.createElement("div", { className: "child-cluster-mark" }),
                            get(cluster, 'types', []).map(function (type) { return (React.createElement("span", __assign({ key: type, className: classNames('type', type) }, getDataAttributes(cluster.clusterId, null)), type)); })),
                        extraHeadColumns.map(function (_a) {
                            var className = _a.className, key = _a.key, isTooltip = _a.isTooltip;
                            return has(cluster, key) && (React.createElement(Tooltip, { key: key, className: key, disable: !isTooltip, place: "top", message: has(normalizers, key)
                                    ? normalizers[key](cluster[key], cluster, true)
                                    : cluster[key] },
                                React.createElement("div", __assign({ className: classNames(className, cluster[key]) }, getDataAttributes(cluster.clusterId, null)), has(normalizers, key)
                                    ? normalizers[key](cluster[key], cluster)
                                    : cluster[key])));
                        })))),
        ];
        var tds = subtitles.map(function (_a) {
            var clickable = _a.clickable, tag = _a.tag, key = _a.key, parentColumnKey = _a.parentColumnKey;
            var active = cluster.clusterId === activeClusterId;
            var finalDataAttributes = getDataAttributes(cluster.clusterId, null);
            var value = (bluredColumnsKeys === null || bluredColumnsKeys === void 0 ? void 0 : bluredColumnsKeys.includes(key)) ? ('-') : key in normalizers ? (normalizers[key](cluster[key], cluster)) : key === 'nodeCount' && NODES_DETAILS_KEYS.some(function (key) { return Object.keys(cluster).includes(key); }) ? (React.createElement(StyledPopover, { content: React.createElement(AntdTable, { columns: NODES_DETAILS_KEYS.map(function (key) { return ({
                        key: key,
                        dataIndex: key,
                        title: NODES_DETAILS[key],
                        className: key,
                    }); }), dataSource: [
                        {
                            key: 'nodes-details-values',
                            controlPlaneNodes: cluster.controlPlaneNodes,
                            computeNodes: cluster.computeNodes,
                            osdNodes: cluster.osdNodes,
                        },
                    ], pagination: false }), placement: "TOP_LEFT", "aria-label": "nodes-details-popover" },
                React.createElement("span", null, cluster[key]))) : (cluster[key]);
            return (React.createElement("td", __assign({ key: key, "colomn-id": key, "parent-colomn-id": parentColumnKey, className: classNames('cluster_name', key, {
                    clickable: clickable && active,
                }) }, finalDataAttributes), tag ? (React.createElement(StyledTag, null,
                React.createElement("div", __assign({}, finalDataAttributes, { className: "tag tag--".concat(resolveTagModifier(cluster[key])) }), value))) : (React.createElement("span", __assign({}, finalDataAttributes), value))));
        });
        var trElement = (React.createElement("tr", { key: uuid(), role: "tr-cellOnCLick", className: getClassName(cluster.clusterId, isClustersManaging, activeClusterId), onClick: function (event) {
                return tableOnClick(event, isClustersManaging, activeClusterId, setActiveClusterId, cellOnCLick);
            } }, __spreadArray([arr], tds, true)));
        return cluster.clusterName ? (React.createElement(Tooltip, { className: "cluster-name", place: "left", key: cluster.clusterId + Date.now(), message: cluster.clusterName, wrapperClassName: "table-row-wrapper" }, trElement)) : (trElement);
    });
    return React.createElement(React.Fragment, null, tableBody);
};
export default GroupedTableBody;
var templateObject_1;

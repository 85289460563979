var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import { Alert, Button } from 'antd';
import styled from '@emotion/styled';
import BannerDescription from '@commonComponents/api-error-banner/BannerDescription';
export var StyledActionButton = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding-right: 10px;\n"], ["\n    padding-right: 10px;\n"])));
var ApiErrorBanner = function (_a) {
    var message = _a.message, detailInfo = _a.detailInfo;
    var _b = useState(false), expanded = _b[0], toggleExpanded = _b[1];
    var shouldShowDetailButton = detailInfo === null || detailInfo === void 0 ? void 0 : detailInfo.url;
    return (React.createElement(Alert, { type: "error", key: message, message: message, description: React.createElement(BannerDescription, { detailInfo: detailInfo, expanded: expanded }), action: shouldShowDetailButton && (React.createElement(StyledActionButton, null,
            React.createElement(Button, { size: "small", onClick: function () { return toggleExpanded(!expanded); }, danger: true }, "Detail"))), banner: true, closable: true }));
};
export default ApiErrorBanner;
var templateObject_1;

export var optionsManageUsers = {
    isActive: [{ value: 'Active' }, { value: 'Inactive' }],
};
export var optionsCustomerSettings = {
    status: [{ value: 'OK' }, { value: 'WARNING' }, { value: 'BAD' }, { value: 'UNKNOWN' }],
};
export var getColorByStatus = function (palette) { return ({
    OK: palette.good,
    WARNING: palette.warning,
    Warning: palette.warning,
    BAD: palette.bad,
    Bad: palette.bad,
    UNKNOWN: palette.cpu,
    Unknown: palette.cpu,
}); };
export var COLOR_BY_STATUS = {
    OK: '#43B899',
    WARNING: '#F0BD8D',
    Warning: '#F0BD8D',
    BAD: '#C82828',
    Bad: '#C82828',
    UNKNOWN: '#5A6774',
    Unknown: '#5A6774',
};

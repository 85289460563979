import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDebouncedEffect, useLocalStorage } from '@app/hooks';
import { DateUtils } from '@utils/date/DateUtils';
import { getUrlWithParams } from '@commonUtils/urlUtils/urlUtils';
import { isTelemetryCustomers, getTelemetryCustomersParams } from './telemetryCustomersUtils';
import TableLoadingStates from '@commonComponents/table-loading-states/TableLoadingStates';
import Table from '@commonComponents/table/Table';
import PaginationContainer from '@commonComponents/pagination/PaginationContainer';
import { GET_TELEMETRY_CUSTOMERS_TIMEOUT, COLUMNS } from './telementryCustomersConstants';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import { LOCAL_STORAGE_KEYS } from '@commonConstants/localStorageConstancts';
var TELEMETRY_MKE_MCC_SORTED = LOCAL_STORAGE_KEYS.TELEMETRY_MKE_MCC_SORTED;
var ACCOUNT_ID = URL_QUERIES.COMMON.ACCOUNT_ID;
var TELEMETRY_CUSTOMERS = DASHBOARDS_SCHEMA.MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.FILTER_PSEUDOS.TELEMETRY_CUSTOMERS;
var TelemetryCustomersTable = function (props) {
    var _a;
    var multiFilter = props.multiFilter, timezone = props.timezone, telemetry = props.telemetry, pagination = props.pagination, changeCurrentPage = props.changeCurrentPage, getTelemetryCustomers = props.getTelemetryCustomers, prevAccountId = props.prevAccountId;
    var _b = useLocalStorage(TELEMETRY_MKE_MCC_SORTED, { sortBy: 'created_at', orderBy: 'desc' }), state = _b[0], setState = _b[1];
    useEffect(function () {
        var node = document.getElementById(prevAccountId);
        if (node) {
            node.scrollIntoView({ block: 'center' });
        }
    }, [prevAccountId]);
    var callAPI = function () {
        var params = getTelemetryCustomersParams(multiFilter.filter, pagination, timezone, state);
        getTelemetryCustomers(params);
    };
    useDebouncedEffect(function () { return isTelemetryCustomers(multiFilter.name) && callAPI(); }, [state, timezone, multiFilter, pagination.limit, pagination.currentPage], GET_TELEMETRY_CUSTOMERS_TIMEOUT);
    var data = {
        columns: COLUMNS,
        rows: telemetry.customers.data,
    };
    var className = classNames({
        'telemetry-customers-table': true,
        loading: telemetry.customers.loading,
    });
    var onPaginationChange = function (page) {
        changeCurrentPage({
            paginationName: TELEMETRY_CUSTOMERS,
            currentPage: page,
        });
    };
    var onLimitChange = function (limit) {
        changeCurrentPage({
            paginationName: TELEMETRY_CUSTOMERS,
            currentPage: 1,
            limit: limit,
        });
    };
    var normalizers = {
        id: function (id) {
            var _a;
            return React.createElement(Link, { to: getUrlWithParams((_a = {}, _a[ACCOUNT_ID] = id, _a)) }, id);
        },
        createdAt: function (str) {
            return DateUtils.getDateLocalStringAutoTimezoneHandler(str);
        },
        lastModified: function (str) {
            return DateUtils.getDateLocalStringAutoTimezoneHandler(str);
        },
    };
    return (React.createElement("div", { className: className },
        React.createElement(TableLoadingStates, { count: pagination.limit, loading: telemetry.customers.loading, data: ((_a = telemetry === null || telemetry === void 0 ? void 0 : telemetry.customers) === null || _a === void 0 ? void 0 : _a.data) || [], empty: {
                paddingTop: 10,
            } },
            React.createElement(React.Fragment, null,
                React.createElement(Table, { sortable: true, data: data, orderBy: state.orderBy, sortBy: state.sortBy, setSortableState: function (s) { return setState(s); }, normalizers: normalizers, pagination: pagination, withSequenceNumbering: true, columnControl: true }),
                React.createElement(PaginationContainer, { withLimits: true, onLimitChange: onLimitChange, pagination: pagination, onChange: onPaginationChange })))));
};
export default TelemetryCustomersTable;

import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedEffect } from '@app/hooks';
import Line from '@commonComponents/chart/Line/Line';
import { fetchCharts, dropChartsToDefault } from '@redux/dispatchers/charts';
import { getChartOptions, getNormalizedABSYValue, getNormalizedABSTooltipValue } from '@commonUtils/charts/chartUtils';
import { CHART_MESSAGES } from '@commonConstants/chartsConstants';
import { CHART_DASHBOARDS } from './installationChartConstants';
import { getNormalizedAttempts, getNormalizedSuccesses, getInstallationsChartsConfigs, getNormalizedBinaryExecutions, } from './installationChartsUtils';
import { StyledInstallationCharts } from './StyledInstallationCharts';
import { StyledChartsWrapperNew } from '@app/common/react/styled/StyledChartsWrapperNew';
var InstallationCharts = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var _o = useSelector(function (store) { return ({
        timezone: store.userInfo.userPreferences.timezone,
        charts: store.charts.installations,
        customerTypes: store.mode.selected,
        crossPageFilter: store.crossPageFilter,
    }); }), charts = _o.charts, customerTypes = _o.customerTypes, crossPageFilter = _o.crossPageFilter, timezone = _o.timezone;
    var params = {
        customerTypes: customerTypes,
        granularity: (_b = (_a = crossPageFilter.granularity) === null || _a === void 0 ? void 0 : _a.selected) === null || _b === void 0 ? void 0 : _b.value,
        end: (_d = (_c = crossPageFilter.dateRange.calculatedDateRange) === null || _c === void 0 ? void 0 : _c.end) === null || _d === void 0 ? void 0 : _d.timeStamp,
        start: (_f = (_e = crossPageFilter.dateRange.calculatedDateRange) === null || _e === void 0 ? void 0 : _e.start) === null || _f === void 0 ? void 0 : _f.timeStamp,
        timezone: timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    var loading = (_g = charts[CHART_DASHBOARDS.installations]) === null || _g === void 0 ? void 0 : _g.loading;
    useDebouncedEffect(function () {
        if (!loading && params.granularity) {
            fetchCharts(getInstallationsChartsConfigs(params));
        }
        return function () {
            dropChartsToDefault(CHART_DASHBOARDS.installations);
        };
    }, [
        crossPageFilter.dateRange.calculatedDateRange,
        (_j = (_h = crossPageFilter.granularity) === null || _h === void 0 ? void 0 : _h.selected) === null || _j === void 0 ? void 0 : _j.value,
        props.activeClusterId,
        customerTypes,
        timezone,
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledInstallationCharts, null,
            React.createElement(StyledChartsWrapperNew, { className: "styled_charts_wrapper one" },
                React.createElement(Line, { loadingOut: loading, title: "Installations", datasetsTitles: [
                        'Binary executions - License-check event count',
                        'Attempts - Bootstrap-start event count',
                        'Successes - Bootstrap-end event with the ok status count',
                    ], datasets: [
                        getNormalizedBinaryExecutions((_k = charts[CHART_DASHBOARDS.installations]) === null || _k === void 0 ? void 0 : _k.data),
                        getNormalizedAttempts((_l = charts[CHART_DASHBOARDS.installations]) === null || _l === void 0 ? void 0 : _l.data),
                        getNormalizedSuccesses((_m = charts[CHART_DASHBOARDS.installations]) === null || _m === void 0 ? void 0 : _m.data),
                    ], message: CHART_MESSAGES.INSTALLATION_CHART_MESSAGE, chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedABSYValue,
                        getNormalizedTooltipValue: getNormalizedABSTooltipValue,
                    }) })))));
};
export default memo(InstallationCharts);

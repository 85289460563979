import React from 'react';
import get from 'lodash/get';
import { Dropdown } from '@mirantis/mds-dropdown';
var Granularity = function (_a) {
    var selected = _a.selected, options = _a.options, onChange = _a.onChange;
    var normalizedOptions = options.map(function (option) { return ({
        value: option.pseudo,
    }); });
    var handleChange = function (pseudo) {
        var granularity = options.find(function (option) { return pseudo === option.pseudo; });
        onChange(granularity);
    };
    return (React.createElement(Dropdown, { showHelp: false, className: "select", style: { width: 'calc((100% - -98px) / 6)' }, label: "Granularity", placeholder: "Select granularity:", showPlaceholder: true, value: get(selected, 'pseudo'), items: normalizedOptions, 
        //@ts-ignore
        onItemSelect: function (_a) {
            var item = _a.item;
            return handleChange(item.value);
        } }));
};
export default Granularity;

import get from 'lodash/get';
import { SIGN_UPS_CHARTS, CHART_DASHBOARDS } from './newSignUpsConstants';
export var getSignUpsChartsConfigs = function (params) {
    return {
        dashboard: CHART_DASHBOARDS.signUps,
        charts: SIGN_UPS_CHARTS.map(function (_a) {
            var key = _a.key;
            return ({
                key: key,
                timezone: params.timezone,
                customerTypes: params.mode
                    .map(function (value) { return value.toLowerCase().charAt(0).toUpperCase() + value.slice(1); })
                    .join(','),
                granularity: get(params, 'crossPageFilter.granularity.selected.value'),
                start: get(params, 'crossPageFilter.dateRange.calculatedDateRange.start.timeStamp'),
                end: get(params, 'crossPageFilter.dateRange.calculatedDateRange.end.timeStamp'),
            });
        }),
    };
};

import * as actionCreators from '@actionCreators/userInfo';
import { connect } from 'react-redux';
import withUrlValidationHOC from '@HOC/withUrlValidationHOC/withUrlValidationHOC';
import SettingsModal from './SettingsModal';
import { getTimezoneFromUrl } from '@commonUtils/urlUtils/urlUtils';
var mapState = function (store) { return ({
    user: store.userInfo.userPreferences,
    settings: store.userInfo.settings,
    timeZoneFromUrl: getTimezoneFromUrl(window.location),
}); };
var mapDispatch = {
    changeUserPreference: actionCreators.changeUserPreference,
    resetPreferences: actionCreators.resetPreferences,
    handlePreferencesSave: actionCreators.handlePreferencesSave,
};
var connector = connect(mapState, mapDispatch);
export default withUrlValidationHOC(connector(SettingsModal));

export var CHART_DASHBOARDS = {
    mccCluster: 'mccCluster',
};
export var MCC_CHARTS_KEYS = {
    VMS: 'vms_total',
    PODS: 'pods_total',
    NODE: 'node_count',
    CONTAINERS: 'containers_total',
    CPU_USAGE_ABS: 'cpu',
    RAM_USAGE_ABS: 'memory',
    DISK_USAGE_ABS: 'disk',
    K8S_AVAILABILITY: 'k8s_api_availability',
    MKE_AVAILABILITY: 'mke_api_availability',
    CPU_USAGE_CAPACITY: 'cpu_capacity',
    RAM_USAGE_CAPACITY: 'memory_capacity',
};

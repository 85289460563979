import React from 'react';
import classNames from 'classnames';
import { ExpandIcon, CollapseIcon, variants } from '@mirantis/mds-icon';
import { ARROW_ICON_SIZE, ASC_TYPE, DESC_TYPE } from '@commonConstants/commonConstants';
import { StyledArrowsWrapper } from '@commonComponents/table/styled';
var TableTH = function (_a) {
    var columns = _a.columns, sortBy = _a.sortBy, orderBy = _a.orderBy, setSortableState = _a.setSortableState;
    return (React.createElement(React.Fragment, null, columns.map(function (column, idx) {
        var handleClick = function () {
            if (column.sortByKey) {
                if (sortBy === column.sortByKey) {
                    setSortableState({
                        sortBy: column.sortByKey,
                        orderBy: orderBy === ASC_TYPE ? DESC_TYPE : ASC_TYPE,
                    });
                    return;
                }
                setSortableState({
                    sortBy: column.sortByKey,
                    orderBy: ASC_TYPE,
                });
            }
        };
        var isAsc = column.sortByKey === sortBy && orderBy === ASC_TYPE;
        var isDesc = column.sortByKey === sortBy && orderBy === DESC_TYPE;
        var className = classNames(column.key, {
            table__th: true,
            sortable: column.sortByKey,
            active: column.sortByKey === sortBy,
            asc: isAsc,
            desc: isDesc,
        });
        return (React.createElement("th", { key: column.key + idx, className: className, onClick: handleClick, "data-testid": "column_".concat(column.key) },
            column.name,
            column.sortByKey && (React.createElement(StyledArrowsWrapper, null,
                React.createElement(CollapseIcon, { size: ARROW_ICON_SIZE, variant: isAsc ? variants.INFO : variants.NORMAL }),
                React.createElement(ExpandIcon, { size: ARROW_ICON_SIZE, variant: isDesc ? variants.INFO : variants.NORMAL })))));
    })));
};
export default TableTH;

import * as Actions from '@redux/actions';
var r = {};
r[Actions.management.SyncActions.SET_CITIES] = function (draft, payload) {
    draft.autocomplete.loading = false;
    draft.autocomplete.cities = payload;
};
r[Actions.management.SyncActions.RESET_CITIES_LOADING] = function (draft) {
    draft.autocomplete.loading = true;
};
r[Actions.management.AsyncActions.GET_USERS] = function (draft) {
    draft.users.loading = true;
};
r[Actions.management.SyncActions.SET_USERS] = function (draft, payload) {
    var users = {
        loading: false,
        data: payload.data,
    };
    draft.users = users;
};
r[Actions.management.SyncActions.UPDATE_USER] = function (draft, payload) {
    var id = payload.id;
    draft.users.data.forEach(function (user, idx) {
        if (user.id === id) {
            draft.users.data[idx] = payload;
        }
    });
};
r[Actions.management.SyncActions.PUSH_NEW_USER] = function () {
    // draft.users.data.push(payload);
};
r[Actions.management.SyncActions.SET_INSTANCES] = function (draft, payload) {
    var instances = {
        loading: false,
        data: payload.data,
    };
    Object.assign(draft.instances, instances);
};
r[Actions.management.AsyncActions.GET_CUSTOMERS] = function (draft) {
    draft.customers.loading = true;
};
r[Actions.management.SyncActions.SET_CUSTOMERS] = function (draft, payload) {
    var customers = {
        loading: false,
        data: payload.data,
    };
    Object.assign(draft.customers, customers);
};
r[Actions.management.AsyncActions.GET_INSTANCES] = function (draft) {
    draft.instances.loading = true;
};
r[Actions.management.SyncActions.UPDATE_CUSTOMER] = function (draft, payload) {
    var id = payload.id;
    draft.customers.data.forEach(function (customer, idx) {
        if (customer.id === id) {
            draft.customers.data[idx] = payload;
        }
    });
};
r[Actions.management.SyncActions.UPDATE_INSTANCE] = function (draft, payload) {
    var id = payload.id;
    draft.instances.data.forEach(function (instance, idx) {
        if (instance.id === id) {
            draft.instances.data[idx].name = payload.name;
        }
    });
};
r[Actions.management.SyncActions.SET_SINGLE_CUSTOMER_MODE] = function (draft, payload) {
    draft.customerMode = true;
    draft.singleCustomer.customer = payload;
};
r[Actions.management.SyncActions.RESET_SINGLE_CUSTOMER_MODE] = function (draft) {
    draft.customerMode = false;
    draft.singleCustomer.customer = null;
    draft.singleCustomer.instances.loading = true;
};
r[Actions.management.AsyncActions.GET_SINGLE_CUSTOMER_INSTANCES] = function (draft) {
    draft.singleCustomer.instances.loading = true;
};
r[Actions.management.SyncActions.SET_SINGLE_CUSTOMER_INSTANCES] = function (draft, payload) {
    draft.singleCustomer.instances.loading = false;
    draft.singleCustomer.instances.data = payload.data;
};
export { r as resolvers };

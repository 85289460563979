var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { changeCurrentPage } from '@actionCreators/pagination/pagination';
import { getUsers, patchUser } from '@actionCreators/management/management';
import { fetchPermissionGroups } from '@redux/dispatchers/permissions';
import EditableTable from '@commonComponents/editable-table/EditableTable';
import PaginationContainer from '@commonComponents/pagination/PaginationContainer';
import { useDebouncedEffect } from '@app/hooks';
import { DASHBOARDS_SCHEMA, PAGINATION_NAMES } from '@configs/DASHBOARD_PSEUDOS';
import { StyledTable } from '@commonComponents/table/styled';
import TableLoadingStates from '@commonComponents/table-loading-states/TableLoadingStates';
import ManageUsersTableRow from './ManageUsersTableRow';
import { COLUMNS, NO_USERS_FOUND } from './manageUsersTableConstants';
var PSEUDO_CC = DASHBOARDS_SCHEMA.ACCOUNT_MANAGEMENT.PAGES.MANAGE_USERS.PSEUDO_CC;
var mapState = function (store) { return ({
    users: store.management.users,
    multiFilter: store.multiFilter,
    pagination: store.pagination.manageUsers,
    timezone: store.userInfo.userPreferences.timezone,
    allPermissionGroups: store.permissions.allPermissionGroups,
}); };
var mapDispatch = {
    getUsers: getUsers,
    patchUser: patchUser,
    changeCurrentPage: changeCurrentPage,
};
var connector = connect(mapState, mapDispatch);
var Raw = function (props) {
    var multiFilter = props.multiFilter, pagination = props.pagination, users = props.users, allPermissionGroups = props.allPermissionGroups;
    var filter = multiFilter.filter;
    var _a = useState({
        sortBy: 'email',
        orderBy: 'asc',
    }), state = _a[0], setState = _a[1];
    var _b = useState(COLUMNS.map(function (C) { return C.key; })), visibleColumns = _b[0], setVisibleColumns = _b[1];
    var getApiParams = function () {
        var _a = multiFilter.filter, selected = _a.selected, _b = _a.searchTerm, searchTerm = _b === void 0 ? '' : _b;
        return {
            sortBy: state.sortBy,
            orderBy: state.orderBy,
            limit: pagination.limit,
            page: pagination.currentPage,
            search_term: searchTerm.length > 0 ? searchTerm : null,
            permission_ids: selected.permissions.map(function (_a) {
                var id = _a.id;
                return id;
            }).join(','),
            is_active: selected.status.length === 1 ? selected.status[0] === 'Active' : null,
            timezone: props.timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
    };
    useDebouncedEffect(function () {
        props.getUsers(getApiParams());
    }, [
        filter.searchTerm,
        filter.selected,
        filter.configuration.status,
        pagination.currentPage,
        pagination.limit,
        state,
        props.timezone,
    ]);
    var handleSave = function (user) {
        props.patchUser({
            id: user.id,
            params: {
                is_active: user.isActive,
                full_name: user.fullName,
            },
        });
    };
    var allPermissionGroupsMap = new Map(allPermissionGroups.data.map(function (item) { return [item.id, item]; }));
    var TRs = users.data.map(function (user) { return (React.createElement(ManageUsersTableRow, { key: user.id, user: user, columns: COLUMNS, visibleColumns: visibleColumns, allPermissionGroupsMap: allPermissionGroupsMap, handleSave: handleSave })); });
    var setSortableState = function (nextState) {
        setState(nextState);
    };
    var onPaginationChange = function (page) {
        props.changeCurrentPage({
            paginationName: PAGINATION_NAMES.MANAGE_USERS,
            currentPage: page,
        });
    };
    var onLimitChange = function (limit) {
        props.changeCurrentPage({
            paginationName: PAGINATION_NAMES.MANAGE_USERS,
            currentPage: 1,
            limit: limit,
        });
    };
    return (React.createElement(TableLoadingStates, { data: users.data, count: pagination.limit, loading: users.loading, empty: { description: NO_USERS_FOUND }, skeleton: {
            paddingBottom: 30,
        } },
        React.createElement(StyledTable, { className: "table" },
            React.createElement(EditableTable, { columns: COLUMNS, sortBy: state.sortBy, orderBy: state.orderBy, setSortableState: setSortableState, columnControl: true, visibleColumns: visibleColumns, setVisibleColumns: setVisibleColumns }, TRs),
            React.createElement(PaginationContainer, { pagination: pagination, withLimits: true, onLimitChange: onLimitChange, onChange: onPaginationChange }))));
};
var ManageUsersTable = function (props) {
    var _a = props.allPermissionGroups, maxPages = _a.maxPages, loading = _a.loading;
    useEffect(function () {
        if (!loading && maxPages !== 1) {
            var params = { limit: Number.MAX_SAFE_INTEGER };
            fetchPermissionGroups(params);
        }
    }, [maxPages, loading]);
    return props.multiFilter.name !== PSEUDO_CC ? null : React.createElement(Raw, __assign({}, props));
};
export default connector(ManageUsersTable);

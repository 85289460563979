export var getNormalizedUsers = function (response) {
    var data = response.data.map(function (dataItem) { return ({
        id: dataItem.id,
        email: dataItem.email,
        fullName: dataItem.full_name,
        isActive: dataItem.is_active,
        lastSignIn: dataItem.last_sign_in,
        preferences: dataItem.preferences,
        sfAccountName: dataItem.sf_account_name,
        permissionIds: (dataItem === null || dataItem === void 0 ? void 0 : dataItem.permission_ids) || [],
    }); });
    return {
        data: data,
        maxPages: response.max_pages,
        currentPage: response.current_page,
    };
};
export var getNormalizedPatchUser = function (response) { return ({
    id: response.id,
    email: response.email,
    fullName: response.full_name,
    isActive: response.is_active,
    permissionIds: (response === null || response === void 0 ? void 0 : response.permission_ids) || [],
}); };
export var getNormalizedPostUser = function (response) { return ({
    id: response.id,
    email: response.email,
    fullName: response.full_name,
    isActive: response.is_active,
    permissionIds: (response === null || response === void 0 ? void 0 : response.permission_ids) || [],
}); };

import React, { useEffect } from 'react';
import { Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { LoadingOutlined } from '@ant-design/icons';
import EditableInput from '@commonComponents/editable-input/EditableInput';
import { StyledCreateEditPermissionGroup } from './StyledCreateEditPermissionGroup';
import DashboardsCheckBoxes from './DashboardsCheckBoxes';
var CreateEditPermissionGroup = function (props) {
    var groupId = props.groupId, groupName = props.groupName, setGroupName = props.setGroupName, newDashboardSchema = props.newDashboardSchema, setDashboardSchema = props.setDashboardSchema, initialGroupSchemaApiCall = props.initialGroupSchemaApiCall;
    useEffect(function () {
        initialGroupSchemaApiCall({ groupId: groupId });
    }, []);
    return (React.createElement(StyledCreateEditPermissionGroup, null,
        React.createElement("div", { className: "modify-permission-group" },
            React.createElement("div", { className: "label" }, "Group Name"),
            React.createElement(EditableInput, { value: groupName, onChange: function (value) { return setGroupName(value); } }),
            isEmpty(newDashboardSchema) ? (React.createElement(Spin, { className: "spinner", indicator: React.createElement(LoadingOutlined, { style: { fontSize: 24 }, spin: true }) })) : (React.createElement(DashboardsCheckBoxes, { newDashboardSchema: newDashboardSchema, setDashboardSchema: setDashboardSchema })))));
};
export default CreateEditPermissionGroup;

import cloneDeep from 'lodash/cloneDeep';
import { store } from '@redux/store';
export var getState = function () {
    return cloneDeep(store.getState());
};
export var getGlobalFilteringParams = function (additionalParams, isCustomerTypesInUse, isInactiveHoursInUse) {
    if (additionalParams === void 0) { additionalParams = {}; }
    if (isCustomerTypesInUse === void 0) { isCustomerTypesInUse = true; }
    if (isInactiveHoursInUse === void 0) { isInactiveHoursInUse = true; }
    var mode = getState().mode;
    return Object.assign(additionalParams, {
        customer_types: mode.selected.length === 0 || !isCustomerTypesInUse ? null : mode.selected.toString(),
        inactive_hours_threshold: isInactiveHoursInUse ? mode.lastSyncDate.selected.value : null,
    });
};
export var getBootstrapStatisticsParams = function (additionalParams) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (additionalParams === void 0) { additionalParams = {}; }
    var state = getState();
    var filter = state.multiFilter.filter;
    var dateRange = state.crossPageFilter.dateRange;
    var timezone = state.userInfo.userPreferences.timezone;
    var globalFilteringParams = getGlobalFilteringParams(additionalParams);
    return Object.assign(globalFilteringParams, {
        customer_ids: ((_a = filter.selected) === null || _a === void 0 ? void 0 : _a.customer.length) > 0 ? filter.selected.customer.map(function (o) { return o.id; }).toString() : null,
        instance_ids: ((_b = filter.selected) === null || _b === void 0 ? void 0 : _b.instance.length) > 0 ? filter.selected.instance.map(function (o) { return o.id; }).toString() : null,
        stages: ((_c = filter.selected) === null || _c === void 0 ? void 0 : _c.stage.length) > 0 ? filter.selected.stage.toString() : null,
        providers: ((_d = filter.selected) === null || _d === void 0 ? void 0 : _d.provider.length) > 0 ? filter.selected.provider.toString() : null,
        statuses: ((_e = filter.selected) === null || _e === void 0 ? void 0 : _e.status.length) > 0 ? filter.selected.status.toString() : null,
        start: (_f = dateRange.calculatedDateRange) === null || _f === void 0 ? void 0 : _f.start.timeStamp,
        end: (_g = dateRange.calculatedDateRange) === null || _g === void 0 ? void 0 : _g.end.timeStamp,
        timezone: timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
};

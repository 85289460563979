import produce from 'immer';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import { getPaginationUserPreferencesFromUrl } from '@commonUtils/urlUtils/urlUtils';
import { resolvers } from './resolvers';
export var initialState = {
    customerOverview: {
        loading: true,
        minLimit: 5,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 5,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
        maxPages: null,
    },
    openstackClusterUsage: {
        loading: true,
        maxPages: null,
        minLimit: 10,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 10,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
    },
    manageUsers: {
        loading: true,
        minLimit: 10,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 10,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
        maxPages: null,
    },
    manageCustomers: {
        loading: true,
        minLimit: 10,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 10,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
        maxPages: null,
    },
    manageInstances: {
        loading: true,
        minLimit: 10,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 10,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
        maxPages: null,
    },
    generalSearchDashboard: {
        loading: true,
        minLimit: 10,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 10,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
        maxPages: null,
    },
    clusterHealth: {
        loading: true,
        minLimit: 10,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 10,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
        maxPages: null,
    },
    singleCustomerInstances: {
        loading: true,
        minLimit: 10,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 10,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
        maxPages: null,
    },
    bootstrap: {
        loading: true,
        minLimit: 10,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 10,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
        maxPages: null,
    },
    bootstrapInstallations: {
        loading: true,
        minLimit: 10,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 10,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
        maxPages: null,
    },
    newSignUps: {
        loading: true,
        minLimit: 10,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 10,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
        maxPages: null,
    },
    activeInstances: {
        loading: true,
        minLimit: 10,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 10,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
        maxPages: null,
    },
    trialReport: {
        loading: true,
        minLimit: 10,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 10,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
        maxPages: null,
    },
    userGroups: {
        loading: true,
        minLimit: 10,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 10,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
        maxPages: null,
    },
    telemetryCustomers: {
        loading: true,
        minLimit: 10,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 10,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
        maxPages: null,
    },
    permissionGroups: {
        loading: true,
        minLimit: 10,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 10,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
        maxPages: null,
    },
    telemetryCustomerClustersMCC: {
        loading: false,
        minLimit: 10,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 10,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
        maxPages: null,
    },
    telemetryCustomerClustersMKE: {
        loading: false,
        minLimit: 10,
        limit: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).limit || 10,
        currentPage: getPaginationUserPreferencesFromUrl(URL_QUERIES.COMMON.PAGINATION).page || 1,
        maxPages: null,
    },
};
export var pagination = function (state, action) {
    if (state === void 0) { state = initialState; }
    if (action === void 0) { action = {}; }
    var type = action.type, payload = action.payload;
    if (!(type in resolvers)) {
        return state;
    }
    var resolver = function (draft) { return resolvers[type](draft, payload); };
    return produce(state, resolver);
};

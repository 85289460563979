import uuid from 'react-uuid';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { navBar } from '@configs/navbar';
import { isSFPermissionGroup } from '@commonUtils/commonUtils';
import { Link } from './Link';
import { NavBarDropdown } from './NavBarDropdown';
import { getRoutesWithResetOpenAndMatchFields, getNavItems } from './navBarUtils';
import { NavBarStyled } from './NavBarStyled';
var NavBar = function (_a) {
    var location = _a.location, collapsed = _a.collapsed;
    var _b = useState(getNavItems(location, collapsed)), navItems = _b[0], setNavItems = _b[1];
    var allowedPagesTags = useSelector(function (store) { return store.permissions.allowedPagesTags; });
    useEffect(function () {
        setNavItems(getNavItems(location, collapsed));
    }, [location, allowedPagesTags]);
    useEffect(function () {
        var newNavItems = getRoutesWithResetOpenAndMatchFields(navItems);
        setNavItems(newNavItems);
    }, [collapsed]);
    var handleClickOnLink = function (pseudo) {
        var newNavItems = getRoutesWithResetOpenAndMatchFields(navItems);
        newNavItems[pseudo].match = true;
        setNavItems(newNavItems);
    };
    var handleClickOnDropdown = function (pseudo) {
        if (collapsed) {
            return;
        }
        var newNavItems = getRoutesWithResetOpenAndMatchFields(navItems);
        newNavItems[pseudo].opened = true;
        setNavItems(newNavItems);
    };
    var menu = navBar.map(function (topRoute) {
        var isSfUser = isSFPermissionGroup();
        if (!topRoute.isVisible()) {
            return null;
        }
        var C = topRoute.children ? NavBarDropdown : Link;
        return (React.createElement(C, { key: uuid(), top: true, pathname: location.pathname, route: topRoute, state: navItems[topRoute.pseudo], needRenderIcon: true, isSfUser: isSfUser, collapsed: collapsed, handleClickOnLink: handleClickOnLink, handleClickOnDropdown: handleClickOnDropdown }));
    });
    return React.createElement(NavBarStyled, { className: classNames('nav', { collapsed: collapsed }) }, menu);
};
export default NavBar;

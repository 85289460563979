var StringUtils = /** @class */ (function () {
    function StringUtils() {
    }
    StringUtils.capitalize = function (string) { return string.charAt(0).toUpperCase() + string.slice(1); };
    StringUtils.countStringLengthWithoutSpaces = function (string) {
        var s = string.replace(/\s/g, '');
        return s.length;
    };
    return StringUtils;
}());
export { StringUtils };

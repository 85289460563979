import { store } from '@redux/store';
import * as actionCreators from '@redux/action-creators';
export var setError = function (payload) {
    var action = actionCreators.errorHandling.setError(payload);
    store.dispatch(action);
};
export var clearError = function (payload) {
    var action = actionCreators.errorHandling.clearError(payload);
    store.dispatch(action);
};

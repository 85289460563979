import React, { memo } from 'react';
import history from '@history/history';
import { useSelector } from 'react-redux';
import { DateUtils } from '@utils/date/DateUtils';
import { getMultiFilterPickers } from '@configs/multiFilterConfig';
import FilterContainer from '@commonComponents/filter/FilterContainer';
import { changeCrossPageFilterOption } from '@redux/dispatchers/crossPageFilter';
import CrossPageFilterContainer from '@commonComponents/cross-page-filter/CrossPageFilterContainer';
import { getFilterDateChangeConfig } from '@commonComponents/cross-page-filter/crossPageFilterUtils';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import { CROSS_PAGE_FILTER_CONFIG } from '@configs/crossPageFilterInitialState';
import TrialSignUpsChart from './TrialSignUpsChart';
import { StyledFilter } from './style';
var DATE_RANGE = URL_QUERIES.COMMON.DATE_RANGE;
var name = DASHBOARDS_SCHEMA.REPORTS.PAGES.TRIAL_STATISTIC.TABS.SIGN_UPS;
var defaultSelected = CROSS_PAGE_FILTER_CONFIG[name].dateRange.selected;
var TrialSignUpsContainer = function () {
    var _a = useSelector(function (store) { return ({
        mode: store.mode,
        multiFilter: store.multiFilter,
    }); }), multiFilter = _a.multiFilter, mode = _a.mode;
    var resetAdditionalParams = function () {
        changeCrossPageFilterOption(getFilterDateChangeConfig(DATE_RANGE, defaultSelected, DateUtils.calculateDateRangeByOption(defaultSelected), true));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledFilter, null,
            React.createElement(CrossPageFilterContainer, { isGranularity: true, history: history, dashboardName: name }),
            React.createElement(FilterContainer, { name: name, params: {}, currentMode: mode, multiFilter: multiFilter, pickers: getMultiFilterPickers(name), resetAdditionalParams: resetAdditionalParams })),
        React.createElement(TrialSignUpsChart, null)));
};
export default memo(TrialSignUpsContainer);

import React, { useEffect } from 'react';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { Dropdown } from '@mirantis/mds-dropdown';
import { Button } from '@mirantis/mds-button';
import { dropPaginationToDefault } from '@redux/dispatchers/pagination';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import Picker from '@commonComponents/picker/Picker';
import StyledFilter from '@app/common/react/styled/StyledFilter';
import Redirect from '@commonUtils/urlUtils/Redirect';
import { resetCustomerTypeAndLastSyncDate, setCustomerTypeToStoreAndLocalStorage, setLastSyncDateToStoreAndLocalStorage, } from './customerTypesFilterUtils';
import { LAST_SYNC_DATE_OPTIONS } from '@configs/modeSwitcher';
import { CUSTOMER_TYPES_PICKER, LAST_SYNC_DATE_PICKER, ALL } from './customerTypesFilterConstants';
var _a = URL_QUERIES.COMMON, CUSTOMER_TYPE = _a.CUSTOMER_TYPE, LAST_SYNC_DATE = _a.LAST_SYNC_DATE;
var CustomerTypesFilter = function (_a) {
    var mode = _a.mode, getCustomerTypes = _a.getCustomerTypes, setSelectedOptions = _a.setSelectedOptions, isLastSyncDisabled = _a.isLastSyncDisabled, lastSyncDateFromUrl = _a.lastSyncDateFromUrl, customerTypesFromUrl = _a.customerTypesFromUrl, resetAdditional = _a.resetAdditional, _b = _a.isResetButton, isResetButton = _b === void 0 ? true : _b;
    var selected = mode.selected, lastSyncDate = mode.lastSyncDate;
    var optionValues = Object.values(mode.options);
    var optionsKeys = Object.keys(mode.options);
    var normalizedSelectedOptions = selected.map(function (option) { return optionValues[optionsKeys.indexOf(option)]; });
    var lastSyncOptions = lastSyncDate.options.map(function (option) { return ({ value: option.label }); });
    var handlePickerApply = function (_, options) {
        var optionsPayload = options.map(function (option) { return optionsKeys[optionValues.indexOf(option)]; });
        dropPaginationToDefault();
        setCustomerTypeToStoreAndLocalStorage(setSelectedOptions, optionsPayload);
    };
    var handleLastSyncDateChange = function (selectedValue) {
        var finalLastSyncDate = find(LAST_SYNC_DATE_OPTIONS, { label: selectedValue });
        dropPaginationToDefault();
        setLastSyncDateToStoreAndLocalStorage(setSelectedOptions, finalLastSyncDate);
    };
    useEffect(function () {
        getCustomerTypes();
        if (!isNil(lastSyncDateFromUrl))
            handleLastSyncDateChange(lastSyncDateFromUrl);
        if (!isEmpty(customerTypesFromUrl))
            setCustomerTypeToStoreAndLocalStorage(setSelectedOptions, customerTypesFromUrl);
    }, []);
    useEffect(function () {
        var finalModeSelected = isEmpty(mode.selected) ? [ALL] : mode.selected;
        Redirect.toCurrentPageWithQueryParams(CUSTOMER_TYPE, finalModeSelected, '');
    }, [mode.selected]);
    useEffect(function () {
        Redirect.toCurrentPageWithQueryParams(LAST_SYNC_DATE, [mode.lastSyncDate.selected.label], '');
    }, [mode.lastSyncDate]);
    var handleReset = function () {
        resetCustomerTypeAndLastSyncDate(setSelectedOptions);
        if (resetAdditional) {
            resetAdditional();
        }
    };
    if (mode.loading)
        return null;
    return (React.createElement(StyledFilter, { className: "filter" },
        React.createElement(Picker, { picker: CUSTOMER_TYPES_PICKER, selected: normalizedSelectedOptions, options: { data: optionValues }, handlePickerApply: handlePickerApply }),
        isLastSyncDisabled ? null : (React.createElement(Dropdown, { className: "select", style: { width: 'calc((100vw - 310) / 6)' }, label: LAST_SYNC_DATE_PICKER.name, placeholder: "Select the range:", showPlaceholder: true, value: lastSyncDate.selected.label, items: lastSyncOptions, showHelp: false, 
            //@ts-ignore
            onItemSelect: function (_a) {
                var item = _a.item;
                return handleLastSyncDateChange(item === null || item === void 0 ? void 0 : item.value);
            } })),
        isResetButton ? (React.createElement(Button, { className: "customer-type__reset", label: "Reset", "data-testid": "reset", onClick: handleReset })) : null));
};
export default CustomerTypesFilter;

import React, { lazy, Suspense, useEffect, useState } from 'react';
import Tabs from '@commonComponents/tabs/Tabs';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { setActiveManageCustomersTabToUrl } from './manageCustomersUtils';
import { MANAGE_CUSTOMERS_TABS_TITLES } from './manageCutomersConstants';
var DEECInstances = lazy(function () { return import('./DEECInstances'); });
var ManageCustomers = lazy(function () { return import('../manage-customer'); });
var ManageCustomersPage = lazy(function () { return import('./ManageCustomersPage'); });
var finalTabs = Object.values(MANAGE_CUSTOMERS_TABS_TITLES);
var MANAGE_INSTANCES = DASHBOARDS_SCHEMA.ACCOUNT_MANAGEMENT.PAGES.MANAGE_CUSTOMERS.FILTER_PSEUDOS.MANAGE_INSTANCES;
var ManageCustomersRAW = function (_a) {
    var activeTabFromUrl = _a.activeTabFromUrl, singleCustomer = _a.singleCustomer, customerMode = _a.customerMode;
    var finalActiveTab = activeTabFromUrl || finalTabs[0];
    var _b = useState(finalActiveTab), active = _b[0], setActive = _b[1];
    var tab = active === finalTabs[0] ? React.createElement(ManageCustomersPage, null) : React.createElement(DEECInstances, { filterName: MANAGE_INSTANCES });
    var handleChange = function (newTab) {
        setActive(newTab);
    };
    useEffect(function () {
        setActiveManageCustomersTabToUrl(active);
    }, [active]);
    return (React.createElement(Suspense, { fallback: null }, customerMode ? (React.createElement(ManageCustomers, { singleCustomer: singleCustomer })) : (React.createElement(React.Fragment, null,
        React.createElement(Tabs, { active: active, tabs: finalTabs, handleChange: handleChange, activeTabFromUrl: activeTabFromUrl }),
        tab))));
};
export default ManageCustomersRAW;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAllQueryParams } from '@commonUtils/urlUtils/urlUtils';
import { isSFPermissionGroup } from '@commonUtils/commonUtils';
import TelemetryCustomersFilter from '../telemetryCustomersFilterNew/TelemetryCustomersFilter';
import TelemetryCustomersTableContainer from './TelemetryCustomersTableContainer';
import { setAccountIdToUrlIfSFUser } from './telemetryCustomersUtils';
var TelemetryCustomers = function (_a) {
    var prevAccountId = _a.prevAccountId;
    //@ts-ignore
    var user = useSelector(function (state) { return state === null || state === void 0 ? void 0 : state.userInfo.activeUser; });
    var isSfUser = isSFPermissionGroup();
    useEffect(function () {
        var queries = getAllQueryParams(window.location.search);
        var isCorrectQueries = 
        // eslint-disable-next-line no-prototype-builtins
        queries.hasOwnProperty('accountId') && queries.hasOwnProperty('inactiveHoursThreshold');
        if (isSfUser && !isCorrectQueries) {
            setAccountIdToUrlIfSFUser(user);
        }
    }, [user, isSfUser]);
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", null, "Telemetry Dashboard"),
        React.createElement("h5", { style: { marginTop: 20 } }, "Choose the customer to see the data"),
        React.createElement(TelemetryCustomersFilter, { shouldNotResetPickersOnModeChange: true }),
        React.createElement(TelemetryCustomersTableContainer, { prevAccountId: prevAccountId })));
};
export default TelemetryCustomers;

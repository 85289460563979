import React from 'react';
import { Toggle as MDSToggle } from '@mirantis/mds-toggle';
import { StyledNameSwitchWrapper, StyledToggleAndPickerWrapper } from './StyledActiveClustersToggle';
import withDisablingWhileFetchingHOC from '@HOC/withDisablingWhileFetchingHOC/withDisablingWhileFetchingHOC';
import CrossPageFilterContainer from '@commonComponents/cross-page-filter/CrossPageFilterContainer';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
var PSEUDO_CC = DASHBOARDS_SCHEMA.MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.PSEUDO_CC;
var ActiveClustersToggle = function (_a) {
    var name = _a.name, onClickActiveClusters = _a.onClickActiveClusters, checkedActive = _a.checkedActive, history = _a.history;
    return (React.createElement(StyledNameSwitchWrapper, null,
        React.createElement("h3", null, name),
        React.createElement(StyledToggleAndPickerWrapper, { checkedActive: checkedActive },
            React.createElement(CrossPageFilterContainer, { dashboardName: PSEUDO_CC, history: history, isEmbedded: true }),
            React.createElement(MDSToggle, { className: "active-clusters-toggle", value: checkedActive, label: "Active (24h)", onClick: onClickActiveClusters }))));
};
export default withDisablingWhileFetchingHOC(ActiveClustersToggle, { spinner: false });

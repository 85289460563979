var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { Button } from '@mirantis/mds-button';
import { downloadFile } from '@commonUtils/commonUtils';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { getOpenstackClusterUsage } from '@api/openstack-cluster-usage-api/openstackClusterUsageApiRequests';
import { getFilteredCsv } from './downloadCsvUtils';
var OPENSTACK_CLUSTERS_USAGE = DASHBOARDS_SCHEMA.DASHBOARDS.PAGES.OPENSTACK_CLUSTERS_USAGE;
var DownloadCsv = function (_a) {
    var sortState = _a.sortState, visibleColumns = _a.visibleColumns;
    var _b = useSelector(function (store) {
        var _a, _b;
        return ({
            mode: store.mode,
            data: store.openstackClusterUsage.data || [],
            multiFilter: store.multiFilter,
            timezone: store.userInfo.userPreferences.timezone,
            pagination: store.pagination[OPENSTACK_CLUSTERS_USAGE.PSEUDO_CC],
            loading: store.openstackClusterUsage.loading,
            customers: ((_b = (_a = store.multiFilter.filter) === null || _a === void 0 ? void 0 : _a.selected) === null || _b === void 0 ? void 0 : _b.customer) || [],
        });
    }), mode = _b.mode, multiFilter = _b.multiFilter, pagination = _b.pagination, customers = _b.customers, timezone = _b.timezone, data = _b.data;
    var params = {
        as_csv: true,
        sortBy: sortState.sortBy,
        orderBy: sortState.orderBy,
        limit: pagination.limit,
        page: pagination.currentPage,
        search_term: multiFilter.filter.searchTerm,
        inactive_hours_threshold: mode.lastSyncDate.selected.value,
        customer_types: mode.selected[0] === 'All' ? null : mode.selected.toString(),
        customer_ids: customers.map(function (_a) {
            var id = _a.id;
            return id;
        }).join(','),
        timezone: timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    var onDownloadCsv = function () { return __awaiter(void 0, void 0, void 0, function () {
        var fileName, result, filteredCsv;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    fileName = "Openstack Cluster Usage ".concat(moment().format('YYYY-MM-DD'), ".csv");
                    return [4 /*yield*/, getOpenstackClusterUsage(params)];
                case 1:
                    result = _a.sent();
                    filteredCsv = getFilteredCsv(result, visibleColumns);
                    downloadFile(filteredCsv, fileName, 'text/json');
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Button, { disabled: data.length === 0, className: "download-csv-button", label: "Export CSV", actionType: "secondary", onClick: onDownloadCsv }));
};
export default DownloadCsv;

export var getNormalizedOpenstackClusterUsage = function (response) {
    var data = response.data.map(function (item) {
        return {
            clusterId: item.cluster_id,
            clusterName: item.cluster_name,
            customerName: item.customer_name,
            clusterVersion: item.cluster_version,
            clusterUpdating: item.cluster_updating,
            instanceId: item.instance_id,
            instanceName: item.instance_name,
            customerId: item.customer_id,
            managementId: item.management_id,
            regionId: item.region_id,
            provider: item.provider,
            cpu: item.cpu || 0,
            cpuPercentage: item.cpu_percentage || 0,
            memory: item.memory || 0,
            memoryPercentage: item.memory_percentage || 0,
            disk: item.disk || 0,
            diskPercentage: item.disk_percentage || 0,
            tenants: item.tenants || 0,
            nodes: item.nodes || 0,
            vms: item.vms || 0,
            volumes: item.volumes || 0,
            images: item.images || 0,
            networks: item.networks || 0,
            loadbalancers: item.loadbalancers || 0,
            masterNodes: item.master_nodes || 0,
            workerNodes: item.worker_nodes || 0,
            totalNodes: (item.master_nodes || 0) + (item.worker_nodes || 0),
            lastSyncDate: item.last_sync_date,
            clusterLastSyncDate: item.cluster_last_sync_date,
            mccVersion: item.mcc_version,
        };
    });
    return {
        data: data,
        currentPage: response.current_page,
        maxPages: response.max_pages,
    };
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import last from 'lodash/last';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import CryptoJS from 'crypto-js';
import { JWT } from '@utils/JWT/JWT';
import { Localstorage } from '@utils/localstorage/Localstorage';
import { PERMISSION_GROUPS } from '@commonConstants/permissionGroups';
import { LOCAL_STORAGE_KEYS } from '@commonConstants/localStorageConstancts';
import { navBar } from '@configs/navbar';
export var isEveryItemInArrayObject = function (array) { return array.every(function (item) { return isObject(item); }); };
export var getPermissionGroups = function () {
    var token = Localstorage.get('token');
    if (!token)
        return [];
    return JWT.decode(token).groups;
};
export var getPageFromPathname = function (pathname) { return last(pathname.split('/')); };
export var isSFPermissionGroup = function (groups) {
    if (groups === void 0) { groups = getPermissionGroups(); }
    return groups.find(function (_a) {
        var tag = _a.tag;
        return tag === PERMISSION_GROUPS.SF;
    });
};
export var roundToDecimals = function (num) { return Math.round((+num + Number.EPSILON) * 10) / 10; };
export var scrollIntoView = function (ref) {
    var _a;
    (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
    });
};
export var getCloserAvailableDashboard = function () {
    return navBar.reduce(function (result, bar) {
        if (bar.isVisible() && !result) {
            if (!bar.children) {
                result = bar.path;
                return result;
            }
            result = bar.children.find(function (child) { return child.isVisible(); }).path;
        }
        return result;
    }, '');
};
export var clearCookies = function (wildcardDomain, primaryDomain, path) {
    if (wildcardDomain === void 0) { wildcardDomain = false; }
    if (primaryDomain === void 0) { primaryDomain = true; }
    if (path === void 0) { path = null; }
    var pathSegment = path ? '; path=' + path : '';
    var expSegment = '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    document.cookie.split(';').forEach(function (c) {
        primaryDomain && (document.cookie = c.replace(/^ +/, '').replace(/=.*/, expSegment + pathSegment));
        wildcardDomain &&
            (document.cookie = c
                .replace(/^ +/, '')
                .replace(/=.*/, expSegment + pathSegment + '; domain=' + document.domain));
    });
};
export var clearAllCache = function () {
    sessionStorage.clear();
    localStorage.clear();
    clearCookies();
    // window.location.reload(); // when removing cookies, in the url they do not disappear, but essentially disappear. If you want it to disappear automatically there, please uncomment on the automatic reload
};
export var getNavBarCollapsedFromLocalStore = function () {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.NAV_BAR_COLLAPSED) === 'true';
};
export var setNavBarCollapsedToLocalStore = function (value) {
    return localStorage.setItem(LOCAL_STORAGE_KEYS.NAV_BAR_COLLAPSED, value);
};
export var isTestEnvMode = function () {
    var _a;
    return localStorage.getItem(LOCAL_STORAGE_KEYS.IS_TESTING_MODE) === 'true' &&
        !isNil((_a = process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_TEST_AUTH_TOKEN);
};
function base64url(source) {
    // Encode in classical base64
    var encodedSource = CryptoJS.enc.Base64.stringify(source);
    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, '');
    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');
    return encodedSource;
}
export var getJWTToken = function (secretKey, data) { return __awaiter(void 0, void 0, void 0, function () {
    var header, stringifiedHeader, encodedHeader, stringifiedData, encodedData, token, signature;
    return __generator(this, function (_a) {
        header = {
            alg: 'HS256',
            typ: 'JWT',
        };
        stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
        encodedHeader = base64url(stringifiedHeader);
        stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
        encodedData = base64url(stringifiedData);
        token = encodedHeader + '.' + encodedData;
        signature = CryptoJS.HmacSHA256(token, secretKey);
        signature = base64url(signature);
        return [2 /*return*/, "".concat(token, ".").concat(signature)];
    });
}); };
export var getFormattedNumberAfterDot = function (value, toFixed) {
    if (toFixed === void 0) { toFixed = 3; }
    var index = Number(value).toString().indexOf('.');
    var finalNewFixed = index < 0 ? toFixed + 1 : toFixed;
    var precision = null;
    var valueString = Number(value)
        .toString()
        .substring(0, Number(value).toString().indexOf('.') + finalNewFixed);
    var beforeDotSymb = valueString.split('.')[0];
    if (valueString === '100' || Number(value) > 100) {
        precision = 5;
    }
    else if (beforeDotSymb === '0' && valueString.length >= 3) {
        precision = 2;
    }
    else if (beforeDotSymb.length === 1) {
        precision = 3;
    }
    else {
        precision = 4;
    }
    return parseFloat(valueString).toPrecision(precision);
};
export var camelToSnakeCase = function (inputString) {
    return inputString
        .split('')
        .map(function (character) {
        if (character === character.toUpperCase()) {
            return '_' + character.toLowerCase();
        }
        return character;
    })
        .join('');
};
export function downloadFile(content, fileName, contentType) {
    var aElement = document.createElement('a');
    var file = new Blob([content], { type: contentType });
    aElement.href = URL.createObjectURL(file);
    aElement.download = fileName;
    aElement.click();
}

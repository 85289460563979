import jwtDecode from 'jwt-decode';
var JWT = /** @class */ (function () {
    function JWT() {
    }
    JWT.decode = function (jwt) {
        return jwtDecode(jwt);
    };
    return JWT;
}());
export { JWT };

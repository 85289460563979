import * as Actions from '@redux/actions/warningBanner';
var r = {};
r[Actions.SyncActions.SET_WARNING_BANNER] = function (draft, payload) {
    draft.banner = payload;
};
r[Actions.AsyncActions.GET_WARNING_BANNER_DETAILS] = function (draft) {
    draft.jobs.loading = true;
};
r[Actions.SyncActions.SET_WARNING_BANNER_DETAILS] = function (draft, payload) {
    draft.jobs.data = payload;
    draft.jobs.loading = false;
};
export { r as resolvers };

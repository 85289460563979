var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import get from 'lodash/get';
import { message } from 'antd';
import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import * as permissions from '@actionCreators/permissions/permissions';
import * as API from '@api/permissions-api/permissionsApi';
import * as Actions from '@redux/actions';
import { handleValidateTokenUpdate, getNormalizedEditPermissionGroupParams, getNormalizedCreatePermissionGroupParams, getNormalizedSetPermissionGroupUsersParams, } from './permissionsSagaUtils';
function fetchPermissionGroups(action) {
    var res, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                return [4 /*yield*/, call(API.fetchPermissionGroups, action.payload)];
            case 1:
                res = _a.sent();
                if (!res) return [3 /*break*/, 3];
                return [4 /*yield*/, put(permissions.setPermissionGroups(res))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3: return [3 /*break*/, 5];
            case 4:
                e_1 = _a.sent();
                console.error(e_1);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function getPermissionsAllowedPages() {
    var _a, id, permissionGroups, res, e_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select()];
            case 1:
                _a = (_b.sent()).userInfo, id = _a.userPreferences.id, permissionGroups = _a.activeUser.permissionGroups;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 9]);
                return [4 /*yield*/, call(API.getPermissionsAllowedPages, id)];
            case 3:
                res = _b.sent();
                handleValidateTokenUpdate(res.groupsIds, permissionGroups.map(function (group) { return group.id; }));
                if (!res.tags) return [3 /*break*/, 5];
                return [4 /*yield*/, put(permissions.setPermissionsAllowedPagesTags(res.tags))];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put(permissions.setPermissionsAllowedPagesLoading(false))];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 9];
            case 8:
                e_2 = _b.sent();
                console.error(e_2);
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
function fetchPermissionGroupUsers(action) {
    var params, res, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                params = {
                    search_term: action.payload.searchTerm,
                    sortBy: action.payload.sortBy,
                    orderBy: action.payload.orderBy,
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 5, , 6]);
                return [4 /*yield*/, call(API.fetchPermissionGroupUsers, { groupId: action.payload.groupId, params: params })];
            case 2:
                res = _a.sent();
                if (!res) return [3 /*break*/, 4];
                return [4 /*yield*/, put(permissions.setActiveGroupAssignedUsers(res))];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [3 /*break*/, 6];
            case 5:
                e_3 = _a.sent();
                console.error(e_3);
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function setPermissionGroupUsers(action) {
    var _a, data, config, _b, multiFilter, activeGroupAssignedUsers, permissionGroups, params, e_4;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = action.payload, data = _a.data, config = _a.config;
                return [4 /*yield*/, select()];
            case 1:
                _b = _c.sent(), multiFilter = _b.multiFilter, activeGroupAssignedUsers = _b.permissions.activeGroupAssignedUsers, permissionGroups = _b.pagination.permissionGroups;
                params = getNormalizedSetPermissionGroupUsersParams(data, activeGroupAssignedUsers.map(function (_a) {
                    var id = _a.id;
                    return id;
                }));
                _c.label = 2;
            case 2:
                _c.trys.push([2, 5, , 6]);
                return [4 /*yield*/, call(API.setPermissionGroupUsers, params)];
            case 3:
                _c.sent();
                return [4 /*yield*/, put(permissions.fetchPermissionGroups({
                        sortBy: config.sortBy,
                        orderBy: config.orderBy,
                        search_term: get(multiFilter, 'filter.searchTerm', ''),
                        limit: permissionGroups.limit,
                    }))];
            case 4:
                _c.sent();
                return [3 /*break*/, 6];
            case 5:
                e_4 = _c.sent();
                console.error(e_4);
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function fetchPermissionAllUsers(action) {
    var params, res, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                params = {
                    search_term: action.payload.searchTerm,
                    limit: action.payload.limit,
                    page: action.payload.page,
                    sortBy: action.payload.sortBy,
                    orderBy: action.payload.orderBy,
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 5, , 6]);
                return [4 /*yield*/, call(API.fetchPermissionAllUsers, params)];
            case 2:
                res = _a.sent();
                if (!res) return [3 /*break*/, 4];
                return [4 /*yield*/, put(permissions.setPermissionsAllUsers(res))];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [3 /*break*/, 6];
            case 5:
                e_5 = _a.sent();
                console.error(e_5);
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function createPermissionGroup(action) {
    var permissionGroups, params, createPermissionsGroup, e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select()];
            case 1:
                permissionGroups = (_a.sent()).pagination.permissionGroups;
                params = getNormalizedCreatePermissionGroupParams(action.payload);
                _a.label = 2;
            case 2:
                _a.trys.push([2, 7, , 8]);
                return [4 /*yield*/, call(API.createPermissionsGroup, params)];
            case 3:
                createPermissionsGroup = _a.sent();
                return [4 /*yield*/, put(permissions.fetchPermissionGroups({ searchTerm: '', limit: permissionGroups.limit }))];
            case 4:
                _a.sent();
                if (!(createPermissionsGroup !== undefined)) return [3 /*break*/, 6];
                return [4 /*yield*/, message.success('Permission Group created successfully')];
            case 5:
                _a.sent();
                _a.label = 6;
            case 6: return [3 /*break*/, 8];
            case 7:
                e_6 = _a.sent();
                console.error(e_6);
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function editPermissionGroup(action) {
    var _a, multiFilter, permissionGroup, permissionGroups, params, e_7;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select()];
            case 1:
                _a = _b.sent(), multiFilter = _a.multiFilter, permissionGroup = _a.permissions.permissionGroup, permissionGroups = _a.pagination.permissionGroups;
                params = getNormalizedEditPermissionGroupParams(action.payload, permissionGroup.dashboards, permissionGroup.name);
                _b.label = 2;
            case 2:
                _b.trys.push([2, 6, , 7]);
                return [4 /*yield*/, call(API.editPermissionGroup, params, action.payload.groupId)];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(permissions.fetchPermissionGroups({
                        search_term: get(multiFilter, 'filter.searchTerm', ''),
                        limit: permissionGroups.limit,
                    }))];
            case 4:
                _b.sent();
                return [4 /*yield*/, message.success('Permission Group edited successfully')];
            case 5:
                _b.sent();
                return [3 /*break*/, 7];
            case 6:
                e_7 = _b.sent();
                console.error(e_7);
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function deletePermissionGroup(action) {
    var _a, multiFilter, permissionGroups, e_8;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select()];
            case 1:
                _a = _b.sent(), multiFilter = _a.multiFilter, permissionGroups = _a.pagination.permissionGroups;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 6, , 7]);
                return [4 /*yield*/, call(API.deletePermissionGroup, action.payload)];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(permissions.fetchPermissionGroups({
                        search_term: get(multiFilter, 'filter.searchTerm', ''),
                        limit: permissionGroups.limit,
                    }))];
            case 4:
                _b.sent();
                return [4 /*yield*/, message.success('Permission Group deleted successfully')];
            case 5:
                _b.sent();
                return [3 /*break*/, 7];
            case 6:
                e_8 = _b.sent();
                console.error(e_8);
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function fetchPermissionsGroup(action) {
    var res, e_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                return [4 /*yield*/, call(API.fetchPermissionsGroup, action.payload)];
            case 1:
                res = _a.sent();
                if (!res) return [3 /*break*/, 3];
                return [4 /*yield*/, put(permissions.setPermissionsGroup(res))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3: return [3 /*break*/, 5];
            case 4:
                e_9 = _a.sent();
                console.error(e_9);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export default function permissionsSaga() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeEvery(Actions.permissions.AsyncActions.FETCH_PERMISSIONS_ALLOWED_PAGES_IDS, getPermissionsAllowedPages)];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeEvery(Actions.permissions.AsyncActions.FETCH_PERMISSION_GROUPS, fetchPermissionGroups)];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.permissions.AsyncActions.CREATE_PERMISSION_GROUP, createPermissionGroup)];
            case 3:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.permissions.AsyncActions.EDIT_PERMISSION_GROUP, editPermissionGroup)];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.permissions.AsyncActions.DELETE_PERMISSION_GROUP, deletePermissionGroup)];
            case 5:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.permissions.SyncActions.SET_PERMISSION_GROUP_USERS, setPermissionGroupUsers)];
            case 6:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.permissions.AsyncActions.FETCH_PERMISSIONS_GROUP, fetchPermissionsGroup)];
            case 7:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.permissions.AsyncActions.FETCH_PERMISSION_ALL_USERS, fetchPermissionAllUsers)];
            case 8:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.permissions.AsyncActions.FETCH_PERMISSION_GROUP_USERS, fetchPermissionGroupUsers)];
            case 9: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 10:
                _c.sent();
                return [2 /*return*/];
        }
    });
}

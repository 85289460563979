var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import GroupedTableTHead from './GroupedTableTHead';
import Loader from '@commonComponents/Loader';
import GroupedTableBody from './GroupedTableBody';
import { getSortedDataBySwitcher } from './gropedTableUtils';
var GropedColumnTable = function (props) {
    var data = useMemo(function () { return getSortedDataBySwitcher(props.data); }, [props.data]);
    var preparedProps = __assign(__assign({}, props), { data: data });
    return data.length > 0 ? (React.createElement("div", { className: "groped-table-wrapper" },
        React.createElement("table", { className: "groped-table", id: props.id },
            React.createElement(GroupedTableTHead, __assign({}, preparedProps)),
            React.createElement("tbody", { className: "groped-table__tbody" },
                React.createElement(GroupedTableBody, __assign({}, preparedProps)))))) : (React.createElement(Loader, { message: props.noDataMessage }));
};
export default memo(GropedColumnTable, function (prevProps, nextProps) {
    return prevProps.isClustersManaging === nextProps.isClustersManaging && isEqual(prevProps.data, nextProps.data);
});

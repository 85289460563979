export var AsyncActions;
(function (AsyncActions) {
    AsyncActions["GET_WARNING_BANNER"] = "GET_WARNING_BANNER";
    AsyncActions["GET_WARNING_BANNER_DETAILS"] = "GET_WARNING_BANNER_DETAILS";
})(AsyncActions || (AsyncActions = {}));
export var SyncActions;
(function (SyncActions) {
    SyncActions["SET_WARNING_BANNER"] = "SET_WARNING_BANNER";
    SyncActions["SET_WARNING_BANNER_DETAILS"] = "SET_WARNING_BANNER_DETAILS";
})(SyncActions || (SyncActions = {}));

export var LOADING_TEXT = 'Loading...';
export var NO_INSTALLATION_FOUND_MESSAGE = 'No Installation found';
export var CHART_TEXT = {
    TITLE: 'Installation',
    MESSAGE: 'Installation',
};
export var COLUMNS = [
    {
        name: 'Date',
        key: 'date',
        sortByKey: 'date',
        isVisibleByDefault: true,
    },
    {
        name: 'Binary executions',
        key: 'binaryExecutions',
        sortByKey: 'binary_executions',
        isVisibleByDefault: true,
    },
    {
        name: 'Attempts',
        key: 'attempts',
        sortByKey: 'attempts',
        isVisibleByDefault: true,
    },
    {
        name: 'Successes',
        key: 'successes',
        sortByKey: 'successes',
        isVisibleByDefault: true,
    },
];
export var ONE_SEC_IN_MINUTES = 0.01;
export var FREEMIUM_INSTALLATIONS_CHART;
(function (FREEMIUM_INSTALLATIONS_CHART) {
    FREEMIUM_INSTALLATIONS_CHART["uid"] = "installations";
    FREEMIUM_INSTALLATIONS_CHART["chart"] = "installations";
})(FREEMIUM_INSTALLATIONS_CHART || (FREEMIUM_INSTALLATIONS_CHART = {}));

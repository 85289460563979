import * as Actions from '@redux/actions';
var r = {};
r[Actions.bootstrap.SyncActions.SET_BOOTSTRAP_EVENTS] = function (draft, payload) {
    draft.data = payload.data;
    draft.loading = false;
};
r[Actions.bootstrap.AsyncActions.GET_BOOTSTRAP_EVENTS] = function (draft) {
    draft.loading = true;
};
export { r as resolvers };

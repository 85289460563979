import '../wdr';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Global } from '@emotion/react';
import { lightTheme, ThemeProvider } from '@mirantis/mds-theme';
import { store } from '@redux/store';
import App from '@app/App';
import OAuthProvider from '@app/OAuthProvider/OAuthProvider';
import { clearAllCache } from '@commonUtils/commonUtils';
import { CLIENT_ID } from '@configs/auth0';
import { routes } from '@configs/routes';
import getGlobalStyles from '@root/globalStyles';
import fontPaths from '@configs/fontPaths';
import '@style/_reset.scss';
var rootElement = document.getElementById('root') || document.createElement('div');
var root = createRoot(rootElement);
var appProviderRef;
var WithTheme = function () { return (React.createElement(ThemeProvider, { theme: lightTheme, withFonts: fontPaths },
    React.createElement(Global, { styles: getGlobalStyles(lightTheme) }),
    React.createElement(App, null))); };
var WithStore = function () { return (React.createElement(Provider, { store: store },
    React.createElement(WithTheme, null))); };
var WithAuth = function () { return (React.createElement(OAuthProvider, { ref: function (ref) { return (appProviderRef = ref); } },
    React.createElement(WithStore, null))); };
var isJsdom = navigator.userAgent.includes('Node.js') || navigator.userAgent.includes('jsdom');
// we do not render app if it is test env in node js
if (!isJsdom) {
    root.render(React.createElement(WithAuth, null));
}
export var getAuthProviderState = function () { return appProviderRef.state; };
export var signOut = function () {
    getAuthProviderState().auth0.logout({
        logoutParams: {
            returnTo: window.location.origin + routes.SIGN_IN.path,
            client_id: CLIENT_ID,
        },
    });
    clearAllCache();
};

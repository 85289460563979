import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
var MonthCalendarDay = function (props) {
    var isActive = function () {
        if (props.selectedDateEnd || props.selectedDateStart) {
            var thisTime = props.formatedDate;
            var startTime = props.selectedDateStart;
            var endTime = props.selectedDateEnd;
            return thisTime === endTime || thisTime === startTime;
        }
        return false;
    };
    var isInRange = function () {
        if (props.selectedDateEnd && props.selectedDateStart) {
            var thisTime = moment(props.formatedDate).valueOf();
            var startTime = moment(props.selectedDateStart).valueOf();
            var endTime = moment(props.selectedDateEnd).valueOf();
            return thisTime <= endTime && thisTime >= startTime;
        }
        return false;
    };
    var isDisabled = function () {
        var thisTime = props.formatedDate;
        var currentTime = Date.now();
        return currentTime < moment(thisTime).valueOf();
    };
    var isStart = function () {
        return props.selectedDateStart === props.formatedDate && props.selectedDateStart !== props.selectedDateEnd;
    };
    var isEnd = function () {
        return props.selectedDateEnd === props.formatedDate && props.selectedDateStart !== props.selectedDateEnd;
    };
    var isLastInRow = function () {
        return props.idx % 7 === 0 && !isActive();
    };
    var className = classNames({
        'date-picker__part__days__day': true,
        exists: true,
        active: isActive(),
        disabled: isDisabled(),
        range: isInRange(),
        start: isStart(),
        end: isEnd(),
        'last-in-row': isLastInRow(),
    });
    var handleClick = function () {
        var start = props.selectedDateStart, end = props.selectedDateEnd, date = props.formatedDate;
        //if initial state
        if (start === '' && end === '') {
            props.updateState({
                selectedDateStart: date,
                selectedDateEnd: date,
            });
            return;
        }
        //if 1 date set
        if (start === end) {
            if (start === date) {
                return;
            }
            if (start !== date) {
                if (start > date) {
                    props.updateState({
                        selectedDateStart: date,
                    });
                }
                else {
                    props.updateState({
                        selectedDateEnd: date,
                    });
                }
            }
            return;
        }
        if (start === date) {
            props.updateState({
                selectedDateStart: end,
            });
        }
        if (end === date) {
            props.updateState({
                selectedDateEnd: start,
            });
        }
        if (start !== date && end !== date) {
            props.updateState({
                selectedDateStart: date,
                selectedDateEnd: date,
            });
        }
    };
    return (React.createElement("div", { className: className, onClick: handleClick }, props.date.get('date')));
};
export default MonthCalendarDay;

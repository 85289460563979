var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { MQ } from '@emotionConstants';
var AsideInfoMain = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 0 160px 0 70px;\n\n    ", " {\n        margin: 0 25px 40px;\n    }\n\n    ", " {\n        margin: 0 50px 40px;\n    }\n\n    ", " {\n        margin-right: 70px;\n    }\n"], ["\n    margin: 0 160px 0 70px;\n\n    ", " {\n        margin: 0 25px 40px;\n    }\n\n    ", " {\n        margin: 0 50px 40px;\n    }\n\n    ", " {\n        margin-right: 70px;\n    }\n"])), MQ['md'], MQ['lg'], MQ['xlg']);
var AsideTitle = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-bottom: 10px;\n    color: ", ";\n"], ["\n    margin-bottom: 10px;\n    color: ", ";\n"])), function (props) { return props.theme.color.text.header1; });
var AsideSubtitle = styled.h5(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    color: ", ";\n"], ["\n    color: ", ";\n"])), function (props) { return props.theme.color.text.header1; });
var AsideInfo = function () { return (React.createElement(AsideInfoMain, { className: "aside__info-main" },
    React.createElement(AsideTitle, null, "Cloud On Your Terms"),
    React.createElement(AsideSubtitle, null, "The best developer experience on any infrastructure, powered by Kubernetes"))); };
export default AsideInfo;
var templateObject_1, templateObject_2, templateObject_3;

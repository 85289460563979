import React, { useState } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { store } from '@redux/store';
import { Modal, sizes } from '@mirantis/mds-modal';
import { addNewUser } from '@actionCreators/management/management';
import AddNewUserForm from './AddNewUserForm';
var mapDispatch = {
    addNewUser: addNewUser,
};
var connector = connect(null, mapDispatch);
export var AddNewUser = function (props) {
    var _a = useState(true), disabled = _a[0], setDisabled = _a[1];
    var handleSubmit = function (fields) {
        props.addNewUser(fields);
        props.dismiss();
    };
    return (React.createElement(Modal, { title: "Add new user", prompt: "Enter the Mirantis e-mail to add new user to system", size: sizes.LARGE, zIndex: 1000, callAction: {
            label: 'Add User',
            enabled: !disabled,
            onClick: function () { return store.dispatch(submit('add-new-user')); },
        }, cancelAction: {
            onClick: props.dismiss,
        }, onClose: props.dismiss },
        React.createElement(AddNewUserForm, { onSubmit: handleSubmit, disabled: disabled, setDisabled: setDisabled })));
};
export default connector(AddNewUser);

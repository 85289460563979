import React, { memo } from 'react';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import CrossPageFilterContainer from '@commonComponents/cross-page-filter/CrossPageFilterContainer';
import NewSignUpsTable from './NewSignUpsTable';
import NewSignUpsCharts from './NewSignUpsCharts';
var PSEUDO_CC = DASHBOARDS_SCHEMA.REPORTS.PAGES.NEW_SIGN_UPS_REPORT.PSEUDO_CC;
var NewSignUps = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", null, "New Freemium Sign-Ups"),
        React.createElement(CrossPageFilterContainer, { dashboardName: PSEUDO_CC, history: props.history, isGranularity: true }),
        React.createElement(NewSignUpsCharts, null),
        React.createElement(NewSignUpsTable, null)));
};
export default memo(NewSignUps);

var Localstorage = /** @class */ (function () {
    function Localstorage() {
    }
    Localstorage.set = function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    };
    Localstorage.get = function (key) {
        return localStorage.getItem(key);
    };
    Localstorage.remove = function (key) {
        localStorage.removeItem(key);
    };
    return Localstorage;
}());
export { Localstorage };

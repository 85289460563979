import React from 'react';
import LazyImageFactory from '@services/lazy-image-factory/LazyImageFactory';
export var NO_CLUSTERS_FOUND = 'No Clusters found';
export var CLUSTER_SOURCE_TYPES = {
    MKE: 'mke',
    MCC: 'mcc',
};
export var PROVIDER_ICONS_MAP = {
    aws: React.createElement(LazyImageFactory, { imageName: "icons/providers/aws.svg", type: "img" }),
    azure: React.createElement(LazyImageFactory, { imageName: "icons/providers/azure.svg", type: "img" }),
    baremetal: React.createElement(LazyImageFactory, { imageName: "icons/providers/baremetal.svg", type: "img" }),
    equinixmetal: React.createElement(LazyImageFactory, { imageName: "icons/providers/equinixmetal.svg", type: "img" }),
    openstack: React.createElement(LazyImageFactory, { imageName: "icons/providers/openstack.svg", type: "img" }),
    vsphere: React.createElement(LazyImageFactory, { imageName: "icons/providers/vsphere.svg", type: "img" }),
    byo: 'BYO',
};
export var HIDE_ALL = 'Hide all';
export var SHOW_ALL = 'Show all';

export var getNormalizedCustomers = function (response) {
    var data = response.data.map(function (item) {
        return {
            id: item.id,
            name: item.name,
            status: item.status,
            customerType: item.customer_type,
            createdAt: item.created_at,
        };
    });
    return {
        data: data,
        currentPage: response.current_page,
        maxPages: response.max_pages,
    };
};
export var getNormalizedCustomersWithLocation = function (response) {
    var data = response.data.map(function (item) {
        var city = item.city
            ? {
                id: item.city.id,
                name: item.city.name,
                longitude: item.city.longitude,
                latitude: item.city.latitude,
                region: item.city.region,
            }
            : null;
        return {
            id: item.id,
            name: item.name,
            status: item.status,
            customerType: item.customer_type,
            createdAt: item.created_at,
            city: city,
        };
    });
    return {
        data: data,
        maxPages: response.max_pages,
        currentPage: response.current_page,
    };
};
export var getNormalizedCustomersOverview = function (response) {
    var data = response.data.map(function (item) { return ({
        id: item.id,
        name: item.name,
        managedClusterCount: item.managed_cluster_count,
        childClusterCount: item.child_cluster_count,
        instanceCount: item.instance_count,
        nodeCount: item.node_count,
        alertCount: item.alert_count,
        createdAt: item.created_at,
    }); });
    return {
        data: data,
        maxPages: response.max_pages,
        currentPage: response.current_page,
    };
};
export var getNormalizedNewSignUps = function (response) {
    var data = response.data.map(function (dataItem) { return ({
        id: dataItem.id,
        name: dataItem.name,
        email: dataItem.email,
        status: dataItem.status,
        company: dataItem.company,
        createdAt: dataItem.created_at,
        customerType: dataItem.customer_type,
    }); });
    return {
        data: data,
        maxPages: response.max_pages,
        currentPage: response.current_page,
    };
};
export var getNormalizedPatchedCustomer = function (response) { return ({
    id: response.id,
    name: response.name,
    status: response.status,
    customerType: response.customer_type,
}); };

import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import Table from '@commonComponents/table/Table';
import EmptyComponent from '@commonComponents/skeletons/empty/EmptyComponent';
import { getFinalTableConfig, getNormalizers } from './telemetryUsageAvailabilityUtils';
var TelemetryUsageAvailability = function (props) {
    var data = useSelector(function (store) { return ({
        data: get(store, "charts.".concat(props.path, ".data.").concat(props.activeClusterId), []),
    }); }).data;
    var getFinalData = useCallback(function () { return getFinalTableConfig(data, props.columns, props.fields, props.dateKey); }, [data, props.dateKey]);
    if (data.length === 0) {
        return React.createElement(EmptyComponent, { description: "No Availability Data" });
    }
    return React.createElement(Table, { id: props.id, data: getFinalData(), normalizers: getNormalizers(props.fields) });
};
export default TelemetryUsageAvailability;

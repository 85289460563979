import forEach from 'lodash/forEach';
import * as Actions from '@redux/actions';
import { initialState } from './reducer';
var r = {};
r[Actions.pagination.SyncActions.CHANGE_CURRENT_PAGE] = function (draft, payload) {
    draft[payload.paginationName].currentPage = payload.currentPage;
    if (payload.limit) {
        draft[payload.paginationName].limit = payload.limit;
    }
    if (payload.maxPages) {
        draft[payload.paginationName].maxPages = payload.maxPages;
    }
};
r[Actions.pagination.SyncActions.DROP_PAGINATION_TO_DEFAULT] = function (draft, payload) {
    forEach(Object.keys(draft), function (paginationName) {
        if (!payload.paginationNames.includes(paginationName)) {
            return;
        }
        draft[paginationName].currentPage = 1;
        draft[paginationName].limit = initialState[paginationName].minLimit;
    });
};
r[Actions.home.SyncActions.SET_TOP_CUSTOMERS] = function (draft, payload) {
    draft.customerOverview.loading = false;
    draft.customerOverview.currentPage = payload.currentPage;
    draft.customerOverview.maxPages = payload.maxPages;
};
r[Actions.management.SyncActions.SET_USERS] = function (draft, payload) {
    draft.manageUsers.loading = false;
    draft.manageUsers.currentPage = payload.currentPage;
    draft.manageUsers.maxPages = payload.maxPages;
};
r[Actions.management.SyncActions.SET_CUSTOMERS] = function (draft, payload) {
    draft.manageCustomers.loading = false;
    draft.manageCustomers.currentPage = payload.currentPage;
    draft.manageCustomers.maxPages = payload.maxPages;
};
r[Actions.management.SyncActions.SET_INSTANCES] = function (draft, payload) {
    draft.manageInstances.loading = false;
    draft.manageInstances.currentPage = payload.currentPage;
    draft.manageInstances.maxPages = payload.maxPages;
};
r[Actions.clusters.SyncActions.SET_CLUSTERS] = function (draft, payload) {
    draft.generalSearchDashboard.loading = false;
    draft.generalSearchDashboard.currentPage = payload.currentPage;
    draft.generalSearchDashboard.maxPages = payload.maxPages;
};
r[Actions.clusterHealth.SyncActions.SET_CLUSTER_HEALTH] = function (draft, payload) {
    draft.clusterHealth.loading = false;
    draft.clusterHealth.currentPage = payload.currentPage;
    draft.clusterHealth.maxPages = payload.maxPages;
};
r[Actions.bootstrap.SyncActions.SET_BOOTSTRAP_EVENTS] = function (draft, payload) {
    draft.bootstrap.loading = false;
    draft.bootstrap.currentPage = payload.currentPage;
    draft.bootstrap.maxPages = payload.maxPages;
};
r[Actions.management.SyncActions.SET_SINGLE_CUSTOMER_INSTANCES] = function (draft, payload) {
    draft.singleCustomerInstances.loading = false;
    draft.singleCustomerInstances.currentPage = payload.currentPage;
    draft.singleCustomerInstances.maxPages = payload.maxPages;
};
r[Actions.bootstrapInstallations.SyncActions.SET_BOOTSTRAP_INSTALLATIONS] = function (draft, payload) {
    draft.bootstrapInstallations.loading = false;
    draft.bootstrapInstallations.currentPage = payload.currentPage;
    draft.bootstrapInstallations.maxPages = payload.maxPages;
};
r[Actions.newSignUps.SyncActions.SET_NEW_SIGN_UPS] = function (draft, payload) {
    draft.newSignUps.loading = false;
    draft.newSignUps.currentPage = payload.currentPage;
    draft.newSignUps.maxPages = payload.maxPages;
};
r[Actions.activeInstances.SyncActions.SET_ACTIVE_INSTANCES] = function (draft, payload) {
    draft.activeInstances.loading = false;
    draft.activeInstances.currentPage = payload.currentPage;
    draft.activeInstances.maxPages = payload.maxPages;
};
r[Actions.telemetry.SyncActions.SET_TELEMETRY_CUSTOMERS] = function (draft, payload) {
    draft.telemetryCustomers.loading = false;
    draft.telemetryCustomers.currentPage = payload.currentPage;
    draft.telemetryCustomers.maxPages = payload.maxPages;
};
r[Actions.trialReport.SyncActions.SET_TRIAL_REPORT] = function (draft, payload) {
    draft.trialReport.loading = false;
    draft.trialReport.currentPage = payload.currentPage;
    draft.trialReport.maxPages = payload.maxPages;
};
r[Actions.openstackClusterUsage.SyncActions.SET_OPENSTACK_CLUSTER_USAGE] = function (draft, payload) {
    draft.openstackClusterUsage.loading = false;
    draft.openstackClusterUsage.currentPage = payload.currentPage;
    draft.openstackClusterUsage.maxPages = payload.maxPages;
};
r[Actions.permissions.SyncActions.SET_PERMISSION_GROUPS] = function (draft, payload) {
    draft.userGroups.loading = false;
    draft.userGroups.currentPage = payload.currentPage;
    draft.userGroups.maxPages = payload.maxPages;
};
export { r as resolvers };

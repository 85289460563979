var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { KEYS } from './activeInstanceConstants';
var StyledTextRed = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    color: ", ";\n    text-align: center;\n"], ["\n    color: ", ";\n    text-align: center;\n"])), function (props) { return props.theme.color.metric.bad; });
var StyledTextGreen = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    color: ", ";\n    text-align: center;\n"], ["\n    color: ", ";\n    text-align: center;\n"])), function (props) { return props.theme.color.metric.good; });
export var getDetailItem = function (detail) {
    var masterNodeCount = detail.masterNodeCount, workerNodeCount = detail.workerNodeCount, provider = detail.provider, version = detail.version, type = detail.type;
    var detailItemsList = [
        {
            value: type,
            formattedValue: type,
        },
        {
            value: provider,
            formattedValue: "".concat(type ? ' - ' : '').concat(provider),
        },
        {
            value: version,
            formattedValue: "".concat(type || provider ? ' - ' : '').concat(version),
        },
        {
            value: masterNodeCount,
            formattedValue: "".concat(type || provider || version ? ' - ' : '').concat(masterNodeCount, " masters"),
        },
        {
            value: workerNodeCount,
            formattedValue: "".concat(type || provider || version ? ' + ' : '').concat(workerNodeCount, " workers"),
        },
    ];
    return detailItemsList
        .filter(function (_a) {
        var value = _a.value;
        return value !== null;
    })
        .map(function (_a) {
        var formattedValue = _a.formattedValue;
        return formattedValue;
    })
        .join('');
};
export var getDetailValue = function (detail) {
    var masterNodeCount = detail.masterNodeCount, workerNodeCount = detail.workerNodeCount, provider = detail.provider, version = detail.version, type = detail.type, releaseControllerState = detail.releaseControllerState;
    var detailItemsList = [
        {
            value: type,
            formattedValue: "".concat(type ? type : '-'),
        },
        {
            value: provider,
            formattedValue: "".concat(provider ? provider : '-', " "),
        },
        {
            value: version,
            formattedValue: "".concat(version ? version : '-'),
        },
        {
            value: masterNodeCount,
            formattedValue: "".concat(masterNodeCount ? masterNodeCount + ' masters' : '-'),
        },
        {
            value: workerNodeCount,
            formattedValue: "".concat(workerNodeCount ? workerNodeCount + ' workers' : '-'),
        },
        {
            value: releaseControllerState,
            formattedValue: [0, 1].includes(releaseControllerState) ? (releaseControllerState === 1 ? (React.createElement(StyledTextGreen, null, " Up")) : (React.createElement(StyledTextRed, null, " Down"))) : ('-'),
        },
    ];
    var items = [];
    for (var i = 0; i < KEYS.length; i++) {
        items[KEYS[i]] = detailItemsList[i]['formattedValue'];
    }
    return items;
};
export var reorderDetailItems = function (obj1, obj2, priorityPrefix) {
    var a = String(obj1.type);
    var b = String(obj2.type);
    if ((a.startsWith(priorityPrefix) && b.startsWith(priorityPrefix)) ||
        (!a.startsWith(priorityPrefix) && !b.startsWith(priorityPrefix))) {
        return a < b ? -1 : 1;
    }
    return a.startsWith(priorityPrefix) ? -1 : 1;
};
export var getNormalizedData = function (data) {
    if (data === void 0) { data = []; }
    return data.map(function (item) { return ({
        details: item.details,
        id: item.id,
        name: item.name || item.id,
        releaseControllerState: item.releaseControllerState === 1 ? (React.createElement(CheckCircleOutlined, { style: { color: 'green', fontSize: '20px' } })) : item.releaseControllerState === 0 ? (React.createElement(CloseCircleOutlined, { style: { color: 'red', fontSize: '20px' } })) : (''),
        customerId: item.customerId,
        customerName: item.customerName,
        mccVersion: item.mccVersion,
        mccVersionSyncTime: item.mccVersionSyncTime,
        lastHeartbeat: item.lastHeartbeat,
    }); });
};
var templateObject_1, templateObject_2;

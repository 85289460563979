import { store } from '@redux/store';
import * as actionCreators from '@actionCreators/index';
import { PAGINATION_NAMES } from '@configs/DASHBOARD_PSEUDOS';
export var changeCurrentPage = function (payload) {
    var action = actionCreators.pagination.changeCurrentPage(payload);
    store.dispatch(action);
};
export var dropPaginationToDefault = function () {
    var payload = {
        paginationNames: Object.values(PAGINATION_NAMES),
    };
    var action = actionCreators.pagination.dropPaginationToDefault(payload);
    store.dispatch(action);
};

import get from 'lodash/get';
import queryString from 'query-string';
import Redirect from '@commonUtils/urlUtils/Redirect';
import { URL_QUERIES } from '@commonConstants/urlConstants';
var SEARCH_VALUE = URL_QUERIES.COMMON.SEARCH_VALUE;
var UNSUPPORTED_SYMBOLS_MAP = {
    '+': '{pr}',
    '&': '{amp}',
    '~': '{tilde}',
    '#': '{hash}',
    $: '{dollar}',
    '%': '{percent}',
    '^': '{carat}',
    '*': '{asterisk}',
    '(': '{open_parenthesis}',
    ')': '{close_parenthesis}',
    '()': '{parentheses}',
    '=': '{equals}',
    '<>': '{angle_brackets}',
    '<': '{less_than}',
    '>': '{greater_than}',
    '?': '{question_mark}',
    ',': '{comma}',
    ':': '{colon}',
    ';': '{semi_colon }',
    '/': '{slash}',
};
export var getSearchValueFromUrl = function (searchValueQuery) {
    return get(queryString.parse(window.location.search), searchValueQuery, null);
};
export var handleSetFilterSearchValueToUrl = function (searchValueQuery, searchValue, prevSearchValue) {
    if (prevSearchValue !== searchValue) {
        if (searchValue.length === 0) {
            return Redirect.toCurrentPageWithoutQueryParams([searchValueQuery]);
        }
        Redirect.toCurrentPageWithQueryParams(searchValueQuery, [searchValue], '');
    }
};
export var resetSearchValueInUrl = function (searchValueQuery) {
    if (searchValueQuery === void 0) { searchValueQuery = SEARCH_VALUE; }
    return Redirect.toCurrentPageWithoutQueryParams([searchValueQuery]);
};
export var replaceUnsupportedSymbolInUrl = function (value) {
    var replacedValue = value;
    Object.entries(UNSUPPORTED_SYMBOLS_MAP).forEach(function (_a) {
        var replaced = _a[0], key = _a[1];
        if (replacedValue.includes(key)) {
            replacedValue = replacedValue.replace(key, replaced);
        }
        else {
            replacedValue = replacedValue.replace(replaced, key);
        }
    });
    return replacedValue;
};

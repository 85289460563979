import moment from 'moment';
import { DATE_RANGES } from '@utils/date/dateConstants';
import { URL_QUERIES, BOOTSTRAP_ROUTE_PATHNAME } from '@commonConstants/urlConstants';
import { COMMON_GRANULARITY_MINUTES_EQUIVALENTS } from '@commonConstants/granularityConstants';
import { ONE_SEC_IN_MINUTES } from './installationsConstants';
var DATE_RANGE = URL_QUERIES.COMMON.DATE_RANGE;
var CUSTOM_RANGE = DATE_RANGES.CUSTOM_RANGE;
export var getBootstrapInstallationsParams = function (_a, _b) {
    var _c;
    var mode = _a.mode, filter = _a.filter, pagination = _a.pagination, timezone = _a.timezone;
    var sortBy = _b.sortBy, orderBy = _b.orderBy;
    return ({
        customer_types: mode[0] === 'All' ? null : mode.toString(),
        interval: ((_c = filter.granularity.selected) === null || _c === void 0 ? void 0 : _c.value) || '1 day',
        start: filter.dateRange.calculatedDateRange.start.timeStamp,
        end: filter.dateRange.calculatedDateRange.end.timeStamp,
        timezone: timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
        page: pagination.currentPage,
        limit: pagination.limit,
        sortBy: sortBy,
        orderBy: orderBy,
    });
};
export var getTableRowLinkUrl = function (startDate, granularityPseudo) {
    var start = moment(moment(startDate).valueOf()).startOf('day').format('YYYY-MM-DD');
    var minutes = COMMON_GRANULARITY_MINUTES_EQUIVALENTS[granularityPseudo] - ONE_SEC_IN_MINUTES;
    var end = moment(start).add(minutes, 'minutes').format('YYYY-MM-DD');
    return "".concat(BOOTSTRAP_ROUTE_PATHNAME, "?").concat(DATE_RANGE, "=").concat([CUSTOM_RANGE, start, end]);
};

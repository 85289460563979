import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import Line from '@commonComponents/chart/Line/Line';
import { fetchCharts, dropChartsToDefault } from '@redux/dispatchers/charts';
import { getChartOptions, getNormalizedPercentageTooltipValue, getNormalizedConvertedInPercentageValue, getNormalizedChartDataInPercentageConverted, } from '@commonUtils/charts/chartUtils';
import { DateUtils } from '@utils/date/DateUtils';
import { STATIC_REPORTS_DATE_RANGES } from '@utils/date/dateConstants';
import { CHART_MESSAGES } from '@commonConstants/chartsConstants';
import { AVAILABILITY_CHARTS_KEYS, CHART_DASHBOARDS } from './availabilutyClusterChartsConstants';
import { getChartsConfigs } from './availabilityClusterChartsUtils';
import { StyledAvailabilityCharts, StyledAvailabilityChartsContainer } from './StyledMccClusterCharts';
import { StyledChartsWrapperNew, StyledChartsContainer } from '@app/common/react/styled/StyledChartsWrapperNew';
var NOVA = AVAILABILITY_CHARTS_KEYS.NOVA, KEYSTONE = AVAILABILITY_CHARTS_KEYS.KEYSTONE, GLANCE = AVAILABILITY_CHARTS_KEYS.GLANCE, NEUTRON = AVAILABILITY_CHARTS_KEYS.NEUTRON, CINDERV2 = AVAILABILITY_CHARTS_KEYS.CINDERV2, CINDERV3 = AVAILABILITY_CHARTS_KEYS.CINDERV3, SWIFT = AVAILABILITY_CHARTS_KEYS.SWIFT, KPI_PROVISIONING = AVAILABILITY_CHARTS_KEYS.KPI_PROVISIONING;
var CHART_OPTIONS = getChartOptions({
    getNormalizedYValue: getNormalizedConvertedInPercentageValue(),
    getNormalizedTooltipValue: getNormalizedPercentageTooltipValue,
    yValue: { min: 0, max: 100 },
});
var AvailabilityClusterCharts = function (props) {
    var _a = useSelector(function (store) {
        var _a, _b, _c, _d, _e, _f;
        return ({
            timezone: store.userInfo.userPreferences.timezone,
            data: get((_c = (_b = (_a = store.charts) === null || _a === void 0 ? void 0 : _a.mccClusterAvailabilityCharts) === null || _b === void 0 ? void 0 : _b.availabilityCharts) === null || _c === void 0 ? void 0 : _c.data, "".concat(props.activeClusterId), []),
            loading: (_f = (_e = (_d = store.charts) === null || _d === void 0 ? void 0 : _d.mccClusterAvailabilityCharts) === null || _e === void 0 ? void 0 : _e.availabilityCharts) === null || _f === void 0 ? void 0 : _f.loading,
            calculatedDateRange: store.crossPageFilter.dateRange.calculatedDateRange,
            crossPageFilter: store.crossPageFilter,
        });
    }), timezone = _a.timezone, data = _a.data, loading = _a.loading, calculatedDateRange = _a.calculatedDateRange, crossPageFilter = _a.crossPageFilter;
    var t = timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone;
    var _b = DateUtils.calculateDateRangeByOption(STATIC_REPORTS_DATE_RANGES.PREVIOUS_1_MONTHS), start = _b.start, end = _b.end;
    var params = {
        timezone: t,
        start: props.checkedActive || crossPageFilter.dashboardName === null
            ? start.timeStamp
            : calculatedDateRange === null || calculatedDateRange === void 0 ? void 0 : calculatedDateRange.start.timeStamp,
        end: props.checkedActive || crossPageFilter.dashboardName === null
            ? end.timeStamp
            : calculatedDateRange === null || calculatedDateRange === void 0 ? void 0 : calculatedDateRange.end.timeStamp,
        granularity: '1 day',
        cluster: props.activeClusterId,
        license: props.clusterLicense,
        activeClusterId: props.activeClusterId,
    };
    useEffect(function () {
        if (props.shouldFetchCharts) {
            fetchCharts(getChartsConfigs(params));
        }
        return function () {
            dropChartsToDefault(CHART_DASHBOARDS.AVAILABILITY_CHARTS);
        };
    }, [props.activeClusterId]);
    return (React.createElement(StyledAvailabilityChartsContainer, { className: "availability-cluster-charts" },
        React.createElement(StyledChartsContainer, null,
            React.createElement(StyledAvailabilityCharts, null,
                React.createElement(StyledChartsWrapperNew, { className: "two" },
                    React.createElement(Line, { chartKeys: {
                            key: NOVA,
                            clusterId: props.activeClusterId,
                            dashboard: CHART_DASHBOARDS.AVAILABILITY_CHARTS,
                        }, title: "Nova", loadingOut: loading, chartOptions: CHART_OPTIONS, datasets: [getNormalizedChartDataInPercentageConverted(data, NOVA, 'time')], message: CHART_MESSAGES.CPU_USAGE_AND_CAPACITY }),
                    React.createElement(Line, { chartKeys: {
                            key: KEYSTONE,
                            clusterId: props.activeClusterId,
                            dashboard: CHART_DASHBOARDS.AVAILABILITY_CHARTS,
                        }, title: "Keystone", loadingOut: loading, chartOptions: CHART_OPTIONS, message: CHART_MESSAGES.RAM_USAGE_AND_CAPACITY, datasets: [getNormalizedChartDataInPercentageConverted(data, KEYSTONE, 'time')] }))),
            React.createElement(StyledAvailabilityCharts, null,
                React.createElement(StyledChartsWrapperNew, { className: "two" },
                    React.createElement(Line, { chartKeys: {
                            key: GLANCE,
                            clusterId: props.activeClusterId,
                            dashboard: CHART_DASHBOARDS.AVAILABILITY_CHARTS,
                        }, title: "Glance", loadingOut: loading, chartOptions: CHART_OPTIONS, message: CHART_MESSAGES.RAM_USAGE_AND_CAPACITY, datasets: [getNormalizedChartDataInPercentageConverted(data, GLANCE, 'time')] }),
                    React.createElement(Line, { chartKeys: {
                            key: NEUTRON,
                            clusterId: props.activeClusterId,
                            dashboard: CHART_DASHBOARDS.AVAILABILITY_CHARTS,
                        }, title: "Neutron", loadingOut: loading, chartOptions: CHART_OPTIONS, message: CHART_MESSAGES.PVS_USAGE_AND_CAPACITY, datasets: [getNormalizedChartDataInPercentageConverted(data, NEUTRON, 'time')] }))),
            React.createElement(StyledAvailabilityCharts, null,
                React.createElement(StyledChartsWrapperNew, { className: "two" },
                    React.createElement(Line, { chartKeys: {
                            key: CINDERV2,
                            clusterId: props.activeClusterId,
                            dashboard: CHART_DASHBOARDS.AVAILABILITY_CHARTS,
                        }, title: "Cinderv2", loadingOut: loading, chartOptions: CHART_OPTIONS, message: CHART_MESSAGES.NODE_USAGE, datasets: [getNormalizedChartDataInPercentageConverted(data, CINDERV2, 'time')] }),
                    React.createElement(Line, { chartKeys: {
                            key: CINDERV3,
                            clusterId: props.activeClusterId,
                            dashboard: CHART_DASHBOARDS.AVAILABILITY_CHARTS,
                        }, title: "Cinderv3", loadingOut: loading, chartOptions: CHART_OPTIONS, message: CHART_MESSAGES.NODE_USAGE, datasets: [getNormalizedChartDataInPercentageConverted(data, CINDERV3, 'time')] })),
                React.createElement(StyledChartsWrapperNew, { className: "two" },
                    React.createElement(Line, { chartKeys: {
                            key: SWIFT,
                            clusterId: props.activeClusterId,
                            dashboard: CHART_DASHBOARDS.AVAILABILITY_CHARTS,
                        }, title: "Swift", loadingOut: loading, chartOptions: CHART_OPTIONS, datasets: [getNormalizedChartDataInPercentageConverted(data, SWIFT, 'time')] }),
                    React.createElement(Line, { chartKeys: {
                            key: KPI_PROVISIONING,
                            clusterId: props.activeClusterId,
                            dashboard: CHART_DASHBOARDS.AVAILABILITY_CHARTS,
                        }, loadingOut: loading, title: "KPI provisioning", chartOptions: CHART_OPTIONS, datasets: [getNormalizedChartDataInPercentageConverted(data, KPI_PROVISIONING, 'time')] }))))));
};
export default memo(AvailabilityClusterCharts);

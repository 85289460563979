import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mirantis/mds-button';
import { changeTermV2 } from '@root/app/redux/dispatchers/multiFilter';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { resetSearchValueInUrl } from '@commonComponents/search/searchUtils';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import { SEARCH_PLACEHOLDER } from '@root/app/common/constants/commonConstants';
import { USER_GROUPS_TITLE, SEARCH_DELAY, SEARCH_LABEL } from './permissionGroupsConstants';
import { getNormalizedData } from './permissionGroupsUtils';
import PermissionGroupsTable from './PermissionGroupsTable';
import Search from '@root/app/common/react/components/search/Search';
import { createPermissionGroup, fetchPermissionsGroup } from '@redux/dispatchers/permissions';
import { StringUtils } from '@root/app/utils/string/StringUtils';
import StyledUserGroups from './StyledPermissionGroups';
import ModifyPermissionsGroupModal from './create-edit-permission-group-modal/CreateEditPermissionGroupModal';
var SEARCH_VALUE = URL_QUERIES.COMMON.SEARCH_VALUE;
var PermissionGroups = function () {
    var _a = useSelector(function (store) { return store.permissions.allPermissionGroups; }), data = _a.data, loading = _a.loading;
    var multiFilter = useSelector(function (store) { return store.multiFilter; });
    var crossPageFilter = useSelector(function (store) { return store.crossPageFilter; });
    var pagination = useSelector(function (store) { return store.pagination.userGroups; });
    var timezone = useSelector(function (store) { return store.userInfo.userPreferences.timezone; });
    var normalizedData = getNormalizedData(data, pagination);
    var resetAdditionalParams = function () {
        resetSearchValueInUrl(SEARCH_VALUE);
        changeTermV2({
            tableName: DASHBOARDS_SCHEMA.ACCOUNT_MANAGEMENT.PAGES.PERMISSION_GROUPS.PSEUDO_CC,
            pseudo: 'searchTerm',
            value: '',
        });
    };
    var onSearchChange = function (value) {
        var length = StringUtils.countStringLengthWithoutSpaces(value);
        if (value === '' || length > 2) {
            changeTermV2({
                tableName: DASHBOARDS_SCHEMA.ACCOUNT_MANAGEMENT.PAGES.PERMISSION_GROUPS.PSEUDO_CC,
                pseudo: 'searchTerm',
                value: value,
            });
        }
    };
    return (React.createElement(StyledUserGroups, null,
        React.createElement("div", { className: "user_groups-header-top-line" },
            React.createElement("h2", { className: "user_groups-table-title" }, USER_GROUPS_TITLE),
            React.createElement(ModifyPermissionsGroupModal, { title: "Create Permission Group", onSaveApiCall: createPermissionGroup, initialGroupSchemaApiCall: fetchPermissionsGroup },
                React.createElement(Button, { className: "filter__button add_new_group", style: { marginLeft: '20px' }, label: "Add New Group" }))),
        React.createElement("div", { className: "user_groups-table-filters" },
            React.createElement(Search, { max: 64, onChange: onSearchChange, searchValueQuery: SEARCH_VALUE, label: SEARCH_LABEL, withDelay: SEARCH_DELAY, placeholder: SEARCH_PLACEHOLDER }),
            React.createElement(Button, { className: "filter__button reset", label: "Reset", onClick: resetAdditionalParams })),
        React.createElement(PermissionGroupsTable, { columnControl: true, loading: loading, timezone: timezone, data: normalizedData, pagination: pagination, multiFilter: multiFilter, crossPageFilter: crossPageFilter })));
};
export default PermissionGroups;

export var LAST_SYNC_DATE_OPTIONS = [
    {
        label: 'Never',
        value: null,
    },
    {
        label: '1h',
        value: 1,
    },
    {
        label: '6h',
        value: 6,
    },
    {
        label: '12h',
        value: 12,
    },
    {
        label: '1 day',
        value: 24,
    },
    {
        label: '7 days',
        value: 168,
    },
    {
        label: '30 days',
        value: 720,
    },
];
export var DEFAULT_LAST_SYNC_DATE = LAST_SYNC_DATE_OPTIONS[4]; // 1d

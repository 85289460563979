import { store } from '@redux/store';
import * as actionCreators from '@actionCreators/index';
export var fetchShouldShowWarningBanner = function () {
    var action = actionCreators.warningBanner.getWarningBanner();
    store.dispatch(action);
};
export var setShouldShowWarningBanner = function (payload) {
    var action = actionCreators.warningBanner.setWarningBanner(payload);
    store.dispatch(action);
};
export var fetchWarningDetails = function () {
    var action = actionCreators.warningBanner.getWarningBannerDetails();
    store.dispatch(action);
};
export var setWarningBannerDetails = function (payload) {
    var action = actionCreators.warningBanner.setWarningBannerDetails(payload);
    store.dispatch(action);
};

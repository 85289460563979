var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import classNames from 'classnames';
import styled from '@emotion/styled';
import uuid from 'react-uuid';
import 'react-tooltip/dist/react-tooltip.css';
var StyledTooltip = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    .tooltip__message {\n        max-width: 200px;\n        padding: 3px 8px;\n\n        &:before {\n            display: none;\n        }\n    }\n"], ["\n    .tooltip__message {\n        max-width: 200px;\n        padding: 3px 8px;\n\n        &:before {\n            display: none;\n        }\n    }\n"])));
var Tooltip = function (props) {
    var uid = uuid();
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    return (React.createElement(StyledTooltip, { className: classNames('tooltip', props.wrapperClassName), id: "tolltip" },
        React.cloneElement(props.children, { id: uid }),
        createPortal(React.createElement(ReactTooltip
        /* @ts-ignore */
        , { 
            /* @ts-ignore */
            content: props.message, isOpen: isOpen, setIsOpen: function (value) { return setIsOpen(value); }, anchorId: uid, place: props.place || 'left', style: { maxWidth: '400px' }, className: classNames('tooltip__message', props.className, { show: isOpen }) }), document.getElementById('root'))));
};
export default Tooltip;
var templateObject_1;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { Popover } from '@mirantis/mds-popover';
import { DateUtils } from '@utils/date/DateUtils';
import classNames from 'classnames';
import { fetchWarningDetails } from '@redux/dispatchers/warningBanner';
import { COLUMNS } from './jobsConstants';
import { StyledJobsStatusTable, StyledApplicationStatusDot } from './jobsStatusPopoverStyled';
var NA = 'Not Applicable';
var getNormalizedJobs = function (jobs) {
    return jobs.map(function (job) { return (__assign(__assign({}, job), { lastRun: job.lastRun ? DateUtils.getDateStringWithDynamicTimezone(job.lastRun) : NA, lastSuccessfulRun: job.lastSuccessfulRun
            ? DateUtils.getDateStringWithDynamicTimezone(job.lastSuccessfulRun)
            : NA })); });
};
export var JobsModal = function () {
    var _a = useState(false), isVisible = _a[0], setIsVisible = _a[1];
    var bannerStatus = useSelector(function (store) { var _a; return (_a = store.warningBanner.banner) === null || _a === void 0 ? void 0 : _a.status; });
    var jobs = useSelector(function (store) { return store.warningBanner.jobs.data; });
    var loading = useSelector(function (store) { return store.warningBanner.jobs.loading; });
    var normalizedJobs = getNormalizedJobs(jobs);
    useEffect(function () {
        fetchWarningDetails();
    }, [isVisible]);
    return (React.createElement(StyledJobsStatusTable, null,
        React.createElement(Popover, { placement: "TOP_RIGHT", className: "jobs-detail-info", "aria-label": "job-status-popover", content: React.createElement(Table, { columns: COLUMNS, dataSource: normalizedJobs, loading: loading, pagination: false, rowKey: "name" }), onShow: function () { return setIsVisible(!isVisible); } },
            React.createElement(StyledApplicationStatusDot, { className: classNames('jobs-status', bannerStatus === null || bannerStatus === void 0 ? void 0 : bannerStatus.toLowerCase()) }))));
};
export default JobsModal;

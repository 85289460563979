export var getNormalizedSFAccounts = function (response) {
    var data = response.data.map(function (dataItem) { return ({
        id: dataItem.id,
        name: dataItem.name,
        createdAt: dataItem.created_at,
        lastModified: dataItem.last_modified,
    }); });
    return {
        data: data,
        maxPages: response.max_pages,
        currentPage: response.current_page,
    };
};
export var getNormalizedSFAccount = function (response) {
    return response
        ? {
            id: response.id,
            shortId: response.short_id,
            name: response.name,
            createdAt: response.created_at,
            lastModified: response.last_modified,
        }
        : null;
};

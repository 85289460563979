import produce from 'immer';
import { resolvers } from './resolvers';
var initialMapFilter = {
    region: {
        active: 'All World',
        options: ['All World'],
    },
    status: {
        active: 'All Statuses',
        options: ['All Statuses'],
    },
    customer: {
        active: 'All Customers',
        options: ['All Customers'],
    },
};
export var prev = {
    region: 'All World',
    status: 'All Statuses',
    customer: 'All Customers',
};
export var initialState = {
    topCustomers: {
        loading: true,
        data: [],
    },
    map: {
        acceptDisabled: true,
        filtration: {
            prev: prev,
            initial: initialMapFilter,
            current: initialMapFilter,
        },
        filteredCustomers: [],
        customersList: [],
    },
};
export var home = function (state, action) {
    if (state === void 0) { state = initialState; }
    if (action === void 0) { action = {}; }
    var type = action.type, payload = action.payload;
    if (!(type in resolvers)) {
        return state;
    }
    var resolver = function (draft) { return resolvers[type](draft, payload); };
    return produce(state, resolver);
};

export var TELEMETRY_USAGE_FIELDS = ['mke_api_availability', 'k8s_api_availability'];
export var OPENSTACK_USAGE_FIELDS = [
    'nova',
    'keystone',
    'glance',
    'neutron',
    'cinderv2',
    'cinderv3',
    'swift',
    'kpi_provisioning',
];
export var AVAILABILITY_LABELS = {
    mke_api_availability: 'MKE API',
    k8s_api_availability: 'K8S API',
    nova: 'Nova',
    keystone: 'Keystone',
    glance: 'Glance',
    neutron: 'Neutron',
    cinderv2: 'Cinder v2',
    cinderv3: 'Cinder v3',
    swift: 'Swift',
    kpi_provisioning: 'KPI provisioning',
};
export var TELEMETRY_USAGE_COLUMNS = [
    {
        name: 'Date',
        key: 'date',
        isVisibleByDefault: true,
    },
    {
        name: 'MKE API Availability',
        key: 'mke_api_availability',
        isVisibleByDefault: true,
    },
    {
        name: 'K8S API Availability',
        key: 'k8s_api_availability',
        isVisibleByDefault: true,
    },
];
export var OPENSTACK_USAGE_COLUMNS = [
    {
        name: 'Date',
        key: 'date',
        isVisibleByDefault: true,
    },
    {
        name: 'Nova',
        key: 'nova',
        isVisibleByDefault: true,
    },
    {
        name: 'Keystone',
        key: 'keystone',
        isVisibleByDefault: true,
    },
    {
        name: 'Glance',
        key: 'glance',
        isVisibleByDefault: true,
    },
    {
        name: 'Neutron',
        key: 'neutron',
        isVisibleByDefault: true,
    },
    {
        name: 'Cinderv2',
        key: 'cinderv2',
        isVisibleByDefault: true,
    },
    {
        name: 'Cinderv3',
        key: 'cinderv3',
        isVisibleByDefault: true,
    },
    {
        name: 'Swift',
        key: 'swift',
        isVisibleByDefault: true,
    },
    {
        name: 'KPI provisioning',
        key: 'kpi_provisioning',
        isVisibleByDefault: true,
    },
];

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as Actions from '@root/app/redux/actions/trialReport';
var r = { data: [] };
r[Actions.SyncActions.SET_TRIAL_REPORT] = function (draft, payload) {
    Object.assign(draft, __assign({}, payload));
    draft.loading = false;
};
r[Actions.AsyncActions.GET_TRIAL_REPORT] = function (draft, payload) {
    draft.loading = true;
    draft.filter = {
        cluster_created: payload.cluster_created,
        end: payload.end,
        is_mirantis: payload.is_mirantis,
        search: payload.search,
        start: payload.start,
        statuses: payload.statuses,
    };
};
export { r as resolvers };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo, useCallback } from 'react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';
import { StyledMccGroppedTable } from '@commonComponents/gropped-column-table/styled';
import TelemetryClustersTableWithDetailView from '@commonComponents/telemetry-clusters-table-with-detail-view/TelemetryClustersTableWithDetailView';
import { MCC_TABLE_TITLE, MCC_CLUSTERS_TABLE_COLUMNS, MCC_CLUSTERS_TABLE_SUBTITLES, MCC_CUSTOM_CLUSTERS_TABLE_COLUMNS, } from './mccTelemetryCustomerConstants';
import { PAGINATION_NAMES } from '@configs/DASHBOARD_PSEUDOS';
import MccTelemetryDetailView from './MccTelemetryDetailView';
import { getNormalizedClusters, normalizers } from '../../../../commonTelemetryDashboardUtils';
import TableLoadingStates from '@commonComponents/table-loading-states/TableLoadingStates';
var TELEMETRY_CUSTOMER_CLUSTERS_MCC = PAGINATION_NAMES.TELEMETRY_CUSTOMER_CLUSTERS_MCC;
var MccTelemetryTable = function (props) {
    var _a;
    var clusters = useSelector(function (store) { return get(store, 'telemetry.customer.mccOverview.clusters', {}); });
    var loading = useSelector(function (store) { return get(store, 'telemetry.customer.loading', false); });
    var mccHeadExtraColumns = [{ key: 'provider', title: 'Provider', className: 'provider', isTooltip: true }];
    var mccClusterId = (_a = clusters[props.activeClusterId]) === null || _a === void 0 ? void 0 : _a.clusterId;
    var clustersList = Object.values(clusters);
    var normalizedClusters = useCallback(function () { return getNormalizedClusters(clustersList); }, [clustersList]);
    return (React.createElement(TableLoadingStates, { data: clustersList, count: 15, loading: loading, className: "mcc-clusters-table", empty: { description: 'No MCC Clusters found' } },
        React.createElement(StyledMccGroppedTable, { className: "mcc-clusters-table" },
            React.createElement(TelemetryClustersTableWithDetailView, __assign({}, props, { normalizers: normalizers, title: MCC_TABLE_TITLE, columns: props.checkedActive ? MCC_CLUSTERS_TABLE_COLUMNS : MCC_CUSTOM_CLUSTERS_TABLE_COLUMNS, subtitles: MCC_CLUSTERS_TABLE_SUBTITLES, paginationName: TELEMETRY_CUSTOMER_CLUSTERS_MCC, data: normalizedClusters(), extraHeadColumns: mccHeadExtraColumns, expanded: !isNil(mccClusterId) }),
                React.createElement(MccTelemetryDetailView, { activeClusterId: mccClusterId, checkedActive: props.checkedActive })))));
};
export default memo(MccTelemetryTable);

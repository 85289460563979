import moment from 'moment';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { CLUSTER_NAME_TERM, SEARCH_TERM } from '@commonConstants/searchTermPseudosConstants';
import { MONTHS } from '@commonConstants/commonConstants';
var DASHBOARDS = DASHBOARDS_SCHEMA.DASHBOARDS, ACCOUNT_MANAGEMENT = DASHBOARDS_SCHEMA.ACCOUNT_MANAGEMENT, REPORTS = DASHBOARDS_SCHEMA.REPORTS, SUPPORT = DASHBOARDS_SCHEMA.SUPPORT;
export var MULTI_FILTER_CONFIG = {};
var HEALTH_STATUSES = ['OK', 'WARNING', 'BAD', 'UNKNOWN'];
MULTI_FILTER_CONFIG[SUPPORT.PAGES.ALERTS.PSEUDO_CC] = {
    PICKERS: [
        {
            name: 'Customer',
            pseudo: 'customer',
            termPseudo: SEARCH_TERM,
            effectsOn: ['cluster'],
        },
        {
            name: 'Cluster',
            pseudo: 'cluster',
            termPseudo: CLUSTER_NAME_TERM,
        },
        {
            name: 'Status',
            pseudo: 'alertStatus',
            static: true,
        },
        {
            name: 'Alerts Priority',
            pseudo: 'alertPriority',
            static: true,
        },
    ],
    INITIAL_STATE: {
        isApiRequired: true,
        config: {
            selected: {
                customer: [],
                cluster: [],
                alertStatus: [],
                alertPriority: [],
            },
            configuration: {
                customer: {
                    lazyLoad: true,
                    currentPage: 1,
                    maxPages: null,
                    data: [],
                    searchTerm: '',
                },
                cluster: {
                    lazyLoad: true,
                    currentPage: 1,
                    maxPages: null,
                    data: [],
                    clusterNameTerm: '',
                },
                alertStatus: {
                    lazyLoad: false,
                    data: [],
                },
                alertPriority: {
                    lazyLoad: false,
                    data: [],
                },
            },
        },
    },
};
MULTI_FILTER_CONFIG[DASHBOARDS.PAGES.BOOTSTRAP.PSEUDO_CC] = {
    PICKERS: [
        {
            name: 'Customer',
            pseudo: 'customer',
            termPseudo: SEARCH_TERM,
            effectsOn: ['instance'],
        },
        {
            name: 'Instance',
            pseudo: 'instance',
            termPseudo: SEARCH_TERM,
        },
        {
            name: 'Stage',
            pseudo: 'stage',
            static: true,
        },
        {
            name: 'Provider',
            pseudo: 'provider',
            static: true,
        },
        {
            name: 'Status',
            pseudo: 'status',
            static: true,
        },
    ],
    INITIAL_STATE: {
        isApiRequired: true,
        config: {
            selected: {
                customer: [],
                instance: [],
                stage: [],
                provider: [],
                status: [],
            },
            configuration: {
                customer: {
                    lazyLoad: true,
                    currentPage: 1,
                    maxPages: null,
                    data: [],
                    searchTerm: '',
                },
                instance: {
                    lazyLoad: true,
                    currentPage: 1,
                    maxPages: null,
                    data: [],
                    searchTerm: '',
                },
                stage: {
                    lazyLoad: false,
                    data: [],
                },
                provider: {
                    lazyLoad: false,
                    data: [],
                },
                status: {
                    lazyLoad: false,
                    data: ['ok', 'err'],
                },
            },
        },
    },
};
MULTI_FILTER_CONFIG[SUPPORT.PAGES.CLUSTER_HEALTH.PSEUDO_CC] = {
    PICKERS: [
        {
            name: 'Customer',
            pseudo: 'customer',
            termPseudo: SEARCH_TERM,
        },
        {
            name: 'Health Statuses',
            pseudo: 'healthStatus',
            static: true,
        },
    ],
    INITIAL_STATE: {
        isApiRequired: true,
        config: {
            selected: {
                customer: [],
                healthStatus: [],
            },
            searchTerm: '',
            configuration: {
                customer: {
                    lazyLoad: true,
                    currentPage: 1,
                    maxPages: null,
                    data: [],
                    searchTerm: '',
                },
                healthStatus: {
                    lazyLoad: false,
                    data: [],
                },
            },
        },
    },
};
MULTI_FILTER_CONFIG[REPORTS.PAGES.TRIAL_REPORT.PSEUDO_CC] = {
    PICKERS: [
        {
            name: 'Customer',
            pseudo: 'customer',
            termPseudo: SEARCH_TERM,
        },
        {
            name: 'Health Statuses',
            pseudo: 'healthStatus',
            static: true,
        },
    ],
    INITIAL_STATE: {
        isApiRequired: true,
        config: {
            selected: {
                customer: [],
                healthStatus: [],
            },
            searchTerm: '',
            configuration: {
                customer: {
                    lazyLoad: true,
                    currentPage: 1,
                    maxPages: null,
                    data: [],
                    searchTerm: '',
                },
                healthStatus: {
                    lazyLoad: false,
                    data: [],
                },
            },
        },
    },
};
MULTI_FILTER_CONFIG[DASHBOARDS_SCHEMA.MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.FILTER_PSEUDOS.TELEMETRY_CUSTOMERS] = {
    PICKERS: [
        {
            name: 'Has Active License',
            pseudo: 'hasActiveMkeLicense',
            type: 'checkbox',
        },
    ],
    INITIAL_STATE: {
        isApiRequired: false,
        config: {
            searchTerm: '',
            selected: {
                hasActiveMkeLicense: [false],
            },
            configuration: {},
        },
    },
};
MULTI_FILTER_CONFIG[DASHBOARDS_SCHEMA.MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.FILTER_PSEUDOS.TELEMETRY_CUSTOMERS] = {
    PICKERS: [
        {
            name: 'Has Active MKE License',
            pseudo: 'hasActiveMkeLicense',
            type: 'checkbox',
        },
        {
            name: 'Is MCC Customer',
            pseudo: 'isMccCustomer',
            type: 'checkbox',
        },
        {
            name: 'Hide Inactive Customers',
            pseudo: 'hideInactiveCustomers',
            type: 'checkbox',
        },
    ],
    INITIAL_STATE: {
        isApiRequired: false,
        config: {
            searchTerm: '',
            selected: {
                hasActiveMkeLicense: [false],
                isMccCustomer: [true],
                hideInactiveCustomers: [true],
            },
            configuration: {},
        },
    },
};
MULTI_FILTER_CONFIG[DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE.PSEUDO_CC] = {
    PICKERS: [
        {
            name: 'Customer',
            pseudo: 'customer',
            termPseudo: SEARCH_TERM,
            effectsOn: ['instance', 'cluster'],
        },
        {
            name: 'Instance',
            pseudo: 'instance',
            termPseudo: SEARCH_TERM,
            effectsOn: ['cluster'],
        },
        {
            name: 'Cluster',
            pseudo: 'cluster',
            termPseudo: CLUSTER_NAME_TERM,
        },
    ],
    INITIAL_STATE: {
        isApiRequired: true,
        config: {
            selected: {
                customer: [],
                instance: [],
                cluster: [],
            },
            configuration: {
                customer: {
                    lazyLoad: true,
                    currentPage: 1,
                    maxPages: null,
                    data: [],
                    searchTerm: '',
                },
                instance: {
                    lazyLoad: true,
                    currentPage: 1,
                    maxPages: null,
                    data: [],
                    searchTerm: '',
                },
                cluster: {
                    lazyLoad: true,
                    currentPage: 1,
                    maxPages: null,
                    data: [],
                    clusterNameTerm: '',
                },
            },
        },
    },
};
MULTI_FILTER_CONFIG[DASHBOARDS.PAGES.OPENSTACK_CLUSTERS_USAGE.PSEUDO_CC] = {
    PICKERS: [
        {
            name: 'Customer',
            pseudo: 'customer',
            termPseudo: SEARCH_TERM,
        },
    ],
    INITIAL_STATE: {
        isApiRequired: true,
        config: {
            selected: {
                customer: [],
            },
            searchTerm: '',
            configuration: { customer: { lazyLoad: true, currentPage: 1, maxPages: null, data: [], searchTerm: '' } },
        },
    },
};
MULTI_FILTER_CONFIG[ACCOUNT_MANAGEMENT.PAGES.MANAGE_USERS.PSEUDO_CC] = {
    PICKERS: [
        {
            name: 'Status',
            pseudo: 'status',
            static: true,
        },
        {
            name: 'Permissions',
            pseudo: 'permissions',
            termPseudo: SEARCH_TERM,
            static: false,
        },
    ],
    INITIAL_STATE: {
        isApiRequired: true,
        config: {
            searchTerm: '',
            selected: {
                status: [],
                permissions: [],
            },
            configuration: {
                status: {
                    lazyLoad: false,
                    data: ['Active', 'Inactive'],
                },
                permissions: {
                    lazyLoad: true,
                    data: [],
                    currentPage: 1,
                    maxPages: null,
                },
            },
        },
    },
};
MULTI_FILTER_CONFIG[ACCOUNT_MANAGEMENT.PAGES.MANAGE_CUSTOMERS.PSEUDO_CC] = {
    PICKERS: [
        {
            name: 'Type',
            pseudo: 'type',
        },
        {
            name: 'Status',
            pseudo: 'status',
            static: true,
        },
    ],
    INITIAL_STATE: {
        isApiRequired: true,
        config: {
            selected: {
                status: [],
                type: [],
            },
            searchTerm: '',
            configuration: {
                status: {
                    lazyLoad: false,
                    data: HEALTH_STATUSES,
                },
                type: {
                    lazyLoad: false,
                    data: [],
                },
            },
        },
    },
};
MULTI_FILTER_CONFIG[REPORTS.PAGES.TRIAL_STATISTIC.TABS.AWS_COSTS] = {
    PICKERS: [
        {
            name: 'AWS Accounts',
            pseudo: 'awsAccounts',
        },
        {
            static: true,
            singleSelect: true,
            name: 'Year',
            pseudo: 'year',
            placeholder: 'Choose Year',
        },
        {
            static: true,
            singleSelect: true,
            name: 'Month',
            pseudo: 'month',
            placeholder: 'Choose Month',
        },
    ],
    INITIAL_STATE: {
        isApiRequired: true,
        config: {
            selected: {
                awsAccounts: [],
                year: [moment().year().toString()],
                month: [moment().format('MMMM')],
            },
            searchTerm: '',
            configuration: {
                awsAccounts: {
                    lazyLoad: false,
                    currentPage: 1,
                    maxPages: null,
                    data: [],
                },
                year: {
                    lazyLoad: false,
                    currentPage: 1,
                    maxPages: null,
                    data: [moment().subtract(1, 'year').year().toString(), moment().year().toString()],
                },
                month: {
                    lazyLoad: false,
                    currentPage: 1,
                    maxPages: null,
                    data: MONTHS,
                },
            },
        },
    },
};
MULTI_FILTER_CONFIG[REPORTS.PAGES.TRIAL_STATISTIC.TABS.OCCUPIED_SLOTS] = {
    PICKERS: [
        {
            type: 'checkbox',
            name: 'External',
            pseudo: 'external',
        },
        {
            type: 'checkbox',
            name: 'Stacked',
            pseudo: 'stacked',
        },
    ],
    INITIAL_STATE: {
        isApiRequired: true,
        config: {
            selected: {
                external: [true],
                stacked: [false],
            },
            searchTerm: '',
            configuration: {
                external: {
                    lazyLoad: false,
                    currentPage: 1,
                    maxPages: null,
                    data: [true],
                },
                stacked: {
                    lazyLoad: false,
                    currentPage: 1,
                    maxPages: null,
                    data: [false],
                },
            },
        },
    },
};
MULTI_FILTER_CONFIG[REPORTS.PAGES.TRIAL_STATISTIC.TABS.SIGN_UPS] = {
    PICKERS: [
        {
            type: 'checkbox',
            name: 'External',
            pseudo: 'external',
        },
    ],
    INITIAL_STATE: {
        isApiRequired: false,
        config: {
            selected: {
                external: [true],
            },
            searchTerm: '',
            configuration: {
                external: {
                    lazyLoad: false,
                    currentPage: 1,
                    maxPages: null,
                    data: [true],
                },
            },
        },
    },
};
MULTI_FILTER_CONFIG[DASHBOARDS.PAGES.OPENSTACK_USAGE.TABS.OPENSTACK_OVERALL_USAGE.PSEUDO_CC] = {
    PICKERS: [
        {
            name: 'Customer',
            pseudo: 'customer',
            termPseudo: SEARCH_TERM,
            effectsOn: ['instance', 'cluster'],
        },
        {
            name: 'Container Cloud Instances with OpenStack',
            pseudo: 'instance',
            termPseudo: SEARCH_TERM,
            effectsOn: ['cluster'],
        },
        {
            name: 'OpenStack Child Cluster',
            pseudo: 'cluster',
            termPseudo: CLUSTER_NAME_TERM,
        },
    ],
    INITIAL_STATE: {
        isApiRequired: true,
        config: {
            selected: {
                customer: [],
                instance: [],
                cluster: [],
            },
            configuration: {
                customer: {
                    lazyLoad: true,
                    currentPage: 1,
                    maxPages: null,
                    data: [],
                    searchTerm: '',
                },
                instance: {
                    lazyLoad: true,
                    currentPage: 1,
                    maxPages: null,
                    data: [],
                    searchTerm: '',
                },
                cluster: {
                    lazyLoad: true,
                    currentPage: 1,
                    maxPages: null,
                    data: [],
                    clusterNameTerm: '',
                },
            },
        },
    },
};
MULTI_FILTER_CONFIG[ACCOUNT_MANAGEMENT.PAGES.MANAGE_CUSTOMERS.FILTER_PSEUDOS.MANAGE_INSTANCES] = {
    PICKERS: [],
    INITIAL_STATE: {
        isApiRequired: false,
        config: { selected: {}, searchTerm: '', configuration: {} },
    },
};
MULTI_FILTER_CONFIG[SUPPORT.PAGES.SEARCH_CLUSTERS.PSEUDO_CC] = {
    PICKERS: [],
    INITIAL_STATE: {
        isApiRequired: false,
        config: { selected: {}, searchTerm: '', configuration: {} },
    },
};
MULTI_FILTER_CONFIG[ACCOUNT_MANAGEMENT.PAGES.MANAGE_CUSTOMERS.FILTER_PSEUDOS.SINGLE_CUSTOMER_INSTANCES] = {
    PICKERS: [],
    INITIAL_STATE: {
        isApiRequired: false,
        config: { selected: {}, searchTerm: '', configuration: {} },
    },
};
export var getMultiFilterInitialState = function (pseudo) {
    if (pseudo in MULTI_FILTER_CONFIG) {
        return MULTI_FILTER_CONFIG[pseudo].INITIAL_STATE;
    }
    throw new Error("Can't resolve ".concat(pseudo, " if filter configuration"));
};
export var getMultiFilterPickers = function (pseudo) {
    if (pseudo in MULTI_FILTER_CONFIG)
        return MULTI_FILTER_CONFIG[pseudo].PICKERS;
    throw new Error("Can't resolve ".concat(pseudo, " if filter configuration"));
};

import React from 'react';
import uuid from 'react-uuid';
import RadioButton from '@commonComponents/radio-button/RadioButton';
import { getDataAttributes } from '@commonComponents/gropped-column-table/gropedTableUtils';
import Toggle from '@commonComponents/toggle/Toggle';
import CheckboxComponent from '@commonComponents/checkbox/Checkbox';
export var GeneratePdfCModeContext = React.createContext(false);
export var VisibleClustersIdsContext = React.createContext([]);
export var GeneratePdfClusterIdsContext = React.createContext([]);
var RowControls = function (_a) {
    var clusterId = _a.clusterId, activeClusterId = _a.activeClusterId, onPdfGenerateSelect = _a.onPdfGenerateSelect, toggleStatus = _a.toggleStatus, isClustersManaging = _a.isClustersManaging;
    return (React.createElement(VisibleClustersIdsContext.Consumer, null, function (visibleClusterIds) { return (React.createElement(GeneratePdfCModeContext.Consumer, null, function (isPdfGeneratingMode) {
        return isClustersManaging ? (React.createElement(Toggle, { initialChecked: visibleClusterIds.includes(clusterId), className: "manage-cluster-toggle", value: clusterId, onChange: toggleStatus })) : isPdfGeneratingMode ? (React.createElement(GeneratePdfClusterIdsContext.Consumer, null, function (selectedIds) { return (React.createElement(CheckboxComponent, { onChange: onPdfGenerateSelect, checked: selectedIds.includes(clusterId) })); })) : (React.createElement(RadioButton, { dataAttributes: getDataAttributes(clusterId, null), checked: clusterId === activeClusterId, prefix: "groped-table__checkbox", pseudo: "", label: "", key: uuid(), onChange: function (value) { return value; } }));
    })); }));
};
export default RowControls;

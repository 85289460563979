var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import moment from 'moment';
import React from 'react';
import { jsPDF } from 'jspdf';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import * as htmlToImage from 'html-to-image';
import autoTable from 'jspdf-autotable';
import { store } from '@redux/store';
import { mountComponent } from '@commonUtils/pdfGeneration';
import { fetchCharts, dropChartsToDefault } from '@redux/dispatchers/charts';
import { CLUSTER_AVAILABILITY_THRESHOLD } from '@commonConstants/chartsConstants';
import { dropMKEAlertsToDefault, dropMKERecommendationsToDefault } from '@redux/dispatchers/telemetry';
import { isMOSCluster } from '../../../../commonTelemetryDashboardUtils';
import { STATES } from '@commonComponents/generate-pdf-button/generatePdfConstants';
import { getChartsConfigs as getMCCChartsConfigs } from '@components/telemetry-dashboard/charts/mccClustersCharts/mccClusterChartsUtils';
import { getChartsConfigs as getMCCCAvailabilityChartsConfigs } from '@components/telemetry-dashboard/charts/availabilityCharts/availabilityClusterChartsUtils';
import { getChartsConfigs as getMKEChartsConfigs } from '@components/telemetry-dashboard/charts/mkeClusterscharts/mkeClusterChartsUtils';
import { getAutoTableConfig, getParams, shouldGeneratePDf } from './pdfGenerationUtils';
import PdfMurkup from './PdfMurkup';
var DATE_FORMAT = 'DDD-YY';
var COMPRESSION_TYPE = 'FAST';
var addAlertsAndRecommendations = function (availabilityTable, alertsNode, recommendationsNode, pdf, id) {
    var shouldAddNewPage = !isNil(alertsNode) || !isNil(recommendationsNode);
    if (!isNil(availabilityTable)) {
        pdf.addPage();
        autoTable(pdf, __assign(__assign({ startY: 15, html: "#availability-table-".concat(id), 
            // @ts-ignore
            beforePageContent: function () {
                pdf.text("Availability Data - (".concat(id, ")"), 15, 10);
            }, didParseCell: function (data) {
                var isValueCell = data.cell.text[0].includes('%');
                var value = isValueCell ? parseFloat(data.cell.text[0].split(' ')[0]) : 0;
                if (isValueCell) {
                    data.cell.styles.textColor = value < CLUSTER_AVAILABILITY_THRESHOLD ? 'red' : 'green';
                }
            } }, getAutoTableConfig()), { columnStyles: {
                0: { fillColor: [215, 232, 252], cellWidth: 11 },
                1: { cellWidth: 16 },
                7: { cellWidth: 16 },
                8: { cellWidth: 16 },
                9: { cellWidth: 14 },
            } }));
    }
    if (shouldAddNewPage) {
        pdf.addPage();
    }
    if (!isNil(alertsNode)) {
        autoTable(pdf, __assign({ startY: 15, html: "#alerts-".concat(id), 
            // @ts-ignore
            beforePageContent: function () {
                pdf.text("Alerts - (".concat(id, ")"), 15, 10);
            } }, getAutoTableConfig()));
    }
    if (!isNil(recommendationsNode)) {
        autoTable(pdf, __assign({ 
            // @ts-ignore
            startY: pdf.autoTable.previous.finalY + 25, html: "#recommendations-".concat(id), 
            // @ts-ignore
            beforePageContent: function () {
                // @ts-ignore
                pdf.text("Recommendations - (".concat(id, ")"), 15, pdf.autoTable.previous.finalY + 19);
            } }, getAutoTableConfig()));
    }
};
var generatePdf = function (pagesIds) { return __awaiter(void 0, void 0, void 0, function () {
    var pdf, width, height;
    return __generator(this, function (_a) {
        pdf = new jsPDF('p', 'mm', 'a4', true);
        width = pdf.internal.pageSize.getWidth();
        height = pdf.internal.pageSize.getHeight();
        return [2 /*return*/, Promise.all(pagesIds.map(function (id) { return __awaiter(void 0, void 0, void 0, function () {
                var image, alerts, availabilityData, recommendations, imageData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            image = document.getElementById(id);
                            alerts = document.getElementById("alerts-".concat(id));
                            availabilityData = document.getElementById("availability-table-".concat(id));
                            recommendations = document.getElementById("recommendations-".concat(id));
                            return [4 /*yield*/, htmlToImage.toPng(image)];
                        case 1:
                            imageData = _a.sent();
                            return [2 /*return*/, {
                                    id: id,
                                    image: imageData,
                                    alertsNode: alerts,
                                    availabilityData: availabilityData,
                                    recommendationsNode: recommendations,
                                }];
                    }
                });
            }); })).then(function (clusters) { return __awaiter(void 0, void 0, void 0, function () {
                var generalInfoPage, customer;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, htmlToImage.toPng(document.getElementById('general_telemetry_customer_page'))];
                        case 1:
                            generalInfoPage = _b.sent();
                            pdf.addImage(generalInfoPage, 'PNG', 0, 0, width, height, undefined, COMPRESSION_TYPE);
                            pdf.addPage();
                            // set all other page to pdf
                            clusters.forEach(function (_a, index) {
                                var availabilityData = _a.availabilityData, image = _a.image, id = _a.id, alertsNode = _a.alertsNode, recommendationsNode = _a.recommendationsNode;
                                pdf.addImage(image, 'PNG', 0, 0, width, height, undefined, COMPRESSION_TYPE);
                                addAlertsAndRecommendations(availabilityData, alertsNode, recommendationsNode, pdf, id);
                                if (index + 1 < clusters.length) {
                                    pdf.addPage();
                                }
                            });
                            customer = store.getState().telemetry.customer;
                            pdf.save("".concat((_a = customer === null || customer === void 0 ? void 0 : customer.sfAccount) === null || _a === void 0 ? void 0 : _a.name, " ").concat(moment().format('Y MMM D'), ".pdf"));
                            return [2 /*return*/, Promise.resolve()];
                    }
                });
            }); })];
    });
}); };
export var generatePdfWrapper = function (generatePdfClusterIds, setPdfGeneratingMode) { return __awaiter(void 0, void 0, void 0, function () {
    var newDiv, pdfContainerNode, _a, calculatedDateRange, timezone, _b, mccOverview, mkeOverview, mccClusters, mkeClusters, isActiveToggleOn, mccClusterIds, mkeClusterIds, mkeClusterLicenseKeys, mosClusterIds, timerId;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (generatePdfClusterIds.length === 0) {
                    setPdfGeneratingMode(STATES.START_SELECTING);
                    return [2 /*return*/];
                }
                newDiv = document.createElement('div');
                newDiv.id = 'pdf-generation-root';
                document.getElementById('root').appendChild(newDiv);
                pdfContainerNode = document.getElementById('pdf-generation-root');
                _a = store.getState(), calculatedDateRange = _a.crossPageFilter.dateRange.calculatedDateRange, timezone = _a.userInfo.userPreferences.timezone, _b = _a.telemetry.customer, mccOverview = _b.mccOverview, mkeOverview = _b.mkeOverview;
                mccClusters = Object.values(get(mccOverview, 'clusters', {})).filter(function (_a) {
                    var clusterId = _a.clusterId;
                    // @ts-ignore
                    return generatePdfClusterIds.includes(clusterId);
                });
                mkeClusters = Object.values(get(mkeOverview, 'clusters', {})).filter(function (_a) {
                    var clusterId = _a.clusterId;
                    // @ts-ignore
                    return generatePdfClusterIds.includes(clusterId);
                });
                isActiveToggleOn = moment(calculatedDateRange.start.timeString).format(DATE_FORMAT) ===
                    moment(calculatedDateRange.end.timeString).format(DATE_FORMAT);
                mountComponent(React.createElement(PdfMurkup
                // @ts-ignore
                , { 
                    // @ts-ignore
                    mccClusters: mccClusters, 
                    // @ts-ignore
                    mkeClusters: mkeClusters, isActiveToggleOn: isActiveToggleOn, calculatedDateRange: calculatedDateRange }), pdfContainerNode);
                mccClusterIds = mccClusters.map(function (_a) {
                    var clusterId = _a.clusterId;
                    return clusterId;
                });
                mkeClusterIds = mkeClusters.map(function (_a) {
                    var clusterId = _a.clusterId;
                    return clusterId;
                });
                mkeClusterLicenseKeys = mkeClusters.map(function (_a) {
                    var license = _a.license;
                    return license;
                });
                mosClusterIds = mccClusters.filter(function (cluster) { return isMOSCluster(cluster); }).map(function (_a) {
                    var clusterId = _a.clusterId;
                    return clusterId;
                });
                if (!!isEmpty(mccClusterIds)) return [3 /*break*/, 2];
                return [4 /*yield*/, fetchCharts(getMCCChartsConfigs(getParams(timezone, mccClusterIds, [], calculatedDateRange, isActiveToggleOn)))];
            case 1:
                _c.sent();
                _c.label = 2;
            case 2:
                if (!(mosClusterIds.length > 0)) return [3 /*break*/, 4];
                return [4 /*yield*/, fetchCharts(getMCCCAvailabilityChartsConfigs(getParams(timezone, mccClusterIds, [], calculatedDateRange, isActiveToggleOn)))];
            case 3:
                _c.sent();
                _c.label = 4;
            case 4:
                if (!(!isEmpty(mkeClusterIds) && !isEmpty(mkeClusterLicenseKeys))) return [3 /*break*/, 6];
                return [4 /*yield*/, fetchCharts(getMKEChartsConfigs(getParams(timezone, mkeClusterIds, mkeClusterLicenseKeys, calculatedDateRange, isActiveToggleOn)))];
            case 5:
                _c.sent();
                _c.label = 6;
            case 6:
                timerId = setInterval(function () {
                    var charts = store.getState().charts;
                    if (shouldGeneratePDf(charts, mccClusterIds, mkeClusterIds, mosClusterIds)) {
                        clearInterval(timerId);
                        setTimeout(function () {
                            generatePdf(generatePdfClusterIds).then(function () {
                                pdfContainerNode.remove();
                                setPdfGeneratingMode(STATES.START_SELECTING);
                                dropChartsToDefault('mccCluster');
                                dropChartsToDefault('mccClusterCapacity');
                                dropChartsToDefault('mkeCluster');
                                dropChartsToDefault('mccClusterAvailabilityCharts');
                                dropMKEAlertsToDefault();
                                dropMKERecommendationsToDefault();
                            });
                        }, 5000);
                    }
                }, 500);
                return [2 /*return*/];
        }
    });
}); };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import without from 'lodash/without';
import * as chart from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import { setChartsCanvasLoaded } from '@redux/dispatchers/charts';
import Tooltip from '@commonComponents/tooltip/Tooltip';
import ChartsLoaderEmptyState from '../ChartsLoader';
import { getOptions, getFinalLeftLabel, isChartDataEmpty } from './LineUtils';
import { BORDER_COLOR, BACKGROUND_COLOR } from '../chartConstants';
import { StyledLine } from './StyledLine';
var plugins = [
    {
        beforeDraw: function (chartObject) {
            chartObject.ctx.fillStyle = 'white';
            chartObject.ctx.fillRect(0, 0, chartObject.width, chartObject.height);
            chartObject.ctx.fillRect(0, 0, chartObject.width, chartObject.height);
            chartObject.ctx.restore();
        },
    },
    {
        afterDraw: function (chartObject) {
            var _a, _b, _c, _d;
            if (((_a = chartObject === null || chartObject === void 0 ? void 0 : chartObject.tooltip) === null || _a === void 0 ? void 0 : _a.active) && ((_c = (_b = chartObject === null || chartObject === void 0 ? void 0 : chartObject.tooltip) === null || _b === void 0 ? void 0 : _b.active) === null || _c === void 0 ? void 0 : _c.length)) {
                var activePoint = (_d = chartObject === null || chartObject === void 0 ? void 0 : chartObject.tooltip) === null || _d === void 0 ? void 0 : _d.active[0];
                var ctx = chartObject.ctx;
                var x = activePoint.element.x;
                var topY = chartObject.scales.y.top;
                var bottomY = chartObject.scales.y.bottom;
                // draw vertical line
                ctx.save();
                ctx.beginPath();
                ctx.moveTo(x, topY);
                ctx.lineTo(x, bottomY);
                ctx.lineWidth = 0.5;
                ctx.strokeStyle = '#4aa0f2';
                ctx.stroke();
                ctx.restore();
            }
        },
    },
];
chart.Chart.register(chart.Filler);
chart.Chart.register(zoomPlugin);
chart.Chart.register(chart.CategoryScale, chart.LinearScale, chart.PointElement, chart.LineElement, chart.Title, chart.Tooltip, chart.Legend);
var LineChart = function (_a) {
    var title = _a.title, chartKeys = _a.chartKeys, _b = _a.leftLabel, leftLabel = _b === void 0 ? ' ' : _b, stepped = _a.stepped, message = _a.message, _c = _a.datasets, datasets = _c === void 0 ? [] : _c, _d = _a.datasetsTitles, datasetsTitles = _d === void 0 ? [''] : _d, chartOptions = _a.chartOptions, dynamicLabelFormatter = _a.dynamicLabelFormatter, loadingOut = _a.loadingOut;
    var _e = useState(false), isDerty = _e[0], setDerty = _e[1];
    var _f = useState(false), isLoading = _f[0], setLoading = _f[1];
    var _g = useState(datasets.map(function (item, index) { return index; })), activeDataSets = _g[0], setActiveDataSets = _g[1];
    var finalLeftLabel = getFinalLeftLabel(dynamicLabelFormatter, datasets, activeDataSets, leftLabel);
    var handleToggleCanvasLoaded = function (value) {
        setLoading(value);
        if (chartKeys && value) {
            setChartsCanvasLoaded({
                key: chartKeys.key,
                clusterId: chartKeys.clusterId,
                dashboard: chartKeys.dashboard,
                loaded: true,
            });
        }
    };
    var legendClick = function (_a) {
        var datasetIndex = _a.datasetIndex;
        return activeDataSets.includes(datasetIndex)
            ? setActiveDataSets(without(activeDataSets, datasetIndex, setLoading))
            : setActiveDataSets(__spreadArray(__spreadArray([], activeDataSets, true), [datasetIndex], false));
    };
    useEffect(function () {
        setActiveDataSets(datasets.map(function (item, index) { return index; }));
    }, [datasets]);
    useEffect(function () {
        if (loadingOut) {
            handleToggleCanvasLoaded(true);
            setDerty(true);
        }
        else if (isChartDataEmpty(datasets)) {
            handleToggleCanvasLoaded(false);
        }
    }, [loadingOut]);
    var isEmpty = isDerty && !isLoading && isChartDataEmpty(datasets);
    return (React.createElement(ChartsLoaderEmptyState, { loading: isLoading, isEmpty: isEmpty },
        React.createElement("div", { className: "chart" },
            React.createElement(StyledLine, null,
                React.createElement(Tooltip, { message: message, place: "top", className: "chart-title-tooltip" },
                    React.createElement("div", { className: "title" }, title)),
                React.createElement("div", { className: "left-side-label" },
                    React.createElement("span", { className: "label" }, finalLeftLabel)),
                React.createElement(Line, { id: "test-id", data: {
                        labels: datasets.length > 0 ? datasets[0].map(function (e) { return chartOptions.xFormatFunction(e.x); }) : [],
                        datasets: datasets.map(function (dataset, index) { return ({
                            label: datasetsTitles[index],
                            fill: true,
                            data: dataset.map(function (e) { return (activeDataSets.includes(index) ? e.y : null); }),
                            borderColor: BORDER_COLOR[index],
                            backgroundColor: BACKGROUND_COLOR[index],
                            tension: 0.1,
                            borderWidth: 1,
                            pointRadius: 0,
                            pointStyle: 'line',
                            stepped: stepped,
                        }); }),
                    }, 
                    // @ts-ignore
                    plugins: plugins, 
                    // @ts-ignore
                    options: getOptions(__assign(__assign({}, chartOptions), { isLegend: datasets.length > 1, legendClick: legendClick, setLoading: handleToggleCanvasLoaded, isChartDataEmpty: isChartDataEmpty(datasets), isLoadingOut: loadingOut })) })))));
};
export default LineChart;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import get from 'lodash/get';
import { DateUtils } from '@utils/date/DateUtils';
export var getGroupNameById = function (allPermissionGroups, activeGroupId) {
    return get(allPermissionGroups.find(function (group) { return group.id === activeGroupId; }), 'name');
};
export var getGroupIsEditableById = function (allPermissionGroups, activeGroupId) {
    return get(allPermissionGroups.find(function (group) { return group.id === activeGroupId; }), 'isEditable');
};
export var getGroupIsAssignableById = function (allPermissionGroups, activeGroupId) {
    return get(allPermissionGroups.find(function (group) { return group.id === activeGroupId; }), 'isAssignable');
};
export var normalizers = {
    name: function (value) {
        return value;
    },
    usersCount: function (value) {
        return value;
    },
    createdAt: function (value) {
        return value ? DateUtils.getDateStringWithDynamicTimezone(value) : '';
    },
};
export var getNormalizedData = function (data, pagination) {
    var currentPage = pagination.currentPage, limit = pagination.limit;
    if (!Array.isArray(data))
        return;
    if (data.length < 1)
        return;
    return data.map(function (element, index) { return (__assign({ sequentialNumber: index + 1 + (currentPage - 1) * limit }, element)); });
};
export var getCallApiParams = function (pagination, multiFilter, state, timezone) { return ({
    limit: pagination.limit,
    page: pagination.currentPage,
    search_term: multiFilter.filter.searchTerm,
    sortBy: state.sortBy,
    orderBy: state.orderBy,
    timezone: timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
}); };

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
var StyledLoader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 30px 0 40px;\n"], ["\n    margin: 30px 0 40px;\n"])));
var Loader = function (_a) {
    var message = _a.message;
    return React.createElement(StyledLoader, { className: "loader" }, message);
};
export default Loader;
var templateObject_1;

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import moment from 'moment';
import MonthCalendarDay from './MonthCalendarDay';
import { DateUtils } from '@utils/date/DateUtils';
var MonthCalendar = function (props) {
    var m = props.date.get('month');
    var y = props.date.get('year');
    var staringMomentDay = moment([y, m, 1]).get('day');
    var emptyDays = staringMomentDay ? staringMomentDay - 1 : 6;
    var numberOfDaysInMonth = moment(props.date).daysInMonth();
    var days = __spreadArray([], Array(emptyDays), true);
    for (var i = 0; i < numberOfDaysInMonth; i++) {
        days.push(i + 1);
    }
    var daysMap = days.map(function (day, idx) {
        return day ? (React.createElement(MonthCalendarDay, { key: idx, idx: idx + 1, date: moment([y, m, day]), formatedDate: DateUtils.format(moment([y, m, day])).timeString, selectedDateStart: props.selectedDateStart, selectedDateEnd: props.selectedDateEnd, hoveredDate: props.hoveredDate, updateState: props.updateState })) : (React.createElement("div", { key: idx, className: "date-picker__part__days__day" }));
    });
    return React.createElement("div", { className: "date-picker__part__days" }, daysMap);
};
export default MonthCalendar;

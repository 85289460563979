var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { InfoIcon, variants } from '@mirantis/mds-icon';
import { Button } from '@mirantis/mds-button';
var StyledPermissionGroupPopover = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n\n    .title {\n        display: flex;\n        font-weight: 600;\n\n        svg {\n            padding-right: 5px;\n        }\n    }\n\n    .bottom {\n        display: flex;\n        justify-content: end;\n    }\n\n    .no,\n    .yes {\n        margin: 5px 10px;\n    }\n\n    .group-name {\n        padding: 0 5px;\n    }\n"], ["\n    display: flex;\n    flex-direction: column;\n\n    .title {\n        display: flex;\n        font-weight: 600;\n\n        svg {\n            padding-right: 5px;\n        }\n    }\n\n    .bottom {\n        display: flex;\n        justify-content: end;\n    }\n\n    .no,\n    .yes {\n        margin: 5px 10px;\n    }\n\n    .group-name {\n        padding: 0 5px;\n    }\n"])));
var RemovePermissionGroupPopover = function (_a) {
    var onClose = _a.onClose, onOk = _a.onOk, name = _a.name;
    return (React.createElement(StyledPermissionGroupPopover, null,
        React.createElement("div", { className: "title" },
            React.createElement(InfoIcon, { variant: variants.WARNING }),
            "Are you sure to remove ",
            React.createElement("b", { className: "group-name" }, name),
            " Permission Group?"),
        React.createElement("div", { className: "bottom" },
            React.createElement(Button, { className: "no", label: "no", actionType: "secondary", onClick: onClose }),
            React.createElement(Button, { className: "yes", label: "yes", actionType: "primary", onClick: onOk }))));
};
export default RemovePermissionGroupPopover;
var templateObject_1;

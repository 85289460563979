var _a;
import React, { memo, useEffect, useState } from 'react';
import Tabs from '@commonComponents/tabs/Tabs';
import { getTrialStatisticActiveTabFromUrl, setTrialStatisticTabToUrl } from './trialstatisticUtils';
import TrialOccupiedSlotsContainer from './trialOccupiedSlotsTab/TrialOccupiedSlotsContainer';
import TrialSignUpsContainer from './trialSignUpsTab/TrialSignUpsContainer';
import TrialCostsContainer from './trialCostsTab/TrialCostsContainer';
var TRIAL_STATISTIC_TABS_TITLES = {
    COSTS: 'Trial Costs',
    OCCUPIED_SLOTS: 'Occupied Slots',
    SIGN_UPS: 'Sign Ups',
};
var finalTabs = Object.values(TRIAL_STATISTIC_TABS_TITLES);
var TABS_COMPONENTS = (_a = {},
    // eslint-disable-next-line react/display-name
    _a[TRIAL_STATISTIC_TABS_TITLES.COSTS] = function () { return React.createElement(TrialCostsContainer, null); },
    // eslint-disable-next-line react/display-name
    _a[TRIAL_STATISTIC_TABS_TITLES.OCCUPIED_SLOTS] = function () { return React.createElement(TrialOccupiedSlotsContainer, null); },
    // eslint-disable-next-line react/display-name
    _a[TRIAL_STATISTIC_TABS_TITLES.SIGN_UPS] = function () { return React.createElement(TrialSignUpsContainer, null); },
    _a);
var TrialStatistic = function (_a) {
    var activeTabFromUrl = _a.activeTabFromUrl;
    var _b = useState(null), activeTab = _b[0], setActiveTab = _b[1];
    useEffect(function () {
        if (activeTab) {
            setTrialStatisticTabToUrl(activeTab);
        }
    }, [activeTab]);
    useEffect(function () {
        var activeTabFromUrl = getTrialStatisticActiveTabFromUrl() || finalTabs[0];
        setActiveTab(activeTabFromUrl);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Tabs, { tabs: finalTabs, active: activeTab, activeTabFromUrl: activeTabFromUrl, handleChange: function (newTab) { return setActiveTab(newTab); } }),
        activeTab && TABS_COMPONENTS[activeTab]()));
};
export default memo(TrialStatistic);

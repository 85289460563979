import * as Actions from '@redux/actions/crossPageFilter';
export var initCrossPageFilterState = function (payload) { return ({
    type: Actions.SyncActions.INIT_CROSS_PAGE_FILTER_STATE,
    payload: payload,
}); };
export var changeCrossPageFilterOption = function (payload) { return ({
    type: Actions.SyncActions.CHANGE_CROSS_PAGE_FILTER_OPTION,
    payload: payload,
}); };
export var resetCrossPageFilterState = function (payload) { return ({
    type: Actions.SyncActions.RESET_CROSS_PAGE_FILTER_STATE,
    payload: payload,
}); };

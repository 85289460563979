var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import without from 'lodash/without';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { SOURCE_TYPES } from '@commonConstants/sourceTypes';
import { toggleTelemetryClusterStatus } from '@redux/dispatchers/telemetry';
import MkeTelemetryTableWrapper from '../mke-telemetry-customer/MkeTelemetryTableWrapper';
import MccTelemetryTableWrapper from '../mcc-telemetry-customer/MccTelemetryTableWrapper';
import ToggleAllClustersButton from '../../../../ToggleAllClustersButton';
import { VisibleClustersIdsContext } from '@commonComponents/gropped-column-table/RowControls';
import { CLUSTER_SOURCE_TYPES } from '@components/telemetry-dashboard/telemetryCustomerConstants';
var MKE = CLUSTER_SOURCE_TYPES.MKE;
var TelemetryManageClusters = function (_a) {
    var _b = _a.manageClusterListPage, manageClusterListPage = _b === void 0 ? '' : _b, setActiveClusterId = _a.setActiveClusterId;
    var clusters = useSelector(function (store) {
        return get(store, "telemetry.customer.".concat(manageClusterListPage.toLowerCase(), "Overview.clusters"), {});
    });
    var clusterList = Object.values(clusters);
    var allClustersIds = clusterList.map(function (_a) {
        var clusterId = _a.clusterId;
        return clusterId;
    });
    var _c = useState([]), visibleClustersIds = _c[0], setVisibleClustersIds = _c[1];
    var visibleClustersIdsRef = useRef([]);
    useEffect(function () {
        if (!isEmpty(clusterList)) {
            var newHVisibleClustersIds = clusterList
                .filter(function (_a) {
                var isHidden = _a.isHidden;
                return !isHidden;
            })
                .map(function (_a) {
                var clusterId = _a.clusterId;
                return clusterId;
            });
            visibleClustersIdsRef.current = newHVisibleClustersIds;
            setVisibleClustersIds(newHVisibleClustersIds);
        }
    }, [clusters]);
    var toggleStatus = function (clusterId, checked) {
        var newVisibleClustersIds = checked
            ? __spreadArray(__spreadArray([], visibleClustersIdsRef.current, true), [clusterId], false) : without(visibleClustersIdsRef.current, clusterId);
        visibleClustersIdsRef.current = newVisibleClustersIds;
        setVisibleClustersIds(newVisibleClustersIds);
        toggleTelemetryClusterStatus({
            hidden: !checked,
            clusterIds: [clusterId],
            type: SOURCE_TYPES.MKE,
        });
    };
    var toggleAllClustersStatus = function () {
        var nextHidden = !isEmpty(visibleClustersIds);
        var newVisibleClustersIds = nextHidden ? [] : allClustersIds;
        visibleClustersIdsRef.current = newVisibleClustersIds;
        setVisibleClustersIds(newVisibleClustersIds);
        toggleTelemetryClusterStatus({
            hidden: nextHidden,
            clusterIds: allClustersIds,
            type: SOURCE_TYPES.MKE,
        });
    };
    var renderTable = function () {
        var ClustersTable = MKE === manageClusterListPage ? MkeTelemetryTableWrapper : MccTelemetryTableWrapper;
        return (React.createElement(VisibleClustersIdsContext.Provider, { value: visibleClustersIds },
            React.createElement(ClustersTable, { isClustersManaging: true, activeClusterId: null, toggleStatus: toggleStatus, setActiveClusterId: setActiveClusterId })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ToggleAllClustersButton, { onChange: toggleAllClustersStatus, visibleClustersIds: visibleClustersIds, allClustersIds: allClustersIds }),
        renderTable()));
};
export default TelemetryManageClusters;

import { CHART_DASHBOARDS } from './availabilutyClusterChartsConstants';
export var getChartsConfigs = function (params) {
    return {
        dashboard: CHART_DASHBOARDS.AVAILABILITY_CHARTS,
        charts: [
            {
                key: 'availabilityCharts',
                end: params.end,
                start: params.start,
                timezone: params.timezone,
                granularity: params.granularity,
                clusterIds: params.activeClusterId || params.clusterIds,
            },
        ],
    };
};

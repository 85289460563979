import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import uudi from 'react-uuid';
import { StyledSlider } from './StyledSlider';
var Slider = function (_a) {
    var defaultActive = _a.defaultActive, items = _a.items, onChange = _a.onChange;
    var itemsRefs = useRef({ current: null });
    var _b = useState({}), activeStyles = _b[0], setActiveStyles = _b[1];
    var _c = useState(defaultActive), active = _c[0], setActive = _c[1];
    useEffect(function () {
        setActive(defaultActive);
    }, [defaultActive]);
    useEffect(function () {
        if (!itemsRefs.current[active]) {
            return;
        }
        var node = itemsRefs.current[active];
        var _a = node.getBoundingClientRect(), height = _a.height, width = _a.width;
        setActiveStyles({ left: node.offsetLeft, width: width, height: height });
        onChange(active);
    }, [active]);
    return (React.createElement(StyledSlider, { className: "slider" },
        React.createElement("span", { style: activeStyles, className: classNames('active') }),
        items.map(function (_a, index) {
            var item = _a.item, id = _a.id;
            return (React.createElement("div", { key: id, className: classNames({ item_container: true, checked: active === id }), onClick: function () { return setActive(id); }, ref: function (element) { return (itemsRefs.current[id] = element); } },
                React.createElement("span", { className: "slider_item_text" }, item),
                index < items.length - 1 && React.createElement("span", { className: "separator", key: uudi() })));
        })));
};
export default Slider;

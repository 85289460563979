import React, { useState, memo } from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import classNames from 'classnames';
import { useDebouncedEffect } from '@app/hooks';
import { DateUtils } from '@utils/date/DateUtils';
import { DASHBOARDS_SCHEMA, PAGINATION_NAMES } from '@configs/DASHBOARD_PSEUDOS';
import Loader from '@commonComponents/Loader';
import Table from '@commonComponents/table/Table';
import PaginationContainer from '@commonComponents/pagination/PaginationContainer';
import TableLoadingStates from '@commonComponents/table-loading-states/TableLoadingStates';
import { getBootstrapInstallationsParams, getTableRowLinkUrl } from './instalationReportsUtils';
import { COLUMNS, LOADING_TEXT, NO_INSTALLATION_FOUND_MESSAGE } from './installationsConstants';
var PSEUDO_CC = DASHBOARDS_SCHEMA.REPORTS.PAGES.INSTALLATION_REPORT.PSEUDO_CC;
var InstallationsTable = function (props) {
    var _a, _b;
    var mode = props.mode, filter = props.filter, timezone = props.timezone, pagination = props.pagination, dropLastSyncDate = props.dropLastSyncDate, changeCurrentPage = props.changeCurrentPage, bootstrapInstallations = props.bootstrapInstallations, getBootstrapInstallations = props.getBootstrapInstallations;
    var _c = useState({
        sortBy: 'date',
        orderBy: 'asc',
    }), state = _c[0], setState = _c[1];
    var callAPI = function () {
        var params = getBootstrapInstallationsParams(props, state);
        getBootstrapInstallations(params);
    };
    useDebouncedEffect(function () {
        if (filter.dashboardName !== PSEUDO_CC) {
            return;
        }
        callAPI();
    }, [
        filter.dateRange.calculatedDateRange,
        (_b = (_a = filter.granularity) === null || _a === void 0 ? void 0 : _a.selected) === null || _b === void 0 ? void 0 : _b.value,
        pagination.currentPage,
        pagination.limit,
        timezone,
        state,
        mode,
    ]);
    var data = {
        columns: COLUMNS,
        rows: bootstrapInstallations.data,
    };
    var onPaginationChange = function (page) {
        changeCurrentPage({
            currentPage: page,
            paginationName: PAGINATION_NAMES.BOOTSTRAP_INSTALLATIONS,
        });
    };
    var onLimitChange = function (limit) {
        changeCurrentPage({
            limit: limit,
            currentPage: 1,
            paginationName: PAGINATION_NAMES.BOOTSTRAP_INSTALLATIONS,
        });
    };
    var normalizers = {
        date: function (date) {
            return (React.createElement(Link, { to: getTableRowLinkUrl(date, get(filter, 'granularity.selected.pseudo')), onClick: dropLastSyncDate }, DateUtils.getDateLocalStringAutoTimezoneHandler(date)));
        },
    };
    var renderTable = function () { return (React.createElement("div", { className: classNames({
            'bootstrap-installations-table': true,
            loading: bootstrapInstallations.loading,
        }) },
        React.createElement(TableLoadingStates, { loading: bootstrapInstallations.loading, count: pagination.limit, data: (bootstrapInstallations === null || bootstrapInstallations === void 0 ? void 0 : bootstrapInstallations.data) || [], empty: { description: NO_INSTALLATION_FOUND_MESSAGE }, skeleton: {
                paddingBottom: 20,
                paddingTop: 20,
            } },
            React.createElement(React.Fragment, null,
                React.createElement(Table, { sortable: true, data: data, orderBy: state.orderBy, sortBy: state.sortBy, setSortableState: function (s) { return setState(s); }, normalizers: normalizers, pagination: pagination, columnControl: true }),
                React.createElement(PaginationContainer, { withLimits: true, onLimitChange: onLimitChange, pagination: pagination, onChange: onPaginationChange }))))); };
    return !bootstrapInstallations.data ? React.createElement(Loader, { message: LOADING_TEXT }) : renderTable();
};
export default memo(InstallationsTable);

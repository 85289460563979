import React, { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { getBootstrapStats } from '@api/bootstrap-api/bootstrapApi';
import GreySection from '@commonComponents/GreySection';
import Tooltip from '@commonComponents/tooltip/Tooltip';
import { usePrevious } from '@app/hooks';
import StyledOverallStatistic from '@app/common/react/styled/StyledOverallStatistic';
import { getBootstrapStatisticsParams } from '@components/bootstrap-overall-statistic/OverallStatisticUtils';
import withDisablingWhileFetchingHOC from '@HOC/withDisablingWhileFetchingHOC/withDisablingWhileFetchingHOC';
var data = [
    {
        name: 'Customers',
        pseudo: 'customerCount',
        tooltip: 'Showing only customers that have source ‘bootstrap’',
    },
    {
        name: 'Instances',
        pseudo: 'instanceCount',
        tooltip: 'Showing only instances that have source ‘bootstrap’',
    },
    {
        name: 'Attempts',
        pseudo: 'attemptCount',
        tooltip: 'All events with stage ’bootstrap-create-start’ for selected date range',
    },
    {
        name: 'Successes',
        pseudo: 'successCount',
        tooltip: 'All events with ’bootstrap-create-end’ stage and status ’ok’ for selected date range',
    },
    {
        name: 'Success Ratio',
        pseudo: 'successRatio',
        tooltip: 'Ratio between success and attempts',
    },
];
var Item = function (_a) {
    var value = _a.value, name = _a.name, tooltip = _a.tooltip;
    return (React.createElement("div", { className: "overall-statistic__item" },
        React.createElement(Tooltip, { disable: false, place: "left", message: tooltip },
            React.createElement("div", { className: "overall-statistic__item__value" }, name === data[4].name ? "".concat(Math.round(value * 10000) / 100, "%") : value)),
        React.createElement("div", { className: "overall-statistic__item__name" }, name)));
};
var OverallStatistic = function (_a) {
    var mode = _a.mode, multiFilter = _a.multiFilter, crossPageFilter = _a.crossPageFilter;
    var _b = useState({
        customerCount: '-',
        instanceCount: '-',
        attemptCount: '-',
        successCount: '-',
        successRatio: 0,
    }), state = _b[0], setState = _b[1];
    var items = data.map(function (item) { return (React.createElement(Item, { key: item.pseudo, value: state[item.pseudo], name: item.name, tooltip: item.tooltip })); });
    var params = getBootstrapStatisticsParams();
    var prevParams = usePrevious(params);
    var callApi = function () {
        getBootstrapStats(params).then(function (res) { return setState(res); });
    };
    useEffect(function () {
        if (!isEqual(prevParams, params)) {
            callApi();
        }
    }, [mode, multiFilter.filter, crossPageFilter.dateRange.calculatedDateRange]);
    return (React.createElement(GreySection, null,
        React.createElement(Tooltip, { disable: false, place: "top", message: "Customers that have only \u2019runtime-periodic\u2019 stage events are ignored" },
            React.createElement("h2", { style: { width: '230px' } }, "Overall Statistics")),
        React.createElement(StyledOverallStatistic, { className: "overall-statistic" }, items)));
};
export default withDisablingWhileFetchingHOC(OverallStatistic, { spinner: true });

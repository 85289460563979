import get from 'lodash/get';
import queryString from 'query-string';
import { DateUtils } from '@utils/date/DateUtils';
import Redirect from '@commonUtils/urlUtils/Redirect';
import { URL_QUERIES } from '@commonConstants/urlConstants';
var _a = URL_QUERIES.COMMON, GRANULARITY = _a.GRANULARITY, DATE_RANGE = _a.DATE_RANGE;
export var isGranularityFilterEmpty = function (granularity) {
    return !get(granularity, 'selected.value');
};
export var getFilterDateChangeConfig = function (key, selected, calculatedDateRange, isGranularityEnabled) { return ({
    key: key,
    payload: {
        selected: selected,
        calculatedDateRange: calculatedDateRange,
        isGranularityEnabled: isGranularityEnabled,
    },
}); };
export var getFormattedCalculatedDateRange = function (start, end) { return ({
    start: DateUtils.formatCustomDateRange(start, 'start'),
    end: DateUtils.formatCustomDateRange(end, 'end'),
}); };
export var getCrossPageFilterPreferencesFromUrl = function () {
    var search = window.location.search;
    var dateRange = {};
    var granularity = {};
    var _a = get(queryString.parse(search), GRANULARITY, '').split(','), value = _a[0], pseudo = _a[1];
    var _b = get(queryString.parse(search), DATE_RANGE, '').split(','), dateRangeSelected = _b[0], start = _b[1], end = _b[2];
    dateRange = {
        selected: dateRangeSelected,
        calculatedDateRange: getFormattedCalculatedDateRange(start, end),
    };
    granularity = {
        selected: {
            value: value,
            pseudo: pseudo,
        },
    };
    return {
        dateRange: dateRange,
        granularity: granularity,
    };
};
export var handleSetInitialFiltersDates = function (_a, isGranularity, changeCrossPageFilterOption) {
    var dateRange = _a.dateRange, granularity = _a.granularity;
    if (dateRange.selected) {
        changeCrossPageFilterOption(getFilterDateChangeConfig(DATE_RANGE, dateRange.selected, dateRange.calculatedDateRange, isGranularity));
    }
    if (get(granularity, 'selected.value')) {
        changeCrossPageFilterOption(getFilterDateChangeConfig(GRANULARITY, granularity.selected));
    }
};
export var handleSetFiltersDatesToUrl = function (crossPageFilter, isGranularity) {
    var dateRange = crossPageFilter.dateRange, granularity = crossPageFilter.granularity;
    if (dateRange.selected) {
        var options = [
            dateRange.selected,
            dateRange.calculatedDateRange.start.timeString,
            dateRange.calculatedDateRange.end.timeString,
        ];
        Redirect.toCurrentPageWithQueryParams(DATE_RANGE, options, '');
    }
    if (isGranularity && !isGranularityFilterEmpty(granularity)) {
        var options = [granularity.selected.value, granularity.selected.pseudo];
        Redirect.toCurrentPageWithQueryParams(GRANULARITY, options, '');
    }
};

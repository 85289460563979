import * as Actions from '@redux/actions/newSignUps';
var r = {};
r[Actions.AsyncActions.GET_NEW_SIGN_UPS] = function (draft) {
    draft.loading = true;
};
r[Actions.SyncActions.SET_NEW_SIGN_UPS] = function (draft, payload) {
    draft.data = payload.data;
    draft.loading = false;
};
export { r as resolvers };

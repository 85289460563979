export var BORDER_COLOR = [
    'rgba(74, 160, 242)',
    'rgb(242, 152, 74)',
    'rgb(114, 213, 64)',
    'rgb(104,64,213)',
    'rgba(236,53,13)',
    'rgb(72,238,250)',
    'rgba(219,131,17)',
    'rgb(174,131,78)',
    'rgb(19,77,222)',
    'rgba(74, 160, 242)',
    'rgb(242, 152, 74)',
    'rgb(114, 213, 64)',
    'rgb(104,64,213)',
    'rgba(236,53,13)',
    'rgb(72,238,250)',
    'rgba(219,131,17)',
    'rgb(174,131,78)',
    'rgb(19,77,222)',
];
export var BACKGROUND_COLOR = [
    'rgba(74, 160, 242, 0.4)',
    'rgb(242, 152, 74, 0.4)',
    'rgb(114, 213, 64, 0.4)',
    'rgb(104,64,213, 0.4)',
    'rgba(236,53,135,0.4)',
    'rgb(72,238,250, 0.4)',
    'rgba(219,131,172,0.4)',
    'rgb(174,131,78, 0.4)',
    'rgb(19,77,222, 0.4)',
    'rgba(74, 160, 242, 0.4)',
    'rgb(242, 152, 74, 0.4)',
    'rgb(114, 213, 64, 0.4)',
    'rgb(104,64,213, 0.4)',
    'rgba(236,53,135,0.4)',
    'rgb(72,238,250, 0.4)',
    'rgba(219,131,172,0.4)',
    'rgb(174,131,78, 0.4)',
    'rgb(19,77,222, 0.4)',
];

import { GROPED_TABLE_DOME_ATTRIBUTES, CLASS_NAMES } from './GropedTableConstants';
import orderBy from 'lodash/orderBy';
var DATA_CLICKABLE_CELL_ID = GROPED_TABLE_DOME_ATTRIBUTES.DATA_CLICKABLE_CELL_ID, DATA_CLICKABLE_CLUSTER_ID = GROPED_TABLE_DOME_ATTRIBUTES.DATA_CLICKABLE_CLUSTER_ID;
export var getDataAttributes = function (clusterId, dataClickableCellId) {
    var _a;
    return (_a = {},
        _a[DATA_CLICKABLE_CELL_ID] = dataClickableCellId,
        _a[DATA_CLICKABLE_CLUSTER_ID] = clusterId,
        _a);
};
export var getClassName = function (cluster, isClustersManaging, activeClusterId) {
    if (isClustersManaging || !activeClusterId)
        return '';
    return cluster === activeClusterId ? CLASS_NAMES.ACTIVE : CLASS_NAMES.DISABLED;
};
export var tableOnClick = function (event, isClustersManaging, activeClusterId, setActiveClusterId, cellOnCLick) {
    if (isClustersManaging) {
        return;
    }
    var clickableData = event.target.getAttribute(DATA_CLICKABLE_CELL_ID);
    var nextActiveClusterId = event.target.getAttribute(DATA_CLICKABLE_CLUSTER_ID);
    if (!clickableData && nextActiveClusterId === activeClusterId) {
        setActiveClusterId(null);
        cellOnCLick(null);
    }
    else {
        if (nextActiveClusterId) {
            setActiveClusterId(nextActiveClusterId);
        }
        if (clickableData) {
            cellOnCLick(clickableData);
        }
    }
    event.preventDefault();
};
export var getSortedDataBySwitcher = function (data) {
    var res = orderBy(data, ['isHidden'], ['asc']);
    return res;
};

import classNames from 'classnames';
import React from 'react';
import { ExpandIcon, CollapseIcon, variants } from '@mirantis/mds-icon';
import ColumnController from '@commonComponents/table/ColumnController';
import { ARROW_ICON_SIZE, ASC_TYPE, DESC_TYPE } from '@commonConstants/commonConstants';
import { StyledArrowsWrapper } from '@commonComponents/table/styled';
import { getEditableTableDataWithSequenceNumbering } from '@commonUtils/tableUtils';
var renderArrows = function (isAsc, idDesc) { return (React.createElement(StyledArrowsWrapper, null,
    React.createElement(CollapseIcon, { size: ARROW_ICON_SIZE, variant: isAsc ? variants.INFO : variants.NORMAL }),
    React.createElement(ExpandIcon, { size: ARROW_ICON_SIZE, variant: idDesc ? variants.INFO : variants.NORMAL }))); };
//Table with editable inputs (rows child element is required)
var EditableTable = function (props) {
    var _a = getEditableTableDataWithSequenceNumbering({
        columns: props.columns,
        rows: props.children,
    }), finalColumns = _a.columns, finalRows = _a.rows;
    var isSomeEditableColumnVisible = finalColumns.some(function (_a) {
        var isEditable = _a.isEditable, key = _a.key;
        return isEditable && props.visibleColumns.includes(key);
    });
    var renderTableHead = function () {
        var tableHeadings = finalColumns.map(function (column, idx) {
            if (!column.isStatic && !props.visibleColumns.includes(column.key)) {
                return null;
            }
            var getOrderBy = function () {
                if (props.sortBy === column.sortByKey) {
                    return props.orderBy === ASC_TYPE ? DESC_TYPE : ASC_TYPE;
                }
                return ASC_TYPE;
            };
            var handleClick = function () {
                if (!column.sortByKey) {
                    return;
                }
                var params = {
                    sortBy: column.sortByKey,
                    orderBy: getOrderBy(),
                };
                props.setSortableState(params);
            };
            var isAsc = column.sortByKey === props.sortBy && props.orderBy === ASC_TYPE;
            var isDesc = column.sortByKey === props.sortBy && props.orderBy === DESC_TYPE;
            var className = classNames({
                table__th: true,
                sortable: column.sortByKey,
                active: column.key === props.sortBy,
                asc: isAsc,
                desc: isDesc,
            }, column.key);
            return (React.createElement("th", { key: column.key, className: className, onClick: handleClick, colSpan: idx + 1 === props.columns.length ? 2 : 1 },
                column.name,
                column.sortByKey && renderArrows(isAsc, isDesc)));
        });
        if (isSomeEditableColumnVisible) {
            tableHeadings.push(React.createElement("th", { key: "plug", className: "plug" }));
        }
        return (React.createElement("thead", { className: "table__thead" },
            React.createElement("tr", { className: "table__tr" }, tableHeadings)));
    };
    return (React.createElement(React.Fragment, null,
        props.title ? React.createElement("h2", { className: "table__title" }, props.title) : null,
        props.columnControl ? (React.createElement(ColumnController, { columns: finalColumns, visibleColumnsKeys: props.visibleColumns, setVisibleColumns: props.setVisibleColumns })) : null,
        React.createElement("div", { className: classNames({ overflow: props.isRequireOverflow }) },
            React.createElement("table", { className: "table__editable" },
                renderTableHead(),
                React.createElement("tbody", { className: "table__tbody" }, finalRows)))));
};
export default EditableTable;

import { createStore, applyMiddleware } from 'redux';
import forEach from 'lodash/forEach';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ENVIRONMENTS } from '@commonConstants/environments';
import * as sagas from '@redux/sagas';
import { rootReducer } from '@redux/reducers/rootReducer';
var sagaMiddleware = createSagaMiddleware();
var middlewareApplier = process.env.ENVIRONMENT === ENVIRONMENTS.PROD
    ? applyMiddleware(sagaMiddleware)
    : composeWithDevTools(applyMiddleware(sagaMiddleware));
export var store = createStore(rootReducer, middlewareApplier);
forEach(sagas, function (saga) { return sagaMiddleware.run(saga); });

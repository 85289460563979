import { store } from '@redux/store';
import * as actionCreators from '@redux/action-creators/signIn';
export var signInStarted = function () {
    var action = actionCreators.signInStarted();
    store.dispatch(action);
};
export var changeSignInStep = function () {
    var action = actionCreators.changeSignInStep();
    store.dispatch(action);
};
export var redirectingStarted = function () {
    var action = actionCreators.redirectingStarted();
    store.dispatch(action);
};
export var resetSignIn = function () {
    var action = actionCreators.resetSignIn();
    store.dispatch(action);
};

import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
var DASHBOARDS = DASHBOARDS_SCHEMA.DASHBOARDS, SUPPORT = DASHBOARDS_SCHEMA.SUPPORT, MKE_DASHBOARDS = DASHBOARDS_SCHEMA.MKE_DASHBOARDS, ACCOUNT_MANAGEMENT = DASHBOARDS_SCHEMA.ACCOUNT_MANAGEMENT, REPORTS = DASHBOARDS_SCHEMA.REPORTS, HOME = DASHBOARDS_SCHEMA.HOME;
export var routes = {
    MAIN: {
        path: "/".concat(HOME.PSEUDO_DS),
    },
    SIGN_IN: {
        path: '/sign_in',
    },
    FORBIDDEN_ACCESS: {
        path: '/forbidden-access',
    },
    NOT_FOUND: {
        path: '/',
    },
};
export var navBarRoutes = {
    HOME: {
        path: routes.MAIN.path,
    },
    CONTAINER_CLOUD_USAGE: {
        path: "".concat(routes.MAIN.path, "/").concat(DASHBOARDS.PSEUDO_DS, "/").concat(DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE.PSEUDO_DS),
    },
    OPENSTACK_CLUSTERS_USAGE: {
        path: "".concat(routes.MAIN.path, "/").concat(DASHBOARDS.PSEUDO_DS, "/").concat(DASHBOARDS.PAGES.OPENSTACK_CLUSTERS_USAGE.PSEUDO_DS),
    },
    OPENSTACK_OVERVIEW: {
        path: "".concat(routes.MAIN.path, "/").concat(DASHBOARDS.PSEUDO_DS, "/").concat(DASHBOARDS.PAGES.OPENSTACK_USAGE.PSEUDO_DS),
    },
    MANAGE_USERS: {
        path: "".concat(routes.MAIN.path, "/").concat(ACCOUNT_MANAGEMENT.PSEUDO_DS, "/").concat(ACCOUNT_MANAGEMENT.PAGES.MANAGE_USERS.PSEUDO_DS),
    },
    MANAGE_CUSTOMERS: {
        path: "".concat(routes.MAIN.path, "/").concat(ACCOUNT_MANAGEMENT.PSEUDO_DS, "/").concat(ACCOUNT_MANAGEMENT.PAGES.MANAGE_CUSTOMERS.PSEUDO_DS),
    },
    PERMISSION_GROUPS: {
        path: "".concat(routes.MAIN.path, "/").concat(ACCOUNT_MANAGEMENT.PSEUDO_DS, "/").concat(ACCOUNT_MANAGEMENT.PAGES.PERMISSION_GROUPS.PSEUDO_DS),
    },
    BOOTSTRAP: {
        path: "".concat(routes.MAIN.path, "/").concat(DASHBOARDS.PSEUDO_DS, "/").concat(DASHBOARDS.PAGES.BOOTSTRAP.PSEUDO_DS),
    },
    INSTALLATION_REPORT: {
        path: "".concat(routes.MAIN.path, "/").concat(REPORTS.PSEUDO_DS, "/").concat(REPORTS.PAGES.INSTALLATION_REPORT.PSEUDO_DS),
    },
    NEW_SIGN_UPS_REPORT: {
        path: "".concat(routes.MAIN.path, "/").concat(REPORTS.PSEUDO_DS, "/").concat(REPORTS.PAGES.NEW_SIGN_UPS_REPORT.PSEUDO_DS),
    },
    ACTIVE_INSTANCES: {
        path: "".concat(routes.MAIN.path, "/").concat(REPORTS.PSEUDO_DS, "/").concat(REPORTS.PAGES.ACTIVE_INSTANCES.PSEUDO_DS),
    },
    TRIAL_REPORT: {
        path: "".concat(routes.MAIN.path, "/").concat(REPORTS.PSEUDO_DS, "/").concat(REPORTS.PAGES.TRIAL_REPORT.PSEUDO_DS),
    },
    COSTS: {
        path: "".concat(routes.MAIN.path, "/").concat(REPORTS.PSEUDO_DS, "/").concat(REPORTS.PAGES.TRIAL_STATISTIC.PSEUDO_DS),
    },
    ALERTS: {
        path: "".concat(routes.MAIN.path, "/").concat(SUPPORT.PSEUDO_DS, "/").concat(SUPPORT.PAGES.ALERTS.PSEUDO_DS),
    },
    SEARCH_CLUSTERS: {
        path: "".concat(routes.MAIN.path, "/").concat(SUPPORT.PSEUDO_DS, "/").concat(SUPPORT.PAGES.SEARCH_CLUSTERS.PSEUDO_DS),
    },
    CLUSTER_HEALTH: {
        path: "".concat(routes.MAIN.path, "/").concat(SUPPORT.PSEUDO_DS, "/").concat(SUPPORT.PAGES.CLUSTER_HEALTH.PSEUDO_DS),
    },
    TELEMETRY_DASHBOARD: {
        path: "".concat(routes.MAIN.path, "/").concat(MKE_DASHBOARDS.PSEUDO_DS, "/").concat(MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.PSEUDO_DS),
    },
};

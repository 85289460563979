import React from 'react';
import { Button } from '@mirantis/mds-button';
import AddNewUser from './AddNewUser';
import ManageUsersFilter from './ManageUsersFilter';
import ManageAccountTable from './table-manage-users-table/ManageUsersTable';
export var ManageUsers = function () {
    var _a = React.useState(false), showModal = _a[0], setShowModal = _a[1];
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", null, "Users"),
        React.createElement(ManageUsersFilter, null),
        React.createElement(ManageAccountTable, null),
        React.createElement(Button, { label: "Add new user", onClick: function () { return setShowModal(true); } }),
        showModal && React.createElement(AddNewUser, { dismiss: function () { return setShowModal(false); } })));
};
export default ManageUsers;

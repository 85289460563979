import * as React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import TelemetryAlertsTable from '@components/telemetry-dashboard/telemetryMccMke/customer/telemetry-alerts-table/TelemetryAlertsTable';
import { getClusterLicense } from '@components/telemetry-dashboard/commonTelemetryDashboardUtils';
import { StyledPage } from './style';
var Alerts = function (_a) {
    var activeClusterId = _a.activeClusterId;
    var clusters = useSelector(function (store) { return get(store, 'telemetry.customer.mkeOverview.clusters', {}); });
    var sfAccount = useSelector(function (store) { return get(store, 'telemetry.customer.sfAccount'); });
    if (!activeClusterId || !sfAccount) {
        return React.createElement("div", null);
    }
    var clusterLicense = getClusterLicense(clusters, activeClusterId);
    var alertsParams = {
        license: clusterLicense,
        cluster_id: activeClusterId,
        alert_types: 'Health,Utilization',
    };
    var alertsFilter = { type: 'All', status: 'All' };
    return (React.createElement(StyledPage, null,
        React.createElement(TelemetryAlertsTable, { params: alertsParams, filter: alertsFilter })));
};
export default Alerts;

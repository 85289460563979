export var NO_ALERTS_FOUND = 'No Alerts found';
export var TO_ALERTS_TABLE_HASH = { VALUE: 'toAlertsTable', HASH_VALUE: '#toAlertsTable' };
export var COLUMNS = [
    {
        name: 'Type',
        key: 'type',
        sortByKey: 'type',
        isVisibleByDefault: true,
    },
    {
        name: 'Status',
        key: 'status',
        sortByKey: 'status',
        isVisibleByDefault: true,
    },
    {
        name: 'Description',
        key: 'description',
        sortByKey: 'description',
        isVisibleByDefault: true,
    },
    {
        name: 'Action',
        key: 'action',
        sortByKey: 'action',
        isVisibleByDefault: true,
    },
];

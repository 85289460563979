import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
var HOME = DASHBOARDS_SCHEMA.HOME, DASHBOARDS = DASHBOARDS_SCHEMA.DASHBOARDS;
export var BOOTSTRAP_ROUTE_PATHNAME = "/".concat(HOME.PSEUDO_UN, "/").concat(DASHBOARDS.PSEUDO_UN, "/").concat(DASHBOARDS.PAGES.BOOTSTRAP.PSEUDO_UN);
export var URL_QUERIES = {
    COMMON: {
        TYPE: 'type',
        STAGE: 'stage',
        STATUS: 'status',
        CLUSTER: 'cluster',
        PROVIDER: 'provider',
        CUSTOMER: 'customer',
        INSTANCE: 'instance',
        TIME_ZONE: 'timezone',
        DATE_RANGE: 'dateRange',
        PAGINATION: 'pagination',
        ACCOUNT_ID: 'accountId',
        PERMISSIONS: 'permissions',
        MKE_VERSION: 'mkeVersion',
        GRANULARITY: 'granularity',
        SOURCE_TYPE: 'sourceType',
        SEARCH_VALUE: 'searchValue',
        CUSTOMER_TYPE: 'customerType',
        HEALTH_STATUS: 'health_status',
        LAST_SYNC_DATE: 'lastSyncDate',
        ALERT_PRIORITY: 'alertPriority',
        IS_MCC_CUSTOMER: 'isMccCustomer',
        CLUSTER_HEALTH_STATUS: 'healthStatus',
        HAS_ACTIVE_MKE_LICENSE: 'hasActiveMkeLicense',
        HIDE_INACTIVE_CUSTOMERS: 'hideInactiveCustomers',
    },
    TELEMETRY: {
        TELEMETRY_MCC_NAME: 'mccName',
        IS_ACTIVE_CLUSTERS: 'isActiveClusters',
        TELEMETRY_ALERTS_FILTER: 'telemetryAlertsFilter',
        TELEMETRY_ACTIVE_CLUSTER_ID: 'activeClusterId',
        TELEMETRY_MANAGE_CLUSTER_LIST: 'manageClusterList',
        INACTIVE_HOURS_THRESHOLD: 'inactiveHoursThreshold',
    },
    MANAGE: {
        MANAGE_CUSTOMERS_ACTIVE_TAB: 'manageCustomersActiveTab',
    },
    DASHBOARDS: {
        CHECKED_NODES: 'checkedAllNodes',
        IS_ACTIVE_CLUSTERS: 'isActiveClusters',
        ALERT_STATUS: 'alertStatus',
    },
    TRIAL_STATISTIC: {
        TRIAL_STATISTIC_ACTIVE_TAB: 'trialStatisticActiveTab',
    },
    PICKERS: {
        CUSTOMER_PICKER: {
            picker: 'customer',
            value: 'customerPicker',
        },
        AWS_ACCOUNTS_PICKER: {
            picker: 'awsAccounts',
            value: 'awsAccounts',
        },
        INSTANCE_PICKER: {
            picker: 'instance',
            value: 'instancePicker',
        },
        CLUSTER_PICKER: {
            picker: 'cluster',
            value: 'clusterPicker',
        },
        PERMISSIONS_PICKER: {
            picker: 'permissions',
            value: 'permissionsPicker',
        },
        EXTERNAL_PICKER: {
            picker: 'external',
            value: 'externalPicker',
        },
        STACKED_PICKER: {
            picker: 'stacked',
            value: 'stackedPicker',
        },
        MANAGE_USERS_STATUS_PICKER: {
            picker: 'status',
            value: 'status',
        },
        TRIAL_REPORT_STATUS_PICKER: {
            picker: 'state',
            value: 'state',
        },
        TRIAL_REPORT_IS_MIRANTIS_PICKER: {
            picker: 'isMirantis',
            value: 'isMirantis',
        },
        TRIAL_REPORT_CLUSTER_CREATED_PICKER: {
            picker: 'clusterCreated',
            value: 'clusterCreated',
        },
        TRIAL_STATISTIC_YEAR_PICKER: {
            picker: 'year',
            value: 'year',
        },
        TRIAL_STATISTIC_MONTH_PICKER: {
            picker: 'month',
            value: 'month',
        },
    },
};

import React from 'react';
import { Popover } from 'antd';
import classNames from 'classnames';
import { ExpandIcon } from '@mirantis/mds-icon';
import { Link } from './Link';
import { Icon } from './Icon';
import { StyledTooltip } from './NavBarStyled';
export var NavBarDropdown = function (_a) {
    var handleClickOnDropdown = _a.handleClickOnDropdown, handleClickOnLink = _a.handleClickOnLink, route = _a.route, needRenderIcon = _a.needRenderIcon, pathname = _a.pathname, state = _a.state, isSfUser = _a.isSfUser, collapsed = _a.collapsed;
    var handleClick = function () {
        if (!route.disabled) {
            handleClickOnDropdown(route.pseudo);
        }
    };
    var className = classNames({
        nav__link: true,
        nav__drop: true,
        disabled: route.disabled,
        opened: !!(state === null || state === void 0 ? void 0 : state.opened),
        match: !!(state === null || state === void 0 ? void 0 : state.match),
    });
    var renderChildren = function () {
        var menu = route.children.map(function (routeItem) {
            if (!routeItem.isVisible()) {
                return null;
            }
            var C = routeItem.children ? NavBarDropdown : Link;
            return (React.createElement(C, { key: routeItem.pseudo, handleClickOnDropdown: handleClickOnDropdown, handleClickOnLink: handleClickOnLink, pathname: pathname, route: routeItem, isSfUser: isSfUser, collapsed: collapsed, needRenderIcon: false }));
        });
        return React.createElement("div", { className: "nav__link__child" }, menu);
    };
    var content = route.children && React.createElement(StyledTooltip, null, renderChildren());
    var dropdown = (React.createElement("div", { className: className, onClick: handleClick },
        React.createElement(Icon, { needRenderIcon: needRenderIcon, pseudo: route.pseudo }),
        React.createElement("span", { className: "nav__link__name" }, route.name),
        React.createElement(ExpandIcon, { className: "nav__link__angle", size: 10 })));
    return (React.createElement(React.Fragment, null,
        collapsed ? (React.createElement(Popover, { content: content, trigger: "hover", placement: "rightTop" }, dropdown)) : (dropdown),
        (state === null || state === void 0 ? void 0 : state.opened) && renderChildren()));
};

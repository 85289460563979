import get from 'lodash/get';
import { USAGE_OVERVIEW_CHARTS, CHART_DASHBOARDS } from './usageOverviewChartsConstants';
export var getUsageOverviewChartsConfigs = function (params) {
    return {
        dashboard: CHART_DASHBOARDS.containerCloudUsage,
        charts: USAGE_OVERVIEW_CHARTS.map(function (_a) {
            var usageType = _a.usageType, chartType = _a.chartType, key = _a.key;
            return ({
                key: key,
                chartType: chartType,
                usageType: usageType,
                timezone: params.timezone,
                customerTypes: params.mode
                    .map(function (value) { return value.toLowerCase().charAt(0).toUpperCase() + value.slice(1); })
                    .join(','),
                inactiveHoursThreshold: params.lastSyncDate,
                granularity: get(params, 'crossPageFilter.granularity.selected.value'),
                start: get(params, 'crossPageFilter.dateRange.calculatedDateRange.start.timeStamp'),
                end: get(params, 'crossPageFilter.dateRange.calculatedDateRange.end.timeStamp'),
                customerIds: get(params, 'filter.selected.customer', [])
                    .map(function (_a) {
                    var id = _a.id;
                    return id;
                })
                    .join(','),
                instanceIds: get(params, 'filter.selected.instance', [])
                    .map(function (_a) {
                    var id = _a.id;
                    return id;
                })
                    .join(','),
                clusterIds: get(params, 'filter.selected.cluster', [])
                    .map(function (_a) {
                    var id = _a.id;
                    return id;
                })
                    .join(','),
            });
        }),
    };
};

import * as Actions from '@redux/actions';
export var setFilterOption = function (payload) { return ({
    type: Actions.filtration.SyncActions.SET_FILTER_OPTION,
    payload: payload,
}); };
export var setAlertFilterOption = function (payload) { return ({
    type: Actions.filtration.SyncActions.SET_ALERT_FILTER_OPTION,
    payload: payload,
}); };
export var setClusterHealthFilterOption = function (payload) { return ({
    type: Actions.filtration.SyncActions.SET_CLUSTER_HEALTH_FILTER_OPTION,
    payload: payload,
}); };
export var setFilterDateRange = function (payload) { return ({
    type: Actions.filtration.SyncActions.SET_FILTER_DATE_RANGE,
    payload: payload,
}); };
export var setFilterInitialState = function (payload) { return ({
    type: Actions.filtration.SyncActions.SET_FILTER_INITIAL_STATE,
    payload: payload,
}); };
export var saveFetchedOptionsInStore = function (payload) { return ({
    type: Actions.filtration.AsyncActions.SAVE_FETCHED_OPTIONS_IN_STORE,
    payload: payload,
}); };
export var changeSearch = function (payload) { return ({
    type: Actions.filtration.SyncActions.CHANGE_SEARCH,
    payload: payload,
}); };
export var getManageInstancesFilterOptions = function () { return ({
    type: Actions.filtration.AsyncActions.GET_MANAGE_INSTANCES_FILTER_OPTIONS,
}); };

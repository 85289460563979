import { connect } from 'react-redux';
import TelemetryCustomersTable from './TelemetryCustomersTable';
import { getTelemetryCustomers } from '@actionCreators/telemetry';
import { changeCurrentPage } from '@actionCreators/pagination/pagination';
var mapState = function (store) { return ({
    multiFilter: store.multiFilter,
    telemetry: store.telemetry,
    pagination: store.pagination.telemetryCustomers,
    timezone: store.userInfo.settings.initial.timezone,
}); };
var mapDispatch = {
    changeCurrentPage: changeCurrentPage,
    getTelemetryCustomers: getTelemetryCustomers,
};
var connector = connect(mapState, mapDispatch);
export default connector(TelemetryCustomersTable);

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useState } from 'react';
import { Checkbox } from '@mirantis/mds-checkbox';
import styled from '@emotion/styled';
export var StyledCheckbox = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    max-width: 27px;\n\n    svg {\n        position: absolute;\n    }\n"], ["\n    max-width: 27px;\n\n    svg {\n        position: absolute;\n    }\n"])));
var CheckboxComponent = function (_a) {
    var _b = _a.label, label = _b === void 0 ? ' ' : _b, onChange = _a.onChange, checked = _a.checked;
    var _c = useState(false), checkedInner = _c[0], setChecked = _c[1];
    var handleOnChecked = function (value) {
        setChecked(!checkedInner);
        onChange(value);
    };
    useEffect(function () {
        if (checked !== checkedInner) {
            setChecked(checked);
        }
    }, [checked]);
    return (React.createElement(StyledCheckbox, null,
        React.createElement(Checkbox, { label: label, onChange: handleOnChecked, value: checkedInner })));
};
export default CheckboxComponent;
var templateObject_1;

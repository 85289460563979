var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import get from 'lodash/get';
import queryString from 'query-string';
import { URL_QUERIES } from '@commonConstants/urlConstants';
var TIME_ZONE = URL_QUERIES.COMMON.TIME_ZONE;
export var getUrlWithParams = function (query, search) {
    if (search === void 0) { search = window.location.search; }
    return queryString.stringifyUrl({ url: search, query: query });
};
export var getUrlWithoutParams = function (params, pathname, search) {
    if (pathname === void 0) { pathname = window.location.pathname; }
    if (search === void 0) { search = window.location.search; }
    return "".concat(pathname).concat(queryString.exclude(search, params));
};
export var getTimezoneFromUrl = function (_a) {
    var search = _a.search;
    return get(queryString.parse(search), TIME_ZONE, null);
};
/* eslint-disable */
export var getAllQueryParams = function (search) {
    var params = new URLSearchParams(search);
    // @ts-ignore
    return Array.from(params.keys()).reduce(function (acc, val) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[val] = params.get(val), _a)));
    }, {});
};
/* eslint-enable */
export var getPaginationUserPreferencesFromUrl = function (paginationQuery) {
    var search = window.location.search;
    var preferences = {
        limit: null,
        page: null,
    };
    var _a = get(queryString.parse(search), paginationQuery, '').split(','), page = _a[0], limit = _a[1];
    preferences.page = parseInt(page);
    preferences.limit = parseInt(limit);
    return preferences;
};

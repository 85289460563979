export var SyncActions;
(function (SyncActions) {
    SyncActions["SET_CLUSTERS"] = "SET_CLUSTERS";
    SyncActions["UPDATE_CLUSTER_NAME_IN_REDUX"] = "UPDATE_CLUSTER_NAME_IN_REDUX";
})(SyncActions || (SyncActions = {}));
export var AsyncActions;
(function (AsyncActions) {
    AsyncActions["GET_CLUSTERS"] = "GET_CLUSTERS";
    AsyncActions["PATCH_CLUSTER_NAME"] = "PATCH_CLUSTER_NAME";
})(AsyncActions || (AsyncActions = {}));

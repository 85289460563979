export var getDashboardUrl = function (charts) {
    if (charts === 'mccClusterAvailabilityCharts')
        return '/charts/mos-api-availability';
    if (charts === 'mkeCluster')
        return '/charts/mke-telemetry-usage';
    if (charts === 'mccCluster')
        return '/charts/mcc-telemetry-usage';
    if (charts === 'containerCloudUsage')
        return '/charts/container-cloud-usage';
    if (charts === 'mccClusterCapacity')
        return '/charts/container-cloud-capacity';
    if (charts === 'signUps')
        return '/charts/sign-ups';
    if (charts === 'installations')
        return '/charts/installations';
    if (charts === 'activeInstances')
        return '/charts/active-instances';
    if (charts === 'alerts')
        return '/charts/alerts';
    if (charts === 'openstackUsageOverall')
        return '/charts/openstack';
    if (charts === 'trialReportsCosts')
        return '/trials/reports/aws/costs';
    if (charts === 'trialReportsSignUps')
        return '/trials/reports/sign-ups';
    if (charts === 'trialReportsOccupiedSlots')
        return '/trials/reports/occupied-slots';
};

import React from 'react';
import SearchClustersFilter from './SearchClustersFilter';
import SearchClustersTable from './table-search-clusters/SearchClustersTable';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
var filterName = DASHBOARDS_SCHEMA.SUPPORT.PAGES.SEARCH_CLUSTERS.PSEUDO_CC;
var SearchClusters = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", null, "Search Clusters"),
        React.createElement(SearchClustersFilter, { filterName: filterName }),
        React.createElement(SearchClustersTable, null)));
};
export default SearchClusters;

export var NO_USERS_FOUND = 'No users found';
export var COLUMNS_KEYS = {
    EMAIL: 'email',
    FULL_NAME: 'fullName',
    ACCOUNT_NAME: 'sfAccountName',
    IS_ACTIVE: 'isActive',
    LAST_SIGN_IN: 'lastSignIn',
    PERMISSION_IDS: 'permissionIds',
};
export var COLUMNS = [
    {
        name: 'Email',
        key: COLUMNS_KEYS.EMAIL,
        sortByKey: 'email',
    },
    {
        name: 'Full Name',
        key: COLUMNS_KEYS.FULL_NAME,
        sortByKey: 'full_name',
        isEditable: true,
    },
    {
        name: 'Account',
        key: COLUMNS_KEYS.ACCOUNT_NAME,
        sortByKey: 'sf_account_name',
        isEditable: false,
    },
    {
        name: 'Status',
        key: COLUMNS_KEYS.IS_ACTIVE,
        sortByKey: 'is_active',
        isEditable: true,
    },
    {
        name: 'Last Sign In',
        key: COLUMNS_KEYS.LAST_SIGN_IN,
        sortByKey: 'last_sign_in',
    },
    {
        name: 'Permissions',
        key: COLUMNS_KEYS.PERMISSION_IDS,
    },
];

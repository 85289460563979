import queryString from 'query-string';
import isArray from 'lodash/isArray';
import last from 'lodash/last';
import isNil from 'lodash/isNil';
import history from '@history/history';
import { routes, navBarRoutes } from '@configs/routes';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import { getUrlWithParams } from '@commonUtils/urlUtils/urlUtils';
export var debouncedGoToWrapper = function () {
    var accommodatedPaths = [];
    var timeoutID = null;
    return function (path) {
        clearTimeout(timeoutID);
        var queries = queryString.parse(path, { arrayFormat: 'comma' });
        accommodatedPaths.push(queries);
        timeoutID = setTimeout(function () {
            var finalAccommodatedParams = accommodatedPaths.reduce(function (result, item) {
                Object.entries(item).forEach(function (_a) {
                    var key = _a[0], value = _a[1];
                    if (isNil(value)) {
                        return;
                    }
                    var finalKey = last(key.split('?'));
                    // @ts-ignore
                    result[finalKey] = isArray(value) ? value.join(',') : value;
                });
                return result;
            }, {});
            var finalUrl = getUrlWithParams(finalAccommodatedParams);
            if (finalUrl.length > 0) {
                history.push(decodeURIComponent(finalUrl));
            }
            accommodatedPaths = [];
        }, 100);
    };
};
var debouncedGoTo = debouncedGoToWrapper();
var Redirect = /** @class */ (function () {
    function Redirect() {
    }
    Redirect.toMain = function () {
        Redirect.goTo(routes.MAIN.path);
    };
    Redirect.toSignIn = function () {
        Redirect.goTo(routes.SIGN_IN.path);
    };
    Redirect.forbiddenAccess = function () {
        Redirect.goTo(routes.FORBIDDEN_ACCESS.path);
    };
    Redirect.toTelemetryDashboard = function (account_id) {
        var path = "".concat(navBarRoutes.TELEMETRY_DASHBOARD.path, "?").concat(URL_QUERIES.COMMON.ACCOUNT_ID, "=").concat(account_id);
        Redirect.goTo(path);
    };
    Redirect.toCurrentPageWithQueryParams = function (key, options, search) {
        if (search === void 0) { search = history.location.search; }
        var queries = queryString.parse(search, { arrayFormat: 'comma' });
        queries[key] = options.map(function (option) { return (typeof option === 'string' ? option : option.id); });
        Redirect.debouncedGoTo("?".concat(queryString.stringify(queries, { arrayFormat: 'comma' })));
    };
    Redirect.toCurrentPageWithoutQueryParams = function (keys) {
        var queries = queryString.parse(history.location.search, { arrayFormat: 'comma' });
        queries = Object.entries(queries).reduce(function (result, query) {
            var queryKey = query[0], queryValue = query[1];
            if (!keys.includes(queryKey)) {
                result[queryKey] = queryValue;
            }
            return result;
        }, {});
        Redirect.goTo("?".concat(queryString.stringify(queries, { arrayFormat: 'comma' })));
    };
    Redirect.resetQueryParams = function () {
        Redirect.goTo(history.location.pathname);
    };
    Redirect.debouncedGoTo = function (path) {
        debouncedGoTo(decodeURIComponent(path));
    };
    Redirect.goTo = function (path) {
        history.push(decodeURIComponent(path));
    };
    return Redirect;
}());
export default Redirect;

import React from 'react';
import { Route } from 'react-router-dom';
import { store } from '@redux/store';
import { routes } from '@configs/routes';
import Redirect from '@commonUtils/urlUtils/Redirect';
import { Localstorage } from '@utils/localstorage/Localstorage';
import { NoPermissionToPage } from '@app/pages/no-permission-to-page';
import { isPagePermissionGrantedWrapper } from '@configs/navbar/navBarUtils';
export var getUserRouters = function (routers) {
    var allowedPagesTags = store.getState().permissions.allowedPagesTags.data;
    var token = Localstorage.get('token');
    if (!token &&
        !window.location.pathname.includes(routes.SIGN_IN.path) &&
        !window.location.pathname.includes(routes.FORBIDDEN_ACCESS.path)) {
        Redirect.toSignIn();
    }
    return routers.map(function (route) {
        var Component = route.component, path = route.path;
        var isPagePermissionGranted = isPagePermissionGrantedWrapper(allowedPagesTags);
        if (!isPagePermissionGranted([route.id])) {
            return React.createElement(Route, { key: path, element: React.createElement(NoPermissionToPage, null), path: path });
        }
        return React.createElement(Route, { key: path, element: React.createElement(Component, null), path: path });
    });
};

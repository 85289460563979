import styled from '@emotion/styled';
export var StyledToggledDisplay = styled.div(function (props) { return ({
    transition: 'all 0.3s',
    display: props.isVisible ? 'block' : 'none',
}); });
export var StyledToggledOpacity = styled.div(function (props) { return ({
    transition: 'all 0.3s',
    zIndex: '-1',
    overflow: 'hidden',
    height: props.isVisible ? 'auto' : 0,
    opacity: props.isVisible ? 1 : 0,
}); });

import React from 'react';
import classNames from 'classnames';
import ReactList from 'react-list';
import isEmpty from 'lodash/isEmpty';
import { Input } from '@mirantis/mds-input';
import LazyLoading from 'react-list-lazy-load';
import { ExpandIcon, CollapseIcon, variants } from '@mirantis/mds-icon';
import { StyledArrowsWrapper } from '@commonComponents/table/styled';
import LazyImageFactory from '@services/lazy-image-factory/LazyImageFactory';
import { ARROW_ICON_SIZE, SEARCH_PLACEHOLDER, SECOND_SEARCH_PLACEHOLDER } from '@commonConstants/commonConstants';
import { StyledAssignUsersToPermissionGroup } from './StyledAssignUsersToPermissionGroup';
import { FETCH_USERS_LIMIT } from './assignUsersToPermissionGroupConstants';
import { getNormalizedUserKey } from '@root/app/api/permissions-api/permissionsApiUtils';
import { shouldShowUser } from './assignUsersToPermissionGroupUtils';
var AssignUsersToPermissionGroupView = function (props) {
    var users = props.users, allUsers = props.allUsers, groupName = props.groupName, allUsersStatic = props.allUsersStatic, assignedUsersIds = props.assignedUsersIds, allUsersMaxPages = props.allUsersMaxPages, isUserAssignedDesc = props.isUserAssignedDesc, isUserUnassignedDesc = props.isUserUnassignedDesc, assignedUsersSearchTerm = props.assignedUsersSearchTerm, handleAssignedSelectUser = props.handleAssignedSelectUser, toggleIsUserAssignedDesc = props.toggleIsUserAssignedDesc, unassignedUsersSearchTerm = props.unassignedUsersSearchTerm, setAssignedUsersSearchTerm = props.setAssignedUsersSearchTerm, handleUnassignedSelectUser = props.handleUnassignedSelectUser, setUnassignedUsersSearchTerm = props.setUnassignedUsersSearchTerm, moveToAssignedUsersSection = props.moveToAssignedUsersSection, toggleIsUserUnassignedDesc = props.toggleIsUserUnassignedDesc, moveToUnAssignedUsersSection = props.moveToUnAssignedUsersSection, handleUnassignedUsersOnRequestPage = props.handleUnassignedUsersOnRequestPage, unassignedTemporarySelectedUsersIds = props.unassignedTemporarySelectedUsersIds, assignedTemporarySelectedUsersIds = props.assignedTemporarySelectedUsersIds, _a = props.imageFactory, imageFactory = _a === void 0 ? function (imageName, type) { return React.createElement(LazyImageFactory, { imageName: imageName, type: type }); } : _a;
    var allUsersList = (React.createElement(LazyLoading, { items: allUsers, pageSize: FETCH_USERS_LIMIT, length: allUsersMaxPages * FETCH_USERS_LIMIT, onRequestPage: function (newPage) { return handleUnassignedUsersOnRequestPage(newPage); } },
        React.createElement(ReactList, { itemRenderer: function (idx, key) {
                var _a;
                var userId = (_a = allUsers[idx]) === null || _a === void 0 ? void 0 : _a.id;
                var userKey = getNormalizedUserKey(userId);
                var user = users[userKey];
                return (React.createElement("div", { key: key, className: classNames('user-row', {
                        selected: unassignedTemporarySelectedUsersIds.includes(userId),
                    }), onClick: function () { return handleUnassignedSelectUser(userId); } }, "".concat(user.fullName, " ").concat(user.email)));
            }, length: allUsers.length })));
    var assignedUsers = isEmpty(allUsersStatic)
        ? []
        : assignedUsersIds.map(function (id) {
            var userKey = getNormalizedUserKey(id);
            var user = allUsersStatic[userKey];
            var _a = allUsersStatic[userKey], email = _a.email, fullName = _a.fullName;
            return shouldShowUser(assignedUsersSearchTerm, email, fullName) ? (React.createElement("div", { key: id, className: classNames('user-row', {
                    selected: assignedTemporarySelectedUsersIds.includes(id),
                }), onClick: function () { return handleAssignedSelectUser(id); } }, "".concat(user.fullName, " ").concat(user.email))) : null;
        });
    return (React.createElement(StyledAssignUsersToPermissionGroup, null,
        React.createElement("div", { className: "assign-users-to-permission-group" },
            React.createElement("div", { className: "top-row" },
                React.createElement(Input, { showHelp: false, style: { width: 240, pointerEvents: 'none' }, label: "Group Name", value: groupName })),
            React.createElement("div", { className: "top-row" },
                React.createElement(Input, { showHelp: false, style: { width: 240 }, className: "filter__input unassigned_users", label: "Search user", placeholder: SEARCH_PLACEHOLDER, showPlaceholder: true, value: unassignedUsersSearchTerm, onChange: function (e) { return setUnassignedUsersSearchTerm(e.target.value); }, onClear: function () { return setUnassignedUsersSearchTerm(''); }, clearable: true }),
                React.createElement(Input, { showHelp: false, style: { width: 240 }, className: "filter__input assigned_users", label: "Search Assigned user", placeholder: SECOND_SEARCH_PLACEHOLDER, showPlaceholder: true, value: assignedUsersSearchTerm, onChange: function (e) { return setAssignedUsersSearchTerm(e.target.value); }, onClear: function () { return setAssignedUsersSearchTerm(''); }, clearable: true })),
            React.createElement("div", { className: "control-area" },
                React.createElement("div", { className: "users-container" },
                    React.createElement("div", { className: "title-container", "data-testid": "unassigned-title-container", onClick: function () { return toggleIsUserUnassignedDesc(!isUserUnassignedDesc); } },
                        React.createElement("div", { className: "label" }, "Available users"),
                        React.createElement(StyledArrowsWrapper, null,
                            React.createElement(CollapseIcon, { size: ARROW_ICON_SIZE, variant: !isUserUnassignedDesc ? variants.INFO : variants.NORMAL }),
                            React.createElement(ExpandIcon, { size: ARROW_ICON_SIZE, variant: isUserUnassignedDesc ? variants.INFO : variants.NORMAL }))),
                    React.createElement("div", { className: "users", key: "unassigned-users-".concat(isUserUnassignedDesc, "-").concat(unassignedUsersSearchTerm) }, allUsersList)),
                React.createElement("div", { className: "users-controls" },
                    React.createElement("div", { className: "control move-to-selected-control", "data-testid": "move-to-selected-control", onClick: moveToAssignedUsersSection }, imageFactory('icons/arrow/right-twice-arrow.svg', 'img')),
                    React.createElement("div", { className: "control move-to-unselected-control", "data-testid": "move-to-unselected-control", onClick: moveToUnAssignedUsersSection }, imageFactory('icons/arrow/left-twice-arrow.svg', 'img'))),
                React.createElement("div", { className: "users-container" },
                    React.createElement("div", { className: "title-container", "data-testid": "assigned-title-container", onClick: function () { return toggleIsUserAssignedDesc(!isUserAssignedDesc); } },
                        React.createElement("div", { className: "label" }, "Users already in the group"),
                        React.createElement(StyledArrowsWrapper, null,
                            React.createElement(CollapseIcon, { size: ARROW_ICON_SIZE, variant: !isUserAssignedDesc ? variants.INFO : variants.NORMAL }),
                            React.createElement(ExpandIcon, { size: ARROW_ICON_SIZE, variant: isUserAssignedDesc ? variants.INFO : variants.NORMAL }))),
                    React.createElement("div", { className: "users" }, assignedUsers))))));
};
export default AssignUsersToPermissionGroupView;

import produce from 'immer';
import { resolvers } from './resolvers';
export var initialState = {
    loading: false,
    data: null,
    filter: {
        cluster_created: '',
        end: null,
        is_mirantis: '',
        search: '',
        start: null,
        statuses: '',
    },
};
export var trialReport = function (state, action) {
    if (state === void 0) { state = initialState; }
    if (action === void 0) { action = {}; }
    var type = action.type, payload = action.payload;
    if (!(type in resolvers)) {
        return state;
    }
    var resolver = function (draft) { return resolvers[type](draft, payload); };
    return produce(state, resolver);
};

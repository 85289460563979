import React from 'react';
import styled from '@emotion/styled';
import { STEPS } from '@components/new-sign-ups/constants';
var getWidth = function (step) {
    switch (step) {
        case STEPS[0]:
            return 0;
        case STEPS[1]:
            return 33;
        case STEPS[2]:
            return 66;
        default:
            return 100;
    }
};
var SignInBar = styled.div(function (_a) {
    var theme = _a.theme;
    return ({
        width: '100%',
        height: 8,
        marginBottom: 15,
        borderRadius: 4,
        background: theme.color.action.primary.normal.disabled.background,
        position: 'relative',
    });
});
var SignInBarLine = styled.div(function (props) { return ({
    width: "".concat(getWidth(props.description), "%"),
    height: 'inherit',
    background: props.theme.color.action.primary.normal.enabled.background,
    borderRadius: 'inherit',
    position: 'absolute',
    left: 0,
    top: 0,
    transition: 'width 0.2s ease',
}); });
var SignInProgressBar = function (_a) {
    var signIn = _a.signIn;
    return (React.createElement("div", { className: "sign-in-progress" },
        React.createElement(SignInBar, { className: "sign-in-progress__bar" },
            React.createElement(SignInBarLine, { className: "sign-in-progress__bar__line", description: signIn.description })),
        React.createElement("div", { className: "sign-in-progress__step" },
            signIn.description === 'Redirecting...' ? null : (React.createElement("span", { className: "sign-in-progress__step__counter" },
                "Step ",
                signIn.step + 1,
                "/",
                STEPS.length,
                ":",
                ' ')),
            signIn.description)));
};
export default SignInProgressBar;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { connect } from 'react-redux';
import { dropMKERecommendationsToDefault, getMKERecommendations } from '@actionCreators/telemetry';
import Recommendations from './Recommendations';
import { getNormalizedRecommendationData } from './recommendationsUtils';
var mapState = function (store) {
    var recommendations = store.telemetry.customer.recommendations;
    return {
        recommendations: __assign(__assign({}, recommendations), { data: getNormalizedRecommendationData(recommendations.data) }),
    };
};
var mapDispatch = {
    getMKERecommendations: getMKERecommendations,
    dropMKERecommendationsToDefault: dropMKERecommendationsToDefault,
};
var connector = connect(mapState, mapDispatch);
export default connector(Recommendations);

import get from 'lodash/get';
import { toggleRequestPendingStateIds } from '@redux/dispatchers/requestsTracking';
import { handleErrorResponse } from '@commonUtils/errorHandlingUtils/errorHandlingUtils';
import { clearAllRequestPendingStateIds, debouncedToggleRequestPendingStateIds, } from './axiosInstancesInterceptorsUtils';
export var setRequestPendingStateIds = debouncedToggleRequestPendingStateIds(toggleRequestPendingStateIds, true);
export var clearRequestPendingStateIds = debouncedToggleRequestPendingStateIds(toggleRequestPendingStateIds, false);
export var requestPendingStateSideEffect = function (config) {
    var shouldHandleRequestId = get(config, 'url');
    if (shouldHandleRequestId) {
        setRequestPendingStateIds(shouldHandleRequestId);
    }
};
export var responsePendingStateSideEffect = function (response) {
    var shouldHandleRequestId = get(response, 'config.url');
    if (shouldHandleRequestId) {
        clearRequestPendingStateIds(shouldHandleRequestId);
    }
};
export var successRequestsSideEffects = [requestPendingStateSideEffect];
export var failureRequestsSideEffects = [];
export var successResponseSideEffects = [responsePendingStateSideEffect];
export var failureResponseSideEffects = [clearAllRequestPendingStateIds, handleErrorResponse];

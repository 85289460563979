import flatten from 'lodash/flatten';
import { camelToSnakeCase } from '@commonUtils/commonUtils';
import { COLUMNS_KEYS } from '../openstack-cluster-usage-table/openstackClusterUsageTableConstants';
export var getFilteredCsv = function (data, visibleColumns) {
    var rows = data.split(/[\r\n]+/).map(function (row) { return row.split(','); });
    var indexesDoNotRemove = flatten(visibleColumns.map(function (_a) {
        var key = _a.key;
        if (key === COLUMNS_KEYS.TOTAL_NODES) {
            return [rows[0].indexOf('master_nodes'), rows[0].indexOf('worker_nodes')];
        }
        return rows[0].indexOf(camelToSnakeCase(key));
    }));
    var filteredCSV = rows.reduce(function (result, item, index) {
        result[index] = item.filter(function (item, index) { return indexesDoNotRemove.includes(index); });
        return result;
    }, rows);
    return filteredCSV.map(function (value) { return value.join(','); }).join('\r\n');
};

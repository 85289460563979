import { mount } from '@services/pdf-generation-service/pdfGenerationService';
import { Provider } from 'react-redux';
import { store } from '@redux/store';
import { lightTheme, ThemeProvider } from '@mirantis/mds-theme';
import fontPaths from '@configs/fontPaths';
import { Global } from '@emotion/react';
import getGlobalStyles from '@root/globalStyles';
import React from 'react';
export var mountComponent = function (component, nodeElement) {
    return mount(React.createElement(Provider, { store: store },
        React.createElement(ThemeProvider, { theme: lightTheme, withFonts: fontPaths },
            React.createElement(Global, { styles: getGlobalStyles(lightTheme) }),
            component)), nodeElement);
};

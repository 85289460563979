import React, { useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedEffect } from '@app/hooks';
import { changeCurrentPage } from '@redux/dispatchers/pagination';
import { getOpenstackClusterUsage } from '@redux/dispatchers/openstackClusterUsage';
import PaginationContainer from '@commonComponents/pagination/PaginationContainer';
import TableLoadingStates from '@commonComponents/table-loading-states/TableLoadingStates';
import { PAGINATION_NAMES } from '@configs/DASHBOARD_PSEUDOS';
import Table from '@commonComponents/table/Table';
import { COLUMNS, NO_OPENSTACK_USAGE_CLUSTERS_FOUND } from './openstackClusterUsageTableConstants';
import { normalizers, summaryNormalizers } from './openstackClusterUsageUtils';
import { StyledTable } from '@commonComponents/table/styled';
var OPENSTACK_CLUSTER_USAGE = PAGINATION_NAMES.OPENSTACK_CLUSTER_USAGE;
var OpenstackClusterUsageTable = function (_a) {
    var _b;
    var name = _a.name, summary = _a.summary, onSortStateChange = _a.onSortStateChange, visibleColumnsKeysCallback = _a.visibleColumnsKeysCallback;
    var _c = useSelector(function (store) {
        var _a, _b;
        return ({
            mode: store.mode,
            multiFilter: store.multiFilter,
            timezone: store.userInfo.userPreferences.timezone,
            pagination: store.pagination[name],
            data: store.openstackClusterUsage.data || [],
            loading: store.openstackClusterUsage.loading,
            customers: ((_b = (_a = store.multiFilter.filter) === null || _a === void 0 ? void 0 : _a.selected) === null || _b === void 0 ? void 0 : _b.customer) || [],
        });
    }), data = _c.data, mode = _c.mode, multiFilter = _c.multiFilter, pagination = _c.pagination, customers = _c.customers, timezone = _c.timezone, loading = _c.loading;
    var _d = useState({
        sortBy: 'cluster_name',
        orderBy: 'asc',
    }), state = _d[0], setState = _d[1];
    var params = {
        sortBy: state.sortBy,
        orderBy: state.orderBy,
        limit: pagination.limit,
        page: pagination.currentPage,
        customer_ids: customers.map(function (_a) {
            var id = _a.id;
            return id;
        }).join(','),
        search_term: multiFilter.filter.searchTerm,
        inactive_hours_threshold: mode.lastSyncDate.selected.value,
        customer_types: mode.selected[0] === 'All' ? null : mode.selected.toString(),
        timezone: timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    useDebouncedEffect(function () {
        getOpenstackClusterUsage(params);
    }, [
        state,
        timezone,
        mode.selected,
        pagination.limit,
        pagination.currentPage,
        mode.lastSyncDate.selected.value,
        (_b = multiFilter.filter.selected) === null || _b === void 0 ? void 0 : _b.customer,
        multiFilter.filter.searchTerm,
    ]);
    var onPaginationChange = function (page) {
        changeCurrentPage({
            paginationName: OPENSTACK_CLUSTER_USAGE,
            currentPage: page,
        });
    };
    var onLimitChange = function (limit) {
        changeCurrentPage({
            limit: limit,
            paginationName: OPENSTACK_CLUSTER_USAGE,
            currentPage: 1,
        });
    };
    var finalData = {
        rows: data,
        columns: COLUMNS,
    };
    var handleSetSortState = function (newSate) {
        onSortStateChange(newSate);
        setState(newSate);
    };
    return (React.createElement(StyledTable, { className: "table" },
        React.createElement(TableLoadingStates, { data: data, loading: loading, count: pagination.limit, empty: {
                description: NO_OPENSTACK_USAGE_CLUSTERS_FOUND,
            }, skeleton: {
                paddingBottom: 20,
                paddingTop: 20,
            } },
            React.createElement(React.Fragment, null,
                React.createElement(Table, { sortable: true, data: finalData, orderBy: state.orderBy, sortBy: state.sortBy, visibleColumnsKeysCallback: visibleColumnsKeysCallback, setSortableState: function (newState) { return handleSetSortState(newState); }, pagination: pagination, normalizers: normalizers, summaryNormalizers: summaryNormalizers, summary: summary, columnControl: true }),
                React.createElement(PaginationContainer, { withLimits: true, onLimitChange: onLimitChange, pagination: pagination, onChange: onPaginationChange })))));
};
export default memo(OpenstackClusterUsageTable);

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import { Outlet } from 'react-router-dom';
import history from '@history/history';
import AsideNav from '@components/aside-nav/AsideNav';
import Container from '@containers/main/Container';
import ContentRaw from '@containers/main/ContentRaw';
import NavBar from '@components/navbar/NavBar';
import AsideLogo from '@commonComponents/aside-logo/AsideLogo';
import { setNavBarCollapsedToLocalStore, getNavBarCollapsedFromLocalStore } from '@commonUtils/commonUtils';
import { BANNER_STATUSES } from '@components/warning-banner/warningBannerConstants';
var MainContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    padding-top: 15px;\n    padding-right: 15px;\n    height: 100%;\n"], ["\n    display: flex;\n    padding-top: 15px;\n    padding-right: 15px;\n    height: 100%;\n"])));
export var Main = function () {
    var _a = useState(getNavBarCollapsedFromLocalStore()), collapsed = _a[0], toggleCollapsed = _a[1];
    useEffect(function () {
        setNavBarCollapsedToLocalStore(collapsed);
    }, [collapsed]);
    var status = useSelector(function (store) { return store.warningBanner.banner.status; });
    var top = !isNil(status) && status !== BANNER_STATUSES.FINE ? '82' : '0';
    return (React.createElement(Container, null,
        React.createElement(MainContent, { className: "main__content", style: {
                height: "calc(100vh - ".concat(top, "px)"),
            } },
            React.createElement(AsideNav, { toggleCollapsed: toggleCollapsed, collapsed: collapsed },
                React.createElement("div", null,
                    React.createElement(AsideLogo, { isSmall: true, collapsed: collapsed }),
                    React.createElement(NavBar, { location: history.location, collapsed: collapsed }))),
            React.createElement(ContentRaw, null,
                React.createElement(Outlet, null)))));
};
var templateObject_1;

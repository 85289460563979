var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import isEmpty from 'lodash/isEmpty';
import { ENDPOINTS } from '@configs/api';
import { getAxiosInstance } from '../axios-instance/apiInstances';
import { getCustomerTypes, getCustomers } from '@api/customers-api/customersApi';
import { setCustomerTypes } from '@redux/dispatchers/mode';
import { getInstances } from '@api/instance-api/instanceApi';
import { getClusters } from '@api/clusters-api/clustersApi';
import { fetchPermissionGroups } from '@api/permissions-api/permissionsApi';
import { fetchAwsAccounts } from '@api/trial-api/trialApi';
import { getFormattedCustomers, getFormattedInstances, getFormattedClusters, getClusterExtractedParams, getTransformedHealthStatus, getCustomerExtractedParams, getInstancesExtractedParams, getPermissionsExtractedParams, } from './pickersApiUtils';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { store } from '@redux/store';
var DASHBOARDS = DASHBOARDS_SCHEMA.DASHBOARDS, ACCOUNT_MANAGEMENT = DASHBOARDS_SCHEMA.ACCOUNT_MANAGEMENT, REPORTS = DASHBOARDS_SCHEMA.REPORTS, SUPPORT = DASHBOARDS_SCHEMA.SUPPORT;
var OptionsAPI = /** @class */ (function () {
    function OptionsAPI() {
    }
    var _b;
    _b = OptionsAPI;
    OptionsAPI.mkeVersion = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(_b, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAxiosInstance().get(ENDPOINTS.MKE_VERSIONS)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
            }
        });
    }); };
    OptionsAPI.stage = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(_b, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAxiosInstance().get(ENDPOINTS.BOOTSTRAP_STAGES, { params: params })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
            }
        });
    }); };
    OptionsAPI.provider = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(_b, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAxiosInstance().get(ENDPOINTS.BOOTSTRAP_PROVIDERS, { params: params })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
            }
        });
    }); };
    OptionsAPI.alertStatus = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(_b, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAxiosInstance().get(ENDPOINTS.ALERTS_STATUSES)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
            }
        });
    }); };
    OptionsAPI.alertPriority = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(_b, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAxiosInstance().get(ENDPOINTS.ALERTS_PRIORITIES)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
            }
        });
    }); };
    OptionsAPI.healthStatus = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(_b, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAxiosInstance().get(ENDPOINTS.CLUSTER_HEALTH_STATUSES)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
            }
        });
    }); };
    OptionsAPI.customerTypes = function () { return __awaiter(void 0, void 0, void 0, function () {
        var customerTypes;
        return __generator(_b, function (_a) {
            switch (_a.label) {
                case 0:
                    customerTypes = store.getState().mode.options;
                    if (!isEmpty(customerTypes)) return [3 /*break*/, 2];
                    return [4 /*yield*/, getCustomerTypes()];
                case 1:
                    customerTypes = _a.sent();
                    setCustomerTypes(customerTypes);
                    _a.label = 2;
                case 2: return [2 /*return*/, customerTypes];
            }
        });
    }); };
    OptionsAPI.customer = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var extractedParams, customers;
        return __generator(_b, function (_a) {
            switch (_a.label) {
                case 0:
                    extractedParams = getCustomerExtractedParams(params);
                    return [4 /*yield*/, getCustomers(extractedParams)];
                case 1:
                    customers = _a.sent();
                    return [2 /*return*/, getFormattedCustomers(customers, params.limit)];
            }
        });
    }); };
    OptionsAPI.instance = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var extractedParams, instances;
        return __generator(_b, function (_a) {
            switch (_a.label) {
                case 0:
                    extractedParams = getInstancesExtractedParams(params);
                    return [4 /*yield*/, getInstances(extractedParams)];
                case 1:
                    instances = _a.sent();
                    return [2 /*return*/, getFormattedInstances(instances, params.limit)];
            }
        });
    }); };
    OptionsAPI.cluster = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var extractedParams, clusters;
        return __generator(_b, function (_a) {
            switch (_a.label) {
                case 0:
                    extractedParams = getClusterExtractedParams(params);
                    return [4 /*yield*/, getClusters(extractedParams)];
                case 1:
                    clusters = _a.sent();
                    return [2 /*return*/, getFormattedClusters(clusters, params.limit)];
            }
        });
    }); };
    OptionsAPI.permissions = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var extractedParams;
        return __generator(_b, function (_a) {
            extractedParams = getPermissionsExtractedParams(params);
            return [2 /*return*/, fetchPermissionGroups(extractedParams)];
        });
    }); };
    OptionsAPI.awsAccounts = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var extractedParams;
        return __generator(_b, function (_a) {
            extractedParams = getPermissionsExtractedParams(params);
            return [2 /*return*/, fetchAwsAccounts(extractedParams)];
        });
    }); };
    return OptionsAPI;
}());
export { OptionsAPI };
//TODO PRODX-15372
export var PICKERS_API = (_a = {},
    _a[DASHBOARDS.PAGES.BOOTSTRAP.PSEUDO_CC] = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, customer, instance, stage, provider;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, Promise.all([
                        OptionsAPI.customer(params.customerParams),
                        OptionsAPI.instance(params.instanceParams),
                        OptionsAPI.stage(params.stageParams),
                        OptionsAPI.provider(params.providerParams),
                    ])];
                case 1:
                    _a = _c.sent(), customer = _a[0], instance = _a[1], stage = _a[2], provider = _a[3];
                    return [2 /*return*/, {
                            customer: customer,
                            instance: instance,
                            stage: {
                                data: stage,
                            },
                            provider: {
                                data: provider,
                            },
                        }];
            }
        });
    }); },
    _a[DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE.PSEUDO_CC] = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, usageOverviewCustomer, usageOverviewInstance, usageOverviewCluster;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, Promise.all([
                        OptionsAPI.customer(params.customerParams),
                        OptionsAPI.instance(params.instanceParams),
                        OptionsAPI.cluster(params.clusterParams),
                    ])];
                case 1:
                    _a = _c.sent(), usageOverviewCustomer = _a[0], usageOverviewInstance = _a[1], usageOverviewCluster = _a[2];
                    return [2 /*return*/, {
                            customer: usageOverviewCustomer,
                            instance: usageOverviewInstance,
                            cluster: usageOverviewCluster,
                        }];
            }
        });
    }); },
    _a[DASHBOARDS.PAGES.OPENSTACK_USAGE.TABS.OPENSTACK_OVERALL_USAGE.PSEUDO_CC] = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, openStackOverallCustomer, openStackOverallInstance, openStackOverallCluster;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, Promise.all([
                        OptionsAPI.customer(params.customerParams),
                        OptionsAPI.instance(params.instanceParams),
                        OptionsAPI.cluster(params.clusterParams),
                    ])];
                case 1:
                    _a = _c.sent(), openStackOverallCustomer = _a[0], openStackOverallInstance = _a[1], openStackOverallCluster = _a[2];
                    return [2 /*return*/, {
                            customer: openStackOverallCustomer,
                            instance: openStackOverallInstance,
                            cluster: openStackOverallCluster,
                        }];
            }
        });
    }); },
    _a[SUPPORT.PAGES.ALERTS.PSEUDO_CC] = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, customer, cluster, alertStatus, alertPriority;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, Promise.all([
                        OptionsAPI.customer(params.customerParams),
                        OptionsAPI.cluster(params.instanceParams),
                        OptionsAPI.alertStatus(),
                        OptionsAPI.alertPriority(),
                    ])];
                case 1:
                    _a = _c.sent(), customer = _a[0], cluster = _a[1], alertStatus = _a[2], alertPriority = _a[3];
                    return [2 /*return*/, {
                            customer: customer,
                            cluster: cluster,
                            alertStatus: {
                                data: alertStatus,
                            },
                            alertPriority: {
                                data: alertPriority,
                            },
                        }];
            }
        });
    }); },
    _a[ACCOUNT_MANAGEMENT.PAGES.MANAGE_CUSTOMERS.PSEUDO_CC] = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, customerTypes, type;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    data = [];
                    return [4 /*yield*/, OptionsAPI.customerTypes()];
                case 1:
                    customerTypes = _a.sent();
                    for (type in customerTypes) {
                        if (Object.prototype.hasOwnProperty.call(customerTypes, type)) {
                            data.push({
                                id: type,
                                name: customerTypes[type],
                            });
                        }
                    }
                    return [2 /*return*/, {
                            type: {
                                data: data,
                            },
                        }];
            }
        });
    }); },
    _a[ACCOUNT_MANAGEMENT.PAGES.MANAGE_USERS.PSEUDO_CC] = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var permissions;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Promise.all([OptionsAPI.permissions(params)])];
                case 1:
                    permissions = (_a.sent())[0];
                    return [2 /*return*/, {
                            status: {
                                data: [],
                            },
                            permissions: permissions,
                        }];
            }
        });
    }); },
    _a[SUPPORT.PAGES.CLUSTER_HEALTH.PSEUDO_CC] = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, customer, healthStatus;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, Promise.all([
                        OptionsAPI.customer(params.customerParams),
                        OptionsAPI.healthStatus(),
                    ])];
                case 1:
                    _a = _c.sent(), customer = _a[0], healthStatus = _a[1];
                    return [2 /*return*/, {
                            customer: customer,
                            healthStatus: {
                                data: getTransformedHealthStatus(healthStatus),
                            },
                        }];
            }
        });
    }); },
    _a[REPORTS.PAGES.TRIAL_STATISTIC.TABS.AWS_COSTS] = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var awsAccounts;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Promise.all([OptionsAPI.awsAccounts(params.customerParams)])];
                case 1:
                    awsAccounts = (_a.sent())[0];
                    return [2 /*return*/, {
                            awsAccounts: {
                                data: awsAccounts,
                            },
                        }];
            }
        });
    }); },
    _a[REPORTS.PAGES.TRIAL_STATISTIC.TABS.OCCUPIED_SLOTS] = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var awsAccounts;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Promise.all([OptionsAPI.awsAccounts(params.customerParams)])];
                case 1:
                    awsAccounts = (_a.sent())[0];
                    return [2 /*return*/, {
                            awsAccounts: {
                                data: awsAccounts,
                            },
                        }];
            }
        });
    }); },
    _a[DASHBOARDS.PAGES.OPENSTACK_CLUSTERS_USAGE.PSEUDO_CC] = function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var openstackClusterUsageCustomer;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Promise.all([OptionsAPI.customer(params.customerParams)])];
                case 1:
                    openstackClusterUsageCustomer = (_a.sent())[0];
                    return [2 /*return*/, {
                            customer: openstackClusterUsageCustomer,
                        }];
            }
        });
    }); },
    _a[ACCOUNT_MANAGEMENT.PAGES.MANAGE_CUSTOMERS.FILTER_PSEUDOS.MANAGE_INSTANCES] = function () {
        return {};
    },
    _a[ACCOUNT_MANAGEMENT.PAGES.MANAGE_CUSTOMERS.FILTER_PSEUDOS.SINGLE_CUSTOMER_INSTANCES] = function () {
        return {};
    },
    _a);

export var NO_CUSTOMERS_FOUND = 'No customers found';
export var TABLE_TITLE = 'Customer Overview';
export var COLUMNS = [
    {
        name: 'ID',
        key: 'id',
        sortByKey: 'id',
    },
    {
        name: 'Name',
        key: 'name',
        sortByKey: 'name',
        isVisibleByDefault: true,
    },
    {
        name: 'Instance Count',
        key: 'instanceCount',
        sortByKey: 'instance_count',
        isVisibleByDefault: true,
    },
    {
        name: 'Management clusters',
        key: 'managedClusterCount',
        sortByKey: 'managed_cluster_count',
        isVisibleByDefault: true,
    },
    {
        name: 'Child clusters',
        key: 'childClusterCount',
        sortByKey: 'child_cluster_count',
        isVisibleByDefault: true,
    },
    {
        name: 'Nodes',
        key: 'nodeCount',
        sortByKey: 'node_count',
        isVisibleByDefault: true,
    },
    {
        name: 'Alerts',
        key: 'alertCount',
        sortByKey: 'alert_count',
        isVisibleByDefault: true,
    },
    {
        name: 'Created At',
        key: 'createdAt',
        sortByKey: 'created_at',
    },
];

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import get from 'lodash/get';
import { CUSTOMER_TYPES } from '@commonConstants/customeTypeConstants';
import { MULTI_FILTER_CONFIG } from '@configs/multiFilterConfig';
import { URL_QUERIES } from '@commonConstants/urlConstants';
var COMMON = URL_QUERIES.COMMON;
import { CLUSTER_TYPES, CLUSTER_STATUSES } from '@commonConstants/clustersConstants';
import { defaultGranularity, staticReportsGranularity } from '@configs/crossPageFilterInitialState';
var ALL = 'All';
var geMultiFilterPickersStandards = function (pseudo) {
    return Object.values(MULTI_FILTER_CONFIG)
        .map(function (config) { return get(config, "INITIAL_STATE.config.configuration.".concat(pseudo, ".data")); })
        .filter(Boolean)
        .flat();
};
export var getUserStatusStandard = function () { return geMultiFilterPickersStandards(COMMON.STATUS); };
export var getSourceTypeStandard = function () { return __spreadArray(__spreadArray([], geMultiFilterPickersStandards(COMMON.SOURCE_TYPE), true), [ALL], false); };
export var getCustomerTypesStandard = function () { return Object.values(CUSTOMER_TYPES).map(function (value) { return value; }); };
export var getGranularityStandard = function () { return __spreadArray(__spreadArray([], Object.values(defaultGranularity).map(function (_a) {
    var value = _a.value;
    return value;
}), true), Object.values(staticReportsGranularity).map(function (_a) {
    var value = _a.value;
    return value;
}), true); };
export var getTelemetryAlertsFilterTypeStandard = function () { return __spreadArray(__spreadArray([], Object.values(CLUSTER_TYPES), true), ['All'], false); };
export var getTelemetryAlertsFilterStatusStandard = function () { return __spreadArray(__spreadArray([], Object.values(CLUSTER_STATUSES), true), ['All'], false); };

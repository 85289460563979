import * as Actions from '@redux/actions';
import merge from 'lodash/merge';
var r = {};
r[Actions.filtration.SyncActions.SET_FILTER_INITIAL_STATE] = function (draft, payload) {
    draft.loading = false;
    Object.assign(draft, payload);
};
r[Actions.filtration.SyncActions.SET_FILTER_OPTION] = function (draft, payload) {
    merge(draft.filter, payload);
};
r[Actions.filtration.SyncActions.SET_ALERT_FILTER_OPTION] = function (draft, payload) {
    merge(draft.filter, payload);
};
r[Actions.filtration.SyncActions.SET_CLUSTER_HEALTH_FILTER_OPTION] = function (draft, payload) {
    merge(draft.filter, payload);
};
r[Actions.filtration.SyncActions.SET_FILTER_DATE_RANGE] = function (draft, payload) {
    merge(draft, payload);
};
r[Actions.filtration.AsyncActions.SAVE_FETCHED_OPTIONS_IN_STORE] = function (draft, payload) {
    var _a;
    merge(draft.fetchedOptions, (_a = {},
        _a[payload.key] = payload.data,
        _a));
};
r[Actions.filtration.SyncActions.CHANGE_SEARCH] = function (draft, payload) {
    draft.filter[payload.pseudo].selected = payload.value;
};
export { r as resolvers };

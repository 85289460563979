var Location = /** @class */ (function () {
    function Location() {
    }
    Location.isManagementPages = function (url) {
        if (url === void 0) { url = window.location.href; }
        var regExp = new RegExp('account-management');
        var result = url.search(regExp);
        return result !== -1;
    };
    Location.isStaticReportsPage = function (url) {
        if (url === void 0) { url = window.location.href; }
        return url.includes('report');
    };
    Location.isMKEPage = function (url) {
        if (url === void 0) { url = window.location.href; }
        var regExp = new RegExp('telemetry');
        var result = url.search(regExp);
        return result !== -1;
    };
    return Location;
}());
export { Location };

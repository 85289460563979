import { store } from '@redux/store';
import isEmpty from 'lodash/isEmpty';
import { toggleRequestPendingStateIds } from '@redux/dispatchers/requestsTracking';
import { CLEAR_REQUEST_PENDING_IDS_TIMEOUT, DEBOUNCED_TOGGLE_REQUEST_PENDING_IDS_TIMEOUT, } from './axiosInstancesInterceptorsConstants';
export var clearAllRequestPendingStateIds = function () {
    var shouldHandleRequestIds = store.getState().requestsTracking.requestsInPendingStateIds;
    if (isEmpty(shouldHandleRequestIds))
        return;
    toggleRequestPendingStateIds({
        pending: false,
        shouldHandleRequestIds: shouldHandleRequestIds,
    });
};
export var debouncedToggleRequestPendingStateIds = function (action, pending, timeout) {
    if (timeout === void 0) { timeout = DEBOUNCED_TOGGLE_REQUEST_PENDING_IDS_TIMEOUT; }
    var accommodatedIds = [];
    var setReqIdsTimeoutID = null;
    var clearReqIdsTimeoutID = null;
    return function (shouldHandleRequestIds) {
        clearTimeout(setReqIdsTimeoutID);
        clearTimeout(clearReqIdsTimeoutID);
        accommodatedIds.push(shouldHandleRequestIds);
        setReqIdsTimeoutID = setTimeout(function () {
            action({ shouldHandleRequestIds: accommodatedIds, pending: pending });
            accommodatedIds = [];
            clearReqIdsTimeoutID = setTimeout(clearAllRequestPendingStateIds, CLEAR_REQUEST_PENDING_IDS_TIMEOUT);
        }, timeout);
    };
};

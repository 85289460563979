import React from 'react';
import { Button } from '@mirantis/mds-button';
import { signOut } from '@root/index';
import ErrorBanner from '@commonComponents/error-baner/ErrorBanner';
import { MESSAGES } from '@commonConstants/messagesConstants';
var ErrorsLoading = function (_a) {
    var shouldShowNoPermissionsError = _a.shouldShowNoPermissionsError, error = _a.error, children = _a.children;
    if (shouldShowNoPermissionsError) {
        return (React.createElement(ErrorBanner, { title: error.message, message: MESSAGES.NO_PERMISSIONS },
            React.createElement(Button, { label: "Logout", onClick: signOut, style: { margin: 'auto' } })));
    }
    return children;
};
export default ErrorsLoading;

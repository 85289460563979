var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var StyledAsideNav = styled.aside(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    width: 335px;\n    position: relative;\n    transition: all 0.2s;\n\n    .nav__link__name {\n        transition: all 0.7s;\n        height: 15px;\n    }\n\n    &.collapsed {\n        width: 80px;\n\n        .nav__link__img {\n            transition: all 0.2s;\n            padding-left: 8px;\n            padding-top: 2px;\n        }\n\n        .aside__logo {\n            transition: all 0.2s;\n            padding-left: 20px;\n        }\n\n        .aside-nav__version {\n            transition: all 0.2s;\n            font-size: 9px;\n            padding-left: 10px;\n        }\n\n        .jobs-status {\n            width: 9px;\n            height: 9px;\n            margin-top: 1px;\n        }\n\n        .nav__link__name {\n            color: transparent;\n        }\n\n        .nav__link__angle {\n            display: none;\n        }\n    }\n"], ["\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    width: 335px;\n    position: relative;\n    transition: all 0.2s;\n\n    .nav__link__name {\n        transition: all 0.7s;\n        height: 15px;\n    }\n\n    &.collapsed {\n        width: 80px;\n\n        .nav__link__img {\n            transition: all 0.2s;\n            padding-left: 8px;\n            padding-top: 2px;\n        }\n\n        .aside__logo {\n            transition: all 0.2s;\n            padding-left: 20px;\n        }\n\n        .aside-nav__version {\n            transition: all 0.2s;\n            font-size: 9px;\n            padding-left: 10px;\n        }\n\n        .jobs-status {\n            width: 9px;\n            height: 9px;\n            margin-top: 1px;\n        }\n\n        .nav__link__name {\n            color: transparent;\n        }\n\n        .nav__link__angle {\n            display: none;\n        }\n    }\n"])));
export var StyledAsideNavActiveUser = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background: ", ";\n    width: 100%;\n    height: 60px;\n    display: flex;\n    align-items: center;\n    border-top: 1px solid ", ";\n    cursor: pointer;\n"], ["\n    background: ", ";\n    width: 100%;\n    height: 60px;\n    display: flex;\n    align-items: center;\n    border-top: 1px solid ", ";\n    cursor: pointer;\n"])), function (props) { return props.theme.color.background.layer2; }, function (props) { return props.theme.color.background.layer4; });
export var StyledVersion = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin: 5px;\n    height: 14px;\n    display: flex;\n    font-size: 12px;\n    padding-left: 19px;\n"], ["\n    margin: 5px;\n    height: 14px;\n    display: flex;\n    font-size: 12px;\n    padding-left: 19px;\n"])));
export var StyledExpandController = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 100%;\n    height: 48px;\n    display: flex;\n    cursor: pointer;\n    align-items: center;\n    justify-content: center;\n    background: ", ";\n\n    svg {\n        fill: ", ";\n    }\n"], ["\n    width: 100%;\n    height: 48px;\n    display: flex;\n    cursor: pointer;\n    align-items: center;\n    justify-content: center;\n    background: ", ";\n\n    svg {\n        fill: ", ";\n    }\n"])), function (props) { return props.theme.color.background.layer4; }, function (props) { return props.theme.color.background.header1; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

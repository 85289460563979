var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { TABLE_SEQUENTIAL_NUMBER } from '@commonConstants/commonConstants';
export var getTableDataWithSequenceNumbering = function (_a, pagination) {
    var columns = _a.columns, rows = _a.rows;
    if (pagination === void 0) { pagination = { currentPage: 1, limit: 1 }; }
    var currentPage = pagination.currentPage, limit = pagination.limit;
    var finalColumns = __spreadArray([
        {
            name: '#',
            key: TABLE_SEQUENTIAL_NUMBER,
            isStatic: true,
        }
    ], columns, true);
    var finalRows = rows.map(function (element, index) { return (__assign({ sequentialNumber: index + 1 + (currentPage - 1) * limit }, element)); });
    return {
        columns: finalColumns,
        rows: finalRows,
    };
};
export var getEditableTableDataWithSequenceNumbering = function (_a, pagination) {
    var columns = _a.columns, rows = _a.rows;
    if (pagination === void 0) { pagination = { currentPage: 1, limit: 1 }; }
    var currentPage = pagination.currentPage, limit = pagination.limit;
    var finalColumns = __spreadArray([
        {
            name: '#',
            key: TABLE_SEQUENTIAL_NUMBER,
            isStatic: true,
        }
    ], columns, true);
    var finalRows = rows.map(function (element, index) {
        var sequenceNumber = index + 1 + (currentPage - 1) * limit;
        return (React.createElement("tr", { className: "table__tr", key: sequenceNumber },
            React.createElement("td", { className: "table__td sequentialNumber" }, sequenceNumber),
            element));
    });
    return {
        columns: finalColumns,
        rows: finalRows,
    };
};

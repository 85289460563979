import { CHART_DASHBOARDS } from './mkeClusterChartsConstants';
export var getChartsConfigs = function (params) {
    return {
        dashboard: CHART_DASHBOARDS.mkeCluster,
        charts: [
            {
                key: 'mkeClusterCharts',
                end: params.end,
                start: params.start,
                timezone: params.timezone,
                granularity: params.granularity,
                clusterIds: params.activeClusterId || params.clusterIds,
                licenseKeys: params.licenseKeys || params.license,
            },
        ],
    };
};

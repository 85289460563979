import { SignIn } from './sign-in';
import { ForbiddenAccess } from './forbidden-access';
import { Main } from './main';
import { NotFound } from './not-found';
export var pages = {
    SignIn: SignIn,
    ForbiddenAccess: ForbiddenAccess,
    Main: Main,
    NotFound: NotFound,
};

import { CHARTS_TYPES } from '@commonConstants/chartsConstants';
var CPU = CHARTS_TYPES.CPU, RAM = CHARTS_TYPES.RAM, DISK = CHARTS_TYPES.DISK;
export var CHART_DASHBOARDS = {
    containerCloudUsage: 'containerCloudUsage',
};
export var USAGE_OVERVIEW_CHARTS_KEYS = {
    CPU_USAGE_RATIO: 'cpu_usage_ratio',
    RAM_USAGE_RATIO: 'ram_usage_ratio',
    CPU_USAGE_ABS: 'cpu_usage_abs',
    RAM_USAGE_ABS: 'ram_usage_abs',
    DISK_USAGE_ABS: 'disk_usage_abs',
};
export var USAGE_OVERVIEW_CHARTS = [
    {
        key: USAGE_OVERVIEW_CHARTS_KEYS.CPU_USAGE_RATIO,
        usageType: CPU,
        chartType: 'ratio',
    },
    {
        key: USAGE_OVERVIEW_CHARTS_KEYS.CPU_USAGE_ABS,
        usageType: CPU,
        chartType: 'abs',
    },
    {
        key: USAGE_OVERVIEW_CHARTS_KEYS.RAM_USAGE_ABS,
        usageType: RAM,
        chartType: 'abs',
    },
    {
        key: USAGE_OVERVIEW_CHARTS_KEYS.RAM_USAGE_RATIO,
        usageType: RAM,
        chartType: 'ratio',
    },
    {
        key: USAGE_OVERVIEW_CHARTS_KEYS.DISK_USAGE_ABS,
        usageType: DISK,
        chartType: 'abs',
    },
];

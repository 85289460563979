var _a;
export var BANNER_STATUSES = {
    FINE: 'FINE',
    CRITICAL: 'CRITICAL',
    WARNING: 'WARNING',
};
export var WARNING_TYPES = (_a = {},
    _a[BANNER_STATUSES.CRITICAL] = 'error',
    _a[BANNER_STATUSES.WARNING] = 'warning',
    _a[BANNER_STATUSES.FINE] = 'fine',
    _a);
export var CHECK_WARNING_BANNER_TIMEOUT = 1000 * 60 * 15;

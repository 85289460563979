var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
var StyledGreySection = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: transparent;\n    padding: 35px 35px 50px;\n    margin-left: -35px;\n    margin-right: -35px;\n    margin-top: ", ";\n"], ["\n    background: transparent;\n    padding: 35px 35px 50px;\n    margin-left: -35px;\n    margin-right: -35px;\n    margin-top: ", ";\n"])), function (props) { return props.marginTop || '0px'; });
var GreySection = function (_a) {
    var children = _a.children, marginTop = _a.marginTop;
    return (React.createElement(StyledGreySection, { className: "grey-section", marginTop: marginTop }, children));
};
export default GreySection;
var templateObject_1;

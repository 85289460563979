import get from 'lodash/get';
import queryString from 'query-string';
import Redirect from '@commonUtils/urlUtils/Redirect';
import history from '@history/history';
import { URL_QUERIES } from '@commonConstants/urlConstants';
var TELEMETRY_ACTIVE_CLUSTER_ID = URL_QUERIES.TELEMETRY.TELEMETRY_ACTIVE_CLUSTER_ID;
export var getActiveClusterIdFromUrl = function () {
    return get(queryString.parse(history.location.search), TELEMETRY_ACTIVE_CLUSTER_ID, null);
};
export var setActiveClusterIdToUrl = function (activeClusterId) {
    return activeClusterId && Redirect.toCurrentPageWithQueryParams(TELEMETRY_ACTIVE_CLUSTER_ID, [activeClusterId], '');
};

var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { message } from 'antd';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getCities as citiesAPI } from '@api/location';
import { getUsers as getUserAPI, patchUser as patchUserAPI, postUser } from '@api/users-api/usersApi';
import { getCustomersWithLocations, patchCustomer as patchCustomerAPI } from '@api/customers-api/customersApi';
import { getInstances as getInstancesAPI, patchInstance as patchInstanceAPI } from '@api/instance-api/instanceApi';
import { setCities, setUsers, updateUser, pushNewUser, setInstances, updateCustomer, setCustomers, updateInstance, setSingleCustomerInstances, } from '@redux/action-creators/management/management';
import * as Actions from '@redux/actions';
function getCities(action) {
    var params, cities, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                params = {
                    city_prefix: action.payload.city_prefix,
                    limit: action.payload.limit,
                };
                return [4 /*yield*/, call(citiesAPI, params)];
            case 1:
                cities = _a.sent();
                return [4 /*yield*/, put(setCities(cities.data))];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                e_1 = _a.sent();
                console.error('Failed to get list of cities');
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
function patchUser(_a) {
    var res, e_2;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 5]);
                return [4 /*yield*/, call(patchUserAPI, payload)];
            case 1:
                res = _b.sent();
                return [4 /*yield*/, put(updateUser(res))];
            case 2:
                _b.sent();
                return [4 /*yield*/, message.success('User edited successfully')];
            case 3:
                _b.sent();
                return [3 /*break*/, 5];
            case 4:
                e_2 = _b.sent();
                console.error('Failed to patch user');
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function getUsers(_a) {
    var users, e_3;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(getUserAPI, payload)];
            case 1:
                users = _b.sent();
                return [4 /*yield*/, put(setUsers(users))];
            case 2:
                _b.sent();
                return [3 /*break*/, 4];
            case 3:
                e_3 = _b.sent();
                console.error('Failed to get users');
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
function addNewUser(_a) {
    var res, e_4;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 5]);
                return [4 /*yield*/, call(postUser, payload)];
            case 1:
                res = _b.sent();
                return [4 /*yield*/, put(pushNewUser(res))];
            case 2:
                _b.sent();
                return [4 /*yield*/, message.success('User added successfully')];
            case 3:
                _b.sent();
                return [3 /*break*/, 5];
            case 4:
                e_4 = _b.sent();
                console.error('Failed to add new user');
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function getCustomers(_a) {
    var customers, e_5;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(getCustomersWithLocations, payload)];
            case 1:
                customers = _b.sent();
                return [4 /*yield*/, put(setCustomers(customers))];
            case 2:
                _b.sent();
                return [3 /*break*/, 4];
            case 3:
                e_5 = _b.sent();
                console.error('Failed to get customers');
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
function patchCustomer(_a) {
    var res, e_6;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 5]);
                return [4 /*yield*/, call(patchCustomerAPI, payload)];
            case 1:
                res = _b.sent();
                return [4 /*yield*/, put(updateCustomer(res))];
            case 2:
                _b.sent();
                return [4 /*yield*/, message.success('Customer edited successfully')];
            case 3:
                _b.sent();
                return [3 /*break*/, 5];
            case 4:
                e_6 = _b.sent();
                console.error('Failed to patch customers');
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function getInstances(_a) {
    var instances, e_7;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(getInstancesAPI, payload)];
            case 1:
                instances = _b.sent();
                return [4 /*yield*/, put(setInstances(instances))];
            case 2:
                _b.sent();
                return [3 /*break*/, 4];
            case 3:
                e_7 = _b.sent();
                console.error('Failed to get instances');
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
function patchInstance(_a) {
    var res, e_8;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 5]);
                return [4 /*yield*/, call(patchInstanceAPI, payload)];
            case 1:
                res = _b.sent();
                return [4 /*yield*/, put(updateInstance(res))];
            case 2:
                _b.sent();
                return [4 /*yield*/, message.success('Instance edited successfully')];
            case 3:
                _b.sent();
                return [3 /*break*/, 5];
            case 4:
                e_8 = _b.sent();
                console.error('Failed to patch instances');
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function getSingleCustomerInstances(_a) {
    var res, e_9;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(getInstancesAPI, payload)];
            case 1:
                res = _b.sent();
                return [4 /*yield*/, put(setSingleCustomerInstances(res))];
            case 2:
                _b.sent();
                return [3 /*break*/, 4];
            case 3:
                e_9 = _b.sent();
                console.error('Failed to get instances per selected customer_id');
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
export default function managementSaga() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeEvery(Actions.management.AsyncActions.GET_CITIES, getCities)];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeEvery(Actions.management.AsyncActions.GET_USERS, getUsers)];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.management.AsyncActions.PATCH_USER, patchUser)];
            case 3:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(Actions.management.AsyncActions.ADD_NEW_USER, addNewUser)];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.management.AsyncActions.GET_INSTANCES, getInstances)];
            case 5:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.management.AsyncActions.GET_CUSTOMERS, getCustomers)];
            case 6:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.management.AsyncActions.PATCH_CUSTOMER, patchCustomer)];
            case 7:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.management.AsyncActions.PATCH_INSTANCE, patchInstance)];
            case 8:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.management.AsyncActions.GET_SINGLE_CUSTOMER_INSTANCES, getSingleCustomerInstances)];
            case 9: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 10:
                _c.sent();
                return [2 /*return*/];
        }
    });
}

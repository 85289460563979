export var NO_CLUSTERS_FOUND = 'No clusters found';
export var COLUMNS_KEYS = {
    CLUSTER_ID: 'clusterId',
    CLUSTER_NAME: 'clusterName',
    MANAGEMENT_ID: 'managementId',
    REGION_ID: 'regionId',
    CUSTOMER_NAME: 'customerName',
    PREFIX: 'prefix',
    HEALTH_STATUS: 'healthStatus',
    LAST_SYNC: 'lastSync',
    ANNOTATION: 'annotation',
};
export var COLUMNS = [
    {
        name: 'Cluster ID',
        key: COLUMNS_KEYS.CLUSTER_ID,
        sortByKey: 'cluster_id',
    },
    {
        name: 'Cluster',
        key: COLUMNS_KEYS.CLUSTER_NAME,
        sortByKey: 'cluster_name',
        isVisibleByDefault: true,
    },
    {
        name: 'Mgmt Cluster ID',
        key: COLUMNS_KEYS.MANAGEMENT_ID,
        sortByKey: 'management_id',
    },
    {
        name: 'Reg Cluster ID',
        key: COLUMNS_KEYS.REGION_ID,
        sortByKey: 'region_id',
    },
    {
        name: 'Customer',
        key: COLUMNS_KEYS.CUSTOMER_NAME,
        sortByKey: 'customer_name',
        isVisibleByDefault: true,
    },
    {
        name: 'Prefix - (MR)',
        key: COLUMNS_KEYS.PREFIX,
    },
    {
        name: 'Health Status',
        key: COLUMNS_KEYS.HEALTH_STATUS,
        sortByKey: 'health_status',
        isVisibleByDefault: true,
    },
    {
        name: 'Last Sync',
        key: COLUMNS_KEYS.LAST_SYNC,
        sortByKey: 'last_sync',
        isVisibleByDefault: true,
    },
    {
        name: 'Annotation',
        key: COLUMNS_KEYS.ANNOTATION,
        isVisibleByDefault: true,
        isEditable: true,
    },
];

import * as Actions from '@redux/actions';
var r = {};
r[Actions.clusterHealth.SyncActions.SET_CLUSTER_HEALTH] = function (draft, payload) {
    draft.loading = false;
    draft.data = payload.data;
};
r[Actions.clusterHealth.AsyncActions.GET_CLUSTER_HEALTH] = function (draft) {
    draft.loading = true;
};
export { r as resolvers };

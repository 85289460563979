import get from 'lodash/get';
import { HIDDEN_DASHBOARDS_TAGS } from '@commonConstants/permissionsConstants';
export var getNormalizedUserKey = function (id) { return "key_".concat(id); };
export var getNormalizedSfAccounts = function (value) { return value; };
export var getNormalizedPermissionsAllowedPages = function (response) { return ({
    groupsIds: get(response, 'permissions', []),
    tags: get(response, 'dashboards', [])
        .map(function (_a) {
        var items = _a.items;
        return items.map(function (_a) {
            var tag = _a.tag;
            return tag;
        });
    })
        .flat(1),
}); };
export var getNormalizedPermissionUsers = function (response) { return ({
    currentPage: response.current_page,
    maxPages: response.max_pages,
    data: response.data.reduce(function (result, item) {
        result[getNormalizedUserKey(item.id)] = {
            id: item.id,
            email: item.email,
            timezone: item.timezone,
            fullName: item.full_name,
            isActive: item.is_active,
            sfAccountName: item.sf_account_name,
            preferences: {
                timezone: item.preferences.timezone,
            },
        };
        return result;
    }, {}),
}); };
export var getNormalizedPermissionGroupUsers = function (data) {
    return get(data, 'users', []).map(function (_a) {
        var id = _a.id, email = _a.email, name = _a.name;
        return ({
            id: id,
            email: email,
            fullName: name,
        });
    });
};
export var getNormalizedPermissionsGroup = function (dashboardSchema) {
    var dashboards = dashboardSchema.dashboards || dashboardSchema;
    return {
        name: dashboardSchema.name || '',
        dashboards: dashboards.map(function (data) { return ({
            section: data.section,
            items: data.items
                .filter(function (_a) {
                var tag = _a.tag;
                return !HIDDEN_DASHBOARDS_TAGS.includes(tag);
            })
                .map(function (_a) {
                var id = _a.id, tag = _a.tag, is_selected = _a.is_selected, parent_id = _a.parent_id;
                return ({
                    id: id,
                    tag: tag,
                    parentId: parent_id,
                    isSelected: Boolean(is_selected),
                });
            }),
        }); }),
    };
};
export var getNormalizedPermissionGroups = function (response) {
    var data = response.data.map(function (dataItem) { return ({
        id: dataItem.id,
        name: dataItem.name,
        createdAt: dataItem.created_at,
        usersCount: dataItem.users_count,
        isEditable: dataItem.is_editable,
        isAssignable: dataItem.is_assignable,
    }); });
    return {
        data: data,
        maxPages: response.max_pages,
        currentPage: response.current_page,
    };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { usePrevious } from '@app/hooks';
import BarChart from '@commonComponents/chart/bar/Bar';
import { fetchCharts, dropChartsToDefault } from '@redux/dispatchers/charts';
import { getChartOptions, getNormalizedABSYValue, getNormalizedABSTooltipValue, getNormalizedTimeBarChartData, getNormalizedServiceBarChartData, } from '@commonUtils/charts/chartUtils';
import { ALERTS_CHARTS_KEYS, CHART_DASHBOARDS } from './alertsChartsConstants';
import { getAlertsChartsConfigs } from './alertsChartsUtils';
import { StyledAlertsCharts } from './StyledAlertsCharts';
import { StyledChartsWrapperNew } from '@app/common/react/styled/StyledChartsWrapperNew';
var ALERTS_BY_TIME = ALERTS_CHARTS_KEYS.ALERTS_BY_TIME, ALERTS_BY_SERVICE = ALERTS_CHARTS_KEYS.ALERTS_BY_SERVICE;
var AlertsCharts = function () {
    var _a, _b, _c, _d, _e, _f, _g;
    var _h = useState(false), shouldStart = _h[0], setShouldStart = _h[1];
    var _j = useSelector(function (store) { return ({
        customerTypes: store.mode.selected,
        charts: store.charts.alerts,
        multiFilter: store.multiFilter,
        crossPageFilter: store.crossPageFilter,
        lastSyncDate: store.mode.lastSyncDate.selected.value,
        timezone: store.userInfo.userPreferences.timezone,
    }); }), timezone = _j.timezone, charts = _j.charts, customerTypes = _j.customerTypes, multiFilter = _j.multiFilter, crossPageFilter = _j.crossPageFilter, lastSyncDate = _j.lastSyncDate;
    var filter = multiFilter.filter;
    var dateRange = crossPageFilter.dateRange;
    var calculatedDateRange = dateRange.calculatedDateRange;
    var _k = get(filter, 'selected', {}), _l = _k.customer, customer = _l === void 0 ? [] : _l, _m = _k.cluster, cluster = _m === void 0 ? [] : _m, _o = _k.alertStatus, alertStatus = _o === void 0 ? [] : _o, _p = _k.alertPriority, alertPriority = _p === void 0 ? [] : _p;
    var params = useMemo(function () { return ({
        customer: customer,
        cluster: cluster,
        alertStatus: alertStatus,
        alertPriority: alertPriority,
        customerTypes: customerTypes,
        lastSyncDate: lastSyncDate,
        calculatedDateRange: calculatedDateRange,
        timezone: timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
    }); }, [customer, cluster, alertStatus, alertPriority, customerTypes, lastSyncDate, calculatedDateRange, timezone]);
    var prevParams = usePrevious(params);
    var isGeneralLoading = ((_a = charts[ALERTS_BY_TIME]) === null || _a === void 0 ? void 0 : _a.loading) && ((_b = charts[ALERTS_BY_SERVICE]) === null || _b === void 0 ? void 0 : _b.loading);
    useEffect(function () {
        if (shouldStart) {
            fetchCharts(getAlertsChartsConfigs(params));
        }
    }, [shouldStart]);
    useEffect(function () {
        if (multiFilter.name === 'alerts' && !isGeneralLoading && calculatedDateRange) {
            if (!isEqual(params, prevParams)) {
                fetchCharts(getAlertsChartsConfigs(params));
            }
            setShouldStart(true);
        }
    }, [
        params,
        timezone,
        prevParams,
        lastSyncDate,
        customerTypes,
        filter.selected,
        isGeneralLoading,
        multiFilter.name,
        (_c = crossPageFilter === null || crossPageFilter === void 0 ? void 0 : crossPageFilter.dateRange) === null || _c === void 0 ? void 0 : _c.calculatedDateRange,
    ]);
    useEffect(function () {
        return function () {
            dropChartsToDefault(CHART_DASHBOARDS.ALERTS);
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledAlertsCharts, null,
            React.createElement(StyledChartsWrapperNew, { className: "one" },
                React.createElement(BarChart, { loadingOut: (_d = charts[ALERTS_BY_TIME]) === null || _d === void 0 ? void 0 : _d.loading, title: "Alerts By Time", datasetsTitles: ['total'], datasets: [getNormalizedTimeBarChartData((_e = charts[ALERTS_BY_TIME]) === null || _e === void 0 ? void 0 : _e.data)], chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedABSYValue,
                        getNormalizedTooltipValue: getNormalizedABSTooltipValue,
                    }) }))),
        React.createElement(StyledAlertsCharts, null,
            React.createElement(StyledChartsWrapperNew, { className: "one" },
                React.createElement(BarChart, { loadingOut: (_f = charts[ALERTS_BY_SERVICE]) === null || _f === void 0 ? void 0 : _f.loading, isDataLabels: true, maxXRotation: 360, minXRotation: -360, maxXAxisTicksLimit: 100, title: "Alerts By Service", datasets: [getNormalizedServiceBarChartData((_g = charts[ALERTS_BY_SERVICE]) === null || _g === void 0 ? void 0 : _g.data)], chartOptions: __assign(__assign({}, getChartOptions({
                        getNormalizedYValue: getNormalizedABSYValue,
                        getNormalizedTooltipValue: getNormalizedABSTooltipValue,
                    })), { xFormatFunction: function (value) { return value; } }) })))));
};
export default memo(AlertsCharts);

import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { StyledChartsWrapperNew } from '@app/common/react/styled/StyledChartsWrapperNew';
import Line from '@commonComponents/chart/Line/Line';
import { fetchCharts } from '@redux/dispatchers/charts';
import { usePrevious } from '@app/hooks';
import { getUsageOverviewChartsConfigs } from './UsageOverviewUtils';
import { getChartOptions, getNormalizedChartData, getNormalizedABSYValue, getNormalizedABSTooltipValue, getNormalizedPercentageValue, getNormalizedDynamicGbYAxisData, getNormalizedDynamicGbTooltipValue, getNormalizedPercentageTooltipValue, getDynamicValueUnit, } from '@commonUtils/charts/chartUtils';
import { CHART_MESSAGES } from '@commonConstants/chartsConstants';
import { USAGE_OVERVIEW_CHARTS_KEYS } from './usageOverviewChartsConstants';
import { StyledUsageOverviewCharts } from './StyledUsageOverviewCharts';
var CONTAINER_CLOUD_USAGE = DASHBOARDS_SCHEMA.DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE;
var CPU_USAGE_RATIO = USAGE_OVERVIEW_CHARTS_KEYS.CPU_USAGE_RATIO, RAM_USAGE_RATIO = USAGE_OVERVIEW_CHARTS_KEYS.RAM_USAGE_RATIO, CPU_USAGE_ABS = USAGE_OVERVIEW_CHARTS_KEYS.CPU_USAGE_ABS, RAM_USAGE_ABS = USAGE_OVERVIEW_CHARTS_KEYS.RAM_USAGE_ABS, DISK_USAGE_ABS = USAGE_OVERVIEW_CHARTS_KEYS.DISK_USAGE_ABS;
var UsageOverviewCharts = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var _o = useSelector(function (store) { return ({
        timezone: store.userInfo.userPreferences.timezone,
        mode: store.mode.selected,
        lastSyncDate: store.mode.lastSyncDate.selected.value,
        multiFilter: store.multiFilter,
        crossPageFilter: store.crossPageFilter,
        charts: store.charts.containerCloudUsage,
    }); }), timezone = _o.timezone, mode = _o.mode, lastSyncDate = _o.lastSyncDate, multiFilter = _o.multiFilter, crossPageFilter = _o.crossPageFilter, charts = _o.charts;
    var filter = multiFilter.filter, name = multiFilter.name;
    var params = {
        filter: filter,
        mode: mode,
        lastSyncDate: lastSyncDate,
        crossPageFilter: crossPageFilter,
        timezone: timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    var prevParams = usePrevious(params);
    var shouldNotRenderCharts = name !== CONTAINER_CLOUD_USAGE.PSEUDO_CC ||
        isEmpty(crossPageFilter.granularity.selected) ||
        crossPageFilter.dashboardName !== CONTAINER_CLOUD_USAGE.PSEUDO_CC;
    useEffect(function () {
        var _a, _b;
        var shouldFetchCharts = !isEmpty(crossPageFilter.dateRange.calculatedDateRange) &&
            (isEmpty(charts) || !isEqual(prevParams, params)) &&
            ((_b = (_a = crossPageFilter.granularity) === null || _a === void 0 ? void 0 : _a.selected) === null || _b === void 0 ? void 0 : _b.value) &&
            name === CONTAINER_CLOUD_USAGE.PSEUDO_CC &&
            filter.selected;
        if (shouldFetchCharts) {
            fetchCharts(getUsageOverviewChartsConfigs(params));
        }
    }, [
        mode,
        timezone,
        lastSyncDate,
        filter.selected,
        (_b = (_a = crossPageFilter.granularity) === null || _a === void 0 ? void 0 : _a.selected) === null || _b === void 0 ? void 0 : _b.value,
        crossPageFilter.dateRange.calculatedDateRange,
    ]);
    if (isEmpty(charts) || shouldNotRenderCharts) {
        return React.createElement("div", null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", { className: "usage-report-title" }, "Utilization (%)"),
        React.createElement(StyledUsageOverviewCharts, null,
            React.createElement(StyledChartsWrapperNew, { className: "two" },
                React.createElement(Line, { loadingOut: (_c = charts[CPU_USAGE_RATIO]) === null || _c === void 0 ? void 0 : _c.loading, title: "CPU usage", datasets: [getNormalizedChartData((_d = charts[CPU_USAGE_RATIO]) === null || _d === void 0 ? void 0 : _d.data)], message: CHART_MESSAGES.CPU_RATIO_CHART_MESSAGE, chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedPercentageValue(),
                        getNormalizedTooltipValue: getNormalizedPercentageTooltipValue,
                    }) }),
                React.createElement(Line, { loadingOut: (_e = charts[RAM_USAGE_RATIO]) === null || _e === void 0 ? void 0 : _e.loading, title: "RAM usage", datasets: [getNormalizedChartData((_f = charts[RAM_USAGE_RATIO]) === null || _f === void 0 ? void 0 : _f.data)], message: CHART_MESSAGES.RAM_RATIO_CHART_MESSAGE, chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedPercentageValue(),
                        getNormalizedTooltipValue: getNormalizedPercentageTooltipValue,
                    }) }))),
        React.createElement("h3", { className: "usage-report-title" }, "Utilization (Real)"),
        React.createElement(StyledUsageOverviewCharts, null,
            React.createElement(StyledChartsWrapperNew, { className: "three" },
                React.createElement(Line, { loadingOut: (_g = charts[CPU_USAGE_ABS]) === null || _g === void 0 ? void 0 : _g.loading, title: "CPU usage", leftLabel: 'cores', datasets: [getNormalizedChartData((_h = charts[CPU_USAGE_ABS]) === null || _h === void 0 ? void 0 : _h.data)], message: CHART_MESSAGES.CPU_ABS_CHART_MESSAGE, chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedABSYValue,
                        getNormalizedTooltipValue: getNormalizedABSTooltipValue,
                    }) }),
                React.createElement(Line, { loadingOut: (_j = charts[RAM_USAGE_ABS]) === null || _j === void 0 ? void 0 : _j.loading, title: "RAM usage", leftLabel: "bytes", dynamicLabelFormatter: getDynamicValueUnit, datasets: [getNormalizedChartData((_k = charts[RAM_USAGE_ABS]) === null || _k === void 0 ? void 0 : _k.data)], message: CHART_MESSAGES.RAM_ABS_CHART_MESSAGE, chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedDynamicGbYAxisData,
                        getNormalizedTooltipValue: getNormalizedDynamicGbTooltipValue,
                    }) }),
                React.createElement(Line, { loadingOut: (_l = charts[DISK_USAGE_ABS]) === null || _l === void 0 ? void 0 : _l.loading, title: "DISK usage", leftLabel: "bytes", dynamicLabelFormatter: getDynamicValueUnit, datasets: [getNormalizedChartData((_m = charts[DISK_USAGE_ABS]) === null || _m === void 0 ? void 0 : _m.data)], message: CHART_MESSAGES.DISK_ABS_CHART_MESSAGE, chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedDynamicGbYAxisData,
                        getNormalizedTooltipValue: getNormalizedDynamicGbTooltipValue,
                    }) })))));
};
export default memo(UsageOverviewCharts);

import { useSelector } from 'react-redux';
import React, { useLayoutEffect } from 'react';
import { Route, Routes, Router, Navigate } from 'react-router-dom';
import history from '@history/history';
import { useHistory } from '@app/hooks';
import { generalRouters } from '@app/routes';
import RequiredAuth from '@commonComponents/RequiredAuth';
import { getUserRouters } from './pages/main/userDashboardRouterUtils';
var getRoutes = function () {
    return generalRouters.map(function (route) {
        var Component = route.component, path = route.path, _a = route.nestedRoutes, nestedRoutes = _a === void 0 ? [] : _a;
        var element = route.forAuthorized ? React.createElement(RequiredAuth, { component: React.createElement(Component, null) }) : React.createElement(Component, null);
        return (React.createElement(Route, { key: route.path, element: element, path: path }, getUserRouters(nestedRoutes)));
    });
};
var RouterComponent = function () {
    var historyLocal = useHistory();
    var _a = React.useState(false), updateState = _a[1];
    var dirty = useSelector(function (store) { return store.permissions.allowedPagesTags; }).dirty;
    var forceUpdate = React.useCallback(function () { return updateState(dirty); }, []);
    useLayoutEffect(function () { return forceUpdate(); }, [dirty]);
    return (React.createElement(Router, { location: historyLocal.location, navigationType: historyLocal.action, navigator: history },
        React.createElement(Routes, null,
            getRoutes(),
            React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: "/", replace: true }) }))));
};
export default RouterComponent;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { usePrevious } from '@app/hooks';
var StyledRowLimit = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    font-size: 12px;\n\n    .row-limits__title {\n        font-weight: 600;\n    }\n\n    .row-limits__button {\n        font-weight: 600;\n        border: none;\n        background: ", ";\n        color: ", ";\n        margin: 0 10px;\n        padding: 0;\n\n        &.active {\n            cursor: default;\n            color: ", ";\n        }\n    }\n"], ["\n    display: flex;\n    align-items: center;\n    font-size: 12px;\n\n    .row-limits__title {\n        font-weight: 600;\n    }\n\n    .row-limits__button {\n        font-weight: 600;\n        border: none;\n        background: ", ";\n        color: ", ";\n        margin: 0 10px;\n        padding: 0;\n\n        &.active {\n            cursor: default;\n            color: ", ";\n        }\n    }\n"])), function (props) { return props.theme.color.action.tertiary.normal.enabled.background; }, function (props) { return props.theme.color.action.tertiary.normal.enabled.text; }, function (props) { return props.theme.color.action.tertiary.normal.disabled.text; });
var RowLimit = function (_a) {
    var limit = _a.limit, limits = _a.limits, maxPages = _a.maxPages, onLimitChange = _a.onLimitChange;
    // we handle limits here ti avoid limits values jumping when new rows are fetching
    var prevMaxPages = usePrevious(maxPages);
    var prevLimits = usePrevious(limits);
    var finalLimits = prevMaxPages === maxPages ? prevLimits : limits;
    var handleOnLimitChange = function (nextLimit) {
        onLimitChange(nextLimit);
    };
    var buttons = finalLimits.map(function (l) {
        var className = classNames({
            'row-limits__button': true,
            active: l === limit,
        });
        return (React.createElement("button", { key: l, className: className, onClick: function () { return handleOnLimitChange(l); }, "data-testid": "limit_".concat(l) }, l));
    });
    return (React.createElement(StyledRowLimit, { className: "row-limits" },
        React.createElement("div", { className: "row-limits__title" }, "Rows:"),
        React.createElement("div", { className: "row-limits__handler" }, buttons)));
};
export default RowLimit;
var templateObject_1;

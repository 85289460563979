import React from 'react';
import { connect } from 'react-redux';
import { getMultiFilterPickers } from '@configs/multiFilterConfig';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { StringUtils } from '@utils/string/StringUtils';
import { resetSearchValueInUrl } from '@commonComponents/search/searchUtils';
import { changeTermV2 } from '@actionCreators/multiFilter/multiFilter';
import { changeCurrentPage } from '@actionCreators/pagination/pagination';
import FilterContainer from '@commonComponents/filter/FilterContainer';
import Search from '@commonComponents/search/Search';
import { SEARCH_PLACEHOLDER } from '@commonConstants/commonConstants';
import { HIDE_INACTIVE_CUSTOMER_TOOLTIP } from './TelemetryCustomersFilterConstants';
import { StyledTelemetryPickersGroup, StyledTelemetryPickersContainer } from './styled';
var mapState = function (store) { return ({
    mode: store.mode,
    multiFilter: store.multiFilter,
}); };
var mapDispatch = {
    changeTermV2: changeTermV2,
    changeCurrentPage: changeCurrentPage,
};
var connector = connect(mapState, mapDispatch);
var TelemetryCustomers = function (props) {
    var name = DASHBOARDS_SCHEMA.MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.FILTER_PSEUDOS.TELEMETRY_CUSTOMERS;
    var onSearchChange = function (value) {
        var length = StringUtils.countStringLengthWithoutSpaces(value);
        if (value === '' || length > 2) {
            props.changeTermV2({
                tableName: name,
                pseudo: 'searchTerm',
                value: value,
            });
        }
    };
    var resetAdditionalParams = function () {
        resetSearchValueInUrl();
        onSearchChange('');
    };
    return (React.createElement(StyledTelemetryPickersContainer, null,
        React.createElement(Search, { max: 64, withDelay: 1500, label: "Customer search", onChange: onSearchChange, placeholder: SEARCH_PLACEHOLDER }),
        React.createElement(StyledTelemetryPickersGroup, null,
            React.createElement("div", { className: "divider" }),
            React.createElement("div", { className: "top" },
                React.createElement("div", { className: "title mke" }, "MKE customers"),
                React.createElement("div", { className: "title mcc" }, "MCC customers"),
                React.createElement("div", { className: "title hide" }, "Hide inactive customers")),
            React.createElement(FilterContainer, { name: name, params: {}, currentMode: props.mode, multiFilter: props.multiFilter, isOptionsHidden: true, isPaginationOn: true, doNotUnmount: true, doNotUpdate: true, pickers: getMultiFilterPickers(name), pickersWithTooltip: HIDE_INACTIVE_CUSTOMER_TOOLTIP, resetAdditionalParams: resetAdditionalParams, shouldNotResetPickersOnModeChange: props.shouldNotResetPickersOnModeChange }))));
};
export default connector(TelemetryCustomers);

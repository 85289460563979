import get from 'lodash/get';
var errorHandlingMessagesSwitch = function (response, errorMessage) {
    if (errorMessage === void 0) { errorMessage = ''; }
    if (get(response, 'data.message', '').includes('search_term is invalid')) {
        return "Please type at least 3 symbols to search";
    }
    if (errorMessage.includes('Network Error')) {
        return 'Seams you have problems with network';
    }
    return null;
};
export default errorHandlingMessagesSwitch;

import * as Actions from '@redux/actions';
export var getClusters = function (payload) { return ({
    type: Actions.clusters.AsyncActions.GET_CLUSTERS,
    payload: payload,
}); };
export var patchClusterName = function (payload) { return ({
    type: Actions.clusters.AsyncActions.PATCH_CLUSTER_NAME,
    payload: payload,
}); };
export var setClusters = function (payload) { return ({
    type: Actions.clusters.SyncActions.SET_CLUSTERS,
    payload: payload,
}); };
export var updateClusterNameInRedux = function (payload) { return ({
    type: Actions.clusters.SyncActions.UPDATE_CLUSTER_NAME_IN_REDUX,
    payload: payload,
}); };

import { store } from '@redux/store';
import * as actionCreators from '@actionCreators/index';
export var changeCrossPageFilterOption = function (payload) {
    var action = actionCreators.crossPageFilter.changeCrossPageFilterOption(payload);
    store.dispatch(action);
};
export var initCrossPageFilterState = function (payload) {
    var action = actionCreators.crossPageFilter.initCrossPageFilterState(payload);
    store.dispatch(action);
};

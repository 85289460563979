var _a;
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
var HOME = DASHBOARDS_SCHEMA.HOME, ACCOUNT_MANAGEMENT = DASHBOARDS_SCHEMA.ACCOUNT_MANAGEMENT, DASHBOARDS = DASHBOARDS_SCHEMA.DASHBOARDS, REPORTS = DASHBOARDS_SCHEMA.REPORTS, SUPPORT = DASHBOARDS_SCHEMA.SUPPORT, MKE_DASHBOARDS = DASHBOARDS_SCHEMA.MKE_DASHBOARDS;
export var errorHandlingPages = (_a = {},
    _a[HOME.PSEUDO_CC] = HOME.PSEUDO_CC,
    _a[ACCOUNT_MANAGEMENT.PAGES.MANAGE_USERS.PSEUDO_CC] = ACCOUNT_MANAGEMENT.PAGES.MANAGE_USERS.PSEUDO_CC,
    _a[ACCOUNT_MANAGEMENT.PAGES.MANAGE_CUSTOMERS.PSEUDO_CC] = ACCOUNT_MANAGEMENT.PAGES.MANAGE_CUSTOMERS.PSEUDO_CC,
    _a[DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE.PSEUDO_CC] = DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE.PSEUDO_CC,
    _a[DASHBOARDS.PAGES.OPENSTACK_USAGE.PSEUDO_CC] = DASHBOARDS.PAGES.OPENSTACK_USAGE.PSEUDO_CC,
    _a[DASHBOARDS.PAGES.BOOTSTRAP.PSEUDO_CC] = DASHBOARDS.PAGES.BOOTSTRAP.PSEUDO_CC,
    _a[REPORTS.PAGES.INSTALLATION_REPORT.PSEUDO_CC] = REPORTS.PAGES.INSTALLATION_REPORT.PSEUDO_CC,
    _a[REPORTS.PAGES.NEW_SIGN_UPS_REPORT.PSEUDO_CC] = REPORTS.PAGES.NEW_SIGN_UPS_REPORT.PSEUDO_CC,
    _a[REPORTS.PAGES.ACTIVE_INSTANCES.PSEUDO_CC] = REPORTS.PAGES.ACTIVE_INSTANCES.PSEUDO_CC,
    _a[REPORTS.PAGES.TRIAL_REPORT.PSEUDO_CC] = REPORTS.PAGES.TRIAL_REPORT.PSEUDO_CC,
    _a[SUPPORT.PAGES.CLUSTER_HEALTH.PSEUDO_CC] = SUPPORT.PAGES.CLUSTER_HEALTH.PSEUDO_CC,
    _a[SUPPORT.PAGES.ALERTS.PSEUDO_CC] = SUPPORT.PAGES.ALERTS.PSEUDO_CC,
    _a[MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.PSEUDO_CC] = MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.PSEUDO_CC,
    _a);

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { changeTermV2 } from '@root/app/redux/dispatchers/multiFilter';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { PICKER_TYPES } from '@root/app/common/constants/pickerConstants';
import { SEARCH_PLACEHOLDER } from '@root/app/common/constants/commonConstants';
import { REPORT_TITLE, SEARCH_DELAY, SEARCH_LABEL, STATE_OPTIONS, CLUSTER_CREATED_OPTIONS, IS_MIRANTIS_SELECT_OPTIONS, TRIAL_REPORT_STATUS_PICKER, TRIAL_REPORT_IS_MIRANTIS_PICKER, TRIAL_REPORT_CLUSTER_CREATED_PICKER, } from './trialReportConstants';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import Redirect from '@commonUtils/urlUtils/Redirect';
import TrialReportTable from './TrialReportTable';
import Picker from '@commonComponents/picker/Picker';
import Search from '@root/app/common/react/components/search/Search';
import CrossPageFilterContainer from '@root/app/common/react/components/cross-page-filter/CrossPageFilterContainer';
import { StringUtils } from '@root/app/utils/string/StringUtils';
import TrialReportStatsBanner from './TrialReportStatsBanner';
import StyledTrialReport from './StyledTrialReport';
import { Button, actionTypes } from '@mirantis/mds-button';
import { getPickerValueFromUrl, handleRemovePickerValueFromUrl, } from '@root/app/common/react/components/picker/pickerUtils';
var SEARCH_VALUE = URL_QUERIES.COMMON.SEARCH_VALUE;
export var TrialReport = function () {
    var _a = useSelector(function (store) { return store.trialReport; }), data = _a.data, loading = _a.loading;
    var multiFilter = useSelector(function (store) { return store.multiFilter; });
    var crossPageFilter = useSelector(function (store) { return store.crossPageFilter; });
    var pagination = useSelector(function (store) { return store.pagination.trialReport; });
    var timezone = useSelector(function (store) { return store.userInfo.userPreferences.timezone; });
    var _b = useState(getPickerValueFromUrl(TRIAL_REPORT_STATUS_PICKER.pseudo)), statusesSelected = _b[0], setStatusesSelected = _b[1];
    var _c = useState(getPickerValueFromUrl(TRIAL_REPORT_IS_MIRANTIS_PICKER.pseudo)), isMirantisSelected = _c[0], setIsMirantisSelected = _c[1];
    var _d = useState(getPickerValueFromUrl(TRIAL_REPORT_CLUSTER_CREATED_PICKER.pseudo)), clusterCreatedSelected = _d[0], setClusterCreatedSelect = _d[1];
    var stateOptions = {
        data: STATE_OPTIONS,
        lazyLoad: false,
    };
    var isMirantisSelectOptions = {
        data: IS_MIRANTIS_SELECT_OPTIONS,
        lazyLoad: false,
    };
    var clusterCreatedOptions = {
        data: CLUSTER_CREATED_OPTIONS,
        lazyLoad: false,
    };
    var onSearchChange = function (value) {
        var length = StringUtils.countStringLengthWithoutSpaces(value);
        if (value === '' || length > 2) {
            changeTermV2({
                tableName: DASHBOARDS_SCHEMA.REPORTS.PAGES.TRIAL_REPORT.PSEUDO_CC,
                pseudo: 'searchTerm',
                value: value,
            });
        }
    };
    var onResetClicked = function () {
        Redirect.toCurrentPageWithoutQueryParams([SEARCH_VALUE]);
        changeTermV2({
            tableName: DASHBOARDS_SCHEMA.REPORTS.PAGES.TRIAL_REPORT.PSEUDO_CC,
            pseudo: 'searchTerm',
            value: '',
        });
        setStatusesSelected([]);
        setIsMirantisSelected([]);
        setClusterCreatedSelect([]);
        handleRemovePickerValueFromUrl([
            TRIAL_REPORT_STATUS_PICKER.pseudo,
            TRIAL_REPORT_IS_MIRANTIS_PICKER.pseudo,
            TRIAL_REPORT_CLUSTER_CREATED_PICKER.pseudo,
        ]);
    };
    var handlePickerStatusApply = function (e, options) {
        return setStatusesSelected(options);
    };
    var handlePickerIsMirantisApply = function (e, options) { return setIsMirantisSelected(options); };
    var handlePickerClusterCreatedApply = function (e, options) {
        return setClusterCreatedSelect(options);
    };
    return (React.createElement(StyledTrialReport, null,
        React.createElement("h2", { className: "trial-report-table-title" }, REPORT_TITLE),
        React.createElement("div", { className: "trial-report-table-filters" },
            React.createElement(Search, { label: SEARCH_LABEL, placeholder: SEARCH_PLACEHOLDER, withDelay: SEARCH_DELAY, max: 64, onChange: onSearchChange }),
            React.createElement(CrossPageFilterContainer, { dashboardName: DASHBOARDS_SCHEMA.REPORTS.PAGES.TRIAL_REPORT.PSEUDO_CC, history: history }),
            React.createElement(Picker, { selected: statusesSelected, options: stateOptions, picker: TRIAL_REPORT_STATUS_PICKER, handlePickerApply: handlePickerStatusApply }),
            React.createElement(Picker, { selected: isMirantisSelected, options: isMirantisSelectOptions, picker: TRIAL_REPORT_IS_MIRANTIS_PICKER, handlePickerApply: handlePickerIsMirantisApply, type: PICKER_TYPES.SINGLE }),
            React.createElement(Picker, { selected: clusterCreatedSelected, options: clusterCreatedOptions, picker: TRIAL_REPORT_CLUSTER_CREATED_PICKER, handlePickerApply: handlePickerClusterCreatedApply, type: PICKER_TYPES.SINGLE }),
            React.createElement("div", { className: "trial-report-table-filters__button_place" },
                React.createElement(Button, { className: "trial-report-table-filters__button", label: "Reset", 
                    //@ts-ignore
                    actionType: actionTypes.PRIMARY, onClick: onResetClicked }))),
        React.createElement(TrialReportStatsBanner, null),
        React.createElement(TrialReportTable, { data: data, loading: loading, timezone: timezone, pagination: pagination, multiFilter: multiFilter, crossPageFilter: crossPageFilter, statusesSelected: statusesSelected, isMirantisSelected: isMirantisSelected, clusterCreatedSelected: clusterCreatedSelected, columnControl: true })));
};
export default TrialReport;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { connect } from 'react-redux';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import OverallStatistic from './OverallStatistic';
var PSEUDO_CC = DASHBOARDS_SCHEMA.DASHBOARDS.PAGES.BOOTSTRAP.PSEUDO_CC;
var BootstrapOverallStatisticContainer = function (props) {
    if (props.multiFilter.name !== PSEUDO_CC || props.crossPageFilter.dashboardName !== PSEUDO_CC) {
        return null;
    }
    return React.createElement(OverallStatistic, __assign({}, props));
};
var mapStateToProps = function (store) { return ({
    mode: store.mode,
    multiFilter: store.multiFilter,
    crossPageFilter: store.crossPageFilter,
}); };
export default connect(mapStateToProps)(BootstrapOverallStatisticContainer);

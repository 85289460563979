var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import toString from 'lodash/toString';
import { getFormattedNumberAfterDot } from '@commonUtils/commonUtils';
import { DateUtils } from '@utils/date/DateUtils';
import { X_AXIS_VALUE_FORMAT_WITHOUT_HOER } from '@commonConstants/chartsConstants';
import { StyledChartsWrapper } from '@app/common/react/styled/StyledAvailability';
export var getFinalTableConfig = function (data, columns, fields, dateKey) { return ({
    columns: columns,
    rows: data.map(function (row) { return (__assign({ date: DateUtils.getDateLocalStringAutoTimezoneHandler(row[dateKey], X_AXIS_VALUE_FORMAT_WITHOUT_HOER) }, fields.reduce(function (result, item) {
        result[item] = toString(row[item]);
        return result;
    }, {}))); }),
}); };
export var getNormalizers = function (fields) {
    return fields.reduce(function (result, item) {
        result[item] = function (value) {
            if (value.length === 0) {
                return '-';
            }
            var multipliedValue = value * 100;
            return (React.createElement(StyledChartsWrapper, { value: multipliedValue }, getFormattedNumberAfterDot(multipliedValue) + ' %'));
        };
        return result;
    }, {});
};

export var NO_NEW_SIGN_UPS_FOUND = 'No New Sign-Ups found';
export var CHART_TEXT = {
    TITLE: 'New Sign-Ups',
    MESSAGE: 'Number of employees and non-employees signups',
};
export var COLUMNS = [
    {
        name: 'ID',
        key: 'id',
        sortByKey: 'id',
        isVisibleByDefault: true,
    },
    {
        name: 'Name',
        key: 'name',
        sortByKey: 'name',
        isVisibleByDefault: true,
    },
    {
        name: 'Email',
        key: 'email',
        sortByKey: 'email',
        isVisibleByDefault: true,
    },
    {
        name: 'Company',
        key: 'company',
        sortByKey: 'company',
        isVisibleByDefault: true,
    },
    {
        name: 'Customer Type',
        key: 'customerType',
        sortByKey: 'customer_type',
        isVisibleByDefault: true,
    },
    {
        name: 'Created At',
        key: 'createdAt',
        sortByKey: 'created_at',
        isVisibleByDefault: true,
    },
];
export var CHART_DASHBOARDS = {
    signUps: 'signUps',
};
export var SIGN_UPS_CHARTS_KEYS = {
    SIGN_UPS: 'sign_ups',
};
export var SIGN_UPS_CHARTS = [
    {
        key: SIGN_UPS_CHARTS_KEYS.SIGN_UPS,
    },
];

import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import postscribe from 'postscribe';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import Redirect from '@commonUtils/urlUtils/Redirect';
import { getTelemetryCustomer } from '@actionCreators/telemetry';
import { getIssueCollectorScript, ROOT_ELEMENT_FOR_COLLECTOR } from '@configs/issue-collector';
import { initCrossPageFilterState, changeCrossPageFilterOption } from '@actionCreators/crossPageFilter';
import { usePrevious } from '@app/hooks';
import { isSFPermissionGroup } from '@commonUtils/commonUtils';
import StatsBarWithTags from '@commonComponents/stats-bar-with-tags/StatsBarWithTags';
import { getActiveClusterIdFromUrl } from '@commonComponents/telemetry-clusters-table-with-detail-view/TelemetryClustersTableWithDetailViewUtils';
import ActiveClustersToggle from '@components/telemetry-dashboard/activeClustersToggle/ActiveClustersToggle';
import ErrorsLoading from '@components/telemetry-dashboard/ErrorsLoading';
import { getUrlWithoutParams } from '@commonUtils/urlUtils/urlUtils';
import DrawerComponent from '@commonComponents/drawer/Drawer';
import GeneratePdfButton from '@commonComponents/generate-pdf-button/GeneratePdfButton';
import { CLUSTER_SOURCE_TYPES } from '@components/telemetry-dashboard/telemetryCustomerConstants';
import { STATES } from '@commonComponents/generate-pdf-button/generatePdfConstants';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import TelemetryBackButton from './TelemetryBackButton';
import ManageClustersLink from '../../../ManageClustersLink';
import MkeTelemetryTableWrapper from './mke-telemetry-customer/MkeTelemetryTableWrapper';
import MccTelemetryTableWrapper from './mcc-telemetry-customer/MccTelemetryTableWrapper';
import TelemetryManageClusters from './telemetry-manage-cluster/TelemetryManageClusters';
import { resolveHiddenParamValue } from '../../../commonTelemetryDashboardUtils';
import { handlePdfGeneratingModeChanged, handlePdfGeneratingClusterOnchange, setCheckedIsActiveClustersToUrl, getCheckedIsActiveClustersFromUrl, } from './telemetryCustomerUtils';
import { BLURED_COLUMNS_KEYS } from './telemetryCustomerConstants';
import { StyledDescription, StyledGeneratePdfButton } from './styled';
import { GeneratePdfCModeContext, GeneratePdfClusterIdsContext, } from '@commonComponents/gropped-column-table/RowControls';
import { getFilterDateChangeConfig } from '@commonComponents/cross-page-filter/crossPageFilterUtils';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { CROSS_PAGE_FILTER_CONFIG } from '@configs/crossPageFilterInitialState';
var PSEUDO_CC = DASHBOARDS_SCHEMA.MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.PSEUDO_CC;
var MKE = CLUSTER_SOURCE_TYPES.MKE;
var _a = URL_QUERIES.TELEMETRY, TELEMETRY_MANAGE_CLUSTER_LIST = _a.TELEMETRY_MANAGE_CLUSTER_LIST, TELEMETRY_ACTIVE_CLUSTER_ID = _a.TELEMETRY_ACTIVE_CLUSTER_ID;
var mapState = function (store) { return ({
    timezone: store.userInfo.userPreferences.timezone,
}); };
var mapDispatch = {
    getTelemetryCustomer: getTelemetryCustomer,
    initCrossPageFilterState: initCrossPageFilterState,
    changeCrossPageFilterOption: changeCrossPageFilterOption,
};
var connector = connect(mapState, mapDispatch);
var TelemetryCustomerWrapper = function (props) {
    var _a, _b, _c;
    var isSfUser = isSFPermissionGroup();
    var hidden = resolveHiddenParamValue(props.manageClusterListPage, isSfUser);
    var _d = useSelector(function (store) { return get(store, 'telemetry.customer', {}); }), sfAccount = _d.sfAccount, mccOverview = _d.mccOverview;
    var timezone = useSelector(function (store) { return get(store, 'userInfo.userPreferences.timezone'); });
    var crossPageFilter = useSelector(function (store) { return store.crossPageFilter; });
    var _e = useState(getActiveClusterIdFromUrl()), activeClusterId = _e[0], setActiveClusterId = _e[1];
    var _f = useState(undefined), inactiveHoursThreshold = _f[0], setClustersInactiveHoursThreshold = _f[1];
    var _g = useState(STATES.START_SELECTING), pdfGeneratingMode = _g[0], setPdfGeneratingMode = _g[1];
    var generatePdfClusterIdsRef = useRef([]);
    var _h = useState([]), generatePdfClusterIds = _h[0], setGeneratePdfClusterIds = _h[1];
    var _j = useState(["mcc_table".concat(Math.random()), "mke_table".concat(Math.random())]), tableKeys = _j[0], setTableKeys = _j[1];
    var _k = useState((_a = JSON.parse(getCheckedIsActiveClustersFromUrl())) !== null && _a !== void 0 ? _a : true), checkedActive = _k[0], toggleCheckedActive = _k[1];
    useEffect(function () {
        handlePdfGeneratingModeChanged(pdfGeneratingMode, generatePdfClusterIdsRef, setPdfGeneratingMode, setGeneratePdfClusterIds, setTableKeys);
    }, [pdfGeneratingMode]);
    var error = props.customer.error;
    var shouldShowNoPermissionsError = error && error.type === 'sf_customer' && isSfUser;
    var params = {
        hidden: hidden,
        inactiveHoursThreshold: inactiveHoursThreshold,
        account_id: props.accountId.toString(),
        timezone: props.timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
        is_historical_data: checkedActive ? undefined : true,
        start: checkedActive ? undefined : (_b = crossPageFilter.dateRange.calculatedDateRange) === null || _b === void 0 ? void 0 : _b.start.timeStamp,
        end: checkedActive ? undefined : (_c = crossPageFilter.dateRange.calculatedDateRange) === null || _c === void 0 ? void 0 : _c.end.timeStamp,
    };
    useEffect(function () {
        if (isSfUser)
            postscribe(ROOT_ELEMENT_FOR_COLLECTOR, getIssueCollectorScript());
    }, []);
    var prevParams = usePrevious(params);
    useEffect(function () {
        var _a, _b, _c;
        var startDayMoment = moment((_a = crossPageFilter.dateRange.calculatedDateRange) === null || _a === void 0 ? void 0 : _a.start.timeStamp);
        var endDayMoment = moment((_b = crossPageFilter.dateRange.calculatedDateRange) === null || _b === void 0 ? void 0 : _b.end.timeStamp);
        var isCurrentDayAndDiffLessThanOneDay = Math.abs(startDayMoment.diff(endDayMoment, 'days')) < 1 &&
            moment().isSame((_c = crossPageFilter.dateRange.calculatedDateRange) === null || _c === void 0 ? void 0 : _c.start.timeStamp, 'day');
        var shouldGetTelemetryCustomer = !isEqual(prevParams, params) && (inactiveHoursThreshold === null || !isNil(inactiveHoursThreshold));
        var shouldNotGetTelemetryCustomer = (inactiveHoursThreshold && params.is_historical_data) ||
            (!inactiveHoursThreshold && !params.is_historical_data);
        if (shouldGetTelemetryCustomer && !shouldNotGetTelemetryCustomer) {
            if (isCurrentDayAndDiffLessThanOneDay && !checkedActive) {
                return toggleCheckedActive(true);
            }
            props.getTelemetryCustomer(params);
        }
    }, [crossPageFilter, checkedActive, params, timezone]);
    useEffect(function () {
        var _a;
        var start = (_a = crossPageFilter.dateRange.calculatedDateRange) === null || _a === void 0 ? void 0 : _a.start;
        var endOfToday = moment().endOf('day').valueOf();
        var diff = endOfToday - (start === null || start === void 0 ? void 0 : start.timeStamp);
        var diffInHours = diff / 1000 / 60 / 60;
        if (diffInHours > 24) {
            toggleCheckedActive(false);
        }
    }, [crossPageFilter]);
    var onCloseDrawer = function () {
        return Redirect.goTo(getUrlWithoutParams([TELEMETRY_MANAGE_CLUSTER_LIST, TELEMETRY_ACTIVE_CLUSTER_ID]));
    };
    var handleSetActiveClusterId = function (clusterId) {
        if ([STATES.START_GENERATING, STATES.GENERATING_IN_PROGRESS].includes(pdfGeneratingMode)) {
            return;
        }
        setActiveClusterId(clusterId);
    };
    var onClickActiveClusters = function () {
        toggleCheckedActive(function (checkedActiveState) {
            if (!checkedActiveState) {
                var config = CROSS_PAGE_FILTER_CONFIG[PSEUDO_CC];
                props.changeCrossPageFilterOption(getFilterDateChangeConfig(URL_QUERIES.COMMON.DATE_RANGE, config.dateRange.selected, config.dateRange.calculatedDateRange));
            }
            return !checkedActiveState;
        });
    };
    useEffect(function () {
        var checkedNodesFromUrl = getCheckedIsActiveClustersFromUrl();
        if (checkedNodesFromUrl === 'true') {
            toggleCheckedActive(true);
        }
    }, []);
    useEffect(function () {
        if (checkedActive) {
            setCheckedIsActiveClustersToUrl('true');
            setClustersInactiveHoursThreshold(24);
        }
        else {
            setCheckedIsActiveClustersToUrl('false');
            setClustersInactiveHoursThreshold(null);
        }
    }, [checkedActive]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerComponent, { currentPlacement: "right", width: "100%", onClose: onCloseDrawer, visible: props.manageClusterListPage }, props.manageClusterListPage ? (React.createElement(TelemetryManageClusters, { setActiveClusterId: handleSetActiveClusterId, manageClusterListPage: props.manageClusterListPage })) : null),
        React.createElement("div", { className: "telemetry-customer-container" },
            !isSfUser ? (React.createElement(React.Fragment, null,
                React.createElement(TelemetryBackButton, null),
                React.createElement(StyledGeneratePdfButton, null,
                    React.createElement(GeneratePdfButton, { disabled: pdfGeneratingMode === STATES.START_GENERATING && generatePdfClusterIds.length === 0, onChange: function (value) { return setPdfGeneratingMode(value); }, pdfGeneratingMode: pdfGeneratingMode })))) : (''),
            React.createElement(ActiveClustersToggle, { name: sfAccount === null || sfAccount === void 0 ? void 0 : sfAccount.name, onClickActiveClusters: onClickActiveClusters, checkedActive: checkedActive, history: props.history }),
            React.createElement(ErrorsLoading, { error: error, shouldShowNoPermissionsError: shouldShowNoPermissionsError },
                React.createElement(React.Fragment, null,
                    React.createElement(StyledDescription, { className: "description" },
                        "ID: ", sfAccount === null || sfAccount === void 0 ? void 0 :
                        sfAccount.id),
                    React.createElement(StatsBarWithTags, { mccOverview: mccOverview }),
                    React.createElement(GeneratePdfClusterIdsContext.Provider, { value: generatePdfClusterIds },
                        React.createElement(GeneratePdfCModeContext.Provider, { value: pdfGeneratingMode === STATES.START_GENERATING },
                            React.createElement(MccTelemetryTableWrapper, { key: tableKeys[0], pdfGeneratingClusterOnchange: function (id) {
                                    return handlePdfGeneratingClusterOnchange(id, generatePdfClusterIdsRef, setGeneratePdfClusterIds);
                                }, activeClusterId: activeClusterId, generatePdfClusterIds: generatePdfClusterIds, setActiveClusterId: handleSetActiveClusterId, checkedActive: checkedActive }),
                            React.createElement(MkeTelemetryTableWrapper, { key: tableKeys[1], pdfGeneratingClusterOnchange: function (id) {
                                    return handlePdfGeneratingClusterOnchange(id, generatePdfClusterIdsRef, setGeneratePdfClusterIds);
                                }, activeClusterId: activeClusterId, generatePdfClusterIds: generatePdfClusterIds, setActiveClusterId: handleSetActiveClusterId, bottomElement: React.createElement(ManageClustersLink, { type: MKE }), checkedActive: checkedActive, bluredColumnsKeys: checkedActive ? [] : BLURED_COLUMNS_KEYS }))))))));
};
export default connector(TelemetryCustomerWrapper);

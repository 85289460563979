import { successRequestsSideEffects, failureRequestsSideEffects, successResponseSideEffects, failureResponseSideEffects, } from './axiosInterceptSideEffects';
var interceptRequest = function (instance) {
    return instance.interceptors.request.use(function (config) {
        successRequestsSideEffects.forEach(function (sideEffect) { return sideEffect(config); });
        return config;
    }, function (error) {
        failureRequestsSideEffects.forEach(function (sideEffect) { return sideEffect(error); });
        Promise.reject(error);
    });
};
var interceptResponse = function (instance) {
    return instance.interceptors.response.use(function (response) {
        successResponseSideEffects.forEach(function (sideEffect) { return sideEffect(response); });
        return response;
    }, function (error) {
        failureResponseSideEffects.forEach(function (sideEffect) { return sideEffect(error); });
        Promise.reject(error);
    });
};
export var interceptRequests = function (instance) {
    interceptRequest(instance);
    interceptResponse(instance);
};

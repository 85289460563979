import React from 'react';
import Alerts from './Alerts';
import Recommendations from './Recommendations';
import MccClusterPdfPage from './MccClusterPdfPage';
import MkeClusterPdfPage from './MkeClusterPdfPage';
import GeneralInfoPdfPage from './GeneralInfoPdfPage';
import AvailabilityPdfPage from './AvailabilityPdfPage';
var PdfMurkup = function (_a) {
    var mccClusters = _a.mccClusters, mkeClusters = _a.mkeClusters, isActiveToggleOn = _a.isActiveToggleOn, calculatedDateRange = _a.calculatedDateRange;
    return (React.createElement(React.Fragment, null,
        React.createElement(GeneralInfoPdfPage, { calculatedDateRange: calculatedDateRange, isActiveToggleOn: isActiveToggleOn, mkeClusters: mkeClusters, mccClusters: mccClusters }),
        mccClusters.map(function (cluster) { return (React.createElement(React.Fragment, null,
            React.createElement(MccClusterPdfPage, { cluster: cluster, key: cluster.clusterId }),
            React.createElement(AvailabilityPdfPage, { cluster: cluster, key: "availability-cluster-".concat(cluster.clusterId) }))); }),
        mkeClusters.map(function (cluster) { return (React.createElement("div", { key: "cluster-container-".concat(cluster.clusterId) },
            React.createElement(MkeClusterPdfPage, { cluster: cluster, key: "cluster-".concat(cluster.clusterId), isActiveToggleOn: isActiveToggleOn }),
            React.createElement(Alerts, { activeClusterId: cluster.clusterId, key: "alerts-".concat(cluster.clusterId) }),
            React.createElement(Recommendations, { activeClusterId: cluster.clusterId, clusterLicense: cluster.license, key: "recommendations-".concat(cluster.clusterId) }))); })));
};
export default PdfMurkup;

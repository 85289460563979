import React, { useState } from 'react';
import { useDebouncedEffect } from '@app/hooks';
import Table from '@commonComponents/table/Table';
import PaginationContainer from '@commonComponents/pagination/PaginationContainer';
import { PAGINATION_NAMES } from '@configs/DASHBOARD_PSEUDOS';
import { INITIAL_STATE, COLUMNS_USERS_GROUP, NO_ACTIVE_USER_GROUPS_FOUND } from './permissionGroupsConstants';
import { fetchPermissionGroups } from '@redux/dispatchers/permissions';
import { changeCurrentPage } from '@redux/dispatchers/pagination';
import { normalizers, getCallApiParams } from './permissionGroupsUtils';
import PermissionGroupControlPopover from './PermissionGroupControlPopover';
import TableLoadingStates from '@commonComponents/table-loading-states/TableLoadingStates';
var PermissionGroupsTable = function (props) {
    var pagination = props.pagination, multiFilter = props.multiFilter, crossPageFilter = props.crossPageFilter, loading = props.loading, timezone = props.timezone;
    var _a = useState({
        sortBy: INITIAL_STATE.sortBy,
        orderBy: INITIAL_STATE.orderBy,
    }), state = _a[0], setState = _a[1];
    var data = {
        columns: COLUMNS_USERS_GROUP,
        rows: props.data || [],
    };
    var callAPI = function () {
        var params = getCallApiParams(pagination, multiFilter, state, timezone);
        fetchPermissionGroups(params);
    };
    useDebouncedEffect(function () {
        callAPI();
    }, [state, pagination.limit, pagination.currentPage, multiFilter.filter.searchTerm, crossPageFilter, timezone]);
    var onPaginationChange = function (page) {
        changeCurrentPage({
            paginationName: PAGINATION_NAMES.USER_GROUPS,
            currentPage: page,
        });
    };
    var onLimitChange = function (limit) {
        changeCurrentPage({
            paginationName: PAGINATION_NAMES.USER_GROUPS,
            currentPage: 1,
            limit: limit,
        });
    };
    var extraCustomColumns = [
        {
            key: 'moreIcon',
            getComponent: function (id) { return React.createElement(PermissionGroupControlPopover, { config: state, id: id }); },
        },
    ];
    return (React.createElement("div", { className: "user-groups-table" },
        React.createElement(TableLoadingStates, { data: (data === null || data === void 0 ? void 0 : data.rows) || [], count: pagination.limit, loading: loading, empty: {
                description: NO_ACTIVE_USER_GROUPS_FOUND,
            } },
            React.createElement(React.Fragment, null,
                React.createElement(Table, { sortable: true, data: data, orderBy: state.orderBy, sortBy: state.sortBy, setSortableState: function (s) { return setState(s); }, normalizers: normalizers, extraCustomColumns: extraCustomColumns, columnControl: true }),
                React.createElement(PaginationContainer, { withLimits: true, onLimitChange: onLimitChange, pagination: pagination, onChange: onPaginationChange })))));
};
export default PermissionGroupsTable;

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import uudi from 'react-uuid';
import get from 'lodash/get';
import remove from 'lodash/remove';
import isEqual from 'lodash/isEqual';
import { Input } from '@mirantis/mds-input';
import { ExpandIcon, variants } from '@mirantis/mds-icon';
import { Checkbox, checkValues } from '@mirantis/mds-checkbox';
import { Button, actionTypes, appearances } from '@mirantis/mds-button';
import InfiniteScroll from 'react-infinite-scroll-component';
import { usePrevious } from '@app/hooks';
import { StringUtils } from '@utils/string/StringUtils';
import { PICKER_TYPES } from '@commonConstants/pickerConstants';
import { SEARCH_PLACEHOLDER } from '@commonConstants/commonConstants';
import StyledPicker from './StyledPicker';
import { isValueActive, resolveOptions, handleSetPickerValueToUrl, handleRemovePickerValueFromUrl, } from './pickerUtils';
var timeout = null;
var Search = function (_a) {
    var handleSearch = _a.handleSearch, term = _a.term;
    var _b = useState(''), value = _b[0], setValue = _b[1];
    var prevValue = usePrevious(value);
    useEffect(function () {
        var length = StringUtils.countStringLengthWithoutSpaces(value);
        if (typeof prevValue !== 'undefined' && value !== prevValue && (length > 2 || value === '')) {
            clearTimeout(timeout);
            timeout = setTimeout(function () { return handleSearch(value); }, 1000);
        }
    }, [value]);
    useEffect(function () { return function () {
        clearInterval(timeout);
    }; }, []);
    useEffect(function () {
        if (term !== '') {
            handleSearch(null);
        }
    }, []);
    var handleSetValue = function (nextValue) {
        if (nextValue.length > 64) {
            return;
        }
        setValue(nextValue);
    };
    return (React.createElement("div", { className: "picker__search" },
        React.createElement(Input, { showHelp: false, "data-testid": "input_search_test_id", style: { width: '100%' }, className: "filter__input", label: "Search", placeholder: SEARCH_PLACEHOLDER, showPlaceholder: true, value: value, onChange: function (e) { return handleSetValue(e.target.value); }, onClear: function () { return handleSetValue(''); }, clearable: true })));
};
var Options = function (_a) {
    var innerState = _a.innerState, options = _a.options, handleChangeOptions = _a.handleChange, loadMore = _a.loadMore;
    var prevOptions = usePrevious(options.data);
    var _b = useState(options.data), newOptions = _b[0], setNewOptions = _b[1];
    useEffect(function () {
        setNewOptions(resolveOptions(newOptions, innerState));
    }, []);
    useEffect(function () {
        if (prevOptions && options.data.length !== prevOptions.length) {
            setNewOptions(resolveOptions(options.data, innerState));
        }
    }, [options]);
    var raw = newOptions.map(function (option) {
        var isOn = isValueActive(option, innerState);
        return (React.createElement(Checkbox, { key: uudi(), label: typeof option === 'string' ? option : option.name || '–', value: isOn ? checkValues.MIXED : checkValues.UNCHECKED, onChange: function () { return handleChangeOptions(option); } }));
    });
    var changeCurrentPage = function () {
        loadMore(options.currentPage + 1);
    };
    return (React.createElement("div", { style: { padding: '5px 0' } },
        React.createElement(InfiniteScroll, { height: 230, dataLength: newOptions.length, next: changeCurrentPage, hasMore: options.currentPage < options.maxPages, loader: React.createElement("h4", null, "Loading...") },
            React.createElement("div", { style: { padding: '0 4px' } }, raw))));
};
var Dropdown = function (_a) {
    var dropdownOptions = _a.options, picker = _a.picker, selected = _a.selected, handlePickerApply = _a.handlePickerApply, handleSearchTerm = _a.handleSearchTerm, handleChangeCurrentPage = _a.handleChangeCurrentPage, setOpened = _a.setOpened, type = _a.type;
    var _b = useState(selected), innerState = _b[0], setInnerState = _b[1];
    var ref = React.useRef(null);
    var handleClick = function (evt) {
        if (ref && ref.current) {
            if (!ref.current.contains(evt.target)) {
                setOpened(false);
            }
        }
    };
    var term = get(dropdownOptions, get(picker, 'termPseudo.termPseudoCC', null), '');
    var handleSearch = function (value) {
        handleSearchTerm(picker.pseudo, picker.termPseudo, value);
    };
    React.useEffect(function () {
        window.addEventListener('mousedown', handleClick);
        return function () {
            window.removeEventListener('mousedown', handleClick);
        };
    }, []);
    var handleChangeOption = function (args) {
        var stateClone = __spreadArray([], innerState, true);
        if (stateClone.some(function (o) { return isEqual(o, args); })) {
            remove(stateClone, function (o) { return isEqual(o, args); });
        }
        else if (type === PICKER_TYPES.SINGLE) {
            stateClone = [args];
        }
        else {
            stateClone.push(args);
        }
        setInnerState(stateClone);
    };
    var clear = function () {
        setInnerState([]);
        handleRemovePickerValueFromUrl(picker.pseudo);
    };
    var handleApply = function () {
        setOpened(false);
        handlePickerApply(picker, innerState);
        innerState.length && handleSetPickerValueToUrl(picker.pseudo, innerState);
    };
    var loadMore = function (currentPage) {
        handleChangeCurrentPage(picker.pseudo, currentPage);
    };
    return (React.createElement("div", { ref: ref, className: "picker__dropdown" },
        dropdownOptions.lazyLoad ? React.createElement(Search, { handleSearch: handleSearch, term: term }) : null,
        React.createElement(Button, { "data-testid": "clear_SVG_test_id", label: "Clear", 
            //@ts-ignore
            actionType: actionTypes.TERTIARY, 
            //@ts-ignore
            appearance: appearances.COMPACT, onClick: clear }),
        dropdownOptions.data.length === 0 ? (React.createElement("div", { style: { padding: '15px 0' } }, "No available options")) : (React.createElement(Options, { innerState: innerState, options: dropdownOptions, handleChange: handleChangeOption, loadMore: loadMore })),
        React.createElement(Button, { className: "picker__apply", label: "Apply", onClick: handleApply, style: { marginTop: 20 } })));
};
var Picker = function (_a) {
    var picker = _a.picker, pickerOptions = _a.options, selected = _a.selected, handlePickerApply = _a.handlePickerApply, _b = _a.handleSearchTerm, handleSearchTerm = _b === void 0 ? null : _b, _c = _a.handleChangeCurrentPage, handleChangeCurrentPage = _c === void 0 ? null : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, _e = _a.type, type = _e === void 0 ? null : _e;
    var _f = useState(false), opened = _f[0], setOpened = _f[1];
    var className = classNames(picker.key, {
        picker: true,
        disabled: disabled,
    });
    var handleOpen = function (newOpened) {
        if (disabled) {
            return;
        }
        setOpened(newOpened);
    };
    var renderDropdown = function () {
        return opened ? (React.createElement(Dropdown, { options: pickerOptions, picker: picker, selected: selected, handlePickerApply: handlePickerApply, handleSearchTerm: handleSearchTerm, handleChangeCurrentPage: handleChangeCurrentPage, setOpened: handleOpen, type: type })) : null;
    };
    var getSelectedText = function () {
        if (selected.length === 1) {
            return typeof selected[0] === 'string' ? selected[0] : selected[0].name;
        }
        return selected.length === 0 ? 'All' : "Multiple (".concat(selected.length, ")");
    };
    return (React.createElement(StyledPicker, { className: className },
        React.createElement("label", { className: "picker__label" },
            picker.name,
            ":"),
        React.createElement("div", { className: "picker__select", onClick: function () { return handleOpen(!opened); } },
            React.createElement("div", { className: "picker__select__selected" }, getSelectedText()),
            React.createElement(ExpandIcon, { size: 14, variant: variants.INFO, style: { transform: opened && 'rotate(180deg)' } })),
        renderDropdown()));
};
export default Picker;

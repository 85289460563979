export var resolveTagModifier = function (tagValue) {
    switch (tagValue) {
        case 'High':
            return 'success';
        case 'Good':
            return 'success';
        case 'Medium':
            return 'warning';
        case 'Warning':
            return 'warning';
        case 'Low':
            return 'danger';
        case 'Critical':
            return 'danger';
        default:
            return 'unknown';
    }
};

import React from 'react';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import StaticStatsBanner from './staticStatsBanner/StaticStatsBanner';
import CrossPageFilterContainer from '@commonComponents/cross-page-filter/CrossPageFilterContainer';
import CustomerTypesFilterContainer from '@commonComponents/customer-type-filter/CustomerTypesFilterContainer';
import UsageOverviewCharts from './UsageOverviewCharts';
import UsageOverviewFilter from './UsageOverviewFilter';
var CONTAINER_CLOUD_USAGE = DASHBOARDS_SCHEMA.DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE;
var UsageOverview = function () { return (React.createElement(React.Fragment, null,
    React.createElement("h2", null, "Container Cloud Usage"),
    React.createElement(CustomerTypesFilterContainer, null),
    React.createElement(UsageOverviewFilter, null),
    React.createElement(CrossPageFilterContainer, { isGranularity: true, dashboardName: CONTAINER_CLOUD_USAGE.PSEUDO_CC }),
    React.createElement(StaticStatsBanner, null),
    React.createElement(UsageOverviewCharts, null))); };
export default UsageOverview;

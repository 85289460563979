import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import { CROSS_PAGE_FILTER_CONFIG } from '@configs/crossPageFilterInitialState';
import { getPageFromPathname } from '@commonUtils/commonUtils';
var DASHBOARDS = DASHBOARDS_SCHEMA.DASHBOARDS, REPORTS = DASHBOARDS_SCHEMA.REPORTS, SUPPORT = DASHBOARDS_SCHEMA.SUPPORT;
var getFormedDateRangeSelected = function (dashboardName) { return [
    CROSS_PAGE_FILTER_CONFIG[dashboardName].dateRange.selected,
    CROSS_PAGE_FILTER_CONFIG[dashboardName].dateRange.calculatedDateRange.start.timeString,
    CROSS_PAGE_FILTER_CONFIG[dashboardName].dateRange.calculatedDateRange.end.timeString,
]; };
var dateRangeDefaultValue = function (pathname) {
    if (pathname === void 0) { pathname = window.location.pathname; }
    var page = getPageFromPathname(pathname);
    switch (page) {
        case DASHBOARDS.PAGES.BOOTSTRAP.PSEUDO_CC:
            return getFormedDateRangeSelected(DASHBOARDS.PAGES.BOOTSTRAP.PSEUDO_CC);
        case SUPPORT.PAGES.ALERTS.PSEUDO_CC:
            return getFormedDateRangeSelected(SUPPORT.PAGES.ALERTS.PSEUDO_CC);
        case REPORTS.PAGES.NEW_SIGN_UPS_REPORT.PSEUDO_CC:
            return getFormedDateRangeSelected(REPORTS.PAGES.NEW_SIGN_UPS_REPORT.PSEUDO_CC);
        case REPORTS.PAGES.INSTALLATION_REPORT.PSEUDO_CC:
            return getFormedDateRangeSelected(REPORTS.PAGES.INSTALLATION_REPORT.PSEUDO_CC);
        case DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE.PSEUDO_CC:
            return getFormedDateRangeSelected(DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE.PSEUDO_CC);
        case REPORTS.PAGES.ACTIVE_INSTANCES.PSEUDO_CC:
            return getFormedDateRangeSelected(REPORTS.PAGES.ACTIVE_INSTANCES.PSEUDO_CC);
        case REPORTS.PAGES.TRIAL_REPORT.PSEUDO_CC:
            return getFormedDateRangeSelected(REPORTS.PAGES.TRIAL_REPORT.PSEUDO_CC);
        case DASHBOARDS.PAGES.OPENSTACK_USAGE.PSEUDO_CC: {
            return getFormedDateRangeSelected(DASHBOARDS.PAGES.OPENSTACK_USAGE.TABS.OPENSTACK_OVERALL_USAGE.PSEUDO_CC);
        }
        default:
            return '';
    }
};
export default dateRangeDefaultValue;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import get from 'lodash/get';
import { Empty } from 'antd';
import styled from '@emotion/styled';
var StyledEmptyWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding-bottom: ", ";\n    padding-top: ", ";\n"], ["\n    padding-bottom: ", ";\n    padding-top: ", ";\n"])), function (props) { return "".concat(props.paddingBottom, "px"); }, function (props) { return "".concat(props.paddingTop, "px"); });
var DEFAULT_DESCRIPTION = 'No Data';
var EmptyComponent = function (props) {
    return (React.createElement(StyledEmptyWrapper, { paddingBottom: props.paddingBottom, paddingTop: props.paddingTop, className: props.className },
        React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: get(props, 'description', DEFAULT_DESCRIPTION) })));
};
export default EmptyComponent;
var templateObject_1;

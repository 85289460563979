var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useState } from 'react';
import { Button } from '@mirantis/mds-button';
import styled from '@emotion/styled';
import { STATES } from './generatePdfConstants';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
var STEPS = Object.values(STATES);
export var StyledGeneratePdfButton = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-right: 15px;\n    display: flex;\n\n    .spinner {\n        margin-right: 10px;\n    }\n\n    button {\n        height: 29px;\n        margin-right: 10px;\n\n        &:hover {\n            height: 29px;\n        }\n    }\n"], ["\n    margin-right: 15px;\n    display: flex;\n\n    .spinner {\n        margin-right: 10px;\n    }\n\n    button {\n        height: 29px;\n        margin-right: 10px;\n\n        &:hover {\n            height: 29px;\n        }\n    }\n"])));
var GeneratePdfButton = function (_a) {
    var onChange = _a.onChange, pdfGeneratingMode = _a.pdfGeneratingMode, disabled = _a.disabled;
    var _b = useState(0), step = _b[0], setStep = _b[1];
    var handleSetStep = function () {
        if (STEPS[step] === STATES.GENERATING_IN_PROGRESS) {
            return;
        }
        var newStep = step < 2 ? step + 1 : 0;
        setStep(newStep);
        onChange(STEPS[newStep]);
    };
    var handleCancel = function () {
        setStep(0);
        onChange(STEPS[0]);
    };
    useEffect(function () {
        if (pdfGeneratingMode !== STEPS[step]) {
            setStep(STEPS.indexOf(pdfGeneratingMode));
        }
    }, [pdfGeneratingMode]);
    return (React.createElement(StyledGeneratePdfButton, null,
        STEPS[step] === STATES.GENERATING_IN_PROGRESS ? (React.createElement(Spin, { className: "spinner", indicator: React.createElement(LoadingOutlined, { style: { fontSize: 28 }, spin: true }) })) : (''),
        React.createElement(Button, { label: STEPS[step], onClick: handleSetStep, disabled: disabled, actionType: "secondary" }),
        STEPS[step] === STATES.START_GENERATING ? (React.createElement(Button, { label: "Cancel", onClick: handleCancel, actionType: "secondary" })) : ('')));
};
export default GeneratePdfButton;
var templateObject_1;

import produce from 'immer';
import { resolvers } from './resolvers';
export var initialState = {
    mkeCluster: {},
    mccCluster: {},
    mccClusterCapacity: {},
    mccClusterAvailabilityCharts: {},
    containerCloudUsage: {},
    installations: {},
    activeInstances: {},
    signUps: {},
    alerts: {},
    openstackUsageOverall: {},
    trialReportsCosts: {},
    trialReportsSignUps: {},
    trialReportsOccupiedSlots: {},
};
export var charts = function (state, action) {
    if (state === void 0) { state = initialState; }
    if (action === void 0) { action = {}; }
    var type = action.type, payload = action.payload;
    if (!(type in resolvers)) {
        return state;
    }
    var resolver = function (draft) { return resolvers[type](draft, payload); };
    return produce(state, resolver);
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getAxiosInstance } from '@api/axios-instance/apiInstances';
import { ENDPOINTS } from '@configs/api';
var METHODS = {
    PUT: 'put',
    DELETE: 'delete',
};
export var toggleClustersStatus = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var method_1, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                method_1 = params.hidden ? METHODS.PUT : METHODS.DELETE;
                return [4 /*yield*/, Promise.all(params.cluster_ids.map(function (clusterId) {
                        return getAxiosInstance()[method_1](ENDPOINTS.TOGGLE_CLUSTER_STATUS(clusterId, params.type));
                    }))];
            case 1: return [2 /*return*/, _a.sent()];
            case 2:
                e_1 = _a.sent();
                console.error(e_1);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getMKEOverview = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var account_id, timezone, hidden, inactive_hours_threshold, is_historical_data, start, end, res, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                account_id = params.account_id, timezone = params.timezone, hidden = params.hidden, inactive_hours_threshold = params.inactive_hours_threshold, is_historical_data = params.is_historical_data, start = params.start, end = params.end;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, getAxiosInstance().get(ENDPOINTS.MKE_OVERVIEW(account_id), {
                        params: { timezone: timezone, hidden: hidden, inactive_hours_threshold: inactive_hours_threshold, is_historical_data: is_historical_data, start: start, end: end },
                    })];
            case 2:
                res = _a.sent();
                return [2 /*return*/, res === null || res === void 0 ? void 0 : res.data];
            case 3:
                e_2 = _a.sent();
                console.error(e_2);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var getMCCOverview = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var account_id, timezone, hidden, inactive_hours_threshold, is_historical_data, start, end, res, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                account_id = params.account_id, timezone = params.timezone, hidden = params.hidden, inactive_hours_threshold = params.inactive_hours_threshold, is_historical_data = params.is_historical_data, start = params.start, end = params.end;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, getAxiosInstance().get(ENDPOINTS.MCC_OVERVIEW(account_id), {
                        params: { timezone: timezone, hidden: hidden, inactive_hours_threshold: inactive_hours_threshold, is_historical_data: is_historical_data, start: start, end: end },
                    })];
            case 2:
                res = _a.sent();
                return [2 /*return*/, res.data];
            case 3:
                e_3 = _a.sent();
                console.error(e_3);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var getMKEAlerts = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var license, cluster_id, alert_types, sortBy, orderBy, res, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                license = params.license, cluster_id = params.cluster_id, alert_types = params.alert_types, sortBy = params.sortBy, orderBy = params.orderBy;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, getAxiosInstance().get(ENDPOINTS.MKE_ALERTS(license, cluster_id), {
                        params: {
                            alert_types: alert_types,
                            sortBy: sortBy,
                            orderBy: orderBy,
                        },
                    })];
            case 2:
                res = _a.sent();
                return [2 /*return*/, res.data];
            case 3:
                e_4 = _a.sent();
                console.error(e_4);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var getMKERecommendations = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var license, cluster_id, res, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                license = params.license, cluster_id = params.cluster_id;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, getAxiosInstance().get(ENDPOINTS.MKE_RECOMMENDATIONS(license, cluster_id))];
            case 2:
                res = _a.sent();
                return [2 /*return*/, res.data];
            case 3:
                e_5 = _a.sent();
                console.error(e_5);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };

import React from 'react';
import uuid from 'react-uuid';
import { CLUSTER_OVERVIEW_COLUMN_ID } from '@commonComponents/gropped-column-table/GropedTableConstants';
var GroupedTableTHead = function (_a) {
    var title = _a.title, columns = _a.columns, isClustersManaging = _a.isClustersManaging, _b = _a.extraHeadColumns, extraHeadColumns = _b === void 0 ? [] : _b;
    var firstLevel = [
        React.createElement("td", { key: uuid(), className: "groped-table__thead__title", "column-id": CLUSTER_OVERVIEW_COLUMN_ID, rowSpan: 2 },
            title,
            React.createElement("div", { className: "sub-titles" },
                React.createElement("div", { className: "cluster-status" }, isClustersManaging && 'Status'),
                React.createElement("div", { className: "cluster-index cluster-index-title", "parent-colomn-id": CLUSTER_OVERVIEW_COLUMN_ID }, "#"),
                React.createElement("div", { className: "type", "parent-colomn-id": CLUSTER_OVERVIEW_COLUMN_ID }, "Type"),
                extraHeadColumns.map(function (_a) {
                    var className = _a.className, key = _a.key, extraColumnTitle = _a.title;
                    return (React.createElement("div", { className: className, key: key, "parent-colomn-id": CLUSTER_OVERVIEW_COLUMN_ID }, extraColumnTitle));
                }))),
    ];
    var secondLevel = [];
    for (var _i = 0, columns_1 = columns; _i < columns_1.length; _i++) {
        var group = columns_1[_i];
        firstLevel.push(React.createElement("th", { className: "groped-table__thead__th", key: uuid(), "column-id": group.key, "parent-colomn-id": CLUSTER_OVERVIEW_COLUMN_ID, colSpan: group.subtitles.length }, group.name));
        for (var _c = 0, _d = group.subtitles; _c < _d.length; _c++) {
            var item = _d[_c];
            secondLevel.push(React.createElement("th", { key: uuid(), "column-id": group.key, "parent-colomn-id": CLUSTER_OVERVIEW_COLUMN_ID }, item.name));
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("thead", { className: "groped-table__thead" },
            React.createElement("tr", null, firstLevel),
            React.createElement("tr", null, secondLevel))));
};
export default GroupedTableTHead;

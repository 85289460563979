import * as Actions from '@redux/actions/bootstrapInstallations';
var r = {};
r[Actions.AsyncActions.GET_BOOTSTRAP_INSTALLATIONS] = function (draft) {
    draft.loading = true;
};
r[Actions.SyncActions.SET_BOOTSTRAP_INSTALLATIONS] = function (draft, payload) {
    draft.data = payload.data;
    draft.loading = false;
};
export { r as resolvers };

import React from 'react';
import { connect } from 'react-redux';
import { getMultiFilterPickers } from '@configs/multiFilterConfig';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import FilterContainer from '@commonComponents/filter/FilterContainer';
var CONTAINER_CLOUD_USAGE = DASHBOARDS_SCHEMA.DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE;
var mapState = function (store) { return ({
    mode: store.mode,
    multiFilter: store.multiFilter,
}); };
var connector = connect(mapState);
export var UsageOverviewFilter = function (_a) {
    var mode = _a.mode, multiFilter = _a.multiFilter;
    var name = CONTAINER_CLOUD_USAGE.PSEUDO_CC;
    var selected = multiFilter.filter.selected;
    var params = {
        limit: 20,
        customer_types: mode.selected[0] === 'All' ? null : mode.selected.toString(),
        inactive_hours_threshold: mode.lastSyncDate.selected.value,
        source: 'CONTAINER_CLOUD',
    };
    if (!multiFilter.loading && multiFilter.name === name) {
        var extendedParams = {
            customer_ids: selected.customer.length > 0 ? selected.customer.map(function (o) { return o.id; }).toString() : null,
            instance_ids: selected.instance.length > 0 ? selected.instance.map(function (o) { return o.id; }).toString() : null,
            cluster_ids: selected.cluster.length > 0 ? selected.cluster.map(function (o) { return o.id; }).toString() : null,
        };
        params = Object.assign(params, extendedParams);
    }
    return (React.createElement(FilterContainer, { name: name, params: params, currentMode: mode, multiFilter: multiFilter, pickers: getMultiFilterPickers(name) }));
};
export default connector(UsageOverviewFilter);

import get from 'lodash/get';
import moment from 'moment';
import { store } from '@redux/store';
import { DATE_RANGES, STATIC_REPORTS_DATE_RANGES } from '@utils/date/dateConstants';
var DateUtils = /** @class */ (function () {
    function DateUtils() {
    }
    DateUtils.format = function (date) {
        var localDate = moment(date);
        return {
            timeString: DateUtils.getTimeString(localDate),
            timeStamp: DateUtils.getTimeStamp(localDate),
        };
    };
    DateUtils.formatCustomDateRange = function (date, point) {
        var localDate = moment(date, 'YYYY-MM-DD');
        var utcDate = moment(date, 'YYYY-MM-DD').utc().add(moment(localDate).utcOffset(), 'minutes');
        var dateShift = point === 'start' ? utcDate.startOf('day') : utcDate.endOf('day');
        return {
            timeString: DateUtils.getTimeString(dateShift),
            timeStamp: DateUtils.getTimeStamp(dateShift),
        };
    };
    DateUtils.getTimeString = function (date) { return moment(date).format('YYYY-MM-DD'); };
    DateUtils.getTimeStamp = function (date) { return date.valueOf(); };
    DateUtils.getMonthAndYearByDate = function (date) { return ({
        m: DateUtils.getNameOfMonthByDate(date),
        y: DateUtils.getYearByDate(date),
    }); };
    DateUtils.recalculateDateByMonths = function (date, months, action) {
        var momentInstance = moment(date);
        return action === 'increase' ? momentInstance.add(months, 'month') : momentInstance.subtract(months, 'month');
    };
    DateUtils.enumerateDaysBetweenDates = function (startDate, endDate) {
        var dates = [];
        var currDate = moment(startDate).subtract(1, 'day').startOf('day');
        var lastDate = moment(endDate).add(1, 'day').startOf('day');
        while (currDate.add(1, 'days').diff(lastDate) < 0) {
            dates.push(currDate.clone().toDate());
        }
        return dates;
    };
    DateUtils.calculateDateRangeByOption = function (option, date) {
        if (date === void 0) { date = moment(); }
        var utcDate = moment(date).utc();
        switch (option) {
            case STATIC_REPORTS_DATE_RANGES.PREVIOUS_YEAR: {
                var start = moment(utcDate).subtract(1, 'year').startOf('day');
                var end = moment(utcDate).endOf('day');
                return {
                    start: DateUtils.format(start),
                    end: DateUtils.format(end),
                };
            }
            case STATIC_REPORTS_DATE_RANGES.PREVIOUS_6_MONTHS: {
                var start = moment(utcDate).subtract(6, 'month').startOf('day');
                var end = moment(utcDate).endOf('day');
                return {
                    start: DateUtils.format(start),
                    end: DateUtils.format(end),
                };
            }
            case STATIC_REPORTS_DATE_RANGES.PREVIOUS_3_MONTHS: {
                var start = moment(utcDate).subtract(3, 'month').startOf('day');
                var end = moment(utcDate).endOf('day');
                return {
                    start: DateUtils.format(start),
                    end: DateUtils.format(end),
                };
            }
            case STATIC_REPORTS_DATE_RANGES.PREVIOUS_1_MONTHS: {
                var start = moment(utcDate).subtract(1, 'month').startOf('day');
                var end = moment(utcDate).endOf('day');
                return {
                    start: DateUtils.format(start),
                    end: DateUtils.format(end),
                };
            }
            case DATE_RANGES.ALL: {
                var start = moment(0);
                var end = moment(utcDate).endOf('day');
                return {
                    start: DateUtils.format(start),
                    end: DateUtils.format(end),
                };
            }
            case DATE_RANGES.PREVIOUS_TWO_MONTHS: {
                var start = moment(utcDate).subtract(2, 'month').startOf('day');
                var end = moment(utcDate).endOf('day');
                return {
                    start: DateUtils.format(start),
                    end: DateUtils.format(end),
                };
            }
            case DATE_RANGES.LAST_MONTH: {
                var start = moment(utcDate).subtract(1, 'month').startOf('month');
                var end = moment(utcDate).subtract(1, 'month').endOf('month');
                return {
                    start: DateUtils.format(start),
                    end: DateUtils.format(end),
                };
            }
            case DATE_RANGES.MONTH_TO_DATE: {
                var start = moment(utcDate).startOf('month');
                var end = moment(utcDate).endOf('day');
                return {
                    start: DateUtils.format(start),
                    end: DateUtils.format(end),
                };
            }
            case DATE_RANGES.PREVIOUS_TWO_WEEKS: {
                var start = moment(utcDate).subtract(2, 'week').startOf('day');
                var end = moment(utcDate).endOf('day');
                return {
                    start: DateUtils.format(start),
                    end: DateUtils.format(end),
                };
            }
            case DATE_RANGES.LAST_WEEK: {
                var start = moment(utcDate).subtract(1, 'week').startOf('week');
                var end = moment(utcDate).subtract(1, 'week').endOf('week');
                return {
                    start: DateUtils.format(start),
                    end: DateUtils.format(end),
                };
            }
            case DATE_RANGES.THIS_WEEK: {
                var start = moment(utcDate).startOf('week');
                var end = moment(utcDate).endOf('day');
                return {
                    start: DateUtils.format(start),
                    end: DateUtils.format(end),
                };
            }
            case DATE_RANGES.YESTERDAY: {
                var start = moment(utcDate).subtract(1, 'day').startOf('day');
                var end = moment(utcDate).subtract(1, 'day').endOf('day');
                return {
                    start: DateUtils.format(start),
                    end: DateUtils.format(end),
                };
            }
            case DATE_RANGES.TODAY: {
                var start = moment(utcDate).startOf('day');
                var end = moment(utcDate).endOf('day');
                return {
                    start: DateUtils.format(start),
                    end: DateUtils.format(end),
                };
            }
            default:
                return {
                    start: DateUtils.format(utcDate.startOf('day')),
                    end: DateUtils.format(utcDate.endOf('day')),
                };
        }
    };
    DateUtils.calculateNumberOfDaysBetweenDates = function (start, end) {
        var startMoment = moment(start);
        var endMoment = moment(end);
        return endMoment.diff(startMoment, 'days') + 1;
    };
    DateUtils.getDateString = function (date, format) {
        if (format === void 0) { format = 'DD-MMM-YYYY HH:mm'; }
        var dateUTC = moment(date).utc();
        return dateUTC.format(format);
    };
    DateUtils.getDateLocalStringAutoTimezoneHandler = function (date, format) {
        if (format === void 0) { format = 'DD-MMM-YYYY HH:mm'; }
        var timezone = get(store.getState(), 'userInfo.userPreferences.timezone', 'UTC');
        var dateUTC = timezone === 'UTC' ? moment.utc(date) : moment(date);
        return dateUTC.format(format);
    };
    DateUtils.getDateStringWithDynamicTimezone = function (date) {
        return moment(date).utcOffset(date).format('DD-MMM-YYYY HH:mm');
    };
    DateUtils.getNameOfMonthByDate = function (date) { return moment(date).format('MMM'); };
    DateUtils.getYearByDate = function (date) { return moment(date).get('year'); };
    return DateUtils;
}());
export { DateUtils };

import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
var UTILIZATION = 'Utilization';
var RESILIENCE = 'Resilience';
export var getFormattedRecommendations = function (response) {
    if (isEmpty(response.data)) {
        return response;
    }
    response.data.forEach(function (recommendation) {
        var extraInfo = get(recommendation, 'extraInfo', '').split('|');
        var links = get(recommendation, 'link', '').split('|');
        recommendation.extraInfoItems = extraInfo.map(function (item, index) {
            return {
                value: item,
                link: links[index],
            };
        });
    });
    return response;
};
export var getFormattedMKEAlerts = function (res) {
    res.data.forEach(function (item) {
        if (item.type === UTILIZATION) {
            item.type = RESILIENCE;
        }
    });
    return res;
};
export var getSortedClusters = function (clusters) {
    return orderBy(clusters, function (_a) {
        var lastSyncDate = _a.lastSyncDate;
        return moment(lastSyncDate).valueOf();
    }, ['desc']);
};
export var getNormalizedClusters = function (clusters) {
    var sortedClusters = getSortedClusters(clusters);
    return sortedClusters.reduce(function (result, cluster) {
        result[cluster.clusterId] = cluster;
        return result;
    }, {});
};

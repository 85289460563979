var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/** @jsx jsx */
import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import Scrollbar from '@commonComponents/Scrollbar';
var StyledContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    min-height: 100%;\n    padding: 40px 35px;\n    z-index: 1;\n    background: ", ";\n    box-shadow: ", ";\n    overflow: scroll;\n"], ["\n    min-height: 100%;\n    padding: 40px 35px;\n    z-index: 1;\n    background: ", ";\n    box-shadow: ", ";\n    overflow: scroll;\n"])), function (props) { return props.theme.color.background.layer3; }, function (props) { return props.theme.shadow.box[0].css; });
var ContentRaw = function (props) {
    return (jsx(Scrollbar, null,
        jsx(StyledContainer, { className: "context-raw" }, props.children)));
};
export default ContentRaw;
var templateObject_1;

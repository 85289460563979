var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import ApiErrorBanner from './ApiErrorBanner';
import { currentState } from './ApiErrorBannersUtils';
export var StyledApiErrorBanner = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    z-index: 100;\n    left: 50%;\n    position: absolute;\n    transform: translateX(-50%);\n\n    .ant-alert {\n        margin-bottom: 10px;\n    }\n"], ["\n    z-index: 100;\n    left: 50%;\n    position: absolute;\n    transform: translateX(-50%);\n\n    .ant-alert {\n        margin-bottom: 10px;\n    }\n"])));
var MODALS_IDS = ['assign-users-modal', 'create-edit-permission-group-modal'];
var CLOSE_ALERTS_TIMEOUT = 15000;
var HIDE_OLDER_ALERT_MS = 500;
var ApiErrorBannersWrapper = function () {
    var closeAlertsTimeoutRef = useRef(null);
    var pages = useSelector(function (store) { return store.errorHandling; }).pages;
    var currentTimestamp = moment().valueOf();
    var allErrors = currentState(pages);
    var root = MODALS_IDS.map(function (id) { return document.getElementById(id); }).filter(Boolean)[0];
    useEffect(function () {
        clearTimeout(closeAlertsTimeoutRef.current);
        if (allErrors.length > 0) {
            closeAlertsTimeoutRef.current = setTimeout(function () {
                var allAlertsCloseIcons = document.querySelectorAll('.ant-alert-close-icon');
                // @ts-ignore
                allAlertsCloseIcons.forEach(function (node) { return node.click(); });
            }, CLOSE_ALERTS_TIMEOUT);
        }
    }, [allErrors]);
    var banner = (React.createElement(StyledApiErrorBanner, null, allErrors.map(function (_a, index) {
        var message = _a.message, detailInfo = _a.detailInfo, timestamp = _a.timestamp;
        return timestamp > currentTimestamp - HIDE_OLDER_ALERT_MS ? (React.createElement(ApiErrorBanner, { key: message + index + Date.now(), message: message, detailInfo: detailInfo })) : (React.createElement("div", { key: message + index + Date.now() }));
    })));
    if (root) {
        return createPortal(banner, root);
    }
    return banner;
};
export default ApiErrorBannersWrapper;
var templateObject_1;

import * as Actions from '@redux/actions/userInfo';
var r = {};
r[Actions.SyncActions.SET_USER_INFO] = function (draft, payload) {
    var _a, _b;
    draft.activeUser = payload;
    draft.settings = {
        initial: { timezone: (_a = payload === null || payload === void 0 ? void 0 : payload.preferences) === null || _a === void 0 ? void 0 : _a.timezone },
        current: { timezone: (_b = payload === null || payload === void 0 ? void 0 : payload.preferences) === null || _b === void 0 ? void 0 : _b.timezone },
    };
};
r[Actions.SyncActions.SET_USER_PREFERENCES] = function (draft, payload) {
    draft.userPreferences = payload;
};
r[Actions.SyncActions.CHANGE_USER_PREFERENCE] = function (draft, payload) {
    draft.settings.current[payload.key] = payload.value;
};
r[Actions.SyncActions.APPLY_SETTINGS_SAVE] = function (draft) {
    draft.userPreferences.timezone = draft.settings.current.timezone;
    draft.settings.initial.timezone = draft.settings.current.timezone;
};
r[Actions.SyncActions.RESET_PREFERENCES] = function (draft) {
    draft.settings.current.timezone = draft.settings.initial.timezone;
};
export { r as resolvers };

import { connect } from 'react-redux';
import TelemetryAlertsFilter from './TelemetryAlertsFilter';
import { alertsFilterFromUrl } from './telementryAlertsfilterUtils';
import withUrlValidationHOC from '@HOC/withUrlValidationHOC/withUrlValidationHOC';
var mapState = function (state) { return ({
    modeSelected: state.mode.selected,
    alertsFilterFromUrl: alertsFilterFromUrl(),
}); };
var connector = connect(mapState, null);
export default withUrlValidationHOC(connector(TelemetryAlertsFilter));

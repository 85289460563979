var _a, _b, _c;
import { GRANULARITIES } from '@commonConstants/granularityConstants';
export var DAYS_CONTRACTIONS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export var DATE_RANGES;
(function (DATE_RANGES) {
    DATE_RANGES["ALL"] = "All";
    DATE_RANGES["PREVIOUS_TWO_MONTHS"] = "Previous Two Months";
    DATE_RANGES["LAST_MONTH"] = "Last Month";
    DATE_RANGES["MONTH_TO_DATE"] = "Month To Date";
    DATE_RANGES["PREVIOUS_TWO_WEEKS"] = "Previous Two Weeks";
    DATE_RANGES["LAST_WEEK"] = "Last Week";
    DATE_RANGES["THIS_WEEK"] = "This Week";
    DATE_RANGES["YESTERDAY"] = "Yesterday";
    DATE_RANGES["TODAY"] = "Today";
    DATE_RANGES["CUSTOM_RANGE"] = "Custom Range";
})(DATE_RANGES || (DATE_RANGES = {}));
export var STATIC_REPORTS_DATE_RANGES;
(function (STATIC_REPORTS_DATE_RANGES) {
    STATIC_REPORTS_DATE_RANGES["PREVIOUS_YEAR"] = "Previous Year";
    STATIC_REPORTS_DATE_RANGES["PREVIOUS_1_MONTHS"] = "Previous 1 Months";
    STATIC_REPORTS_DATE_RANGES["PREVIOUS_3_MONTHS"] = "Previous 3 Months";
    STATIC_REPORTS_DATE_RANGES["PREVIOUS_6_MONTHS"] = "Previous 6 Months";
})(STATIC_REPORTS_DATE_RANGES || (STATIC_REPORTS_DATE_RANGES = {}));
export var DATE_RANGES_DAYS_QTY_EQUIVALENTS = (_a = {},
    _a[STATIC_REPORTS_DATE_RANGES.PREVIOUS_YEAR] = 360,
    _a[STATIC_REPORTS_DATE_RANGES.PREVIOUS_6_MONTHS] = 180,
    _a[STATIC_REPORTS_DATE_RANGES.PREVIOUS_3_MONTHS] = 90,
    _a[STATIC_REPORTS_DATE_RANGES.PREVIOUS_1_MONTHS] = 30,
    _a[DATE_RANGES.PREVIOUS_TWO_MONTHS] = 62,
    _a[DATE_RANGES.LAST_MONTH] = 31,
    _a[DATE_RANGES.MONTH_TO_DATE] = 31,
    _a[DATE_RANGES.PREVIOUS_TWO_WEEKS] = 14,
    _a[DATE_RANGES.LAST_WEEK] = 7,
    _a[DATE_RANGES.THIS_WEEK] = 7,
    _a[DATE_RANGES.YESTERDAY] = 1,
    _a[DATE_RANGES.TODAY] = 1,
    _a);
export var MCC_DATE_RANGE_GRANULARITY_DEFAULTS = (_b = {},
    _b[DATE_RANGES.LAST_MONTH] = GRANULARITIES.ONE_DAY,
    _b[DATE_RANGES.MONTH_TO_DATE] = GRANULARITIES.SIX_HOURS,
    _b[DATE_RANGES.LAST_WEEK] = GRANULARITIES.TWELVE_HOURS,
    _b[DATE_RANGES.THIS_WEEK] = GRANULARITIES.TWELVE_HOURS,
    _b[DATE_RANGES.YESTERDAY] = GRANULARITIES.THREE_HOURS,
    _b[DATE_RANGES.TODAY] = GRANULARITIES.THREE_HOURS,
    _b);
export var STATIC_REPORTS_DATE_RANGE_GRANULARITY_DEFAULTS = (_c = {},
    _c[STATIC_REPORTS_DATE_RANGES.PREVIOUS_YEAR] = GRANULARITIES.ONE_MONTH,
    _c[STATIC_REPORTS_DATE_RANGES.PREVIOUS_6_MONTHS] = GRANULARITIES.ONE_MONTH,
    _c[STATIC_REPORTS_DATE_RANGES.PREVIOUS_3_MONTHS] = GRANULARITIES.ONE_MONTH,
    _c[DATE_RANGES.LAST_MONTH] = GRANULARITIES.ONE_DAY,
    _c[DATE_RANGES.MONTH_TO_DATE] = GRANULARITIES.ONE_DAY,
    _c[DATE_RANGES.LAST_WEEK] = GRANULARITIES.ONE_DAY,
    _c[DATE_RANGES.THIS_WEEK] = GRANULARITIES.ONE_DAY,
    _c);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cloneDeep from 'lodash/cloneDeep';
import * as Actions from '@redux/actions/crossPageFilter';
import { DATE_RANGES } from '@utils/date/dateConstants';
import * as resolverUtils from './crosspageFilterUtils';
var r = {};
r[Actions.SyncActions.INIT_CROSS_PAGE_FILTER_STATE] = function (draft, payload) {
    var state = cloneDeep(draft);
    var selectedGranularity = state.granularity.selected;
    var selectedDateRange = state.dateRange.selected;
    var dateRange = selectedDateRange ? state.dateRange : payload.dateRange;
    //If current filter has date range picker - merge configuration
    if (payload.dateRange) {
        resolverUtils.mergeDateRangeFromPayload(state, payload.dateRange, selectedDateRange, payload.dashboardName);
    }
    //If current filter has granularity range picker - merge configuration
    if (payload.granularity) {
        //In we init filter from another dashboard let's save initial options  to be able to filter them
        if (state.dashboardName !== payload.dashboardName) {
            state.granularity.defaultOptions = payload.granularity.options;
        }
        //If it's initial rendering
        if (!selectedGranularity) {
            state.granularity.selected = payload.granularity.selected;
        }
        //if not initial rendering and selected granularity isn't in list of options
        if (selectedGranularity &&
            !payload.granularity.options.some(function (granularity) { return selectedGranularity.value === granularity.value; })) {
            if (dateRange.selected === DATE_RANGES.CUSTOM_RANGE) {
                state.granularity.selected = resolverUtils.resolveEquivalentGranularity(payload.dashboardFamily, dateRange, payload.granularity.options);
            }
            else {
                state.granularity.selected = resolverUtils.resolveDefaultGranularity(payload.dashboardFamily, dateRange);
            }
        }
        if (selectedGranularity &&
            payload.granularity.options.some(function (granularity) { return selectedGranularity.value === granularity.value; })) {
            if (!resolverUtils.isGranularityHigherThanDateRange(payload.dashboardFamily, selectedGranularity, state.dateRange)) {
                state.granularity.selected = resolverUtils.resolveDefaultGranularity(payload.dashboardFamily, dateRange);
            }
        }
        //Do not allow user to select granularity larger than date range
        state.granularity.options = resolverUtils.filterGranularityOptionsBySelectedDateRange(payload.dashboardFamily, dateRange, state.granularity.defaultOptions);
    }
    //Setting dashboard pseudos
    state.dashboardName = payload.dashboardName;
    state.dashboardFamily = payload.dashboardFamily;
    Object.assign(draft, state);
};
r[Actions.SyncActions.CHANGE_CROSS_PAGE_FILTER_OPTION] = function (draft, payload) {
    if (payload.key === 'dateRange') {
        if (payload.payload.isGranularityEnabled) {
            resolverUtils.changeGranularityRegardingToDateRange(draft, payload);
        }
        else {
            Object.assign(draft.dateRange, payload.payload);
        }
    }
    else {
        draft.granularity.selected = payload.payload.selected;
    }
};
r[Actions.SyncActions.RESET_CROSS_PAGE_FILTER_STATE] = function (draft, payload) {
    var state = cloneDeep(draft);
    var newPayload = __assign(__assign({}, payload), { dateRange: {
            options: null,
            selected: '',
            calculatedDateRange: null,
        } });
    state.dashboardName = payload.dashboardName;
    state.dashboardFamily = payload.dashboardFamily;
    Object.assign(draft, newPayload);
};
export { r as resolvers };

import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { DEFAULT_LAST_SYNC_DATE, LAST_SYNC_DATE_OPTIONS } from '@configs/modeSwitcher';
import { Localstorage } from '@utils/localstorage/Localstorage';
import { LOCAL_STORAGE_KEYS } from '@commonConstants/localStorageConstancts';
import { getCustomerTypesFromUrl, getLastSyncDateFromUrlFromUrl, } from '@commonComponents/customer-type-filter/customerTypesFilterUtils';
import { ALL } from '@commonComponents/customer-type-filter/customerTypesFilterConstants';
// TODO: should be removed in future
if (!Localstorage.get(LOCAL_STORAGE_KEYS.IS_CLEAR)) {
    Localstorage.remove(LOCAL_STORAGE_KEYS.MODE);
    Localstorage.set(LOCAL_STORAGE_KEYS.IS_CLEAR, true);
}
var getCustomerTypes = function () {
    var customerTypesFromUrl = getCustomerTypesFromUrl();
    var localMode = Localstorage.get(LOCAL_STORAGE_KEYS.MODE);
    var finalCustomerTypesFromUrl;
    if (!isEmpty(customerTypesFromUrl)) {
        finalCustomerTypesFromUrl = customerTypesFromUrl.includes(ALL) ? [] : customerTypesFromUrl;
    }
    else {
        finalCustomerTypesFromUrl = localMode ? localMode.split(',') : [];
    }
    return finalCustomerTypesFromUrl;
};
var getLastSyncDate = function () {
    var localLastSync = Localstorage.get(LOCAL_STORAGE_KEYS.LAST_SYNC_DATE);
    var lastSyncDateFromUrl = getLastSyncDateFromUrlFromUrl();
    var lastSyncDateLabel = lastSyncDateFromUrl || JSON.parse(localLastSync);
    return find(LAST_SYNC_DATE_OPTIONS, { label: lastSyncDateLabel }) || DEFAULT_LAST_SYNC_DATE;
};
export var getModeInitialState = function () { return ({
    loading: true,
    selected: getCustomerTypes(),
    options: {},
    lastSyncDate: {
        selected: getLastSyncDate(),
        options: LAST_SYNC_DATE_OPTIONS,
    },
}); };

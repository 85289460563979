var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Spin } from 'antd';
import styled from '@emotion/styled';
import { LoadingOutlined } from '@ant-design/icons';
var StyledSpinner = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    z-index: 1;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #ffffff;\n"], ["\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    z-index: 1;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #ffffff;\n"])));
var AppLoader = function () {
    return (React.createElement(StyledSpinner, null,
        React.createElement(Spin, { className: "spinner", indicator: React.createElement(LoadingOutlined, { style: { fontSize: 50 }, spin: true }) })));
};
export default AppLoader;
var templateObject_1;

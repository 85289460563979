import React, { useEffect, useState, memo } from 'react';
import isEqual from 'lodash/isEqual';
import { usePrevious } from '@app/hooks';
import { getClusterHealth, patchAnnotation } from '@redux/dispatchers/clusterHealth';
import { changeCurrentPage } from '@redux/dispatchers/pagination';
import ClusterHealthTr from './ClusterHealthTr';
import EditableTable from '@commonComponents/editable-table/EditableTable';
import PaginationContainer from '@commonComponents/pagination/PaginationContainer';
import TableLoadingStates from '@commonComponents/table-loading-states/TableLoadingStates';
import { getClusterHealthParams } from '@components/cluster-health-table/ClusterHealthTableUtils';
import { StyledTable } from '@commonComponents/table/styled';
import { PAGINATION_NAMES } from '@configs/DASHBOARD_PSEUDOS';
import { COLUMNS, NO_CLUSTERS_FOUND } from './clusterHealthTableConstants';
var CLUSTER_HEALTH = PAGINATION_NAMES.CLUSTER_HEALTH;
var ALL_COLUMNS = COLUMNS.map(function (C) { return C.key; });
var ClusterHealthTable = function (_a) {
    var mode = _a.mode, clusterHealth = _a.clusterHealth, multiFilter = _a.multiFilter, pagination = _a.pagination;
    var _b = useState({
        sortBy: 'cluster_name',
        orderBy: 'asc',
    }), state = _b[0], setState = _b[1];
    var _c = useState(ALL_COLUMNS.filter(function (C, idx) { return COLUMNS[idx].isVisibleByDefault; })), visibleColumns = _c[0], setVisibleColumns = _c[1];
    var params = getClusterHealthParams({
        search_term: multiFilter.filter.searchTerm,
        page: pagination.currentPage,
        limit: pagination.limit,
        sortBy: state.sortBy,
        orderBy: state.orderBy,
    });
    var prevParams = usePrevious(params);
    var callAPI = function () {
        getClusterHealth(params);
    };
    useEffect(function () {
        if (!isEqual(prevParams, params)) {
            callAPI();
        }
    }, [mode, multiFilter.filter, state, pagination.currentPage, pagination.limit]);
    var handleSave = function (newClusterHealth) {
        patchAnnotation(newClusterHealth);
    };
    var setSortableState = function (nextState) {
        setState(nextState);
    };
    var onPaginationChange = function (page) {
        changeCurrentPage({
            paginationName: CLUSTER_HEALTH,
            currentPage: page,
        });
    };
    var onLimitChange = function (limit) {
        changeCurrentPage({
            limit: limit,
            paginationName: CLUSTER_HEALTH,
            currentPage: 1,
        });
    };
    var TRs = clusterHealth.data.map(function (cluster) { return (React.createElement(ClusterHealthTr, { key: cluster.clusterId, visibleColumns: visibleColumns, cluster: cluster, columns: COLUMNS, handleSave: handleSave })); });
    return (React.createElement(StyledTable, { className: "table" },
        React.createElement(TableLoadingStates, { loading: clusterHealth.loading, data: (clusterHealth === null || clusterHealth === void 0 ? void 0 : clusterHealth.data) || [], count: pagination.limit, empty: {
                description: NO_CLUSTERS_FOUND,
            }, skeleton: {
                paddingBottom: 20,
                paddingTop: 20,
            } },
            React.createElement(React.Fragment, null,
                React.createElement(EditableTable, { columns: COLUMNS, sortBy: state.sortBy, orderBy: state.orderBy, setSortableState: setSortableState, columnControl: COLUMNS.length > 2, visibleColumns: visibleColumns, pagination: pagination, setVisibleColumns: setVisibleColumns }, TRs),
                React.createElement(PaginationContainer, { withLimits: true, onLimitChange: onLimitChange, pagination: pagination, onChange: onPaginationChange })))));
};
export default memo(ClusterHealthTable);

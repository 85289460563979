var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { checkValues } from '@mirantis/mds-checkbox';
import size from 'lodash/size';
var MIN_GROUP_NAME_LENGTH = 3;
export var CheckedSum = function (dataArr) {
    return dataArr.reduce(function (previousValue, currentValue) {
        return previousValue + Number(currentValue.isSelected);
    }, 0);
};
export var CheckedType = function (dataArr) {
    if (!CheckedSum(dataArr))
        return checkValues.UNCHECKED;
    if (CheckedSum(dataArr) === dataArr.length)
        return checkValues.CHECKED;
    return checkValues.MIXED;
};
export var changeElement = function (id, stateTree) {
    stateTree.forEach(function (element) {
        element.items.forEach(function (item) {
            if (item.id === id) {
                item.isSelected = !item.isSelected;
            }
        });
    });
    return __spreadArray([], stateTree, true);
};
export var unCheckAll = function (arr) {
    arr.forEach(function (element) {
        element.isSelected = false;
    });
    return __spreadArray([], arr, true);
};
export var checkAll = function (arr) {
    arr.forEach(function (element) {
        element.isSelected = true;
    });
    return __spreadArray([], arr, true);
};
export var changeGroup = function (section, stateTree) {
    stateTree.forEach(function (element) {
        if (element.section === section) {
            if (CheckedType(element.items) === checkValues.CHECKED ||
                CheckedType(element.items) === checkValues.MIXED) {
                element.items = unCheckAll(element.items);
            }
            else {
                element.items = checkAll(element.items);
            }
        }
    });
    return __spreadArray([], stateTree, true);
};
export var isEnabled = function (groupName) {
    if (groupName === void 0) { groupName = ''; }
    return !groupName.split('').every(function (symb) { return symb === ' '; }) && size(groupName) >= MIN_GROUP_NAME_LENGTH;
};

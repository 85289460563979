import openSansNormal from '@fonts/OpenSans-Regular.ttf';
import openSansNormalItalic from '@fonts/OpenSans-Italic.ttf';
import openSansSemibold from '@fonts/OpenSans-SemiBold.ttf';
import openSansSemiboldItalic from '@fonts/OpenSans-SemiBoldItalic.ttf';
import openSansBold from '@fonts/OpenSans-Bold.ttf';
import openSansBoldItalic from '@fonts/OpenSans-BoldItalic.ttf';
import jetBrainsMonoVar from '@fonts/JetBrainsMono-VariableFont_wght.ttf';
import jetBrainsMonoVarItalic from '@fonts/JetBrainsMono-Italic-VariableFont_wght.ttf';
export default {
    normal: openSansNormal,
    normalItalic: openSansNormalItalic,
    semibold: openSansSemibold,
    semiboldItalic: openSansSemiboldItalic,
    bold: openSansBold,
    boldItalic: openSansBoldItalic,
    monoVar: jetBrainsMonoVar,
    monoVarItalic: jetBrainsMonoVarItalic,
};

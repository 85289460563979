import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { CLUSTER_HEALTH_STATUSES } from './pickersApiConstants';
export var getCustomerExtractedParams = function (params) { return ({
    page: params.page,
    limit: params.limit,
    customer_types: params.customer_types,
    inactive_hours_threshold: params.inactive_hours_threshold,
    search_term: params.search_term,
    source: params.source,
    without_total_count: true,
}); };
export var getClusterExtractedParams = function (params) { return ({
    page: params.page,
    limit: params.limit,
    source: params.source,
    customer_ids: params.customer_ids,
    instance_ids: params.instance_ids,
    customer_types: params.customer_types,
    search_term: params.cluster_name_term,
    inactive_hours_threshold: params.inactive_hours_threshold,
    with_cluster_versions: params.with_cluster_versions,
    without_total_count: true,
}); };
export var getInstancesExtractedParams = function (params) { return ({
    page: params.page,
    limit: params.limit,
    sortBy: params.sortBy,
    source: params.source,
    orderBy: params.orderBy,
    search_term: params.search_term,
    customer_ids: params.customer_ids,
    customer_types: params.customer_types,
    inactive_hours_threshold: params.inactive_hours_threshold,
    without_total_count: true,
}); };
export var getPermissionsExtractedParams = function (params) { return ({
    page: params.page,
    limit: params.limit,
    sortBy: params.sortBy,
    source: params.source,
    orderBy: params.orderBy,
    search_term: params.search_term,
}); };
export var getFormattedClusters = function (_a, limit) {
    var data = _a.data, currentPage = _a.currentPage;
    var dataClone = cloneDeep(data);
    var isNextPage = !isEmpty(data) && data.length === limit;
    return {
        data: dataClone.map(function (cluster) {
            cluster.name = "".concat(cluster.prefix ? '(' + cluster.prefix + ') ' : '').concat(cluster.name, " - (").concat(cluster.id, ")");
            return cluster;
        }),
        currentPage: currentPage,
        maxPages: isNextPage ? currentPage + 1 : currentPage,
    };
};
export var getFormattedCustomers = function (_a, limit) {
    var data = _a.data, currentPage = _a.currentPage;
    var isNextPage = !isEmpty(data) && data.length === limit;
    return {
        data: data,
        currentPage: currentPage,
        maxPages: isNextPage ? currentPage + 1 : currentPage,
    };
};
export var getFormattedInstances = function (_a, limit) {
    var data = _a.data, currentPage = _a.currentPage;
    var isNextPage = !isEmpty(data) && data.length === limit;
    return {
        data: data,
        currentPage: currentPage,
        maxPages: isNextPage ? currentPage + 1 : currentPage,
    };
};
export var getClusterHealthCluster = function (status) {
    switch (status) {
        case 'Good':
            return CLUSTER_HEALTH_STATUSES.GOOD;
        case 'Warning':
            return CLUSTER_HEALTH_STATUSES.WARNING;
        case 'Bad':
            return CLUSTER_HEALTH_STATUSES.BAD;
        default:
            return status;
    }
};
export var getTransformedHealthStatus = function (healthStatuses) {
    return healthStatuses.map(function (status) { return ({
        id: status,
        name: getClusterHealthCluster(status),
    }); });
};

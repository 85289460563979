var _a;
import produce from 'immer';
import has from 'lodash/has';
import { errorHandlingPages } from '@commonConstants/errorHandlingConstants';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import resolvers from './errorHandlingResolvers';
var HOME = DASHBOARDS_SCHEMA.HOME, ACCOUNT_MANAGEMENT = DASHBOARDS_SCHEMA.ACCOUNT_MANAGEMENT, DASHBOARDS = DASHBOARDS_SCHEMA.DASHBOARDS, REPORTS = DASHBOARDS_SCHEMA.REPORTS, SUPPORT = DASHBOARDS_SCHEMA.SUPPORT, MKE_DASHBOARDS = DASHBOARDS_SCHEMA.MKE_DASHBOARDS;
var initialState = {
    pages: (_a = {},
        _a[errorHandlingPages[HOME.PSEUDO_CC]] = [],
        _a[errorHandlingPages[ACCOUNT_MANAGEMENT.PAGES.MANAGE_USERS.PSEUDO_CC]] = [],
        _a[errorHandlingPages[ACCOUNT_MANAGEMENT.PAGES.MANAGE_CUSTOMERS.PSEUDO_CC]] = [],
        _a[errorHandlingPages[ACCOUNT_MANAGEMENT.PAGES.PERMISSION_GROUPS.PSEUDO_CC]] = [],
        _a[errorHandlingPages[DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE.PSEUDO_CC]] = [],
        _a[errorHandlingPages[DASHBOARDS.PAGES.OPENSTACK_USAGE.PSEUDO_CC]] = [],
        _a[errorHandlingPages[DASHBOARDS.PAGES.BOOTSTRAP.PSEUDO_CC]] = [],
        _a[errorHandlingPages[REPORTS.PAGES.INSTALLATION_REPORT.PSEUDO_CC]] = [],
        _a[errorHandlingPages[REPORTS.PAGES.NEW_SIGN_UPS_REPORT.PSEUDO_CC]] = [],
        _a[errorHandlingPages[REPORTS.PAGES.ACTIVE_INSTANCES.PSEUDO_CC]] = [],
        _a[errorHandlingPages[REPORTS.PAGES.TRIAL_REPORT.PSEUDO_CC]] = [],
        _a[errorHandlingPages[SUPPORT.PAGES.CLUSTER_HEALTH.PSEUDO_CC]] = [],
        _a[errorHandlingPages[SUPPORT.PAGES.ALERTS.PSEUDO_CC]] = [],
        _a[errorHandlingPages[MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.PSEUDO_CC]] = [],
        _a),
    errors: [],
};
export var errorHandling = function (state, action) {
    if (state === void 0) { state = initialState; }
    if (action === void 0) { action = {}; }
    var type = action.type, payload = action.payload;
    if (!has(resolvers, type))
        return state;
    var resolver = function (draft) { return resolvers[type](draft, payload); };
    return produce(state, resolver);
};

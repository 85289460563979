import React from 'react';
import CustomerTypesFilterContainer from '@commonComponents/customer-type-filter/CustomerTypesFilterContainer';
import BootstrapFilter from './BootstrapFilter';
import BootstrapEventsTableContainer from './bootstrap-events-table/BootstrapEventsTableContainer';
import BootstrapOverallStatisticContainer from './bootstrap-overall-statistic/BootstrapOverallStatisticContainer';
var Bootstrap = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", null, "Bootstrap Dashboard"),
        React.createElement(CustomerTypesFilterContainer, null),
        React.createElement(BootstrapFilter, { history: props.history }),
        React.createElement(BootstrapOverallStatisticContainer, null),
        React.createElement(BootstrapEventsTableContainer, null)));
};
export default Bootstrap;

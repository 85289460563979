export var MCC_TABLE_TITLE = 'MCC Cluster Overview';
export var MCC_TELEMETRY_DETAIL_VIEW_TABS = ['Telemetry Usage', 'Openstack Availability' /* , 'Specs' */];
export var MCC_CLUSTERS_TABLE_SUBTITLES = [
    {
        name: 'Updating',
        key: 'clusterUpdating',
        parentColumnKey: 'overallInfo',
    },
    {
        name: 'Last poll',
        key: 'lastSyncDate',
        parentColumnKey: 'overallInfo',
    },
    {
        name: 'MCС',
        key: 'mccVersion',
        parentColumnKey: 'versions',
    },
    {
        name: 'K8s',
        key: 'k8sVersion',
        parentColumnKey: 'versions',
    },
    {
        name: 'MKE',
        key: 'mkeVersion',
        parentColumnKey: 'versions',
    },
    {
        name: 'CR',
        key: 'crVersion',
        parentColumnKey: 'versions',
    },
    {
        name: 'CPU',
        key: 'cpuPercentage',
        parentColumnKey: 'utilizationAverages',
    },
    {
        name: 'Memory',
        key: 'memoryPercentage',
        parentColumnKey: 'utilizationAverages',
    },
    {
        name: 'PVCs',
        key: 'disk',
        parentColumnKey: 'utilizationAverages',
    },
    {
        name: 'Nodes',
        key: 'nodeCount',
        parentColumnKey: 'utilizationAverages',
    },
    {
        name: 'MKE API',
        key: 'mkeApiAvailability',
        parentColumnKey: 'utilizationAverages',
    },
    {
        name: 'K8s API',
        key: 'k8sApiAvailability',
        parentColumnKey: 'utilizationAverages',
    },
];
export var MCC_CLUSTERS_TABLE_COLUMNS = [
    {
        name: '',
        key: 'overallInfo',
        subtitles: [MCC_CLUSTERS_TABLE_SUBTITLES[0], MCC_CLUSTERS_TABLE_SUBTITLES[1]],
    },
    {
        name: 'Versions',
        key: 'versions',
        subtitles: [
            MCC_CLUSTERS_TABLE_SUBTITLES[2],
            MCC_CLUSTERS_TABLE_SUBTITLES[3],
            MCC_CLUSTERS_TABLE_SUBTITLES[4],
            MCC_CLUSTERS_TABLE_SUBTITLES[5],
        ],
    },
    {
        name: 'Utilization',
        key: 'utilizationAverages',
        subtitles: [
            MCC_CLUSTERS_TABLE_SUBTITLES[6],
            MCC_CLUSTERS_TABLE_SUBTITLES[7],
            MCC_CLUSTERS_TABLE_SUBTITLES[8],
            MCC_CLUSTERS_TABLE_SUBTITLES[9],
        ],
    },
    {
        name: 'API availability (7 days)',
        key: 'apiAvailability',
        subtitles: [MCC_CLUSTERS_TABLE_SUBTITLES[10], MCC_CLUSTERS_TABLE_SUBTITLES[11]],
    },
];
export var MCC_CUSTOM_CLUSTERS_TABLE_COLUMNS = [
    {
        name: '',
        key: 'overallInfo',
        subtitles: [MCC_CLUSTERS_TABLE_SUBTITLES[0], MCC_CLUSTERS_TABLE_SUBTITLES[1]],
    },
    {
        name: 'Versions',
        key: 'versions',
        subtitles: [
            MCC_CLUSTERS_TABLE_SUBTITLES[2],
            MCC_CLUSTERS_TABLE_SUBTITLES[3],
            MCC_CLUSTERS_TABLE_SUBTITLES[4],
            MCC_CLUSTERS_TABLE_SUBTITLES[5],
        ],
    },
    {
        name: 'Utilization',
        key: 'utilizationAverages',
        subtitles: [
            MCC_CLUSTERS_TABLE_SUBTITLES[6],
            MCC_CLUSTERS_TABLE_SUBTITLES[7],
            MCC_CLUSTERS_TABLE_SUBTITLES[8],
            MCC_CLUSTERS_TABLE_SUBTITLES[9],
        ],
    },
    {
        name: 'API availability',
        key: 'apiAvailability',
        subtitles: [MCC_CLUSTERS_TABLE_SUBTITLES[10], MCC_CLUSTERS_TABLE_SUBTITLES[11]],
    },
];

export var GET_TELEMETRY_CUSTOMERS_TIMEOUT = 300;
export var NO_CUSTOMERS_FOUND = 'No Customers found';
export var COLUMNS = [
    {
        name: 'ID',
        key: 'id',
        sortByKey: 'id',
        isVisibleByDefault: true,
    },
    {
        name: 'Customer Name',
        key: 'name',
        sortByKey: 'name',
        isVisibleByDefault: true,
    },
    {
        name: 'Sf Created At',
        key: 'createdAt',
        sortByKey: 'created_at',
        isVisibleByDefault: true,
    },
    {
        name: 'Sf Last Modified',
        key: 'lastModified',
        sortByKey: 'last_modified',
        isVisibleByDefault: true,
    },
];

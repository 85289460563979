import head from 'lodash/head';
import Redirect from '@root/app/common/utils/urlUtils/Redirect';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
export var isTelemetryCustomers = function (value) {
    return value === DASHBOARDS_SCHEMA.MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.FILTER_PSEUDOS.TELEMETRY_CUSTOMERS;
};
export var getTelemetryCustomersParams = function (_a, pagination, timezone, state) {
    var _b = _a.searchTerm, searchTerm = _b === void 0 ? '' : _b, selected = _a.selected;
    return ({
        search_term: searchTerm.length > 0 ? searchTerm : null,
        timezone: timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
        page: pagination.currentPage,
        limit: pagination.limit,
        sortBy: state.sortBy,
        orderBy: state.orderBy,
        has_active_mke_license: head(selected.hasActiveMkeLicense),
        is_mcc_customer: head(selected.isMccCustomer),
        hide_inactive: head(selected.hideInactiveCustomers),
    });
};
export var setAccountIdToUrlIfSFUser = function (user) { return Redirect.toTelemetryDashboard(user.signInId); };

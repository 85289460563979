export var getNormalizedInstances = function (response) {
    var data = response.data.map(function (instance) { return ({
        id: instance.id,
        name: instance.name,
        customerId: instance.customer_id,
        customerName: instance.customer_name,
    }); });
    return {
        data: data,
        currentPage: response.current_page,
        maxPages: response.max_pages,
    };
};
export var getNormalizedActiveInstancesStatistics = function (response) { return ({
    instanceCount: response.instance_count,
    regionClusterCount: response.region_cluster_count,
    childClusterCount: response.child_cluster_count,
}); };
export var getNormalizedActiveInstances = function (response) {
    var data = response.data.map(function (dataItem) {
        var _a;
        var filteredDetails = (_a = dataItem.cluster_details) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.type || item.version || item.provider || item.node_count; });
        var details = filteredDetails === null || filteredDetails === void 0 ? void 0 : filteredDetails.map(function (detail) { return ({
            id: detail.id,
            type: detail.type,
            version: detail.version,
            provider: detail.provider,
            masterNodeCount: detail.master_node_count,
            workerNodeCount: detail.worker_node_count,
            releaseControllerState: detail === null || detail === void 0 ? void 0 : detail.release_controller_state,
        }); });
        return {
            details: details,
            id: dataItem.id,
            name: dataItem.name,
            customerId: dataItem.customer_id,
            customerName: dataItem.customer_name,
            mccVersion: dataItem.mcc_version,
            lastHeartbeat: dataItem.last_heartbeat,
            mccVersionSyncTime: dataItem.mcc_version_sync_time,
            releaseControllerState: dataItem.release_controller_state,
        };
    });
    return {
        data: data,
        maxPages: response.max_pages,
        currentPage: response.current_page,
    };
};
export var getNormalizedPatchInstances = function (response) { return ({
    id: response.id,
    name: response.name,
    customerId: response.customer_id,
    customerName: response.customer_name,
}); };

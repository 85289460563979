var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo, useState } from 'react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';
import { StyledMkeGroppedTable } from '@commonComponents/gropped-column-table/styled';
import TelemetryClustersTableWithDetailView from '@commonComponents/telemetry-clusters-table-with-detail-view/TelemetryClustersTableWithDetailView';
import TableLoadingStates from '@commonComponents/table-loading-states/TableLoadingStates';
import { PAGINATION_NAMES } from '@configs/DASHBOARD_PSEUDOS';
import { AlertsFilterContext } from '../../telemetry-alerts-filters/TelemetryAlertsFilter';
import { MKE_TABLE_TITLE, NO_CLUSTERS_FOUND, MKE_CLUSTERS_TABLE_COLUMNS, MKE_CLUSTERS_TABLE_SUBTITLES, MKE_CUSTOM_CLUSTERS_TABLE_COLUMNS, MKE_CUSTOM_CLUSTERS_TABLE_SUBTITLES, } from './mkeTelemetryCustomerConstants';
import { INITIAL_ALERTS_FILTER_STATE, CHANGE_ALERTS_FILTER_TIMEOUT, TELEMETRY_ALERTS_FILTERS_CONFIGURATION, } from '../../../../telemetryAlertsFiltersConstants';
import { CLUSTER_TYPES } from '@commonConstants/clustersConstants';
import MkeTelemetryDetailView from './MkeTelemetryDetailView';
import { normalizers } from '../../../../commonTelemetryDashboardUtils';
var HEALTH = CLUSTER_TYPES.HEALTH;
var TYPE = TELEMETRY_ALERTS_FILTERS_CONFIGURATION.TYPE, STATUS = TELEMETRY_ALERTS_FILTERS_CONFIGURATION.STATUS;
var TELEMETRY_CUSTOMER_CLUSTERS_MKE = PAGINATION_NAMES.TELEMETRY_CUSTOMER_CLUSTERS_MKE;
var mkeHeadExtraColumns = [{ key: 'clusterId', title: 'Cluster', className: 'cluster', isTooltip: true }];
var MkeTelemetryTableWrapper = function (props) {
    var _a;
    var clusters = useSelector(function (store) { return get(store, 'telemetry.customer.mkeOverview.clusters', {}); });
    var loading = useSelector(function (store) { return get(store, 'telemetry.customer.loading', false); });
    var _b = useState(INITIAL_ALERTS_FILTER_STATE), alertsFilter = _b[0], changeAlertsFilter = _b[1];
    var clustersList = Object.values(clusters);
    var mkeClusterId = (_a = clusters[props.activeClusterId]) === null || _a === void 0 ? void 0 : _a.clusterId;
    var handleChangeAlertsFilter = function (value) {
        var _a;
        var newAlertsFilter = value
            ? (_a = {},
                _a[TYPE.KEY] = HEALTH,
                _a[STATUS.KEY] = value,
                _a) : INITIAL_ALERTS_FILTER_STATE;
        setTimeout(function () { return changeAlertsFilter(newAlertsFilter); }, CHANGE_ALERTS_FILTER_TIMEOUT);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TableLoadingStates, { data: clustersList, loading: loading, count: 15, className: "mke-clusters-table", empty: { description: 'No MKE Clusters found' } },
            React.createElement(StyledMkeGroppedTable, { className: "mke-clusters-table" },
                React.createElement(TelemetryClustersTableWithDetailView, __assign({}, props, { title: MKE_TABLE_TITLE, normalizers: normalizers, noDataMessage: NO_CLUSTERS_FOUND, columns: props.checkedActive ? MKE_CLUSTERS_TABLE_COLUMNS : MKE_CUSTOM_CLUSTERS_TABLE_COLUMNS, subtitles: props.checkedActive ? MKE_CLUSTERS_TABLE_SUBTITLES : MKE_CUSTOM_CLUSTERS_TABLE_SUBTITLES, paginationName: TELEMETRY_CUSTOMER_CLUSTERS_MKE, data: clustersList, isPdfGeneratingMode: props.isPdfGeneratingMode, cellOnCLick: handleChangeAlertsFilter, extraHeadColumns: mkeHeadExtraColumns, expanded: !isNil(mkeClusterId), bottomElement: props.bottomElement }),
                    React.createElement(AlertsFilterContext.Provider, { value: alertsFilter },
                        React.createElement(MkeTelemetryDetailView, { activeClusterId: mkeClusterId, changeAlertsFilter: function (value) { return changeAlertsFilter(value); }, checkedActive: props.checkedActive }))))),
        props.bottomElement));
};
export default memo(MkeTelemetryTableWrapper);

export var CHART_DASHBOARDS = {
    mkeCluster: 'mkeCluster',
};
export var MKE_CHARTS_KEYS = {
    CPUS: 'cpu',
    MEMORY: 'memory',
    NODE_COUNT: 'node_count',
    CONTAINER_COUNT: 'controller_count',
    CONTROLLER_COUNT: 'container_count',
};

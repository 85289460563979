import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { patchClusterName } from '@redux/dispatchers/clusters';
import MccClusterChart from '@components/telemetry-dashboard/charts/mccClustersCharts/MccClusterCharts';
import AvailabilityClusterCharts from '@components/telemetry-dashboard/charts/availabilityCharts/AvailabilityClusterCharts';
import { StyledRow, StyledItem, StyledLabel, StyledToggle, StyledTelemetryAlertsFilter, } from '@app/common/react/styled/StyledTelemetryCustomer';
import Tabs from '@commonComponents/tabs/Tabs';
import Toggle from '@commonComponents/toggle/Toggle';
import Sliders from '@commonComponents/content-slider/ContentSlider';
import EditableValue from '@commonComponents/editable-value/EditableValue';
import ClusterSpecs from '@root/app/components/telemetry-dashboard/cluster-specs/clusterSpecs';
import { getTabs } from './mccTelemetryDetailViewUtils';
import { getClusterLicense, isMOSCluster } from '../../../../commonTelemetryDashboardUtils';
import { StyledTabs } from '@components/telemetry-dashboard/StyledTabs';
import TelemetryUsageAvailability from '@components/telemetry-dashboard/charts-data-in-tables/TelemetryUsageAvailability';
import { OPENSTACK_USAGE_FIELDS, TELEMETRY_USAGE_FIELDS, TELEMETRY_USAGE_COLUMNS, OPENSTACK_USAGE_COLUMNS, } from '@commonConstants/availabilitychartsConttants';
import { StyledToggledOpacity, StyledToggledDisplay } from '@app/common/react/styled/StyledCommon';
import { NODES_DETAILS } from '@root/app/common/react/components/gropped-column-table/GroupedTableBody';
var MccTelemetryDetailView = function (_a) {
    var activeClusterId = _a.activeClusterId, checkedActive = _a.checkedActive;
    var _b = useState(false), tableMode = _b[0], setTableMode = _b[1];
    var _c = useState(0), activeTabIndex = _c[0], setActiveTabIndex = _c[1];
    var clusters = useSelector(function (store) { return get(store, 'telemetry.customer.mccOverview.clusters', {}); });
    var sfAccount = useSelector(function (store) { return get(store, 'telemetry.customer.sfAccount'); });
    useEffect(function () {
        setActiveTabIndex(0);
        setTableMode(false);
    }, [activeClusterId]);
    if (!activeClusterId || !sfAccount) {
        return null;
    }
    var _d = clusters[activeClusterId], instanceId = _d.instanceId, clusterId = _d.clusterId, managementId = _d.managementId, instanceName = _d.instanceName, clusterName = _d.clusterName, controlPlaneNodes = _d.controlPlaneNodes, computeNodes = _d.computeNodes, osdNodes = _d.osdNodes;
    var clusterLicense = getClusterLicense(clusters, activeClusterId);
    var isMOS = isMOSCluster(clusters[activeClusterId]);
    var onSubmit = function (value) {
        return patchClusterName({ id: clusterId, name: value, table: 'mccOverview', dashboard: 'telemetry' });
    };
    var handleSetActiveTabIndex = function (newTab) {
        var index = getTabs(isMOS).indexOf(newTab);
        setActiveTabIndex(index < 0 ? 0 : index);
    };
    var shouldShowToggle = isMOS ? [0, 1].includes(activeTabIndex) : [0].includes(activeTabIndex);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledTabs, null,
            React.createElement(Tabs, { activeTabFromUrl: "", shouldHideBorder: true, tabs: getTabs(isMOS), active: getTabs(isMOS)[activeTabIndex], handleChange: function (newTab) { return handleSetActiveTabIndex(newTab); } })),
        React.createElement(StyledTelemetryAlertsFilter, null,
            React.createElement(StyledRow, null,
                React.createElement(StyledItem, null,
                    React.createElement(StyledLabel, null, "Instance id:"),
                    instanceId),
                React.createElement(StyledItem, null,
                    React.createElement(StyledLabel, null, "Cluster id:"),
                    clusterId)),
            React.createElement(StyledRow, null,
                React.createElement(StyledItem, null,
                    React.createElement(StyledLabel, null, "Cluster name:"),
                    React.createElement(EditableValue, { onSubmit: onSubmit }, clusterName)),
                React.createElement(StyledItem, null,
                    React.createElement(StyledLabel, null, "Management id:"),
                    managementId),
                React.createElement(StyledItem, null,
                    React.createElement(StyledLabel, null, "Instance name:"),
                    instanceName),
                shouldShowToggle ? (React.createElement(StyledToggle, null,
                    React.createElement(Toggle, { initialChecked: tableMode, className: "table-mode-toggle", onChange: function () { return setTableMode(!tableMode); }, label: "Table mode" }))) : ('')),
            React.createElement(StyledRow, null,
                React.createElement(StyledItem, null,
                    React.createElement(StyledLabel, null,
                        NODES_DETAILS.controlPlaneNodes,
                        ":"),
                    controlPlaneNodes),
                React.createElement(StyledItem, null,
                    React.createElement(StyledLabel, null,
                        NODES_DETAILS.computeNodes,
                        ":"),
                    computeNodes),
                React.createElement(StyledItem, null,
                    React.createElement(StyledLabel, null,
                        NODES_DETAILS.osdNodes,
                        ":"),
                    osdNodes))),
        React.createElement(Sliders, { activeIndex: activeTabIndex },
            React.createElement(StyledToggledOpacity, { isVisible: activeTabIndex === 0 },
                React.createElement(StyledToggledDisplay, { isVisible: tableMode },
                    React.createElement(TelemetryUsageAvailability, { dateKey: "metric_time", id: "mcc-telemetry-availability-table", path: "mccCluster.mccClusterCharts", activeClusterId: activeClusterId, columns: TELEMETRY_USAGE_COLUMNS, fields: TELEMETRY_USAGE_FIELDS })),
                React.createElement(StyledToggledDisplay, { isVisible: !tableMode },
                    React.createElement(MccClusterChart, { isMOS: isMOS, activeClusterId: activeClusterId, clusterLicense: clusterLicense, shouldFetchCharts: true, checkedActive: checkedActive, shouldDisplayAvailabilityCharts: true }))),
            isMOS && (React.createElement(StyledToggledOpacity, { isVisible: activeTabIndex === 1 },
                React.createElement(StyledToggledDisplay, { isVisible: tableMode },
                    React.createElement(TelemetryUsageAvailability, { dateKey: "time", id: "mcc-openstack-availability-table", path: "mccClusterAvailabilityCharts.availabilityCharts", activeClusterId: activeClusterId, columns: OPENSTACK_USAGE_COLUMNS, fields: OPENSTACK_USAGE_FIELDS })),
                React.createElement(StyledToggledDisplay, { isVisible: !tableMode },
                    React.createElement(AvailabilityClusterCharts, { activeClusterId: activeClusterId, clusterLicense: clusterLicense, shouldFetchCharts: true, checkedActive: checkedActive })))),
            React.createElement(StyledToggledOpacity, { isVisible: activeTabIndex === (isMOS ? 2 : 1) }, activeTabIndex === (isMOS ? 2 : 1) ? React.createElement(ClusterSpecs, { clusterId: activeClusterId }) : ''))));
};
export default MccTelemetryDetailView;

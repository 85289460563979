export var ROUTES_IDS = {
    HOME: 'home',
    MANAGE_CUSTOMERS_CONTAINER: 'manage_customers',
    MANAGE_USERS: 'manage_users',
    PERMISSION_GROUP: 'permission_group',
    CONTAINER_CLOUD_USAGE: 'container_cloud_usage',
    OPENSTACK_USAGE: 'openstack_usage',
    OPENSTACK_CLUSTERS_USAGE: 'openstack_cluster_usage',
    ALERTS: 'alerts',
    BOOTSTRAP: 'bootstrap_dashboard',
    CLUSTER_HEALTH: 'cluster_health',
    INSTALLATION_REPORT: 'installation_report',
    NEW_SIGNUPS: 'new_sign_ups',
    ACTIVE_INSTANCES: 'active_instances',
    TRIAL_REPORT: 'trial_report',
    TRIAL_COSTS: 'trial_costs',
    MCC_DASHBOARDS: 'mcc_dashboards',
    SEARCH_CLUSTERS: 'general_search_dashboard',
};

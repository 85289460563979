var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
var navLink = '.nav__link';
var navLinkChild = '.nav__link__child';
export var StyledTooltip = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    .nav__link__child {\n        display: flex;\n        flex-direction: column;\n        background: ", ";\n\n        .nav__link {\n            padding: 10px;\n            color: ", ";\n\n            &.match,\n            &:hover {\n                color: black;\n                background: ", ";\n            }\n        }\n    }\n"], ["\n    .nav__link__child {\n        display: flex;\n        flex-direction: column;\n        background: ", ";\n\n        .nav__link {\n            padding: 10px;\n            color: ", ";\n\n            &.match,\n            &:hover {\n                color: black;\n                background: ", ";\n            }\n        }\n    }\n"])), function (props) { return props.theme.color.background.layer3; }, function (props) { return props.theme.color.text.body2; }, function (props) { return props.theme.color.background.layer4; });
export var NavBarStyled = styled.nav(function (_a) {
    var theme = _a.theme;
    return "\n    & > ".concat(navLink, " {\n        font-weight: 600;\n    }\n\n    & > ").concat(navLinkChild, " {\n        & > ").concat(navLink, " {\n            width: 100%;\n            padding: 13px 16px 10px 46px;         \n        }\n    }\n\n    &.collapsed {\n    ").concat(navLink, " {\n     &.match {\n           position: relative;\n            background: ").concat(theme.color.background.layer4, ";\n            border-left-color: ").concat(theme.color.action.primary.normal.enabled.border, ";\n        }\n      }\n    }\n    \n    ").concat(navLink, " {\n        box-sizing: border-box;\n        border-left: 4px solid transparent;\n        padding: 11px 14px 11px 15px;\n        display: flex;\n        align-items: center;\n        cursor: pointer;\n\n        &:hover {\n            text-decoration: none;\n        }\n\n        &.nav__drop {\n            &.opened .nav__link__angle {\n                transform: rotate(-180deg);\n            }\n        }\n\n        &.nav__drop > .nav__link__name + svg {\n              margin-left: auto;\n        }\n\n        &.disabled {\n            opacity: 0.6;\n            cursor: default;\n        }\n\n        &__img {\n            width: 20px;\n            height: 22px;\n            margin-right: 10px;\n        }\n\n        &__name {\n            color: ").concat(theme.color.text.body1, ";\n            font-size: 13px;\n        }\n\n        .mke_svg {\n            width: 22px;\n            margin-left: -2px;\n        }\n\n        .home_svg {\n            margin-left: -4px;\n        }\n\n        .account_management_svg {\n            margin-left: -2px;\n        }\n\n        .reports_svg {\n            margin-left: 2px;\n        }\n\n        &.match {\n            position: relative;\n            background: ").concat(theme.color.background.layer4, ";\n        }\n\n        &.match:not(.nav__drop) {\n            border-left-color: ").concat(theme.color.action.primary.normal.enabled.border, ";\n        }\n    }\n");
});
var templateObject_1;

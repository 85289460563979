import * as Actions from '@redux/actions';
import { Localstorage } from '@utils/localstorage/Localstorage';
import { LOCAL_STORAGE_KEYS } from '@commonConstants/localStorageConstancts';
var r = {};
r[Actions.mode.SyncActions.SET_CUSTOMER_TYPES] = function (draft, payload) {
    draft.loading = false;
    Object.assign(draft.options, payload);
};
r[Actions.mode.SyncActions.SET_SELECTED_OPTIONS] = function (draft, payload) {
    var mode = payload.mode, lastSyncDate = payload.lastSyncDate;
    if (mode) {
        draft.selected = payload.mode;
    }
    if (lastSyncDate) {
        draft.lastSyncDate.selected = payload.lastSyncDate;
    }
};
r[Actions.mode.SyncActions.DROP_LAST_SYNC_DATE] = function (draft) {
    Localstorage.set(LOCAL_STORAGE_KEYS.LAST_SYNC_DATE, JSON.stringify(draft.lastSyncDate.options[0]));
    draft.lastSyncDate.selected = draft.lastSyncDate.options[0];
};
export { r as resolvers };

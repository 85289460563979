export var SEARCH_LABEL = 'Trial Customer search';
export var ERROR_GET_DATA = 'Failed to get static stats banner data';
export var REPORT_TITLE = 'Trial Report';
export var REPORT_STATISTIC_TITLE = 'Trial Statistics';
export var SEARCH_DELAY = 1500;
var enumSort = "desc";
export var INITIAL_STATE = {
    sortBy: 'email',
    orderBy: enumSort,
};
export var STATE_OPTIONS = [
    'Started',
    'Not Started',
    'Finished',
    'Terminating',
];
export var IS_MIRANTIS_SELECT_OPTIONS = ['Yes', 'No'];
export var CLUSTER_CREATED_OPTIONS = ['Yes', 'No'];
export var PICKER_TO_REQUEST_VOCABULAR = {
    'Not Started': 'notstarted',
    Started: 'started',
    Finished: 'finished',
    Terminating: 'terminating',
    Yes: 'true',
    No: 'false',
};
export var RESPONSE_TO_STATE_VOCABULAR = {
    notstarted: 'Not Started',
    started: 'Started',
    finished: 'Finished',
    terminating: 'Terminating',
};
export var TRIAL_REPORT_STATUS_PICKER = { name: 'State', pseudo: 'state', static: true };
export var TRIAL_REPORT_IS_MIRANTIS_PICKER = { name: 'Is mirantis', pseudo: 'isMirantis', static: true };
export var TRIAL_REPORT_CLUSTER_CREATED_PICKER = { name: 'Cluster created', pseudo: 'clusterCreated', static: true };
export var COLUMNS_KEYS = {
    ID: 'id',
    EMAIL: 'email',
    IS_MIRANTIS: 'isMirantis',
    STATUS: 'status',
    CREATED_AT: 'createdAt',
    STARTED_AT: 'startedAt',
    EXPIRED_AT: 'expiredAt',
    IS_CLUSTER_CREATED: 'isClusterCreated',
    LICENSE_DOWNLOADED_AT: 'licenseDownloadedAt',
};
export var COLUMNS = [
    {
        name: 'Email',
        key: COLUMNS_KEYS.EMAIL,
        sortByKey: 'email',
        isVisibleByDefault: true,
    },
    {
        name: 'Mirantis',
        key: COLUMNS_KEYS.IS_MIRANTIS,
        sortByKey: 'is_mirantis',
        isVisibleByDefault: true,
    },
    {
        name: 'Status',
        key: COLUMNS_KEYS.STATUS,
        sortByKey: 'status',
        isVisibleByDefault: true,
    },
    {
        name: 'Created At',
        key: COLUMNS_KEYS.CREATED_AT,
        sortByKey: 'u.created_at',
        isVisibleByDefault: true,
    },
    {
        name: 'Started At',
        key: COLUMNS_KEYS.STARTED_AT,
        sortByKey: 'started_at',
        isVisibleByDefault: true,
    },
    {
        name: 'Expired At',
        key: COLUMNS_KEYS.EXPIRED_AT,
        sortByKey: 'expired_at',
        isVisibleByDefault: true,
    },
    {
        name: 'Cluster Created',
        key: COLUMNS_KEYS.IS_CLUSTER_CREATED,
        sortByKey: 'cluster_created',
        isVisibleByDefault: true,
    },
    {
        name: 'License Downloaded At',
        key: COLUMNS_KEYS.LICENSE_DOWNLOADED_AT,
        sortByKey: 'license_downloaded_at',
        isVisibleByDefault: false,
    },
];
export var TRIAL_REPORT_STATS_BANNER_DATA = [
    {
        name: 'All users',
        pseudo: 'fullCount',
    },
    {
        name: 'Mirantis Employees',
        pseudo: 'mirantisCount',
    },
    {
        name: 'Created Cluster',
        pseudo: 'clusterCreatedCount',
    },
];

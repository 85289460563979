var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as Actions from '@redux/actions';
import get from 'lodash/get';
import { getMultiFilterInitialState } from '@configs/multiFilterConfig';
export var r = {};
r[Actions.multiFilter.SyncActions.SET_FILTER_DATA] = function (draft, payload) {
    draft.loading = false;
    Object.assign(draft, payload);
};
r[Actions.multiFilter.SyncActions.UNMOUNT_FILTER] = function (draft) {
    draft.loading = true;
    draft.name = '';
    draft.filter.selected = [];
    draft.filter.searchTerm = '';
    draft.filter.configuration = {};
};
r[Actions.multiFilter.SyncActions.RESET_FILTER_PICKERS] = function (draft, payload) {
    var selected = JSON.parse(JSON.stringify(get(draft.filter, 'selected', {})));
    for (var pickerOption in selected) {
        if (payload.hasToBeUpdated.includes(pickerOption)) {
            selected[pickerOption] = [];
        }
    }
    draft.filter.selected = selected;
    draft.filter.hasToBeUpdated = payload.hasToBeUpdated;
};
r[Actions.multiFilter.SyncActions.SET_SELECTED_PICKER_OPTIONS] = function (draft, payload) {
    var picker = payload.picker, options = payload.options;
    var pseudo = picker.pseudo, effectsOn = picker.effectsOn;
    draft.filter.selected[pseudo] = options;
    if (effectsOn) {
        for (var _i = 0, effectsOn_1 = effectsOn; _i < effectsOn_1.length; _i++) {
            var opt = effectsOn_1[_i];
            draft.filter.selected[opt] = [];
            draft.filter.configuration[opt].data = [];
        }
        draft.filter.hasToBeUpdated = effectsOn;
    }
};
r[Actions.multiFilter.SyncActions.UPDATE_PICKER_OPTIONS] = function (draft, payload) {
    var option = draft.filter.configuration[payload.optionsKey];
    option[payload.termPseudo.termPseudoCC] = payload.term;
    option.currentPage = payload.data.currentPage;
    option.maxPages = payload.data.maxPages;
    option.data = payload.data.data;
};
r[Actions.multiFilter.SyncActions.PUSH_NEW_PAGINATION_OPTIONS] = function (draft, payload) {
    var option = draft.filter.configuration[payload.optionsKey];
    option.currentPage = payload.data.currentPage;
    option.maxPages = payload.data.maxPages;
    option.data = __spreadArray(__spreadArray([], option.data, true), payload.data.data, true);
};
r[Actions.filtration.SyncActions.SET_FILTER_DATE_RANGE] = function (draft, payload) {
    draft.filter.dateRange.selected = payload.selected;
    draft.filter.dateRange.calculatedDateRange = payload.calculatedDateRange;
};
r[Actions.multiFilter.SyncActions.SET_UPDATED_PICKERS_DATA] = function (draft, payload) {
    payload.hasToBeUpdated.forEach(function (picker, idx) {
        // TODO: consider to remove this if
        if (!draft.filter.configuration[picker]) {
            return;
        }
        var option = draft.filter.configuration[picker];
        option.data = payload.data[idx].data;
        option.currentPage = payload.data[idx].currentPage;
        option.maxPages = payload.data[idx].maxPages;
    });
    draft.filter.hasToBeUpdated = null;
};
r[Actions.multiFilter.SyncActions.CHANGE_GRANULARITY] = function (draft, payload) {
    draft.filter.granularity.selected = payload;
};
r[Actions.multiFilter.SyncActions.CHANGE_TERMV2] = function (draft, payload) {
    draft.filter[payload.pseudo] = payload.value;
};
r[Actions.multiFilter.SyncActions.CHANGE_SINGLE_SELECT_PICKER_OPTION] = function (draft, payload) {
    draft.filter.selected[payload.pseudo] = [payload.value];
};
r[Actions.multiFilter.SyncActions.DROP_FILTER_TO_DEFAULT] = function (draft) {
    var initialState = getMultiFilterInitialState(draft.name);
    draft.filter.selected = initialState.config.selected;
};

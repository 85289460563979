var _a;
import { CLUSTER_STATUSES, CLUSTER_TYPES } from '@commonConstants/clustersConstants';
var CRITICAL = CLUSTER_STATUSES.CRITICAL, WARNING = CLUSTER_STATUSES.WARNING, INFO = CLUSTER_STATUSES.INFO;
var HEALTH = CLUSTER_TYPES.HEALTH, UTILIZATION = CLUSTER_TYPES.UTILIZATION;
export var CHANGE_ALERTS_FILTER_TIMEOUT = 100;
export var ALL = 'All';
export var TELEMETRY_ALERTS_FILTERS_CONFIGURATION = {
    TYPE: {
        KEY: 'type',
        TITLE: 'Type',
        DEFAULT_ITEM_SELECTED: ALL,
        SWITCHERS: [
            { id: ALL, item: ALL },
            { id: UTILIZATION, item: UTILIZATION },
            { id: HEALTH, item: HEALTH },
        ],
    },
    STATUS: {
        KEY: 'status',
        TITLE: 'Status',
        DEFAULT_ITEM_SELECTED: ALL,
        SWITCHERS: [
            { id: ALL, item: ALL },
            { id: CRITICAL, item: CRITICAL },
            { id: WARNING, item: WARNING },
            { id: INFO, item: INFO },
        ],
    },
};
export var INITIAL_ALERTS_FILTER_STATE = (_a = {},
    _a[TELEMETRY_ALERTS_FILTERS_CONFIGURATION.TYPE.KEY] = ALL,
    _a[TELEMETRY_ALERTS_FILTERS_CONFIGURATION.STATUS.KEY] = ALL,
    _a);

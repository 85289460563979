import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import Line from '@commonComponents/chart/Line/Line';
import { fetchCharts, dropChartsToDefault } from '@redux/dispatchers/charts';
import { getChartOptions, getDynamicValueUnit, getNormalizedABSYValue, getNormalizedABSTooltipValue, getNormalizedDynamicGbYAxisData, getNormalizedChartDataDynamicKey, getNormalizedDynamicGbTooltipValue, getNormalizedPercentageTooltipValue, getNormalizedConvertedInPercentageValue, getNormalizedChartDataInPercentageConverted, } from '@commonUtils/charts/chartUtils';
import { DateUtils } from '@utils/date/DateUtils';
import { STATIC_REPORTS_DATE_RANGES } from '@utils/date/dateConstants';
import { CHART_MESSAGES } from '@commonConstants/chartsConstants';
import { MCC_CHARTS_KEYS, CHART_DASHBOARDS } from './mccClusterChartsConstants';
import { getChartsConfigs } from './mccClusterChartsUtils';
import { StyledMccClusterCharts } from './StyledMccClusterCharts';
import { StyledChartsWrapperNew, StyledChartsContainer } from '@app/common/react/styled/StyledChartsWrapperNew';
var VMS = MCC_CHARTS_KEYS.VMS, NODE = MCC_CHARTS_KEYS.NODE, PODS = MCC_CHARTS_KEYS.PODS, CONTAINERS = MCC_CHARTS_KEYS.CONTAINERS, CPU_USAGE_ABS = MCC_CHARTS_KEYS.CPU_USAGE_ABS, RAM_USAGE_ABS = MCC_CHARTS_KEYS.RAM_USAGE_ABS, DISK_USAGE_ABS = MCC_CHARTS_KEYS.DISK_USAGE_ABS, K8S_AVAILABILITY = MCC_CHARTS_KEYS.K8S_AVAILABILITY, MKE_AVAILABILITY = MCC_CHARTS_KEYS.MKE_AVAILABILITY, CPU_USAGE_CAPACITY = MCC_CHARTS_KEYS.CPU_USAGE_CAPACITY, RAM_USAGE_CAPACITY = MCC_CHARTS_KEYS.RAM_USAGE_CAPACITY;
var MccClusterCharts = function (props) {
    var _a = useSelector(function (store) {
        var _a, _b, _c, _d, _e, _f;
        return ({
            timezone: store.userInfo.userPreferences.timezone,
            data: get((_c = (_b = (_a = store.charts) === null || _a === void 0 ? void 0 : _a.mccCluster) === null || _b === void 0 ? void 0 : _b.mccClusterCharts) === null || _c === void 0 ? void 0 : _c.data, "".concat(props.activeClusterId), []),
            loading: (_f = (_e = (_d = store.charts) === null || _d === void 0 ? void 0 : _d.mccCluster) === null || _e === void 0 ? void 0 : _e.mccClusterCharts) === null || _f === void 0 ? void 0 : _f.loading,
            calculatedDateRange: store.crossPageFilter.dateRange.calculatedDateRange,
            crossPageFilter: store.crossPageFilter,
        });
    }), timezone = _a.timezone, data = _a.data, loading = _a.loading, calculatedDateRange = _a.calculatedDateRange, crossPageFilter = _a.crossPageFilter;
    var t = timezone === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone;
    var _b = DateUtils.calculateDateRangeByOption(STATIC_REPORTS_DATE_RANGES.PREVIOUS_1_MONTHS), start = _b.start, end = _b.end;
    var params = {
        timezone: t,
        start: props.checkedActive || crossPageFilter.dashboardName === null
            ? start.timeStamp
            : calculatedDateRange === null || calculatedDateRange === void 0 ? void 0 : calculatedDateRange.start.timeStamp,
        end: props.checkedActive || crossPageFilter.dashboardName === null
            ? end.timeStamp
            : calculatedDateRange === null || calculatedDateRange === void 0 ? void 0 : calculatedDateRange.end.timeStamp,
        is_historical_data: props.checkedActive ? undefined : true,
        granularity: '1 day',
        cluster: props.activeClusterId,
        license: props.clusterLicense,
        activeClusterId: props.activeClusterId,
    };
    var getChartKeys = function (key) { return ({
        key: key,
        clusterId: props.activeClusterId,
        dashboard: CHART_DASHBOARDS.mccCluster,
    }); };
    useEffect(function () {
        if (props.shouldFetchCharts) {
            fetchCharts(getChartsConfigs(params));
        }
        return function () {
            dropChartsToDefault(CHART_DASHBOARDS.mccCluster);
        };
    }, [props.activeClusterId]);
    return (React.createElement(StyledChartsContainer, { className: "mcc-cluster-charts" },
        React.createElement(StyledMccClusterCharts, null,
            React.createElement(StyledChartsWrapperNew, { className: "charts two" },
                React.createElement(Line, { chartKeys: getChartKeys(CPU_USAGE_CAPACITY + CPU_USAGE_ABS), leftLabel: "cores", loadingOut: loading, title: "CPU Usage & Capacity", datasetsTitles: ['capacity', 'usage'], datasets: [
                        getNormalizedChartDataDynamicKey(data, CPU_USAGE_CAPACITY),
                        getNormalizedChartDataDynamicKey(data, CPU_USAGE_ABS),
                    ], message: CHART_MESSAGES.CPU_USAGE_AND_CAPACITY, chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedABSYValue,
                        getNormalizedTooltipValue: getNormalizedABSTooltipValue,
                    }) }),
                React.createElement(Line, { chartKeys: getChartKeys(RAM_USAGE_CAPACITY + RAM_USAGE_ABS), leftLabel: "bytes", loadingOut: loading, title: "RAM Usage & Capacity", datasetsTitles: ['capacity', 'usage'], dynamicLabelFormatter: getDynamicValueUnit, datasets: [
                        getNormalizedChartDataDynamicKey(data, RAM_USAGE_CAPACITY),
                        getNormalizedChartDataDynamicKey(data, RAM_USAGE_ABS),
                    ], message: CHART_MESSAGES.RAM_USAGE_AND_CAPACITY, chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedDynamicGbYAxisData,
                        getNormalizedTooltipValue: getNormalizedDynamicGbTooltipValue,
                    }) }))),
        React.createElement(StyledMccClusterCharts, null,
            React.createElement(StyledChartsWrapperNew, { className: "two" },
                React.createElement(Line, { chartKeys: getChartKeys(DISK_USAGE_ABS), title: "PVS Usage", leftLabel: "bytes", loadingOut: loading, dynamicLabelFormatter: getDynamicValueUnit, datasets: [getNormalizedChartDataDynamicKey(data, DISK_USAGE_ABS)], message: CHART_MESSAGES.PVS_USAGE_AND_CAPACITY, chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedDynamicGbYAxisData,
                        getNormalizedTooltipValue: getNormalizedDynamicGbTooltipValue,
                    }) }),
                React.createElement(Line, { chartKeys: getChartKeys(NODE), leftLabel: "Nodes", loadingOut: loading, title: "Nodes Count", datasets: [getNormalizedChartDataDynamicKey(data, NODE)], message: CHART_MESSAGES.NODE_USAGE, chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedABSYValue,
                        getNormalizedTooltipValue: getNormalizedABSTooltipValue,
                    }) }))),
        React.createElement(StyledMccClusterCharts, null,
            React.createElement(StyledChartsWrapperNew, { className: props.isMOS ? 'three' : 'two' },
                React.createElement(Line, { chartKeys: getChartKeys(PODS), title: "Pods", message: "", loadingOut: loading, dashboard: CHART_DASHBOARDS.mccCluster, datasets: [getNormalizedChartDataDynamicKey(data, PODS)], chartOptions: getChartOptions({
                        maxXAxisTicksLimit: props.isMOS ? 3 : 5,
                        getNormalizedYValue: getNormalizedABSYValue,
                        getNormalizedTooltipValue: getNormalizedABSTooltipValue,
                    }) }),
                React.createElement(Line, { chartKeys: getChartKeys(CONTAINERS), message: "", loadingOut: loading, title: "Containers", datasets: [getNormalizedChartDataDynamicKey(data, CONTAINERS)], chartOptions: getChartOptions({
                        maxXAxisTicksLimit: props.isMOS ? 3 : 5,
                        getNormalizedYValue: getNormalizedABSYValue,
                        getNormalizedTooltipValue: getNormalizedABSTooltipValue,
                    }) }),
                props.isMOS ? (React.createElement(Line, { chartKeys: getChartKeys(VMS), title: "VMS", message: "", loadingOut: loading, datasets: [getNormalizedChartDataDynamicKey(data, VMS)], chartOptions: getChartOptions({
                        maxXAxisTicksLimit: props.isMOS ? 3 : 5,
                        getNormalizedYValue: getNormalizedABSYValue,
                        getNormalizedTooltipValue: getNormalizedABSTooltipValue,
                    }) })) : (''))),
        props.shouldDisplayAvailabilityCharts ? (React.createElement(StyledMccClusterCharts, null,
            React.createElement(StyledChartsWrapperNew, { className: "two" },
                React.createElement(Line, { chartKeys: getChartKeys(MKE_AVAILABILITY), loadingOut: loading, title: "MKE API Availability", datasets: [getNormalizedChartDataInPercentageConverted(data, MKE_AVAILABILITY)], chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedConvertedInPercentageValue(),
                        getNormalizedTooltipValue: getNormalizedPercentageTooltipValue,
                        yValue: { min: 0, max: 100 },
                    }) }),
                React.createElement(Line, { chartKeys: getChartKeys(K8S_AVAILABILITY), loadingOut: loading, title: "K8s API Availability", datasets: [getNormalizedChartDataInPercentageConverted(data, K8S_AVAILABILITY)], chartOptions: getChartOptions({
                        getNormalizedYValue: getNormalizedConvertedInPercentageValue(),
                        getNormalizedTooltipValue: getNormalizedPercentageTooltipValue,
                        yValue: { min: 0, max: 100 },
                    }) })))) : ('')));
};
export default memo(MccClusterCharts);

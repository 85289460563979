var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { Button } from '@mirantis/mds-button';
import { useSelector } from 'react-redux';
import { setUserInfo } from '@redux/dispatchers/userInfo';
import { signOut } from '@root/index';
import ButtonWrapper from '@commonComponents/button-wrapper/ButtonWrapper';
import ErrorBanner from '@commonComponents/error-baner/ErrorBanner';
import { getCloserAvailableDashboard } from '@commonUtils/commonUtils';
import Redirect from '@commonUtils/urlUtils/Redirect';
export var NoPermissionToPage = function () {
    var activeUser = useSelector(function (state) { return state.userInfo.activeUser; });
    var closerAvailablePage = getCloserAvailableDashboard();
    useEffect(function () {
        if (activeUser.firstLogin) {
            Redirect.goTo(closerAvailablePage);
            setUserInfo(__assign(__assign({}, activeUser), { firstLogin: false }));
        }
    }, [activeUser.firstLogin]);
    return activeUser.firstLogin && closerAvailablePage ? null : (React.createElement(React.Fragment, null,
        React.createElement(ErrorBanner, { title: "Unsuccessful access", message: React.createElement("span", null,
                "You have no permissions to this page. Please contact MCNC administrator at",
                ' ',
                React.createElement("a", { href: "mailto:mcnc-support@mirantis.com" }, "mcnc-support@mirantis.com")) },
            React.createElement(ButtonWrapper, { justify: "center" },
                React.createElement(Button, { label: "Logout", onClick: signOut })))));
};

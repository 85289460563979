import { COLUMNS } from './telemetryAlertsTableConstants';
import { ALL } from '../telemetry-alerts-filters/telemetryAlertsFiltersConstants';
export var isStatusValid = function (status, filter) { return filter === ALL || status === filter; };
export var isTypeValid = function (type, filter) { return filter === ALL || type === filter; };
export var getFilteredAlerts = function (data, filter) {
    var rows = data
        ? data.filter(function (_a) {
            var status = _a.status, type = _a.type;
            return isStatusValid(status, filter.status) && isTypeValid(type, filter.type);
        })
        : [];
    return {
        columns: COLUMNS,
        rows: rows,
    };
};

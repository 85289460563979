var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, all, takeEvery, select } from 'redux-saga/effects';
import { getSFAccounts, getSFAccount } from '@api/salesforce-api/salesforceApi';
import { getClusterSpecs as getClusterSpecsAPI } from '@api/clusters-api/clustersApi';
import { toggleClustersStatus, getMKEOverview, getMCCOverview, getMKEAlerts as getMKEAlertsAPI, getMKERecommendations as getMKERecommendationsAPI, } from '@api/mke-api/mkeApi';
import { setTelemetryCustomers, setTelemetryCustomer, setMKEAlerts, setMKERecommendations, setTelemetryCustomerError, setClusterSpecs, } from '@actionCreators/telemetry';
import { getNormalizedClusters, getFormattedMKEAlerts, getFormattedRecommendations } from './telemetrySagaUtils';
import * as Actions from '@redux/actions';
function getTelemetryCustomers(action) {
    var res, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                return [4 /*yield*/, call(getSFAccounts, action.payload)];
            case 1:
                res = _a.sent();
                if (!res) return [3 /*break*/, 3];
                return [4 /*yield*/, put(setTelemetryCustomers(res))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3: return [3 /*break*/, 5];
            case 4:
                e_1 = _a.sent();
                console.error(e_1);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function getClusterSpecs(action) {
    var res, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                return [4 /*yield*/, call(getClusterSpecsAPI, { cluster_ids: action.payload.clusterIds })];
            case 1:
                res = _a.sent();
                if (!res) return [3 /*break*/, 3];
                return [4 /*yield*/, put(setClusterSpecs(res))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3: return [3 /*break*/, 5];
            case 4:
                e_2 = _a.sent();
                console.error(e_2);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function getTelemetryCustomer(action) {
    var payload, sfAccountPrev, sfAccount, _a, _b, mkeOverview, mccOverview, finalMKEOverview, finalMCCOverview, customerPayload, e_3;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                payload = action.payload;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 10, , 11]);
                return [4 /*yield*/, select()];
            case 2:
                sfAccountPrev = (_c.sent()).telemetry.customer.sfAccount;
                if (!sfAccountPrev) return [3 /*break*/, 3];
                _a = sfAccountPrev;
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, getSFAccount(payload)];
            case 4:
                _a = _c.sent();
                _c.label = 5;
            case 5:
                sfAccount = _a;
                if (!!sfAccount) return [3 /*break*/, 7];
                return [4 /*yield*/, put(setTelemetryCustomerError({
                        status: 404,
                        type: 'sf_customer',
                        message: 'SF Account not found',
                    }))];
            case 6:
                _c.sent();
                return [2 /*return*/];
            case 7: return [4 /*yield*/, Promise.all([
                    getMKEOverview({
                        hidden: payload.hidden,
                        account_id: sfAccount.id,
                        timezone: payload.timezone,
                        inactive_hours_threshold: payload.inactiveHoursThreshold,
                        is_historical_data: payload.is_historical_data,
                        start: payload.start,
                        end: payload.end,
                    }),
                    getMCCOverview({
                        hidden: payload.hidden,
                        account_id: sfAccount.id,
                        timezone: payload.timezone,
                        inactive_hours_threshold: payload.inactiveHoursThreshold,
                        is_historical_data: payload.is_historical_data,
                        start: payload.start,
                        end: payload.end,
                    }),
                ])];
            case 8:
                _b = _c.sent(), mkeOverview = _b[0], mccOverview = _b[1];
                finalMKEOverview = __assign(__assign({}, mkeOverview), { clusters: getNormalizedClusters(mkeOverview.clusters) });
                finalMCCOverview = __assign(__assign({}, mccOverview), { allClustersInformationMke: mkeOverview.allClustersCount, clusters: getNormalizedClusters(mccOverview.clusters) });
                customerPayload = {
                    sfAccount: sfAccount,
                    overview: finalMKEOverview,
                    mkeOverview: finalMKEOverview,
                    mccOverview: finalMCCOverview,
                };
                return [4 /*yield*/, put(setTelemetryCustomer(customerPayload))];
            case 9:
                _c.sent();
                return [3 /*break*/, 11];
            case 10:
                e_3 = _c.sent();
                console.error(e_3);
                return [3 /*break*/, 11];
            case 11: return [2 /*return*/];
        }
    });
}
function getMKEAlerts(action) {
    var res, formattedMKEAlerts, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                return [4 /*yield*/, call(getMKEAlertsAPI, action.payload)];
            case 1:
                res = _a.sent();
                if (!res) return [3 /*break*/, 3];
                formattedMKEAlerts = getFormattedMKEAlerts(res);
                return [4 /*yield*/, put(setMKEAlerts(__assign(__assign({}, formattedMKEAlerts), { clusterId: action.payload.cluster_id })))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3: return [3 /*break*/, 5];
            case 4:
                e_4 = _a.sent();
                console.error(e_4);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function getMKERecommendations(action) {
    var recommendations, formattedRecommendations, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                return [4 /*yield*/, call(getMKERecommendationsAPI, action.payload)];
            case 1:
                recommendations = _a.sent();
                if (!recommendations) return [3 /*break*/, 3];
                formattedRecommendations = getFormattedRecommendations(recommendations);
                return [4 /*yield*/, put(setMKERecommendations(__assign(__assign({}, formattedRecommendations), { clusterId: action.payload.cluster_id })))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3: return [3 /*break*/, 5];
            case 4:
                e_5 = _a.sent();
                console.error(e_5);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function toggleTelemetryClusterStatus(action) {
    var _a, clusterIds, hidden, type, params, e_6;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, clusterIds = _a.clusterIds, hidden = _a.hidden, type = _a.type;
                params = {
                    cluster_ids: clusterIds,
                    hidden: hidden,
                    type: type,
                };
                _b.label = 1;
            case 1:
                _b.trys.push([1, 3, , 4]);
                return [4 /*yield*/, call(toggleClustersStatus, params)];
            case 2:
                _b.sent();
                return [3 /*break*/, 4];
            case 3:
                e_6 = _b.sent();
                console.error(e_6);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
export default function telemetrySaga() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeEvery(Actions.telemetry.AsyncActions.GET_TELEMETRY_CUSTOMERS, getTelemetryCustomers)];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeEvery(Actions.telemetry.AsyncActions.GET_TELEMETRY_CUSTOMER, getTelemetryCustomer)];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.telemetry.AsyncActions.GET_MKE_ALERTS, getMKEAlerts)];
            case 3:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.telemetry.AsyncActions.GET_MKE_RECOMMENDATIONS, getMKERecommendations)];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.telemetry.AsyncActions.TOGGLE_TELEMETRY_CLUSTER_STATUS, toggleTelemetryClusterStatus)];
            case 5:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(Actions.telemetry.AsyncActions.GET_CLUSTER_SPECS, getClusterSpecs)];
            case 6: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 7:
                _c.sent();
                return [2 /*return*/];
        }
    });
}

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { SuccessIcon, variants } from '@mirantis/mds-icon';
import { DateUtils } from '@utils/date/DateUtils';
import Tooltip from '@commonComponents/tooltip/Tooltip';
import { getFormattedNumberAfterDot } from '@commonUtils/commonUtils';
var TOOLTIP_PLACEMENT = 'left';
var TOOLTIP_MESSAGE = 'Overcommit > 100%';
var StyledTooltip = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    cursor: pointer;\n"], ["\n    cursor: pointer;\n"])));
export var normalizers = {
    clusterLastSyncDate: function (value) {
        return value ? DateUtils.getDateLocalStringAutoTimezoneHandler(value) : value;
    },
    lastSyncDate: function (value) {
        return value ? DateUtils.getDateLocalStringAutoTimezoneHandler(value) : value;
    },
    diskPercentage: function (value) {
        return value > 1 ? (React.createElement(StyledTooltip, null,
            React.createElement(Tooltip, { message: TOOLTIP_MESSAGE, place: TOOLTIP_PLACEMENT, disable: false },
                React.createElement("div", null, "".concat(getFormattedNumberAfterDot(value * 100), " %"))))) : ("".concat(getFormattedNumberAfterDot(value * 100), " %"));
    },
    memoryPercentage: function (value) {
        return value > 1 ? (React.createElement(StyledTooltip, null,
            React.createElement(Tooltip, { message: TOOLTIP_MESSAGE, place: TOOLTIP_PLACEMENT, disable: false },
                React.createElement("div", null, "".concat(getFormattedNumberAfterDot(value * 100), " %"))))) : ("".concat(getFormattedNumberAfterDot(value * 100), " %"));
    },
    cpuPercentage: function (value) {
        return value > 1 ? (React.createElement(StyledTooltip, null,
            React.createElement(Tooltip, { message: TOOLTIP_MESSAGE, place: TOOLTIP_PLACEMENT, disable: false },
                React.createElement("div", null, "".concat(getFormattedNumberAfterDot(value * 100), " %"))))) : ("".concat(getFormattedNumberAfterDot(value * 100), " %"));
    },
    memory: function (value) {
        return "".concat(value, " GB");
    },
    cpu: function (value) {
        return "".concat(value, " cores");
    },
    disk: function (value) {
        return "".concat(value, " GB");
    },
    totalNodes: function (value, row) {
        return "".concat(value, " (").concat(row.masterNodes, " m + ").concat(row.workerNodes, " w)");
    },
    clusterUpdating: function (value) {
        return value ? (React.createElement("div", { className: "is_updating" },
            React.createElement(SuccessIcon, { size: 16, variant: variants.SUCCESS, className: "is-updating", "data-testid": "is_updating" }))) : (React.createElement("div", { className: "not_updating" }));
    },
};
export var summaryNormalizers = {
    totalNodes: function (value, rows, summaryRows) {
        return (React.createElement(StyledTooltip, null,
            React.createElement(Tooltip, { message: "Total Nodes", place: TOOLTIP_PLACEMENT, disable: false },
                React.createElement("div", null, "".concat(value, " (").concat(summaryRows.masterNodes, " m + ").concat(summaryRows.workerNodes, " w)")))));
    },
    nodes: function (value) {
        return (React.createElement(StyledTooltip, null,
            React.createElement(Tooltip, { message: "Total Compute Nodes", place: TOOLTIP_PLACEMENT, disable: false },
                React.createElement("div", null, value))));
    },
    vms: function (value) {
        return (React.createElement(StyledTooltip, null,
            React.createElement(Tooltip, { message: "Total VMs", place: TOOLTIP_PLACEMENT, disable: false },
                React.createElement("div", null, value))));
    },
    cpuPercentage: function (value, rows) {
        return (React.createElement(StyledTooltip, null,
            React.createElement(Tooltip, { message: "Average CPU Usage %", place: TOOLTIP_PLACEMENT, disable: false },
                React.createElement("div", null, "".concat(getFormattedNumberAfterDot((value / rows.length) * 100), " %")))));
    },
    memoryPercentage: function (value, rows) {
        return (React.createElement(StyledTooltip, null,
            React.createElement(Tooltip, { message: "Average RAM Usage %", place: TOOLTIP_PLACEMENT, disable: false },
                React.createElement("div", null, "".concat(getFormattedNumberAfterDot((value / rows.length) * 100), " %")))));
    },
    cpu: function (value) {
        return (React.createElement(StyledTooltip, null,
            React.createElement(Tooltip, { message: "Total CPU", place: TOOLTIP_PLACEMENT, disable: false },
                React.createElement("div", null, "".concat(value, " cores")))));
    },
    memory: function (value) {
        return (React.createElement(StyledTooltip, null,
            React.createElement(Tooltip, { message: "Total Memory", place: TOOLTIP_PLACEMENT, disable: false },
                React.createElement("div", null, "".concat(value.toFixed(2), " GB")))));
    },
    disk: function (value) {
        return (React.createElement(StyledTooltip, null,
            React.createElement(Tooltip, { message: "Total Disk", place: TOOLTIP_PLACEMENT, disable: false },
                React.createElement("div", null, "".concat(value.toFixed(2), " GB")))));
    },
    diskPercentage: function (value, rows) {
        return (React.createElement(StyledTooltip, null,
            React.createElement(Tooltip, { message: "Average Disk Usage %", place: TOOLTIP_PLACEMENT, disable: false },
                React.createElement("div", null, "".concat(getFormattedNumberAfterDot((value / rows.length) * 100), " %")))));
    },
    tenants: function (value) {
        return (React.createElement(StyledTooltip, null,
            React.createElement(Tooltip, { message: "Total Tenants", place: TOOLTIP_PLACEMENT, disable: false },
                React.createElement("div", null, value))));
    },
    volumes: function (value) {
        return (React.createElement(StyledTooltip, null,
            React.createElement(Tooltip, { message: "Total Volumes", place: TOOLTIP_PLACEMENT, disable: false },
                React.createElement("div", null, value))));
    },
    images: function (value) {
        return (React.createElement(StyledTooltip, null,
            React.createElement(Tooltip, { message: "Total Images", place: TOOLTIP_PLACEMENT, disable: false },
                React.createElement("div", null, value))));
    },
    networks: function (value) {
        return (React.createElement(StyledTooltip, null,
            React.createElement(Tooltip, { message: "Total Networks", place: TOOLTIP_PLACEMENT, disable: false },
                React.createElement("div", null, value))));
    },
    loadbalancers: function (value) {
        return (React.createElement(StyledTooltip, null,
            React.createElement(Tooltip, { message: "Total Load Balancers", place: TOOLTIP_PLACEMENT, disable: false },
                React.createElement("div", null, value))));
    },
};
var templateObject_1;

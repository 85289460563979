import React, { memo } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { TITLE_STYLE } from './mkeTelemetryCustomerConstants';
import { getClusterLicense } from '../../../../commonTelemetryDashboardUtils';
import MkeClusterCharts from '@components/telemetry-dashboard/charts/mkeClusterscharts/MkeClusterCharts';
import TelemetryAlertsTable from '../../telemetry-alerts-table/TelemetryAlertsTable';
import RecommendationsContainer from '../../recommendations/RecommendationsContainer';
import TelemetryAlertsFilterContainer from '../../telemetry-alerts-filters/TelemetryAlertsFilterContainer';
import { AlertsFilterContext } from '../../telemetry-alerts-filters/TelemetryAlertsFilter';
var MkeTelemetryDetailView = function (_a) {
    var activeClusterId = _a.activeClusterId, changeAlertsFilter = _a.changeAlertsFilter, checkedActive = _a.checkedActive;
    var clusters = useSelector(function (store) { return get(store, 'telemetry.customer.mkeOverview.clusters', {}); });
    var sfAccount = useSelector(function (store) { return get(store, 'telemetry.customer.sfAccount'); });
    if (!activeClusterId || !sfAccount) {
        return React.createElement("div", null);
    }
    var clusterLicense = getClusterLicense(clusters, activeClusterId);
    var alertsParams = {
        license: clusterLicense,
        cluster_id: activeClusterId,
        alert_types: 'Health,Utilization',
    };
    var recommendationsParams = {
        license: clusterLicense,
        cluster_id: activeClusterId,
        alert_types: 'Recommendation',
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("h5", { style: TITLE_STYLE.FIRST },
            "Cluster Actions: (",
            activeClusterId,
            ")"),
        React.createElement(MkeClusterCharts, { activeClusterId: activeClusterId, clusterLicense: clusterLicense, shouldFetchCharts: true, checkedActive: checkedActive }),
        React.createElement("h5", { style: TITLE_STYLE.GENERAL }, "Alerts"),
        React.createElement(AlertsFilterContext.Consumer, null, function (alertsFilter) { return (React.createElement(React.Fragment, null,
            React.createElement(TelemetryAlertsFilterContainer, { onChange: changeAlertsFilter, alertsFilter: alertsFilter }),
            React.createElement("div", { style: { overflow: 'auto' } },
                React.createElement(TelemetryAlertsTable, { params: alertsParams, filter: alertsFilter })))); }),
        React.createElement("h5", { style: TITLE_STYLE.GENERAL }, "Recommendations"),
        React.createElement(RecommendationsContainer, { params: recommendationsParams })));
};
export default memo(MkeTelemetryDetailView);

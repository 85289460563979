export var SyncActions;
(function (SyncActions) {
    SyncActions["SET_CHARTS_DATA"] = "SET_CHARTS_DATA";
    SyncActions["DROP_CHARTS_TO_DEFAULT"] = "DROP_CHARTS_TO_DEFAULT";
    SyncActions["SET_CHARTS_CANVAS_LOADED"] = "SET_CHARTS_CANVAS_LOADED";
})(SyncActions || (SyncActions = {}));
export var AsyncActions;
(function (AsyncActions) {
    AsyncActions["FETCH_CHARTS"] = "FETCH_CHARTS";
})(AsyncActions || (AsyncActions = {}));

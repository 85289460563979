var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { connect } from 'react-redux';
import { DASHBOARDS_SCHEMA } from '@configs/DASHBOARD_PSEUDOS';
import ClusterHealthTable from '@components/cluster-health/cluster-health-table/ClusterHealthTable';
var PSEUDO_CC = DASHBOARDS_SCHEMA.SUPPORT.PAGES.CLUSTER_HEALTH.PSEUDO_CC;
var mapState = function (store) { return ({
    mode: store.mode,
    multiFilter: store.multiFilter,
    clusterHealth: store.clusterHealth,
    pagination: store.pagination.clusterHealth,
}); };
var connector = connect(mapState, null);
var ClusterHealthTableContainer = function (props) {
    return props.multiFilter.name !== PSEUDO_CC ? null : React.createElement(ClusterHealthTable, __assign({}, props));
};
export default connector(ClusterHealthTableContainer);

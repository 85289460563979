import { connect } from 'react-redux';
import withUrlValidationHOC from '@HOC/withUrlValidationHOC/withUrlValidationHOC';
import withDisablingWhileFetchingHOC from '@HOC/withDisablingWhileFetchingHOC/withDisablingWhileFetchingHOC';
import CrossPageFilter from './CrossPageFilter';
import { getCrossPageFilterPreferencesFromUrl } from './crossPageFilterUtils';
import { changeCrossPageFilterOption, initCrossPageFilterState, resetCrossPageFilterState, } from '@actionCreators/crossPageFilter';
var mapState = function (state) { return ({
    crossPageFilter: state.crossPageFilter,
    userPreferences: getCrossPageFilterPreferencesFromUrl(),
}); };
var mapDispatch = {
    initCrossPageFilterState: initCrossPageFilterState,
    changeCrossPageFilterOption: changeCrossPageFilterOption,
    resetCrossPageFilterState: resetCrossPageFilterState,
};
export default withDisablingWhileFetchingHOC(withUrlValidationHOC(connect(mapState, mapDispatch)(CrossPageFilter)));

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Input } from '@mirantis/mds-input';
import { useDebouncedEffect, useQuery } from '@app/hooks';
import { handleSetFilterSearchValueToUrl, replaceUnsupportedSymbolInUrl } from './searchUtils';
import { URL_QUERIES } from '@commonConstants/urlConstants';
var SEARCH_VALUE = URL_QUERIES.COMMON.SEARCH_VALUE;
var Search = function (props) {
    var max = props.max, label = props.label, onChange = props.onChange, withDelay = props.withDelay, placeholder = props.placeholder, searchValueQuery = props.searchValueQuery;
    var _a = useState(''), value = _a[0], setValue = _a[1];
    var query = searchValueQuery || SEARCH_VALUE;
    var searchValueFromUrl = useQuery(query);
    var searchValueFromRedux = useSelector(function (store) { return store.multiFilter.filter.searchTerm; });
    useEffect(function () {
        if (searchValueQuery !== value) {
            setValue(replaceUnsupportedSymbolInUrl(searchValueFromUrl));
        }
    }, [searchValueFromUrl]);
    useDebouncedEffect(function () {
        if (value !== searchValueFromRedux) {
            onChange(value);
            handleSetFilterSearchValueToUrl(query, replaceUnsupportedSymbolInUrl(value), searchValueFromUrl);
        }
    }, [value], withDelay);
    var handleChange = function (nextValue) {
        if (max && nextValue.length > max) {
            return;
        }
        setValue(nextValue);
    };
    return (React.createElement("div", { className: "filter__search", "data-testid": "search" },
        React.createElement("div", { className: "filter__search__dr" },
            React.createElement(Input, { style: { width: 240 }, className: "filter__input", "data-testid": "search-input", label: label, placeholder: placeholder || 'Search', showPlaceholder: true, value: value, showHelp: false, onChange: function (e) { return handleChange(e.target.value); }, onClear: function () { return handleChange(''); }, clearable: true }))));
};
export default Search;

import { INSTALLATIION_CHARTS, CHART_DASHBOARDS } from './activeInstancesChartConstants';
export var getActiveInstancesChartsConfigs = function (params) { return ({
    dashboard: CHART_DASHBOARDS.activeInstancesCharts,
    charts: INSTALLATIION_CHARTS.map(function (_a) {
        var key = _a.key;
        return ({
            key: key,
            end: params.end,
            start: params.start,
            timezone: params.timezone,
            granularity: params.granularity,
            customerTypes: params.customerTypes.join(','),
        });
    }),
}); };
export var getNormalizedRegionalClusters = function (chartsData) {
    if (chartsData === void 0) { chartsData = []; }
    return chartsData.map(function (item) { return ({
        x: item.time,
        y: item.reg_clusters,
    }); });
};
export var getNormalizedInstances = function (chartsData) {
    if (chartsData === void 0) { chartsData = []; }
    return chartsData.map(function (item) { return ({
        x: item.time,
        y: item.instances,
    }); });
};
export var getNormalizedChildClusters = function (chartsData) {
    if (chartsData === void 0) { chartsData = []; }
    return chartsData.map(function (item) { return ({
        x: item.time,
        y: item.child_clusters,
    }); });
};

var _a;
export var DASHBOARDS_SCHEMA = {
    HOME: {
        PSEUDO_UN: 'home',
        PSEUDO_DS: 'home',
        PSEUDO_CC: 'home',
    },
    ACCOUNT_MANAGEMENT: {
        PSEUDO_DS: 'account-management',
        PSEUDO_UN: 'account_management',
        PSEUDO_CC: 'accountManagement',
        PAGES: {
            MANAGE_USERS: {
                PSEUDO_DS: 'manage-users',
                PSEUDO_UN: 'manage_users',
                PSEUDO_CC: 'manageUsers',
            },
            MANAGE_CUSTOMERS: {
                PSEUDO_DS: 'manage-customers',
                PSEUDO_UN: 'manage_customers',
                PSEUDO_CC: 'manageCustomers',
                FILTER_PSEUDOS: {
                    MANAGE_INSTANCES: 'manageInstances',
                    SINGLE_CUSTOMER_INSTANCES: 'singleCustomerInstances',
                },
            },
            PERMISSION_GROUPS: {
                PSEUDO_DS: 'permission-groups',
                PSEUDO_UN: 'permission_groups',
                PSEUDO_CC: 'permissionGroups',
            },
        },
    },
    DASHBOARDS: {
        PSEUDO_DS: 'dashboards',
        PSEUDO_UN: 'dashboards',
        PSEUDO_CC: 'dashboards',
        PAGES: {
            CONTAINER_CLOUD_USAGE: {
                PSEUDO_DS: 'container-cloud-usage',
                PSEUDO_UN: 'container_cloud_usage',
                PSEUDO_CC: 'containerCloudUsage',
            },
            OPENSTACK_USAGE: {
                PSEUDO_DS: 'openstack-usage',
                PSEUDO_UN: 'openstack_usage',
                PSEUDO_CC: 'openstackUsage',
                TABS: {
                    OPENSTACK_OVERALL_USAGE: {
                        PSEUDO_DS: 'openstack-overall-usage',
                        PSEUDO_UN: 'openstack_overall_usage',
                        PSEUDO_CC: 'openstackOverallUsage',
                    },
                },
            },
            OPENSTACK_CLUSTERS_USAGE: {
                PSEUDO_DS: 'openstack-clusters-usage',
                PSEUDO_UN: 'openstack_clusters_usage',
                PSEUDO_CC: 'openstackClusterUsage',
            },
            BOOTSTRAP: {
                PSEUDO_DS: 'bootstrap',
                PSEUDO_UN: 'bootstrap',
                PSEUDO_CC: 'bootstrap',
            },
        },
    },
    REPORTS: {
        PSEUDO_DS: 'reports',
        PSEUDO_UN: 'reports',
        PSEUDO_CC: 'reports',
        PAGES: {
            INSTALLATION_REPORT: {
                PSEUDO_DS: 'installation-report',
                PSEUDO_UN: 'installation_report',
                PSEUDO_CC: 'installationReport',
            },
            NEW_SIGN_UPS_REPORT: {
                PSEUDO_DS: 'new-sign-ups-report',
                PSEUDO_UN: 'new_sign_ups_report',
                PSEUDO_CC: 'newSignUpsReport',
            },
            ACTIVE_INSTANCES: {
                PSEUDO_DS: 'active-instances',
                PSEUDO_UN: 'active_instances',
                PSEUDO_CC: 'activeInstances',
            },
            TRIAL_REPORT: {
                PSEUDO_DS: 'trialReport-report',
                PSEUDO_UN: 'trial_report',
                PSEUDO_CC: 'trialReport',
                FILTER_PSEUDOS: {
                    TELEMETRY_CUSTOMERS: 'trialCustomers',
                },
            },
            TRIAL_STATISTIC: {
                PSEUDO_DS: 'trial-statistic',
                PSEUDO_UN: 'trial_statistic',
                PSEUDO_CC: 'trialStatistic',
                TABS: {
                    OCCUPIED_SLOTS: 'occupiedSlots',
                    AWS_COSTS: 'AWScosts',
                    SIGN_UPS: 'signUps',
                },
            },
        },
    },
    SUPPORT: {
        PSEUDO_DS: 'support',
        PSEUDO_UN: 'support',
        PSEUDO_CC: 'support',
        PAGES: {
            CLUSTER_HEALTH: {
                PSEUDO_DS: 'cluster-health',
                PSEUDO_UN: 'cluster_health',
                PSEUDO_CC: 'clusterHealth',
            },
            ALERTS: {
                PSEUDO_DS: 'alerts',
                PSEUDO_UN: 'alerts',
                PSEUDO_CC: 'alerts',
            },
            SEARCH_CLUSTERS: {
                PSEUDO_DS: 'general-search-dashboard',
                PSEUDO_UN: 'general_search_dashboard',
                PSEUDO_CC: 'generalSearchDashboard',
            },
        },
    },
    MKE_DASHBOARDS: {
        PSEUDO_DS: 'mke-dashboards',
        PSEUDO_UN: 'mke_dashboards',
        PSEUDO_CC: 'mkeDashboards',
        PAGES: {
            MKE_DASHBOARDS: {
                PSEUDO_DS: 'imke-dashboards',
                PSEUDO_UN: 'imke_dashboards',
                PSEUDO_CC: 'imkeDashboards',
                FILTER_PSEUDOS: {
                    TELEMETRY_CUSTOMERS: 'telemetryCustomers',
                },
                TABLES: {
                    MCC_TABLE: 'mccClustersTable',
                    MKE_TABLE: 'mkeClustersTable',
                },
            },
        },
    },
};
export var DASHBOARD_FAMILIES;
(function (DASHBOARD_FAMILIES) {
    DASHBOARD_FAMILIES["MCC_REPORTS"] = "MCC_REPORTS";
    DASHBOARD_FAMILIES["STATIC_REPORTS"] = "STATIC_REPORTS";
})(DASHBOARD_FAMILIES || (DASHBOARD_FAMILIES = {}));
export var PAGINATION_NAMES = {
    BOOTSTRAP: 'bootstrap',
    USER_GROUPS: 'userGroups',
    NEW_SIGN_UPS: 'newSignUps',
    MANAGE_USERS: 'manageUsers',
    TRIAL_REPORT: 'trialReport',
    CLUSTER_HEALTH: 'clusterHealth',
    TOP_CUSTOMERS: 'customerOverview',
    MANAGE_CUSTOMERS: 'manageCustomers',
    MANAGE_INSTANCES: 'manageInstances',
    ACTIVE_INSTANCES: 'activeInstances',
    CUSTOMER_OVERVIEW: 'customerOverview',
    PERMISSION_GROUPS: 'permissionGroups',
    TELEMETRY_CUSTOMERS: 'telemetryCustomers',
    SEARCH_CLUSTERS: 'generalSearchDashboard',
    OPENSTACK_CLUSTER_USAGE: 'openstackClusterUsage',
    BOOTSTRAP_INSTALLATIONS: 'bootstrapInstallations',
    SINGLE_CUSTOMER_INSTANCES: 'singleCustomerInstances',
    TELEMETRY_CUSTOMER_CLUSTERS_MCC: 'telemetryCustomerClustersMCC',
    TELEMETRY_CUSTOMER_CLUSTERS_MKE: 'telemetryCustomerClustersMKE',
};
var ACCOUNT_MANAGEMENT = DASHBOARDS_SCHEMA.ACCOUNT_MANAGEMENT, MKE_DASHBOARDS = DASHBOARDS_SCHEMA.MKE_DASHBOARDS, DASHBOARDS = DASHBOARDS_SCHEMA.DASHBOARDS, SUPPORT = DASHBOARDS_SCHEMA.SUPPORT, REPORTS = DASHBOARDS_SCHEMA.REPORTS, HOME = DASHBOARDS_SCHEMA.HOME;
export var PAGES_UNDERSCORE_CAMEL_CASE_MAP = (_a = {},
    _a[HOME.PSEUDO_DS] = HOME.PSEUDO_CC,
    _a[ACCOUNT_MANAGEMENT.PSEUDO_DS] = ACCOUNT_MANAGEMENT.PSEUDO_CC,
    _a[ACCOUNT_MANAGEMENT.PAGES.MANAGE_USERS.PSEUDO_DS] = ACCOUNT_MANAGEMENT.PAGES.MANAGE_USERS.PSEUDO_CC,
    _a[ACCOUNT_MANAGEMENT.PAGES.MANAGE_CUSTOMERS.PSEUDO_DS] = ACCOUNT_MANAGEMENT.PAGES.MANAGE_CUSTOMERS.PSEUDO_CC,
    _a[DASHBOARDS.PSEUDO_DS] = DASHBOARDS.PSEUDO_CC,
    _a[DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE.PSEUDO_DS] = DASHBOARDS.PAGES.CONTAINER_CLOUD_USAGE.PSEUDO_CC,
    _a[DASHBOARDS.PAGES.OPENSTACK_USAGE.PSEUDO_DS] = DASHBOARDS.PAGES.OPENSTACK_USAGE.PSEUDO_CC,
    _a[DASHBOARDS.PAGES.BOOTSTRAP.PSEUDO_DS] = DASHBOARDS.PAGES.BOOTSTRAP.PSEUDO_CC,
    _a[REPORTS.PSEUDO_DS] = REPORTS.PSEUDO_CC,
    _a[REPORTS.PAGES.ACTIVE_INSTANCES.PSEUDO_DS] = REPORTS.PAGES.ACTIVE_INSTANCES.PSEUDO_CC,
    _a[REPORTS.PAGES.INSTALLATION_REPORT.PSEUDO_DS] = REPORTS.PAGES.INSTALLATION_REPORT.PSEUDO_CC,
    _a[REPORTS.PAGES.NEW_SIGN_UPS_REPORT.PSEUDO_DS] = REPORTS.PAGES.NEW_SIGN_UPS_REPORT.PSEUDO_CC,
    _a[REPORTS.PAGES.TRIAL_REPORT.PSEUDO_DS] = REPORTS.PAGES.TRIAL_REPORT.PSEUDO_CC,
    _a[SUPPORT.PAGES.ALERTS.PSEUDO_DS] = SUPPORT.PAGES.ALERTS.PSEUDO_CC,
    _a[SUPPORT.PAGES.CLUSTER_HEALTH.PSEUDO_DS] = SUPPORT.PAGES.CLUSTER_HEALTH.PSEUDO_CC,
    _a[MKE_DASHBOARDS.PSEUDO_DS] = MKE_DASHBOARDS.PSEUDO_CC,
    _a[MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.PSEUDO_DS] = MKE_DASHBOARDS.PAGES.MKE_DASHBOARDS.PSEUDO_CC,
    _a);

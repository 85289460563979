var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import get from 'lodash/get';
import Redirect from '@commonUtils/urlUtils/Redirect';
export var compareOptionStrings = function (a, b) { return (typeof a === 'string' ? a.localeCompare(b) : a.id.localeCompare(b.id)); };
export var isValueActive = function (option, state) {
    return typeof option === 'string' ? state.includes(option) : state.some(function (o) { return isEqual(o, option); });
};
export var isSomeValueSelected = function (options, innerState) { return options.some(function (option) { return isValueActive(option, innerState); }); };
export var getSortedOptionsByActiveState = function (options, innerState) {
    var active = [];
    var rest = [];
    options.forEach(function (option) {
        if (isValueActive(option, innerState))
            active.push(option);
        else
            rest.push(option);
    });
    return __spreadArray(__spreadArray([], active, true), rest, true);
};
export var resolveOptions = function (options, innerState) {
    var newOptions = cloneDeep(options);
    if (newOptions.length < 11 || !isSomeValueSelected(newOptions, innerState)) {
        return newOptions;
    }
    return getSortedOptionsByActiveState(newOptions, innerState);
};
export var getPickerValueFromUrl = function (picker) {
    var resultArray = get(queryString.parse(window.location.search), picker, null);
    return resultArray ? resultArray.split(',') : [];
};
export var handleSetPickerValueToUrl = function (picker, innerState) {
    Redirect.toCurrentPageWithQueryParams(picker, innerState, '');
};
export var handleRemovePickerValueFromUrl = function (pickers) {
    Redirect.toCurrentPageWithoutQueryParams(pickers);
};

import get from 'lodash/get';
import isArray from 'lodash/isArray';
import forEach from 'lodash/forEach';
import Redirect from '@commonUtils/urlUtils/Redirect';
import { URL_QUERIES } from '@commonConstants/urlConstants';
import { getUrlWithParams } from '@commonUtils/urlUtils/urlUtils';
import { MAX_MULTISELECT_ITEMS_SIZE } from './filterConstants';
var PICKERS = URL_QUERIES.PICKERS;
var getValidatedOptions = function (items) { return items.slice(0, MAX_MULTISELECT_ITEMS_SIZE); };
var getPickersQueries = function (filter, params) {
    var limit = get(params, 'limit', 1);
    var queriesToSet = {};
    forEach(filter.selected, function (value, pseudo) {
        var _a;
        var _b = get(filter, "configuration.".concat(pseudo), {}), currentPage = _b.currentPage, maxPages = _b.maxPages;
        var paginationQuery = (_a = Object.values(PICKERS).find(function (_a) {
            var picker = _a.picker;
            return picker === pseudo;
        })) === null || _a === void 0 ? void 0 : _a.value;
        var shouldSetPaginationParams = paginationQuery && currentPage && maxPages;
        var options;
        if (isArray(value)) {
            options = "".concat(getValidatedOptions(value).map(function (item) { return get(item, 'id', item); }));
        }
        else {
            options = value;
        }
        queriesToSet[pseudo] = options;
        if (shouldSetPaginationParams) {
            queriesToSet[paginationQuery] = [currentPage, currentPage * limit, maxPages].join(',');
        }
    });
    return {
        queriesToSet: queriesToSet,
    };
};
export var handleSetPickersSelectedItemsToUrl = function (filter, params) {
    var queries = getPickersQueries(filter, params);
    var url = getUrlWithParams(queries.queriesToSet, '');
    Redirect.debouncedGoTo(url);
};
export var handleResetFilterPickers = function (resetFilterPickers, pickers, multiFilter, name) {
    if (!(!multiFilter.loading && multiFilter.name === name)) {
        return;
    }
    var pickersToUpdate = [];
    pickers.forEach(function (p) {
        if (!p.static) {
            pickersToUpdate.push(p.pseudo);
        }
    });
    resetFilterPickers({ hasToBeUpdated: pickersToUpdate });
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import chunk from 'lodash/chunk';
import isEqual from 'lodash/isEqual';
import React, { useEffect, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { changeCurrentPage } from '@redux/dispatchers/pagination';
import PaginationContainer from '@commonComponents/pagination/PaginationContainer';
import GropedColumnTable from '@commonComponents/gropped-column-table/GropedColumnTable';
var MIN_PAGINATION_VALUE = 10;
var GroupTableWrapper = function (props) {
    var pagination = useSelector(function (store) { return ({
        pagination: store.pagination[props.paginationName],
    }); }).pagination;
    var normalizedData = useMemo(function () { return chunk(props.data, pagination.limit)[pagination.currentPage - 1]; }, [props.data, pagination.limit, pagination.currentPage]);
    useEffect(function () {
        changeCurrentPage({
            currentPage: 1,
            limit: MIN_PAGINATION_VALUE,
            paginationName: props.paginationName,
            maxPages: Math.ceil(props.data.length / MIN_PAGINATION_VALUE),
        });
    }, [props.data]);
    var onPaginationChange = function (page) {
        changeCurrentPage({
            currentPage: Number(page),
            paginationName: props.paginationName,
        });
    };
    var onLimitChange = function (limit) {
        changeCurrentPage({
            limit: limit,
            currentPage: 1,
            paginationName: props.paginationName,
            maxPages: Math.ceil(props.data.length / limit),
        });
    };
    var startRowNumber = pagination.currentPage === 1 ? 1 : pagination.limit * pagination.currentPage - pagination.limit + 1;
    return (React.createElement(React.Fragment, null,
        React.createElement(GropedColumnTable, __assign({}, props, { data: normalizedData, cellOnCLick: function (value) { return props.cellOnCLick(value); }, startRowNumber: startRowNumber })),
        React.createElement(PaginationContainer, { withLimits: true, pagination: pagination, paginationQuery: props.paginationName, onLimitChange: onLimitChange, onChange: onPaginationChange })));
};
export default memo(GroupTableWrapper, function (prevProps, nextProps) {
    return prevProps.isClustersManaging === nextProps.isClustersManaging && isEqual(prevProps.data, nextProps.data);
});

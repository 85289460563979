import { store } from '@redux/store';
import * as actionCreators from '@redux/action-creators';
export var getCustomerTypes = function () {
    var action = actionCreators.mode.getCustomerTypes();
    store.dispatch(action);
};
export var setCustomerTypes = function (payload) {
    var action = actionCreators.mode.setCustomerTypes(payload);
    store.dispatch(action);
};
export var setSelectedOptions = function (payload) {
    var action = actionCreators.mode.setSelectedOptions(payload);
    store.dispatch(action);
};

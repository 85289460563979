import produce from 'immer';
import { getNormalizedUserInfo } from '@commonUtils/tokenUtils';
import { Localstorage } from '@utils/localstorage/Localstorage';
import { JWT } from '@utils/JWT/JWT';
import { resolvers } from './resolvers';
import { DEFAULT_TIMEZONE } from './constants';
var token = Localstorage.get('token');
var preferences = Localstorage.get('user-preferences');
var activeUser = null;
if (token) {
    activeUser = getNormalizedUserInfo(JWT.decode(token));
}
var userPreferences = preferences ? JSON.parse(preferences) : { id: activeUser === null || activeUser === void 0 ? void 0 : activeUser.id, timezone: DEFAULT_TIMEZONE };
var timezone = (function () {
    if (!userPreferences) {
        return null;
    }
    return userPreferences.timezone !== 'UTC' ? new Date().toTimeString().slice(9, 17) : userPreferences.timezone;
})();
export var initialState = {
    activeUser: activeUser,
    userPreferences: userPreferences,
    settings: {
        initial: {
            timezone: timezone,
        },
        current: {
            timezone: timezone,
        },
    },
};
export var userInfo = function (state, action) {
    if (state === void 0) { state = initialState; }
    if (action === void 0) { action = {}; }
    var type = action.type, payload = action.payload;
    if (!(type in resolvers)) {
        return state;
    }
    var resolver = function (draft) { return resolvers[type](draft, payload); };
    return produce(state, resolver);
};

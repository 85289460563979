var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import { getActiveInstancesStatistics } from '@api/instance-api/instanceApi';
import GreySection from '@commonComponents/GreySection';
import StyledOverallStatistic from '@app/common/react/styled/StyledOverallStatistic';
import withDisablingWhileFetchingHOC from '@HOC/withDisablingWhileFetchingHOC/withDisablingWhileFetchingHOC';
import { ACTIVE_INSTANCE_STATS_BANNER_DATA } from './activeInstanceConstants';
var mapState = function (store) { return ({
    mode: store.mode.selected,
}); };
var connector = connect(mapState);
var Item = function (_a) {
    var value = _a.value, name = _a.name;
    return (React.createElement("div", { className: "overall-statistic__item" },
        React.createElement("div", { className: "overall-statistic__item__value" }, value),
        React.createElement("div", { className: "overall-statistic__item__name" }, name)));
};
var ActiveInstanceStatsBanner = function (_a) {
    var mode = _a.mode;
    var _b = React.useState({
        instanceCount: '-',
        regionClusterCount: '-',
        childClusterCount: '-',
    }), state = _b[0], setState = _b[1];
    var callApi = function () { return __awaiter(void 0, void 0, void 0, function () {
        var params, _a, instanceCount, regionClusterCount, childClusterCount;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    params = {
                        customer_types: mode[0] === 'All' ? null : mode.toString(),
                    };
                    return [4 /*yield*/, getActiveInstancesStatistics(params)];
                case 1:
                    _a = _b.sent(), instanceCount = _a.instanceCount, regionClusterCount = _a.regionClusterCount, childClusterCount = _a.childClusterCount;
                    return [2 /*return*/, {
                            instanceCount: instanceCount,
                            regionClusterCount: regionClusterCount,
                            childClusterCount: childClusterCount,
                        }];
            }
        });
    }); };
    React.useEffect(function () {
        callApi()
            .then(function (res) { return setState(res); })
            .catch(function () { return console.error('Failed to get static stats banner data'); });
    }, [mode]);
    var items = ACTIVE_INSTANCE_STATS_BANNER_DATA.map(function (item) { return (React.createElement(Item, { key: item.pseudo, value: state[item.pseudo], name: item.name })); });
    return (React.createElement(GreySection, null,
        React.createElement("h2", null, "Current Active Instances"),
        React.createElement(StyledOverallStatistic, { className: "overall-statistic" }, items)));
};
var areEqual = function (pP, nP) {
    return isEqual(pP.mode, nP.mode);
};
export default withDisablingWhileFetchingHOC(connector(memo(ActiveInstanceStatsBanner, areEqual)), { spinner: true });

import { ENVIRONMENTS } from '@commonConstants/environments';
var PROD = ENVIRONMENTS.PROD, STAGE = ENVIRONMENTS.STAGE;
export var ROOT_ELEMENT_FOR_COLLECTOR = '#issue-collector-trigger';
export var COLLECTOR_SCRIPT_TEST_ENV = '<script type="text/javascript" src="https://mirantis.jira.com/s/d41d8cd98f00b204e9800998ecf8427e-T/-fzx0du/b/3/9edb822e6c4db3635f27806476c088cb/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-GB&collectorId=9e0102b9"></script><script type="text/javascript" src="?collectorId=9e0102b9"></script>';
export var COLLECTOR_SCRIPT_PROD_ENV = '<script type="text/javascript" src="https://mirantis.jira.com/s/d41d8cd98f00b204e9800998ecf8427e-T/-fzx0du/b/3/9edb822e6c4db3635f27806476c088cb/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-GB&collectorId=0abd2ad7"></script><script type="text/javascript" src="?collectorId=0abd2ad7"></script>';
export var getIssueCollectorScript = function () {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return [PROD, STAGE].includes(process.env.ENVIRONMENT)
        ? COLLECTOR_SCRIPT_PROD_ENV
        : COLLECTOR_SCRIPT_TEST_ENV;
};

import React from 'react';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from './Icon';
export var Link = function (_a) {
    var handleClickOnLink = _a.handleClickOnLink, top = _a.top, route = _a.route, needRenderIcon = _a.needRenderIcon, pathname = _a.pathname, state = _a.state, isSfUser = _a.isSfUser;
    if (!route.isVisible())
        return null;
    var isMatch = function () { return pathname === route.path; };
    var className = classNames({
        nav__link: true,
        disabled: route.disabled,
        match: top ? !!(state === null || state === void 0 ? void 0 : state.match) : isMatch(),
    });
    var handleClick = function () {
        if (top) {
            handleClickOnLink(route.pseudo);
        }
    };
    var Raw = function () { return (React.createElement(React.Fragment, null,
        React.createElement(Icon, { needRenderIcon: needRenderIcon, pseudo: route.pseudo }),
        React.createElement("span", { className: "nav__link__name" }, route.name))); };
    return route.disabled || isSfUser ? (React.createElement("div", { className: className },
        React.createElement(Raw, null))) : (React.createElement(RouterLink, { className: className, to: route.path, onClick: handleClick },
        React.createElement(Raw, null)));
};

export var COLUMNS = [
    {
        title: 'Job Id',
        dataIndex: 'jobId',
        key: 'jobId',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Last Run',
        dataIndex: 'lastRun',
        key: 'lastRun',
    },
    {
        title: 'Last Successful Run',
        dataIndex: 'lastSuccessfulRun',
        key: 'lastSuccessfulRun',
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
    },
];

export var SyncActions;
(function (SyncActions) {
    SyncActions["SET_FILTER_OPTION"] = "SET_FILTER_OPTION";
    SyncActions["SET_ALERT_FILTER_OPTION"] = "SET_ALERT_FILTER_OPTION";
    SyncActions["SET_CLUSTER_HEALTH_FILTER_OPTION"] = "SET_CLUSTER_HEALTH_FILTER_OPTION";
    SyncActions["SET_FILTER_DATE_RANGE"] = "SET_FILTER_DATE_RANGE";
    SyncActions["SET_FILTER_INITIAL_STATE"] = "SET_FILTER_INITIAL_STATE";
    SyncActions["CHANGE_SEARCH"] = "CHANGE_SEARCH";
})(SyncActions || (SyncActions = {}));
export var AsyncActions;
(function (AsyncActions) {
    AsyncActions["SAVE_FETCHED_OPTIONS_IN_STORE"] = "SAVE_FETCHED_OPTIONS_IN_STORE";
    AsyncActions["GET_MANAGE_INSTANCES_FILTER_OPTIONS"] = "GET_MANAGE_INSTANCES_FILTER_OPTIONS";
})(AsyncActions || (AsyncActions = {}));

import { flattenDeep } from 'lodash';
export var currentState = function (pages) {
    return flattenDeep(Object.values(pages))
        .map(function (_a) {
        var errorObject = _a.errorObject;
        return ({
            message: errorObject === null || errorObject === void 0 ? void 0 : errorObject.message,
            detailInfo: errorObject === null || errorObject === void 0 ? void 0 : errorObject.detailInfo,
            timestamp: errorObject === null || errorObject === void 0 ? void 0 : errorObject.timestamp,
        });
    })
        .reduce(function (acc, item) {
        if (acc.find(function (i) { return i.message === 'Seams you have problems with network'; })) {
            return acc;
        }
        return acc.concat(item);
    }, []);
};

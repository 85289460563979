var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { ENVIRONMENTS } from '@commonConstants/environments';
import { GAInit, EventUserAgent } from '@app/tracking';
import RouterComponent from './Router';
var AppContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    min-height: 100vh;\n    overflow: hidden;\n"], ["\n    display: flex;\n    min-height: 100vh;\n    overflow: hidden;\n"])));
var App = function () {
    useEffect(function () {
        if (process.env.ENVIRONMENT === ENVIRONMENTS.PROD) {
            GAInit();
            EventUserAgent();
        }
    }, []);
    return (React.createElement(AppContainer, { className: "app" },
        React.createElement(RouterComponent, null)));
};
export default App;
var templateObject_1;
